import React, { ReactElement, useContext } from "react";
import { TNSInput, TNSTooltip } from "@tns/ui-components";
import { TRANSLATION } from "../../../../../../../utils/const/translation";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TroubleshootingContext, TroubleshootingFormValues } from "../../../../../../../components/device/troubleshooting/context/troubleshooting-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import ipRegex from "ip-regex";
import { TroubleshootingHelper } from "../../../../../../../helpers/device/TroubleshootingHelper";

export const IPAddressFormInput: React.FC = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const { ipAddressRangeFromTo, filtersEnabled } = useContext(TroubleshootingContext);
  const { isIpInRange } = TroubleshootingHelper();
  const name: keyof TroubleshootingFormValues = "ipAddress";

  return (
    <div className="d-flex flex-column w-25">
      <div className="d-flex gap-2">
        <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.ipAddress)}</span>
        <span className="pl-2">
          <TNSTooltip title={`${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.ipMustBebetweenThisRange, ipAddressRangeFromTo)}`}>
            <FontAwesomeIcon icon={faCircleInfo} />
          </TNSTooltip>
        </span>
      </div>
      <div>
        <Controller
          name={name}
          control={control}
          rules={{
            required: true,
            validate: (value: string): boolean => {
              if (!ipRegex({ exact: true }).test(value)) {
                return false;
              }
              if (!isIpInRange(value, { lo: ipAddressRangeFromTo.from, hi: ipAddressRangeFromTo.to })) {
                return false;
              }
              return true;
            }
          }}
          render={({ field }): ReactElement => (
            <TNSInput
              className="w-100"
              placeholder={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.pleaseInputValidIpAddress)}
              {...(errors[name] ? { status: "error" } : {})}
              onChange={(e): void => {
                field.onChange(e);
              }}
              value={field.value}
              disabled={!filtersEnabled}
            />
          )}
        />
        {errors[name] && <small className="text-danger position-absolute">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.pleaseInputValidIpAddress)}</small>}
      </div>
    </div>
  );
};
