export const TRANSLATION = {
  SIDEBAR: {
    MENU: {
      next: "next",
      informationFilters: "informationFilters",
      monitoring: "monitoring",
      devices: "devices",
      transactions: "transactions",
      support: "support",
      incidentTickets: "incidentTickets",
      changeManagement: "changeManagement",
      administration: "administration",
      userAdministration: "userAdministration",
      companyAdministration: "companyAdministration",
      help: "help",
      contactUs: "contactUs",
      userGuide: "userGuide",
      members: "members",
      entityProfiles: "entityProfiles",
      contactInformation: "contactInformation",
      contact: "contact",
      userRoles: "userRoles",
      directory: "directory",
      coinReports: "coinReports",
      availabilityReports: "availabilityReports",
      selfServiceTools: "selfServiceTools",
      tnsLinkProvisioning: "tnsLinkProvisioning"
    },
    SUPPORT: {
      CHANGEMANAGMENT: {
        changeStartTime: "changeStartTime",
        changeTicketId: "changeTicketId",
        companyName: "companyName",
        changeDescription: "changeDescription",
        impactType: "impactType",
        statusOfChange: "statusOfChange",
        maximumDuration: "maximumDuration",
        changeDetails: "changeDetails",
        changeTicket: "changeTicket",
        type: "type",
        startDate: "startDate",
        hrs: "hrs",
        reasonForChange: "reasonForChange",
        changesPlanned: "changesPlanned",
        expectedServiceImpactDuringChange: "expectedServiceImpactDuringChange",
        detailsOfDevicesEffectedByChange: "detailsOfDevicesEffectedByChange",
        changeTickets: "changeTickets",
        startDateTime: "startDateTime",
        maximumDurationHrs: "maximumDurationHrs",
        merchantName: "merchantName",
        customerSiteName: "customerSiteName",
        customerDeviceName: "customerDeviceName",
        tnsDeviceName: "tnsDeviceName",
        shedule: "shedule",
        changeSchedule: "changeSchedule",
        scheduleView: "scheduleView",
        gridView: "gridView"
      }
    },
    HELP: {
      CONTACT: {
        contactWhenReporting: "contactWhenReporting",
        organizationsContact: "organizationsContact",
        globalNetworkOperationsCenter: "globalNetworkOperationsCenter",
        clientManagementServices: "clientManagementServices",
        userGuideComingSoon: "userGuideComingSoon",
        supportDuringRegularBusiness: "supportDuringRegularBusiness"
      }
    },
    ADMINISTRATION: {
      next: "next",
      informationFilters: "informationFilters",
      administrationPanel: "administrationPanel",
      createUser: "createUser",
      COMPANY: {
        areYouSureYouWantToCreateCompanyProfile: "areYouSureYouWantToCreateCompanyProfile",
        previous: "previous",
        isActivelyAssociatedWithTheseUsers: "isActivelyAssociatedWithTheseUsers",
        isNotAssociatedWithAnyUsers: "isNotAssociatedWithAnyUsers",
        areYouSureYouWantToDeleteThisCompany: "areYouSureYouWantToDeleteThisCompany",
        companyProfileName: "companyProfileName",
        canBeDeleted: "canBeDeleted",
        notes: "notes",
        note: "note",
        associatedCompanyNames: "associatedCompanyNames",
        areSureYouWantToDeleteThisCompanyProfile: "areSureYouWantToDeleteThisCompanyProfile",
        companyProfile: "companyProfile",
        companyProfileAdministration: "companyProfileAdministration",
        availableAcna: "availableAcna",
        associateAcna: "associateAcna",
        nameIsRequired: "nameIsRequired",
        noteMustBeLessThan: "noteMustBeLessThan",
        characters: "characters",
        passwordCannotBeTheSameAsUsername: "passwordCannotBeTheSameAsUsername",
        companyNameIsAlreadyTaken: "companyNameIsAlreadyTaken",
        saveCompanyProfile: "areYouSureYouWantToSave",
        emailAlreadyInUse: "emailAlreadyInUse",
        usernameAlreadyIsUse: "usernameAlreadyIsUse",
        usernameAlreadyIsUseButNotOnboarded: "usernameAlreadyIsUseButNotOnboarded",
        associateACNAs: "associateACNAs",
        createCompanyProfile: "createCompanyProfile",
        hasAuspaynet: "hasAuspaynet",
        create: "create",
        save: "save"
      },
      USER: {
        companyProfileAdministration: "companyProfileAdministration",
        entityProfiles: "entityProfiles",
        roles: "roles",
        role: "role",
        roleRequired: "roleRequeired",
        Enabled: "enabled",
        Disabled: "disabled",
        Locked: "locked",
        Expired: "expired",
        enabled: "enabled",
        disabled: "disabled",
        expired: "expired",
        locked: "locked",
        timeZone: "timeZone",
        updateUser: "areYouSureYouWantToUpdateUser",
        createUser: "areYouSureYouWantToCreateUser",
        userProfileAdministration: "userProfileAdministration",
        availableCompanyProfiles: "availableCompanyProfiles",
        associatedCompanyProfiles: "associatedCompanyProfiles",
        previous: "previous",
        email: "email",
        emailMaxAdministration: "emailMaxAdministration",
        emailMinAdministration: "emailMinAdministration",
        firstNameMaxAdministration: "firstNameMaxAdministration",
        firstNameMinAdministration: "firstNameMinAdministration",
        lastNameMaxAdministration: "lastNameMaxAdministration",
        lastNameMinAdministration: "lastNameMinAdministration",
        usernameMaxAdministration: "usernameMaxAdministration",
        usernameMinAdministration: "usernameMinAdministration",
        youAreAboutToImpersonateAsTheFollowingUser: "youAreAboutToImpersonateAsTheFollowingUser",
        keepInMindAllTheActionsYouPerformWillAffectToTheRealUser: "keepInMindAllTheActionsYouPerformWillAffectToTheRealUser",
        userCreatedSuccessfully: "userCreatedSuccessfully",
        errorCreatingUser: "errorCreatingUser",
        userUpdatedSuccessfully: "userUpdatedSuccessfully",
        errorUpdatingUser: "errorUpdatingUser",
        associateCompanyProfiles: "associateCompanyProfiles",
        userOnboardedSuccessfully: "userOnboardedSuccessfully",
        errorOnboardingUser: "errorOnboardingUser",
        isInvalid: "isInvalid"
      },
      COIN: {
        coinReports: "coinReports",
        coinReport: "coinReports",
        yearReport: "yearReport",
        coinAvailability: "coinAvailability",
        coinMemberAvailabilityReportDetails: "coinMemberAvailabilityReportDetails",
        year: "year",
        availabilityReport: "availabilityReport",
        membersAvailabilityReport: "membersAvailabilityReport",
        coinMemberAvailability: "coinMemberAvailability",
        coinAvailabilityReport: "coinAvailabilityReport",
        coinMembersAvailabilityReport: "coinMembersAvailabilityReport",
        selectYear: "selectYear",
        selectAcna: "selectAcna",
        memberACNA: "memberACNA",
        inOrderToSeeTheReport: "inOrderToSeeTheReport"
      }
    },
    MONITORING: {
      dashboard: "dashboard",
      devices: "devices",
      MEMBERS: {
        BUTTON: {
          tunnelDetailsView: "tunnelDetailsView",
          connectionGraphView: "connectionGraphView"
        },
        INTERFACETYPE: {
          primary: "primary",
          secondary: "secondary"
        },
        MAPPER: {
          sourceMember: "sourceMember",
          destinationMember: "destinationMember",
          sourceTNSDeviceName: "sourceTNSDeviceName",
          sourceCustomerDeviceName: "sourceCustomerDeviceName",
          destinationTNSDeviceName: "destinationTNSDeviceName",
          destinationCustomerDeviceName: "destinationCustomerDeviceName",
          wanInstance: "wanInstance",
          tunnelStatus: "tunnelStatus",
          destAcnaStatus: "destAcnaStatus",
          acnaStatus: "acnaStatus"
        },
        MEMBERSCONNECTIVITY: {
          upFull: "UpFull",
          upSiteToSite: "upSiteToSite",
          upTnsNetwork: "upTnsNetwork",
          down: "Down",
          exportMember: "exportMember"
        },
        INTERFACES: {
          UP: "UP",
          DOWN: "DOWN",
          ACKNOWLEDGED: "ACKNOWLEDGED",
          UNKNOWN: "UNKNOWN"
        },
        CONECTION: {
          memberOnline: "memberOnline",
          memberOnBackup: "memberOnBackup",
          memberOffline: "memberOffline"
        },
        STATUSCONECTION: {
          fulleSiteToSite: "fulleSiteToSite",
          siteToSite: "siteToSitePrimarySecondary",
          tnsNetwork: "tnsNetwork",
          down: "down"
        },
        TABLE: {
          sourceSite: "sourceSite",
          destinationSite: "destinationSite",
          wan: "wan",
          siteToSite: "siteToSite"
        },
        primarySecondaryTunnels: "primarySecondaryTunnels",
        statusOfMemberConnection: "statusOfMemberConnection",
        partner: "partner",
        membersConnections: "membersConnections",
        allMemberConnections: "allMemberConnections",
        connectionsFromMember: "connectionsFromMember",
        tunnelDetailsView: "tunnelDetailsView",
        connectionGraphView: "connectionGraphView",
        member: "member",
        downloadAllMembers: "downloadAllMembers",
        noMemberSelected: "noMemberSelected",
        noMemberConnectionsConfigured: "noMemberConnectionsConfigured",
        select: "select",
        upFull: "upFull",
        sourceSiteDeviceName: "sourceSiteDeviceName"
      },
      DEVICES: {
        NOTES: {
          enterNoteHere: "enterNoteHere"
        },
        CONNECTEDDEVICES: {
          lastUpdatedOn: "lastUpdatedOn",
          lastUpdatedAt: "lastUpdatedAt",
          connectedDevices: "connectedDevices",
          customerDeviceName: "customerDeviceName",
          tnsDeviceName: "tnsDeviceName",
          connectedDeviceName: "connectedDeviceName",
          connectedDeviceNameLength: "connectedDeviceNameLength",
          connectedDeviceNameIsRequired: "connectedDeviceNameIsRequired",
          charactersAllowedAreLettersNumbersSpaceSnakeCaseAndHyphen: "charactersAllowedAreLettersNumbersSpaceSnakeCaseAndHyphen",
          connectedDeviceNameMustNotBeEmptyOrOnlySpaces: "connectedDeviceNameMustNotBeEmptyOrOnlySpaces",
          thisFunctionalityOnlyWorksForVAmodels: "thisFunctionalityOnlyWorksForVAmodels",
          thereAreNoConnectedDevicesToDisplay: "thereAreNoConnectedDevicesToDisplay",
          noDataAvailable: "noDataAvailable",
          theDeviceIsNotCompatibleWithThisFunctionality: "theDeviceIsNotCompatibleWithThisFunctionality",
          theDeviceIsEitherNotCompatibleWithThisFunctionalityOrItIsNotOnline: "theDeviceIsEitherNotCompatibleWithThisFunctionalityOrItIsNotOnline",
          ipAddress: "ipAddress",
          macAddress: "macAddress",
          lanPort: "lanPort",
          acna: "acna",
          action: "action",
          cancel: "cancel",
          save: "save",
          editConnectedDeviceName: "editConnectedDeviceName",
          changesSuccessfullySaved: "changesSuccessfullySaved",
          vlanInformation: "vlanInformation",
          withADeviceStatus: "withADeviceStatus"
        },
        SOFTWARE: {
          softwareVersions: "softwareVersions"
        },
        deviceName: "deviceName",
        logicalVirtualCircuits: "logicalVirtualCircuits",
        devicesWithoutValidatedAddressDetails: "devicesWithoutValidatedAddressDetails",
        TREEMENU: {
          groups: "groups",
          createGroup: "createGroup",
          myGroups: "myGroups",
          groupBy: "groupBy",
          filteredGroups: "filteredGroups",
          resetFilters: "resetFilters",
          deviceGroups: "deviceGroups",
          FILTER: {
            ACNA: "ACNA",
            member: "member",
            country: "country",
            city: "city",
            zipCode: "zipCode",
            TNSServiceType: "TNSServiceType",
            boardingStatus: "boardingStatus"
          }
        },
        DEVICECONFIGURATION: {
          deviceLocation: "deviceLocation",
          general: "general",
          tnsDeviceName: "tnsDeviceName",
          customerDeviceName: "customerDeviceName",
          moreInfo: "moreInfo",
          acna: "acna",
          company: "company",
          companyName: "companyName",
          manufacturer: "manufacturer",
          model: "model",
          operationalStatus: "operationalStatus",
          connectivityStatus: "connectivityStatus",
          latitude: "latitude",
          longitude: "longitude",
          streetAddress: "streetAddress",
          city: "city",
          state: "state",
          zipCode: "zipCode",
          country: "country",
          statusLastChangedOn: "statusLastChangedOn",
          vendor: "vendor",
          deviceLocationStreet: "deviceLocationStreet",
          deviceLocationState: "deviceLocationState",
          deviceLocationCountry: "deviceLocationCountry",
          siteAddress: "siteAddress",
          changeAddress: "changeAddress"
        },
        TOGGLEBUTTONVIEW: {
          mapView: "mapView",
          gridView: "gridView"
        },
        DEVICESTATUS: {
          fullService: "fullService",
          onPrimary: "onPrimary",
          onBackup: "onBackup",
          indeterminate: "indeterminate",
          offline: "offline",
          unknown: "unknown",
          selectAllConnectivityStatus: "selectAllConnectivityStatus"
        },
        DEVICEDETAIL: {
          details: "details",
          noDataAvailableAtThisTime: "noDataAvailableAtThisTime",
          normal: "normal",
          warning: "warning",
          critical: "critical",
          mostRecent: "mostRecent",
          interfaceName: "interfaceName",
          interfaceStatus: "interfaceStatus",
          utilization: "utilization",
          interfaceConfigSpeed: "interfaceConfigSpeed",
          subtitle: "subtitle",
          model: "model",
          vendor: "vendor",
          sim1: "sim1",
          sim2: "sim2",
          iccidNumber: "iccidNumber",
          interfaceSIM: "interfaceSIM",
          wirelessCarrier: "wirelessCarrier",
          connectionStatus: "connectionStatus",
          temperature: "temperature",
          cpuUsage: "cpuUsage",
          latency: "latency",
          uptimeCPE: "uptimeCPE",
          signalStrength: "signalStrength",
          active: "active",
          inactive: "inactive",
          interfaceMetrics: "interfaceMetrics",
          recentAlerts: "recentAlerts",
          noRecentAlerts: "noRecentAlerts",
          deviceDetailsOperationalSince: "deviceDetailsOperationalSince",
          deviceDetailsTNSService: "deviceDetailsTNSService",
          deviceDetailsMonitoringSystem: "deviceDetailsMonitoringSystem",
          deviceDetailsSponsor: "deviceDetailsSponsor",
          deviceDetailsATMManufacturer: "deviceDetailsATMManufacturer",
          deviceDetailsATMModelNumber: "deviceDetailsATMModelNumber",
          deviceDetailsATMProtocol: "deviceDetailsATMProtocol",
          deviceDetailsATMSerialBaudRate: "deviceDetailsATMSerialBaudRate",
          deviceDetailsATMPollAddress: "deviceDetailsATMPollAddress",
          deviceDetailsATMAddress: "deviceDetailsATMAddress",
          deviceDetailsATMHostCharacter: "deviceDetailsATMHostCharacter",
          deviceType: "deviceType",
          serialNumber: "serialNumber",
          deviceDetailsInfoCardTitle: "deviceDetailsInfoCardTitle",
          deviceDetailsPOSATMCardTitle: "deviceDetailsPOSATMCardTitle",
          CUSTOMERDEVICENAME: {
            editCustomerDeviceName: "editCustomerDeviceName",
            customerDeviceNameLength: "customerDeviceNameLength",
            customerDeviceNameAlreadyExists: "customerDeviceNameAlreadyExists"
          },
          TAGS: {
            deviceTags: "deviceTags",
            deviceTagsAvailables: "deviceTagsAvailables",
            ERROR: {
              errorLoadingGroupsForThisDevice: "errorLoadingGroupsForThisDevice"
            },
            SUCCESS: {
              changesSavedSuccesfully: "changesSavedSuccesfully"
            }
          },
          TABS: {
            information: "information",
            informationFilters: "informationFilters",
            uptime: "uptime",
            details: "details",
            cellular: "cellular",
            software: "software",
            usage: "usage",
            lvc: "lvc",
            notes: "notes",
            cellularUsage: "cellularUsage",
            wireless: "wireless",
            membersConnectivity: "membersConnectivity",
            members: "members",
            exportAllMembers: "exportAllMembers",
            devices: "devices",
            contact: "contact",
            hardware: "hardware",
            connections: "connections"
          },
          CONTACT: {
            OnSiteContact: "OnSiteContact",
            Edit: "Edit",
            Name: "Name",
            AddOnSiteContactInformation: "AddOnSiteContactInformation",
            ApplyTheChangesToDataForAllDevicesContainingTheSame: "ApplyTheChangesToDataForAllDevicesContainingTheSame"
          },
          HARDWARE: {
            memory: "memory",
            storage: "storage",
            cpu: "cpu",
            temperature: "temperature",
            mb: "MB",
            kb: "KB",
            used: "used",
            cpuUsed: "cpuUsed",
            storageUsed: "storageUsed",
            memoryUsed: "memoryUsed",
            selectMonth: "selectMonth",
            showLast12Months: "showLast12Months",
            selectDay: "selectDay",
            critical: "critical",
            warning: "warning",
            normal: "normal"
          },
          SIGNALCARD: {
            cellularSignalGraph: "cellularSignalGraph",
            celularSignalDetails: "celularSignalDetails",
            legend: "legend",
            noSignal: "noSignal",
            poor: "poor",
            fair: "fair",
            good: "good",
            excellent: "excellent",
            high: "high",
            low: "low",
            signalStrength: "signalStrength",
            signalStrenghtToday: "signalStrenghtToday",
            INTERVAL: {
              lastHours: "lastHours",
              lastDays: "lastDays",
              lastMonth: "lastMonth"
            },
            SWITCHBUTTONVIEW: {
              default: "default",
              legacy: "legacy",
              dataReport: "dataReport"
            }
          },
          DOUGHNUTCARD: {
            cellularDataUsage: "cellularDataUsage",
            totalBytes: "totalBytes",
            currentPlan: "currentPlan",
            predictedOverageBytes: "predictedOverageBytes",
            currentTitle: "currentTitle",
            rxBytes: "rxBytes",
            txBytes: "txBytes",
            billingCycle: "billingCycle",
            month: "month",
            year: "year",
            week: "week",
            day: "day",
            historicalView: "historicalView",
            currentView: "currentView",
            percentedUsed: "percentedUsed"
          },
          CELLULARSIGNAL: {
            METRICS: {
              excellent: "excellent",
              good: "good",
              fair: "fair",
              poor: "poor",
              noSignal: "noSignal"
            },
            cellularSignalGraph: "cellularSignalGraph",
            lastdaily: "lastdaily",
            lastweekly: "lastweekly",
            lastmonthly: "lastmonthly",
            signalInterval: "signalInterval",
            signalDetailsGraph: "signalDetailsGraph",
            currentPeriod: "currentPeriod",
            thereIsNoData: "thereIsNoData",
            historicalDataPeriod: "historicalDataPeriod",
            dailyDataUsageTracker: "dailyDataUsageTracker",
            megabytesMB: "megabytesMB",
            percentOfDataConsumed: "percentOfDataConsumed",
            deviceDataPlan: "deviceDataPlan",
            MB: "MB",
            totalDataConsumed: "totalDataConsumed",
            percentConsumed: "percentConsumed",
            dataOverage: "dataOverage",
            overagePredicted: "overagePredicted",
            qualitySignalMetric: "qualitySignalMetric",
            MiB: "MiB",
            mebibytesMiB: "mebibytesMiB"
          },
          UPTIME: {
            UPTIMECARD: {
              twelveMonths: "twelveMonths",
              sixMonths: "sixMonths",
              days: "days",
              month: "month",
              serviceUptime: "serviceUptime",
              deviceUptime: "deviceUptime"
            },
            MONTHSLEYEND: {
              january: "january",
              february: "february",
              march: "march",
              april: "april",
              may: "may",
              june: "june",
              july: "july",
              august: "august",
              september: "september",
              october: "october",
              november: "november",
              december: "december"
            },
            USAGESELECTOR: {
              monthYear: "monthYear",
              selectDate: "selectDate",
              january: "january",
              february: "february",
              march: "march",
              april: "april",
              may: "may",
              june: "june",
              july: "july",
              august: "august",
              september: "september",
              october: "october",
              november: "november",
              december: "december",
              switchView: "switchView",
              label: "label"
            },
            LABEL: {
              username: "username",
              password: "password"
            }
          },
          LAN: {
            lan: "lan",
            en: "en",
            pt: "pt",
            es: "es",
            fr: "fr",
            it: "it",
            de: "de",
            ph: "ph",
            nl: "nl"
          },
          DETAIL: {
            HEADER: {
              tnsServiceStatus: "tnsServiceStatus",
              connectivityStatus: "connectivityStatus",
              tnsDeviceName: "tnsDeviceName",
              customerDeviceName: "customerDeviceName",
              company: "company"
            }
          },
          CONTACTINFO: {
            pleaseEnterAValidTelephoneNumber: "pleaseEnterAValidTelephoneNumber",
            pleaseEnterAValidMobilePhoneNumber: "pleaseEnterAValidMobilePhoneNumber",
            nameFieldOnlyAllowsAZcharacters: "nameFieldOnlyAllowsAZcharacters",
            pleaseEnterTheContactsName: "pleaseEnterTheContactsName",
            pleaseEnterTheContactEmailAddress: "pleaseEnterTheContactEmailAddress",
            pleaseEnterTheContactThelephoneNumber: "pleaseEnterTheContactThelephoneNumber",
            pleaseEnterTheContactMobilePhoneNumber: "pleaseEnterTheContactMobilePhoneNumber",
            onSiteContact: "onSiteContact",
            edit: "edit",
            addOnSiteContactInformation: "addOnSiteContactInformation",
            someDevicesAlreadyHaveContactInformation: "someDevicesAlreadyHaveContactInformation",
            areYouSureYouWantToOverwriteIt: "areYouSureYouWantToOverwriteIt",
            applyTheChangesToDataForOllDevicesContainingTheSame: "applyTheChangesToDataForOllDevicesContainingTheSame",
            name: "name",
            emailContact: "emailContact",
            telephone: "telephone",
            mobilePhoneNumber: "mobilePhoneNumber",
            onlyInThisDevices: "onlyInThisDevices",
            address: "address",
            city: "city",
            country: "country",
            nameIsMandatory: "nameIsMandatory",
            emailIsMandatory: "emailIsMandatory",
            telephoneIsMandatory: "telephoneIsMandatory",
            mobilePhoneNumberIsMandatory: "mobilePhoneNumberIsMandatory",
            pleaseEnterAValidEmailAddress: "pleaseEnterAValidEmailAddress",
            nameMustBeAtLeast5AndUpTo60Characters: "nameMustBeAtLeast5AndUpTo60Characters",
            emailMustBeAtLeast5AndUpTo100Characters: "emailMustBeAtLeast5AndUpTo100Characters",
            telephoneMustBeAtLeast5AndUpTo15Numbers: "telephoneMustBeAtLeast5AndUpTo15Numbers",
            mobilePhoneNumberMustBeAtLeast5AndUpTo15Numbers: "mobilePhoneNumberMustBeAtLeast5AndUpTo15Numbers"
          }
        },
        GROUPING: {
          deviceGroups: "deviceGroups",
          groups: "groups",
          addGroup: "addGroup",
          createGroup: "createGroup",
          DETAILS: {
            noDataAvailableAtThisTime: "noDataAvailableAtThisTime",
            groupsDetails: "groupsDetails",
            availableGroups: "availableGroups",
            allGroups: "allGroups",
            myGroups: "myGroups",
            devices: "devices",
            associatedDevices: "associatedDevices",
            associatedDevicesToGroup: "associatedDevicesToGroup",
            deleteGroup: "deleteGroup",
            areYouSureYouWantToDelete: "areYouSureYouWantToDelete",
            editGroup: "editGroup",
            pleaseSelectedGroupToViewTheAssociatedDevices: "pleaseSelectedGroupToViewTheAssociatedDevices",
            no: "no",
            yes: "yes"
          },
          CREATE: {
            groupName: "groupName",
            groupNameIsRequired: "groupNameIsRequired",
            thisNameIsAlreadyTakenPleaseChooseAnother: "thisNameIsAlreadyTakenPleaseChooseAnother",
            tagColorGroup: "tagColorGroup",
            next: "next",
            availableDevices: "availableDevices",
            selectedDevices: "selectedDevices",
            groupCreatedSuccessfully: "groupCreatedSuccessfully"
          },
          EDIT: {
            groupEditedSuccessfully: "groupEditedSuccessfully",
            tagColorGroup: "tagColorGroup",
            next: "next",
            cancel: "cancel",
            save: "save",
            availableDevices: "availableDevices",
            selectedDevices: "selectedDevices"
          },
          DELETE: {
            groupDeletedSuccessfully: "groupDeletedSuccessfully"
          }
        }
      }
    }
  },
  PROFILE: {
    myAccount: "myAccount",
    myProfile: "myProfile",
    THEME: {
      dark: "dark",
      light: "light"
    },
    LNG: {
      en: "en",
      es: "es",
      por: "pt"
    }
  },
  TNSDEVICES: {
    showing: "showing",
    from: "from",
    of: "of",
    records: "records",
    data: "data",
    FILTERS: {
      selectCategories: "selectCategories",
      entityProfile: "entityProfile",
      boardingStatus: "boardingStatus",
      isWireless: "isWireless",
      serviceDescription: "serviceDescription",
      manufacturer: "manufacturerCPE",
      model: "model",
      countries: "country",
      state: "state",
      city: "city",
      zipCode: "zipCode",
      cleanFilters: "cleanFilters"
    }
  },
  ERROR: {
    weAreSorry: "weAreSorry",
    pageRestricted: "pageRestricted",
    pageNotFound: "pageNotFound",
    goToLogin: "goToLogin",
    accountDisabled: "accountDisabled",
    weLookedEverywhereForThisPage: "weLookedEverywhereForThisPage",
    areYouSureTheWebsiteURLIsCorrect: "areYouSureTheWebsiteURLIsCorrect",
    getInTouchWithTheSiteOwner: "getInTouchWithTheSiteOwner",
    thisaccounthasbeenlocked: "thisaccounthasbeenlocked",
    noInformationAvailable: "noInformationAvailable",
    notAvailableMessage: "notAvailableMessage",
    errorLoadingUptimeMonthlyData: "errorLoadingUptimeMonthlyData",
    errorLoadingUsers: "errorLoadingUsers",
    errorCheckingEmail: "errorCheckingEmail",
    errorCheckingUsername: "errorCheckingUsername",
    errorSendingEmail: "errorSendingEmail",
    upsSomethingWentWrong: "upsSomethingWentWrong",
    errorResettingPassword: "errorResettingPassword",
    requestIsInvalid: "requestIsInvalid",
    requestedResourceCouldNotBeFound: "requestedResourceCouldNotBeFound",
    serverIsNotResponding: "serverIsNotResponding",
    yourAccountIsLocked: "yourAccountIsLocked",
    yourAccountIsExpired: "yourAccountIsExpired",
    incorrectUsernameAndOrPassword: "incorrectUsernameAndOrPassword",
    passwordMustNotMatch: "passwordMustNotMatch",
    passwordMustBe12CharactersLong: "passwordMustBe12CharactersLong",
    thePasswordMustContain: "thePasswordMustContain",
    passwordCannotContainUsername: "passwordCannotContainUsername",
    resetPasswordTokenInvalid: "resetPasswordTokenInvalid",
    errorSigningIn: "errorSigningIn",
    errorStoppingActAsUser: "errorStoppingActAsUser",
    errorLoadingDeviceDetail: "errorLoadingDeviceDetail",
    invalidAddress: "invalidAddress",
    requiredField: "requiredField",
    addressTooLong: "addressTooLong",
    errorUserNotOnboarded: "errorUserNotOnboarded",
    credentialsExpired: "credentialsExpired",
    somethingWentWrongTryAgainLater: "somethingWentWrongTryAgainLater"
  },
  MODAL: {
    RELEASE: {
      releaseModalTitle: "releaseModalTitle",
      releaseModalCheckbox: "releaseModalCheckbox",
      releaseModalButton: "releaseModalButton",
      releaseModalDescription: "releaseModalDescription",
      releaseModalEntityProfileTitle: "releaseModalEntityProfileTitle",
      releaseModalEntityProfileDescription: "releaseModalEntityProfileDescription",
      releaseModalCustomRolesTitle: "releaseModalCustomRolesTitle",
      releaseModalCustomRolesDescription: "releaseModalCustomRolesDescription",
      releaseModalEnding: "releaseModalEnding",
      releaseModalTNSO: "releaseModalTNSO"
    },
    loadingDataToExport: "loadingDataToExport",
    ALERT: {
      draggingFromTableWithFilterActive: "draggingFromTableWithFilterActive",
      thisDevicesAlreadyBelongToGroup: "thisDevicesAlreadyBelongToGroup",
      draggingBetweenGroups: "draggingBetweenGroups",
      youAreImpersonatedAs: "youAreImpersonatedAs",
      somethingWentWrong: "somethingWentWrong",
      coouldNotConnect: "somethingWentWrong",
      sessionExpired: "somethingWentWrong",
      loginSuccess: "loginSuccess",
      stopActAs: "stopActAs",
      successUpdate: "successUpdate",
      errorCreating: "errorCreating",
      errorLoading: "errorLoading",
      errorLoadingCompanyProfiles: "errorLoadingCompanyProfiles",
      companyProfileCreatedSuccessfully: "companyProfileCreatedSuccessfully",
      errorCreatingCompanyProfile: "errorCreatingCompanyProfile",
      companyProfileUpdatedSuccessfully: "companyProfileUpdatedSuccessfully",
      errorUpdatingCompanyProfile: "errorUpdatingCompanyProfile",
      companyProfileDeletedSuccessfully: "companyProfileDeletedSuccessfully",
      errorDeletingCompanyProfile: "errorDeletingCompanyProfile",
      errorCheckingCompanyProfileName: "errorCheckingCompanyProfileName",
      passwordResetSuccessfully: "passwordResetSuccessfully",
      passwordTockenHasBeenExpired: "passwordTockenHasBeenExpired",
      errorActingAsUser: "errorActingAsUser",
      successUpdateDeviceAddress: "successUpdateDeviceAddress",
      switchingUserRole: "switchingUserRole",
      switching: "switching",
      changesSaved: "changesSaved",
      changesSuccessfullySaved: "changesSuccessfullySaved",
      contactUpdatedSuccess: "contactUpdatedSuccess"
    },
    preparingDocument: "preparingDocument",
    loadingSheet: "loadingSheet",
    totalSheets: "totalSheets"
  },
  TOOLTIP: {
    export: "export",
    exportRawData: "exportRawData",
    exportSignalReport: "exportSignalReport",
    resetMap: "resetMap",
    resetZoomAndCenterMap: "resetZoomAndCenterMap",
    nextPage: "nextPage",
    prevPage: "prevPage"
  },
  SHARED: {
    contactUs: "contactUs",
    username: "username",
    firstName: "firstName",
    email: "email",
    goTo: "goTo",
    goBack: "goBack",
    status: "status",
    emailUser: "emailUser",
    general: "general",
    search: "search",
    create: "create",
    cancel: "cancel",
    delete: "delete",
    canBeDeleted: "delete",
    accept: "accept",
    next: "next",
    placeholderNote: "placeholderNote",
    placeholder: "placeholder",
    update: "update",
    date: "date",
    name: "name",
    telephone: "telephone",
    mobilePhoneNumber: "mobilePhoneNumber",
    BUTTON: {
      search: "search",
      create: "create",
      cancel: "cancel",
      delete: "delete",
      accept: "accept",
      save: "save",
      next: "next",
      update: "update",
      remove: "remove",
      done: "done"
    },
    TABLE: {
      records: "records",
      of: "of",
      ticketId: "ticketId",
      deviceName: "deviceName",
      groupName: "groupName",
      addToMap: "addToMap",
      companyProfileName: "companyProfileName",
      entityProfileName: "entityProfileName",
      note: "note",
      actAs: "actAs",
      canBeDeleted: "canBeDeleted",
      companyProfile: "companyProfile",
      profile: "profile",
      entityProfile: "entityProfile",
      companyProfileAdministration: "companyProfileAdministration",
      companyProfileAdministrationTable: "companyProfileAdministrationTable",
      availableAcna: "availableAcna",
      associateAcna: "associateAcna",
      action: "action",
      startDate: "startDate",
      duration: "duration",
      signalIndicator: "signalIndicator",
      technology: "technology",
      tnsDeviceName: "tnsDeviceName",
      createTime: "createTime",
      updateTime: "updateTime",
      customerDeviceName: "customerDeviceName",
      companyName: "companyName",
      knownAs: "knownAs",
      serviceStatus: "serviceStatus",
      connectivityStatus: "connectivityStatus",
      operationalStatus: "operationalStatus",
      statusTieBreaker: "statusTieBreaker",
      acna: "acna",
      changeStartTime: "changeStartTime",
      changeTicketId: "changeTicketId",
      changeDescription: "changeDescription",
      impactType: "impactType",
      statusOfChange: "statusOfChange",
      maximumDuration: "maximumDuration",
      changeDetails: "changeDetails",
      changeTicket: "changeTicket",
      type: "type",
      hrs: "hrs",
      reasonForChange: "reasonForChange",
      changesPlanned: "changesPlanned",
      expectedServiceImpactDuringChange: "expectedServiceImpactDuringChange",
      detailsOfDevicesEffectedByChange: "detailsOfDevicesEffectedByChange",
      changeTickets: "changeTickets",
      startDateTime: "startDateTime",
      maximumDurationHrs: "maximumDurationHrs",
      merchantName: "merchantName",
      customerSiteName: "customerSiteName",
      service: "service",
      hasGeolocation: "hasGeolocation",
      region: "region",
      source: "source",
      ticket: "ticket",
      groupId: "groupId",
      lvcId: "lvcId",
      salesStatus: "salesStatus",
      ep1Host: "ep1Host",
      ep2Host: "ep2Host",
      ep1RealIp: "ep1RealIp",
      ep2RealIp: "ep2RealIp",
      knowsEp2As: "knowsEp2As",
      knowsEp1As: "knowsEp1As",
      ep1Acna: "ep1Acna",
      ep2Acna: "ep2Acna",
      dateOperational: "dateOperational",
      ep1DeviceName: "ep1DeviceName",
      lvcTicketNumber: "lvcTicketNumber",
      status: "status",
      ep2DeviceName: "ep2DeviceName",
      ep1CustomerSiteId: "ep1CustomerSiteId",
      ep2CustomerSiteId: "ep2CustomerSiteId",
      ep1Customer: "ep1Customer",
      ep2Customer: "ep2Customer",
      ep1KnownEp2: "ep1KnownEp2",
      ep2KnownEp1: "ep2KnownEp1",
      division: "division",
      divisionType: "divisionType",
      ep1_real_ip: "ep1_real_ip",
      ep2_real_ip: "ep2_real_ip",
      company: "company",
      country: "country",
      devices: "devices",
      software: "software",
      deviceVersion: "deviceVersion",
      currentVersion: "currentVersion",
      billingPeriod: "billingPeriod",
      rx: "rx",
      tx: "tx",
      overage: "overage",
      consumed: "consumed",
      predicted: "predicted",
      predictedOverage: "predictedOverage",
      percentage: "percentage",
      bandwith: "bandwith",
      total: "total",
      endDate: "endDate",
      rbBytes: "rbBytes",
      totalBytes: "totalBytes",
      currentPlan: "currentPlan",
      noDataFound: "noDataFound",
      planSize: "planSize",
      txcnt: "txcnt",
      rxcnt: "rxcnt",
      percent: "percent",
      dataRemaining: "dataRemaining",
      totalReceivedBytes: "totalReceivedBytes",
      totalTransmittedBytes: "totalTransmittedBytes",
      TNSTicket: "TNSTicket",
      Operational: "Operational",
      Disconnected: "Disconnected",
      CountDeviceName: "CountDeviceName",
      username: "username",
      firstName: "firstName",
      emailAddress: "emailAddress",
      lastName: "lastName",
      userRole: "userRole",
      carrier: "carrier",
      groups: "groups",
      totalData: "totalData"
    },
    DATATABLE: {
      approved: "approved",
      Cancelled: "Cancelled",
      delete: "delete",
      actAs: "actAs",
      yes: "yes",
      no: "no",
      all: "all",
      true: "yes",
      false: "no",
      Configured: "Configured",
      Operational: "Operational",
      Disconnected: "Disconnected",
      Test: "Test",
      Implementation: "Implementation",
      noDataAvailableAtThisTime: "noDataAvailableAtThisTime",
      noDataAvailable: "noDataAvailable",
      "Change Request": "Change Request",
      "Notify Customer": "Notify Customer",
      "Customer Hold": "Customer Hold",
      "Disco Req": "Disco Req",
      planSize: "planSize",
      txcnt: "rxcnt",
      "Return to Sales": "Return to Sales",
      "Pending Device Installation": "Pending Device Installation",
      "New/Open": "New/Open",
      Standard: "Standard",
      Emergency: "Emergency",
      "3rd Party": "3rd Party",
      Testing: "Testing",
      Installed: "Installed",
      Shipped: "Shipped",
      Hold: "Hold",
      CustomerHold: "CustomerHold",
      Disabled: "Disabled",
      onPrimary: "onPrimary",
      onBackup: "onBackup",
      offline: "offline",
      indeterminate: "indeterminate",
      unknown: "unknown",
      Failed: "Failed",
      Success: "success",
      Completed: "Completed",
      InProgress: "InProgress",
      Scheduled: "Scheduled",
      Pending: "Pending",
      Rejected: "Rejected",
      Requested: "Requested",
      Minor: "Minor",
      Major: "Major",
      Critical: "Critical",
      enabled: "enabled",
      disabled: "disabled",
      Locked: "Locked",
      Expired: "Expired",
      Admin: "Admin",
      Basic: "Basic",
      SuperUser: "SuperUser",
      ADMIN: "ADMIN",
      BASIC: "BASIC",
      SUPERUSER: "SUPERUSER",
      Maintenance: "Maintenance",
      Incident: "Incident",
      Escalation: "Escalation",
      contactDeleteConfirmation: "contactDeleteConfirmation",
      Billing: "Billing",
      Business: "Business",
      Facility: "Facility",
      Other: "Other",
      more: "more"
    },
    USERPROFILE: {
      superUserHasAccess: "superUserHasAccess",
      thereAreNoAssociatedCompanies: "thereAreNoAssociatedCompanies",
      username: "username",
      firstName: "firstName",
      lastName: "lastName",
      email: "email",
      enabled: "enabled",
      disabled: "disabled",
      true: "enabled",
      false: "disabled",
      role: "userRole",
      actAs: "actAs",
      status: "status",
      associateCompanies: "associateCompanies",
      companyProfileName: "companyProfileName",
      name: "companyProfileName",
      thereAreNoAssociatedEntities: "thereAreNoAssociatedEntities",
      associateEntities: "associateEntities",
      userMustChangePassword: "userMustChangePassword"
    }
  },
  EXPORT: {
    exportButton: "exportButton",
    historicalData: "historicalData",
    rawDataReport: "rawDataReport",
    dataReportExport: "dataReportExport",
    signalReport: "signalReport",
    cellularSignalExport: "cellularSignalExport",
    cellularUsageHistoricalData: "cellularUsageHistoricalData"
  }
};
