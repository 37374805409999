import React, { ReactNode, useContext, useState } from "react";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { ApiCore } from "@tnso/api-core";
import { TNSOSearchGrid } from "../../../../../portal-app/components/shared/table/SearchFilter";
import { TNSOSelectFilter } from "../../../../../portal-app/components/shared/table/SelectFilter";
import { TablePaginationConfig } from "antd/lib";
import { SorterResult } from "antd/es/table/interface";
import { SorterHelper } from "../../../../helpers/shared/SorterHelper";
import { DirectionType, FirewallRulesContext, GetFirewallRulesResponse, StatusType } from "../../../../context/firawall-rules/firewall-rules-context";
import { useParams } from "react-router-dom";
import { TRANSLATION } from "src/app/portal-app/utils/const/translation";
import { useTranslation } from "react-i18next";

export interface Columns extends ColumnsType {
  dataIndex: string;
}

interface UseTableConfigReturn {
  columns: { firewallRules: ColumnsType[] };
  firewallRulesData: GetFirewallRulesResponse[];
  firewallRulesDataPaginated: GetFirewallRulesResponse[];
  filteredFirewallRulesData: GetFirewallRulesResponse[];
  handleSort: (pagination: TablePaginationConfig, _: Record<string, React.ReactText[] | null>, sorter: SorterResult<GetFirewallRulesResponse>) => Promise<void>;
  currentPage: number;
  handleChangePage: (page: number) => Promise<void>;
  filters: GetFirewallRulesResponse;
  handleLoadData: (filters?: GetFirewallRulesResponse) => Promise<void>;
  skeletonColumns: unknown[];
}

const PAGE_SIZE = 10;

export const useTableConfig = (): UseTableConfigReturn => {
  const [filters, setFilters] = useState<GetFirewallRulesResponse>({});
  const [firewallRulesData, setFirewallRulesData] = useState<GetFirewallRulesResponse[]>([]);
  const [firewallRulesDataPaginated, setFirewallRulesDataPaginated] = useState<GetFirewallRulesResponse[]>([]);
  const [filteredFirewallRulesData, setFilteredFirewallRules] = useState<GetFirewallRulesResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { sortValues } = SorterHelper();
  const context = useContext(FirewallRulesContext);
  const { deviceName } = useParams();
  const { t } = useTranslation();

  // eslint-disable-next-line
  const getColumnSearchProps = (dataIndex: string, filterType?: "search" | "select", icon?: ReactNode, options?: any[]): any => ({
    // eslint-disable-next-line
    filterDropdown: (props: any) =>
      filterType === "search" ? (
        <TNSOSearchGrid
          themeSelected="dark"
          {...props}
          onSearch={handleSearch}
          keyFilter={dataIndex}
          onReset={(): Promise<void> => handleSearch("all", dataIndex)}
          close={props.close}
        />
      ) : (
        <TNSOSelectFilter
          displayValues={true}
          options={options ?? []}
          onSearch={(value: string): Promise<void> => handleSearch(value, dataIndex)}
          defaultValue={[]}
          keyFilter={dataIndex}
          close={props.close}
        />
      ),
    filterIcon: () => icon ?? <SearchOutlined />,
    onFilter: (value: string) => handleSearch(value, dataIndex),
    onSort: () => console.log("onSort")
  });

  const firewallRules = [
    {
      dataIndex: "tnsDeviceName",
      title: "deviceName",
      key: "tnsDeviceName",
      sorter: true,
      ...getColumnSearchProps("tnsDeviceName", "search"),
      render: (cell: string, row: GetFirewallRulesResponse): string => (row.tnsDeviceName ? row.tnsDeviceName : "N/A"),
      width: 150
    },
    {
      dataIndex: "lvcTicketNumber",
      title: "lvcTicketNumber",
      key: "lvcTicketNumber",
      sorter: true,
      ...getColumnSearchProps("lvcTicketNumber", "search"),
      render: (cell: string, row: GetFirewallRulesResponse): string => (row.lvcTicketNumber ? row.lvcTicketNumber : "N/A"),
      width: 190
    },
    {
      dataIndex: "lvcId",
      title: "lvcId",
      key: "lvcId",
      sorter: true,
      ...getColumnSearchProps("lvcId", "search"),
      render: (cell: string, row: GetFirewallRulesResponse): string => (row.lvcId ? row.lvcId : "N/A"),
      width: 120
    },
    {
      dataIndex: "ep1ConnectedTo",
      title: "ep1ConnectedTo",
      key: "ep1ConnectedTo",
      sorter: true,
      ...getColumnSearchProps("ep1ConnectedTo", "search"),
      render: (cell: string, row: GetFirewallRulesResponse): string => (row.ep1ConnectedTo ? row.ep1ConnectedTo : "N/A"),
      width: 190
    },
    {
      dataIndex: "ep1RealIpStored",
      title: "ep1RealIpStored",
      key: "ep1RealIpStored",
      sorter: true,
      ...getColumnSearchProps("ep1RealIpStored", "search"),
      render: (cell: string, row: GetFirewallRulesResponse): string => (row.ep1RealIpStored ? row.ep1RealIpStored : "N/A"),
      width: 190
    },
    {
      dataIndex: "ep1Knowsep2As",
      title: "ep1Knowsep2As",
      key: "ep1Knowsep2As",
      sorter: true,
      ...getColumnSearchProps("ep1Knowsep2As", "search"),
      render: (cell: string, row: GetFirewallRulesResponse): string => (row.ep1Knowsep2As ? row.ep1Knowsep2As : "N/A"),
      width: 200
    },
    {
      dataIndex: "ep2ConnectedTo",
      title: "ep2ConnectedTo",
      key: "ep2ConnectedTo",
      sorter: true,
      ...getColumnSearchProps("ep2ConnectedTo", "search"),
      render: (cell: string, row: GetFirewallRulesResponse): string => (row.ep2ConnectedTo ? row.ep2ConnectedTo : "N/A"),
      width: 200
    },
    {
      dataIndex: "ep2RealIpStored",
      title: "ep2RealIpStored",
      key: "ep2RealIpStored",
      sorter: true,
      ...getColumnSearchProps("ep2RealIpStored", "search"),
      render: (cell: string, row: GetFirewallRulesResponse): string => (row.ep2RealIpStored ? row.ep2RealIpStored : "N/A"),
      width: 200
    },
    {
      dataIndex: "status",
      title: "status",
      key: "status",
      ...getColumnSearchProps(
        "status",
        "select",
        // eslint-disable-next-line react/react-in-jsx-scope
        <FilterFilled />,
        Object.values(StatusType).map((type) => {
          return {
            value: type,
            label: t(
              TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.FIREWALLRULES[type as unknown as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.FIREWALLRULES]
            )
          };
        })
      ),
      render: (cell: string, row: GetFirewallRulesResponse): string => (row.status ? row.status : "N/A"),
      width: 100
    },
    {
      dataIndex: "direction",
      title: "direction",
      key: "direction",
      ...getColumnSearchProps(
        "direction",
        "select",
        // eslint-disable-next-line react/react-in-jsx-scope
        <FilterFilled />,
        Object.values(DirectionType).map((type) => {
          return {
            value: type,
            label: t(
              TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.FIREWALLRULES[type as unknown as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.FIREWALLRULES]
            )
          };
        })
      ),
      render: (cell: string, row: GetFirewallRulesResponse): string => (row.direction ? row.direction : "N/A"),
      width: 130
    },
    {
      dataIndex: "hitCount",
      title: "hitCount",
      key: "hitCount",
      sorter: true,
      render: (cell: string, row: GetFirewallRulesResponse): string => (row.hitCount ? row.hitCount : "N/A"),
      width: 130
    },
    {
      dataIndex: "customerSystemNameEp1",
      title: "customerSystemNameEp1",
      key: "customerSystemNameEp1",
      sorter: true,
      ...getColumnSearchProps("customerSystemNameEp1", "search"),
      render: (cell: string, row: GetFirewallRulesResponse): string => (row.customerSystemNameEp1 ? row.customerSystemNameEp1 : "N/A"),
      width: 270
    },
    {
      dataIndex: "customerSystemNameEp2",
      title: "customerSystemNameEp2",
      key: "customerSystemNameEp2",
      sorter: true,
      ...getColumnSearchProps("customerSystemNameEp2", "search"),
      render: (cell: string, row: GetFirewallRulesResponse): string => (row.customerSystemNameEp2 ? row.customerSystemNameEp2 : "N/A"),
      width: 270
    }
  ];

  const columns = {
    firewallRules
  };

  const skeletonColumns = firewallRules.map(({ dataIndex, title, key }) => ({
    dataIndex,
    title,
    key
  }));

  const handleLoadData = async (): Promise<void> => {
    if (deviceName) {
      const response = await context.getFirewallRules(deviceName);
      if (response) {
        setFirewallRulesData(response);
        setFilteredFirewallRules(response);
        setFirewallRulesDataPaginated(response?.slice(0, PAGE_SIZE));
      }
    }
  };

  type FirewallRulesFilters = Partial<Record<keyof GetFirewallRulesResponse, string>>;

  const handleFilter = (filters: FirewallRulesFilters): void => {
    setCurrentPage(1);
    const filteredData = firewallRulesData.filter((item) => {
      return Object.entries(filters).every(([key, filterValue]) => {
        if (!filterValue) {
          return true;
        }
        const itemValue = item[key as keyof GetFirewallRulesResponse];
        if (itemValue === undefined) {
          return false;
        }

        return itemValue?.toString().toLowerCase().includes(filterValue.toLowerCase());
      });
    });

    setFilteredFirewallRules(filteredData);
    const paginatedData = filteredData.slice(0, PAGE_SIZE);

    setFirewallRulesDataPaginated(paginatedData);
  };

  const handleChangePage = async (page: number): Promise<void> => {
    setFirewallRulesDataPaginated(filteredFirewallRulesData.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE));
    setCurrentPage(page);
  };

  const handleSort = async (
    pagination: TablePaginationConfig,
    _: Record<string, React.ReactText[] | null>,
    sorter: SorterResult<GetFirewallRulesResponse> | SorterResult<GetFirewallRulesResponse>[]
  ): Promise<void> => {
    setCurrentPage(1);

    const sortedData = sortValues(filteredFirewallRulesData, sorter);
    setFilteredFirewallRules(sortedData);
    const paginatedData = sortedData.slice(0, PAGE_SIZE);
    setFirewallRulesDataPaginated(paginatedData);
  };

  const handleSearch = async (filter: string, keyFilter: string): Promise<void> => {
    if (filter === "all") {
      filter = "";
    }
    const params = ApiCore.builderQueryParams({ tableFilters: { [keyFilter]: filter } }, filters);
    delete params.startAtRecord;
    delete params.recordsPerPage;

    setFilters(params);
    handleFilter(params);
  };

  return {
    columns,
    firewallRulesData,
    firewallRulesDataPaginated,
    filteredFirewallRulesData,
    handleSort,
    currentPage,
    handleChangePage,
    filters,
    handleLoadData,
    skeletonColumns
  };
};
