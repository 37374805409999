import React, { useState } from "react";
import { CreateGroup } from "../../../components/device/device-grouping/create-device-group";
import { DeviceGroupTransferList } from "src/app/portal-app/components/device/device-grouping/device-group-transfer-list";
import { useParams } from "react-router-dom";
import { TNSDivider } from "@tns/ui-components";

export function EditDeviceGrouping(): JSX.Element {
  const params = useParams();
  const [groupName, setGroupName] = useState<string>(params.groupName ?? "");
  const [groupColor, setGroupColor] = useState<string>(params.groupColor ?? "#ffffff");

  return (
    <div>
      <CreateGroup secondStep={false} setGroupName={setGroupName} setGroupColor={setGroupColor} groupName={groupName} groupColor={groupColor} />
      <div className="d-flex flex-column gap-3">
        <TNSDivider type="horizontal" />
        <DeviceGroupTransferList groupName={groupName} groupColor={groupColor} transferListEnable={true} />
      </div>
    </div>
  );
}
