import { ScaleSize } from "@tnso/ui-components";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { TRANSLATION } from "../../../translations/translation";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { IncidentManagementContext, Ticket } from "src/app/administration/context/incident-management/incident-management-context";
import dayjs, { Dayjs } from "dayjs";
import { TNSButton, TNSSelect, Variants, TNSTextArea, TNSContainer, GapSizes, TNSDatePicker, TNSDrawer, TNSTooltip, Themes } from "@tns/ui-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useAsyncCall } from "src/hooks/useAsyncCallShared";
import "./incident-management.scss";
import { CustomizerContext } from "src/contexts/customizer/CustomizerContext";

export interface IncidentManagementCreateDrawerProps {
  isDrawerOpen: boolean;
  onConfirm: () => void;
  onCloseDrawer: () => void;
}

export const IncidentManagementCreateDrawer: React.FC<IncidentManagementCreateDrawerProps> = ({ isDrawerOpen, onConfirm, onCloseDrawer }) => {
  const { t } = useTranslation();
  const context = useContext(IncidentManagementContext);
  const { selectedTheme } = useContext(CustomizerContext);
  const [acnas, setAcnas] = useState<string[]>([]);
  const [devices, setDevices] = useState<string[]>([]);

  const initialValues: Ticket = useMemo(() => {
    return {
      description: "",
      startDate: undefined,
      acna: undefined,
      tnsDeviceName: undefined
    };
  }, []);

  const {
    control,
    getValues,
    reset,
    watch,
    setValue,
    formState: { errors, isValid }
  } = useForm<Ticket>({
    defaultValues: initialValues,
    mode: "all"
  });

  const acna = watch("acna");

  const handleLoadAcnas = useCallback((): void => {
    const acnasList = context.getAcna();
    if (acnasList) {
      setAcnas(acnasList);
    }
  }, [context]);

  useAsyncCall(async (): Promise<void> => {
    if (acna) {
      const response = await context.getDevices({ acna });
      if (response) {
        const devices = response.map((device) => String(device.tnsDeviceName)) ?? [];
        setDevices(devices);
      }
    }
  }, [acna, context]);

  useAsyncCall(async () => {
    if (isDrawerOpen) {
      handleLoadAcnas();
    }
  }, [handleLoadAcnas, isDrawerOpen]);

  const closeDrawer = useCallback((): void => {
    onCloseDrawer();
    reset(initialValues);
    setDevices([]);
  }, [reset, initialValues, onCloseDrawer]);

  const submit = useCallback(async (): Promise<void> => {
    const newTicket = getValues();
    if (newTicket.acna && newTicket.description && newTicket.startDate) {
      const response = await context.createTicket({
        acna: newTicket.acna,
        description: newTicket.description,
        startDate: newTicket.startDate,
        tnsDeviceName: newTicket.tnsDeviceName ?? ""
      });
      if (response) {
        onConfirm();
        closeDrawer();
      }
    }
  }, [getValues, closeDrawer, onConfirm, context]);

  const disabledDate = useCallback((current: Dayjs) => current && current.isAfter(dayjs().endOf("day")), []);

  const disabledTime = useCallback((current: Dayjs | null) => {
    const now = dayjs();

    if (!current || !current.isSame(now, "day")) {
      return {};
    }

    const currentHour = current.hour();

    return {
      disabledHours: () => Array.from({ length: 24 }, (_, hourIndex) => (hourIndex > now.hour() ? hourIndex : null)).filter((hour) => hour !== null),

      disabledMinutes: (): number[] => {
        // If the selected hour is earlier than the current hour, enable all minutes
        if (currentHour < now.hour()) {
          return [];
        }

        // Disable minutes only if the selected hour matches the current hour
        return Array.from({ length: 60 }, (_, minuteIndex) => (minuteIndex > now.minute() ? minuteIndex : null)).filter((minute) => minute !== null);
      }
    };
  }, []);

  // TODO: review this and that the icon takes the colors of the theme well
  const iconColor = useMemo(() => {
    return selectedTheme === Themes.DARK ? undefined : "#12121273";
  }, [selectedTheme]);

  return (
    <TNSDrawer open={isDrawerOpen} onClose={closeDrawer} title={t(TRANSLATION.INCIDENTMANAGEMENT.createIncidentTicket)} sizeType={ScaleSize.sm}>
      <TNSContainer gap={GapSizes.sm} className="containerColums">
        <div className="d-flex gap-1 align-items-center">
          <FontAwesomeIcon icon={faAsterisk} size="2xs" color="red" className="mb-1" />
          <strong>{t(TRANSLATION.INCIDENTMANAGEMENT.incidentStartTime)}</strong>
        </div>
        <Controller
          name="startDate"
          control={control}
          rules={{ required: true }}
          render={({ field }): JSX.Element => (
            <TNSDatePicker
              showTime={{ format: "HH:mm" }}
              format="MM/DD/YYYY HH:mm"
              onChange={(date): void => {
                field.onChange(date ? Math.floor(date.toDate().getTime() / 1000) : null);
              }}
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              value={field.value ? dayjs(field.value * 1000) : null}
              onBlur={field.onBlur}
              className="w-100"
              placeholder={t(TRANSLATION.INCIDENTMANAGEMENT.pleaseSelectDate)}
              status={errors.startDate ? "error" : ""}
            />
          )}
        />
        <div>{errors.startDate?.type === "required" && <label className="incident-management-error-message">{t(TRANSLATION.INCIDENTMANAGEMENT.ERROR.required)}</label>}</div>
        <div className="d-flex gap-1 align-items-center">
          <FontAwesomeIcon icon={faAsterisk} size="2xs" color="red" className="mb-1" />
          <strong>ACNA</strong>
        </div>
        <Controller
          name="acna"
          control={control}
          rules={{ required: true }}
          render={({ field }): JSX.Element => (
            <TNSSelect
              {...field}
              className="w-100"
              placeholder={t(TRANSLATION.INCIDENTMANAGEMENT.completeWithAcna)}
              options={acnas?.map((acna): { label: string; value: string } => ({ label: acna, value: acna }))}
              onChange={(value): void => {
                setValue("tnsDeviceName", undefined);
                field.onChange(value);
              }}
              status={errors.acna ? "error" : ""}
            />
          )}
        />
        <div>{errors.acna?.type === "required" && <label className="incident-management-error-message">{t(TRANSLATION.INCIDENTMANAGEMENT.ERROR.required)}</label>}</div>
        <div className="d-flex gap-2 align-items-center">
          <strong>{t(TRANSLATION.INCIDENTMANAGEMENT.device)}</strong>
          <TNSTooltip title={t(TRANSLATION.INCIDENTMANAGEMENT.youCanOnlyPickOneDeviceAndItMustBeOnStatusOperational)} overlayStyle={{ lineHeight: "unset" }} placement="bottom">
            <FontAwesomeIcon icon={faInfoCircle} style={{ color: iconColor }} />
          </TNSTooltip>
        </div>
        <Controller
          name="tnsDeviceName"
          control={control}
          render={({ field }): JSX.Element => (
            <TNSSelect
              {...field}
              className="w-100"
              placeholder={t(TRANSLATION.INCIDENTMANAGEMENT.selectDeviceName)}
              options={devices?.map((device): { label: string; value: string } => ({ label: device, value: device }))}
              onChange={(value): void => {
                field.onChange(value);
              }}
            />
          )}
        />
        <div className="d-flex gap-1 align-items-center">
          <FontAwesomeIcon icon={faAsterisk} size="2xs" color="red" className="mb-1" />
          <strong>{t(TRANSLATION.INCIDENTMANAGEMENT.description)}</strong>
        </div>
        <Controller
          name="description"
          control={control}
          rules={{ required: true, minLength: 30, maxLength: 160 }}
          render={({ field }): JSX.Element => (
            <TNSTextArea
              {...field}
              rows={8}
              showCount
              maxLength={160}
              placeholder={t(TRANSLATION.INCIDENTMANAGEMENT.pleaseDescribeTheProblem)}
              status={errors.description ? "error" : ""}
            />
          )}
        />
        <div>
          {errors.description?.type === "required" && <label className="incident-management-error-message">{t(TRANSLATION.INCIDENTMANAGEMENT.ERROR.required)}</label>}
          {errors.description?.type === "minLength" && <label className="incident-management-error-message">{t(TRANSLATION.INCIDENTMANAGEMENT.ERROR.min30Length)}</label>}
          {errors.description?.type === "maxLength" && <label className="incident-management-error-message">{t(TRANSLATION.INCIDENTMANAGEMENT.ERROR.max160Length)}</label>}
        </div>
        <div className="d-flex justify-content-end mt-2">
          <TNSButton buttonVariant={Variants.Primary} onClick={submit} disabled={!isValid} className="me-2">
            {t(TRANSLATION.INCIDENTMANAGEMENT.BUTTON.create)}
          </TNSButton>
          <TNSButton buttonVariant={Variants.Secondary} onClick={closeDrawer}>
            {t(TRANSLATION.INCIDENTMANAGEMENT.BUTTON.cancel)}
          </TNSButton>
        </div>
      </TNSContainer>
    </TNSDrawer>
  );
};
