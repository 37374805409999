import React, { MouseEvent, ReactNode, useCallback, useContext } from "react";
import { NotificationMessage, NotificationType } from "src/app/portal-app/components/notification&alerts/notification";
import { Notifications } from "@tnso/api-sdk";
import { DateHelper } from "@tnso/shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer, faTicket } from "@fortawesome/free-solid-svg-icons";
import { Checkbox, Tooltip } from "antd";
import { NotificationContext } from "./notifications-context";
import { TNSOSelectFilter } from "src/app/portal-app/components/shared/table/SelectFilter";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "src/app/portal-app/utils/const/translation";
import { store } from "src/app/portal-app/store/StoreMobx";
import { NotificationQueryParams } from "src/shared/models/notification.model";
import "./notifications-table.scss";

export interface InterfaceTableConfig {
  notificationsCloumns: unknown[];
  skeletonColumns: unknown[];
}

export const useNotificationTableConfig = (
  notificationIds: number[],
  setSelectedRows: (ids: number[]) => void,
  selectedRows: number[],
  setIsAllSelected: (isAllSelected: boolean) => void,
  isAllSelected: boolean,
  loadNotifications: (params?: NotificationQueryParams) => Promise<void>,
  setCurrentPage: (page: number) => void,
  setIsLoading: (isLoading: boolean) => void,
): InterfaceTableConfig => {
  const context = useContext(NotificationContext);
  const { t } = useTranslation();

  const toggleSelectAll = (): void => {
    if (isAllSelected) {
      const idsOfOtherPages = selectedRows.filter((id) => !notificationIds.includes(id));
      setSelectedRows([...idsOfOtherPages]);
    } else {
      setSelectedRows([...selectedRows, ...notificationIds]);
    }
    setIsAllSelected(!isAllSelected);
  };

  const toggleSelect = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>, notification: Notifications): void => {
    e.stopPropagation();
    if (notification.id) {
      if (selectedRows.includes(notification.id)) {
        setSelectedRows(selectedRows.filter((id) => id !== notification.id));
        setIsAllSelected(false);
      } else {
        const totalIds = [...selectedRows, notification.id];
        setSelectedRows(totalIds);
        setIsAllSelected(totalIds.length === notificationIds.length);
      }
    }
  };

  const handleSearch = useCallback(
    async (value: string, dataIndex: string): Promise<void> => {
      setIsLoading(true);
      setSelectedRows([]);
      setIsAllSelected(false);
      setCurrentPage(1);
      const newParams = { ...context.params, [dataIndex]: value, startAtRecord: 0, recordsPerPage: 10 };
      if (value === "") {
        delete newParams[dataIndex as keyof NotificationQueryParams];
        await loadNotifications(newParams);
      } else {
        await loadNotifications(newParams);
      }
      setIsLoading(false);
    },
    [context, setSelectedRows, setIsAllSelected, setCurrentPage, setIsLoading, loadNotifications]
  );

  // eslint-disable-next-line
  const getColumnSearchProps = (dataIndex: string, filterType?: "search" | "select", icon?: ReactNode, options?: any[]): any => ({
    // eslint-disable-next-line
    filterDropdown: (props: any) => (
      <TNSOSelectFilter options={options ?? []} onSearch={handleSearch} keyFilter={dataIndex} defaultValue={[]} close={props.close} displayLabels={true} />
    )
  });

  const notificationsCloumns = [
    {
      dataIndex: "",
      title: (
        <div className="notifications-table-row">
          <Checkbox onClick={toggleSelectAll} checked={isAllSelected} disabled={notificationIds.length === 0} />
        </div>
      ),
      key: "checkbox",
      ellipsis: true,
      width: 50,
      filter: true,
      render: (cell: string, row: Notifications) => (
        <div>
          <Checkbox onClick={(e): void => toggleSelect(e, row)} checked={selectedRows.find((id) => id === row.id) ? true : false} />
        </div>
      )
    },
    {
      dataIndex: "notificationType",
      title: t(TRANSLATION.NOTIFICATIONS.type),
      key: "type",
      ellipsis: true,
      width: 90,
      filter: true,
      render: (cell: string, row: Notifications) => (
        <Tooltip title={cell}>
          <div>
            <NotificationType type={<FontAwesomeIcon icon={cell === "DEVICE" ? faServer : faTicket} />} status={row.status} />
          </div>
        </Tooltip>
      ),
      ...getColumnSearchProps("notificationType", "select", <FontAwesomeIcon icon={faServer} />, [
        { label: t("Device"), value: "DEVICE" },
        { label: t("Incident"), value: "INCIDENT" },
        { label: t("all"), value: "" }
      ])
    },
    {
      dataIndex: "subject",
      title: t(TRANSLATION.NOTIFICATIONS.subject),
      key: "subject",
      ellipsis: true,
      width: 170,
      render: (cell: string, row: Notifications) => <NotificationMessage message={cell} status={row.status} />
    },
    {
      dataIndex: "message",
      title: t(TRANSLATION.NOTIFICATIONS.content),
      key: "message",
      ellipsis: true,
      render: (cell: string, row: Notifications) => <NotificationMessage message={cell} status={row.status} />
    },
    {
      dataIndex: "createdAt",
      title: t(TRANSLATION.NOTIFICATIONS.date),
      key: "createdAt",
      ellipsis: true,
      width: 180,
      sorter: true,
      render: (cell: number, row: Notifications) => (
        <div>
          <span>
            <NotificationMessage
              message={DateHelper.convertTimestampToBrowserCulture(cell, store.auth.userInfo?.timeZone, {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
              })}
              status={row.status}
            />
          </span>
        </div>
      )
    }
  ];

  const skeletonColumns = [
    {
      dataIndex: "",
      title: (
        <div className="notifications-table-row">
          <Checkbox onClick={toggleSelectAll} checked={isAllSelected} disabled={notificationIds.length === 0} />
        </div>
      ),
      key: "checkbox",
      ellipsis: true,
      width: 40
    },
    {
      dataIndex: "notificationType",
      title: t(TRANSLATION.NOTIFICATIONS.type),
      key: "type",
      ellipsis: true,
      width: 60,
      ...getColumnSearchProps("notificationType", "select", <FontAwesomeIcon icon={faServer} className="filter-icon" />, [
        { label: "device", value: "DEVICE" },
        { label: "incident", value: "INCIDENT" },
        { label: t("all"), value: "" }
      ])
    },
    {
      dataIndex: "subject",
      title: t(TRANSLATION.NOTIFICATIONS.subject),
      key: "subject",
      ellipsis: true,
      width: 170
    },
    {
      dataIndex: "message",
      title: t(TRANSLATION.NOTIFICATIONS.content),
      key: "message"
    },
    {
      dataIndex: "createdAt",
      title: t(TRANSLATION.NOTIFICATIONS.date),
      key: "createdAt",
      width: 140
    }
  ];

  return { notificationsCloumns, skeletonColumns };
};
