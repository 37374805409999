import React, { useContext, useMemo } from "react";
import { useAsyncCall } from "./hooks/useAsyncCallShared";
import { SSOContext } from "./contexts/auth/sso-context";
import { AuthHelper } from "./app/portal-app/helpers/auth/AuthHelper";
import { useRoutes } from "react-router-dom";
import { ThemeRoutes } from "./routes/RouterComponent";
import { signOut } from "aws-amplify/auth/cognito";
import { store } from "./app/portal-app/store/StoreMobx";
import { TokenHelper } from "./app/portal-app/helpers/token/TokenHelper";
import { ConfigContext } from "./contexts/configuration/ConfigurationContext";
import { isEmpty } from "lodash";
import { LogHelper } from "./helpers/shared/LogHelper";
import App from "./App";
import Loader from "./layouts/loader/Loader";

const getAccessToken = (): string | null => {
  console.info("[AuthInterceptor] Getting access token...");
  return localStorage.getItem("accessToken");
};

const getRefreshToken = (): string | null => {
  console.info("[AuthInterceptor] Getting refresh token...");
  return localStorage.getItem("refreshToken");
};

export const AuthInterceptor: React.FC = (): JSX.Element => {
  const ssoContext = useContext(SSOContext);
  const { auth } = store;
  const { configuration } = useContext(ConfigContext);

  const TNSO_SSO_URL = useMemo(() => configuration?.environmentValues?.TNSO_SSO_URL, [configuration]);
  LogHelper.info("[AuthInterceptor] SSO URL", TNSO_SSO_URL);

  const API_URL_BASE = useMemo(() => configuration?.environmentValues?.API_URL_BASE, [configuration]);
  LogHelper.info("[AuthInterceptor] API URL", API_URL_BASE);

  const loader = useAsyncCall(async () => {
    const isActAs = localStorage.getItem("actAs");
    LogHelper.writeLog("[AuthInterceptor]", "Acting as: ", isActAs);

    LogHelper.info("[AuthInterceptor] Configuration loaded", configuration?.environmentValues);
    if (TNSO_SSO_URL && API_URL_BASE && !isActAs) {
      LogHelper.info("[AuthInterceptor] SSO is active, validating credentials...");
      const credentials = await ssoContext.getCognitoCredentials();

      LogHelper.info("[AuthInterceptor] Credentials loaded", credentials);
      const validationResponse = await ssoContext.getValidations(API_URL_BASE);

      LogHelper.info("[AuthInterceptor] Validation response", validationResponse);
      if (!validationResponse?.accessToken || !credentials) {
        LogHelper.error("[AuthInterceptor] Invalid credentials, signing out...");
        await signOut();

        LogHelper.info("[AuthInterceptor] Redirecting to SSO");
        window.location.href = TNSO_SSO_URL;
        return;
      } else if (validationResponse?.accessToken && validationResponse.refreshToken) {
        LogHelper.info("[AuthInterceptor] User is authenticated, processing login......");
        const { accessToken, refreshToken } = validationResponse;
        AuthHelper.getAuthByToken(accessToken);
        LogHelper.info("[AuthInterceptor] Processing login...");
        await auth.processLogin(undefined, accessToken, refreshToken);
      }
    }

    LogHelper.info("[AuthInterceptor] User is acting as another user...");
  }, [configuration?.environmentValues, TNSO_SSO_URL, API_URL_BASE, ssoContext, auth]);

  useAsyncCall(async () => {
    const accessToken = getAccessToken();
    LogHelper.info("[AuthInterceptor] Access token", accessToken);
    const refreshToken = getRefreshToken();
    LogHelper.info("[AuthInterceptor] Refresh token", refreshToken);
    if (accessToken && refreshToken) {
      LogHelper.info("[AuthInterceptor] Validating expired tokens...");
      await TokenHelper.validateExpiredToken(accessToken, refreshToken);
      await TokenHelper.validateExpiredCognitoToken();
    }
  }, []);

  const accessToken = getAccessToken();

  const themeRoutes = useMemo(() => (accessToken ? ThemeRoutes(accessToken) : []), [accessToken]);

  const themes = useRoutes(themeRoutes);

  LogHelper.info("[AuthInterceptor] Loader status", loader.loading ? "loading" : "loaded");
  return loader.loading || !accessToken ? <Loader /> : <App routing={themes} ssoActive={!isEmpty(TNSO_SSO_URL)} />;
};
