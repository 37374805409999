import React from "react";
import { DateHelper, UnitConvertHelper } from "@tnso/shared";
import { CellularUsage } from "../../../interfaces/devices/cellular/cellularUsage";
import { TRANSLATION } from "../../../utils/const/translation";
import { i18nInstance as i18n } from "../../../../../i18n";
import { TFunction } from "i18next";
import { store } from "../../StoreMobx";

export const historicalPeriod = [
  {
    dataIndex: "billingPeriod",
    title: "billingPeriod",
    key: "billingPeriod",
    render: (_: string, row: CellularUsage): string => {
      return `${DateHelper.convertTimestampToBrowserCulture(Number(row.startDate), store.auth.userInfo?.timeZone, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric"
      })} - ${DateHelper.convertTimestampToBrowserCulture(Number(row.endDate), store.auth.userInfo?.timeZone, { month: "2-digit", day: "2-digit", year: "numeric" })}`;
    }
  },
  {
    dataIndex: "planSize",
    title: "planSize",
    key: "planSize",
    render: (cel: number): string => {
      return `${UnitConvertHelper.convertBytesToMebiBytes(cel).toFixed(2)} ${i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.MiB)}`;
    }
  },
  {
    dataIndex: "txcnt",
    title: "txcnt",
    key: "txcnt",
    render: (cel: number): string => {
      return `${UnitConvertHelper.convertBytesToMebiBytes(cel).toFixed(2)} ${i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.MiB)}`;
    }
  },
  {
    dataIndex: "rxcnt",
    title: "rxcnt",
    key: "rxcnt",
    render: (cel: number): string => {
      return `${UnitConvertHelper.convertBytesToMebiBytes(cel).toFixed(2)} ${i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.MiB)}`;
    }
  },
  {
    dataIndex: "total",
    title: "total",
    key: "total",
    render: (cel: number): string => {
      return `${UnitConvertHelper.convertBytesToMebiBytes(cel).toFixed(2)} ${i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.MiB)}`;
    }
  },
  {
    dataIndex: "percent",
    title: "percent",
    key: "percent",
    render: (cel: number): JSX.Element => {
      return <span style={cel > 100 ? { color: "red" } : {}}>{cel}%</span>;
    }
  },
  {
    dataIndex: "overage",
    title: "overage",
    key: "overage",
    render: (cel: number): string => {
      return `${UnitConvertHelper.convertBytesToMebiBytes(cel).toFixed(2)} ${i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.MiB)}`;
    }
  }
];
const skeletonColumns = historicalPeriod.map(({ dataIndex, title, key }) => ({
  dataIndex,
  title,
  key
}));

export const columns = {
  historicalPeriod,
  skeletonColumns
};

// eslint-disable-next-line
export const cellularUsageTranslatedData = (t: TFunction, data: CellularUsage[]): CellularUsage[] => {
  return data?.map((item) => {
    return {
      ...item,
      formatter: (cel: number): string => {
        return `${UnitConvertHelper.convertBytesToMebiBytes(cel).toFixed(2)} ${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.MiB)}`;
      }
    };
  });
};
