import { ApiCore, IResponse, ApiService } from "@tnso/api-core";
import { ConnectedDeviceName, ConnectedDevicesParams, ConnectedDevicesResponse } from "src/app/monitoring/models/connected-devices/connected-devices";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class ConnectedDevicesService extends ApiService {
  public static getAll(params: { deviceParams?: ConnectedDevicesParams; tnsDeviceName?: string }): Promise<IResponse<ConnectedDevicesResponse> | undefined> {
    const connectedDeviceParams = ApiCore.encodeParams<ConnectedDevicesParams>(params.deviceParams ?? {});
    if (params?.tnsDeviceName) {
      return this.get<ConnectedDevicesResponse>(`${getApiBaseUrl()}/devices/${params.tnsDeviceName}/connecteddevices`, connectedDeviceParams);
    }
    return this.get<ConnectedDevicesResponse>(`${getApiBaseUrl()}/devices/connecteddevices`, connectedDeviceParams);
  }

  public static getAvailableLanPorts(tnsDeviceName?: string): Promise<IResponse<string[]> | undefined> {
    const urlSearchParams = ApiCore.encodeParams<ConnectedDevicesParams>(tnsDeviceName ? { tnsDeviceName } : {});
    return this.get<string[]>(`${getApiBaseUrl()}/devices/ports`, urlSearchParams);
  }

  public static updateConnectedDeviceName(connectedDeviceName: string, macAddress: string): Promise<IResponse<ConnectedDeviceName> | undefined> {
    return this.patch<ConnectedDeviceName>(`${getApiBaseUrl()}/devices/connecteddevices/${encodeURIComponent(macAddress)}/connecteddevicename`, {
      connectedDeviceName
    });
  }
}
