export const SIZE = {
  MOBILE_XS: 280,
  MOBILE: 540,
  TABLET_XS: 768,
  TABLET: 912,
  DESKTOP: 1024,
  CELLULAR_USAGE_CHART: {
    DASH_ARRAY_FOR_BAR: [5, 5],
    LEGENDS_WIDTH: "30px",
    LEGENDS_HEIGHT_LINES: "1px"
  }
};
