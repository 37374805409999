import React from "react";
import { MetricCard } from "./metric-card";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "src/app/monitoring/utils/const/translation";

export interface LatencyCardProps {
  latency: number;
}

export const LatencyCard: React.FC<LatencyCardProps> = ({ latency }) => {
  const { t } = useTranslation();
  const latencyValue = latency ? <h6>{latency} ms</h6> : <h6>N/A</h6>;

  return <MetricCard header={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.latency)} data={latencyValue} />;
};
