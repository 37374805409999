export const API_URL_BASE = import.meta.env.VITE_API_URL_BASE || "";
export const API_URL_BASE_NOTIFICATIONS = import.meta.env.VITE_API_URL_BASE_NOTIFICATIONS || "";
export const API_URL_STRAPI = import.meta.env.VITE_API_URL_STRAPI || "";
export const API_URL_BASE_V2 = import.meta.env.VITE_API_URL_BASE_V2 || "";
export const API_SOCKET_URL = import.meta.env.VITE_API_SOCKET_URL || "";
export const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_API_KEY || "";
export const STRAPI_TOKEN = import.meta.env.VITE_STRAPI_TOKEN || "";
export const IS_API_MOCKING_ENABLED = import.meta.env.VITE_IS_API_MOCKING_ENABLED;

// These flags enable or disable the content view of each device details tabs
export const TROUBLESHOOTING_PING_TIME = import.meta.env.VITE_TROUBLESHOOTING_PING_TIME;
export const TROUBLESHOOTING_TCP_TIME = import.meta.env.VITE_TROUBLESHOOTING_TCP_TIME;
export const INITIAL_MAP_ZOOM = import.meta.env.VITE_INITIAL_MAP_ZOOM;

export const CLIENT_IDS = import.meta.env.VITE_CLIENT_IDS || "";
export const AWS_COGNITO_IDENTITY_POOL_ID = import.meta.env.VITE_AWS_COGNITO_IDENTITY_POOL_ID || "";
export const AWS_COGNITO_REGION = import.meta.env.VITE_AWS_COGNITO_REGION || "";
export const AWS_PROJECT_REGION = import.meta.env.VITE_AWS_PROJECT_REGION || "";
export const AWS_USER_POOLS_ID = import.meta.env.VITE_AWS_USER_POOLS_ID || "";
export const AWS_USER_POOLS_WEB_CLIENT_ID = import.meta.env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID || "";
export const CLOUDFRONT_DISTRIBUTION_DOMAIN_NAME = import.meta.env.VITE_CLOUDFRONT_DISTRIBUTION_DOMAIN_NAME || "";
export const CLOUDFRONT_DISTRIBUTION_ID = import.meta.env.VITE_CLOUDFRONT_DISTRIBUTION_ID || "";
export const S3_BUCKET_NAME = import.meta.env.VITE_S3_BUCKET_NAME || "";
export const AWS_COGNITO_USERNAME_ATTRIBUTES = import.meta.env.VITE_AWS_COGNITO_USERNAME_ATTRIBUTES || "";
export const AWS_COGNITO_SOCIAL_PROVIDERS = import.meta.env.VITE_AWS_COGNITO_SOCIAL_PROVIDERS || "";
export const AWS_COGNITO_SIGNUP_ATTRIBUTES = import.meta.env.VITE_AWS_COGNITO_SIGNUP_ATTRIBUTES || "";
export const AWS_COGNITO_MFA_CONFIGURATION = import.meta.env.VITE_AWS_COGNITO_MFA_CONFIGURATION || "";
export const AWS_COGNITO_MFA_TYPES = import.meta.env.VITE_AWS_COGNITO_MFA_TYPES || "";
export const AWS_COGNITO_PASSWORDPOLICYMINLENGTH = import.meta.env.VITE_AWS_COGNITO_PASSWORDPOLICYMINLENGTH || "";
export const AWS_COGNITO_PASSWORDPOLICYCHARACTERS = import.meta.env.VITE_AWS_COGNITO_PASSWORDPOLICYCHARACTERS || "";
export const AWS_COGNITO_VERIFICATION_MECHANISMS = import.meta.env.VITE_AWS_COGNITO_VERIFICATION_MECHANISMS || "";

export const SMS_COUNTRIES_ALLOWED = import.meta.env.VITE_SMS_COUNTRIES_ALLOWED || "";

export const TNSO_SSO_URL = import.meta.env.VITE_TNSO_SSO_URL || false;

export const MFA_LANDING_ENABLED = import.meta.env.VITE_MFA_LANDING_PAGE_ENABLED || false;

export const MFA_ENABLED = import.meta.env.VITE_MFA_ENABLED || false;

export const APP_DISCOVERY_VIEW_ENABLED = import.meta.env.VITE_APP_DISCOVERY_VIEW_ENABLED || false;
export const TIMESTAMP_RELEASE_DEPLOYMENT = import.meta.env.VITE_TIMESTAMP_RELEASE_DEPLOYMENT;
export const APP_BANNER_ENABLED = import.meta.env.VITE_APP_BANNER_ENABLED || false;
export const APP_ENTRY_PATH_ID_STRAPI_BANNER = import.meta.env.VITE_APP_ENTRY_PATH_ID_STRAPI_BANNER || false;
export const VITE_MFA_ENABLED = import.meta.env.VITE_MFA_ENABLED || false;

// these flags are used for strapi
export const STRAPI_RELEASE_CONTENT_IDS = import.meta.env.VITE_STRAPI_RELEASE_CONTENT_IDS || "";

// Flags for data refresh time
export const VITE_TIME_REFRESH_DATA_MONITORING = import.meta.env.VITE_TIME_REFRESH_DATA_MONITORING || "";

// Flags for AWS session
export const AWS_ACCESS_KEY_ID = import.meta.env.VITE_AWS_ACCESS_KEY_ID || "";
export const AWS_SECRET_ACCESS_KEY = import.meta.env.VITE_AWS_SECRET_ACCESS_KEY || "";
export const AWS_SESSION_TOKEN = import.meta.env.VITE_AWS_SESSION_TOKEN || "";

// Flag service
export const AWS_FEATURE_FLAG_SERVICE_APPLICATION_ID = import.meta.env.VITE_AWS_FEATURE_FLAG_SERVICE_APPLICATION_ID || "";
export const AWS_FEATURE_FLAG_SERVICE_ENVIRONMENT_ID = import.meta.env.VITE_AWS_FEATURE_FLAG_SERVICE_ENVIRONMENT_ID || "";
export const AWS_FEATURE_FLAG_SERVICE_CONFIGURATION_PROFILE_ID = import.meta.env.VITE_AWS_FEATURE_FLAG_SERVICE_CONFIGURATION_PROFILE_ID || "";

export const AWS_PARAM_STORE_PATH = import.meta.env.VITE_AWS_PARAM_STORE_PATH || "";
export const COGNITO_REFRESH_TOKEN_TIMEOUT = import.meta.env.VITE_COGNITO_REFRESH_TOKEN_TIMEOUT || 1800000;

export const TNSO_ACTION = import.meta.env.VITE_ACTION || "";
export const TNSO_PATH = import.meta.env.VITE_PATH || "";
export const TNSO_APPLICATION_ID = import.meta.env.VITE_APPLICATION_ID || "";
export const TNSO_ENVIRONMENT_ID = import.meta.env.VITE_ENVIRONMENT_ID || "";
export const TNSO_CONFIGURATION_PROFILE_ID = import.meta.env.VITE_CONFIGURATION_PROFILE_ID || false;
export const AWS_PARAMS_LAMBDA_URL = import.meta.env.VITE_LAMBDA_PARAMS_URL || false;

export const FORCE_LOCAL_VARIABLES = import.meta.env.VITE_FORCE_LOCAL_VARIABLES || false;

export const TROUBLESHOOTING_ATTEMPS = import.meta.env.VITE_TROUBLESHOOTING_ATTEMPS;
export const TROUBLESHOOTING_ATTEMPS_DEFAULT = import.meta.env.VITE_TROUBLESHOOTING_ATTEMPS_DEFAULT;
export const VITE_TROUBLESHOOTING_TIME_PING = import.meta.env.VITE_TROUBLESHOOTING_TIME_PING;
export const VITE_TROUBLESHOOTING_TIME_PING_DEFAULT = import.meta.env.VITE_TROUBLESHOOTING_TIME_PING_DEFAULT;
export const VITE_TROUBLESHOOTING_TIME_TCPPACKETCAPTURE = import.meta.env.VITE_TROUBLESHOOTING_TIME_TCPPACKETCAPTURE;
export const VITE_TROUBLESHOOTING_TIME_TCPPACKETCAPTURE_DEFAULT = import.meta.env.VITE_TROUBLESHOOTING_TIME_TCPPACKETCAPTURE_DEFAULT;
export const TROUBLESHOOTING_COMMAND_EXTRA_WAITING_TIME_SECONDS = import.meta.env.VITE_TROUBLESHOOTING_COMMAND_EXTRA_WAITING_TIME_SECONDS;
