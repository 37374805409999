import React, { useEffect, useState } from "react";
import { COLORS } from "../../utils/const/colors";

import { useParams } from "react-router-dom";
import { HeaderDetailDhi } from "../../components/dhi/header-detail-dhi";
import { IDevice } from "../../store/device/device";
import { store } from "src/app/portal-app/store/StoreMobx";
import { observer } from "mobx-react";

interface HeaderDetailProps {
  tnsDeviceName?: string;
  devices: IDevice;
}

export const HeaderDetail: React.FC<HeaderDetailProps> = observer(({ tnsDeviceName, devices }) => {
  const { deviceName } = useParams();
  const { detail } = store.device;

  const [color, setColor] = useState<string>(COLORS.DEVICES.STATUS.INDETERMINATE);

  useEffect(() => {
    if (detail.data || detail?.data) {
      const device = detail.data ?? detail.data;
      const statusColor = COLORS.DEVICES.STATUS[device?.connectivityStatus.toUpperCase() as keyof typeof COLORS.DEVICES.STATUS];
      setColor(statusColor);
    }
  }, [detail.data?.connectivityStatus, detail.data]);

  return <HeaderDetailDhi detail={detail} deviceName={deviceName} devices={devices} color={color} tnsDeviceName={tnsDeviceName} />;
});
