import { ApiCore, ApiService, IResponse } from "@tnso/api-core";
import { ExternalApiClientSecret, ExternalApiConfiguration, ExternalApiEnabled, ExternalApiHistory, ExternalApiHistoryParams } from "../../models/tnsonline-api/external-api";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class ExternalApiService extends ApiService {
  static async getExternalApiConfiguration(): Promise<IResponse<ExternalApiConfiguration> | undefined> {
    return this.get<ExternalApiConfiguration>(`${getApiBaseUrl()}/externalapi`);
  }

  static async getAll(params?: ExternalApiHistoryParams): Promise<IResponse<ExternalApiHistory[]> | undefined> {
    const urlSearchParams = ApiCore.encodeParams<ExternalApiHistoryParams>(params as ExternalApiHistoryParams);
    return this.get<ExternalApiHistory[]>(`${getApiBaseUrl()}/externalapi/history`, urlSearchParams);
  }

  static async updateExternalApiConfiguration(enabled: ExternalApiEnabled): Promise<IResponse<ExternalApiEnabled> | undefined> {
    return this.patch<ExternalApiEnabled>(`${getApiBaseUrl()}/externalapi`, enabled);
  }

  static async updateExternalApiClientSecret(): Promise<IResponse<ExternalApiClientSecret> | undefined> {
    return this.patch<ExternalApiClientSecret>(`${getApiBaseUrl()}/externalapi/clientsecret`);
  }
}
