import React from "react";

import { DevicesGETApi, InlineResponse20077 as GetVLANResponse } from "@tnso/api-sdk";
import { InstanceType, useApiController } from "../../../shared/hooks/use-api-controller";
import { MessageHelper } from "@tnso/shared";
import { i18nInstance } from "src/i18n";
import { TRANSLATION } from "../utils/const/translation";

export interface VLANContextProviderProps {
  children: React.ReactNode;
}

export { GetVLANResponse };

export interface VLANContextValue {
  getVLANData: (deviceName: string) => Promise<GetVLANResponse[] | undefined>;
}

export const initialValue: VLANContextValue = {
  getVLANData: (): Promise<GetVLANResponse[] | undefined> => Promise.resolve(undefined)
};

export const VLANContext = React.createContext(initialValue);

const VLANContextProvider: React.FC<VLANContextProviderProps> = ({ children }) => {
  const apiGetController = useApiController(DevicesGETApi, InstanceType.DefaultWithBase);

  const getVLANData = async (deviceName: string): Promise<GetVLANResponse[] | undefined> => {
    try {
      const result = await apiGetController.devicesTnsDeviceNameRulesVlanGet(deviceName);
      return result?.data;
    } catch (error) {
      MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.somethingWentWrongTryAgainLater));
      console.error(error);
    }
  };

  return (
    <VLANContext.Provider
      value={{
        getVLANData
      }}>
      {children}
    </VLANContext.Provider>
  );
};

export default VLANContextProvider;
