import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../utils/const/translation";
import { Tab, Tabs as TabContainer } from "react-bootstrap";
import { HeaderDetail } from "./header-dhi";
import { Details } from "./tabs/details";
import { useScreenSize } from "../../../../hooks/useScreenSize";
import { useParams } from "react-router-dom";
import { TABS_ENABLED } from "../../auth/available-dhi-tab";
import { useAsyncCall } from "../../../../hooks/useAsyncCall";

import { store } from "../../store/store";
import { observer } from "mobx-react";
import { Wireless } from "./tabs/wireless";

export enum Tabs {
  Details = "details",
  CellularUsage = "cellularUsage",
  Wireless = "wireless",
  Uptime = "uptime",
  Notes = "notes",
  LVC = "lvc",
  Hardware = "hardware",
  ConnectedDevices = "connectedDevices",
  Connections = "connections",
  Troubleshooting = "troubleshooting",
  Events = "events",
  FirewallRules = "firewallRules",
  Software = "softwareTab"
}

export const DeviceHealthInformation: React.FC = observer(() => {
  const { t } = useTranslation();
  const { device, connection, devices } = store;
  const { isMobile } = useScreenSize();
  const [viewActive, setViewActive] = useState<Tabs>();
  const tabsEnabled = useMemo(() => TABS_ENABLED(), []);
  const availableTabs: Tabs[] = useMemo(
    () =>
      // eslint-disable-next-line array-callback-return
      Object.values(Tabs).filter((tab): Tabs | undefined => {
        if ((tabsEnabled[tab] && tab === Tabs.CellularUsage) || (tabsEnabled[tab] && tab === Tabs.Wireless)) {
          if (store.device.cellularUsage.isWireless) {
            return tab;
          }
        } else if (tab === Tabs.LVC && tabsEnabled[tab]) {
          if (store.device.lvc.data?.lvcs && store.device.lvc.data?.lvcs?.length > 0) {
            return tab;
          }
        } else {
          if (tab === Tabs.Connections && tabsEnabled[tab]) {
            if (connection.hasMemberDevice?.hasMemberConnectivity) {
              return tab;
            }
          } else {
            if (tabsEnabled[tab]) {
              return tab;
            }
          }
        }
      }),
    [device.lvc.data?.lvcs, device.cellularUsage.isWireless, connection.hasMemberDevice?.hasMemberConnectivity]
  );

  const { deviceName } = useParams();

  const loadMethod = useCallback(async (): Promise<void> => {
    if (deviceName) {
      device.note.clearContactData();
      await Promise.all([
        device.uptime.loadDataDaily(deviceName),
        device.lvc.loadData(deviceName, { startAtRecord: 0, recordsPerPage: 10 }),
        device.cellularUsage.loadWireless(deviceName),
        connection.loadHasMemberDevice(deviceName),
        device.note.loadDataContact(deviceName)
      ]);
    }
  }, []);

  useAsyncCall(async () => await loadMethod(), []);

  const handleViewChange = useCallback(
    (tab: string | null): void => {
      setViewActive(tab as Tabs);
    },
    [setViewActive]
  );

  useEffect(() => {
    if (!viewActive) {
      const activeTabs = Object.values(Tabs).filter((tab) => tabsEnabled[tab]);
      setViewActive(activeTabs[0]);
    }
  }, [viewActive]);

  const currentTab = useMemo(() => {
    return (
      <>
        {viewActive === Tabs.Details && <Details />}
        {viewActive === Tabs.Wireless && <Wireless />}
      </>
    );
  }, [isMobile, viewActive]);

  return (
    <div className="d-flex flex-column gap-2 device-detail-container">
      <div className="d-flex gap-2 device-detail">
        <TabContainer
          defaultActiveKey={viewActive}
          id="uncontrolled-tab-example"
          className="mb-1 tab-container"
          onSelect={handleViewChange}
          unmountOnExit={true}
          transition={false}
          activeKey={viewActive}>
          {availableTabs.map((tab, index) => (
            <Tab
              eventKey={tab}
              title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TABS[tab as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TABS])}
              key={index}
            />
          ))}
        </TabContainer>
      </div>
      {<HeaderDetail tnsDeviceName={deviceName} detail={device.detail} devices={devices} />}
      {currentTab}
    </div>
  );
});
