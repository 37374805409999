import React, { useCallback, useState } from "react";
import { Controller, Form, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../utils/const/translation";
import { TNSColorPicker } from "@tns/ui-components";
import { Variants, TNSButton, TNSInput } from "@tns/ui-components";
import { GroupForm } from "../../../interfaces/devices/group/group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { Color } from "antd/es/color-picker";
import { store } from "../../../store/StoreMobx";
import { useNavigate } from "react-router-dom";
import tinycolor from "tinycolor2";

export interface CreateGroupProps {
  secondStep: boolean;
  setSecondStep?: (value: boolean) => void;
  setGroupName: (value: string) => void;
  setGroupColor: (value: string) => void;
  groupName?: string;
  groupColor?: string;
}
export function CreateGroup({ secondStep, setSecondStep, setGroupName, setGroupColor, groupName, groupColor }: CreateGroupProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [color, setColor] = React.useState<string>(groupColor ?? "#ffffff");
  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid }
  } = useForm<GroupForm>({ defaultValues: { name: groupName ?? "", color: groupColor ?? "#ffffff" }, mode: "all" });
  const [groupNameToUpdate, setGroupNameToUpdate] = useState(groupName ?? "");

  const handleClick = useCallback(async (): Promise<void> => {
    const result = await store.group.createGroup(getValues());
    setGroupName(getValues("name"));
    setGroupColor(getValues("color"));
    if (result && setSecondStep) {
      setSecondStep(true);
    }
  }, [getValues]);

  const handleEditClick = useCallback(async (): Promise<void> => {
    if (groupName) {
      const result = await store.group.editDevicesByGroupName(groupNameToUpdate, { name: getValues("name"), color: getValues("color") });
      setGroupName(getValues("name"));
      setGroupColor(getValues("color"));
      setGroupNameToUpdate(getValues("name"));
      if (result && setSecondStep) {
        setSecondStep(true);
      }
    }
  }, [getValues, groupNameToUpdate, groupName]);

  return (
    <div>
      <Form control={control} className="d-flex flex-column gap-4">
        <div className="d-flex gap-2 align-items-center mb-3">
          <label className="field-container">*{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.CREATE.groupName)}: </label>
          <div className="d-flex flex-column ">
            <Controller
              name="name"
              control={control}
              rules={{ required: true, validate: async (value) => await store.group.isAvailable(value, groupNameToUpdate) }}
              render={({ field }): JSX.Element => <TNSInput {...field} status={errors.name ? "error" : ""} disabled={secondStep} />}
            />
            {errors.name && errors.name.type === "required" && (
              <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.CREATE.groupNameIsRequired)}</small>
            )}
            {errors.name?.type === "validate" && (
              <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.CREATE.thisNameIsAlreadyTakenPleaseChooseAnother)}</small>
            )}
          </div>
        </div>
        <div className="d-flex gap-2 align-items-center justify-content-between mt-2">
          <div className="d-flex gap-2 align-items-center">
            <label htmlFor="tagColorGroup">*{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.CREATE.tagColorGroup)}: </label>
            <Controller
              name="color"
              control={control}
              render={({ field }): JSX.Element => (
                <TNSColorPicker
                  {...field}
                  format="hex"
                  disabledAlpha
                  defaultFormat="hex"
                  defaultValue={color}
                  disabled={secondStep}
                  trigger="click"
                  onChange={(color: Color, hexa: string): void => {
                    setColor(tinycolor(hexa).toHexString());
                    setValue("color", tinycolor(hexa).toHexString());
                  }}
                  className="d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faTag} color={color} size="lg" />
                </TNSColorPicker>
              )}
            />
          </div>
          {groupName && groupColor ? (
            <div className="d-flex gap-2 align-items-center">
              <TNSButton buttonVariant={Variants.Secondary} onClick={(): void => navigate(-1)}>
                {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.EDIT.cancel)}
              </TNSButton>
              <TNSButton buttonVariant={Variants.Primary} onClick={handleEditClick} disabled={!isValid}>
                {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.EDIT.save)}
              </TNSButton>
            </div>
          ) : (
            <TNSButton buttonVariant={Variants.Primary} onClick={handleClick} disabled={!isValid || secondStep}>
              {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.CREATE.next)}
            </TNSButton>
          )}
        </div>
      </Form>
    </div>
  );
}
