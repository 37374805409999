import React, { useCallback, useContext, useMemo } from "react";
import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../utils/const/translation";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Variants, TNSButton } from "@tns/ui-components";
import { CustomizerContext } from "../../../../../contexts/customizer/CustomizerContext";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  keyFilter: string;
  defaultValue: CheckboxValueType[];
  displayValues?: boolean;
  displayLabels?: boolean;
  onSearch: (value: string, keyFilter: string) => void;
  close: () => void;
}

export function TNSOSelectFilter({ options, keyFilter, onSearch, defaultValue, displayValues, displayLabels, close }: Props): JSX.Element {
  const { t } = useTranslation();
  const customizer = useContext(CustomizerContext);
  const [optionsSelected, setOptionsSelected] = React.useState<CheckboxValueType[]>(defaultValue);
  const optionsAvailable = useMemo(
    () =>
      options.map((option, index) => (
        <Checkbox key={option + index} value={option.value}>
          <span className="select-filter-text">
            {displayValues ? option.value : displayLabels ? option.label : t(TRANSLATION.SHARED.DATATABLE[option.label as keyof typeof TRANSLATION.SHARED.DATATABLE])}
          </span>
        </Checkbox>
      )),
    [options, onSearch, keyFilter, t]
  );

  const handleSearch = useCallback(
    (value: CheckboxValueType[]) => {
      // there is always the last value
      setOptionsSelected([value[value.length - 1]]);
    },
    [onSearch, keyFilter]
  );

  const resetValues = useCallback(() => {
    setOptionsSelected([]);
    onSearch("", keyFilter);
    close();
  }, []);

  const handleClick = useCallback(
    (searchValue: string, keyFilter: string): void => {
      onSearch(searchValue, keyFilter);
      close();
    },
    [onSearch]
  );

  return (
    <div className={`d-flex flex-column gap-2 p-2 select-filter-grid-${customizer.selectedTheme}`} data-testid="select-filter">
      <Checkbox.Group onChange={handleSearch} value={optionsSelected} className="d-flex flex-column">
        {optionsAvailable}
      </Checkbox.Group>
      <div className="d-flex justify-content-between gap-4">
        <TNSButton buttonVariant={Variants.Link} onClick={resetValues} data-testid="button-ok">
          Reset
        </TNSButton>
        <TNSButton buttonVariant={Variants.Primary} onClick={(): void => handleClick(optionsSelected.toString(), keyFilter)} data-testid="button-ok">
          Ok
        </TNSButton>
      </div>
    </div>
  );
}
