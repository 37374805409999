import { Themes, useThemeContext } from "@tns/ui-components";
import React, { createContext, useMemo, useCallback, useState } from "react";
import { AuthService } from "../../app/portal-app/service/auth/AuthService";

interface CustomizerContextValue {
  selectedTheme: Themes;
  setTheme: (theme: Themes, notSaveInCognito?: boolean) => Promise<void>;
}

interface CustomizerContextProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const CustomizerContextProvider: React.FC<CustomizerContextProviderProps> = ({ children }): JSX.Element => {
  const [selectedTheme, setSelectedTheme] = useState<Themes>(Themes.DARK);
  const { changeTheme } = useThemeContext();

  const setTheme = useCallback(
    async (theme: Themes, notSaveInCognito?: boolean) => {
      let currentTheme: Themes = theme;
      if (theme === Themes.BROWSER) {
        const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        currentTheme = darkModeMediaQuery.matches ? Themes.DARK : Themes.DARK;
      }
      try {
        if (!notSaveInCognito) {
          await AuthService.setUserPreferences({ DEFAULT_THEME: currentTheme });
        }
        changeTheme(currentTheme);
        setSelectedTheme(currentTheme);
        localStorage.setItem("theme", currentTheme);
      } catch (error) {
        console.error(error);
      }
    },
    [changeTheme]
  );

  const customizerContextValue = useMemo<CustomizerContextValue>(
    () => ({
      selectedTheme,
      setTheme
    }),
    [selectedTheme, setTheme]
  );

  return <CustomizerContext.Provider value={customizerContextValue}>{children}</CustomizerContext.Provider>;
};

export const CustomizerContext = createContext<CustomizerContextValue>({} as CustomizerContextValue);
