import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RawData, SignalDataTable } from "../../../../../models/devices/chart/chart";
import { observer } from "mobx-react";
import { Period } from "../../../../../models/devices/cellular/cellularSignal";
import { RawDataBody } from "./raw-data-body";
import { TECHNOLOGY_TYPE } from "../../../../../utils/const/technologyType";
import { TRANSLATION } from "../../../../../utils/const/translation";
import { DateHelper } from "src/shared/helpers/DateHelper";
import { store as sharedStore } from "src/app/portal-app/store/StoreMobx";
import { store } from "src/app/monitoring/store/store";

import { CustomizerContext } from "../../../../../../../contexts/customizer/CustomizerContext";
import { Themes } from "@tns/ui-components";
import * as darkTokens from "@tns/ui-style-dictionary/build/ts/dark-tokens";
import * as lightTokens from "@tns/ui-style-dictionary/build/ts/light-tokens";

interface ChartsProps {
  interval: Period;
}
export const RawDataTable: React.FC<ChartsProps> = observer(({ interval }) => {
  const { cellularSignal } = store.device;
  const customizer = useContext(CustomizerContext);
  const isDark = useMemo(() => customizer.selectedTheme === Themes.DARK, [customizer.selectedTheme]);
  const { t } = useTranslation();
  const [dataTable, setData] = useState<RawData>();
  const columnsWidth = useMemo(() => `${100 / (cellularSignal.data ? cellularSignal.data.length : 1)}%`, [dataTable]);
  const isDaily = interval === Period.Daily;

  const dateTime = useCallback(
    (date: string) => {
      const newDate = isDaily
        ? DateHelper.formatTimestampInHours(date, sharedStore.auth.userInfo?.timeZone)
        : DateHelper.formatTimestampInDays(date, sharedStore.auth.userInfo?.timeZone);
      return newDate;
    },
    [t, isDaily]
  );

  const returnBody = useCallback(
    (data: SignalDataTable) => {
      {
        return data.signal.map((item, index) => {
          const statusSignal = data.statusSignal[index];
          return <RawDataBody valueSignal={item} key={index} statusSignal={statusSignal} />;
        });
      }
    },
    [dataTable, t]
  );
  useEffect(() => {
    if (cellularSignal.rawTNSSignal) {
      setData(cellularSignal.rawTNSSignal.current ?? []);
    }
  }, [cellularSignal.rawTNSSignal.current]);

  return dataTable && dataTable.time && dataTable.data && dataTable.data.length > 0 && cellularSignal.data && cellularSignal.data?.length > 0 ? (
    <div className="w-100 overflow-auto">
      <table>
        <thead
          style={{
            color: isDark ? darkTokens.TnsTableLabelColor : lightTokens.TnsTableLabelColor,
            backgroundColor: isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault
          }}>
          <tr>
            <th className="p-2  text-center">
              <small>{t(TRANSLATION.SHARED.TABLE.technology)}</small>
            </th>
            <th className="p-2 text-center">
              <small>{t(TRANSLATION.SHARED.TABLE.signalIndicator)}</small>
            </th>
            {cellularSignal.data?.map((date, index) => (
              <th className="p-1  text-center" key={index} style={{ width: columnsWidth }}>
                <small>{dateTime(date.dateTime)}</small>
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{ borderBottom: `1px solid  ${isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}` }}>
          <tr>
            <th
              className="p-2 text-center"
              rowSpan={4}
              style={{ borderBottom: `1px solid  ${isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}` }}>
              <small>{TECHNOLOGY_TYPE.FOURG}</small>
            </th>
            <th
              className="text-center"
              style={{ borderBottom: `1px solid  ${isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}` }}>
              <small>
                {TECHNOLOGY_TYPE.FOURG} {TECHNOLOGY_TYPE.RSSI}
              </small>
            </th>
            {returnBody(dataTable.data[2].data[0])}
          </tr>
          <tr>
            <th
              className="text-center"
              style={{ borderBottom: `1px solid  ${isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}` }}>
              <small>{TECHNOLOGY_TYPE.RSRP}</small>
            </th>
            {returnBody(dataTable.data[2].data[1])}
          </tr>
          <tr>
            <th
              className="p-1 text-center"
              style={{ borderBottom: `1px solid  ${isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}` }}>
              <small>{TECHNOLOGY_TYPE.RSRQ}</small>
            </th>
            {returnBody(dataTable.data[2].data[2])}
          </tr>
          <tr>
            <th
              className="p-1 text-center"
              style={{ borderBottom: `1px solid  ${isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}` }}>
              <small>{TECHNOLOGY_TYPE.SINR}</small>
            </th>
            {returnBody(dataTable.data[2].data[3])}
          </tr>
          <tr>
            <th
              className="p-1 text-center"
              rowSpan={3}
              style={{ borderBottom: `1px solid  ${isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}` }}>
              <small>{TECHNOLOGY_TYPE.THREEG}</small>
            </th>
            <th
              className="p-1 text-center"
              style={{ borderBottom: `1px solid  ${isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}` }}>
              <small>
                {TECHNOLOGY_TYPE.THREEG} {TECHNOLOGY_TYPE.RSSI}
              </small>
            </th>
            {returnBody(dataTable.data[1].data[0])}
          </tr>
          <tr>
            <th
              className="p-1 text-center"
              style={{ borderBottom: `1px solid  ${isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}` }}>
              <small>{TECHNOLOGY_TYPE.RSCP}</small>
            </th>
            {returnBody(dataTable.data[1].data[1])}
          </tr>
          <tr>
            <th
              className="p-1 text-center"
              style={{ borderBottom: `1px solid  ${isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}` }}>
              <small>{TECHNOLOGY_TYPE.ECIO}</small>
            </th>
            {returnBody(dataTable.data[1].data[2])}
          </tr>
          <tr>
            <th
              className="p-1 text-center"
              rowSpan={1}
              style={{ borderBottom: `1px solid  ${isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}` }}>
              <small>{TECHNOLOGY_TYPE.TWOG}</small>
            </th>
            <th
              className="p-1 text-center"
              style={{ borderBottom: `1px solid  ${isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}` }}>
              <small>
                {TECHNOLOGY_TYPE.TWOG} {TECHNOLOGY_TYPE.RSSI}
              </small>
            </th>
            {returnBody(dataTable.data[0].data[0])}
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <div className="d-flex justify-content-center align-items-center w-100 p-4 m-4">
      <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.thereIsNoData)}</span>
    </div>
  );
});
