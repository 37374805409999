import React, { useMemo } from "react";
import { TNSCard, TNSDivider } from "@tns/ui-components";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "src/app/monitoring/utils/const/translation";
import "./device-health-information.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

export interface SlotSIMSCardProps {
  slotNumber: number;
  iccid: string;
  carrier: string;
  isActive: boolean;
}
export const SlotSimsCard: React.FC<SlotSIMSCardProps> = ({ slotNumber, iccid, carrier, isActive }) => {
  const { t } = useTranslation();
  const title = `sim${slotNumber}`;

  const status = useMemo((): JSX.Element => {
    if (isActive) {
      return (
        <>
          <FontAwesomeIcon icon={faCircleCheck} color="#42C53F" size="lg" /> {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.active)}
        </>
      );
    } else {
      return (
        <>
          <FontAwesomeIcon icon={faCircleCheck} color="gray" size="lg" /> {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.inactive)}
        </>
      );
    }
  }, [isActive]);

  return (
    <TNSCard className="w-100">
      <h6 className="">
        {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL[title as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL])}{" "}
        {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.details)}
      </h6>
      <TNSDivider type="horizontal" />
      <div className="d-flex align-items-center justify-content-between container-sim">
        <div className="d-flex flex-column">
          <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.iccidNumber)}</span>
          <span>{iccid}</span>
        </div>
        <div className="d-flex flex-column">
          <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.interfaceSIM)}</span>
          <span>{slotNumber}</span>
        </div>
        <div className="d-flex flex-column">
          <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.wirelessCarrier)}</span>
          <span>{carrier}</span>
        </div>
        <div className="d-flex flex-column">
          <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.connectionStatus)}</span>
          <span>{status}</span>
        </div>
      </div>
    </TNSCard>
  );
};
