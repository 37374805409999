import React, { ReactElement, useContext, useMemo } from "react";
import { TNSSelect } from "@tns/ui-components";
import { TRANSLATION } from "../../../../../../../utils/const/translation";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TroubleshootingContext, TroubleshootingFormValues } from "../../../../../../../components/device/troubleshooting/context/troubleshooting-context";
import { PingTypes } from "../enums/PingTypes";
import { isEmpty } from "lodash";

export const NetworkFormSelect: React.FC = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const name: keyof TroubleshootingFormValues = "network";
  const { filtersEnabled } = useContext(TroubleshootingContext);

  const options = useMemo(() => {
    return [
      { label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.lan), value: PingTypes.LAN },
      { label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.internet), value: PingTypes.INTERNET }
    ];
  }, [t]);

  return (
    <div className="d-flex flex-column w-25">
      <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.network)}</span>
      <div>
        <Controller
          name={name}
          control={control}
          rules={{
            required: true,
            validate: (value): boolean => {
              return !isEmpty(value);
            }
          }}
          render={({ field }): ReactElement => (
            <TNSSelect
              className="w-100"
              placeholder={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.select)}
              {...(errors[name] ? { status: "error" } : {})}
              onSelect={(e): void => {
                field.onChange(e);
              }}
              options={options}
              value={field.value}
              disabled={!filtersEnabled}
            />
          )}
        />
        {errors[name] && <small className="text-danger position-absolute">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.requiredField)}</small>}
      </div>
    </div>
  );
};
