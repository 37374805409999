import { makeAutoObservable } from "mobx";
import { ContactParams, ContactProfilesResponse } from "../../models/device-contact.model";
import { ContactProfilesService } from "../../services/contact-profile-service";
import { StatusCode } from "../../helpers/api/RequestHelper";
import { ContactProfileForm } from "../../components/contact/contact-form";
import { MessageHelper } from "../../../portal-app/helpers/shared/MessageHelper";
import i18n from "../../../../i18n";
import { TRANSLATION } from "../../utils/translation-keys";

export interface IContact {
  data?: ContactProfilesResponse;
  contactSelected?: ContactProfileForm;
  companyProfile?: string;
  entityProfile?: string;
  currentPage: number;
  loading: boolean;
  loadData: (params?: ContactParams) => Promise<ContactProfilesResponse | undefined>;
  loadDataByExport: (params?: ContactParams) => Promise<ContactProfilesResponse | undefined>;
  createContact: (contact: ContactProfileForm, contactEntityProfile: string) => Promise<void>;
  updateContact: (contactId: number, contactEntityProfile: string, contactCompanyProfile: string, contact: ContactProfileForm) => Promise<void>;
  deleteContact: (contactId: number) => Promise<void>;
  setContactSelected: (contact: ContactProfileForm) => void;
  setEntityProfile: (entityProfile: string) => void;
  clearContactSelected: () => void;
  setCompanyProfile: (companyProfile: string) => void;
  setCurrentPage: (page: number) => void;
}
class Contact implements IContact {
  data?: ContactProfilesResponse = undefined;
  contactSelected?: ContactProfileForm = undefined;
  companyProfile?: string;
  entityProfile?: string = undefined;
  currentPage = 1;
  loading = false;
  constructor() {
    makeAutoObservable(this);
  }

  loadData = async (params?: ContactParams): Promise<ContactProfilesResponse | undefined> => {
    try {
      this.loading = true;
      const response = await ContactProfilesService.getAll(params);
      if (response?.status === StatusCode.OK && response?.data) {
        this.data = response.data;
        return response.data;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.errorLoadingContacts));
    } finally {
      this.loading = false;
    }
  };

  loadDataByExport = async (params?: ContactParams): Promise<ContactProfilesResponse | undefined> => {
    try {
      const response = await ContactProfilesService.getAll(params);
      if (response?.status === StatusCode.OK && response?.data) {
        return response.data;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.errorLoadingContacts));
    }
  };

  createContact = async (contact: ContactProfileForm, contactEntityProfile: string): Promise<void> => {
    try {
      const updatedContact = {
        entityProfile: contactEntityProfile,
        ...contact
      };
      const response = await ContactProfilesService.create(updatedContact);
      if (response?.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18n.t(TRANSLATION.MODAL.contactCreatedSuccess));
      }
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.errorCreatingContact));
    }
  };

  updateContact = async (contactId: number, contactEntityProfile: string, contactCompanyProfile: string, contact: ContactProfileForm): Promise<void> => {
    try {
      const updatedContact = {
        entityProfile: contactEntityProfile,
        companyProfile: contactCompanyProfile,
        ...contact
      };
      const response = await ContactProfilesService.update(contactId, updatedContact);
      if (response?.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18n.t(TRANSLATION.MODAL.contactUpdatedSuccess));
      }
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.errorUpdatingContact));
    }
  };

  deleteContact = async (contactId: number): Promise<void> => {
    try {
      const response = await ContactProfilesService.deleteContact(contactId);
      if (response?.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18n.t(TRANSLATION.MODAL.contactDeletedSuccess));
      }
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.errorDeletingContact));
    }
  };

  setContactSelected = (contact: ContactProfileForm): void => {
    this.contactSelected = contact;
  };

  setEntityProfile = (entityProfile: string): void => {
    this.entityProfile = entityProfile;
  };

  setCompanyProfile = (companyProfile: string): void => {
    this.companyProfile = companyProfile;
  };

  clearContactSelected = (): void => {
    this.contactSelected = undefined;
  };

  setCurrentPage = (page: number): void => {
    this.currentPage = page - 1;
  };
}

const contact = new Contact();
export default contact;
