import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import { observer } from "mobx-react";
import { ContactCriteria, ContactInterface } from "../../../interfaces/devices/note/contact";
import { Variants, TNSButton, TNSInput, TNSModal, TNSRadio } from "@tns/ui-components";
import { store } from "../../../store/StoreMobx";
import { useParams } from "react-router-dom";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactInfo from "./ContactInfo";
import "./ContactForm.scss";
import { TRANSLATION } from "../../../utils/const/translation";
import { useTranslation } from "react-i18next";
import i18n from "../../../../../i18n";

interface ContactFormProps {
  setShowButton: React.Dispatch<React.SetStateAction<boolean>>;
  isView: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
}

export const ContactForm: React.FC<ContactFormProps> = observer(({ isView, isEdit, setShowButton }) => {
  const { deviceName } = useParams();
  const [open, setOpen] = useState(false);
  const { device } = store;
  const { t } = useTranslation();

  useEffect(() => {
    if (!isEdit) {
      setShowButton(false);
    }
  }, [isEdit, setShowButton]);

  const initialValues = useMemo(() => {
    return {
      name: device.note.dataContact?.name || "",
      telephoneNumber: device.note.dataContact?.telephoneNumber || "",
      email: device.note.dataContact?.email || "",
      mobileNumber: device.note.dataContact?.mobileNumber || "",
      criteria: device.note.dataContact?.criteria || ContactCriteria.OnlyInThisDevice
    };
  }, [device.note.dataContact]);

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm<ContactInterface>({
    defaultValues: initialValues,
    shouldUnregister: false,
    mode: "all"
  });

  const handleSave = useCallback(async () => {
    if (getValues("criteria") === ContactCriteria.OnlyInThisDevice) {
      await onSubmit();
    } else {
      setOpen(true);
    }
  }, []);

  const onSubmit = handleSubmit(async () => {
    const data = getValues();
    await device.note.updateContact(data, deviceName);
    setOpen(false);
    isView(true);
    setShowButton(false);
  });

  const modalTitle = useMemo(() => {
    return (
      <div className="d-flex flex-column gap-3">
        <span className="text-start">
          <FontAwesomeIcon icon={faCircleExclamation} className="mt-1" color="#F8E00D" />{" "}
          {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.someDevicesAlreadyHaveContactInformation)}
        </span>
        <span className="text-start">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.areYouSureYouWantToOverwriteIt)}</span>
      </div>
    );
  }, [t]);

  const handleCancel = useCallback(() => {
    reset();
    setShowButton(false);
    isView(true);
  }, [isView, setShowButton]);

  return isEdit ? (
    <ContactInfo contactData={device.note.dataContact} setShowButton={setShowButton} />
  ) : (
    <form className="d-flex flex-column gap-2 device-detail-container">
      <Row className="align-items-center">
        <Col md={6}>
          <Row className="align-items-center mt-2">
            <Col md={6} className="mt-2 d-flex justify-content-end align-items-end">
              <label>*{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.name)}</label>
            </Col>
            <Col md={6} className="align-items-start mt-2">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: true,
                  minLength: 5,
                  maxLength: 60,
                  pattern: /^[a-zA-Z\s]+$/
                }}
                render={({ field }): JSX.Element => (
                  <TNSInput {...field} placeholder={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.pleaseEnterTheContactsName)} />
                )}
              />
              {errors.name && errors.name?.type === "required" && (
                <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.nameIsMandatory)}</small>
              )}
              {errors.name && (errors.name?.type === "minLength" || errors.name?.type === "maxLength") && (
                <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.nameMustBeAtLeast5AndUpTo60Characters)}</small>
              )}
              {errors.name && errors.name?.type === "pattern" && (
                <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.nameFieldOnlyAllowsAZcharacters)}</small>
              )}
            </Col>
          </Row>
          <Row className="align-items-center mt-2">
            <Col md={6} className="mt-2 d-flex justify-content-end align-items-end">
              <label className="field-container">*{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.emailContact)}</label>
            </Col>
            <Col md={6} className="align-items-start mt-2">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  minLength: 5,
                  maxLength: 60,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                }}
                render={({ field }): JSX.Element => (
                  <TNSInput {...field} placeholder={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.pleaseEnterTheContactEmailAddress)} />
                )}
              />
              {errors.email && errors.email?.type === "required" && (
                <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.emailIsMandatory)}</small>
              )}
              {errors.email && (errors.email?.type === "minLength" || errors.email?.type === "maxLength") && (
                <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.emailMustBeAtLeast5AndUpTo100Characters)}</small>
              )}
              {errors.email && errors.email?.type === "pattern" && (
                <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.pleaseEnterAValidEmailAddress)}</small>
              )}
            </Col>
          </Row>
          <Row className="align-items-center mt-2">
            <Col md={6} className="mt-2 d-flex justify-content-end align-items-center">
              <label className="field-container">*{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.telephone)}</label>
            </Col>
            <Col md={6} className="align-items-start mt-2">
              <Controller
                name="telephoneNumber"
                control={control}
                rules={{
                  required: true,
                  minLength: 5,
                  maxLength: 15,
                  pattern: /^[0-9]+$/
                }}
                render={({ field }): JSX.Element => (
                  <TNSInput {...field} placeholder={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.pleaseEnterTheContactThelephoneNumber)} />
                )}
              />
              {errors.telephoneNumber && errors.telephoneNumber?.type === "pattern" && (
                <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.pleaseEnterAValidTelephoneNumber)}</small>
              )}
              {errors.telephoneNumber && errors.telephoneNumber?.type === "required" && (
                <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.telephoneIsMandatory)}</small>
              )}
              {errors.telephoneNumber && (errors.telephoneNumber?.type === "minLength" || errors.telephoneNumber?.type === "maxLength") && (
                <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.telephoneMustBeAtLeast5AndUpTo15Numbers)}</small>
              )}
            </Col>
          </Row>
          <Row className="align-items-center mt-2">
            <Col md={6} className="mt-2 d-flex justify-content-end align-items-center">
              <label className="field-container">*{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.mobilePhoneNumber)}</label>
            </Col>
            <Col md={6} className="align-items-start mt-2">
              <Controller
                name="mobileNumber"
                control={control}
                rules={{
                  required: true,
                  minLength: 5,
                  maxLength: 15,
                  pattern: /^[0-9]+$/
                }}
                render={({ field }): JSX.Element => (
                  <TNSInput {...field} placeholder={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.pleaseEnterTheContactMobilePhoneNumber)} />
                )}
              />
              {errors.mobileNumber && errors.mobileNumber?.type === "pattern" && (
                <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.pleaseEnterAValidMobilePhoneNumber)}</small>
              )}
              {errors.mobileNumber && errors.mobileNumber?.type === "required" && (
                <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.mobilePhoneNumberIsMandatory)}</small>
              )}
              {errors.mobileNumber && (errors.mobileNumber?.type === "minLength" || errors.mobileNumber?.type === "maxLength") && (
                <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.mobilePhoneNumberMustBeAtLeast5AndUpTo15Numbers)}</small>
              )}
            </Col>
          </Row>
        </Col>
        <Col md={6} className="mt-2 d-flex justify-content-center align-items-center">
          <Row className="align-items-center">
            <span className="m-0 pt-1">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.applyTheChangesToDataForOllDevicesContainingTheSame)}</span>
            <Controller
              name="criteria"
              control={control}
              render={({ field }): JSX.Element => (
                <TNSRadio
                  {...field}
                  defaultValue={ContactCriteria.OnlyInThisDevice}
                  isRadioGroup
                  direction="vertical"
                  options={[
                    { children: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.onlyInThisDevices), value: ContactCriteria.OnlyInThisDevice },
                    {
                      children: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.address),
                      value: ContactCriteria.Address
                    },
                    {
                      children: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.city),
                      value: ContactCriteria.City
                    },
                    {
                      children: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.country),
                      value: ContactCriteria.Country
                    }
                  ]}
                />
              )}
            />
          </Row>
        </Col>
      </Row>
      <Row className="align-items-end mt-4">
        <Col className="d-grid gap-2 d-md-flex justify-content-md-end">
          <TNSButton buttonVariant={Variants.Primary} onClick={handleSave} disabled={!isValid}>
            {t(TRANSLATION.SHARED.BUTTON.save)}
          </TNSButton>
          <TNSButton buttonVariant={Variants.Secondary} onClick={handleCancel}>
            {t(TRANSLATION.SHARED.BUTTON.cancel)}
          </TNSButton>
        </Col>
      </Row>
      <TNSModal
        closable={false}
        open={open}
        title={modalTitle}
        handleAccept={onSubmit}
        handleCancel={(): void => setOpen(false)}
        textOkButton={i18n.t(TRANSLATION.SHARED.update)}
        textCancelButton={i18n.t(TRANSLATION.SHARED.cancel)}
      />
    </form>
  );
});

export default ContactForm;
