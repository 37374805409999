import { Notifications } from "@tnso/api-sdk";

export class NotificationHelper {
  public static getIdsByNotifications = (notifications: Notifications[]): number[] => {
    const ids: number[] = [];
    notifications.forEach((notification) => {
      if (notification.id) {
        ids.push(notification.id);
      }
    });
    return ids;
  };

  public static getIsAllChecked = (notifications: number[], selectedRows: number[]): boolean => {
    let numberOfCoincidence = 0;
    notifications.forEach((notification) => {
      if (notification) {
        if (selectedRows.includes(notification)) {
          numberOfCoincidence++;
        }
      }
    });
    return numberOfCoincidence === notifications.length;
  };
}
