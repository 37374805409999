import React, { useMemo } from "react";
import { ChartData, ChartOptions } from "chart.js";
import { Chart as ChartJS, BarController, LineController, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, Filler } from "chart.js";
import { Chart } from "react-chartjs-2";
import { COLORS } from "../../utils/const/colors";
import { DateHelper, UnitConvertHelper } from "@tnso/shared";
import { DailyUsageStatus } from "../../interfaces/devices/cellular/cellularUsage";
import { TRANSLATION } from "../../utils/const/translation";
import { useTranslation } from "react-i18next";
import { CellularUsageHelper } from "../../helpers/device/CellularUsageHelper";
import { SIZE } from "../../utils/const/sizesDefinition";

ChartJS.register(CategoryScale, LinearScale, PointElement, BarController, LineController, LineElement, BarElement, Title, Tooltip, Legend, Filler);

enum DataSetsForChart {
  DATA = 0,
  CUMULATIVE = 1,
  THRESHOLD = 2
}

interface ChartDataInterface {
  type: "bar" | "line";
  data: number[];
  labels: string;
}

interface BandwidthUsageChartProps {
  dailyUsage?: number[];
  threshold?: number;
  statusData?: string[];
  categories?: string[];
}

export const BandwidthUsageChart: React.FC<BandwidthUsageChartProps> = ({ dailyUsage = [], threshold = 0, categories = [], statusData = ["#2C579E"] }) => {
  const { t } = useTranslation();
  const userTimezone = useMemo(() => {
    const userData = JSON.parse(localStorage.getItem("user") || "");
    return userData.timeZone;
  }, []);

  const { cumulativeUsage, predictiveUsage } = CellularUsageHelper.getCumulativeAndPredictiveData(dailyUsage, statusData);

  const areaColors = cumulativeUsage.map((value, index) => {
    if (statusData[index] === DailyUsageStatus.PREDICTED_NO_OVERAGE || statusData[index] === DailyUsageStatus.PREDICTED_OVERAGE) {
      return COLORS.BARS.TRANSPARENT;
    }
    return value > threshold ? COLORS.BARS.ACTUAL_OVERAGE : COLORS.BARS.ACTUAL_NO_OVERAGE;
  });
  const data: ChartData<ChartDataInterface["type"], ChartDataInterface["data"], ChartDataInterface["labels"]> = {
    labels: Array.from({ length: dailyUsage.length }, (_, i) =>
      DateHelper.convertTimestampToBrowserCulture(Number(categories[i] ?? "0"), userTimezone, { day: "2-digit", month: "2-digit" })
    ),
    datasets: [
      {
        type: "bar",
        data: predictiveUsage,
        backgroundColor: predictiveUsage.map((_, i) =>
          statusData[i] === DailyUsageStatus.PREDICTED_NO_OVERAGE || statusData[i] === DailyUsageStatus.PREDICTED_OVERAGE
            ? COLORS.BARS.PREDICTED_BACKGROUND
            : COLORS.BARS.ACTUAL_NO_OVERAGE
        ),
        borderColor: predictiveUsage.map((_, i) =>
          statusData[i] === DailyUsageStatus.PREDICTED_NO_OVERAGE || statusData[i] === DailyUsageStatus.PREDICTED_OVERAGE ? COLORS.BARS.PREDICTED_BORDER : COLORS.BARS.TRANSPARENT
        ),
        borderWidth: 1
      },
      {
        type: "line",
        data: cumulativeUsage,
        borderColor: "blue",
        backgroundColor: areaColors,
        fill: true,
        pointRadius: 5,
        pointBackgroundColor: (ctx): string => {
          return cumulativeUsage[ctx.dataIndex] >= threshold ? COLORS.BARS.ACTUAL_OVERAGE : COLORS.BARS.GREEN;
        },
        pointBorderColor: (ctx): string => {
          return cumulativeUsage[ctx.dataIndex] >= threshold ? COLORS.BARS.ACTUAL_OVERAGE : COLORS.BARS.GREEN;
        },
        segment: {
          borderColor: (ctx): string => {
            return cumulativeUsage[ctx.p0DataIndex] >= threshold ? COLORS.BARS.ACTUAL_OVERAGE : COLORS.BARS.GREEN;
          },
          backgroundColor: (ctx): string => {
            switch (statusData[ctx.p0DataIndex]) {
              case DailyUsageStatus.PREDICTED_NO_OVERAGE:
                return COLORS.BARS.TRANSPARENT;
              case DailyUsageStatus.PREDICTED_OVERAGE:
                return COLORS.BARS.TRANSPARENT;
              default:
                return cumulativeUsage[ctx.p0DataIndex] >= threshold ? COLORS.CHARTS.AREA.OVERAGE_BACKGROUND : COLORS.CHARTS.AREA.NO_OVERAGE_BACKGROUND;
            }
          },
          borderDash: (ctx): number[] => {
            return statusData[ctx.p0DataIndex] === DailyUsageStatus.PREDICTED_NO_OVERAGE || statusData[ctx.p0DataIndex] === DailyUsageStatus.PREDICTED_OVERAGE
              ? SIZE.CELLULAR_USAGE_CHART.DASH_ARRAY_FOR_BAR
              : [];
          }
        }
      },
      {
        type: "line",
        data: [threshold, ...categories.map(() => threshold)],
        borderColor: COLORS.BARS.ORANGE,
        borderWidth: 1.5,
        pointRadius: 0,
        fill: false,
        spanGaps: true
      }
    ]
  };

  const options: ChartOptions = {
    backgroundColor: COLORS.CHARTS.BACKGROUND_COLOR,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            if (context[0].datasetIndex === DataSetsForChart.THRESHOLD) {
              return [];
            }
            const index = context[0].dataIndex;
            const date = categories[index];
            return DateHelper.convertTimestampToBrowserCulture(Number(date), userTimezone, { day: "2-digit", month: "2-digit" });
          },
          label: function (context) {
            if (context.datasetIndex === 2) {
              return [];
            }
            const datasetLabel = context.dataset.label || "";
            const value = context.raw;
            const formattedValue = `${UnitConvertHelper.convertBytesToMebiBytes(Number(value)).toFixed(2)} ${t(
              TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.MiB
            )}`;
            return `${datasetLabel}: ${formattedValue}`;
          },
          labelColor: function (context) {
            if (context.datasetIndex === DataSetsForChart.DATA) {
              if (statusData[context.dataIndex] === DailyUsageStatus.ACTUAL_NO_OVERAGE || statusData[context.dataIndex] === DailyUsageStatus.ACTUAL_OVERAGE) {
                return {
                  borderColor: COLORS.BARS.ACTUAL_NO_OVERAGE,
                  backgroundColor: COLORS.BARS.ACTUAL_NO_OVERAGE
                };
              } else {
                return {
                  borderColor: COLORS.BARS.ACTUAL_NO_OVERAGE,
                  backgroundColor: COLORS.BARS.PREDICTED_BACKGROUND
                };
              }
            }
            if (context.datasetIndex === DataSetsForChart.CUMULATIVE) {
              if (cumulativeUsage[context.dataIndex] < threshold) {
                return {
                  borderColor: COLORS.BARS.GREEN,
                  backgroundColor: COLORS.BARS.GREEN
                };
              } else {
                return {
                  borderColor: COLORS.BARS.RED,
                  backgroundColor: COLORS.BARS.RED
                };
              }
            }
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: false
        },
        ticks: {
          color: COLORS.CHARTS.DARK_LABEL_COLOR,
          callback: (val: number | string, index: number): string => {
            return DateHelper.convertTimestampToBrowserCulture(Number(categories[index]), userTimezone, { day: "2-digit", month: "2-digit" });
          }
        }
      },

      y: {
        title: {
          display: true,
          text: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.MiB)
        },
        grid: {
          color: COLORS.CHARTS.GRID_COLOR
        },

        beginAtZero: true,
        ticks: {
          maxTicksLimit: 5,
          color: COLORS.CHARTS.DARK_LABEL_COLOR,
          callback: (val: number | string): string => {
            const mebibytes = UnitConvertHelper.convertBytesToMebiBytes(Number(val));
            const roundedMebibytes = mebibytes < 50 ? mebibytes.toFixed(0) : Math.round(mebibytes / 50) * 50;
            return `${roundedMebibytes} ${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.MiB)}`;
          }
        }
      }
    }
  };

  return <Chart type="bar" data={data} options={options} />;
};
