import React, { useCallback, useContext } from "react";
import { TroubleshootingContext } from "../../../../../../components/device/troubleshooting/context/troubleshooting-context";
import { observer } from "mobx-react";
import { TroubleshootingFilters } from "../troubleshooting/TroubleshootingFilters";
import { Terminal } from "../../../../../../components/device/troubleshooting/terminal";
import { GapSizes, TNSContainer } from "@tns/ui-components";

export const Troubleshooting: React.FC = observer(() => {
  const context = useContext(TroubleshootingContext);

  const handleClear = useCallback(() => {
    context.clearTerminal();
  }, [context]);

  return (
    <TNSContainer gap={GapSizes.large} className="containerColums">
      <TroubleshootingFilters />
      <Terminal message={context.terminal} onClear={handleClear} />
    </TNSContainer>
  );
});
