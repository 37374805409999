import React, { Key, useCallback } from "react";

import {
  InlineResponse20050 as GetTicketsResponse,
  ChangeTickets,
  IncidentGETApi,
  Incidents,
  IncidentPOSTApi,
  IncidentPATCHApi,
  IncidentsBody,
  DevicesGETApi,
  DeviceDataResponse,
  InlineResponse20082,
  ConnectivityStatusEnum
} from "@tnso/api-sdk";
import { MessageHelper } from "../../helpers/shared/meesage-helper";
import { TRANSLATION } from "../../translations/translation";
import { i18nInstance } from "../../../../i18n";
import { InstanceType, useApiController } from "../../../../shared/hooks/use-api-controller";
import { DeviceParams, OperationalStatus } from "../../../../app/portal-app/interfaces/devices/devices";
import { StatusCode } from "@tnso/api-core";
export interface IncidentManagementContextProviderProps {
  children: React.ReactNode;
}

export { GetTicketsResponse, ChangeTickets };

export interface TicketsParams {
  ticketNumber?: string;
  fromStartDate?: number;
  toStartDate?: number;
  acna?: string;
  tnsDeviceName?: string;
  status?: string;
  recordsPerPage?: number;
  startAtRecord?: number;
  orderBy?: string;
  sortBy?: string | Key;
}

export interface Ticket {
  ticketNumber?: string;
  description?: string;
  startDate?: number;
  acna?: string;
  knownAs?: string;
  tnsDeviceName?: string;
  status?: string;
  impact?: string;
  urgency?: string;
  priority?: string;
  customerLog?: string;
  lastStatusDate?: number;
  update?: string;
}

export interface IncidentManagementContextValue {
  getTickets: (params?: TicketsParams) => Promise<InlineResponse20082 | undefined>;
  getDevices: (params?: DeviceParams) => Promise<DeviceDataResponse[] | undefined>;
  getAcna: () => string[] | undefined;
  createTicket: (ticket: IncidentsBody) => Promise<string | undefined>;
  editTicket: (ticket: Incidents) => Promise<number | undefined>;
  getTicketByNumber: (ticketId: string) => Promise<Incidents | undefined>;
}

export const initialValue: IncidentManagementContextValue = {
  getTickets: (): Promise<InlineResponse20082 | undefined> => Promise.resolve(undefined),
  getDevices: (): Promise<DeviceDataResponse[] | undefined> => Promise.resolve(undefined),
  getAcna: (): string[] | undefined => undefined,
  createTicket: (): Promise<string | undefined> => Promise.resolve(undefined),
  editTicket: (): Promise<number | undefined> => Promise.resolve(undefined),
  getTicketByNumber: (): Promise<Incidents | undefined> => Promise.resolve(undefined)
};

export const IncidentManagementContext = React.createContext(initialValue);

const IncidentManagementContextProvider: React.FC<IncidentManagementContextProviderProps> = ({ children }) => {
  const apiGetController = useApiController(IncidentGETApi, InstanceType.DefaultWithBase);
  const apiPatchController = useApiController(IncidentPATCHApi, InstanceType.DefaultWithBase);
  const apiPostController = useApiController(IncidentPOSTApi, InstanceType.DefaultWithBase);
  const devicesApiGetController = useApiController(DevicesGETApi, InstanceType.DefaultWithBase);

  const getTickets = useCallback(
    async (params?: TicketsParams): Promise<InlineResponse20082 | undefined> => {
      try {
        const { ticketNumber, fromStartDate, toStartDate, acna, tnsDeviceName, status, recordsPerPage, startAtRecord, orderBy, sortBy } = params || {};
        const result = await apiGetController.incidentsGet(ticketNumber, fromStartDate, toStartDate, acna, tnsDeviceName, status, recordsPerPage, startAtRecord, orderBy, sortBy);
        return result.data;
      } catch (error) {
        MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
        console.error(error);
        return;
      }
    },
    [apiGetController]
  );

  const getAcna = useCallback((): string[] | undefined => {
    try {
      const acnasList = localStorage.getItem("acnasList") ? JSON.parse(localStorage.getItem("acnasList") || "") : [];
      return acnasList;
    } catch (error) {
      MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
      console.error(error);
      return;
    }
  }, []);

  const getDevices = useCallback(
    async (params?: DeviceParams): Promise<DeviceDataResponse[] | undefined> => {
      try {
        const { acnas, acna, knownAs, entityProfileName, groups, connectivityStatus, customerDeviceName, country, countries, city, zipCode, state } = params || {};
        const result = await devicesApiGetController.devicesGet(
          acnas,
          acna,
          knownAs,
          entityProfileName,
          groups,
          connectivityStatus as ConnectivityStatusEnum,
          customerDeviceName,
          country,
          countries,
          city,
          zipCode,
          state,
          OperationalStatus.Operational
        );
        return result.data.devices || [];
      } catch (error) {
        MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
        console.error(error);
        return;
      }
    },
    [devicesApiGetController]
  );

  const createTicket = useCallback(
    async (ticket: IncidentsBody): Promise<string | undefined> => {
      try {
        const response = await apiPostController.incidentsPost(ticket);
        if (response.data && response.status === StatusCode.CREATED) {
          MessageHelper.successMessage(i18nInstance.t(TRANSLATION.INCIDENTMANAGEMENT.ticketCreatedSuccessfully, { ticketNumber: response.data.ticketNumber }));
          return response.data.ticketNumber;
        }
      } catch (error) {
        MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
        console.error(error);
        return;
      }
    },
    [apiPostController]
  );

  const editTicket = useCallback(
    async (ticket: Incidents): Promise<number | undefined> => {
      try {
        if (ticket && ticket.customerLog && ticket.ticketNumber) {
          const response = await apiPatchController.incidentsTicketNumberPatch({ customerLog: ticket.customerLog }, ticket.ticketNumber);
          if (response.status === StatusCode.NO_CONTENT) {
            MessageHelper.successMessage(i18nInstance.t(TRANSLATION.INCIDENTMANAGEMENT.ticketUpdatedSuccessfully, { ticketNumber: ticket.ticketNumber }));
            return response.status;
          }
        }
      } catch (error) {
        MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
        console.error(error);
      }
    },
    [apiPatchController]
  );

  const getTicketByNumber = useCallback(
    async (ticketId: string): Promise<Incidents | undefined> => {
      try {
        console.log(ticketId);
        const result = await apiGetController.incidentsGet(ticketId);
        return result.data.incidentList ? result.data.incidentList.find((ticket) => ticket.ticketNumber === ticketId) : undefined;
      } catch (error) {
        MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
        console.error(error);
        return;
      }
    },
    [apiGetController]
  );

  return (
    <IncidentManagementContext.Provider
      value={{
        getTickets,
        getDevices,
        getAcna,
        createTicket,
        editTicket,
        getTicketByNumber
      }}>
      {children}
    </IncidentManagementContext.Provider>
  );
};

export default IncidentManagementContextProvider;
