import { useContext } from "react";
import { ConfigContext } from "../../../contexts/configuration/ConfigurationContext";
import { FeatureFlags } from "../../../contexts/configuration/EnvironmentVariables";

// These flags enable or disable the content view of each device details tabs
export const TABS_ENABLED = (): Record<string, string | number | boolean> => {
  const context = useContext(ConfigContext);
  return {
    details: context.canPerformAction(FeatureFlags.DetailsEnabled),
    wireless: context.canPerformAction(FeatureFlags.WirelessEnabled),
    hardware: context.canPerformAction(FeatureFlags.HardwareEnabled),
    cellularUsage: context.canPerformAction(FeatureFlags.CellularUsageEnabled),
    uptime: context.canPerformAction(FeatureFlags.UptimeEnabled),
    lvc: context.canPerformAction(FeatureFlags.LvcEnabled),
    members: context.canPerformAction(FeatureFlags.MembersEnabled),
    connections: context.canPerformAction(FeatureFlags.MembersEnabled),
    notes: context.canPerformAction(FeatureFlags.NotesEnabled),
    events: context.canPerformAction(FeatureFlags.EventsEnabled),
    membersConnectivity: context.canPerformAction(FeatureFlags.MembersEnabled),
    devices: "true",
    contact: "true",
    connectedDevices: context.canPerformAction(FeatureFlags.ConnectedDevicesEnabled),
    troubleshooting: context.canPerformAction(FeatureFlags.TroubleshootingEnabled),
    firewallRules: context.canPerformAction(FeatureFlags.FirewallRulesEnabled),
    softwareTab: context.canPerformAction(FeatureFlags.SoftwareTabEnabled)
  };
};
