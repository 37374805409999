import React from "react";

interface Props {
  color: string;
  label: string;
  width?: string;
  height?: string;
  dashed?: boolean;
  borderColor?: string;
}

export const LegendOptions: React.FC<Props> = ({ color, label, width, height, dashed, borderColor }) => {
  const style = {
    backgroundColor: color,
    width: width ?? "15px",
    height: height ?? "15px",
    borderRadius: "5%",
    border: `1px ${dashed ? "dashed" : "solid"} ${borderColor ? borderColor : "white"}`
  };

  return (
    <div className="d-flex gap-1 align-items-center justify-content-start" data-testid="legend-options-component">
      <div style={style} />
      <small className="text-leyend">{label}</small>
    </div>
  );
};
