import { CompanyProfile, CompanyProfileWithACNAs } from "../../app/portal-app/interfaces/companyProfiles/company";
import { DeviceAddress, DeviceTable } from "../../app/portal-app/interfaces/devices/devices";
import { DevicesResponse, LVCResponse } from "../../app/portal-app/interfaces/devices/response/response";
import { LVCTable } from "../../app/portal-app/interfaces/devices/lvc/lvc";
import { ACNA } from "../../app/portal-app/interfaces/companyProfiles/acna/acna";
import { ChangeTicketsExport, ChangeTicketsResponse } from "../../app/portal-app/interfaces/changeTickets/changeTickets";
import { DateHelper } from "@tnso/shared";
import { store } from "../../app/portal-app/store/StoreMobx";
import { CellularSignal, CellularSignalExport, Period } from "../../app/portal-app/interfaces/devices/cellular/cellularSignal";
import { User, UserResponse, UserStatusForm, UserTable } from "../../app/portal-app/interfaces/users/user";
import { ExportMemberTable, MembersExportResponse } from "../../app/portal-app/interfaces/memberConnectivity/memberConnectivity";
import { CellularUsage, CellularUsageTable } from "../../app/portal-app/interfaces/devices/cellular/cellularUsage";
import { UnitConvertHelper } from "./UnitConvertHelper";
import { IInterfaceMetrics, IInterfaceMetricsToExport } from "src/app/monitoring/models/devices/dhi/dhi";
import i18n from "../../i18n";
import { TRANSLATION } from "../../app/portal-app/utils/const/translation";
import { TRANSLATION as CONNECTEDDEVICES } from "../../app/monitoring/utils/const/translation";
import { Roles, RolesByUserList } from "../../app/portal-app/interfaces/auth/roleAndPermission/role";
import { StorageUsageMonthly, CPUCoresData, MemoryUsageMonthly, HardwareExport, TemperatureData, Measurement } from "../../app/monitoring/models/devices/hardware/hardware";
import { ConnectedDevicesResponse, ConnectedDevicesToExport } from "src/app/monitoring/models/connected-devices/connected-devices";
import { UserInfo } from "../models/auth/login/login";
import { NotificationToExport } from "../models/notification.model";
import { NotificationsResponse } from "src/contexts/notifications/notifications-context";

interface AddressComponentTypes {
  street_number?: string;
  route?: string;
  neighborhood?: string;
  locality?: string;
  administrative_area_level_1?: string;
  administrative_area_level_2?: string;
  country?: string;
  postal_code?: string;
  postal_town?: string;
}

interface AddressComponentTypes {
  street_number?: string;
  route?: string;
  neighborhood?: string;
  locality?: string;
  administrative_area_level_1?: string;
  administrative_area_level_2?: string;
  country?: string;
  postal_code?: string;
  postal_town?: string;
}

enum AddressComponentEnum {
  streetNumber = "street_number",
  route = "route",
  neighborhood = "neighborhood",
  locality = "locality",
  administrative_area_level_1 = "administrative_area_level_1",
  administrative_area_level_2 = "administrative_area_level_2",
  country = "country",
  postal_code = "postal_code",
  postal_town = "postal_town"
}

interface OptionParam {
  label: string;
  value: string;
}

export class MapperHelper {
  public static mapLVC(source?: LVCResponse): LVCTable[] {
    if (source && source.lvcs) {
      const dataMapped = source.lvcs.map((item) => {
        return {
          lvcTicketNumber: item.lvcTicketNumber,
          status: item.status,
          devices: `${item.ep1DeviceName} - ${item.ep2DeviceName}`,
          startDate: item.startDate,
          ep1Acna: item.ep1Acna,
          ep1Host: item.ep1Host,
          ep1RealIp: item.ep1RealIp,
          knowsEp2As: item.knowsEp2As,
          ep2Acna: item.ep2Acna,
          ep2Host: item.ep2Host,
          ep2RealIp: item.ep2RealIp,
          knowsEp1As: item.knowsEp1As
        };
      });
      return dataMapped;
    } else {
      return [];
    }
  }

  public static mapLVCExport(source?: LVCResponse): LVCTable[] {
    const timeZone = store.auth.userInfo?.timeZone;
    if (source && source.lvcs) {
      const dataMapped = source.lvcs.map((item) => {
        const startDate = item.startDate
          ? new Date(item.startDate * 1000).toLocaleString("es-ES", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
              timeZone
            })
          : "";
        const formattedDateWithoutDots = startDate.replace(/\.(\s)?/g, "");
        return {
          "LVC Ticket Number": item.lvcTicketNumber,
          Status: item.status,
          Devices: `${item.ep1DeviceName} - ${item.ep2DeviceName}`,
          "Start Date": formattedDateWithoutDots,
          "EP1 ACNA": item.ep1Acna,
          "EP1 Host": item.ep1Host,
          "EP1 Real IP": item.ep1RealIp,
          "Knows EP2 As": item.knowsEp2As,
          "EP2 ACNA": item.ep2Acna,
          "EP2 Host": item.ep2Host,
          "EP2 Real IP": item.ep2RealIp,
          "Knows EP1 As": item.knowsEp1As
        };
      });
      return dataMapped as unknown as LVCTable[];
    } else {
      return [];
    }
  }

  public static mapCompanyProfile(source: CompanyProfileWithACNAs[]): CompanyProfile[] {
    const dataMapped = source.map((item) => {
      return {
        ...item,
        acnas: item.acnas?.map((acna) => acna.name + "-" + acna.knownAs).join(",")
      };
    });
    return dataMapped;
  }

  public static mapDevices(source?: DevicesResponse): DeviceTable[] {
    if (source && source.devices) {
      const dataMapped = source.devices.map((device) => {
        const groupsNames = device.groups?.map((g) => g.name).join(", ");
        return {
          [i18n.t(TRANSLATION.SHARED.TABLE.company)]: device.knownAs,
          [i18n.t(TRANSLATION.SHARED.TABLE.acna)]: device.acna,
          [i18n.t(TRANSLATION.SHARED.TABLE.tnsDeviceName)]: device.tnsDeviceName,
          [i18n.t(TRANSLATION.SHARED.TABLE.customerDeviceName)]: device.customerDeviceName,
          [i18n.t(TRANSLATION.SHARED.TABLE.groups)]: groupsNames,
          [i18n.t(TRANSLATION.SHARED.TABLE.connectivityStatus)]: device.connectivityStatus,
          [i18n.t(TRANSLATION.SHARED.TABLE.operationalStatus)]: device.operationalStatus,
          [i18n.t(TRANSLATION.SHARED.TABLE.carrier)]: device.carrier,
          [i18n.t(TRANSLATION.SHARED.TABLE.country)]: device.country,
          [i18n.t(TRANSLATION.SHARED.TABLE.hasGeolocation)]: device.hasGeolocation ? i18n.t(TRANSLATION.SHARED.DATATABLE.yes) : i18n.t(TRANSLATION.SHARED.DATATABLE.no)
        };
      });
      return dataMapped as unknown as DeviceTable[];
    } else {
      return [];
    }
  }

  public static acnasToAcnasWithKnownAs(source: string): ACNA[] {
    const acnas = source.split(",");
    const acnasWithKnownAs = acnas.map((acna) => {
      const acnaWithKnownAs = acna.split("-");
      return {
        name: acnaWithKnownAs[0],
        knownAs: acnaWithKnownAs[1]
      };
    });
    return acnasWithKnownAs;
  }

  public static userToUserTable(source: UserResponse): UserTable[] {
    const dataMapped = source.users.map((item) => {
      return {
        "User name": item.username,
        "First name": item.firstName,
        "Last name": item.lastName,
        "Email Address": item.email,
        Status: item.enabled ? UserStatusForm.Enabled : UserStatusForm.Disabled,
        "Company Adminstration": item.companyProfile,
        Profile: item.entityProfile,
        Role: item.role
      };
    });
    return dataMapped as UserTable[];
  }

  public static mapChangeTicketsToExport(source?: ChangeTicketsResponse): ChangeTicketsExport[] {
    if (source && source.changeTickets) {
      const dataMapped = source.changeTickets.map((item) => {
        return {
          [i18n.t(TRANSLATION.SHARED.TABLE.changeStartTime)]: DateHelper.getDateFromTimestampWithTimeZone(item.changeStartTime, store.auth.userInfo?.timeZone),
          [i18n.t(TRANSLATION.SHARED.TABLE.changeTicketId)]: item.changeTicketId,
          [i18n.t(TRANSLATION.SHARED.TABLE.companyName)]: item.companyName,
          [i18n.t(TRANSLATION.SHARED.TABLE.changeDescription)]: item.changeDescription,
          [i18n.t(TRANSLATION.SHARED.TABLE.impactType)]: item.impactType,
          [i18n.t(TRANSLATION.SHARED.TABLE.statusOfChange)]: item.statusOfChange,
          [i18n.t(TRANSLATION.SHARED.TABLE.maximumDuration)]: item.maximumDuration.toFixed(1)
        };
      });
      return dataMapped;
    }
    return [];
  }

  public static acnasAndKnownAs(): ACNA[] {
    const acnas = store.acna.acnas || []; // We use and empty array in case acnas is undefined

    return acnas.map((acna) => {
      return {
        name: acna.name,
        knownAs: acna.knownAs
      };
    });
  }

  public static mapMembersExport(source?: MembersExportResponse): ExportMemberTable[] {
    const dataMapped: ExportMemberTable[] = [];

    if (source && source.members) {
      source.members.forEach((item) => {
        const row: ExportMemberTable = {
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.sourceMember)]: item.acna + " - " + item.knownAs,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.acnaStatus)]: item.acnaStatus,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.destinationMember)]: item.destAcna + " - " + item.destKnownAs,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.statusOfMemberConnection)]: item.memberConnectivityStatus,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.sourceTNSDeviceName)]: item.srcTnsDeviceName,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.sourceCustomerDeviceName)]: item.srcCustomerDeviceName,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.destAcnaStatus)]: item.destAcnaStatus,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.destinationTNSDeviceName)]: item.destTnsDeviceName,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.destinationCustomerDeviceName)]: item.destCustomerDeviceName,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.wanInstance)]: item.interfaceType,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.tunnelStatus)]: item.interfaceStatus
        };
        dataMapped.push(row);
      });
    }
    dataMapped.sort((a, b) => {
      const sourceMemberComparison = a[i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.sourceMember)].localeCompare(
        b[i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.sourceMember)]
      );
      if (sourceMemberComparison !== 0) {
        return sourceMemberComparison;
      }
      return a[i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.destinationMember)].localeCompare(b[i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.destinationMember)]);
    });
    return dataMapped;
  }

  static mapRawDataExport = (source?: CellularSignal[], interval?: string): CellularSignalExport[] => {
    if (source) {
      return source.map((item) => {
        const dateTime =
          interval === Period.Daily
            ? DateHelper.formatTimestampInHours(item.dateTime, store.auth.userInfo?.timeZone)
            : DateHelper.formatTimestampInDays(item.dateTime, store.auth.userInfo?.timeZone);
        return {
          "TNS Device Name": item.name,
          "Date Time": dateTime,
          "Technology Type": item.technologyType,
          "Norm Low": item.normLow,
          "Norm High": item.normHigh,
          "Norm Average": item.normAverage,
          "RSSI Average": item.rssiAverage,
          "RSRP Average": item.rsrpAverage,
          "RSRQ Average": item.rsrqAverage,
          "RSCP Average": item.rscpAverage,
          "EC/IO Average": item.ecioAverage,
          "SINR Average": item.sinrAverage,
          "EC/IO Count": item.ecioCount,
          "RSCP Count": item.rscpCount,
          "RSRQ Count": item.rsrqCount,
          "RSRP Count": item.rsrpCount,
          "SINR Count": item.sinrCount,
          "EC/IO Signal Quality": item.ecioSignalQuality,
          "Norm Signal Quality": item.normSignalQuality,
          "RSSI Signal Quality": item.rssiSignalQuality,
          "RSRP Signal Quality": item.rsrpSignalQuality,
          "RSRQ Signal Quality": item.rsrqSignalQuality,
          "RSCP Signal Quality": item.rscpSignalQuality,
          "SINR Signal Quality": item.sinrSignalQuality,
          "Model Name": item.modelName,
          Service: item.service
        };
      });
    } else {
      return [];
    }
  };

  public static mapUsageToExport(source?: CellularUsage[]): CellularUsageTable[] {
    if (source) {
      const dataMapped: CellularUsageTable[] = source
        .slice()
        .reverse()
        .map((item) => {
          const billingPeriod = `${DateHelper.getDateFromTimestampWithTimeZoneWithoutTime(
            Number(item.startDate),
            "UTC"
          )} - ${DateHelper.getDateFromTimestampWithTimeZoneWithoutTime(Number(item.endDate), "UTC")}`;
          return {
            [i18n.t(TRANSLATION.SHARED.TABLE.tnsDeviceName)]: item.name,
            [i18n.t(TRANSLATION.SHARED.TABLE.billingPeriod)]: billingPeriod,
            [i18n.t(TRANSLATION.SHARED.TABLE.planSize)]: `${UnitConvertHelper.convertBytesToMegaBytes(item.planSize).toFixed(2)} MB`,
            [i18n.t(TRANSLATION.SHARED.TABLE.txcnt)]: `${UnitConvertHelper.convertBytesToMegaBytes(item.txcnt).toFixed(2)} MB`,
            [i18n.t(TRANSLATION.SHARED.TABLE.rxcnt)]: `${UnitConvertHelper.convertBytesToMegaBytes(item.rxcnt).toFixed(2)} MB`,
            [i18n.t(TRANSLATION.SHARED.TABLE.totalData)]: `${UnitConvertHelper.convertBytesToMegaBytes(item.txcnt + item.rxcnt).toFixed(2)} MB`,
            [i18n.t(TRANSLATION.SHARED.TABLE.percent)]: `${item.percent}%`,
            [i18n.t(TRANSLATION.SHARED.TABLE.overage)]: `${UnitConvertHelper.convertBytesToMegaBytes(item.overage).toFixed(2)} MB`
          };
        });

      return dataMapped;
    }
    return [];
  }

  public static mapToExportInterfaceMetrics(source?: IInterfaceMetrics[]): IInterfaceMetricsToExport[] {
    if (source) {
      return source.map((item) => ({
        [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.interfaceName)]: item.name,
        [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.interfaceStatus)]: item.status,
        [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.utilization)]: `${item.utilization} Mbps`,
        [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.interfaceConfigSpeed)]: `${item.configSpeed} Mbps`
      }));
    }
    return [];
  }

  public static mapTemperatureUsageToExport(source?: TemperatureData, isMonthly?: boolean, isDaily?: boolean, measurement?: string): HardwareExport[] {
    if (source) {
      const data = measurement === Measurement.temperatureInFahrenheit ? source.temperatureInFahrenheit : source.temperatureInCelsius;
      const meansurementType = measurement === Measurement.temperatureInFahrenheit ? Measurement.F : Measurement.C;
      const timeZone = store.auth.userInfo?.timeZone;
      const dataMapped: HardwareExport[] = data
        .slice()
        .reverse()
        .map((item) => {
          if (isMonthly) {
            return {
              [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.date)]: DateHelper.convertTimestampToBrowserCulture(
                Number(item.timestamp),
                timeZone,
                isDaily ? { hour: "numeric", minute: "numeric", hourCycle: "h23" } : { day: "2-digit", month: "2-digit", year: "numeric" }
              ),
              [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.temperature)]: `${item.value}°${meansurementType}`
            };
          }
          return {
            [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.DOUGHNUTCARD.month)]: i18n.t(
              TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.UPTIME.MONTHSLEYEND[
                item.month?.toLowerCase() as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.UPTIME.MONTHSLEYEND
              ]
            ),
            [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.DOUGHNUTCARD.year)]: item.year,
            [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.temperature)]: `${item.value}°${meansurementType}`
          };
        });

      return dataMapped;
    }
    return [];
  }

  public static mapStorageUsageToExport(source?: StorageUsageMonthly[], isMonthly?: boolean, isDaily?: boolean): HardwareExport[] {
    if (source) {
      const timeZone = store.auth.userInfo?.timeZone;
      const dataMapped: HardwareExport[] = source
        .slice()
        .reverse()
        .map((item) => {
          if (isMonthly) {
            return {
              [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.date)]: DateHelper.convertTimestampToBrowserCulture(
                Number(item.timestamp),
                timeZone,
                isDaily ? { hour: "numeric", minute: "numeric", hourCycle: "h23" } : { day: "2-digit", month: "2-digit", year: "numeric" }
              ),
              [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.storageUsed)]: `${item.value} ${[
                i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.kb)
              ]}`
            };
          }
          return {
            [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.DOUGHNUTCARD.month)]: i18n.t(
              TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.UPTIME.MONTHSLEYEND[
                item.month?.toLowerCase() as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.UPTIME.MONTHSLEYEND
              ]
            ),
            [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.DOUGHNUTCARD.year)]: item.year,
            [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.storageUsed)]: `${item.value} ${[
              i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.kb)
            ]}`
          };
        });

      return dataMapped;
    }
    return [];
  }

  public static mapMemoryUsageToExport(source?: MemoryUsageMonthly[], isMonthly?: boolean, isDaily?: boolean): HardwareExport[] {
    if (source) {
      const timeZone = store.auth.userInfo?.timeZone;
      const dataMapped: HardwareExport[] = source
        .slice()
        .reverse()
        .map((item) => {
          if (isMonthly) {
            return {
              [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.date)]: DateHelper.convertTimestampToBrowserCulture(
                Number(item.timestamp),
                timeZone,
                isDaily ? { hour: "numeric", minute: "numeric", hourCycle: "h23" } : { day: "2-digit", month: "2-digit", year: "numeric" }
              ),
              [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.memoryUsed)]: `${item.value}%`
            };
          }
          return {
            [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.DOUGHNUTCARD.month)]: i18n.t(
              TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.UPTIME.MONTHSLEYEND[
                item.month?.toLowerCase() as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.UPTIME.MONTHSLEYEND
              ]
            ),
            [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.DOUGHNUTCARD.year)]: item.year,
            [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.memoryUsed)]: `${item.value}%`
          };
        });

      return dataMapped;
    }
    return [];
  }

  public static mapCPUUsageToExport(source?: CPUCoresData, isMonthly?: boolean, isDaily?: boolean): HardwareExport[] {
    if (source) {
      const timeZone = store.auth.userInfo?.timeZone;
      const dataMapped: HardwareExport[] = Object.values(source ?? {})
        .map((core, index) => {
          if (isMonthly) {
            return core.map(({ timestamp, value }) => ({
              [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.name)]: Object.keys(source ?? {})[index],
              [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.date)]: DateHelper.convertTimestampToBrowserCulture(
                Number(timestamp),
                timeZone,
                isDaily ? { hour: "numeric", minute: "numeric", hourCycle: "h23" } : { day: "2-digit", month: "2-digit", year: "numeric" }
              ),
              [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.cpuUsed)]: `${value}%`
            }));
          }
          return core.map(({ year, month, value }) => ({
            [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.name)]: Object.keys(source ?? {})[index],
            [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.DOUGHNUTCARD.month)]: month,
            [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.DOUGHNUTCARD.year)]: year,
            [i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.cpuUsed)]: `${value}%`
          }));
        })
        .flat();

      return dataMapped;
    }
    return [];
  }

  public static mapConnectedDevicesToExport(source?: ConnectedDevicesResponse): ConnectedDevicesToExport[] {
    if (source) {
      return source.connectedDevices.map((item) => ({
        [i18n.t(CONNECTEDDEVICES.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.connectedDeviceName)]: item.connectedDeviceName,
        [i18n.t(CONNECTEDDEVICES.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.ipAddress)]: item.ipAddress,
        [i18n.t(CONNECTEDDEVICES.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.macAddress)]: item.macAddress,
        [i18n.t(CONNECTEDDEVICES.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.lanPort)]: item.routerPortName
      }));
    }
    return [];
  }

  public static mapConnectedDevicesByMenuToExport(source?: ConnectedDevicesResponse): ConnectedDevicesToExport[] {
    const userInfo: UserInfo = JSON.parse(localStorage.getItem("user") ?? "{}");
    if (source) {
      return source.connectedDevices.map((item) => ({
        [i18n.t(CONNECTEDDEVICES.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.connectedDeviceName)]: item.connectedDeviceName,
        [i18n.t(CONNECTEDDEVICES.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.ipAddress)]: item.ipAddress,
        [i18n.t(CONNECTEDDEVICES.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.macAddress)]: item.macAddress,
        [i18n.t(CONNECTEDDEVICES.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.lanPort)]: item.routerPortName,

        [i18n.t(CONNECTEDDEVICES.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.acna)]: item.acna,
        [i18n.t(CONNECTEDDEVICES.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.customerDeviceName)]: item.customerDeviceName,
        [i18n.t(CONNECTEDDEVICES.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.tnsDeviceName)]: item.tnsDeviceName,
        [i18n.t(CONNECTEDDEVICES.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.lastUpdatedAt)]: item.lastUpdatedAt
          ? DateHelper.convertTimestampToBrowserCulture(Number(item.lastUpdatedAt), userInfo?.timeZone, {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hourCycle: "h23"
            })
          : ""
      }));
    }
    return [];
  }

  public static mapSelectedNewAddress(place: google.maps.places.PlaceResult): DeviceAddress {
    const placeAux: AddressComponentTypes = {};
    const deviceAddress: DeviceAddress = {
      street1: "",
      street2: "",
      city: "",
      state: "",
      countryCode: "",
      zipCode: "",
      latitude: "",
      longitude: ""
    };
    place.address_components?.map((component) => {
      Object.assign(placeAux, {
        [component.types[0]]:
          component.types[0] === AddressComponentEnum.administrative_area_level_1 || component.types[0] === AddressComponentEnum.country
            ? component.short_name
            : component.long_name
      });
    });

    deviceAddress.street1 = `${placeAux.street_number ?? ""} ${placeAux.route ?? ""}`;
    deviceAddress.city = placeAux.locality ?? placeAux.postal_town ?? deviceAddress.city;
    deviceAddress.state = placeAux.administrative_area_level_1 ?? deviceAddress.state;
    deviceAddress.countryCode = placeAux.country ?? deviceAddress.countryCode;
    deviceAddress.zipCode = placeAux.postal_code ?? deviceAddress.zipCode;
    deviceAddress.latitude = place.geometry?.location?.lat().toString() ?? deviceAddress.latitude;
    deviceAddress.longitude = place.geometry?.location?.lng().toString() ?? deviceAddress.longitude;
    return deviceAddress;
  }

  public static mapRolestoRolesByUserList(roles?: Roles): RolesByUserList {
    switch (roles) {
      case Roles.Admin:
        return RolesByUserList.Admin;
      case Roles.SuperUser:
        return RolesByUserList.SuperUser;
      default:
        return RolesByUserList.Basic;
    }
  }

  public static mapUserByRole(data: User, isOnboard?: boolean): { user: User; role: string } {
    let userMapped: User = {
      username: data.username,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      enabled: data.enabled,
      timeZone: data.timeZone
    };
    const objectMapped: { user: User; role: string } = { user: data, role: "basic" };

    switch (data.role) {
      case RolesByUserList.Admin:
        userMapped = {
          ...userMapped,
          companyProfile: data.companyProfile
        };
        objectMapped.role = "admin";
        break;
      case RolesByUserList.Basic:
        userMapped = {
          ...userMapped,
          entityProfile: data.entityProfile ?? ""
        };
        objectMapped.role = "basic";
        break;
      case RolesByUserList.SuperUser:
        objectMapped.role = "superuser";
        break;
      default:
        userMapped = {
          ...userMapped,
          entityProfile: data.entityProfile ?? "",
          role: data.role
        };
        objectMapped.role = "custom";
    }
    if (isOnboard) {
      delete userMapped.username;
      delete userMapped.firstName;
      delete userMapped.lastName;
      delete userMapped.email;
      delete userMapped.enabled;
      delete userMapped.timeZone;
    }
    objectMapped.user = userMapped;
    return objectMapped;
  }

  public static mapOptionsFormatted = (strings: string[]): OptionParam[] => {
    return strings.map((str: string) => ({
      label: str,
      value: str
    }));
  };

  public static mapNotifications = (data?: NotificationsResponse): NotificationToExport[] => {
    if (data?.notifications) {
      return data.notifications.map((notification) => ({
        [i18n.t("type")]: notification.notificationType,
        [i18n.t("subject")]: notification.subject,
        [i18n.t("content")]: notification.message,
        [i18n.t("date")]: DateHelper.convertTimestampToBrowserCulture(notification.createdAt ?? 0, store.auth.userInfo?.timeZone, {
          month: "short",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hourCycle: "h23"
        })
      }));
    }
    return [];
  };
}
