import { AxiosResponse } from "axios";
import { makeAutoObservable } from "mobx";
import { StatusCode } from "../../helpers/api/RequestHelper";
import { QueryParams } from "../../interfaces/shared/queryParams";
import { MessageHelper } from "../../helpers/shared/MessageHelper";
import { QueryRoles, RolesByUserList } from "../../interfaces/auth/roleAndPermission/role";
import {
  AvailableByEmail,
  AvailableByUsername,
  UserAlternative,
  UserAssociatedResponse,
  UserAssociatedParams,
  User as UserModel,
  UserResponse,
  UserStatus,
  UserOnBoarded,
  UserStatusForm
} from "../../interfaces/users/user";
import { UserService } from "../../service/user/UserService";
import { TRANSLATION } from "../../utils/const/translation";
import { i18nInstance as i18n } from "../../../../i18n";

import { store } from "../StoreMobx";
import { MapperHelper } from "../../helpers/shared/MapperHelper";
export interface IUser {
  data?: UserResponse;
  userSelected?: UserModel;
  usersAssociated?: UserAssociatedResponse;
  prevParams?: QueryParams;
  showModal: boolean;
  userSelectedAvailable?: AvailableByUsername;
  userToOnBoard?: boolean;

  loadData: (params?: QueryParams) => Promise<void>;
  loadUserByUsername: (username: string) => Promise<void>;
  loadByRoleName: (role: string, params?: UserAssociatedParams) => Promise<void>;
  createByRole: (role: string, user: UserModel) => Promise<void>;
  updateByRole: (role: string, user: UserModel, username: string) => Promise<void>;
  updateOwnUser: (user: UserAlternative) => Promise<boolean>;
  isAvailableByEmail: (email: string) => Promise<AvailableByEmail | undefined>;
  isAvailableByUsername: (username: string) => Promise<AvailableByUsername | undefined>;
  onBoarded: (role: string, username: string, body: UserOnBoarded) => Promise<void>;
  setUser: (user: UserModel) => void;
  setUserSelectedAvailable: (userAvailable: AvailableByUsername) => void;
  setUserEnabled: (enabled: boolean) => void;
  setShowModal: (isShowModal: boolean) => void;
  setUserToOnBoard: (userToOnBoard: boolean) => void;
  setCompanyProfiles: (companyProfiles: string) => void;
  forgotPassword: (email: string) => void;
  resetPassword: (password: string, token: string) => Promise<void>;
  cleanUser: () => void;
  assignStatus: (isActive: boolean, isLocked: boolean, isEnabled?: boolean) => void;
  getAllCamUsersName: () => Promise<string[]>;
  loadDataExport: (params?: QueryParams) => Promise<UserResponse | undefined>;
}

// eslint-disable-next-line
// @ts-ignore
class User implements IUser {
  data?: UserResponse = undefined;
  userSelected?: UserModel = undefined;
  usersAssociated?: UserAssociatedResponse = undefined;
  prevParams?: QueryParams = undefined;
  showModal = false;
  userSelectedAvailable?: AvailableByUsername = undefined;
  userToOnBoard?: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadData = async (params?: QueryParams): Promise<void> => {
    try {
      const response = await UserService.getAll(params);
      if (response?.data) {
        const users = response.data.users.map((user) => ({ ...user, status: this.assignStatus(user.credentialsExpired, user.accountLocked, Boolean(user.enabled)) }));
        this.data = { ...response.data, users };
      }
    } catch (error) {
      MessageHelper.errorMessage(`${i18n.t(TRANSLATION.ERROR.errorLoadingUsers)} , ${i18n.t(TRANSLATION.ERROR[error as keyof typeof TRANSLATION.ERROR])}`);
    }
  };
  loadUserByUsername = async (username: string): Promise<void> => {
    try {
      const response = await UserService.getByUsername(username);
      if (response?.data) {
        this.userSelected = response.data.users[0];
      }
    } catch (error) {
      MessageHelper.errorMessage(`${i18n.t(TRANSLATION.ERROR.errorLoadingUsers)} , ${i18n.t(TRANSLATION.ERROR[error as keyof typeof TRANSLATION.ERROR])}`);
    }
  };

  loadByRoleName = async (role: string, params?: UserAssociatedParams): Promise<void> => {
    try {
      const response = await UserService.getByRoleName(role, params);
      if (response?.data) {
        this.usersAssociated = response.data;
      }
    } catch (error) {
      MessageHelper.errorMessage(`${i18n.t(TRANSLATION.ERROR.errorLoadingUsers)} , ${i18n.t(TRANSLATION.ERROR[error as keyof typeof TRANSLATION.ERROR])}`);
    }
  };

  createByRole = async (role: string, user: UserModel): Promise<void> => {
    try {
      const isEnabled = user.enabled === true || user.enabled === UserStatusForm.Enabled ? true : false;
      const updateUser = { ...user, enabled: isEnabled };
      const roleToUser = role === RolesByUserList.Basic || role === RolesByUserList.Admin || role === RolesByUserList.SuperUser || role === RolesByUserList.CAM ? role : "custom";
      const result = await UserService.createByRole(roleToUser.toLowerCase() as RolesByUserList, updateUser);
      if (result?.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18n.t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.userCreatedSuccessfully));
        // if (user.username) {
        //   await store.companyProfile.loadAvailablesByUser(user.username, { startAtRecord: 0, recordsPerPage: 10 });
        //   await store.companyProfile.loadAssociatedByUser(user.username, { startAtRecord: 0, recordsPerPage: 10 });
        // }
      }
      await this.loadData(this.prevParams);
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.errorCreatingUser));
    }
  };

  updateByRole = async (role: string, user: UserModel, username: string): Promise<void> => {
    try {
      const isEnabled = user.enabled === true || user.enabled === UserStatusForm.Enabled ? true : false;
      console.log(isEnabled, user.enabled);
      const updateUser = { ...user, enabled: isEnabled };
      if (updateUser.credentialsExpired === true) {
        await UserService.forceChangePassword(username);
      }
      delete updateUser.credentialsExpired;
      const roleToUser = role === RolesByUserList.Basic || role === RolesByUserList.Admin || role === RolesByUserList.SuperUser || role === RolesByUserList.CAM ? role : "custom";
      await UserService.updateByRole(roleToUser.toLowerCase() as RolesByUserList, updateUser, username);
      MessageHelper.successMessage(i18n.t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.userUpdatedSuccessfully));
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.errorUpdatingUser));
    }
  };

  updateOwnUser = async (user: UserAlternative): Promise<boolean> => {
    try {
      const data = await UserService.updateOwnProfile(user);
      if (data?.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18n.t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.userUpdatedSuccessfully));
        const userInfo = localStorage.getItem("user");
        if (userInfo) {
          const userParsed = JSON.parse(userInfo);
          const updatedUser = { ...userParsed, ...user };
          localStorage.setItem("user", JSON.stringify(updatedUser));
        }
        return true;
      }
      return false;
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.errorUpdatingUser));
      return false;
    }
  };

  onBoarded = async (role: string, username: string, body: UserModel): Promise<void> => {
    try {
      const data = MapperHelper.mapUserByRole(body, true);
      role = role !== RolesByUserList.Basic && role !== RolesByUserList.Admin && role !== RolesByUserList.SuperUser && role !== RolesByUserList.CAM ? "custom" : role.toLowerCase();
      const response = role === QueryRoles.SuperUser ? await UserService.onBoardedByRole(role, username) : await UserService.onBoardedByRole(role, username, data.user);
      if (response?.status === StatusCode.NO_CONTENT) {
        this.userToOnBoard = false;
        MessageHelper.successMessage(i18n.t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.userOnboardedSuccessfully));
      }
    } catch (error) {
      this.userToOnBoard = false;
      MessageHelper.errorMessage(i18n.t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.errorOnboardingUser));
    }
  };

  isAvailableByEmail = async (email: string): Promise<AvailableByEmail | undefined> => {
    try {
      const response = await UserService.isAvailableByEmail(email);
      return response?.data;
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.ERROR.errorCheckingEmail));
    }
  };

  setUserEnabled = async (enabled: boolean): Promise<void> => {
    if (this.userSelected) {
      this.userSelected.enabled = enabled;
    }
  };

  setCompanyProfiles = async (companyProfiles: string): Promise<void> => {
    if (this.userSelected) {
      this.userSelected.companyProfile = companyProfiles;
    }
  };

  setShowModal = (isShowModal: boolean): void => {
    this.showModal = isShowModal;
  };

  setUserToOnBoard = (userToOnBoard: boolean): void => {
    this.userToOnBoard = userToOnBoard;
  };

  isAvailableByUsername = async (username: string): Promise<AvailableByUsername | undefined> => {
    try {
      const response = await UserService.isAvailableByUsername(username);
      return response?.data;
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.ERROR.errorCheckingUsername));
    }
  };

  setUser = (user: UserModel): void => {
    this.userSelected = user;
  };

  setUserSelectedAvailable = (userAvailable: AvailableByUsername): void => {
    this.userSelectedAvailable = userAvailable;
  };

  cleanUser = (): void => {
    this.userSelected = undefined;
    this.data = undefined;
    store.companyProfile.cleanavailableDataAndAssociatedData();
  };

  forgotPassword = async (email: string): Promise<void> => {
    try {
      const result = (await UserService.forgotPassword(email)) as AxiosResponse;
      if (result.status === StatusCode.OK || result.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18n.t(TRANSLATION.LOGIN.confirmationSentEmailDescription));
      }
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.ERROR.errorSendingEmail));
    }
  };

  resetPassword = async (password: string, guid: string): Promise<void> => {
    try {
      const result = (await UserService.resetPassword(password, guid)) as AxiosResponse;
      if (result.status === StatusCode.OK || result.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18n.t(TRANSLATION.MODAL.ALERT.passwordResetSuccessfully));
        setTimeout(() => {
          localStorage.clear();
          window.location.replace("/auth/login");
        }, 2000);
      }
    } catch (error) {
      const errorParsed = error as AxiosResponse;
      if (errorParsed?.status === StatusCode.BAD_REQUEST) {
        MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.ALERT.passwordTockenHasBeenExpired));
      } else {
        console.warn(error);
      }
    }
  };

  assignStatus = (isExpired?: boolean, isLocked?: boolean, isEnabled?: boolean): UserStatus => {
    const status = isExpired ? UserStatus.Expired : isLocked ? UserStatus.Locked : isEnabled ? UserStatus.Enabled : UserStatus.Disabled;
    return status;
  };

  createCamUser = async (userData: UserModel): Promise<boolean> => {
    try {
      const response = await UserService.createCamUser(userData);
      if (response?.status === StatusCode.OK || response?.status === StatusCode.NO_CONTENT || response?.status === StatusCode.CREATED) {
        return true;
      }
      return false;
    } catch (error) {
      MessageHelper.errorMessage(i18n.t("upsSomethingWentWrong"));
      return false;
    }
  };

  editCamUser = async (userData: UserModel, userName: string): Promise<boolean> => {
    try {
      const response = await UserService.editCamUser(userData, userName);
      if (response?.status === StatusCode.OK || response?.status === StatusCode.NO_CONTENT) {
        return true;
      }
      return false;
    } catch (error) {
      MessageHelper.errorMessage(i18n.t("upsSomethingWentWrong"));
      return false;
    }
  };

  getAllCamUsersName = async (): Promise<string[]> => {
    try {
      const response = await UserService.getAllCamUsersName();
      return response?.data || [];
    } catch (error) {
      MessageHelper.errorMessage(i18n.t("upsSomethingWentWrong"));
      return [];
    }
  };

  onBoardCam = async (userName: string): Promise<void> => {
    try {
      await UserService.onBoardCam(userName);
    } catch (error) {
      console.error(error);
    }
  };

  loadDataExport = async (params?: QueryParams): Promise<UserResponse | undefined> => {
    try {
      const response = await UserService.getAll(params);
      return response?.data;
    } catch (error) {
      MessageHelper.errorMessage(`${i18n.t(TRANSLATION.ERROR.errorLoadingUsers)} , ${i18n.t(TRANSLATION.ERROR[error as keyof typeof TRANSLATION.ERROR])}`);
    }
  };
}

const user = new User();

export default user;
