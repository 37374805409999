import React, { useCallback, useContext, useState } from "react";
import { NotificationContent } from "../../components/notification&alerts/notification-content";
import * as darkTokens from "@tns/ui-style-dictionary/build/ts/dark-tokens";
import * as lightTokens from "@tns/ui-style-dictionary/build/ts/light-tokens";
import { Variants, TNSButton, TNSModal, Themes } from "@tns/ui-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons";
import { NotificationTray } from "../../components/notification&alerts/notification-tray";
import { Notifications } from "@tnso/api-sdk";
import { useAsyncCall } from "src/hooks/useAsyncCallShared";
import { NotificationContext } from "src/contexts/notifications/notifications-context";
import _ from "lodash";
import { NotificationQueryParams, NotificationStatus } from "src/shared/models/notification.model";
import { TRANSLATION } from "../../utils/const/translation";
import { useTranslation } from "react-i18next";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { CustomizerContext } from "../../../../contexts/customizer/CustomizerContext";

export interface NotificationsProps {
  id?: string | number;
  referenceId?: string;
}

export const NotificationHub: React.FC<NotificationsProps> = ({ id, referenceId }) => {
  const notificationContext = useContext(NotificationContext);
  const customizer = useContext(CustomizerContext);
  const { t } = useTranslation();
  const [notificationSelected, setNotificationSelected] = useState<Notifications>();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState<NotificationQueryParams>({});

  const loadData = useCallback(async () => {
    if (id) {
      const response = await notificationContext.getNotifications({ id: Number(id) });
      if (response?.notifications) {
        setNotificationSelected(response.notifications[0]);
      }
    }
  }, [id]);

  useAsyncCall(loadData, [loadData]);

  const rowNotificationSelected = useCallback(
    async (notifications?: Notifications) => {
      setNotificationSelected(notifications);
      if (notifications?.id) {
        await notificationContext.updateNotifications({
          status: NotificationStatus.READ,
          updateIdList: [notifications.id]
        });
        await notificationContext.setNotificationsData(params);
      }
    },
    [setNotificationSelected, selectedRows, notificationContext, params]
  );

  const handleParamsChange = useCallback((newParams: NotificationQueryParams) => {
    setParams((prevParams) => ({ ...prevParams, ...newParams }));
  }, []);

  const upodateStatusOfNotifications = useCallback(
    async (markAs?: NotificationStatus) => {
      if (markAs) {
        await notificationContext.updateNotifications({
          status: markAs,
          updateIdList: selectedRows
        });
      } else {
        await notificationContext.updateNotifications({
          status: NotificationStatus.READ,
          updateAll: true
        });
      }
      await notificationContext.setNotificationsData(params);
      setSelectedRows([]);
      setIsAllSelected(false);
      setShowModal(false);
    },
    [selectedRows, notificationContext, params]
  );

  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex gap-3 justify-content-start w-100">
        <TNSButton buttonVariant={Variants.Secondary} disabled={_.isEmpty(selectedRows)} onClick={(): Promise<void> => upodateStatusOfNotifications(NotificationStatus.READ)}>
          <span className="d-flex align-items-center gap-1">
            <FontAwesomeIcon icon={faEnvelopeOpen} /> {t(TRANSLATION.NOTIFICATIONS.BUTTON.markAsRead)}
          </span>
        </TNSButton>
        <TNSButton buttonVariant={Variants.Secondary} disabled={_.isEmpty(selectedRows)} onClick={(): Promise<void> => upodateStatusOfNotifications(NotificationStatus.UNREAD)}>
          <span className="d-flex align-items-center gap-1">
            <FontAwesomeIcon icon={faEnvelope} /> {t(TRANSLATION.NOTIFICATIONS.BUTTON.markAsUnread)}
          </span>
        </TNSButton>
        {!referenceId && (
          <TNSButton
            buttonVariant={Variants.Link}
            onClick={(): void => setShowModal(true)}
            disabled={_.isEmpty(notificationContext.notifications) || notificationContext.amountOfNotificationsUnread === 0}>
            <span
              style={{
                color:
                  _.isEmpty(notificationContext.notifications) || notificationContext.amountOfNotificationsUnread === 0
                    ? "gray"
                    : customizer.selectedTheme === Themes.DARK
                    ? darkTokens.TnsButtonLinkDefaultLabelColor
                    : lightTokens.TnsButtonLinkDefaultLabelColor
              }}>
              {t(TRANSLATION.NOTIFICATIONS.BUTTON.markAllAsRead)}
            </span>
          </TNSButton>
        )}
      </div>
      <div className="d-flex gap-3 notification-center-container">
        <div className="notification-try-container">
          <NotificationTray
            setNotificationsSelected={rowNotificationSelected}
            notificationSelected={notificationSelected}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            isAllSelected={isAllSelected}
            setIsAllSelected={setIsAllSelected}
            notifications={notificationContext.notifications}
            referenceId={referenceId}
            setParams={handleParamsChange}
          />
        </div>
        <div className="notification-content-container">
          <NotificationContent title={notificationSelected?.subject} message={notificationSelected?.message} />
        </div>
      </div>
      <TNSModal
        open={showModal}
        title={
          <div>
            <p>
              <FontAwesomeIcon icon={faExclamationCircle} color="#D79310" size="lg" /> {t(TRANSLATION.NOTIFICATIONS.MODAL.areYouSureYouWantToMarkAllTheNotificationsAsRead)}
            </p>
            {`${t(TRANSLATION.NOTIFICATIONS.MODAL.mark)} ${notificationContext.amountOfNotificationsUnread} ${t(TRANSLATION.NOTIFICATIONS.MODAL.notificationsAsRead)}`}
          </div>
        }
        textOkButton={t("OK")}
        closable={false}
        textCancelButton={t("cancel")}
        handleCancel={(): void => setShowModal(false)}
        handleAccept={(): Promise<void> => upodateStatusOfNotifications()}
      />
    </div>
  );
};

export default NotificationHub;
