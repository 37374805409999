import { ApiCore } from "@tnso/api-core";
import { TNSOAlert } from "@tnso/ui-components";
import { SorterResult } from "antd/lib/table/interface";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ConnectedDevicesTable } from "src/app/monitoring/components/dhi/tabs/connected-devices/connected-devices-table";
import { ConnectedLastUpdated } from "src/app/monitoring/components/dhi/tabs/connected-devices/connected-last-updated";
import { VLANInformation } from "src/app/monitoring/components/dhi/tabs/connected-devices/vlan-information";
import { ConnectedDevice, ConnectedDevicesParams, ConnectedDevicesResponse } from "src/app/monitoring/models/connected-devices/connected-devices";
import { store } from "src/app/monitoring/store/store";
import { store as sharedStore } from "src/app/portal-app/store/StoreMobx";
import { TRANSLATION } from "src/app/monitoring/utils/const/translation";
import { useAsyncCall } from "libraries/shared/src/lib/hooks/use-async-call-shared";
import { paginationSizeChangerContext } from "../../../../../contexts/paginationSizeChanger/paginationSizeChangerContext";

export const ConnectedDevices: React.FC = () => {
  const { deviceName } = useParams();
  const { t } = useTranslation();
  const { loadDataByTnsDeviceName, getAvailableLanPorts, getModelsByConnectedDevices } = store.device.connectedDevices;
  const [connectedDevicesResponse, setConnectedDevicesResponse] = useState<ConnectedDevicesResponse>();
  const [lanPorts, setLanPorts] = useState<string[]>();
  const { paginationSize } = paginationSizeChangerContext();
  const [prevParams, setPrevParams] = useState<ConnectedDevicesParams>({ startAtRecord: 0, recordsPerPage: paginationSize });
  const [models, setModels] = useState<string[]>([]);
  const messageAlert = useMemo(
    () =>
      `${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.thisFunctionalityOnlyWorksForVAmodels)} ${models.join(", ")} ${t(
        TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.withADeviceStatus
      )}`,
    [t, models]
  );

  const loadData = useCallback(async (): Promise<void> => {
    if (deviceName) {
      await sharedStore.device.detail.loadData(deviceName);
      const lanPortsResponse = await getAvailableLanPorts(deviceName);
      const modelsResponse = await getModelsByConnectedDevices();
      const response = await loadDataByTnsDeviceName(deviceName, prevParams);
      setConnectedDevicesResponse(response);
      setLanPorts(lanPortsResponse);
      if (modelsResponse) {
        setModels(modelsResponse);
      }
    }
  }, [deviceName, prevParams]);

  const loader = useAsyncCall(loadData, [loadData], sharedStore.shared.getTimeToAutoRefresh());

  const handleSearch = useCallback(
    async (filter: string, keyFilter: string) => {
      if (filter === "all") {
        filter = "";
      }
      const queryParams = ApiCore.builderQueryParams(
        {
          tableFilters: { [keyFilter]: filter },
          recordsPerPage: paginationSize
        },
        prevParams
      );
      setPrevParams(queryParams);
    },
    [paginationSize, prevParams]
  );

  const handleSort = useCallback(
    async (sorter: SorterResult<ConnectedDevice>): Promise<void> => {
      if (deviceName && sorter.columnKey && sorter.order) {
        const queryParams = ApiCore.builderQueryParams({ sortBy: sorter.columnKey, orderBy: sorter.order, recordsPerPage: paginationSize }, prevParams);
        setPrevParams(queryParams);
      }
    },
    [deviceName, paginationSize, prevParams]
  );

  return (
    <div className="d-flex flex-column gap-2 w-100">
      <TNSOAlert type="info" message={messageAlert} showIcon />
      <VLANInformation />
      <ConnectedDevicesTable
        data={connectedDevicesResponse}
        loader={loader}
        setData={setConnectedDevicesResponse}
        lanPorts={lanPorts}
        handleSearch={handleSearch}
        prevParams={prevParams}
        handleSort={handleSort}
        setPrevParams={setPrevParams}
        singleDevice
      />
      <ConnectedLastUpdated lastUpdatedAt={connectedDevicesResponse?.lastUpdatedAt} />
    </div>
  );
};
