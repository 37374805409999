import { ContactProfilesResponse, ContactParams } from "../models/device-contact.model";
import { encodeParams } from "../helpers/api/RequestHelper";
import { ContactProfileForm } from "../components/contact/contact-form";
import { IResponse } from "@tnso/api-core";
import { BaseService } from "../../../app/portal-app/service/BaseService";

export class ContactProfilesService extends BaseService {
  static async getAll(params?: ContactParams): Promise<IResponse<ContactProfilesResponse> | undefined> {
    const urlSearchParams = encodeParams<ContactParams>(params as ContactParams);
    return this.get<ContactProfilesResponse>("contacts", urlSearchParams);
  }

  static async create(contact: ContactProfileForm): Promise<IResponse | undefined> {
    return this.post("contacts", contact);
  }

  static async update(contactId: number, contact: ContactProfileForm): Promise<IResponse | undefined> {
    return this.patch<ContactProfileForm>(`contacts/${contactId}`, contact);
  }

  static async deleteContact(contactId: number): Promise<IResponse | undefined> {
    return this.remove(`contacts/${contactId}`);
  }
}
