import { ApiService, IResponse } from "@tnso/api-core";
import { DeviceDetail } from "src/app/monitoring/models/devices/devices";
import { IDeviceMetrics, ISIMDetail, IInterfaceMetrics } from "src/app/monitoring/models/devices/dhi/dhi";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class DeviceDetailService extends ApiService {
  static async getDeviceDetails(tnsDeviceName: string): Promise<IResponse<DeviceDetail> | undefined> {
    return this.get<DeviceDetail>(`${getApiBaseUrl()}/devices/${tnsDeviceName}`);
  }

  static getSIMSDetails(tnsDeviceName: string): Promise<IResponse<ISIMDetail[]> | undefined> {
    return this.get<ISIMDetail[]>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/sims`);
  }

  static getMetrics(tnsDeviceName: string): Promise<IResponse<IDeviceMetrics> | undefined> {
    return this.get<IDeviceMetrics>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/metrics`);
  }

  static getInterfaceMetrics(tnsDeviceName: string): Promise<IResponse<IInterfaceMetrics[]> | undefined> {
    return this.get<IInterfaceMetrics[]>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/metrics/interfaces`);
  }
}
