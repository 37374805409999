import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { observer } from "mobx-react";
import { store } from "../../../store/StoreMobx";
import { LVCColumnKeys, useTableLVCConfig } from "../../../store/device/lvc/useTableConfig";
import { TranslationHelper } from "../../../helpers/shared/TranslationHelper";
import { LVCData, LVCTable } from "../../../interfaces/devices/lvc/lvc";
import { TRANSLATION } from "../../../utils/const/translation";
import { useExportDataTable } from "../../../../../shared/hooks/use-export-data-table";
import { MapperHelper } from "../../../helpers/shared/MapperHelper";
import { LVCResponse } from "../../../interfaces/devices/response/response";
import { LVCViews } from "../../../views/menu/monitoring/devices/device-detail/tabs/LogicalVirtualCircuits";
import { DefaultOptionType } from "antd/es/select";
import { DeviceParams } from "../../../interfaces/devices/devices";
import { ApiCore } from "@tnso/api-core";
import { SorterResult } from "antd/es/table/interface";
import { TNSTable } from "@tns/ui-components";
import { AuthService } from "../../../../../app/portal-app/service/auth/AuthService";
import { paginationSizeChangerContext } from "../../../../../contexts/paginationSizeChanger/paginationSizeChangerContext";

export interface LVCDataTableProps {
  totalRecords?: number;
  lvcs: LVCData[];
  typeOfView: LVCViews;
  options?: DefaultOptionType[];
  loading: boolean;
  params: DeviceParams;
  setParams: React.Dispatch<React.SetStateAction<DeviceParams>>;
}

export const LVCDataTable: React.FC<LVCDataTableProps> = observer(({ totalRecords = 0, lvcs = [], typeOfView, options, loading, params, setParams }) => {
  const { lvc } = store.device;
  const { deviceName } = useParams();
  const { t } = useTranslation();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [paramsToExport, setParamsToExport] = useState<DeviceParams>(params);
  const { paginationSize, paginationPageSizeOptions, setPaginationSize } = paginationSizeChangerContext();

  const handleExportData = useExportDataTable<LVCTable, LVCResponse, DeviceParams>(
    totalRecords,
    paginationSize,
    lvc.loadDataToExport,
    paramsToExport,
    `${deviceName}_LVC`,
    typeOfView === LVCViews.DefaultView ? MapperHelper.mapLVCExport : MapperHelper.mapLVCDefaultExport,
    true,
    deviceName
  );

  const handleSearch = async (filter: string, keyFilter: string): Promise<void> => {
    if (filter === "all") {
      filter = "";
    }
    const newParams = ApiCore.builderQueryParams<DeviceParams>({ tableFilters: { [keyFilter]: filter }, recordsPerPage: paginationSize }, params);
    if (deviceName) {
      store.device.lvc.setCurrentPage(1);
      store.device.lvc.setActiveFiltersData(keyFilter, filter);
      setParams(newParams);
      setParamsToExport(newParams);
    }
  };

  const { columns, skeletonColumns } = useTableLVCConfig({ handleSearch });

  const handleSort = async (sorter: SorterResult<LVCTable>): Promise<void> => {
    if (deviceName && sorter.columnKey && sorter.order) {
      const queryParams = ApiCore.builderQueryParams({ sortBy: sorter.columnKey, orderBy: sorter.order, recordsPerPage: paginationSize }, params);
      setParams(queryParams);
      setParamsToExport(queryParams);
    }
  };

  const handleExport = useCallback(async (): Promise<void> => {
    setIsLoadingExport(true);
    await handleExportData();
    setIsLoadingExport(false);
  }, [handleExportData]);

  const handleChangePage = useCallback(
    async (page: number): Promise<void> => {
      const newParams = ApiCore.builderQueryParams<DeviceParams>({ currentPage: page - 1, recordsPerPage: paginationSize }, params);
      setParams(newParams);
      setParamsToExport(newParams);
      lvc.setCurrentPage(page);
    },
    [lvc]
  );

  const sorting = useCallback(
    async (
      //  eslint-disable-next-line
      pagination: any,
      //  eslint-disable-next-line
      _: Record<string, string[] | null>,
      //  eslint-disable-next-line
      sorter: SorterResult<LVCTable>
    ) => {
      await handleSort(sorter);
      lvc.setCurrentPage(1);
    },
    [handleSort]
  );

  const dataTable = useMemo(() => {
    return lvcs.map((lvc, index) => ({ key: lvc.lvcTicketNumber + index, ...lvc })) ?? [];
  }, [lvcs]);

  const isVisibleColumns = (typeOfView: LVCViews, key: string, dataIndex: string): boolean => {
    if (typeOfView === LVCViews.LegacyView) {
      return (
        key === LVCColumnKeys.devices ||
        key === LVCColumnKeys.startDate ||
        key === LVCColumnKeys.ep1Acna ||
        key === LVCColumnKeys.ep2Acna ||
        !options?.find((item) => item.value === key)
      );
    }
    return (key === LVCColumnKeys.endPoint1 && dataIndex === LVCColumnKeys.endPoint1) || (key === LVCColumnKeys.endPoint2 && dataIndex === LVCColumnKeys.endPoint2);
  };

  const newColumns = columns.map(
    (item) => ({
      ...item,
      hidden: isVisibleColumns(typeOfView, item.collapse, item.dataIndex)
    }),
    [typeOfView, options, isVisibleColumns]
  );

  const handlePaginationSizeChange = useCallback(
    async (size: number): Promise<void> => {
      if (size !== paginationSize) {
        const current = 1;
        setPaginationSize(size);
        const newParams = ApiCore.builderQueryParams<DeviceParams>({ currentPage: current - 1, recordsPerPage: size }, params);
        setParams(newParams);
        setParamsToExport(newParams);
        lvc.setCurrentPage(1);
        await AuthService.setUserPreferences({ PREFERED_PAGE_SIZE: size });
      }
    },
    [paginationSize, setPaginationSize, params, setParams, lvc]
  );

  const grid = useMemo(() => {
    return (
      <TNSTable
        totalRecords={totalRecords}
        onExport={handleExport}
        exportLabel={t("export")}
        isLoadingExport={isLoadingExport}
        isLoading={loading}
        dataSource={TranslationHelper.dataTranslation(dataTable, ["status"])}
        columns={loading ? TranslationHelper.columnsTranslation(skeletonColumns as unknown as ColumnsType[]) : (newColumns as unknown as ColumnsType[])}
        handleGoToPage={handleChangePage}
        showPagination={true}
        emptyMessage={t(TRANSLATION.SHARED.DATATABLE.noDataAvailable)}
        currentPage={lvc.currentPage}
        recordsTranslated={[t(TRANSLATION.TNSDEVICES.records), t(TRANSLATION.TNSDEVICES.of)]}
        whitoutCard={true}
        onChange={sorting}
        showPaginationSizeChanger
        paginationPageSizeTextTranslated={t("resultsPerPage")}
        pageSize={paginationSize}
        paginationPageSizeOptions={paginationPageSizeOptions}
        onShowPaginationSizeChange={handlePaginationSizeChange}
        scroll={{ x: "max-content" }}
      />
    );
  }, [lvc, lvcs, totalRecords, handleChangePage, t, handleExport, isLoadingExport, dataTable, newColumns, options, typeOfView]);

  return grid;
});
