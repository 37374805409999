import React, { useCallback, useContext, useState } from "react";
import { GapSizes, TNSButton, TNSContainer, TNSTable, Variants } from "@tns/ui-components";
import { useTableConfig } from "./use-table-config";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import FilterPanel from "./filter-panel";
import { IncidentManagementCreateDrawer } from "./incident-management-create-drawer";
import { useAsyncCall } from "src/shared/hooks/useAsyncCallShared";
import { TRANSLATION } from "../../../translations/translation";
import { useNavigate } from "react-router-dom";
import { IncidentManagementContext } from "../../../context/incident-management/incident-management-context";
import { paginationSizeChangerContext } from "src/contexts/paginationSizeChanger/paginationSizeChangerContext";
import { TranslationHelper } from "src/app/portal-app/helpers/shared/TranslationHelper";
import dayjs from "dayjs";

enum Status {
  Active = "Active",
  New = "New",
  PendingTNS = "Pending TNS",
  PendingCustomer = "Pending Customer",
  InProgress = "In Progress",
  Monitoring = "Monitoring",
  Resolved = "Resolved",
  Closed = "Closed",
  Cancelled = "Cancelled"
}

const IncidentManagement: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const context = useContext(IncidentManagementContext);

  const [isLoadingExport] = useState(false);
  const [createDrawerOpen, setCreateDrawerOpen] = useState<boolean>(false);
  const [acnas, setAcnas] = useState<string[]>([]);
  const [reloadTableData, setReloadTableData] = useState(false);
  const { paginationPageSizeOptions } = paginationSizeChangerContext();
  const {
    loading,
    setFilters,
    columns,
    ticketsResponse,
    currentPage,
    handleChangePage,
    handleExportData,
    filters,
    handleSort,
    skeletonColumns,
    paginationSize,
    handlePaginationSizeChange,
    setIsDateUpdated
  } = useTableConfig({
    reloadTableData
  });

  useAsyncCall(async () => {
    if (context.getAcna() && !acnas.length) {
      const acnas = context.getAcna();
      setAcnas(acnas || []);
    }
  }, [acnas, context]);

  const openCreateDrawer = useCallback(() => setCreateDrawerOpen(true), []);

  const handleNavigate = useCallback(
    (ticketId: string): void => {
      navigate(`/support/incidentManagement/${ticketId}`);
    },
    [navigate]
  );

  const closeDrawer = useCallback(() => setCreateDrawerOpen(false), []);

  return (
    <TNSContainer gap={GapSizes.sm} className="containerColums">
      <h3>{t(TRANSLATION.INCIDENTMANAGEMENT.tickets)}</h3>
      <div className="d-flex justify-content-end">
        <TNSButton onClick={openCreateDrawer} buttonVariant={Variants.Primary}>
          {t(TRANSLATION.INCIDENTMANAGEMENT.BUTTON.createTicket)}
        </TNSButton>
      </div>
      <FilterPanel
        filters={[
          {
            type: "input",
            key: "ticketNumber",
            onSearch: async (value: string): Promise<void> => {
              setFilters(
                value === ""
                  ? { ...filters, ticketNumber: undefined, fromStartDate: dayjs().subtract(90, "days").unix(), toStartDate: dayjs().unix() }
                  : { ...filters, ticketNumber: value }
              );
            },
            placeholder: t(TRANSLATION.INCIDENTMANAGEMENT.ticketNumberExact),
            value: filters?.ticketNumber
          },
          {
            type: "date",
            key: "fromStartDate",
            onDatesChange: async (startDate: string, endDate: string): Promise<void> => {
              if (startDate && endDate) {
                setFilters({ ...filters, fromStartDate: dayjs(startDate).unix(), toStartDate: dayjs(endDate).unix() });
                setIsDateUpdated(true);
              } else {
                const shadowFilters = { ...filters };
                if (shadowFilters.ticketNumber) {
                  delete shadowFilters.fromStartDate;
                  delete shadowFilters.toStartDate;
                } else {
                  shadowFilters.fromStartDate = dayjs().subtract(90, "days").unix();
                  shadowFilters.toStartDate = dayjs().unix();
                }
                setIsDateUpdated(false);
                setFilters(shadowFilters);
              }
            },
            onSearch: async (): Promise<void> => {},
            value: filters?.fromStartDate ? [dayjs.unix(filters.fromStartDate), dayjs.unix(filters.toStartDate ?? 0)] : undefined
          },
          {
            type: "select",
            key: "acna",
            options: acnas.map((acna) => {
              return {
                value: acna,
                label: acna
              };
            }),
            onSearch: async (value: string): Promise<void> => setFilters({ ...filters, acna: value }),
            placeholder: t(TRANSLATION.INCIDENTMANAGEMENT.completeWithAcna),
            value: filters?.acna
          },
          {
            type: "input",
            key: "tnsDeviceName",
            onSearch: async (value: string): Promise<void> => setFilters({ ...filters, tnsDeviceName: value }),
            placeholder: t(TRANSLATION.INCIDENTMANAGEMENT.searchDeviceExact),
            value: filters?.tnsDeviceName
          },
          {
            type: "select",
            key: "status",
            options: Object.values(Status).map((statusItem) => {
              return {
                value: statusItem,
                label: t(String(TRANSLATION.INCIDENTMANAGEMENT[statusItem as unknown as keyof typeof TRANSLATION.INCIDENTMANAGEMENT]))
              };
            }),
            onSearch: async (value: string): Promise<void> => setFilters({ ...filters, status: value as Status }),
            placeholder: t(TRANSLATION.INCIDENTMANAGEMENT.selectStatus),
            value: filters?.status
          }
        ]}
      />

      <TNSTable
        scroll={{ x: "max-content" }}
        currentPage={currentPage}
        dataSource={TranslationHelper.dataTranslation(ticketsResponse?.incidentList || [], ["status"])}
        totalRecords={ticketsResponse?.totalRecords || 0}
        recordsTranslated={[t("records"), t("of")]}
        columns={
          !loading
            ? TranslationHelper.columnsTranslation(columns.ticketsColumns as unknown as ColumnsType[])
            : TranslationHelper.columnsTranslation(skeletonColumns as unknown as ColumnsType[])
        }
        handleSelectRow={(row): void => handleNavigate(row.ticketNumber)}
        showPagination
        handleGoToPage={handleChangePage}
        onExport={handleExportData}
        exportLabel={t("export")}
        onChange={handleSort}
        isLoadingExport={isLoadingExport}
        isLoading={loading}
        showPaginationSizeChanger
        paginationPageSizeTextTranslated={t("resultsPerPage")}
        pageSize={paginationSize}
        onShowPaginationSizeChange={handlePaginationSizeChange}
        paginationPageSizeOptions={paginationPageSizeOptions}
      />
      <IncidentManagementCreateDrawer isDrawerOpen={createDrawerOpen} onConfirm={(): void => setReloadTableData(!reloadTableData)} onCloseDrawer={closeDrawer} />
    </TNSContainer>
  );
};

export default IncidentManagement;
