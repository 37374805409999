import { makeAutoObservable } from "mobx";
import { LVCData } from "../../../models/devices/lvc/lvc";
import { LVCResponse } from "../../../models/devices/response/response";
import { LVCService } from "../../../service/device/dhi/lvc-service";
import { QueryParams } from "src/shared/models/queryParams";
import { StatusCode } from "@tnso/api-core";
import { MessageHelper } from "../../../../../shared/helpers/MessageHelper";

export interface ILVC {
  data?: LVCResponse;
  lvcs?: LVCData[];
  currentPage: number;

  loadData: (tnsDeviceName?: string, queryParams?: QueryParams) => Promise<void>;
  setCurrentPage: (page: number) => void;
}

class LVC implements ILVC {
  data?: LVCResponse = undefined;
  lvcs?: LVCData[] = undefined;
  currentPage = 1;

  constructor() {
    makeAutoObservable(this);
  }

  loadData = async (tnsDeviceName?: string, queryParams?: QueryParams): Promise<void> => {
    try {
      if (tnsDeviceName) {
        const response = await LVCService.getAll(tnsDeviceName, queryParams);
        if (response?.status === StatusCode.OK && response?.data) {
          const lvcs = response.data.lvcs?.map((lvcItem) => ({ ...lvcItem, devices: `${lvcItem.ep1DeviceName} - ${lvcItem.ep2DeviceName}` }));
          this.data = { ...response.data, lvcs };
          this.lvcs = lvcs;
        }
      }
    } catch (error) {
      MessageHelper.errorMessage("Error loading lvc");
    }
  };

  setCurrentPage = (page: number): void => {
    this.currentPage = page;
  };
}

const lvc = new LVC();

export default lvc;
