import React, { useCallback, useContext, useMemo, useState } from "react";
import { Theme, ToastContainer } from "react-toastify";
import ThemeSelector from "./layouts/theme/ThemeSelector";
import Loader from "./layouts/loader/Loader";
import { store } from "./app/portal-app/store/StoreMobx";
import { observer } from "mobx-react";
import { fetchMFAPreference } from "aws-amplify/auth/cognito";
import "@aws-amplify/ui-react/styles.css";
import { useAsyncCall } from "./hooks/useAsyncCallShared";
import { MFA_LANDING_ENABLED } from "./config/environments";
import { CustomizerContext } from "./contexts/customizer/CustomizerContext";
import { AuthService } from "./app/portal-app/service/auth/AuthService";
import { Themes } from "@tns/ui-components";
import MFALanding from "./MFALanding";
import { LogHelper } from "./helpers/shared/LogHelper";

interface AppProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routing: React.ReactElement<any, string | React.JSXElementConstructor<any>> | null;
  ssoActive?: boolean;
}

export const App: React.FC<AppProps> = ({ routing, ssoActive }) => {
  const direction = store.customizer.isRTL;
  const { shared } = store;
  const customizer = useContext(CustomizerContext);
  const [showLanding, setShowLanding] = useState(false);
  const [noStrapiError, setNoStrapiError] = useState(false);

  useAsyncCall(async () => {
    LogHelper.writeLog("App", "Setting user preferences");
    const userPreferences = await AuthService.getUserPreferences();

    LogHelper.writeLog("App", "Setting user preferences", userPreferences);
    await shared.setIsAutoRefreshActive(userPreferences?.AUTO_REFRESH ?? false);

    LogHelper.writeLog("App", "Setting theme", userPreferences?.DEFAULT_THEME);
    await customizer.setTheme(userPreferences?.DEFAULT_THEME ?? Themes.DARK, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loader = useAsyncCall(async () => {
    LogHelper.info(`[App] Application is validating for sso active ${ssoActive ? "enabled" : "disabled"}...`);
    if (ssoActive) {
      try {
        LogHelper.writeLog("App", "SSO is active");
        const hasMFAEnabled = await fetchMFAPreference();
        setShowLanding(hasMFAEnabled.enabled && hasMFAEnabled.enabled?.length > 0 ? false : true);
      } catch (error) {
        LogHelper.error("[App] Error getting MFA preferences activating landing as fallback: ", error);
        setShowLanding(true);
      }
    }

    LogHelper.info("[App] Application is ready to use...");
  }, [ssoActive]);

  const handleError = useCallback((error: Error) => {
    LogHelper.error("[App] Error getting MFA preferences activating landing as fallback: ", error);
    setNoStrapiError(true);
  }, []);

  const shouldRenderLandingMFA = useMemo(() => showLanding && !store.auth.isActAs && MFA_LANDING_ENABLED && !noStrapiError, [showLanding, noStrapiError]);
  LogHelper.info("[App] Application validating if should render MFA landing page: ", shouldRenderLandingMFA);

  LogHelper.info("[App] Application is rendering...", loader);
  return !loader.loading ? (
    shouldRenderLandingMFA ? (
      <MFALanding showLanding={showLanding} setShowLanding={setShowLanding} onError={handleError} />
    ) : (
      <div className={`${direction ? "rtl" : "ltr"} ${customizer.selectedTheme}`} dir={direction ? "rtl" : "ltr"}>
        <ToastContainer theme={customizer.selectedTheme as Theme} limit={1} />
        <ThemeSelector>{routing}</ThemeSelector>
      </div>
    )
  ) : loader.loading && !noStrapiError ? (
    <Loader />
  ) : (
    <div />
  );
};

export default observer(App);
