import React, { useCallback, useMemo } from "react";
import { useAsyncCall } from "../../hooks/useAsyncCallShared";
import { EnvironmentVariablesFull, FeatureFlags } from "./EnvironmentVariables";
import { ConfigurationService } from "../../app/portal-app/service/configuration-service/configuration-service";
import { AuthService, CognitoTokenUserData } from "../../app/portal-app/service/auth/AuthService";
import { fetchAuthSession } from "aws-amplify/auth";

export interface AppConfiguration {
  environmentValues?: EnvironmentVariablesFull;
}

/**
 * Interface for ConfigurationContextProviderProps
 */
export interface ConfigurationContextProviderProps {
  children: React.ReactNode;
}

/**
 * Interface for ConfigurationContextProps
 */
export interface ConfigurationContextProps {
  configuration?: AppConfiguration;
  loading: boolean;
  canPerformAllActions: (features: FeatureFlags[]) => boolean;
  canPerformAnyAction: (features: FeatureFlags[]) => boolean;
  canPerformAction: (feature: FeatureFlags) => boolean;
}

/**
 * Initial value for ConfigurationContextProps
 */
export const initialValue: ConfigurationContextProps = {
  configuration: undefined,
  loading: true,
  canPerformAllActions: () => false,
  canPerformAnyAction: () => false,
  canPerformAction: () => false
};

/**
 * ConfigurationContext
 */
export const ConfigContext = React.createContext<ConfigurationContextProps>(initialValue);

/**
 * ConfigurationContextProvider
 * @param props ConfigurationContextProviderProps
 */
export const ConfigContextProvider: React.FC<ConfigurationContextProviderProps> = ({ children }) => {
  const cognitoLoader = useAsyncCall(async () => {
    try {
      console.info("[ConfigurationContext] Loading user preferences from COGNITO...");
      return await AuthService.getUserPreferences();
    } catch (error) {
      console.error("[ConfigurationContext] Error loading user preferences from COGNITO", error);
    }
  }, []);

  const userPreferences = useMemo(() => (cognitoLoader.completed ? cognitoLoader.result : undefined), [cognitoLoader]);

  /**
   * This function is used to load the app configuration
   * /tnso/development/portal/
   * @returns AppConfiguration
   */
  const appConfigLoader = useAsyncCall(async () => {
    console.info("[ConfigurationContext] Loading configuration...");
    const appConfiguration: AppConfiguration = {};
    const localVariables: EnvironmentVariablesFull = import.meta.env ?? {};
    let variables: Record<string, string> = {};

    Object.keys(localVariables).forEach((key) => {
      const value = localVariables[key as keyof EnvironmentVariablesFull] ?? "";
      const newKey = key.startsWith("VITE_") ? key.slice(5) : key;
      variables[newKey] = value;
    });

    console.info("[ConfigurationContext] Local variables loaded", variables);
    const forceVariables = localVariables.VITE_FORCE_LOCAL_VARIABLES === "true";

    if (!forceVariables && userPreferences) {
      console.debug("User preferences loaded", userPreferences);
      Object.keys(userPreferences).forEach((key) => {
        const parsedKey = key as keyof CognitoTokenUserData;
        const value = userPreferences[parsedKey] as string;
        variables = { ...variables, [parsedKey]: value };
      });
      localStorage.setItem("UserPreferences", JSON.stringify(userPreferences));
    }

    let token = "";
    try {
      console.info("[ConfigurationContext] Fetching user token from COGNITO...");
      const attributes = await fetchAuthSession();
      token = attributes.tokens?.accessToken.toString() ?? "";
    } catch (error) {
      console.warn("[ConfigurationContext] Error fetching user token from COGNITO, using from local storage...", error);
      token = localStorage.getItem("accessToken")?.replace("Bearer ", "") ?? "";
    }

    console.info(
      `[ConfigurationContext] Validating for LAMBDA URL configured: ${localVariables.VITE_LAMBDA_PARAMS_URL ? localVariables.VITE_LAMBDA_PARAMS_URL : "NOT CONFIGURED"}`,
      token
    );
    if (!forceVariables && localVariables.VITE_LAMBDA_PARAMS_URL && token) {
      try {
        console.info("[ConfigurationContext] Loading configuration from lambda...");
        const parameters = await ConfigurationService.getParams(token);
        const parametersEntries = Object.entries(parameters ?? {});

        if (parametersEntries.length > 0) {
          console.info("[ConfigurationContext] Parameters obtained from LAMBDA, proceeding to configure in context", parameters);
          for (const [parameter, value] of parametersEntries) {
            if (!parameter) {
              continue;
            }

            const paramName = parameter.split("/").pop()?.toString().toUpperCase() ?? "";
            if (paramName) {
              const isEnabled = value.enabled !== undefined;
              let paramValue = isEnabled;
              if (isEnabled) {
                if (value.options) {
                  paramValue = value.options;
                }

                if (value[paramName.toLowerCase()]) {
                  paramValue = value[paramName.toLowerCase()];
                }
              }
              variables = { ...variables, [paramName]: paramValue.toString() };
            }
          }
        }
      } catch (error) {
        console.error("Error loading configuration from lambda, doing fallback .env:", error as object);
      }
    }

    console.info("[ConfigurationContext] Preparing API configuration base object", variables);
    const apiVariables: Pick<EnvironmentVariablesFull, "API_URL_BASE" | "API_URL_STRAPI" | "DIFFERENCE_TIME_TO_REFRESH_TOKEN" | "DIFFERENCE_TIME_TO_REFRESH_COGNITO_TOKEN"> = {
      API_URL_BASE: variables.API_URL_BASE ?? "",
      API_URL_STRAPI: variables.API_URL_STRAPI ?? "",
      DIFFERENCE_TIME_TO_REFRESH_TOKEN: variables.DIFFERENCE_TIME_TO_REFRESH_TOKEN ?? "",
      DIFFERENCE_TIME_TO_REFRESH_COGNITO_TOKEN: variables.DIFFERENCE_TIME_TO_REFRESH_COGNITO_TOKEN ?? ""
    };

    // Check if the configuration is equal to the existing one
    let existingVariables = {};
    const storageInfo = localStorage.getItem("ApiConfiguration");
    if (storageInfo) {
      try {
        existingVariables = JSON.parse(storageInfo);
      } catch (error) {
        console.warn("Error parsing user API configuration");
      }
    }

    const isApiConfigurationEqual = JSON.stringify(existingVariables) === JSON.stringify(apiVariables);

    if (!isApiConfigurationEqual) {
      console.info("[ConfigurationContext] API configuration has changed, updating...");
      localStorage.removeItem("ApiConfiguration");
      localStorage.setItem("ApiConfiguration", JSON.stringify(apiVariables));
    } else {
      console.info("[ConfigurationContext] API configuration is the same, skipping...");
    }

    appConfiguration.environmentValues = variables;
    return appConfiguration;
  }, [userPreferences]);

  const environmentVariables = useMemo<EnvironmentVariablesFull | undefined>(
    () => (appConfigLoader.completed ? appConfigLoader.result?.environmentValues : undefined),
    [appConfigLoader]
  );

  /**
   * This function is used to check if the user can perform all actions
   * @param features FeatureFlags[] - The features to check
   * @returns boolean
   * */
  const canPerformAllActions = useCallback(
    (features: FeatureFlags[]) => {
      if (environmentVariables) {
        console.info("[ConfigurationContext] Variables loaded - Checking if user can perform all actions", features);
        return features.every((feature) => {
          const featureName = feature as keyof EnvironmentVariablesFull;
          return environmentVariables[featureName] === "true";
        });
      }

      console.warn("[ConfigurationContext] Variables not loaded - User can't perform all actions", features);
      return false;
    },
    [environmentVariables]
  );

  /**
   * This function is used to check if the user can perform any action
   * @param features FeatureFlags[] - The features to check
   * @returns boolean
   */
  const canPerformAction = useCallback(
    (feature: FeatureFlags) => {
      if (environmentVariables) {
        console.info("[ConfigurationContext] Variables loaded - Checking if user can perform action", feature);
        const featureName = feature as keyof EnvironmentVariablesFull;
        return environmentVariables[featureName] === "true";
      }

      console.warn("[ConfigurationContext] Variables not loaded - User can't perform action", feature);
      return false;
    },
    [environmentVariables]
  );

  /**
   * This function is used to check if the user can perform any action
   * @param features FeatureFlags[] - The features to check
   * @returns boolean
   */
  const canPerformAnyAction = useCallback(
    (features: FeatureFlags[]) => {
      if (environmentVariables) {
        console.info("[ConfigurationContext] Variables loaded - Checking if user can perform any action", features);
        return features.some((feature) => {
          const featureName = feature as keyof EnvironmentVariablesFull;
          return environmentVariables[featureName] === "true";
        });
      }

      console.warn("[ConfigurationContext] Variables not loaded - User can't perform any action", features);
      return false;
    },
    [environmentVariables]
  );

  /**
   * This variable is used to store the configuration
   * @returns AppConfiguration
   * */
  const configuration = useMemo(() => (appConfigLoader.completed ? appConfigLoader.result : undefined), [appConfigLoader]);

  /**
   * This variable is used to store the context value
   */
  const contextValue = useMemo(
    () => (
      <ConfigContext.Provider value={{ loading: appConfigLoader.loading, configuration, canPerformAllActions, canPerformAnyAction, canPerformAction }}>
        {children}
      </ConfigContext.Provider>
    ),
    [appConfigLoader.loading, canPerformAction, canPerformAllActions, canPerformAnyAction, children, configuration]
  );

  return contextValue;
};
