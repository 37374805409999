import React, { useCallback, useContext, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { COLORS } from "../../../../utils/const/colors";
import { Measurement, TemperatureData } from "../../../../models/devices/hardware/hardware";
import { Radio, RadioChangeEvent } from "antd";
import { LegendOptions } from "./legend-options";
import { MapperHelper } from "../../../../../../shared/helpers/MapperHelper";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../utils/const/translation";
import { HardwareHelper } from "../../../../helpers/dhi/hardware-helper";
import { ExportXLSLHelper, TypeFile } from "../../../../../portal-app/helpers/shared/ExportXLSLHelper";
import { Variants, TNSButton, TNSCard, Themes } from "@tns/ui-components";
import { utils } from "xlsx";
import { ApexOptions } from "apexcharts";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomizerContext } from "../../../../../../contexts/customizer/CustomizerContext";

interface TemperatureGraphProps {
  data?: TemperatureData;
  isMonthly?: boolean;
  isDaily?: boolean;
}
export const TemperatureGraph: React.FC<TemperatureGraphProps> = ({ data, isMonthly, isDaily }) => {
  const { t } = useTranslation();
  const customizer = useContext(CustomizerContext);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [measurement, setMeasurement] = useState(Measurement.temperatureInFahrenheit);
  const dataByMeasurement = measurement === Measurement.temperatureInFahrenheit ? data?.temperatureInFahrenheit : data?.temperatureInCelsius;
  const minValue = dataByMeasurement && Math.min(...dataByMeasurement.map((item) => item.value));
  const xAxisCategories = dataByMeasurement?.map((_, index) => HardwareHelper.formatXAxisLabel((index + 1).toString(), dataByMeasurement || [], isMonthly, isDaily));

  const exportData = (): void => {
    setIsLoadingExport(true);
    setTimeout(() => {
      const dataMapped = MapperHelper.mapTemperatureUsageToExport(data, isMonthly, isDaily, measurement);
      const wb = utils.book_new();
      ExportXLSLHelper.addSheetToBook(wb, dataMapped, 1);
      ExportXLSLHelper.exportToXLSL(wb, t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.temperature), TypeFile.CSV);
      setIsLoadingExport(false);
    }, 1500);
  };

  const yaxisMax = useMemo(() => {
    return measurement === Measurement.temperatureInFahrenheit ? 188 : 100;
  }, [measurement]);

  const tickAmount = useMemo(() => {
    return measurement === Measurement.temperatureInFahrenheit ? 11 : 10;
  }, [measurement]);

  const yaxisMin = useMemo(() => {
    return measurement === Measurement.temperatureInFahrenheit ? minValue && minValue - 9 : 0;
  }, [measurement, minValue]);

  const ranges = useMemo(() => {
    return [
      {
        from: measurement === Measurement.temperatureInFahrenheit ? 167 : 75,
        to: Infinity,
        color: COLORS.BARS.RED
      },
      {
        from: measurement === Measurement.temperatureInFahrenheit ? 149 : 65,
        to: measurement === Measurement.temperatureInFahrenheit ? 167 : 75,
        color: COLORS.BARS.YELLOW
      },
      {
        from: 0,
        to: measurement === Measurement.temperatureInFahrenheit ? 149 : 65,
        color: COLORS.BARS.GREEN
      }
    ];
  }, [measurement]);

  const temperatureOptions = [
    {
      value: Measurement.temperatureInFahrenheit,
      children: Measurement.fahrenheit
    },
    {
      value: Measurement.temperatureInCelsius,
      children: Measurement.celsius
    }
  ];

  const handleChangeTemperatureMeasurement = useCallback(async (e: RadioChangeEvent) => {
    setMeasurement(e.target.value);
  }, []);

  const series: ApexAxisChartSeries = [
    {
      data: dataByMeasurement?.map((item) => item.value) ?? []
    }
  ];

  const options: ApexOptions = {
    tooltip: {
      theme: customizer.selectedTheme == Themes.DARK ? "dark" : "light",
      y: {
        formatter: function (val: number): string {
          return val + `${measurement === Measurement.temperatureInFahrenheit ? Measurement.measurementF : Measurement.measurementC}`;
        }
      }
    },
    chart: {
      type: "bar",
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    annotations: {
      yaxis: [
        {
          y: measurement === Measurement.temperatureInFahrenheit ? 149 : 65,
          borderColor: COLORS.BARS.GREEN,
          strokeDashArray: 5,
          yAxisIndex: 0,
          offsetX: 0,
          offsetY: 0,
          borderWidth: 2
        }
      ]
    },
    xaxis: {
      categories: xAxisCategories,
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      min: yaxisMin,
      max: yaxisMax,
      tickAmount: tickAmount,
      labels: {
        formatter: function (value: number): string {
          return `${Number(value).toFixed(0)}${measurement === Measurement.temperatureInFahrenheit ? Measurement.measurementF : Measurement.measurementC}`;
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    legend: {
      show: false
    },
    plotOptions: {
      bar: {
        distributed: true,
        colors: {
          ranges: ranges
        }
      }
    }
  };
  const temperatureGraph = useMemo(() => {
    const critical = measurement === Measurement.temperatureInFahrenheit ? "167°F" : "75°C";
    const warning = measurement === Measurement.temperatureInFahrenheit ? "149°F" : "65°C";
    return (
      <TNSCard>
        <div className="mb-3 mt-2">
          <span className="p-2">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.temperature)}</span>
        </div>
        <div>
          <ReactApexChart options={options} series={series} type="bar" height={350} />
          <div className="d-flex gap-4 w-100 justify-content-center">
            <LegendOptions
              color={COLORS.BARS.RED}
              label={`${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.critical)}: > ${critical}`}
              height="10px"
              width="40px"
            />
            <LegendOptions
              color={COLORS.BARS.YELLOW}
              label={`${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.warning)}: > ${warning}`}
              height="10px"
              width="40px"
            />
            <LegendOptions color={COLORS.BARS.GREEN} label={`${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.normal)}: < ${warning}`} height="10px" width="40px" />
          </div>
        </div>
      </TNSCard>
    );
  }, [measurement, xAxisCategories, t]);

  return (
    <>
      <Radio.Group onChange={handleChangeTemperatureMeasurement} defaultValue={measurement} className="mb-2">
        {temperatureOptions &&
          temperatureOptions.map((option) => (
            <Radio key={option.value} value={option.value}>
              <span>{option.children}</span>
            </Radio>
          ))}
      </Radio.Group>
      {temperatureGraph}
      <div className="mt-2">
        <TNSButton buttonVariant={Variants.Secondary} loading={isLoadingExport} onClick={exportData} disabled={isLoadingExport}>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <FontAwesomeIcon icon={faDownload} /> {t("exportButton")}
          </div>
        </TNSButton>
      </div>
    </>
  );
};
