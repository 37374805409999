import React, { ReactElement, useContext, useMemo } from "react";
import { TNSSelect } from "@tns/ui-components";
import { Controller, useFormContext } from "react-hook-form";
import { ConfigContext } from "../../../../../../../../../contexts/configuration/ConfigurationContext";
import { TroubleshootingContext, TroubleshootingFormValues } from "../../../../../../../components/device/troubleshooting/context/troubleshooting-context";
import { TroubleshootingTools } from "../enums/TroubleshootingTools";
import { isEmpty } from "lodash";
import { TRANSLATION } from "../../../../../../../utils/const/translation";
import { useTranslation } from "react-i18next";

export const TimeFormSelect: React.FC = () => {
  const { t } = useTranslation();
  const context = useContext(ConfigContext);
  const {
    control,
    formState: { errors }
  } = useFormContext<TroubleshootingFormValues>();
  const name: keyof TroubleshootingFormValues = "time";
  const { selectedTool, filtersEnabled } = useContext(TroubleshootingContext);

  const options = useMemo(() => {
    let times: string | undefined;
    switch (selectedTool) {
      case TroubleshootingTools.Ping:
        times = context.configuration?.environmentValues?.TROUBLESHOOTING_TIME_PING;
        break;
      case TroubleshootingTools.TCPCaptures:
        times = context.configuration?.environmentValues?.TROUBLESHOOTING_TIME_TCPPACKETCAPTURE;
        break;
    }
    const options = times?.split(",") ?? [];
    return options.map((time: string) => {
      return { label: `${time} ${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.seconds)}`, value: Number(time) };
    });
  }, [context.configuration?.environmentValues?.TROUBLESHOOTING_TIME_PING, context.configuration?.environmentValues?.TROUBLESHOOTING_TIME_TCPPACKETCAPTURE, selectedTool, t]);

  return (
    <div className="d-flex flex-column w-25">
      <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.time)}</span>
      <div>
        <Controller
          name={name}
          control={control}
          rules={{
            required: true,
            validate: (value): boolean => {
              return isEmpty(value);
            }
          }}
          render={({ field }): ReactElement => (
            <TNSSelect
              className="w-100"
              placeholder={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.select)}
              {...(errors[name] ? { status: "error" } : {})}
              onSelect={(e): void => {
                field.onChange(Number(e));
              }}
              options={options}
              value={field.value}
              disabled={!filtersEnabled}
            />
          )}
        />
        {errors[name] && <small className="text-danger position-absolute">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.requiredField)}</small>}
      </div>
    </div>
  );
};
