import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { TNSButton, TNSCollapse, TNSSelect, Variants } from "@tns/ui-components";
import { useAsyncCall } from "../../../../../../libraries/shared/src/lib/hooks/use-async-call-shared";
import { useAsyncCall as useAsyncCallWithoutRefresh } from "src/shared/hooks/useAsyncCallShared";
import { HardwareService } from "../../../service/device/dhi/hardware-service";
import { useParams } from "react-router-dom";
import { StorageUsageMonthly, CPUCoresData, TemperatureData, MemoryUsageMonthly } from "../../../models/devices/hardware/hardware";
import { IDeviceMetrics } from "../../../models/devices/dhi/dhi";
import { HardwareAllDetails } from "../../../components/dhi/hardware/hardware-all-details";
import { StorageUsageGraph } from "../../../components/dhi/hardware/graphs/storage-graph";
import { CPUUsageGraph } from "../../../components/dhi/hardware/graphs/cpu-graph";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../monitoring/utils/const/translation";
import { TemperatureGraph } from "../../../components/dhi/hardware/graphs/temperature-graph";
import moment from "moment";
import { MemoryUsageGraph } from "../../../components/graphs/memory-graph";
import { Spin } from "antd";
import { store } from "src/app/portal-app/store/StoreMobx";

interface Collapsible {
  storage: boolean;
  cpu: boolean;
  memory: boolean;
  temperature: boolean;
}

enum Months {
  January = "january",
  February = "february",
  March = "march",
  April = "april",
  May = "may",
  June = "june",
  July = "july",
  August = "august",
  September = "september",
  October = "october",
  November = "november",
  December = "december"
}

export const Hardware: React.FC = observer(() => {
  const { t } = useTranslation();
  const { deviceName } = useParams();
  const [metrics, setMetrics] = useState<IDeviceMetrics>();
  const [monthly, setMonthly] = useState<boolean>(true);
  const [daily, setDaily] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(Object.values(Months)[moment().month()]);
  const [selectedDay, setSelectedDay] = useState<number | null>(null);

  const [collapsible, setCollapsible] = useState<Collapsible>({ storage: false, cpu: false, memory: false, temperature: false });

  const storageMetricsLoader = useAsyncCallWithoutRefresh(async () => {
    if (deviceName && collapsible.storage) {
      const result =
        monthly && selectedMonth
          ? await HardwareService.getStorageUsageMonthlyWithMonth(deviceName, selectedMonth, selectedDay)
          : await HardwareService.getStorageUsageMonthly(deviceName);
      return result?.data;
    }
  }, [deviceName, selectedMonth, selectedDay, collapsible.storage, monthly, daily]);

  const storageMetrics = useMemo<StorageUsageMonthly[] | undefined>(
    () => (storageMetricsLoader.completed && storageMetricsLoader.result ? storageMetricsLoader.result : undefined),
    [storageMetricsLoader]
  );

  const cpuLoader = useAsyncCallWithoutRefresh(async () => {
    if (deviceName && collapsible.cpu && !storageMetricsLoader.loading) {
      const result =
        monthly && selectedMonth ? await HardwareService.getCPUUsageMonthlyWithMonth(deviceName, selectedMonth, selectedDay) : await HardwareService.getCPUUsageMonthly(deviceName);
      return result?.data;
    }
  }, [deviceName, selectedMonth, selectedDay, collapsible.cpu, monthly, daily]);

  const cpuMetrics = useMemo<CPUCoresData | undefined>(() => (cpuLoader.completed && cpuLoader.result ? cpuLoader.result : undefined), [cpuLoader]);

  const temperatureLoader = useAsyncCallWithoutRefresh(async () => {
    if (deviceName && !cpuLoader.loading && !storageMetricsLoader.loading) {
      const result =
        monthly && selectedMonth
          ? await HardwareService.getTemperatureMonthlyWithMonth(deviceName, selectedMonth, selectedDay)
          : await HardwareService.getTemperatureMonthly(deviceName);
      return result?.data;
    }
  }, [deviceName, selectedMonth, selectedDay, collapsible.temperature, monthly]);

  const temperatureMetrics = useMemo<TemperatureData | undefined>(
    () => (temperatureLoader.completed && temperatureLoader.result ? temperatureLoader.result : undefined),
    [temperatureLoader]
  );

  const memoryLoader = useAsyncCallWithoutRefresh(async () => {
    if (deviceName && collapsible.memory && !cpuLoader.loading && !storageMetricsLoader.loading && !temperatureLoader.loading) {
      const result =
        monthly && selectedMonth
          ? await HardwareService.getMemoryUsageMonthlyWithMonth(deviceName, selectedMonth, selectedDay)
          : await HardwareService.getMemoryUsageMonthly(deviceName);
      return result?.data;
    }
  }, [deviceName, selectedMonth, selectedDay, collapsible.memory, monthly]);

  const memoryMetrics = useMemo<MemoryUsageMonthly[] | undefined>(() => (memoryLoader.completed && memoryLoader.result ? memoryLoader.result : undefined), [memoryLoader]);

  const loadData = useCallback(async () => {
    if (deviceName) {
      const response = await HardwareService.getAll(deviceName);
      await store.device.detail.loadData(deviceName);
      setMetrics(response?.data);
      setCollapsible({ storage: false, cpu: false, memory: false, temperature: false });
    }
  }, [deviceName]);

  const loader = useAsyncCall(loadData, [], store.shared.getTimeToAutoRefresh());

  const spinner = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
        <Spin />
      </div>
    );
  }, []);

  const spinnerLoaders = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spin />
      </div>
    );
  }, []);

  const getDaysArray = (length: number): number[] => {
    return Array.from({ length }, (_, i) => i + 1);
  };

  const handleSelectLast12Months = async (): Promise<void> => {
    setSelectedMonth(null);
    setSelectedDay(null);
    setMonthly(false);
    setDaily(false);
  };

  const handleSelectMonth = useCallback((selected: string) => {
    setDaily(false);
    setMonthly(true);
    setSelectedMonth(selected);
    setSelectedDay(null);
  }, []);

  const handleSelectDay = useCallback((selected: string) => {
    setSelectedDay(parseInt(selected));
    setDaily(true);
  }, []);

  const handleChange = useCallback(
    (key: keyof Collapsible) => {
      const newCollapsible = { ...collapsible };
      newCollapsible[key] = !collapsible[key];
      setCollapsible(newCollapsible);
    },
    [collapsible]
  );

  return loader.completed ? (
    <div>
      <HardwareAllDetails metrics={metrics} />
      <div className="d-flex gap-2 mt-3 mb-2">
        <TNSButton buttonVariant={Variants.Primary} onClick={handleSelectLast12Months}>
          {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.showLast12Months)}
        </TNSButton>
        <TNSSelect value={selectedMonth || undefined} onSelect={handleSelectMonth} placeholder={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.selectMonth)}>
          {Object.values(Months).map((month) => (
            <option key={month} value={month}>
              {t(
                TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.UPTIME.MONTHSLEYEND[
                  month as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.UPTIME.MONTHSLEYEND
                ]
              )}
            </option>
          ))}
        </TNSSelect>
        <TNSSelect
          value={selectedDay || undefined}
          onSelect={(selected): void => handleSelectDay(selected)}
          disabled={selectedMonth === null}
          placeholder={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.selectDay)}>
          {selectedMonth
            ? getDaysArray(moment().month(selectedMonth).endOf("month").date()).map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))
            : null}
        </TNSSelect>
      </div>
      <div>
        <TNSCollapse
          items={[
            {
              label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.temperature),
              children: !temperatureLoader.loading ? <TemperatureGraph data={temperatureMetrics} isMonthly={monthly} isDaily={daily} /> : spinnerLoaders
            }
          ]}
          className="w-100 mb-2"
          onChange={(): void => handleChange("temperature")}
        />
        <TNSCollapse
          items={[
            {
              label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.memory),
              children: !memoryLoader.loading ? <MemoryUsageGraph data={memoryMetrics} isMonthly={monthly} isDaily={daily} /> : spinnerLoaders
            }
          ]}
          className="w-100 mb-2"
          onChange={(): void => handleChange("memory")}
        />
        <TNSCollapse
          items={[
            {
              label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.storage),
              children: !storageMetricsLoader.loading ? <StorageUsageGraph data={storageMetrics} isMonthly={monthly} isDaily={daily} /> : spinnerLoaders
            }
          ]}
          className="w-100 mb-2"
          onChange={(): void => handleChange("storage")}
        />
        <TNSCollapse
          items={[
            {
              label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.cpu),
              children: !cpuLoader.loading ? <CPUUsageGraph data={cpuMetrics} isMonthly={monthly} isDaily={daily} /> : spinnerLoaders
            }
          ]}
          className="w-100 mb-2"
          onChange={(): void => handleChange("cpu")}
        />
      </div>
    </div>
  ) : (
    spinner
  );
});
