import { ColumnsType } from "antd/lib/table";
import { TRANSLATION } from "../../utils/translation-keys";
import i18n from "../../../../i18n";

export class TranslationHelper {
  public static columnsTranslation(columns: ColumnsType[]): ColumnsType[] {
    // eslint-disable-next-line
    return columns.map((column: any) => {
      return {
        ...column,
        title: i18n.t(TRANSLATION.CONTACT[column.title as keyof typeof TRANSLATION.CONTACT])
      };
    });
  }

  static dataTranslation<T>(data: T[], keysTranslation: string[]): T[] {
    const dataTranslated = data.map((item) => {
      let obj = item as Record<string, string>;
      keysTranslation.forEach((key) => {
        obj = {
          ...obj,
          [key]: i18n.t(TRANSLATION.CONTACT[obj[key] as keyof typeof TRANSLATION.CONTACT] as keyof typeof TRANSLATION.CONTACT)
        };
      });
      return obj as T;
    });

    return dataTranslated;
  }

  public static userRolesColumnsTranslation(columns: ColumnsType[]): ColumnsType[] {
    // eslint-disable-next-line
    return columns.map((column: any) => {
      return {
        ...column,
        title: i18n.t(column.title)
      };
    });
  }

  static userRolesDataTranslation<T>(data?: T[], keysTranslation?: string[]): T[] {
    if (data && keysTranslation) {
      const dataTranslated = data.map((item) => {
        let obj = item as Record<string, string>;
        keysTranslation.forEach((key) => {
          obj = {
            ...obj,
            [key]: i18n.t(obj[key])
          };
        });
        return obj as T;
      });

      return dataTranslated;
    }
    return [];
  }
}
