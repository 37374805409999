export interface ISIMDetail {
  slot: number;
  iccid: string;
  carrier: string;
  isActive: boolean;
}

export interface IDeviceCPUUsage {
  name: string;
  usage: number;
  key?: string;
}

export interface IDeviceMetrics {
  temperatureInCelsius: number;
  temperatureInFahrenheit: number;
  memoryUsage: number;
  storageUsage: number;
  latency: number;
  uptime: string;
  cpuUsage: IDeviceCPUUsage[];
}

export interface IInterfaceMetrics {
  name: string;
  status: string;
  configSpeed: number;
  utilization: number;
}

export interface IInterfaceMetricsToExport {
  [key: string]: string | number;
}

export enum IDeviceTemperatureUnit {
  C = "C",
  F = "F"
}
