import { GroupForm, GroupParams, GroupRenderResponse, GroupRenderProps, DevicesByGroupName } from "../../interfaces/devices/group/group";
import { GroupService } from "../../service/device/GroupService";
import { makeAutoObservable } from "mobx";
import { QueryParams } from "../../interfaces/shared/queryParams";
import { MessageHelper } from "../../helpers/shared/MessageHelper";
import i18next from "../../../../i18n";
import { TRANSLATION } from "../../utils/const/translation";
import { BuilderParams, StatusCode } from "../../helpers/api/RequestHelper";
import { AllGroupsResponse, AssociatedDevicesInGroupResponse } from "../../interfaces/devices/group/available-groups";
import { FilterType } from "../../../administration/models/device-profile.model";

export interface IGroup {
  originalGroups?: GroupRenderProps[];
  groupRender?: GroupRenderResponse;
  loading: boolean;

  loadData: (params?: QueryParams) => Promise<GroupRenderResponse | undefined>;
  loadAllGroups: (params?: GroupParams) => Promise<AllGroupsResponse | undefined>;
  loadAllMyGroups: (params?: GroupParams) => Promise<AllGroupsResponse | undefined>;
  loadAvailableAllDevices: (groupName: string, filter: FilterType, params?: GroupParams) => Promise<AssociatedDevicesInGroupResponse | undefined>;
  loadAssocaitedDevices: (groupName: string, params?: GroupParams) => Promise<AssociatedDevicesInGroupResponse | undefined>;
  editDevicesByGroupName: (groupName: string, group: DevicesByGroupName) => Promise<boolean | undefined>;
  deleteGroup: (groupName: string) => Promise<boolean | undefined>;
  isAvailable: (groupName: string, previousGroupName?: string) => Promise<boolean | undefined>;
  createGroup: (group: GroupForm) => Promise<boolean | undefined>;
  handlePageChange: (page: number, recordsPerPage: number) => GroupRenderResponse | undefined;
  setCheckedGroup: (groupName: string) => string[] | undefined;
  getCheckedGroup: () => string[] | undefined;
  builderGroupQueryParams: (params: BuilderParams<GroupParams>) => GroupParams;
}

class Group implements IGroup {
  groupRender?: GroupRenderResponse;
  originalGroups?: GroupRenderProps[];
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadData = async (params?: QueryParams): Promise<GroupRenderResponse | undefined> => {
    try {
      const response = await GroupService.getAll();
      if (response?.data) {
        this.originalGroups = response.data.groups.map((group) => ({
          ...group,
          checked: true
        }));
        this.groupRender = {
          groups: this.originalGroups.slice(params?.startAtRecord, params?.recordsPerPage).map((group) => ({
            ...group,
            checked: group.checked
          })),
          totalRecords: response.data.groups.length
        };

        return this.groupRender;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18next.t(TRANSLATION.ERROR.noInformationAvailable));
    }
  };

  loadAllGroups = async (params?: GroupParams): Promise<AllGroupsResponse | undefined> => {
    try {
      this.loading = true;
      const response = await GroupService.getAllGroups(params);
      if (response?.data) {
        return response.data;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18next.t(TRANSLATION.ERROR.noInformationAvailable));
    } finally {
      this.loading = false;
    }
  };

  loadAllMyGroups = async (params?: GroupParams): Promise<AllGroupsResponse | undefined> => {
    try {
      this.loading = true;
      const response = await GroupService.getAllMyGroups(params);
      if (response?.data) {
        return response.data;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18next.t(TRANSLATION.ERROR.noInformationAvailable));
    } finally {
      this.loading = false;
    }
  };

  loadAvailableAllDevices = async (groupName: string, filter: FilterType, params?: GroupParams): Promise<AssociatedDevicesInGroupResponse | undefined> => {
    try {
      if (groupName !== "") {
        if (filter === FilterType.AllDevices) {
          const response = await GroupService.getAvailableDevicesGroup(groupName, params);
          if (response?.data) {
            return response.data;
          }
        } else {
          const response = await GroupService.getAvailableDevicesGroupByFilter(groupName, filter, params);
          if (response?.data) {
            return response.data;
          }
        }
      }
    } catch (error) {
      MessageHelper.errorMessage(i18next.t(TRANSLATION.ERROR.noInformationAvailable));
    }
  };

  loadAssocaitedDevices = async (groupName: string, params?: GroupParams): Promise<AssociatedDevicesInGroupResponse | undefined> => {
    try {
      const response = await GroupService.getAssociatedDevicesGroup(groupName, params);
      if (response?.data) {
        return response.data;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18next.t(TRANSLATION.ERROR.noInformationAvailable));
    }
  };

  editDevicesByGroupName = async (groupName: string, group: DevicesByGroupName): Promise<boolean | undefined> => {
    try {
      const response = await GroupService.editGroup(groupName, group);
      if (response?.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18next.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.EDIT.groupEditedSuccessfully));
        return true;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18next.t(TRANSLATION.ERROR.noInformationAvailable));
    }
  };

  deleteGroup = async (groupName: string): Promise<boolean | undefined> => {
    try {
      const response = await GroupService.deleteGroup(groupName);
      if (response?.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18next.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.DELETE.groupDeletedSuccessfully));
        return true;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18next.t(TRANSLATION.ERROR.noInformationAvailable));
    }
  };

  isAvailable = async (groupName: string, previousGroupName?: string): Promise<boolean | undefined> => {
    try {
      if (previousGroupName && previousGroupName === groupName) {
        return true;
      } else {
        const response = await GroupService.isAvailable(groupName);
        if (response?.data) {
          return response.data.available;
        }
      }
    } catch (error) {
      MessageHelper.errorMessage(i18next.t(TRANSLATION.ERROR.noInformationAvailable));
    }
  };

  createGroup = async (group: GroupForm): Promise<boolean | undefined> => {
    try {
      const response = await GroupService.create(group);
      if (response?.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18next.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.CREATE.groupCreatedSuccessfully));
        return true;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18next.t(TRANSLATION.ERROR.noInformationAvailable));
    }
  };

  handlePageChange = (page: number, recordsPerPage: number): GroupRenderResponse | undefined => {
    if (this.groupRender) {
      return { ...this.groupRender, groups: this.originalGroups?.slice((page - 1) * recordsPerPage, page * recordsPerPage) };
    }
  };

  setCheckedGroup = (groupName: string): string[] | undefined => {
    this.groupRender?.groups?.find((groupRender) => {
      if (groupRender.name === groupName) {
        groupRender.checked = !groupRender.checked;
      }
    });

    this.originalGroups?.find((groupRender) => {
      if (groupRender.name === groupName) {
        groupRender.checked = !groupRender.checked;
      }
    });

    return this.getCheckedGroup();
  };

  getCheckedGroup = (): string[] | undefined => {
    if (this.originalGroups) {
      const filteredGroups: string[] = [];
      this.originalGroups.forEach((group) => {
        if (group.checked && group.name) {
          filteredGroups.push(group.name);
        }
      });
      return filteredGroups;
    }
  };

  builderGroupQueryParams = ({ currentPage = 0, recordsPerPage = 10 }: BuilderParams<GroupParams> = {}): GroupParams => {
    const startAtRecord = (currentPage || 0) * recordsPerPage;
    const queryParams = {
      startAtRecord: startAtRecord,
      recordsPerPage: recordsPerPage
    };

    return queryParams;
  };
}

const group = new Group();

export default group;
