import React from "react";
import { observer } from "mobx-react";
import { TRANSLATION } from "../../../../utils/const/translation";
import { DeviceDetail } from "../../../../models/devices/devices";
import { useTranslation } from "react-i18next";
import { TNSDivider } from "@tns/ui-components";
import { InformationCardDetail } from "./InformationCardDetail";

interface PosAtmInfoCardProps {
  deviceDetail?: DeviceDetail;
}
export const PosAtmInfoCard: React.FC<PosAtmInfoCardProps> = observer(({ deviceDetail }): JSX.Element | null => {
  const { t } = useTranslation();

  const shouldRender =
    !deviceDetail?.atmManufacturer &&
    !deviceDetail?.atmModelNumber &&
    !deviceDetail?.atmProtocol &&
    !deviceDetail?.atmSerialBaudRate &&
    !deviceDetail?.atmPollAddress &&
    !deviceDetail?.atmDeviceAddress &&
    !deviceDetail?.atmHostCharacterSet;

  if (shouldRender) {
    return null;
  }
  return (
    <>
      <h6>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.deviceDetailsPOSATMCardTitle)}</h6>
      <TNSDivider type="horizontal" />
      <div className="d-flex flex-column w-100 ps-2 my-4">
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.deviceDetailsATMManufacturer)} data={deviceDetail?.atmManufacturer} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.deviceDetailsATMModelNumber)} data={deviceDetail?.atmModelNumber} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.deviceDetailsATMProtocol)} data={deviceDetail?.atmProtocol} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.deviceDetailsATMSerialBaudRate)} data={deviceDetail?.atmSerialBaudRate} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.deviceDetailsATMPollAddress)} data={deviceDetail?.atmPollAddress} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.deviceDetailsATMAddress)} data={deviceDetail?.atmDeviceAddress} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.deviceDetailsATMHostCharacter)} data={deviceDetail?.atmHostCharacterSet} />
      </div>
    </>
  );
});
