import React, { useMemo } from "react";
import { i18nInstance } from "../../../../i18n";
import { TRANSLATION } from "../../utils/const/translation";
import { DevicesGETApi, InlineResponse20076 as IsCompatibleResponse, InlineResponse20077 as GetFirewallRulesResponse } from "@tnso/api-sdk";
import { InstanceType, useApiController } from "../../../../shared/hooks/use-api-controller";
import { MessageHelper } from "../../helpers/shared/MessageHelper";
import { CustomErrorResponse } from "../../helpers/api/RequestHelper";
import { StatusCode } from "@tnso/api-core";

export interface FirewallRulesContextProviderProps {
  children: React.ReactNode;
}

export { IsCompatibleResponse, GetFirewallRulesResponse };

export enum StatusType {
  na = "N/A",
  live = "Live",
  configured = "Configured"
}

export enum DirectionType {
  inbound = "Inbound",
  outbound = "Outbound"
}

export interface FirewallRulesContextValue {
  getIsCompatible: (deviceName: string) => Promise<IsCompatibleResponse | undefined>;
  getFirewallRules: (deviceName: string) => Promise<GetFirewallRulesResponse[] | undefined>;
}

export const initialValue: FirewallRulesContextValue = {
  getIsCompatible: (): Promise<IsCompatibleResponse | undefined> => Promise.resolve(undefined),
  getFirewallRules: (): Promise<GetFirewallRulesResponse[] | undefined> => Promise.resolve(undefined)
};

export const FirewallRulesContext = React.createContext(initialValue);

const FirewallRulesContextProvider: React.FC<FirewallRulesContextProviderProps> = ({ children }) => {
  const apiGetController = useApiController(DevicesGETApi, InstanceType.DefaultWithBase);

  const getIsCompatible = async (deviceName: string): Promise<IsCompatibleResponse | undefined> => {
    try {
      const result = await apiGetController.devicesTnsDeviceNameIscompatibleFirewallGet(deviceName);
      return result?.data;
    } catch (error) {
      const parsedError = error as CustomErrorResponse;
      if (parsedError?.status === StatusCode.CONFLICT) {
        return;
      }
      MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.somethingWentWrongTryAgainLater));
      console.error(error);
    }
  };
  const getFirewallRules = async (deviceName: string): Promise<GetFirewallRulesResponse[] | undefined> => {
    try {
      const result = await apiGetController.devicesTnsDeviceNameRulesFirewallGet(deviceName);
      return result?.data;
    } catch (error) {
      const parsedError = error as CustomErrorResponse;
      if (parsedError?.status === StatusCode.CONFLICT) {
        return;
      }
      MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.somethingWentWrongTryAgainLater));
      console.error(error);
    }
  };

  const contextValue = useMemo(
    () => ({
      getIsCompatible,
      getFirewallRules
    }),
    [getIsCompatible, getFirewallRules]
  );

  return <FirewallRulesContext.Provider value={contextValue}>{children}</FirewallRulesContext.Provider>;
};

export default FirewallRulesContextProvider;
