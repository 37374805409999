import { Theme, toast, ToastOptions } from "react-toastify";

export class MessageHelper {
  static theme: Theme = "colored";
  static toastObject: ToastOptions<Record<string, unknown>> | undefined = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    theme: this.theme
  };

  static successMessage = (message: string, options?: ToastOptions): string | number => {
    return toast.success(message, { ...this.toastObject, ...options });
  };

  static errorMessage = (message: string, options?: ToastOptions): string | number => {
    console.log(message);
    return toast.error(message, { ...this.toastObject, ...options });
  };

  static infoMessage = (message: string, options?: ToastOptions): string | number => {
    return toast.info(message, { ...this.toastObject, ...options });
  };

  static warningMessage = (message: string, options?: ToastOptions): string | number => {
    return toast.warning(message, { ...this.toastObject, ...options });
  };

  static updateMessage(toastId: string | number, message: string, options?: ToastOptions): void {
    toast.update(toastId, {
      render: message,
      ...this.toastObject,
      ...options
    });
  }

  static dismissToast(toastId: string | number): void {
    toast.dismiss(toastId);
  }

  static dismissAll(): void {
    toast.dismiss();
  }
}