import React, { useCallback, useMemo } from "react";
import { HistoricalData } from "../../../../../../components/device/cellularUsage/HistoricalData";
import { store } from "../../../../../../store/StoreMobx";
import { observer } from "mobx-react";
import { Col, Row } from "react-bootstrap";
import { DataConsumed } from "../../../../../../components/device/cellularUsage/DataConsumed";
import { DailyData } from "../../../../../../components/device/cellularUsage/DailyData";
import { PeriodSelected } from "../../../../../../components/device/cellularUsage/PeriodSelected";
import { DateHelper } from "@tnso/shared";
import { useAsyncCall } from "../../../../../../../../../libraries/shared/src/lib/hooks/use-async-call-shared";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../../../utils/const/translation";
import Loader from "../../../../../../../../layouts/loader/Loader";

export const CellularUsage: React.FC = observer(() => {
  const { cellularUsage } = store.device;
  const { deviceName } = useParams();
  const { t } = useTranslation();

  const isCurrentPeriod = useMemo(() => cellularUsage.itemSelected === t("currentPeriod") || cellularUsage.itemSelected === "currentPeriod", [cellularUsage.itemSelected, t]);

  const loadMethod = useCallback(async () => {
    if (deviceName && cellularUsage.isWireless) {
      await cellularUsage.loadHistorical(deviceName);
      await cellularUsage.loadCurrent(deviceName);
      await cellularUsage.loadBillingPeriod(deviceName);
      await store.device.detail.loadData(deviceName);
    }
  }, [deviceName, cellularUsage]);

  const loader = useAsyncCall(loadMethod, [loadMethod], isCurrentPeriod ? store.shared.getTimeToAutoRefresh() : undefined);

  return loader.completed ? (
    <div>
      <Row className="mb-2">
        <Col md="auto" className="align-self-center">
          <h6>
            <b>{t(TRANSLATION.SHARED.TABLE.billingPeriod)}:</b>
          </h6>
        </Col>
        <Col md="auto">
          <PeriodSelected />
        </Col>
      </Row>
      {cellularUsage.current?.startDate === cellularUsage.currentData?.startDate && cellularUsage.currentDate && (
        <div className="d-flex mb-2 align-self-start">
          <h6>
            <b>{t(TRANSLATION.SHARED.date)}:</b>
          </h6>
          <span className="ms-2">
            {DateHelper.convertTimestampToBrowserCulture(cellularUsage.currentDate, store.auth.userInfo?.timeZone, { day: "2-digit", month: "2-digit", year: "numeric" })}
          </span>
        </div>
      )}
      <Row>
        <Col md={5}>
          <DataConsumed data={cellularUsage.current} />
        </Col>
        <Col md={7}>
          <DailyData />
        </Col>
      </Row>
      <HistoricalData data={cellularUsage.historical} loading={cellularUsage.loading} />
    </div>
  ) : (
    <Loader />
  );
});
