import { ApiService, IResponse } from "@tnso/api-core";
import { Note } from "../../../models/devices/note/note";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class NoteService extends ApiService {
  static async getAll(deviceName: string): Promise<IResponse<Note> | undefined> {
    return this.get<Note>(`${getApiBaseUrl()}/devices/${deviceName}/note`);
  }
  static async update(note: Note, deviceName: string): Promise<IResponse<Note> | undefined> {
    return this.patch<Note>(`${getApiBaseUrl()}/devices/${deviceName}/note`, note);
  }
}
