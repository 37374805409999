import { CoinReportsService } from "../../service/coin/CoinReportsService";
import { AvailabilityReport as AvailabilityReportModel, MembersAvailabilityReport as MembersAvailabilityReportModel } from "../../interfaces/coin/coinReports";
import { makeAutoObservable } from "mobx";

export interface ICoinReports {
  availabilityReportData?: AvailabilityReportModel[];
  memberAvailabilityReportData?: MembersAvailabilityReportModel[];
  yearReportFilter?: number[];
  acnaFilter?: string[];

  loadAvailabilityReportData: (params?: string) => Promise<void>;
  loadMemberAvailabilityReportData: ({year, acna}:{year: number, acna: string}) => Promise<void>;
  loadYearReportFilterData: (acna: string) => Promise<void>;
  loadAcnaFilterData: () => Promise<void>;

  setAvailabilityReportData: (availabilityReportData: AvailabilityReportModel[]) => void;
  setMemberAvailabilityReportData: (memberAvailabilityReportData: MembersAvailabilityReportModel[]) => void;
  setYearReportFilterData: (yearReportFilterData: []) => void;
}

class CoinReports implements ICoinReports {
  availabilityReportData?: AvailabilityReportModel[];
  memberAvailabilityReportData?: MembersAvailabilityReportModel[];
  yearReportFilter?: number[];
  acnaFilter?: string[];

  constructor() {
    makeAutoObservable(this);
  }

  loadAvailabilityReportData = async (): Promise<void> => {
    try {
      const response = await CoinReportsService.getAvailabilityReportData();
      if (response?.data) {
        this.setAvailabilityReportData(response.data.availability);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  loadMemberAvailabilityReportData = async ({year, acna}: {year: number, acna: string}): Promise<void> => {
    try {
      const response = await CoinReportsService.getMembersAvailabilityReportData({year, acna});
      if (response?.data) {
        this.setMemberAvailabilityReportData(response.data.availability);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  loadYearReportFilterData = async (acna: string): Promise<void> => {
    try {
      const response = await CoinReportsService.getYearReportFilter(acna);
      if (response?.data) {
        this.setYearReportFilterData(response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  loadAcnaFilterData = async (): Promise<void> => {
    try {
      const response = await CoinReportsService.getAcnaFilter();
      if (response?.data) {
        this.seAcnaFilterData(response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  setAvailabilityReportData = (availabilityReportData: AvailabilityReportModel[]): void => {
    this.availabilityReportData = availabilityReportData;
  };

  setMemberAvailabilityReportData = (memberAvailabilityReportData: MembersAvailabilityReportModel[]): void => {
    this.memberAvailabilityReportData = memberAvailabilityReportData;
  };

  setYearReportFilterData = (yearReportFilterData: number[]): void => {
    this.yearReportFilter = yearReportFilterData;
  };

  seAcnaFilterData = (acnaFilterData: string[]): void => {
    this.acnaFilter = acnaFilterData;
  };
}

const coinReports = new CoinReports();

export default coinReports;
