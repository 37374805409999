import React from "react";
import NotificationHub from "../../../../../../containers/notification&alerts/notification-hub";
import { useParams } from "react-router-dom";

export const Events: React.FC = () => {
  const { deviceName } = useParams();

  return <NotificationHub referenceId={deviceName} />;
};

export default Events;
