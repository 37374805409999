import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Variants, TNSButton, TNSTable, TNSDivider, TNSModal } from "@tns/ui-components";
import { TRANSLATION } from "../../../utils/const/translation";
import { Col, Row } from "react-bootstrap";
import { DeviceGroupingContext } from "../../grouping/context/DeviceGroupingContext";
import { groupsColumns } from "./groups-columns";
import { AllGroupsResponse, AssociatedDevicesInGroupResponse, DeviceGroup } from "../../../interfaces/devices/group/available-groups";
import { TranslationHelper } from "src/app/portal-app/helpers/shared/TranslationHelper";
import { ColumnsType } from "antd/lib/table";
import { store } from "../../../../../app/portal-app/store/StoreMobx";
import { observer } from "mobx-react";
import { useAsyncCall } from "src/hooks/useAsyncCallShared";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Roles } from "@tnso/roles-and-permissions";

export const AssociatedDevicesGroup: React.FC = observer((): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentGroupsPage, setCurrentGroupsPage] = useState(1);
  const [currentDevicesPage, setCurrentDevicesPage] = useState(1);
  const { groupsType } = useParams();
  const { getAssociatedDevices } = useContext(DeviceGroupingContext);
  const [groupNameSelected, setGroupNameSelected] = useState<string>();
  const [groupColorSelected, setGroupColorSelected] = useState<string>();
  const columns = groupsColumns();
  const [groupsResponse, setGroupsResponse] = useState<AllGroupsResponse | undefined>();
  const [associatedDevices, setAssociatedDevices] = useState<AssociatedDevicesInGroupResponse>();
  const [associatedLoading, setAssociatedLoading] = useState(false);
  const availableDeleteAndEdit = useMemo(() => {
    return (groupsType === "allGroups" && store.auth.userInfo?.role === Roles.Admin && groupNameSelected !== "TNS All devices") || groupsType === "myGroups";
  }, [groupsType, store.auth.userInfo?.role, groupNameSelected]);

  useAsyncCall(async () => {
    if (groupsType) {
      const params = store.group.builderGroupQueryParams({ currentPage: 0, recordsPerPage: 10 });
      const response = groupsType === "allGroups" ? await store.group.loadAllGroups(params) : await store.group.loadAllMyGroups(params);
      setCurrentGroupsPage(1);
      setCurrentDevicesPage(1);
      setGroupsResponse(response);
      setAssociatedDevices(undefined);
      setGroupNameSelected(undefined);
    }
  }, [groupsType]);

  const handleRow = useCallback(
    async (group: DeviceGroup) => {
      setAssociatedLoading(true);
      setAssociatedDevices(undefined);
      const params = store.group.builderGroupQueryParams({ currentPage: 0, recordsPerPage: 10 });
      const response = await getAssociatedDevices(group.name, params);
      setGroupNameSelected(group.name);
      setGroupColorSelected(group.color);
      setAssociatedDevices(response);
      setAssociatedLoading(false);
      setCurrentDevicesPage(1);
    },
    [getAssociatedDevices]
  );

  const handlePageAssociatedDevices = useCallback(
    async (page: number) => {
      if (groupNameSelected) {
        const params = store.group.builderGroupQueryParams({ currentPage: page - 1, recordsPerPage: 10 });
        const response = await getAssociatedDevices(groupNameSelected, params);
        setCurrentDevicesPage(page);
        setAssociatedDevices(response);
      }
    },
    [getAssociatedDevices, groupNameSelected]
  );

  const handlePageGroups = useCallback(
    async (page: number) => {
      const params = store.group.builderGroupQueryParams({ currentPage: page - 1, recordsPerPage: 10 });
      const response = groupsType === "allGroups" ? await store.group.loadAllGroups(params) : await store.group.loadAllMyGroups(params);
      setCurrentGroupsPage(page);
      setGroupsResponse(response);
    },
    [getAssociatedDevices, groupNameSelected, groupsType]
  );

  const handleDelete = async (): Promise<void> => {
    if (groupNameSelected) {
      await store.group.deleteGroup(groupNameSelected);
      setAssociatedDevices(undefined);
      const params = store.group.builderGroupQueryParams({ currentPage: 0, recordsPerPage: 10 });
      const response = groupsType === "allGroups" ? await store.group.loadAllGroups(params) : await store.group.loadAllMyGroups(params);
      setCurrentDevicesPage(1);
      setCurrentGroupsPage(1);
      setGroupsResponse(response);
      setShowModal(false);
      setGroupNameSelected(undefined);
    }
  };

  const handleEditGroup = useCallback((): void => {
    if (groupNameSelected && groupColorSelected) {
      navigate(`/monitoring/devices/device-grouping/edit/${encodeURIComponent(groupNameSelected)}/${encodeURIComponent(groupColorSelected)}`);
    }
  }, [navigate, groupNameSelected, groupColorSelected]);

  return (
    <div className="mt-4">
      <div className="d-flex gap-3 justify-content-end align-items-center">
        <TNSButton buttonVariant={Variants.Secondary} disabled={!groupNameSelected || !availableDeleteAndEdit} onClick={(): void => setShowModal(true)}>
          {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.DETAILS.deleteGroup)}
        </TNSButton>
        <TNSButton buttonVariant={Variants.Primary} disabled={!groupNameSelected || !availableDeleteAndEdit} onClick={handleEditGroup}>
          {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.DETAILS.editGroup)}
        </TNSButton>
      </div>
      <TNSDivider type="horizontal" />
      <Row>
        <Col md={5}>
          <TNSTable
            whitoutCard
            currentPage={currentGroupsPage}
            emptyMessage={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.DETAILS.noDataAvailableAtThisTime)}
            totalRecords={groupsResponse?.totalRecords}
            showPagination={groupsResponse?.totalRecords && groupsResponse.totalRecords > 0 ? true : false}
            dataSource={groupsResponse?.groups ?? []}
            columns={
              store.group.loading
                ? TranslationHelper.columnsTranslation(columns.skeletonColumnsGroups as unknown as ColumnsType[])
                : TranslationHelper.columnsTranslation(columns.groups as unknown as ColumnsType[])
            }
            handleSelectRow={handleRow}
            handleGoToPage={handlePageGroups}
            recordsTranslated={[t("records"), t("of")]}
            isLoading={store.group.loading}
            scroll={{ x: "max-content" }}
          />
        </Col>
        <Col md={1} />
        <Col md={5}>
          {groupNameSelected ? (
            <div>
              <p>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.DETAILS.associatedDevices)}</p>
              <TNSTable
                whitoutCard
                currentPage={currentDevicesPage}
                emptyMessage={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.DETAILS.noDataAvailableAtThisTime)}
                showPagination={associatedDevices?.totalRecords && associatedDevices.totalRecords > 0 ? true : false}
                loading={associatedLoading}
                dataSource={associatedDevices?.devices?.map((device) => ({ deviceName: device })) ?? []}
                totalRecords={associatedDevices?.totalRecords}
                columns={TranslationHelper.columnsTranslation(columns.associated as unknown as ColumnsType[])}
                handleGoToPage={handlePageAssociatedDevices}
                recordsTranslated={[t("records"), t("of")]}
                scroll={{ x: "max-content" }}
              />
            </div>
          ) : (
            <div className="text-center d-flex justify-content-center align-items-center h-100">
              <p>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.DETAILS.pleaseSelectedGroupToViewTheAssociatedDevices)}</p>
            </div>
          )}
        </Col>
      </Row>
      <TNSModal
        closable={false}
        open={showModal}
        title={
          <>
            <FontAwesomeIcon icon={faCircleExclamation} color="#F8E00D" /> {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.DETAILS.areYouSureYouWantToDelete)}{" "}
            {groupNameSelected}?
          </>
        }
        textCancelButton={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.DETAILS.no)}
        textOkButton={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.DETAILS.yes)}
        handleAccept={handleDelete}
        handleCancel={(): void => setShowModal(false)}
      />
    </div>
  );
});
