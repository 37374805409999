import React, { useCallback, useState } from "react";
import { TNSDivider, TNSTable } from "@tns/ui-components";
import { IInterfaceMetrics } from "src/app/monitoring/models/devices/dhi/dhi";
import { useInterfaceTableConfig } from "../../../../context/use-interface-table-config";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "src/app/monitoring/utils/const/translation";
import { TranslationHelper } from "src/shared/helpers/TranslationHelper";
import _ from "lodash";
import { ExportXLSLHelper, TypeFile } from "src/shared/helpers/ExportXLSLHelper";
import { utils } from "xlsx";
import { useParams } from "react-router-dom";
import { MapperHelper } from "../../../../../../shared/helpers/MapperHelper";
import { paginationSizeChangerContext } from "../../../../../../contexts/paginationSizeChanger/paginationSizeChangerContext";
import { AuthService } from "src/app/portal-app/service/auth/AuthService";

export interface InterfaceMetricsTableProps {
  interfaceMetrics?: IInterfaceMetrics[];
  getInterfaceMetrics: (tnsDeviceName: string) => Promise<IInterfaceMetrics[] | undefined>;
}

export const InterfaceMetricsTable: React.FC<InterfaceMetricsTableProps> = ({ interfaceMetrics, getInterfaceMetrics }) => {
  const { t } = useTranslation();
  const { deviceName } = useParams();
  const totalRecords = interfaceMetrics ? interfaceMetrics.length : 0;
  const { interfaceMetricsCloumns } = useInterfaceTableConfig();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [interfaceMetricsData, setInterfaceMetricsData] = useState(interfaceMetrics?.slice(0, 10) ?? []);
  const [page, setPage] = useState(1);
  const { paginationSize, paginationPageSizeOptions, setPaginationSize } = paginationSizeChangerContext();

  const handleGoToPage = useCallback(
    async (page: number): Promise<void> => {
      setPage(page);
      if (interfaceMetrics) {
        setInterfaceMetricsData(interfaceMetrics.slice((page - 1) * paginationSize, page * paginationSize));
      }
    },
    [interfaceMetrics, paginationSize]
  );

  const handleExportData = useCallback(async () => {
    if (deviceName) {
      setIsLoadingExport(true);
      const interfaceMetricsByExport = await getInterfaceMetrics(deviceName);
      if (interfaceMetricsByExport && !_.isEmpty(interfaceMetricsByExport)) {
        const wb = utils.book_new();
        ExportXLSLHelper.addSheetToBook(wb, MapperHelper.mapToExportInterfaceMetrics(interfaceMetricsByExport), t("interfaceMetrics"));
        ExportXLSLHelper.exportToXLSL(wb, `${deviceName}_${t("interfaceMetrics")}`, TypeFile.CSV);
        setIsLoadingExport(false);
      }
    }
  }, [deviceName, getInterfaceMetrics, t]);

  const handlePaginationSizeChange = useCallback(
    async (size: number): Promise<void> => {
      setPaginationSize(size);
      setPage(1);
      if (interfaceMetrics) {
        setInterfaceMetricsData(interfaceMetrics.slice((page - 1) * size, page * size));
      }
      await AuthService.setUserPreferences({ PREFERED_PAGE_SIZE: size });
    },
    [interfaceMetrics, page, setPaginationSize]
  );

  return (
    <div className="mt-3">
      <TNSDivider type="horizontal" orientation="left">
        <h5>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.interfaceMetrics)}</h5>
      </TNSDivider>
      <TNSTable
        dataSource={interfaceMetricsData}
        currentPage={page}
        whitoutCard
        showPagination
        totalRecords={totalRecords}
        columns={TranslationHelper.columnsTranslation(interfaceMetricsCloumns)}
        handleGoToPage={handleGoToPage}
        onExport={handleExportData}
        exportLabel={t("export")}
        isLoadingExport={isLoadingExport}
        recordsTranslated={[t("records"), t("of")]}
        emptyMessage={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.noDataAvailableAtThisTime)}
        showPaginationSizeChanger
        paginationPageSizeTextTranslated={t("resultsPerPage")}
        pageSize={paginationSize}
        paginationPageSizeOptions={paginationPageSizeOptions}
        onShowPaginationSizeChange={handlePaginationSizeChange}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};
