import React, { useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { CPUCoresData } from "../../../../models/devices/hardware/hardware";
import { Variants, TNSButton, TNSCard, Themes } from "@tns/ui-components";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../utils/const/translation";
import { HardwareHelper } from "../../../../helpers/dhi/hardware-helper";
import { MapperHelper } from "../../../../../../shared/helpers/MapperHelper";
import { utils } from "xlsx";
import { ExportXLSLHelper, TypeFile } from "../../../../../portal-app/helpers/shared/ExportXLSLHelper";
import { LegendOptions } from "../../../graphs/legend-options";
import { COLORS } from "../../../../../monitoring/utils/const/colors";
import { Col, Row } from "antd";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomizerContext } from "../../../../../../contexts/customizer/CustomizerContext";

interface CPUUsageGraphProps {
  data?: CPUCoresData;
  isMonthly?: boolean;
  isDaily?: boolean;
}

export const CPUUsageGraph: React.FC<CPUUsageGraphProps> = ({ data, isMonthly, isDaily }): JSX.Element => {
  const { t } = useTranslation();
  const customizer = useContext(CustomizerContext);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const cpuData = data ?? {};
  const datesMapped = Object.values(cpuData)[0];
  const seriesData = Object.values(cpuData).map((core, index) => ({
    name: Object.keys(cpuData)[index],
    color: HardwareHelper.coresColors[index],
    data: core.map((each) => each.value)
  }));

  const exportData = (): void => {
    setIsLoadingExport(true);
    setTimeout(() => {
      const dataMapped = MapperHelper.mapCPUUsageToExport(cpuData, isMonthly, isDaily);
      const wb = utils.book_new();
      ExportXLSLHelper.addSheetToBook(wb, dataMapped, 1);
      ExportXLSLHelper.exportToXLSL(wb, t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.cpuUsed), TypeFile.CSV);
      setIsLoadingExport(false);
    }, 1500);
  };

  return (
    <TNSCard>
      <div className="mb-3 mt-2">
        <span className="p-2">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.cpuUsed)}</span>
      </div>
      <div>
        <ReactApexChart
          type={seriesData.length > 1 ? "line" : "area"}
          options={{
            tooltip: {
              theme: customizer.selectedTheme == Themes.DARK ? "dark" : "light"
            },
            chart: {
              type: seriesData.length > 1 ? "line" : "area",
              foreColor: "white",
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false
              }
            },
            annotations: {
              yaxis: [
                {
                  y: 90,
                  borderColor: "#FF2D2E",
                  strokeDashArray: 5,
                  yAxisIndex: 0,
                  offsetX: 0,
                  offsetY: 0,
                  borderWidth: 2
                },
                {
                  y: 75,
                  borderColor: "#EB9646",
                  strokeDashArray: 5,
                  yAxisIndex: 0,
                  offsetX: 0,
                  offsetY: 0,
                  borderWidth: 2
                }
              ]
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              labels: {
                formatter: (value): string => HardwareHelper.formatXAxisLabel(value, datesMapped || [], isMonthly, isDaily)
              },
              tooltip: {
                enabled: false
              }
            },
            yaxis: {
              labels: {
                formatter: HardwareHelper.cpuFormatYAxisLabel
              },
              tickAmount: 5,
              min: 0,
              max: 100
            },
            fill: {
              type: seriesData.length <= 1 ? "gradient" : "solid",
              gradient: {
                shade: "dark",
                type: "vertical",
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.1,
                stops: [0, 100],
                colorStops: [
                  {
                    offset: 0,
                    color: "#1D4696",
                    opacity: 0.9
                  },
                  {
                    offset: 100,
                    color: "#1D4696",
                    opacity: 0
                  }
                ]
              }
            },
            stroke: {
              curve: "straight",
              width: 3,
              colors: ["#ffffff"]
            }
          }}
          series={seriesData}
          height={400}
          padding={40}
          width="100%"
        />
        <div className="d-flex gap-4 w-100 justify-content-center">
          <LegendOptions color={COLORS.BARS.RED} label={`${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.critical)}: > 90%`} height="10px" width="40px" />
          <LegendOptions color={COLORS.BARS.YELLOW} label={`${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.warning)}: > 75%`} height="10px" width="40px" />
        </div>
      </div>
      <div className="mt-2">
        <Row justify="space-between" align="middle" style={{ marginTop: "1rem" }}>
          <Col>
            <TNSButton buttonVariant={Variants.Secondary} loading={isLoadingExport} onClick={exportData} disabled={isLoadingExport}>
              <div className="d-flex align-items-center gap-2 justify-content-center">
                <FontAwesomeIcon icon={faDownload} /> {t("exportButton")}
              </div>
            </TNSButton>
          </Col>
        </Row>
      </div>
    </TNSCard>
  );
};
