import { QueryParams } from "../../shared/queryParams";

export interface ContactParams extends QueryParams {
  tnsDeviceName?: string;
  entityProfile?: string;
}
export interface ContactInterface {
  name: string;
  email: string;
  telephoneNumber: string;
  mobileNumber: string;
  criteria: ContactCriteria;
}

export enum ContactCriteria {
  OnlyInThisDevice = "device",
  Address = "address",
  City = "city",
  Country = "country"
}
