import { makeAutoObservable } from "mobx";
import { DeviceAddress, DeviceDetail, DeviceGroups } from "../../../models/devices/devices";
import { DeviceService } from "../../../service/device/device-service";
import i18n from "../../../../../translations/i18n";
import { TRANSLATION } from "../../../utils/const/translation";
import { StatusCode } from "@tnso/api-core";
import { MessageHelper } from "../../../../../shared/helpers/MessageHelper";

export interface IDetail {
  data?: DeviceDetail;
  address?: DeviceAddress;
  groups?: DeviceGroups[];
  loadData: (tnsDeviceName?: string) => Promise<void>;
  loadGroups: (tnsDeviceName?: string) => Promise<DeviceGroups[] | undefined>;
  editGroups: (groups: string[], tnsDeviceName?: string) => Promise<void>;
}

class Detail implements IDetail {
  data?: DeviceDetail = undefined;
  address?: DeviceAddress = undefined;
  groups?: DeviceGroups[] = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  loadData = async (tnsDeviceName?: string): Promise<void> => {
    try {
      if (tnsDeviceName) {
        const response = await DeviceService.getDetail(tnsDeviceName);
        if (response?.status === StatusCode.OK && response?.data) {
          this.data = response.data;
          this.address = {
            city: response.data.city,
            countryCode: response.data.country,
            state: response.data.state,
            street1: response.data.streetAddress,
            street2: response.data.streetAddress2,
            latitude: response.data.latitude,
            longitude: response.data.longitude,
            zipCode: response.data.zipCode
          };
        }
      }
    } catch (error) {
      MessageHelper.errorMessage("Error loading device detail");
    }
  };

  loadGroups = async (tnsDeviceName?: string): Promise<DeviceGroups[] | undefined> => {
    try {
      if (tnsDeviceName) {
        const response = await DeviceService.getGroups(tnsDeviceName);
        if (response?.status === StatusCode.OK && response?.data) {
          this.groups = response.data;
        }
      }
      return this.groups;
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TAGS.ERROR.errorLoadingGroupsForThisDevice));
    }
  };

  editGroups = async (groups: string[], tnsDeviceName?: string): Promise<void> => {
    try {
      if (tnsDeviceName) {
        const response = await DeviceService.updateGroups(groups, tnsDeviceName);
        if (response?.status === StatusCode.NO_CONTENT) {
          MessageHelper.successMessage(i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TAGS.SUCCESS.changesSavedSuccesfully));
        }
      }
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TAGS.ERROR.errorLoadingGroupsForThisDevice));
    }
  };
}

const detail = new Detail();

export default detail;
