import React, { useState } from "react";
import {
  DeviceGroupingFiltersContextProviderProps,
  DeviceGroupingFiltersContextProps,
  DeviceGroupingFiltersType,
  FilterNameType
} from "../../../interfaces/devices/filters/filters";

export const initialValues = {
  [FilterNameType.EntityProfileName]: "",
  [FilterNameType.BoardingStatus]: "",
  [FilterNameType.IsWireless]: "",
  [FilterNameType.ServiceDescription]: "",
  [FilterNameType.Manufacturer]: "",
  [FilterNameType.Model]: "",
  [FilterNameType.Countries]: "",
  [FilterNameType.State]: "",
  [FilterNameType.City]: "",
  [FilterNameType.ZipCode]: "",
  [FilterNameType.EntityProfile]: ""
};

export const initialValueContext = {
  filters: initialValues,
  setFilters: (): void => {
    return;
  },
  disabled: false,
  setDisabled: (): void => {
    return;
  }
};

export const DeviceGroupingFiltersContext = React.createContext<DeviceGroupingFiltersContextProps>(initialValueContext);

const DeviceGroupingFiltersContextProvider: React.FC<DeviceGroupingFiltersContextProviderProps> = ({ children }) => {
  const [filters, setFilters] = useState<DeviceGroupingFiltersType>(initialValues);
  const [disabled, setDisabled] = useState<boolean>(false);
  return (
    <DeviceGroupingFiltersContext.Provider
      value={{
        filters,
        setFilters,
        disabled,
        setDisabled
      }}>
      {children}
    </DeviceGroupingFiltersContext.Provider>
  );
};

export default DeviceGroupingFiltersContextProvider;
