import React from "react";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TNSButton, Variants } from "@tns/ui-components";
import { TNSOActionButton } from "@tnso/ui-components";
import { useMemo } from "react";

export interface Props {
  editConnectedDevices: () => void;
}

export const ConnectedActionButton: React.FC<Props> = ({ editConnectedDevices }) => {
  const actionButtons = useMemo(
    () => [
      {
        icon: (
          <TNSButton buttonVariant={Variants.Link} onClick={editConnectedDevices}>
            <FontAwesomeIcon icon={faPencil} />
          </TNSButton>
        )
      }
    ],
    [editConnectedDevices]
  );

  return <TNSOActionButton actionsButtons={actionButtons} />;
};
