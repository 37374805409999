import { ApiService, IResponse } from "@tnso/api-core";
import { ContactInterface } from "../../../models/devices/note/contact";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class ContactInfoService extends ApiService {
  static async getAll(tnsDeviceName: string): Promise<IResponse<ContactInterface> | undefined> {
    return this.get<ContactInterface>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/contact`);
  }
  static async update(contact: ContactInterface, tnsDeviceName: string): Promise<IResponse<ContactInterface> | undefined> {
    return this.patch<ContactInterface>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/contact`, contact);
  }
}
