import {
  AvailableDevicesResponseByFilter,
  DeviceProfileInterface,
  DeviceProfileParams,
  DeviceProfileParamsToUpdate,
  FilterType,
  ProfileName,
  ProfileNameAvailable,
  ProfilesToEdit
} from "../models/device-profile.model";
import { encodeParams } from "../helpers/api/RequestHelper";
import { IResponse } from "@tnso/api-core";
import { BaseService } from "../../portal-app/service/BaseService";

export class DeviceProfileService extends BaseService {
  static async getAll(): Promise<IResponse<DeviceProfileInterface[]> | undefined> {
    return this.get<DeviceProfileInterface[]>("entityprofiles");
  }

  static async isAvailable(profileName: string): Promise<IResponse<ProfileNameAvailable> | undefined> {
    return this.get<ProfileNameAvailable>(`entityprofiles/name/${encodeURIComponent(profileName)}/available`);
  }

  static async getEntityNames(): Promise<IResponse<string[]> | undefined> {
    return this.get<string[]>("entityprofiles/names");
  }

  static async getAvailableDevices(profileName: string, params?: DeviceProfileParams): Promise<IResponse<AvailableDevicesResponseByFilter> | undefined> {
    const urlSearchParams = encodeParams<DeviceProfileParams>(params as DeviceProfileParams);
    return this.get<AvailableDevicesResponseByFilter>(`devices/entityprofiles/${encodeURIComponent(profileName)}/available`, urlSearchParams);
  }

  static async getAvailableDevicesByFilter(
    profileName: string,
    filter: FilterType,
    params?: DeviceProfileParams
  ): Promise<IResponse<AvailableDevicesResponseByFilter> | undefined> {
    const urlSearchParams = encodeParams<DeviceProfileParams>(params as DeviceProfileParams);
    return this.get<AvailableDevicesResponseByFilter>(`devices/${filter}/entityprofiles/${encodeURIComponent(profileName)}/available`, urlSearchParams);
  }

  static async getDevicesByProfileName(profileName: string, params?: DeviceProfileParams): Promise<IResponse<AvailableDevicesResponseByFilter> | undefined> {
    const urlSearchParams = encodeParams<DeviceProfileParams>(params as DeviceProfileParams);
    return this.get<AvailableDevicesResponseByFilter>(`devices/entityprofiles/${encodeURIComponent(profileName)}/associated`, urlSearchParams);
  }
  static async editDeviceProfile(oldName: string, profile: ProfileName, devices?: DeviceProfileParamsToUpdate): Promise<IResponse | undefined> {
    if (devices) {
      return this.patch<ProfilesToEdit>(`entityprofiles/${encodeURIComponent(oldName)}`, { name: profile.name, ...devices });
    } else {
      return this.patch<ProfilesToEdit>(`entityprofiles/${encodeURIComponent(oldName)}`, { name: profile.name });
    }
  }

  static async createProfile(profileName: ProfileName): Promise<IResponse | undefined> {
    return this.post<ProfileName>("entityprofiles", profileName);
  }

  static async deleteProfile(profileName: string): Promise<IResponse | undefined> {
    return this.remove(`entityprofiles/${encodeURIComponent(profileName)}`);
  }
}
