import React, { useCallback } from "react";
import MembersConnectivity from "../../../members/tabs/MembersConnections";
import { store } from "../../../../../../store/StoreMobx";
import { observer } from "mobx-react";
import { useAsyncCall } from "../../../../../../../../../libraries/shared/src/lib/hooks/use-async-call-shared";
import { useParams } from "react-router-dom";

export const Members: React.FC = observer(() => {
  const { detail } = store.device;
  const { deviceName } = useParams();

  const loadMethod = useCallback(async () => {
    if (detail.data?.acna) {
      await store.member.loadData(detail.data?.acna);
      await store.member.loadMembersGraph(detail.data?.acna);
      await store.device.detail.loadData(deviceName);
    }
  }, [detail.data?.acna]);

  useAsyncCall(loadMethod, [loadMethod], store.shared.getTimeToAutoRefresh());

  return <MembersConnectivity isTabMembers={true} />;
});
