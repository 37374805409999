export const TRANSLATION = {
  SWAP: {
    VIEW: {
      device: "device",
      swap: "swap",
      swapDevices: "swapDevices",
      noCompatibleDeviceIsAvailable: "noCompatibleDeviceIsAvailable",
      areYouSureYouWantToStartSwappingProcess: "areYouSureYouWantToStartSwappingProcess",
      youAreAboutToExchangeDevice: "youAreAboutToExchangeDevice",
      for: "for",
      ifYouAgreeClickYesStartSwapButtonOtherwiseClickCancel: "ifYouAgreeClickYesStartSwapButtonOtherwiseClickCancel",
      cancel: "cancel",
      yesStartSwap: "yesStartSwap",
      theSwapProcessHasBegun: "theSwapProcessHasBegun",
      somethingWentWrong: "somethingWentWrong",
      searchDevice: "searchDevice",
      replaceAnActiveDeviceWithACompatibleAlternative: "replaceAnActiveDeviceWithACompatibleAlternative"
    },
    DEVICEDETAILS: {
      general: "general",
      tnsDeviceName: "tnsDeviceName",
      customerDeviceName: "customerDeviceName",
      moreInfo: "moreInfo",
      acna: "acna",
      company: "company",
      companyName: "companyName",
      manufacturer: "manufacturer",
      model: "model",
      operationalStatus: "operationalStatus",
      connectivityStatus: "connectivityStatus",
      latitude: "latitude",
      longitude: "longitude",
      streetAddress: "streetAddress",
      city: "city",
      state: "state",
      zipCode: "zipCode",
      country: "country",
      statusLastChangedOn: "statusLastChangedOn",
      vendor: "vendor",
      deviceLocationStreet: "deviceLocationStreet",
      deviceLocationState: "deviceLocationState",
      deviceLocationCountry: "deviceLocationCountry",
      siteAddress: "siteAddress",
      changeAddress: "changeAddress",
      tnsServiceStatus: "tnsServiceStatus",
      deviceType: "deviceType",
      details: "details"
    },
    HISTORY: {
      historyOfDeviceSwaps: "historyOfDeviceSwaps",
      noDataAvailableAtThisTime: "noDataAvailableAtThisTime"
    },
    ERROR: {
      reasons: "reasons"
    },
    TABLE: {
      date: "date",
      username: "username",
      device1: "device1",
      device2: "device2",
      customerDeviceName1: "customerDeviceName1",
      customerDeviceName2: "customerDeviceName2",
      status: "status",
      action: "action"
    },
    MESSAGE: {
      theSwapHasBeenCompletedSuccessfullyTheDeviceConnectivityStatusMayTakeSomeToUpdate: "theSwapHasBeenCompletedSuccessfullyTheDeviceConnectivityStatusMayTakeSomeToUpdate",
      thisFunctionalityOnlyWorksForVAmodels: "thisFunctionalityOnlyWorksForVAmodels"
    }
  }
};
