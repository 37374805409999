import React, { useCallback, useEffect, useState } from "react";
import { Variants, TNSButton, TNSInput, TNSDivider } from "@tns/ui-components";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "src/app/monitoring/utils/const/translation";
import { ConnectedDevice, ConnectedDevicesParams, ConnectedDevicesResponse } from "../../../../models/connected-devices/connected-devices";
import { Controller, useForm } from "react-hook-form";
import { store } from "src/app/monitoring/store/store";
import { useParams } from "react-router-dom";

export interface DrawerContentProps {
  connectedDeviceName: string;
  macAddress: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setData: (data: ConnectedDevicesResponse | undefined) => void;
  params?: ConnectedDevicesParams;
}

export const DrawerContent: React.FC<DrawerContentProps> = ({ connectedDeviceName, macAddress, setOpen, setData, params }) => {
  const { t } = useTranslation();
  const { deviceName } = useParams();
  const [loading, setLoading] = useState(false);
  const { device } = store;
  const regex = /^[a-zA-Z0-9 _-]*$/;
  const regexToValidateOnlySpace = /\S/;

  const {
    control,
    getValues,
    reset,
    formState: { errors, isValid }
  } = useForm<ConnectedDevice>({
    mode: "all"
  });

  useEffect(() => {
    reset({ connectedDeviceName });
  }, [connectedDeviceName]);

  const cancelEdit = useCallback((): void => {
    setOpen(false);
    reset();
  }, [setOpen, reset]);

  const submitName = useCallback(async (): Promise<void> => {
    const { connectedDeviceName } = getValues();
    if (macAddress && connectedDeviceName) {
      setLoading(true);
      const data = await device.connectedDevices.updateConnectedDeviceName(connectedDeviceName, macAddress, params, deviceName);
      setLoading(false);
      reset();
      setData(data);
    }
    setOpen(false);
  }, [getValues, reset, setData, deviceName, device, macAddress, setOpen, params]);

  return (
    <div>
      <div className="d-flex gap-2 align-items-center justify-content-between">
        <label htmlFor="connectedDeviceName">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.connectedDeviceName)}</label>
        <Controller
          name="connectedDeviceName"
          control={control}
          rules={{ required: true, minLength: 2, maxLength: 100, validate: (value) => regex.test(value), pattern: regexToValidateOnlySpace }}
          render={({ field }): JSX.Element => <TNSInput {...field} disabled={loading} />}
        />
      </div>
      <div>
        {errors.connectedDeviceName?.type === "minLength" && (
          <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.connectedDeviceNameLength)}</small>
        )}
        {errors.connectedDeviceName?.type === "maxLength" && (
          <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.connectedDeviceNameLength)}</small>
        )}
        {errors.connectedDeviceName?.type === "required" && (
          <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.connectedDeviceNameIsRequired)}</small>
        )}
        {errors.connectedDeviceName?.type === "validate" && (
          <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.charactersAllowedAreLettersNumbersSpaceSnakeCaseAndHyphen)}</small>
        )}
        {errors.connectedDeviceName?.type === "pattern" && (
          <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.connectedDeviceNameMustNotBeEmptyOrOnlySpaces)}</small>
        )}
      </div>
      <TNSDivider type="horizontal" />
      <div className="d-flex justify-content-end gap-2">
        <TNSButton buttonVariant={Variants.Secondary} onClick={cancelEdit} disabled={loading}>
          {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.cancel)}
        </TNSButton>
        <TNSButton buttonVariant={Variants.Primary} disabled={!isValid || loading} onClick={submitName} loading={loading}>
          {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.save)}
        </TNSButton>
      </div>
    </div>
  );
};
