import React from "react";
import i18n from "../../../../i18n";
import { QueryParams } from "@tnso/shared";
import { MessageHelper } from "../../../../shared/helpers/MessageHelper";
import {
  InlineResponse2009 as DestinationListsDataModel,
  InlineResponse20010 as DestinationListDataModel,
  InlineResponse20011 as DestinationsDataModel,
  ContentFilteringGETApi,
  ContentFilteringPOSTApi,
  ContentFilteringDELETEApi
} from "@tnso/api-sdk";
import { InstanceType, useApiController } from "../../../../shared/hooks/use-api-controller";

export interface IDestinationListsContextProps {
  children: React.ReactNode;
}

export interface IDestinationListsContext {
  getDestinationLists: () => Promise<DestinationListsDataModel[]>;
  createDestination: (data: string, id: number) => Promise<boolean>;
  getDestinationList: (id: number) => Promise<DestinationListDataModel>;
  getDestinations(id: number, params: QueryParams): Promise<DestinationsDataModel>;
  deleteDestination(destinationListId: number, destinationId: string): Promise<void>;
}

export const initialState: IDestinationListsContext = {
  getDestinationLists: (): Promise<DestinationListsDataModel[]> => Promise.resolve([]),
  createDestination: (): Promise<boolean> => Promise.resolve(false),
  getDestinationList: () => Promise.resolve({}),
  getDestinations: () => Promise.resolve({}),
  deleteDestination: () => Promise.resolve()
};

export const DestinationListsContext = React.createContext<IDestinationListsContext>(initialState);

const DestinationListsContextProvider: React.FC<IDestinationListsContextProps> = ({ children }): JSX.Element => {
  const apiGetController = useApiController(ContentFilteringGETApi, InstanceType.DefaultWithBase);
  const apiPostController = useApiController(ContentFilteringPOSTApi, InstanceType.DefaultWithBase);
  const apiDeleteController = useApiController(ContentFilteringDELETEApi, InstanceType.DefaultWithBase);

  const getDestinationLists = async (): Promise<DestinationListsDataModel[]> => {
    const defaultResponse: DestinationListsDataModel[] = [];
    try {
      const response = await apiGetController.contentfilteringDestinationlistsGet();
      return response?.data || defaultResponse;
    } catch (error) {
      MessageHelper.errorMessage(i18n.t("upsSomethingWentWrong"));
      return defaultResponse;
    }
  };

  const getDestinationList = async (id: number): Promise<DestinationListDataModel> => {
    const defaultResponse = {};
    try {
      const response = await apiGetController.contentfilteringDestinationlistsDestinationListIdGet(id);
      return response.data || defaultResponse;
    } catch (error) {
      MessageHelper.errorMessage(i18n.t("upsSomethingWentWrong"));
      return defaultResponse;
    }
  };

  const getDestinations = async (destinationListId: number, params: QueryParams): Promise<DestinationsDataModel> => {
    const defaultResponse = {
      totalRecords: 0,
      returnedRecords: 0,
      destinations: []
    };
    try {
      const response = await apiGetController.contentfilteringDestinationlistsDestinationListIdDestinationsGet(destinationListId, params.recordsPerPage, params.startAtRecord);
      return response.data || defaultResponse;
    } catch (error) {
      MessageHelper.errorMessage(i18n.t("upsSomethingWentWrong"));
      return defaultResponse;
    }
  };

  const createDestination = async (destinationName: string, id: number): Promise<boolean> => {
    const data = { destinationName };
    try {
      await apiPostController.contentfilteringDestinationlistsDestinationListIdDestinationsPost(data, id);
      return true;
    } catch (error) {
      MessageHelper.errorMessage(i18n.t("invalidDestination"));
      return false;
    }
  };

  const deleteDestination = async (destinationListId: number, destinationId: string): Promise<void> => {
    try {
      await apiDeleteController.contentfilteringDestinationlistsDestinationListIdDestinationsDestinationIdDelete(destinationListId, destinationId);
      MessageHelper.successMessage(i18n.t("deletedDestination"));
    } catch (error) {
      MessageHelper.errorMessage(i18n.t("upsSomethingWentWrong"));
    }
  };

  return (
    <DestinationListsContext.Provider value={{ deleteDestination, getDestinations, getDestinationLists, createDestination, getDestinationList }}>
      {children}
    </DestinationListsContext.Provider>
  );
};

export default DestinationListsContextProvider;
