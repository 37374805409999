import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { COLORS } from "../../../../utils/const/colors";
import { observer } from "mobx-react";
import { Period } from "../../../../models/devices/cellular/cellularSignal";
import { DataSeries } from "../../../../models/devices/chart/chart";
import { TRANSLATION } from "../../../../utils/const/translation";
import { useTranslation } from "react-i18next";
import { DateHelper } from "@tnso/shared";
import { store } from "src/app/portal-app/store/StoreMobx";
import { CustomizerContext } from "../../../../../../contexts/customizer/CustomizerContext";
import { Themes } from "@tns/ui-components";

interface ChartsProps {
  series: DataSeries[];
  categories: string[];
  interval: string;
}

export const LineChart: React.FC<ChartsProps> = observer(({ series, categories, interval }) => {
  const customizer = useContext(CustomizerContext);
  const isDaily = interval === Period.Daily;

  const { t } = useTranslation();
  const labels = [
    t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.noSignal),
    t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.poor),
    t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.fair),
    t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.good),
    t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.excellent)
  ];

  return series.length > 0 ? (
    <div className="w-100 d-flex flex-column gap-0">
      <ReactApexChart
        type="line"
        options={{
          tooltip: {
            theme: customizer.selectedTheme == Themes.DARK ? "dark" : "light"
          },
          chart: {
            toolbar: {
              show: false
            },
            height: 350,
            animations: {
              enabled: false
            },
            background: customizer.selectedTheme === Themes.DARK ? "transparent" : "transparent"
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: "straight",
            width: 2.5
          },
          grid: {
            borderColor: customizer.selectedTheme === Themes.DARK ? COLORS.CHARTS.LINE.GRAY : COLORS.CHARTS.LINE.DARK_GRAY,
            xaxis: {
              lines: {
                show: true
              }
            },
            yaxis: {
              lines: {
                show: true
              }
            }
          },
          xaxis: {
            categories: categories.map((item): string => {
              const date = isDaily
                ? DateHelper.formatTimestampInHours(item, store.auth.userInfo?.timeZone)
                : DateHelper.convertTimestampToBrowserCulture(Number(item), store.auth.userInfo?.timeZone, { month: "2-digit", day: "2-digit", year: "numeric" });
              return date;
            })
          },
          yaxis: {
            min: 0,
            max: 4,
            tickAmount: 4,
            title: { text: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.signalStrength) },
            labels: {
              formatter: (_, index: number): string => {
                const label = labels[index];
                return label;
              },
              minWidth: 100
            }
          },
          colors: [COLORS.CHARTS.LINE.GREY],
          annotations: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            points: categories.map((item, index): any => {
              const date =
                interval === Period.Daily
                  ? DateHelper.formatTimestampInHours(item, store.auth.userInfo?.timeZone)
                  : DateHelper.convertTimestampToBrowserCulture(Number(item), store.auth.userInfo?.timeZone, { month: "2-digit", day: "2-digit", year: "numeric" });
              if (index <= series.length - 1) {
                return {
                  x: date,
                  y: series[index].data,
                  strokeDashArray: 0,
                  borderColor: COLORS.CHARTS.LINE[series[index].name.split(" ")[0] as keyof typeof COLORS.CHARTS.LINE],
                  label: {
                    borderColor: COLORS.CHARTS.LINE[series[index].name.split(" ")[0] as keyof typeof COLORS.CHARTS.LINE],
                    style: {
                      color: "#fff",
                      background: COLORS.CHARTS.LINE[series[index].name.split(" ")[0] as keyof typeof COLORS.CHARTS.LINE]
                    },
                    text: series[index].name
                  },
                  marker: {
                    size: 6,
                    fillColor: COLORS.CHARTS.LINE[series[index].name.split(" ")[0] as keyof typeof COLORS.CHARTS.LINE],
                    strokeColor: "#fff",
                    radius: 2
                  }
                };
              }
            })
          }
        }}
        series={[{ data: series.map((item) => Number(item.data)) }]}
        height={400}
        width="100%"
        data-testid="line-chart-component"
      />
      {isDaily && (
        <div
          className="d-flex justify-content-between align-items-center mb-3 py-2 date-legend-graph-line"
          style={{ color: "#fff", paddingLeft: "7vw", paddingRight: "1vw", marginTop: "-2.5vh" }}
          data-testid="line-chart-time">
          <small>{DateHelper.convertTimestampToBrowserCulture(Number(categories[0]), store.auth.userInfo?.timeZone, { month: "2-digit", day: "2-digit", year: "numeric" })}</small>
          <small>
            {DateHelper.convertTimestampToBrowserCulture(Number(categories[categories.length - 1]), store.auth.userInfo?.timeZone, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric"
            })}
          </small>
        </div>
      )}
    </div>
  ) : (
    <div className="d-flex justify-content-center align-items-center p-4 m-4" data-testid="error-message-linechart">
      <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.thereIsNoData)}</span>
    </div>
  );
});
