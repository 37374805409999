export const TRANSLATION = {
  ERROR: {
    profileNameAlreadyExists: "profileNameAlreadyExists",
    profileNameEmpty: "profileNameEmpty",
    firstNameRequired: "firstNameRequired",
    firstNameMinAndMaxLength: "firstNameMinAndMaxLength",
    firstNameAlphanumeric: "firstNameAlphanumeric",
    lastNameRequired: "lastNameRequired",
    lastNameMinAndMaxLength: "lastNameMinAndMaxLength",
    lastNameAlphanumeric: "lastNameAlphanumeric",
    emailRequired: "emailRequired",
    emailMinAndMaxLength: "emailMinAndMaxLength",
    emailRegex: "emailRegex",
    phoneRequired: "phoneRequired",
    phoneCharactersAllowed: "phoneCharactersAllowed",
    phoneMinAndMaxLength: "phoneMinAndMaxLength",
    contactNameEmpty: "contactNameEmpty",
    contactNameAlreadyExists: "contactNameAlreadyExists",
    noProfileSelected: "noProfileSelected",
    profileUpdateError: "profileUpdateError",
    profileCreateError: "profileCreateError",
    profileDeleteError: "profileDeleteError",
    errorLoadingUsers: "errorLoadingUsers"
  },
  SUCCESS: {
    addContactSuccess: "addContactSuccess",
    profileUpdateSuccess: "profileUpdateSuccess",
    contactDeleteSuccess: "contactDeleteSuccess",
    profileDeleteSuccess: "profileDeleteSuccess",
    profileCreateSuccess: "profileCreateSuccess"
  },
  CONTACT: {
    contactName: "contactName",
    companyProfileName: "companyProfileName",
    customerAccountManagerName: "customerAccountManagerName",
    emptyContactGrid: "emptyContactGrid",
    Maintenance: "Maintenance",
    Incident: "Incident",
    Escalation: "Escalation",
    contactDeleteConfirmation: "contactDeleteConfirmation",
    Billing: "Billing",
    Business: "Business",
    Facility: "Facility",
    Other: "Other",
    action: "action",
    type: "type",
    conactInformation: "conactInformation",
    firstName: "firstName",
    lastName: "lastName",
    email: "emailAddress",
    phone: "phone",
    contactInformation: "contactInformation",
    onBoardedDevices: "onBoardedDevices",
    nonBoardedDevices: "nonBoardedDevices",
    companyProfileAdministration: "companyProfileAdministration"
  },
  CAM: {
    deleteCAM: "deleteCAM"
  },
  PROFILE: {
    profileName: "profileName",
    profile: "profile",
    profiles: "profiles",
    entityProfiles: "entityProfiles",
    onBoardedDevices: "onBoardedDevices",
    nonBoardedDevices: "nonBoardedDevices",
    availableDevices: "availableDevices",
    selectedDevices: "selectedDevices",
    country: "country",
    state: "state",
    city: "city",
    acna: "acna",
    allDevices: "allDevices",
    profileDetails: "profileDetails",
    profileDevicesEmptyMessage: "profileDevicesEmptyMessage",
    profileNameInputPlaceholder: "profileNameInputPlaceholder",
    profileDeleteConfirmation: "profileDeleteConfirmation",
    profileSelectPlaceholder: "profileSelectPlaceholder",
    profileEntity: "profileEntity",
    infoNotAvailable: "infoNotAvailable",
    incidentManagement: "incidentManagement"
  },
  BUTTONS: {
    editProfileButton: "editProfileButton",
    addProfileButton: "addProfileButton",
    editContactButton: "editContactButton",
    addContactButton: "addContactButton",
    deleteProfileButton: "deleteProfileButton",
    cancelButton: "cancelButton",
    saveButton: "saveButton",
    contactDeleteButton: "contactDeleteButton",
    contactEditButton: "contactEditButton",
    contactModalAccept: "contactModalAccept",
    contactModalCancel: "contactModalCancel",
    exportButton: "exportButton",
    nextButton: "nextButton",
    backButton: "backButton"
  },
  MODAL: {
    loginSuccess: "loginSuccess",
    errorLoadingContacts: "errorLoadingContacts",
    contactCreatedSuccess: "contactCreatedSuccess",
    errorCreatingContact: "errorCreatingContact",
    contactUpdatedSuccess: "contactUpdatedSuccess",
    errorUpdatingContact: "errorUpdatingContact",
    contactDeletedSuccess: "contactDeletedSuccess",
    errorDeletingContact: "errorDeletingContact"
  },
  TABS: {
    contact: "contact",
    devices: "devices"
  }
};
