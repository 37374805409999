import React from "react";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Variants, TNSButton } from "@tns/ui-components";
import "./title-with-text-components.scss";

export interface TitleWithTextComponentProps {
  title: string;
  text?: string;
  titleColor?: string;
  editable?: boolean;
  textColor?: string;
  onEdit?: () => void;
  colSize?: string;
}

export const TitleWithTextComponent: React.FC<TitleWithTextComponentProps> = ({ title, text, titleColor, editable, onEdit, textColor, colSize }) => {
  return (
    <div className={`col-md-${colSize} d-flex flex-column align-items-start flex-shrink-0`}>
      <h6 style={{ color: titleColor ?? "#64B5F6", fontSize: "18px", fontFamily: "HelveticaNeueBold" }} className="bold w-100 text-truncate mb-1" title={title}>
        {title}
      </h6>
      {editable ? (
        <div className="d-flex w-100 align-items-center editable-text-container">
          <span className="text-truncate title-with-text-component" data-testid="customer-device-name" title={text}>
            {text}
          </span>
          <TNSButton buttonVariant={Variants.Link} onClick={onEdit} testId="edit-customer-device-name-button" className="ms-2">
            <FontAwesomeIcon icon={faPencilAlt} />
          </TNSButton>
        </div>
      ) : (
        <span style={{ color: textColor }} className="w-100 text-truncate title-with-text-component" data-testid="known-as" title={text}>
          {text}
        </span>
      )}
    </div>
  );
};
