import React, { ReactElement, useContext, useMemo } from "react";
import { TNSSelect } from "@tns/ui-components";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../../../../utils/const/translation";
import { TroubleshootingTools } from "../enums/TroubleshootingTools";
import { TroubleshootingContext, TroubleshootingFormValues } from "../../../../../../../components/device/troubleshooting/context/troubleshooting-context";
import { DefaultOptionType } from "antd/es/select";
import { Controller, useFormContext } from "react-hook-form";

export const ToolsFormSelect: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const name: keyof TroubleshootingFormValues = "tool";
  const { toolsFilterEnabled } = useContext(TroubleshootingContext);

  const options = useMemo((): DefaultOptionType[] => {
    return [
      {
        label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.dnsLookup),
        value: TroubleshootingTools.DNSLookup
      },
      {
        label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.ping),
        value: TroubleshootingTools.Ping
      },
      {
        label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.dhcpInformation),
        value: TroubleshootingTools.DHCPInformation
      },
      {
        label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.activeConnections),
        value: TroubleshootingTools.ActiveConnections
      },
      {
        label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.tcpCaptures),
        value: TroubleshootingTools.TCPCaptures
      },
      {
        label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.ipsecTunnelInformation),
        value: TroubleshootingTools.IPSecTunnelInformation
      },
      {
        label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.routingTableInformation),
        value: TroubleshootingTools.RoutingTableInformation
      }
    ];
  }, [t]);

  return (
    <div className="d-flex flex-column w-25">
      <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.tools)}</span>
      <div>
        <Controller
          name={name}
          control={control}
          render={({ field }): ReactElement => (
            <TNSSelect
              className="w-100"
              placeholder={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.select)}
              onSelect={(e): void => field.onChange(e)}
              options={options}
              value={field.value}
              disabled={!toolsFilterEnabled}
            />
          )}
        />
      </div>
    </div>
  );
};
