import React, { useEffect, useState } from "react";
import { InputType, TNSInput } from "@tns/ui-components";

interface InputFilterProps {
  key: string;
  onSearch: (value: string, key: string) => Promise<void>;
  defaultValue?: string;
  placeholder?: string;
  value: string;
}

const InputFilter: React.FC<InputFilterProps> = ({ value, onSearch, key, defaultValue, placeholder }) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    if (value !== selectedValue) {
      setSelectedValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <TNSInput
      onChange={(e): void => setSelectedValue(e.target.value)}
      placeholder={placeholder}
      type={InputType.Search}
      {...(selectedValue !== "" ? { value: selectedValue } : {})}
      defaultValue={defaultValue}
      onKeyDown={async (e): Promise<void> => {
        if (e.key === "Enter") {
          await onSearch(selectedValue, key);
        }
      }}
      onSearch={async (value: string): Promise<void> => {
        await onSearch(value, key);
      }}
      allowClear={true}
      value={selectedValue}
    />
  );
};

export default InputFilter;
