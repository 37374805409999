import { DateHelper } from "@tnso/shared";
import i18n from "../../../../i18n";
import { store } from "../../../portal-app/store/StoreMobx";
import { TRANSLATION } from "../../translations/translation";
import { InlineResponse20082 } from "@tnso/api-sdk";

export class MapperHelper {
  public static mapTickets(data?: InlineResponse20082): InlineResponse20082[] {
    const ticketsData = data?.incidentList;
    if (!ticketsData) {
      return [] as unknown as InlineResponse20082[];
    }

    const mappedData: InlineResponse20082[] = [];

    ticketsData.forEach((ticketsData) => {
      const dataToReturn = {
        [i18n.t(TRANSLATION.INCIDENTMANAGEMENT.ticketNumber)]: ticketsData.ticketNumber ? ticketsData.ticketNumber : "N/A",
        [i18n.t(TRANSLATION.INCIDENTMANAGEMENT.incidentStartTime)]:
          ticketsData.startDate && ticketsData.startDate > 0
            ? DateHelper.convertTimestampToBrowserCulture(ticketsData.startDate, store.auth.userInfo?.timeZone, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
              })
            : "N/A",
        [i18n.t(TRANSLATION.INCIDENTMANAGEMENT.description)]: ticketsData.description ? ticketsData.description : "N/A",
        ["ACNA"]: ticketsData.acna ? ticketsData.acna : "N/A",
        [i18n.t(TRANSLATION.INCIDENTMANAGEMENT.device)]: ticketsData.tnsDeviceName ? ticketsData.tnsDeviceName : "N/A",
        [i18n.t(TRANSLATION.INCIDENTMANAGEMENT.status)]: ticketsData.status ? ticketsData.status : "N/A",
        [i18n.t(TRANSLATION.INCIDENTMANAGEMENT.lastStatusUpdatedOn)]:
          ticketsData.lastStatusDate && ticketsData.lastStatusDate > 0
            ? DateHelper.convertTimestampToBrowserCulture(ticketsData.lastStatusDate, store.auth.userInfo?.timeZone, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
              })
            : "N/A"
      };

      mappedData.push(dataToReturn as unknown as InlineResponse20082);
    });

    return mappedData as unknown as InlineResponse20082[];
  }
}
