import React, { useCallback, useMemo } from "react";
import { Checkbox, Tree } from "antd";
import { FilterType } from "../../../interfaces/devices/group/group";
import { AssociatedDevicesInGroupResponse } from "src/app/portal-app/interfaces/devices/group/available-groups";
import { MapperHelper } from "../../../../../app/administration/helpers/shared/mapper-helper";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
const CheckboxGroup = Checkbox.Group;

export interface DeviceGroupItemListProps {
  selectedFilter: FilterType;
  setCheckedKeys: (value: React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] }) => void;
  deviceChecked?: CheckboxValueType[];
  devices?: string[];
  devicesResponse?: AssociatedDevicesInGroupResponse;
  setCheckedDevices: (value: CheckboxValueType[]) => void;
  checkedKeys:
    | React.Key[]
    | {
        checked: React.Key[];
        halfChecked: React.Key[];
      };
}

export const DeviceGroupItemList: React.FC<DeviceGroupItemListProps> = ({
  selectedFilter,
  setCheckedKeys,
  deviceChecked,
  devices,
  setCheckedDevices,
  devicesResponse,
  checkedKeys
}) => {
  const onCheck = useCallback((checkedKeys: React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] }): void => {
    setCheckedKeys(checkedKeys);
  }, []);

  const dataList = useMemo(() => {
    return selectedFilter === FilterType.AllDevices ? (
      <CheckboxGroup className="d-flex flex-column gap-3" value={deviceChecked} options={devices} onChange={(value): void => setCheckedDevices(value)} />
    ) : (
      devicesResponse && <Tree treeData={MapperHelper.mapTreeTransferList(devicesResponse, selectedFilter)} checkable onCheck={onCheck} checkedKeys={checkedKeys} />
    );
  }, [selectedFilter, devices, deviceChecked, devicesResponse, checkedKeys, onCheck, setCheckedDevices]);

  return dataList;
};
