import React from "react";
import { ISIMDetail } from "src/app/monitoring/models/devices/dhi/dhi";
import { SlotSimsCard } from "./slot-sims-card";

export interface SIMSCardsProps {
  sims: ISIMDetail[];
}
export const SIMSCards: React.FC<SIMSCardsProps> = ({ sims }) => {
  return (
    <div className="d-flex flex-column gap-2">
      {sims.map((sim, index) => (
        <SlotSimsCard slotNumber={index + 1} key={index} {...sim} />
      ))}
    </div>
  );
};
