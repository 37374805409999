import {
  AWS_COGNITO_IDENTITY_POOL_ID,
  AWS_COGNITO_MFA_CONFIGURATION,
  AWS_COGNITO_MFA_TYPES,
  AWS_COGNITO_PASSWORDPOLICYCHARACTERS,
  AWS_COGNITO_PASSWORDPOLICYMINLENGTH,
  AWS_COGNITO_REGION,
  AWS_COGNITO_SIGNUP_ATTRIBUTES,
  AWS_COGNITO_SOCIAL_PROVIDERS,
  AWS_COGNITO_USERNAME_ATTRIBUTES,
  AWS_COGNITO_VERIFICATION_MECHANISMS,
  AWS_PROJECT_REGION,
  CLOUDFRONT_DISTRIBUTION_DOMAIN_NAME,
  CLOUDFRONT_DISTRIBUTION_ID,
  S3_BUCKET_NAME,
  AWS_USER_POOLS_ID,
  AWS_USER_POOLS_WEB_CLIENT_ID
} from "./config/environments";

const amplifyConfig = {
  aws_cognito_identity_pool_id: AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: AWS_COGNITO_REGION,
  aws_project_region: AWS_PROJECT_REGION,
  aws_user_pools_id: AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: AWS_USER_POOLS_WEB_CLIENT_ID,
  cloudfront_distribution_domain_name: CLOUDFRONT_DISTRIBUTION_DOMAIN_NAME,
  cloudfront_distribution_id: CLOUDFRONT_DISTRIBUTION_ID,
  s3_bucket_name: S3_BUCKET_NAME,
  oauth: {},
  aws_cognito_username_attributes: AWS_COGNITO_USERNAME_ATTRIBUTES.split(","),
  aws_cognito_social_providers: AWS_COGNITO_SOCIAL_PROVIDERS.split(","),
  aws_cognito_signup_attributes: AWS_COGNITO_SIGNUP_ATTRIBUTES.split(","),
  aws_cognito_mfa_configuration: AWS_COGNITO_MFA_CONFIGURATION,
  aws_cognito_mfa_types: AWS_COGNITO_MFA_TYPES.split(","),
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: AWS_COGNITO_PASSWORDPOLICYMINLENGTH,
    passwordPolicyCharacters: AWS_COGNITO_PASSWORDPOLICYCHARACTERS.split(",")
  },
  aws_cognito_verification_mechanisms: AWS_COGNITO_VERIFICATION_MECHANISMS.split(",")
};

export default amplifyConfig;
