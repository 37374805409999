import React, { useCallback, useMemo } from "react";
import { CognitoResponse, SSOContextProps, ValidateToken, ValidateTokenResponse } from "src/app/portal-app/interfaces/auth/response/response";
import { AuthService } from "src/app/portal-app/service/auth/AuthService";
import { store } from "src/app/portal-app/store/StoreMobx";
import { fetchAuthSession } from "aws-amplify/auth";

export interface SSOContextProviderProps {
  children: React.ReactNode;
}

export const initialValue = {
  overflowCountOfNotifications: 9,
  // eslint-disable-next-line
  getValidations: (): Promise<ValidateTokenResponse | ValidateToken | undefined> => Promise.resolve(undefined),
  getCognitoCredentials: (): Promise<CognitoResponse | undefined> => Promise.resolve(undefined)
};

export const SSOContext = React.createContext<SSOContextProps>(initialValue);

const SSOContextProvider: React.FC<SSOContextProviderProps> = ({ children }) => {
  const { auth } = store;

  /*
   * This function is used to get the validations
   */
  const getValidations = useCallback(
    async (baseUrl: string): Promise<ValidateTokenResponse | ValidateToken | undefined> => {
      try {
        const response = await AuthService.validateToken(baseUrl);
        if (response?.accessToken && response?.refreshToken && !auth.isActAs) {
          localStorage.setItem("accessToken", response.accessToken);
          localStorage.setItem("refreshToken", response.refreshToken);
        }
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [auth.isActAs]
  );

  /**
   * This function is used to get the cognito credentials
   */
  const getCognitoCredentials = useCallback(async (): Promise<CognitoResponse | undefined> => {
    try {
      const cognitoUser = await fetchAuthSession({ forceRefresh: true });
      const cognitoAccessToken = cognitoUser.tokens?.accessToken.toString();
      const cognitoIdToken = cognitoUser.tokens?.idToken?.toString();

      if (cognitoAccessToken && cognitoIdToken) {
        return { cognitoAccessToken, cognitoIdToken };
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const contextValue = useMemo(
    () => <SSOContext.Provider value={{ getValidations, getCognitoCredentials }}>{children}</SSOContext.Provider>,
    [children, getCognitoCredentials, getValidations]
  );

  return contextValue;
};

export default SSOContextProvider;
