import { Key } from "react";
import { makeAutoObservable } from "mobx";
import { store } from "../store";
import { DeviceProfileParams } from "../../models/device-profile.model";

interface FilterTableState {
  id: string;
  value: string[];
}

export interface IFilter {
  activeFilters?: DeviceProfileParams;
  activeAvailableFilters?: DeviceProfileParams;
  isFilteredTable: boolean;
  selectedTableView: boolean;
  filteredDevicesTable?: FilterTableState[];

  setFilteredDevicesTable: (values: string[], id: string) => void;
  setIsSelectedTableView: () => void;
  setActiveFiltersData: (params: DeviceProfileParams) => void;
  setActiveAvailableFiltersData: (params: DeviceProfileParams) => void;
  setFilteredDevicesData: (devices?: string[], groups?: Key[]) => void;
  cleanFilteredDevicesTable: () => void;
  resetAllFilters: () => void;
}

export class Filter implements IFilter {
  activeFilters?: DeviceProfileParams;
  activeAvailableFilters?: DeviceProfileParams;
  isFilteredTable = false;
  selectedTableView = false;
  filteredDevicesTable?: FilterTableState[];

  constructor() {
    makeAutoObservable(this);
  }

  setFilteredDevicesTable = (values: string[], id: string): void => {
    store.deviceProfile.setCurrentPage(1);

    const newState = { id, value: values };
    const newFiltersState = (filteredDevicesTable: FilterTableState[]): FilterTableState[] => {
      const array = [...filteredDevicesTable].filter((filter) => !(filter.id === id));

      if (values[0] === "") {
        return [...filteredDevicesTable].filter((filter) => !(filter.id === id));
      } else {
        return values.length > 0 ? [...array, newState] : [...array];
      }
    };
    const filters = newFiltersState(this.filteredDevicesTable ?? []);
    this.filteredDevicesTable = filters;
  };

  setActiveFiltersData = (filters: DeviceProfileParams): void => {
    this.activeFilters = { ...this.activeFilters, ...filters };
  };
  setActiveAvailableFiltersData = (filters: DeviceProfileParams): void => {
    this.activeAvailableFilters = {
      ...this.activeAvailableFilters,
      ...filters
    };
  };

  setIsSelectedTableView = (): void => {
    this.selectedTableView = !this.selectedTableView;
  };

  setFilteredDevicesData = (devices?: string[]): void => {
    if (devices) {
      const filteredDevices = devices.filter((device) => device);
      store.deviceProfile.setDevices(filteredDevices && filteredDevices.length > 0 ? filteredDevices : devices);
    }
  };

  cleanFilteredDevicesTable = (): void => {
    this.filteredDevicesTable = [];
    this.isFilteredTable = !this.isFilteredTable;
    this.activeFilters = undefined;
    this.activeAvailableFilters = undefined;
  };

  resetAllFilters = (): void => {
    // reset page
    store.deviceProfile.setCurrentPage(1);

    // reset table filters
    this.filteredDevicesTable = [];
    this.isFilteredTable = !this.isFilteredTable;
    this.activeFilters = undefined;
    this.activeAvailableFilters = undefined;
    this.selectedTableView = false;

    // reset status
    localStorage.removeItem("queryParams");
  };
}

const filter = new Filter();

export default filter;
