import React, { useEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import { observer } from "mobx-react";
import { TRANSLATION } from "../../../utils/const/translation";
import { ContactInterface } from "../../../interfaces/devices/note/contact";
import { useTranslation } from "react-i18next";

interface ContactFormProps {
  contactData?: ContactInterface;
  setShowButton: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ContactInfo: React.FC<ContactFormProps> = observer(({ contactData, setShowButton }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setShowButton(true);
  }, [setShowButton]);

  const defaultValues = useMemo(() => {
    return {
      name: contactData?.name || "",
      telephoneNumber: contactData?.telephoneNumber || "",
      email: contactData?.email || "",
      mobileNumber: contactData?.mobileNumber || "",
      criteria: contactData?.criteria || ""
    };
  }, [contactData]);

  return contactData ? (
    <div className="d-flex flex-column gap-2 device-detail-container">
      <Row className="align-items-start mx-5">
        <Col md={6}>
          <Row className="align-items-start mt-2">
            <Col md={12} className="mt-2 d-flex justify-content-start align-items-start">
              <label>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.name)}</label>
              <div className="mx-3">
                <span>{defaultValues.name}</span>
              </div>
            </Col>
          </Row>
          <Row className="align-items-start mt-2">
            <Col md={12} className="mt-2 d-flex justify-content-start align-items-start">
              <label className="field-container">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.emailContact)}</label>
              <div className="mx-3">
                <span>{defaultValues.email}</span>
              </div>
            </Col>
          </Row>
          <Row className="align-items-start mt-2">
            <Col md={12} className="mt-2 d-flex justify-content-start align-items-start">
              <label className="field-container">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.telephone)}</label>
              <div className="mx-3">
                <span>{defaultValues.telephoneNumber}</span>
              </div>
            </Col>
          </Row>
          <Row className="align-items-start mt-2">
            <Col md={12} className="mt-2 d-flex justify-content-start align-items-start">
              <label className="field-container">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.mobilePhoneNumber)}</label>
              <div className="mx-3">
                <span>{defaultValues.mobileNumber}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  ) : null;
});

export default ContactInfo;
