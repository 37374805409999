import { TreeDataNode } from "antd";
import { ContactProfileExport, ContactProfilesResponse } from "../../models/device-contact.model";
import i18n from "../../../../i18n";
import { TRANSLATION } from "../../utils/translation-keys";
import { AvailableDevicesResponseByFilter, DevicesByFilter, FilterType } from "../../models/device-profile.model";
import { DataNode } from "antd/es/tree";
import { i18nInstance } from "../../../../i18n";

import { IUserAssociateByRoleResponse } from "../../models/user-role.model";
import { AssociatedDevicesInGroupResponse } from "src/app/portal-app/interfaces/devices/group/available-groups";
import { TranslationHelper } from "./translation-helper";
import { CamProfilesExport, CustomerAccountManagerResponse } from "../../models/customer-account-manager.model";

export class MapperHelper {
  public static mapContactProfile(data?: ContactProfilesResponse): ContactProfileExport[] {
    if (data) {
      const dataTranslated = TranslationHelper.dataTranslation(data.contacts, ["type"]);
      const dataMapped = dataTranslated.map((contact) => {
        return {
          [i18n.t(TRANSLATION.CONTACT.type)]: contact.type,
          [i18n.t(TRANSLATION.CONTACT.firstName)]: contact.firstName,
          [i18n.t(TRANSLATION.CONTACT.lastName)]: contact.lastName,
          [i18n.t(TRANSLATION.CONTACT.email)]: contact.email,
          [i18n.t(TRANSLATION.CONTACT.phone)]: contact.phone
        };
      });
      return dataMapped as unknown as ContactProfileExport[];
    } else {
      return [];
    }
  }

  public static mapCAMProfiles(data?: CustomerAccountManagerResponse): CamProfilesExport[] {
    if (data) {
      const dataMapped = data.camProfiles.map((cam) => {
        const companies = cam.companyProfiles.join(", ");
        return {
          [i18n.t(TRANSLATION.CONTACT.customerAccountManagerName)]: cam.name,
          [i18n.t(TRANSLATION.CONTACT.companyProfileName)]: companies
        };
      });
      return dataMapped;
    } else {
      return [];
    }
  }

  public static mapTreeTransferList(data: AvailableDevicesResponseByFilter | AssociatedDevicesInGroupResponse, filterType: FilterType): DataNode[] {
    if (data) {
      let dataToMap: DevicesByFilter[] | undefined;
      switch (filterType) {
        case FilterType.Acna:
          dataToMap = data.acnas;
          break;
        case FilterType.City:
          dataToMap = data.cities;
          break;
        case FilterType.Country:
          dataToMap = data.countries;
          break;
        case FilterType.State:
          dataToMap = data.states;
          break;
        default:
          break;
      }
      const dataMapped: TreeDataNode[] | undefined = dataToMap?.map((item, index) => {
        return {
          key: item.name && item.name !== "" ? item.name : ` ${i18n.t(TRANSLATION.PROFILE.infoNotAvailable)} ${index}`,
          title: item.name && item.name !== "" ? item.name : i18n.t(TRANSLATION.PROFILE.infoNotAvailable),
          children: item.devices.map((device) => {
            return {
              key: device,
              title: device
            };
          })
        };
      });
      return dataMapped as DataNode[];
    }
    return [];
  }

  public static mapUsersByRoles(data?: IUserAssociateByRoleResponse): Record<string, string>[] {
    if (data) {
      return data.users.map((user) => ({
        [i18nInstance.t("email")]: user.email
      }));
    }
    return [];
  }
}
