import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useMemo, useCallback, useState } from "react";
import { MemberSelected } from "../../../../../components/shared/table/memberProperties/MemberSelected";
import { useTranslation } from "react-i18next";
import membersConnectivity from "../../../../../store/memberConnectivity/MemberConnectivity";
import { store } from "../../../../../store/StoreMobx";
import { ConnectedGraph } from "../../../../../components/membersConnectivity/ConnectedGraph";
import { MembersGrid } from "../../../../../components/membersConnectivity/MembersGrid";
import { MemberHelper } from "../../../../../helpers/member/MemberHelper";
import { TRANSLATION } from "../../../../../utils/const/translation";
import { Variants, TNSButton } from "@tns/ui-components";
import { MemberExportService } from "../../../../../service/memberConnectivity/MemberExportService";
import { useExportDataTable } from "../../../../../../../hooks/useExportDataTable";
import { ExportMemberTable, MembersExportResponse } from "../../../../../interfaces/memberConnectivity/memberConnectivity";
import { MapperHelper } from "../../../../../helpers/shared/MapperHelper";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

interface Props {
  isTabMembers?: boolean;
}
const MembersConnectionsInternal: React.FC<Props> = ({ isTabMembers }) => {
  const { t } = useTranslation();
  const { member } = store;
  const [gridView, setGridView] = useState<boolean>(false);
  const [textButton, setTextButton] = useState<string>("tunnelDetailsView");
  const [previousSelectedItem, setPreviousSelectedItem] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const textError = useMemo(
    () => (membersConnectivity.selectedItem ? t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.noMemberConnectionsConfigured) : t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.noMemberSelected)),
    [membersConnectivity.selectedItem]
  );
  const thereIsData = useMemo(
    () => Boolean(member?.data && ((member.data.members && member.data.members.length > 0 && member?.membersGraph) || member.membersGraph.srcAcna !== "")) || false,
    [member.data, member?.data?.members, membersConnectivity.selectedItem, member?.membersGraph, member.membersGraph.srcAcna]
  );

  const handleExportData = useExportDataTable<ExportMemberTable, MembersExportResponse>(
    member?.data?.totalRecords ?? 0,
    10,
    MemberExportService,
    MemberHelper.builderMembersExportQueryParams,
    `${t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MEMBERSCONNECTIVITY.exportMember)} ${membersConnectivity.selectedItem}`,
    MapperHelper.mapMembersExport
  );

  const handleExport = useCallback(async (): Promise<void> => {
    setIsLoadingExport(true);
    await handleExportData();
    setIsLoadingExport(false);
  }, [handleExportData]);

  const handleGridView = useCallback(async (): Promise<void> => {
    setGridView((prevValue) => !prevValue);
    setTextButton((prevValue) => (prevValue === "tunnelDetailsView" ? "connectionGraphView" : "tunnelDetailsView"));
  }, [t]);

  useEffect(() => {
    if (isTabMembers) {
      MemberHelper.builderMembersExportQueryParams({ tableFilters: { acna: membersConnectivity.selectedItem } });
    }
  });

  const handleItemSelect = useCallback(
    async (item: string) => {
      if (previousSelectedItem !== item || !item) {
        setPreviousSelectedItem(item ?? item);
        membersConnectivity.selectedItem = item;
        MemberHelper.builderMembersExportQueryParams({ tableFilters: { acna: item } });
        setLoading(true);
        await membersConnectivity.loadData(item);
        await membersConnectivity.loadMembersGraph(item);
        setLoading(false);
      }
    },
    [previousSelectedItem, setLoading, setPreviousSelectedItem, store.shared.isAutoRefreshActive]
  );

  useEffect(() => {
    if (!membersConnectivity.selectedItem) {
      return;
    }

    if (store.shared.isAutoRefreshActive) {
      intervalRef.current = setInterval(async () => {
        setLoading(true);
        await membersConnectivity.loadData(membersConnectivity.selectedItem);
        await membersConnectivity.loadMembersGraph(membersConnectivity.selectedItem);
        setLoading(false);
      }, store.shared.getTimeToAutoRefresh());
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [membersConnectivity.selectedItem, store.shared.isAutoRefreshActive]);

  return (
    <div className="py-4">
      <div className="w-100 d-flex justify-content-between align-items-center">
        <div>
          {!isTabMembers && (
            <div className="d-flex align-items-center justify-content-center">
              <p className="m-2">{t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.memberLabel)}:</p>
              <MemberSelected selectedItem={membersConnectivity.selectedItem} onSelect={handleItemSelect} />
            </div>
          )}
        </div>
        <div className="d-flex gap-2">
          {gridView && member?.data?.totalRecords ? (
            <TNSButton buttonVariant={Variants.Secondary} onClick={handleExport} loading={isLoadingExport} disabled={isLoadingExport}>
              {isLoadingExport ? <Spin indicator={<LoadingOutlined spin />} size="small" /> : <FontAwesomeIcon icon={faDownload} className="me-2" />} {t("export")}
            </TNSButton>
          ) : null}
          <div>
            <TNSButton onClick={handleGridView} disabled={!member?.data?.totalRecords} buttonVariant={Variants.Primary}>
              {t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.BUTTON[textButton as keyof typeof TRANSLATION.SIDEBAR.MONITORING.MEMBERS.BUTTON])}
            </TNSButton>
          </div>
        </div>
      </div>
      {!thereIsData && !loading && (
        <div className="text-center text-status no-data">
          <span data-testid="empty-message">{textError}</span>
        </div>
      )}
      {thereIsData ? gridView ? <MembersGrid membersData={member.data} /> : <ConnectedGraph membersGraph={member.membersGraph} /> : null}
    </div>
  );
};

const MembersConnectivity = observer(MembersConnectionsInternal);
export default MembersConnectivity;
