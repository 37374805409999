import React from "react";

interface Props {
  color: string;
  label: string;
  width?: string;
  height?: string;
}

export const LegendOptions: React.FC<Props> = ({ color, label, width, height }) => {
  const style = {
    backgroundColor: color,
    width: width ?? "15px",
    height: height ?? "15px"
  };

  return (
    <div className="d-flex gap-1 align-items-center justify-content-start" data-testid="legend-options-component">
      <span>{label}</span>
      <div style={style} />
    </div>
  );
};
