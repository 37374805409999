import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Uptime } from "./tabs/Uptime";
import { Notes } from "./tabs/Notes";
import { LogicalVirtualCircuits } from "./tabs/LogicalVirtualCircuits";
import { useAsyncCall } from "../../../../../../../hooks/useAsyncCallShared";
import { Hardware } from "../../../../../../monitoring/containers/device-health-information/tabs/hardware";
import { store } from "../../../../../store/StoreMobx";
import { observer } from "mobx-react";
import { Members } from "./tabs/Members";
import { CellularUsage } from "./tabs/cellular-usage";
import { HeaderDetail } from "src/app/monitoring/containers/device-health-information/header-dhi";
import { Details } from "../../../../../../monitoring/containers/device-health-information/tabs/details";
import { DHIContextProvider } from "src/app/monitoring/context/dhi-context";
import { TNSTabs } from "@tns/ui-components";
import { Wireless } from "src/app/monitoring/containers/device-health-information/tabs/wireless";
import { TABS_ENABLED } from "src/app/monitoring/auth/available-dhi-tab";
import { ConnectedDevices } from "src/app/monitoring/containers/device-health-information/tabs/connected-devices";
import { Tabs } from "src/app/monitoring/containers/device-health-information/dhi";
import { Troubleshooting } from "./tabs/troubleshooting";
import { FirewallRules } from "./tabs/firewall-rules";
import TroubleshootingContextProvider from "src/app/portal-app/components/device/troubleshooting/context/troubleshooting-context";
import FirewallRulesContextProvider from "src/app/portal-app/context/firawall-rules/firewall-rules-context";
import VLANContextProvider from "src/app/monitoring/context/vlan-context";
import Events from "./tabs/events";
import { TNSSwitch } from "@tns/ui-components";
import { SoftwareTab } from "./tabs/Software";
import SoftwareTabContextProvider from "../../../../../context/softwareTab/SoftwareTabContext";
import { DeviceManufacturer } from "../../../../../../monitoring/models/devices/enums/DeviceManufacturer";
import { IDevice } from "../../../../../../monitoring/store/device/device";

export const DeviceDetail: React.FC = observer(() => {
  const { t } = useTranslation();
  const { deviceName } = useParams();
  const { device, member, devices } = store;
  const [viewActive, setViewActive] = useState<Tabs>();
  const tabsEnabled = useMemo(() => TABS_ENABLED(), []);

  const shouldRenderTroubleshooting = useMemo(() => {
    return device.detail.data?.manufacturer.toLowerCase() === DeviceManufacturer.VirtualAccess;
  }, [device.detail.data?.manufacturer]);

  const shouldRenderSoftwareTab = useMemo(() => {
    return device.detail.data?.manufacturer.toLowerCase() !== DeviceManufacturer.Cisco && device.detail.data?.manufacturer.toLowerCase() !== DeviceManufacturer.Fortinet;
  }, [device.detail.data?.manufacturer]);

  const availableTabs: Tabs[] = useMemo(
    () =>
      // eslint-disable-next-line array-callback-return
      Object.values(Tabs).filter((tab): Tabs | undefined => {
        if (tabsEnabled[tab]) {
          switch (tab) {
            case Tabs.CellularUsage:
            case Tabs.Wireless:
              if (store.device.cellularUsage.isWireless) {
                return tab;
              }
              break;
            case Tabs.Connections:
              if (member.hasMemberDevice?.hasMemberConnectivity) {
                return tab;
              }
              break;
            case Tabs.Troubleshooting:
              if (shouldRenderTroubleshooting) {
                return tab;
              }
              break;
            case Tabs.Software:
              if (shouldRenderSoftwareTab) {
                return tab;
              }
              break;
            default:
              return tab;
          }
        }
      }),
    [tabsEnabled, member.hasMemberDevice?.hasMemberConnectivity, shouldRenderTroubleshooting, shouldRenderSoftwareTab]
  );
  const [tabSelected, setTabSelected] = useState(availableTabs[0]);

  const loadMethod = useCallback(async (): Promise<void> => {
    if (deviceName) {
      device.note.clearContactData();
      device.lvc.clearData();
      const promises = [
        device.uptime.loadDataDaily(deviceName),
        device.cellularUsage.loadWireless(deviceName),
        member.loadHasMemberDevice(deviceName),
        device.note.loadDataContact(deviceName),
        device.detail.loadData(deviceName)
      ];
      await Promise.all(promises).catch((e) => console.error(e));
    }
  }, [deviceName, device.note, device.lvc, device.uptime, device.cellularUsage, device.detail, member]);

  useAsyncCall(loadMethod, []);

  useEffect(() => {
    if (!viewActive) {
      const activeTabs = Object.values(Tabs).filter((tab) => tabsEnabled[tab]);
      setViewActive(activeTabs[0]);
    }
  }, [viewActive, tabsEnabled]);

  const tabsComponents = useMemo(() => {
    return {
      details: (
        <DHIContextProvider>
          <Details setTabSelected={setTabSelected} />
        </DHIContextProvider>
      ),
      cellularUsage: <CellularUsage />,
      wireless: (
        <DHIContextProvider>
          <Wireless />
        </DHIContextProvider>
      ),
      uptime: <Uptime />,
      lvc: <LogicalVirtualCircuits />,
      notes: <Notes />,
      hardware: <Hardware />,
      connectedDevices: (
        <VLANContextProvider>
          <ConnectedDevices />
        </VLANContextProvider>
      ),
      connections: <Members />,
      troubleshooting: (
        <TroubleshootingContextProvider>
          <Troubleshooting />
        </TroubleshootingContextProvider>
      ),
      firewallRules: (
        <FirewallRulesContextProvider>
          <FirewallRules />
        </FirewallRulesContextProvider>
      ),
      softwareTab: (
        <SoftwareTabContextProvider>
          <SoftwareTab />
        </SoftwareTabContextProvider>
      ),
      events: <Events />
    };
  }, []);

  return (
    <div className="d-flex flex-column gap-2 device-detail-container">
      <div className="d-flex gap-2 device-detail">
        <div className="d-flex justify-content-end my-2 w-100">
          <div className="d-flex gap-2 align-items-center">
            <small>{t("autoRefresh")}</small>
            <TNSSwitch checked={store.shared.getIsAutoRefreshActive()} onChange={store.shared.toggleAutoRefresh} />
          </div>
        </div>
        <HeaderDetail tnsDeviceName={deviceName} devices={devices as IDevice} />
        <TNSTabs
          defaultActiveKey={tabSelected}
          activeKey={tabSelected}
          onTabClick={(key: string): void => setTabSelected(key as Tabs)}
          destroyInactiveTabPane
          items={availableTabs.map((tab) => ({
            key: tab,
            label: t(tab),
            children: tabsComponents[tab]
          }))}
        />
      </div>
    </div>
  );
});
