import { Dispatch, SetStateAction } from "react";

export interface DeviceGroupingFiltersContextProviderProps {
  children: React.ReactNode;
}

export interface DeviceGroupingFiltersContextProps {
  filters: DeviceGroupingFiltersType;
  setFilters: Dispatch<SetStateAction<DeviceGroupingFiltersType>>;
  disabled: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
}

export interface DeviceGroupingFiltersType {
  [FilterNameType.EntityProfileName]: string;
  [FilterNameType.BoardingStatus]: string;
  [FilterNameType.IsWireless]: boolean | string;
  [FilterNameType.ServiceDescription]: string;
  [FilterNameType.Manufacturer]: string;
  [FilterNameType.Model]: string;
  [FilterNameType.Countries]: string;
  [FilterNameType.State]: string;
  [FilterNameType.City]: string;
  [FilterNameType.ZipCode]: string;
}

export enum FilterNameType {
  EntityProfileName = "entityProfileName",
  BoardingStatus = "boardingStatus",
  IsWireless = "isWireless",
  ServiceDescription = "serviceDescription",
  Manufacturer = "manufacturer",
  Model = "model",
  Countries = "countries",
  State = "state",
  City = "city",
  ZipCode = "zipCode",
  EntityProfile = "entityProfile"
}
