interface TroubleshootingHelperReturn {
  isFQDN: (domain: string) => boolean;
  writtingEffect: (message: string, typingSpeed: number, setDisplayedLines: React.Dispatch<React.SetStateAction<string[]>>) => () => void;
  isIpInRange: (ip: string, range: { lo?: string | undefined; hi?: string | undefined }) => boolean;
}

export const TroubleshootingHelper = (): TroubleshootingHelperReturn => {
  const isFQDN = (domain: string): boolean => {
    domain = domain.trim();

    if (!domain || domain.length > 255) {
      return false;
    }

    if (domain.endsWith(".")) {
      domain = domain.slice(0, -1);
    }

    const parts = domain.split(".");

    if (parts.length < 2) {
      return false;
    }

    const validPart = /^[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/;

    for (let i = 0; i < parts.length; i++) {
      if (!validPart.test(parts[i]) || parts[i].length > 63) {
        return false;
      }
    }

    const tld = parts[parts.length - 1];
    if (!/^[a-zA-Z]{2,}$/.test(tld)) {
      return false;
    }

    return true;
  };

  const writtingEffect = (message: string, typingSpeed: number, setDisplayedLines: React.Dispatch<React.SetStateAction<string[]>>): (() => void) => {
    const trimmedMessage = message.replace(/\n+$/, "");
    const lines = trimmedMessage.split("\n");
    let currentLineIndex = 0;
    setDisplayedLines([]);

    const intervalId = setInterval(() => {
      if (currentLineIndex < lines.length) {
        setDisplayedLines((prevLines) => [...prevLines, lines[currentLineIndex]]);
        currentLineIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, typingSpeed);

    return () => clearInterval(intervalId);
  };

  const isIpInRange = (ip: string, range: { lo?: string | undefined; hi?: string | undefined }): boolean => {
    const ipToInt = (ip: string): number => ip.split(".").reduce((int, oct) => int * 256 + parseInt(oct, 10), 0);

    const ipInt = ipToInt(ip);
    if (range.lo && range.hi) {
      const loInt = ipToInt(range.lo);
      const hiInt = ipToInt(range.hi);
      return ipInt >= loInt && ipInt <= hiInt;
    }

    return false;
  };

  return { isFQDN, writtingEffect, isIpInRange };
};
