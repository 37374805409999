import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Themes, TNSCard } from "@tns/ui-components";
import { TRANSLATION } from "../../../../utils/const/translation";
import { CustomizerContext } from "../../../../../../contexts/customizer/CustomizerContext";
import * as darkTheme from "@tns/ui-style-dictionary/build/ts/dark-tokens";
import * as lightTheme from "@tns/ui-style-dictionary/build/ts/light-tokens";
import { DeviceImageHelper } from "src/shared/helpers/DeviceImageHelper";
import { useAsyncCall } from "src/hooks/useAsyncCallShared";

export interface DeviceDetailCardProps {
  model: string;
  vendor: string;
}

export const DeviceDetailCard: React.FC<DeviceDetailCardProps> = ({ model, vendor }) => {
  const customizer = useContext(CustomizerContext);
  const [image, setImage] = useState<string>();
  const objectStyleImage = useMemo(
    () => ({
      border: `1px solid ${customizer.selectedTheme === Themes.DARK ? darkTheme.TnsCardDefaultBorder : lightTheme.TnsCardDefaultBorder}`,
      borderRadius: "8px"
    }),
    [customizer.selectedTheme]
  );

  useAsyncCall(async () => {
    const imageLoaded = await DeviceImageHelper.getImage(`${vendor}_${model}`);
    setImage(imageLoaded);
  }, [vendor, model]);

  const { t } = useTranslation();
  return (
    <TNSCard className="d-flex flex-column align-items-center gap-5 h-100">
      <div className="d-flex flex-column align-items-center">
        <h6>
          <b>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.model)}</b>: <span>{model}</span>
        </h6>
        <h6>
          <b>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.vendor)}</b>: <span>{vendor}</span>
        </h6>
      </div>
      <div className="d-flex flex-column align-items-center">{image ? <img className="device-image" src={image} style={objectStyleImage} alt={`${vendor}_${model}`} /> : ""}</div>
    </TNSCard>
  );
};
