export enum RolesByUserList {
  Basic = "Basic",
  Admin = "Admin",
  SuperUser = "SuperUser",
  Custom = "Custom",
  CAM = "CAM"
}

export enum Roles {
  Basic = "BASIC",
  Admin = "ADMIN",
  SuperUser = "SUPERUSER",
  CAM = "CUSTOMERACCOUNTMANAGER"
}

export enum QueryRoles {
  Basic = "basic",
  Admin = "admin",
  SuperUser = "superuser",
  CAM = "cam"
}
