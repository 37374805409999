import React, { useEffect, useState } from "react";
import { COLORS } from "../../../../utils/const/colors";
import { observer } from "mobx-react";
import { SignalQualityType } from "../../../../models/devices/cellular/cellularSignal";

interface Props {
  signalQuality?: SignalQualityType;
  width?: string;
  height?: string;
}

export const SignalComponent: React.FC<Props> = observer(({ width, height, signalQuality }): JSX.Element => {
  const { GREEN, YELLOW, ORANGE, RED, TRANSPARENT, DARK_BLUE } = COLORS.BARS;
  const [colors, setColors] = useState(Array.from(Array(5).keys()).map(() => COLORS.BARS.TRANSPARENT));

  useEffect(() => {
    if (signalQuality === SignalQualityType.Excellent) {
      setColors(colors.map(() => GREEN));
    } else if (signalQuality === SignalQualityType.Good) {
      setColors(colors.map((_, index) => (index > 3 ? TRANSPARENT : YELLOW)));
    } else if (signalQuality === SignalQualityType.Fair) {
      setColors(colors.map((_, index) => (index > 2 ? TRANSPARENT : ORANGE)));
    } else if (signalQuality === SignalQualityType.Poor) {
      setColors(colors.map((_, index) => (index > 1 ? TRANSPARENT : RED)));
    } else {
      setColors(colors.map(() => TRANSPARENT));
    }
  }, [signalQuality]);

  return (
    <svg className="col m-auto" width={width ? width : "100"} height={height ? height : "100"} fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="signal-component">
      <path d="M0.52 35.1983L8.28 28.6149V35.6649H0L0.52 35.1983Z" fill={`${colors[0]}`} stroke={colors[0] === TRANSPARENT ? DARK_BLUE : ""} />
      <path d="M8.76 27.8891L16.43 21.3057V35.665H8.76V27.8891Z" fill={`${colors[1]}`} stroke={colors[1] === TRANSPARENT ? DARK_BLUE : ""} />
      <path d="M17.34 21.0517L25.01 14.4683V35.665H17.34V21.0517Z" fill={`${colors[2]}`} stroke={colors[2] === TRANSPARENT ? DARK_BLUE : ""} />
      <path d="M25.84 13.7425L33.51 7.15912V35.665H25.84V13.7425Z" fill={`${colors[3]}`} stroke={colors[3] === TRANSPARENT ? DARK_BLUE : ""} />
    </svg>
  );
});
