import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { TNSCard } from "@tns/ui-components";
import { observer } from "mobx-react";

import { LegendDaily } from "./LegendDaily";
import { store } from "../../../store/StoreMobx";
import { TRANSLATION } from "../../../utils/const/translation";
import { BandwidthUsageChart } from "../../chart/BandwidthUsageChart";

export const DailyData: React.FC = observer(() => {
  const { cellularUsage } = store.device;
  const { t } = useTranslation();

  return (
    <TNSCard className="h-100">
      <Card.Body>
        {cellularUsage?.billing && cellularUsage.billing.dailyDeviceUsage?.length > 0 ? (
          <>
            <div className="row align-items-center">
              <div className="col-md-8 text-end">
                <h5>
                  <b>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.dailyDataUsageTracker)}</b>
                </h5>
              </div>
              <div className="col-md-4 text-end">
                <LegendDaily isClosedPeriod={cellularUsage.isClosedPeriod} />
              </div>
            </div>
            <BandwidthUsageChart
              dailyUsage={cellularUsage.billing?.dailyDeviceUsage.map((value) => value.total)}
              threshold={cellularUsage.billing?.planSize}
              categories={cellularUsage.billing.dailyDeviceUsage.map((value) => value.date)}
              statusData={cellularUsage.billing.dailyDeviceUsage.map((value) => value.status)}
            />
          </>
        ) : (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <span>{t(TRANSLATION.SHARED.TABLE.noDataFound)}</span>
          </div>
        )}
      </Card.Body>
    </TNSCard>
  );
});
