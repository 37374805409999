import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useAsyncCall } from "../../../../../../shared/hooks/useAsyncCallShared";
import { TNSCard } from "@tns/ui-components";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../utils/const/translation";
import { GetVLANResponse, VLANContext } from "../../../../context/vlan-context";

export const VLANInformation: React.FC = () => {
  const { t } = useTranslation();
  const context = useContext(VLANContext);
  const { deviceName } = useParams();
  const [vlanInformation, setVlanInformation] = useState<GetVLANResponse[]>([]);

  const getVlanInformation = async (): Promise<void> => {
    if (deviceName) {
      const response = await context.getVLANData(deviceName);
      if (response) {
        setVlanInformation(response);
      }
    }
  };

  useAsyncCall(async () => {
    await getVlanInformation();
  }, [deviceName]);

  return (
    vlanInformation.length > 0 && (
      <TNSCard>
        <h5>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.vlanInformation)}</h5>
        {vlanInformation?.map((item, index) => {
          let vlanInfoLine = "";
          if (item.ipAddress && item.ipAddress !== "0") {
            {
              vlanInfoLine += `${item.ipAddressLabel}: ${item.ipAddress} `;
            }
          }
          if (item.subnetMask && item.subnetMask !== "0") {
            vlanInfoLine += `${item.subnetMaskLabel}: ${item.subnetMask} `;
          }
          return vlanInfoLine !== "" ? (
            <p className="fw-lighter" key={`${item.ipAddress} ${index}`}>
              {vlanInfoLine}
            </p>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          );
        })}
      </TNSCard>
    )
  );
};
