import React, { useCallback, useContext, useMemo, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { DateHelper } from "@tnso/shared";
import { BuilderParams } from "@tnso/api-core";
import { SorterResult } from "antd/es/table/interface";
import { TablePaginationConfig } from "antd/lib";
import { store } from "../../../../portal-app/store/StoreMobx";
import { MapperHelper } from "../../../../../app/administration/helpers/incident-management/mapper-helper";
import { IncidentManagementContext, Ticket, TicketsParams } from "../../../../../app/administration/context/incident-management/incident-management-context";
import { InlineResponse20082 } from "@tnso/api-sdk";
import { paginationSizeChangerContext } from "../../../../../contexts/paginationSizeChanger/paginationSizeChangerContext";
import { AuthService } from "../../../../../app/portal-app/service/auth/AuthService";
import { UserPrefenrencesHelper } from "../../../../../helpers/shared/UserPreferencesHelper";
import { useExportDataTable } from "@tnso/shared";
import { useAsyncCall } from "../../../hooks/useAsyncCallShared";
import dayjs from "dayjs";

export interface TicketsColumns {
  ticketsColumns: Columns[];
}

export interface Columns extends ColumnsType {
  dataIndex: string;
}

interface UseTableConfigReturn {
  columns: TicketsColumns;
  ticketsResponse?: InlineResponse20082;
  handleSort: (pagination: TablePaginationConfig, _: Record<string, React.ReactText[] | null>, sorter: SorterResult<Ticket>) => Promise<void>;
  currentPage: number;
  handleChangePage: (page: number) => Promise<void>;
  handleExportData: () => Promise<void>;
  skeletonColumns: unknown[];
  paginationSize: number;
  handlePaginationSizeChange: (size: number) => Promise<void>;
  filters: TicketsParams;
  setFilters: React.Dispatch<React.SetStateAction<TicketsParams>>;
  loading: boolean;
  setIsDateUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UseTableConfigProps {
  reloadTableData: boolean;
}

export const useTableConfig = ({ reloadTableData }: UseTableConfigProps): UseTableConfigReturn => {
  const context = useContext(IncidentManagementContext);
  const { paginationSize, setPaginationSize } = paginationSizeChangerContext();
  const [filters, setFilters] = useState<TicketsParams>({
    recordsPerPage: paginationSize,
    startAtRecord: 0,
    status: "Active",
    fromStartDate: dayjs().subtract(90, "days").unix(),
    toStartDate: dayjs().unix()
  });
  const [isDateUpdated, setIsDateUpdated] = useState(false);

  const [ticketsResponse, setTicketsResponse] = useState<InlineResponse20082>();
  const [currentPage, setCurrentPage] = useState(1);

  const ticketsColumns = useMemo(
    () => [
      {
        dataIndex: "ticketNumber",
        title: "ticketNumber",
        key: "ticketNumber",
        ellipsis: true,
        fixed: "left"
      },
      {
        dataIndex: "startDate",
        title: "incidentStartTime",
        key: "startDate",
        sorter: true,
        ellipsis: true,
        render: (cell: string, row: Ticket): string => {
          const timeZone = store.auth.userInfo?.timeZone;
          return row.startDate && row.startDate > 0
            ? DateHelper.convertTimestampToBrowserCulture(row.startDate, timeZone, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
              })
            : "-";
        }
      },
      {
        dataIndex: "description",
        title: "description",
        key: "description",
        ellipsis: true
      },
      {
        dataIndex: "acna",
        title: "ACNA",
        key: "acna",
        ellipsis: true
      },
      {
        dataIndex: "tnsDeviceName",
        title: "device",
        key: "tnsDeviceName",
        ellipsis: true
      },
      {
        dataIndex: "status",
        title: "status",
        key: "status",
        ellipsis: true
      },
      {
        dataIndex: "lastStatusDate",
        title: "lastStatusUpdatedOn",
        key: "lastStatusDate",
        sorter: true,
        ellipsis: true,
        render: (cell: string, row: Ticket): string => {
          const timeZone = store.auth.userInfo?.timeZone;
          return row.lastStatusDate && row.lastStatusDate > 0
            ? DateHelper.convertTimestampToBrowserCulture(row.lastStatusDate, timeZone, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
              })
            : "-";
        }
      }
    ],
    []
  );

  const skeletonColumns = ticketsColumns.map(({ dataIndex, title, key }) => ({
    dataIndex,
    title,
    key
  }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useAsyncCall(async (): Promise<void> => {
    const filtersLocal = { ...filters,  fromStartDate: dayjs().subtract(90, "days").unix(), toStartDate: dayjs().unix()};
    const response = await context.getTickets(!isDateUpdated ? filtersLocal : filters);
    if (response) {
      setTicketsResponse(response);
    }
  }, [context, filters, reloadTableData]);

  const builderTicketsQueryParams = useCallback(
    ({
      tableFilters,
      currentPage = 0,
      recordsPerPage = Number(UserPrefenrencesHelper.getParsedPreferences(["PREFERED_PAGE_SIZE"])?.PREFERED_PAGE_SIZE) || 25,
      sortBy,
      orderBy
    }: BuilderParams = {}): TicketsParams => {
      let queryParams = filters;

      if (orderBy && sortBy) {
        orderBy = orderBy === "ascend" ? "ASC" : "DESC";
        queryParams = { ...queryParams, sortBy, orderBy };
      }

      const startAtRecord = (currentPage || 0) * recordsPerPage;
      queryParams = { ...queryParams, startAtRecord, recordsPerPage };

      if (tableFilters) {
        queryParams = { ...queryParams, ...tableFilters };
      }

      Object.keys(queryParams).forEach((key) => {
        if (queryParams[key as keyof TicketsParams] === "") {
          delete queryParams[key as keyof TicketsParams];
        }
      });

      return queryParams;
    },
    [filters]
  );

  const handleChangePage = useCallback(
    async (page: number): Promise<void> => {
      const queryParams = builderTicketsQueryParams({ currentPage: page - 1, recordsPerPage: paginationSize });
      setFilters(queryParams);
      setCurrentPage(page);
    },
    [builderTicketsQueryParams, paginationSize]
  );

  const handleSort = useCallback(
    async (pagination: TablePaginationConfig, _: Record<string, React.ReactText[] | null>, sorter: SorterResult<Ticket>): Promise<void> => {
      setCurrentPage(1);
      const queryParams = builderTicketsQueryParams({ sortBy: sorter.columnKey, orderBy: String(sorter.order) });
      setFilters(queryParams);
    },
    [builderTicketsQueryParams]
  );

  const handlePaginationSizeChange = useCallback(
    async (size: number) => {
      if (size !== paginationSize) {
        setPaginationSize(size);
        const queryParams = builderTicketsQueryParams({ recordsPerPage: size });
        await AuthService.setUserPreferences({ PREFERED_PAGE_SIZE: size });
        setFilters(queryParams);

        setCurrentPage(1);
      }
    },
    [builderTicketsQueryParams, paginationSize, setPaginationSize]
  );

  const handleExportData = useExportDataTable<InlineResponse20082, InlineResponse20082, TicketsParams>(
    ticketsResponse?.totalRecords || 0,
    paginationSize,
    context.getTickets,
    filters,
    "Tickets",
    MapperHelper.mapTickets,
    false,
    ""
  );

  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: { ticketsColumns } as unknown as any, // TODO: Review this conversion.
    setFilters,
    ticketsResponse,
    handleSort,
    currentPage,
    handleChangePage,
    handleExportData,
    filters,
    handlePaginationSizeChange,
    skeletonColumns,
    paginationSize,
    loading: data.loading,
    setIsDateUpdated
  };
};
