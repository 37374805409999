import { IUserAssociateByRoleResponse, IUserRole, IUserRoleAvailableResponse, IUserRoleUpdate } from "../models/user-role.model";
import { encodeParams } from "../helpers/api/RequestHelper";
import { QueryParams } from "../models/query-params.model";
import { ApiService, IResponse } from "@tnso/api-core";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class UserRoleService extends ApiService {
  static async getRoles(queryParams?: QueryParams): Promise<IResponse<IUserRole[]> | undefined> {
    const urlSearchParams = encodeParams<QueryParams>(queryParams as QueryParams);
    return this.get(`${getApiBaseUrl()}/roles`, urlSearchParams);
  }

  static async getUserAssociateByRole(roleName: string, queryParams?: QueryParams): Promise<IResponse<IUserAssociateByRoleResponse> | undefined> {
    const urlSearchParams = encodeParams<QueryParams>(queryParams as QueryParams);
    return this.get(`${getApiBaseUrl()}/users/${roleName}/associated`, urlSearchParams);
  }

  static async getIsAvailableRole(roleName: string): Promise<IResponse<IUserRoleAvailableResponse> | undefined> {
    return this.get(`${getApiBaseUrl()}/roles/name/${roleName}/available`);
  }

  static async getRoleNames(): Promise<IResponse<string[]> | undefined> {
    return this.get<string[]>(`${getApiBaseUrl()}/roles/names`);
  }

  static async createRole(userRole: IUserRole): Promise<IResponse<IUserRole> | undefined> {
    return this.post(`${getApiBaseUrl()}/roles`, userRole);
  }

  static async editRole(userRole: IUserRoleUpdate, roleName: string): Promise<IResponse<IUserRoleUpdate> | undefined> {
    return this.patch(`${getApiBaseUrl()}/roles/${roleName}`, userRole);
  }

  static async deleteRole(roleName: string): Promise<IResponse<object> | undefined> {
    return this.remove(`${getApiBaseUrl()}/roles/${roleName}`);
  }
}
