import device, { IDevice } from "./device/device";
import detail, { IDetail } from "./device/detail/detail";
import lvc, { ILVC } from "./device/lvc/lvc";
import note, { INote } from "./device/note/note";
import uptime, { IUptime } from "./device/uptime/uptime";
import geolocation, { IGeolocation } from "./device/geolocation/geolocation";
import filter, { IFilter } from "./device/filter/filter";
import cellularSignal, { IWireless } from "./device/wireless/wireless";
import cellularUsage, { ICellularUsage } from "./device/cellular-usage/cellular-usage";
import membersConnectivity, { IMemberConnectivity } from "./connection/connection";
import connectedDevices, { IConnectedDevices } from "./device/connected-devices/connected-device";

interface Store {
  devices: IDevice;
  connection: IMemberConnectivity;
  device: {
    detail: IDetail;
    uptime: IUptime;
    note: INote;
    lvc: ILVC;
    geolocation: IGeolocation;
    filter: IFilter;
    cellularSignal: IWireless;
    cellularUsage: ICellularUsage;
    connectedDevices: IConnectedDevices;
  };
}

export const store: Store = {
  devices: device,
  connection: membersConnectivity,
  device: {
    detail: detail,
    uptime: uptime,
    note: note,
    lvc: lvc,
    geolocation: geolocation,
    filter: filter,
    cellularSignal: cellularSignal,
    cellularUsage: cellularUsage,
    connectedDevices: connectedDevices
  }
};
