import axios from "axios";
import { AuthService } from "../../service/auth/AuthService";
import { RefreshResponse } from "../../interfaces/auth/response/response";
import jwt_decode from "jwt-decode";
import { AxiosHelper } from "../../../../config/api/AxiosInstance";
import { UserInfo } from "../../interfaces/auth/login/login";
import { CustomErrorResponse } from "../api/RequestHelper";
import { Roles } from "../../interfaces/auth/roleAndPermission/role";
import Cookies from "js-cookie";
import { AWS_USER_POOLS_WEB_CLIENT_ID } from "../../../../config/environments";
import { fetchAuthSession } from "aws-amplify/auth";
import { LogHelper } from "../../../../helpers/shared/LogHelper";

export interface UserPayload {
  sub: string;
  firstName: string;
  lastName: string;
  email: string;
  timeZone: string;
  role: Roles;
  companyProfile: string;
  entityProfile: string;
  companyProfilesList: string[];
  customRoleFeatureGroupList: string[];
  featureList: string[];
  acnasList: string[];
}

interface JwtPayloadStandardFields {
  exp?: number;
  iss?: string;
  aud?: string | string[];
  nbf?: number;
  iat?: number;
  scope?: string;
  jti?: string;
  sub?: string;
}
type JsonPrimitive = null | string | number | boolean;
/** JSON array type */
type JsonArray = JsonPrimitive[];
/** JSON Object type */
interface JsonObject {
  [x: string]: JsonPrimitive | JsonArray | JsonObject;
}
export type JwtPayload = JwtPayloadStandardFields & JsonObject;

export class TokenHelper {
  static refreshIsPending = false;
  static originalRefreshTokenIsPending = false;
  static timeToRefreshTokens = localStorage.getItem("ApiConfiguration")
    ? JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")
    : { DIFFERENCE_TIME_TO_REFRESH_TOKEN: 15000, DIFFERENCE_TIME_TO_REFRESH_COGNITO_TOKEN: 15000 };

  static async refreshTokenValidator(error: CustomErrorResponse, refreshToken: string): Promise<void> {
    LogHelper.info("[TokenHelper] refreshTokenValidator start");
    const refresh = localStorage.getItem("refreshToken") || refreshToken;
    const API_URL_BASE = JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
    const config = error.config;

    if (!this.refreshIsPending) {
      this.refreshIsPending = true;
      AxiosHelper.instanceAuth.defaults.baseURL = API_URL_BASE;
      LogHelper.info("[TokenHelper] refreshTokenValidator AxiosHelper.instanceAuth.defaults.baseURL", AxiosHelper.instanceAuth.defaults.baseURL);
      const updatedToken = await AuthService.refreshToken({ refreshToken: refresh });
      if (!updatedToken) {
        return;
      }
      LogHelper.info("[TokenHelper] refreshTokenValidator updatedToken", updatedToken);
      const response = updatedToken as RefreshResponse;
      localStorage.setItem("accessToken", response.accessToken);
      localStorage.setItem("refreshToken", response.refreshToken);
      this.refreshIsPending = false;
      AxiosHelper.isActiveToken = true;
      if (config.headers) {
        config.headers["Authorization"] = response.accessToken;
        return axios.request(config);
      }
    }
    LogHelper.info("[TokenHelper] refreshTokenValidator end");
  }

  static async setUserInfoByToken(accessToken: string): Promise<UserInfo | undefined> {
    const tokenDecoded: UserPayload | undefined = jwt_decode<UserPayload>(accessToken);
    if (tokenDecoded && tokenDecoded.sub) {
      return {
        username: tokenDecoded.sub,
        firstName: tokenDecoded.firstName,
        lastName: tokenDecoded.lastName,
        email: tokenDecoded.email,
        timeZone: tokenDecoded.timeZone,
        role: tokenDecoded.role,
        companyProfile: tokenDecoded.companyProfile,
        entityProfile: tokenDecoded.entityProfile,
        companyProfilesList: tokenDecoded.companyProfilesList,
        customRoleFeatureGroupList: tokenDecoded.customRoleFeatureGroupList,
        featureList: tokenDecoded.featureList,
        acnasList: tokenDecoded.acnasList
      };
    }
    return undefined;
  }

  static async validateExpiredToken(accessToken: string, refreshToken: string): Promise<void> {
    LogHelper.info("[TokenHelper] Validating expired token start");
    const tokenDecoded: JwtPayload | undefined = jwt_decode<JwtPayload>(accessToken);
    LogHelper.info("[TokenHelper] Validating expired token end");
    if (tokenDecoded && tokenDecoded.exp) {
      const tokenExpire = new Date(tokenDecoded.exp * 1000).getTime();
      const dateNow = new Date().getTime();
      const timeUntilRefresh = tokenExpire - dateNow - this.timeToRefreshTokens.DIFFERENCE_TIME_TO_REFRESH_TOKEN;

      LogHelper.info("[TokenHelper] Validating expired token timeUntilRefresh", timeUntilRefresh);
      if (timeUntilRefresh > 0) {
        setTimeout(async () => {
          if (AxiosHelper.isActiveToken) {
            AxiosHelper.isActiveToken = false;
            const API_URL_BASE = JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
            LogHelper.info("[TokenHelper] Validating expired token API_URL_BASE", API_URL_BASE);
            AxiosHelper.instanceAuth.defaults.baseURL = API_URL_BASE;
            LogHelper.info("[TokenHelper] Validating expired token AxiosHelper.instanceAuth.defaults.baseURL", AxiosHelper.instanceAuth.defaults.baseURL);
            const updatedToken = await AuthService.refreshToken({ refreshToken });
            if (!updatedToken) {
              AxiosHelper.isActiveToken = true;
            } else {
              const response = updatedToken as RefreshResponse;
              localStorage.setItem("accessToken", response.accessToken);
              localStorage.setItem("refreshToken", response.refreshToken);
              AxiosHelper.isActiveToken = true;
              await this.validateExpiredToken(response.accessToken, response.refreshToken);
            }
          }
        }, timeUntilRefresh);
      }
    }
  }

  static async validateExpiredCognitoToken(): Promise<void> {
    const username = Cookies.get(`CognitoIdentityServiceProvider.${AWS_USER_POOLS_WEB_CLIENT_ID}.LastAuthUser`);
    const cognitoAccessToken = Cookies.get(`CognitoIdentityServiceProvider.${AWS_USER_POOLS_WEB_CLIENT_ID}.${username}.accessToken`);

    if (cognitoAccessToken) {
      const tokenDecoded: JwtPayload | undefined = jwt_decode<JwtPayload>(cognitoAccessToken);

      if (tokenDecoded && tokenDecoded.exp) {
        const dateNow = new Date().getTime();
        const tokenExpire = new Date(tokenDecoded.exp * 1000).getTime();
        const timeUntilRefresh = tokenExpire - dateNow - this.timeToRefreshTokens.DIFFERENCE_TIME_TO_REFRESH_COGNITO_TOKEN;

        if (timeUntilRefresh > 0) {
          setTimeout(async () => {
            await fetchAuthSession({ forceRefresh: true });
            await this.validateExpiredCognitoToken();
          }, timeUntilRefresh);
        } else {
          await fetchAuthSession({ forceRefresh: true });
          await this.validateExpiredCognitoToken();
        }
      }
    }
  }
}
