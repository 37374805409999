import { IDeviceTemperatureUnit } from "../../models/devices/dhi/dhi";
import { IDeviceCPUUsage } from "src/app/monitoring/models/devices/dhi/dhi";

export class DeviceMetricsHelper {
  static normalColor = "#42C53F";
  static warningColor = "#EB9646";
  static dangerColor = "#FF2D2E";

  public static getColorTemperature(temperature: number | undefined, unit: IDeviceTemperatureUnit): string | undefined {
    if (!temperature) {
      return;
    }
    if (unit === IDeviceTemperatureUnit.C) {
      if (temperature < 65) {
        return DeviceMetricsHelper.normalColor;
      }
      if (temperature <= 75) {
        return DeviceMetricsHelper.warningColor;
      }
      return DeviceMetricsHelper.dangerColor;
    } else {
      if (temperature < 149) {
        return DeviceMetricsHelper.normalColor;
      }
      if (temperature <= 167) {
        return DeviceMetricsHelper.warningColor;
      }
      return DeviceMetricsHelper.dangerColor;
    }
  }

  public static getColorMemory(memoryUsage: number): string {
    if (memoryUsage < 75) {
      return DeviceMetricsHelper.normalColor;
    }
    if (memoryUsage < 90) {
      return DeviceMetricsHelper.warningColor;
    }
    return DeviceMetricsHelper.dangerColor;
  }

  public static getColorCPUUsage(usage: number): string {
    if (usage < 75) {
      return DeviceMetricsHelper.normalColor;
    }
    if (usage < 90) {
      return DeviceMetricsHelper.warningColor;
    }
    return DeviceMetricsHelper.dangerColor;
  }

  public static groupingCores(cores: IDeviceCPUUsage[] | undefined): IDeviceCPUUsage[][] {
    const groupingCores: IDeviceCPUUsage[][] = [];
    let tempArray = [];

    cores?.forEach((object: IDeviceCPUUsage, index: number) => {
      tempArray.push(object);
      if ((index + 1) % 4 === 0 || index === cores.length - 1) {
        groupingCores.push(tempArray);
        tempArray = [];
      }
    });

    return groupingCores;
  }
}
