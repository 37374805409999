import { SorterResult } from "antd/es/table/interface";

interface SorterHelperReturn<T> {
  sortValues: (data: T[], sorter: SorterResult<T> | SorterResult<T>[]) => T[];
}

export const SorterHelper = <T extends Record<keyof T, T[keyof T]>>(): SorterHelperReturn<T> => {
  const sortValues = (data: T[], sorter: SorterResult<T> | SorterResult<T>[]): T[] => {
    const sorters = Array.isArray(sorter) ? sorter : [sorter];

    return [...data].sort((a, b) => {
      for (const s of sorters) {
        if (s.columnKey && s.order) {
          const key = s.columnKey as keyof T;
          const valueA = a[key];
          const valueB = b[key];

          if (valueA && valueB) {
            if (valueA < valueB) {
              return s.order === "ascend" ? -1 : 1;
            }
            if (valueA > valueB) {
              return s.order === "ascend" ? 1 : -1;
            }
          }
        }
      }
      return 0;
    });
  };

  return { sortValues };
};
