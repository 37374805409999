import { useContext, useMemo, useState } from "react";
import { UserPrefenrencesHelper } from "../../helpers/shared/UserPreferencesHelper";
import { ConfigContext } from "../configuration/ConfigurationContext";

export interface PaginationSizeChangerContextResponse {
  paginationSize: number;
  setPaginationSize: (size: number) => void;
  paginationPageSizeOptions?: { label: string; value: number }[];
}

export const paginationSizeChangerContext = (): PaginationSizeChangerContextResponse => {
  const configContext = useContext(ConfigContext);
  const [paginationSize, setPaginationSize] = useState<number>(
    Number(UserPrefenrencesHelper.getParsedPreferences(["PREFERED_PAGE_SIZE"])?.PREFERED_PAGE_SIZE) ||
      Number(configContext.configuration?.environmentValues?.DEFAULT_PAGE_SIZE) ||
      25
  );
  const paginationSizeOptionsEnv = useMemo(() => configContext.configuration?.environmentValues?.PAGINATION_SIZE_OPTIONS?.split(","), [configContext]);
  const paginationPageSizeOptions = paginationSizeOptionsEnv?.map((size: string) => ({
    label: size,
    value: parseInt(size, 10)
  }));

  return { paginationSize, setPaginationSize, paginationPageSizeOptions };
};
