import { Variants, TNSButton, TNSInput, TNSDivider, TNSDrawer } from "@tns/ui-components";
import React, { useCallback, useEffect, useMemo } from "react";
import { TRANSLATION } from "../../utils/const/translation";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { DeviceCustomerName, DeviceDetail } from "../../models/devices/devices";
import { IDetail } from "../../store/device/detail/detail";
import { IDevice } from "../../store/device/device";

export interface HeaderDrawerDhiProps {
  detail: IDetail;
  data?: DeviceDetail;
  devices: IDevice;
  tnsDeviceName?: string;
  closeDrawer: () => void;
  drawerOpen: boolean;
  openDrawer: () => void;
}

export const HeaderDrawerDhi: React.FC<HeaderDrawerDhiProps> = ({ detail, data, devices, tnsDeviceName, closeDrawer, drawerOpen }) => {
  const { t } = useTranslation();

  const initialValues: DeviceCustomerName = useMemo(() => {
    return {
      customerDeviceName: data?.customerDeviceName ?? detail.data?.customerDeviceName ?? ""
    };
  }, [data?.customerDeviceName, detail.data?.customerDeviceName]);

  const {
    control,
    getValues,
    reset,
    formState: { errors, isValid }
  } = useForm<DeviceCustomerName>({
    defaultValues: initialValues,
    mode: "all"
  });

  useEffect(() => {
    reset({
      customerDeviceName: data?.customerDeviceName ?? detail.data?.customerDeviceName ?? ""
    });
  }, [data?.customerDeviceName, detail.data?.customerDeviceName]);

  const submitName = useCallback(async (): Promise<void> => {
    const name = getValues();
    if (name && tnsDeviceName) {
      await devices.updateCustomerDeviceName(name, tnsDeviceName);
      await detail.loadData(tnsDeviceName);
      reset();
    }
    closeDrawer();
  }, [data, devices]);

  return (
    <TNSDrawer open={drawerOpen} onClose={closeDrawer} title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CUSTOMERDEVICENAME.editCustomerDeviceName)}>
      <div className="d-flex justify-content-start">
        <div className="col-lg-4 col-md-4 col-sm-5">
          <label htmlFor="customerDeviceName">{`${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.deviceName)}:`}</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-7">
          <Controller
            name="customerDeviceName"
            control={control}
            rules={{ required: true, minLength: 5, maxLength: 100 }}
            render={({ field }): JSX.Element => <TNSInput {...field} />}
          />
        </div>
      </div>
      <div>
        {errors.customerDeviceName?.type === "minLength" && (
          <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CUSTOMERDEVICENAME.customerDeviceNameLength)}</small>
        )}
        {errors.customerDeviceName?.type === "maxLength" && (
          <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CUSTOMERDEVICENAME.customerDeviceNameLength)}</small>
        )}
        {errors.customerDeviceName?.type === "required" && (
          <small className="text-danger">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CUSTOMERDEVICENAME.customerDeviceNameLength)}</small>
        )}
      </div>
      <TNSDivider type="horizontal" className="mt-2" />
      <div className="justify-content-end d-flex gap-2 mt-2">
        <TNSButton buttonVariant={Variants.Secondary} onClick={closeDrawer}>
          {t(TRANSLATION.SHARED.cancel)}
        </TNSButton>

        <TNSButton buttonVariant={Variants.Primary} onClick={submitName} disabled={!isValid}>
          {t(TRANSLATION.SHARED.BUTTON.save)}
        </TNSButton>
      </div>
    </TNSDrawer>
  );
};
