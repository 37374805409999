import React from "react";
interface InformationCardDetailProps {
  title: string;
  data?: string;
}
export const InformationCardDetail: React.FC<InformationCardDetailProps> = ({ data, title }): JSX.Element => {

  return (
    <div className="d-flex gap-2">
      <span>{title}:</span>
      {data ? <span>{data}</span> : <span>N/A</span>}
    </div>
  );
};
