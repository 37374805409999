import React from "react";
import { DatePickerProps } from "antd";
import { useTranslation } from "react-i18next";
import { TNSRangePicker } from "@tns/ui-components";
import dayjs from "dayjs";

interface DateRangePickerFilterProps {
  key: string;
  onDatesChange: (startDate: string, endDate: string) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any | null; // TODO: Review this later.
  defaultValue?: string;
  placeholder?: string;
}

const DateRangePickerFilter: React.FC<DateRangePickerFilterProps> = ({ value, onDatesChange }) => {
  const { t } = useTranslation();

  const onChange = async (values: [string, string]): Promise<void> => {
    if ((values[0] && values[1]) || (values[0] === "" && values[1] === "")) {
      await onDatesChange(values[0], values[1]);
    }
  };

  const disabled90DaysDate: DatePickerProps["disabledDate"] = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, "days")) >= 90;
    }
    return false;
  };

  return (
    <TNSRangePicker
      onChange={async (_, dateString): Promise<void> => {
        await onChange(dateString);
      }}
      placeholder={[t("startDate"), t("endDate")]}
      disabledDate={disabled90DaysDate}
      minDate={dayjs().subtract(1, "year")}
      maxDate={dayjs()}
      value={value}
    />
  );
};

export default DateRangePickerFilter;
