import { ApiCore, ApiService, IResponse } from "@tnso/api-core";
import { CellularParams, CellularUsage, CellularUsageBilling, CellularUsageBillingPeriod, Wireless } from "../../../models/devices/cellular/cellularUsage";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class CellularUsageService extends ApiService {
  static async getHistorical(tnsDeviceName: string): Promise<IResponse<CellularUsage[]> | undefined> {
    return this.get<CellularUsage[]>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/usage/historical`);
  }

  static async getBillingPeriod(tnsDeviceName: string): Promise<IResponse<CellularUsageBillingPeriod[]> | undefined> {
    return this.get<CellularUsageBillingPeriod[]>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/usage/billingperiods`);
  }

  static async getBilling(params?: CellularParams): Promise<IResponse<CellularUsageBilling> | undefined> {
    const urlSearchParams = ApiCore.encodeParams<CellularParams>(params ?? {});
    return this.get<CellularUsageBilling>(`${getApiBaseUrl()}/devices/usage/billing`, urlSearchParams);
  }

  static async getCurrent(tnsDeviceName: string): Promise<IResponse<CellularUsage> | undefined> {
    return this.get<CellularUsage>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/usage/current`);
  }

  static async getWireless(tnsDeviceName: string): Promise<IResponse<Wireless> | undefined> {
    return this.get<Wireless>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/wireless`);
  }
}
