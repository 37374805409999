import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import { MetricCard } from "../tabs/details/metrics/metric-card";

interface HardwareDetailsProps {
  name?: ReactNode;
  children?: ReactNode;
}

export const HardwareDetails: React.FC<HardwareDetailsProps> = observer(({ name, children }) => {
  return <MetricCard header={name} data={children} />;
});
