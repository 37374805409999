import { ApiService, IResponse, ApiCore } from "@tnso/api-core";
import { UserGuideResponse } from "../models/user-guide";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_STRAPI ?? "/cms/api";
};

export class UserGuideService extends ApiService {
  static async getContent(pathOfGuide?: string): Promise<IResponse<UserGuideResponse> | undefined> {
    return ApiCore.strapiInstance.get<UserGuideResponse>(`${getApiBaseUrl()}/guides${pathOfGuide}`);
  }

  static async getMenu(): Promise<IResponse<UserGuideResponse> | undefined> {
    return ApiCore.strapiInstance.get<UserGuideResponse>(`${getApiBaseUrl()}/menus/1?${encodeURIComponent("populate[0]")}=items&${encodeURIComponent("populate[1]")}=items.parent`);
  }
}
