import React from "react";
import { MetricCard } from "./metric-card";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "src/app/monitoring/utils/const/translation";

export interface UptimeCPECardProps {
  uptime: string;
}

export const UptimeCPECard: React.FC<UptimeCPECardProps> = ({ uptime }) => {
  const { t } = useTranslation();
  const uptimeData = uptime ? <h6>{uptime}</h6> : <h6>N/A</h6>;

  return <MetricCard header={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.uptimeCPE)} data={uptimeData} />;
};
