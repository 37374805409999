import React, { MouseEvent, useCallback, useEffect } from "react";
import { ISidebarData, SidebarData } from "../sidebardata/SidebarData";
import { AuthHelper } from "../../../app/portal-app/helpers/auth/AuthHelper";
import { store } from "../../../app/portal-app/store/StoreMobx";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useAsyncCall } from "../../../hooks/useAsyncCallShared";
import { SidebarFilterHelper } from "../../../app/portal-app/helpers/shared/SidebarFilterHelper";
import { Link } from "react-router-dom";
import { TRANSLATION } from "../../../app/portal-app/utils/const/translation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { Authorization } from "@tnso/roles-and-permissions";
import { ReleaseVersion } from "../../../layouts/release-version/release-version";
import { TNSMenu } from "@tns/ui-components";
import "./Sidebar.scss";

export enum MenuKeys {
  Monitoring = "monitoring",
  Devices = "monitoring_devices",
  Members = "members",
  ConnectedDevices = "connected_devices",
  Administration = "administration",
  CompanyAdministration = "company_profile_administration",
  UserAdministration = "user_administration",
  Support = "support",
  ChangeManagement = "changeManagement",
  Help = "help",
  ContactUs = "contact_us",
  UserGuide = "user_guide",
  DeviceProfiles = "deviceProfiles",
  Contact = "contact",
  Directory = "directory",
  SelfServiceTools = "selfServiceTools",
  TnsLinkProvisioning = "tnslinkProvisioning",
  SwapDevices = "swapDevices",
  camProfiles = "camProfileAdministration",
  TnsOnlineApi = "tnsOnlineApi",
  IncidentManagement = "incident_management"
}

function Sidebar(): JSX.Element {
  const { t } = useTranslation();
  const { member, shared } = store;
  const isMiniSidebar = store.customizer.isMiniSidebar;
  const { role, isSuperUser } = AuthHelper.getAuthByToken(localStorage.getItem("accessToken"));
  const [availableMenus, setAvailableMenus] = React.useState<ISidebarData[]>([]);
  const getRoutesByJwt = Authorization.getRoutesByJwt();
  const isActAs = localStorage.getItem("actAs");

  const handleToggleSideBar = useCallback((event: MouseEvent<HTMLElement | undefined>): void => {
    store.customizer.ToggleMiniSidebar();
    event?.preventDefault();
  }, []);

  useAsyncCall(async (): Promise<void> => {
    if (!isSuperUser) {
      try {
        await member.loadHasMemberConnectivity();
      } catch (e) {
        console.error(e);
      }
    }
  }, [isSuperUser, member]);

  const buildingItemMenu = useCallback(() => {
    const newItems: ISidebarData[] = [];
    SidebarData.forEach((item) => {
      if (getRoutesByJwt.includes(item.key)) {
        if (item.children) {
          const children: ISidebarData[] = [];
          item.children.forEach((child) => {
            if (getRoutesByJwt.includes(child.key) || window.location.hostname.includes("localhost")) {
              const newChild = {
                ...child,
                label: (
                  <Link to={child.href} className="text-decoration-none sidebar-link">
                    {t(TRANSLATION.SIDEBAR.MENU[child.label as keyof typeof TRANSLATION.SIDEBAR.MENU])}
                  </Link>
                )
              };
              children.push(newChild);
            }
          });
          if (children.length > 0) {
            const newItem = {
              ...item,
              children,
              label: t(TRANSLATION.SIDEBAR.MENU[item.label as keyof typeof TRANSLATION.SIDEBAR.MENU])
            };
            newItems.push(newItem);
          }
        } else {
          const newItem = {
            ...item,
            label: t(TRANSLATION.SIDEBAR.MENU[item.label as keyof typeof TRANSLATION.SIDEBAR.MENU])
          };
          newItems.push(newItem);
        }
      }
    });
    return newItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, t]);

  useEffect(() => {
    const newItems = buildingItemMenu();
    const filteredMenus = SidebarFilterHelper.filterMenu(newItems, "monitoring", "members", member.hasMemberConnectivity?.hasMemberConnectivity);
    setAvailableMenus(filteredMenus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member.hasMemberConnectivity, t]);

  return (
    <div className="sidebar-container">
      <div className={`pt-${isActAs ? "3" : "0"} sidebar`}>
        <div className="expandible-icon" aria-hidden="true">
          <div onClick={handleToggleSideBar}>
            <FontAwesomeIcon icon={faList} size="lg" />
          </div>
        </div>
        <TNSMenu
          defaultSelectedKeys={[shared.menuItemSelected ?? MenuKeys.Monitoring]}
          onOpenChange={(menuItem): void => shared.setOpenMenuKeys(menuItem as MenuKeys[])}
          onSelect={(menuItem): void => shared.setMenuItemSelected(menuItem.key as MenuKeys)}
          defaultOpenKeys={shared.openMenuKeys ?? [MenuKeys.Monitoring]}
          openKeys={shared.openMenuKeys}
          selectedKeys={[shared.menuItemSelected ?? MenuKeys.Monitoring]}
          mode="inline"
          className={isMiniSidebar ? "sidebar-position-mini" : "sidebar-position"}
          inlineCollapsed={isMiniSidebar}
          items={availableMenus}
          style={{ overflowY: "auto", maxHeight: "calc(95vh - 120px)" }}
        />
      </div>
      <div className="release-text">
        <ReleaseVersion />
      </div>
    </div>
  );
}
export default observer(Sidebar);
