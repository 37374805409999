import { ApiCore, ApiService, IResponse } from "@tnso/api-core";
import { HasMemberConnectivity, HasMemberDevice, MemberConnectivityParams, MembersGraphResponse, MembersResponse } from "../../models/connection/connection";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class MemberConnectivityService extends ApiService {
  static async getAll(acnas?: string, params?: MemberConnectivityParams): Promise<IResponse<MembersResponse> | undefined> {
    const urlSearchParams = ApiCore.encodeParams<MemberConnectivityParams>(params as MemberConnectivityParams);
    return this.get<MembersResponse>(`${getApiBaseUrl()}/members/${acnas}`, urlSearchParams);
  }

  static async getConnectedGraph(acna?: string): Promise<IResponse<MembersGraphResponse> | undefined> {
    return this.get<MembersGraphResponse>(`${getApiBaseUrl()}/members/${acna}/connectedGraph`);
  }

  static async getHasMemberConnectivity(): Promise<IResponse<HasMemberConnectivity> | undefined> {
    return this.get<HasMemberConnectivity>(`${getApiBaseUrl()}/members/hasMemberConnectivity`);
  }

  static async getHasMemberDevice(tnsDeviceName: string, params?: MemberConnectivityParams): Promise<IResponse<HasMemberDevice> | undefined> {
    const urlSearchParams = ApiCore.encodeParams<MemberConnectivityParams>(params as MemberConnectivityParams);
    return this.get<HasMemberDevice>(`${getApiBaseUrl()}/members/hasMemberConnectivity/${tnsDeviceName}`, urlSearchParams);
  }
}
