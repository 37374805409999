import { QueryParams } from "src/shared/models/queryParams";
import { HasGeolocation } from "../../models/devices/geolocation/geolocation";
import { GeolocationResponse } from "../../models/devices/response/response";
import { ApiCore, ApiService, IResponse } from "@tnso/api-core";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class LocationService extends ApiService {
  static async getAll(params?: QueryParams): Promise<IResponse<GeolocationResponse[]> | undefined> {
    const urlSearchParams = ApiCore.encodeParams<QueryParams>(params as QueryParams);
    return this.get<GeolocationResponse[]>(`${getApiBaseUrl()}/devices/geolocation`, urlSearchParams);
  }

  static async gethasGeolocation(): Promise<IResponse<HasGeolocation> | undefined> {
    return this.get<HasGeolocation>(`${getApiBaseUrl()}/devices/count/nogeolocation`);
  }

  static async getByGroupId(groupId: number, params?: QueryParams): Promise<IResponse<GeolocationResponse[]> | undefined> {
    const urlSearchParams = ApiCore.encodeParams<QueryParams>(params as QueryParams);
    return this.get<GeolocationResponse[]>(`${getApiBaseUrl()}/devices/locations/group/${groupId}`, urlSearchParams);
  }

  static async getByName(deviceName: string): Promise<IResponse<GeolocationResponse> | undefined> {
    return this.get<GeolocationResponse>(`${getApiBaseUrl()}/devices/${deviceName}/locations`);
  }

  static async create(deviceLocation: GeolocationResponse): Promise<IResponse<GeolocationResponse> | undefined> {
    return this.post<GeolocationResponse>(`${getApiBaseUrl()}/devices/locations`, deviceLocation);
  }
}
