import React, { useMemo } from "react";
import { ConnectivityStatus, DeviceDetail } from "../../../../models/devices/devices";
import MapView from "../../../maps/map-view";
import { GeolocationResponse } from "../../../../models/devices/response/response";
import { TNSCard } from "@tns/ui-components";
import { store } from "../../../../store/store";
import { observer } from "mobx-react";

export interface DeviceLocationProps {
  deviceDetail?: DeviceDetail;
}

export const DeviceLocationMap: React.FC<DeviceLocationProps> = observer(({ deviceDetail }) => {
  const detail = deviceDetail ?? store.device.detail.data;

  const location: GeolocationResponse | null = useMemo(
    () =>
      ({
        tnsDeviceName: detail?.tnsDeviceName || "",
        latitude: detail?.latitude || "0",
        longitude: detail?.longitude || "0",
        connectivityStatus: detail?.connectivityStatus || ConnectivityStatus.unknown,
        acna: detail?.acna || ""
      } || null),
    [detail?.tnsDeviceName, detail?.latitude, detail?.longitude, detail?.connectivityStatus, detail?.acna]
  );

  const mapHeight =
    !deviceDetail?.atmManufacturer &&
    !deviceDetail?.atmModelNumber &&
    !deviceDetail?.atmProtocol &&
    !deviceDetail?.atmSerialBaudRate &&
    !deviceDetail?.atmPollAddress &&
    !deviceDetail?.atmDeviceAddress &&
    !deviceDetail?.atmHostCharacterSet;

  return (
    <TNSCard className="w-50">
      <div className="map-container rounded w-100" data-testid="device-loation-component">
        <MapView height={mapHeight ? undefined : "70vh"} devicesGroup={location ? [location] : undefined} />
        <div className="d-flex w-100 justify-content-center align-items-center map-footer">
          <span>{detail?.streetAddress}</span>
        </div>
      </div>
    </TNSCard>
  );
});
