import React from "react";
import { type TableColumnsType } from "antd";
import { TNSBadge } from "@tns/ui-components";

export interface InterfaceTableConfig {
  interfaceMetricsCloumns: TableColumnsType[];
}

export const useInterfaceTableConfig = (): InterfaceTableConfig => {
  const interfaceMetricsCloumns: TableColumnsType[] = [
    {
      dataIndex: "name",
      title: "interfaceName",
      key: "name"
    },
    {
      dataIndex: "status",
      title: "interfaceStatus",
      key: "status",
      render: (cell: string): JSX.Element => {
        const status = cell === "Up" ? "success" : "error";
        return <TNSBadge status={status} text={<span>{cell}</span>} />;
      }
    },
    {
      dataIndex: "utilization",
      title: "utilization",
      key: "utilization",
      render: (cell: string): JSX.Element => {
        return <span>{cell} Mbps</span>;
      }
    },
    {
      dataIndex: "configSpeed",
      title: "interfaceConfigSpeed",
      key: "configSpeed",
      render: (cell: string): JSX.Element => {
        return <span>{cell} Mbps</span>;
      }
    }
  ];

  return { interfaceMetricsCloumns };
};
