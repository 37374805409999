import React, { useMemo } from "react";
import { IDeviceMetrics } from "src/app/monitoring/models/devices/dhi/dhi";
import { TemperatureCard } from "./metrics/temperature-card";
import { LatencyCard } from "./metrics/latency-card";
import { UptimeCPECard } from "./metrics/uptime-cpe-card";
import { CPUUsageCard } from "./metrics/cpu-usage-card";
import { SignalStrength } from "./metrics/signal-strength";
import { CellularSignal } from "src/app/monitoring/models/devices/cellular/cellularSignal";
import { ConnectivityStatus, DeviceDetail } from "../../../../models/devices/devices";

export interface DeviceMetricsProps {
  deviceMetrics: IDeviceMetrics;
  mostRecentSignal?: CellularSignal;
  deviceDetail?: DeviceDetail;
  loadingSignal: boolean;
  handleRefreshData: () => void;
}

export const DeviceMetrics: React.FC<DeviceMetricsProps> = ({ deviceMetrics, mostRecentSignal, deviceDetail, loadingSignal, handleRefreshData }) => {
  const showSignalStrength = useMemo(() => {
    return deviceDetail?.connectivityStatus === ConnectivityStatus.onPrimary || deviceDetail?.connectivityStatus === ConnectivityStatus.onBackup;
  }, [deviceDetail?.connectivityStatus]);

  return (
    <div className="d-flex w-100 gap-2">
      <div className="w-25">
        <TemperatureCard temperatureInCelsius={deviceMetrics.temperatureInCelsius} temperatureInFahrenheit={deviceMetrics.temperatureInFahrenheit} />
      </div>
      <div className="w-25">
        <CPUUsageCard cpuUsage={deviceMetrics.cpuUsage} />
      </div>
      <div className="w-25">
        <LatencyCard latency={deviceMetrics.latency} />
      </div>
      <div className="w-25">
        <UptimeCPECard uptime={deviceMetrics.uptime} />
      </div>
      <div className="w-25">{showSignalStrength && <SignalStrength mostRecentSignal={mostRecentSignal} handleRefreshData={handleRefreshData} loading={loadingSignal} />}</div>
    </div>
  );
};
