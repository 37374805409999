import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "src/app/monitoring/utils/const/translation";
import { MetricCard } from "./metric-card";
import { CellularSignal } from "src/app/monitoring/models/devices/cellular/cellularSignal";
import { DateHelper } from "src/app/portal-app/helpers/shared/DateHelper";
import { SignalComponent } from "../../cellular-signal/signal-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";

export interface CellularSignalProps {
  mostRecentSignal?: CellularSignal;
  loading: boolean;
  handleRefreshData: () => void;
  width?: string;
}
export const SignalStrength: React.FC<CellularSignalProps> = ({ mostRecentSignal, loading, handleRefreshData, width }) => {
  const { t } = useTranslation();

  const header = useMemo((): JSX.Element => {
    return (
      <div className="d-flex justify-content-between align-items-center gap-2 w-100">
        {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.signalStrength)}
        {loading ? <Spinner variant="primary" size="sm" /> : <FontAwesomeIcon icon={faRefresh} size="lg" color="#1677ff" onClick={handleRefreshData} className="cursor-pointer" />}
      </div>
    );
  }, [handleRefreshData, loading, t]);

  const content = useMemo((): JSX.Element => {
    return mostRecentSignal ? (
      <div className="d-flex justify-content-center align-items-center gap-2">
        <div className="d-flex flex-column justify-content-center align-items-center gap-1">
          <div>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.mostRecent)}:</div>
          <div>
            {DateHelper.getDateFromTimestampWithFormat(Number(mostRecentSignal.dateTime), "MMM DD YYYY hh:mm A")}({mostRecentSignal?.technologyType})
          </div>
          <div className="d-flex justify-content-center align-items-center gap-1">
            <SignalComponent signalQuality={mostRecentSignal?.rssiSignalQuality} width="50" height="50" />
            <span>{t(mostRecentSignal?.rssiSignalQuality.toLowerCase())}</span>
          </div>
        </div>
      </div>
    ) : (
      <span>N/A</span>
    );
  }, [mostRecentSignal, t]);

  return <MetricCard header={header} data={content} width={width} />;
};
