import { SoftwareTabContext, SoftwareResponse } from "../../../context/softwareTab/SoftwareTabContext";
import { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { DateHelper } from "@tnso/shared";
import { paginationSizeChangerContext } from "src/contexts/paginationSizeChanger/paginationSizeChangerContext";
import type { ColumnType } from "antd/es/table";
import { AuthService } from "src/app/portal-app/service/auth/AuthService";

interface SoftwareTableReturn {
  columns: ColumnType<SoftwareResponse>[];
  softwareData: SoftwareResponse[];
  softwareDataPaginated: SoftwareResponse[];
  currentPage: number;
  handleChangePage: (page: number) => Promise<void>;
  handleLoadData: (filters?: SoftwareResponse) => Promise<void>;
  skeletonColumns: ColumnType<SoftwareResponse>[];
  handlePaginationSizeChange: (size: number) => Promise<void>;
  paginationSize: number;
}
export const useTableConfig = (): SoftwareTableReturn => {
  const [softwareData, setSoftwareData] = useState<SoftwareResponse[]>([]);
  const [softwareDataPaginated, setSoftwareDataPaginated] = useState<SoftwareResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const context = useContext(SoftwareTabContext);
  const { deviceName } = useParams();
  const userTimezone = useMemo(() => {
    const userData = JSON.parse(localStorage.getItem("user") ?? "{}");
    return userData.timeZone;
  }, []);
  const { paginationSize, setPaginationSize } = paginationSizeChangerContext();

  const dateFormatted = useCallback((date: string): string => {
    return date.replace(",", " ").replace("/", "-").replace("/", "-");
  }, []);

  const columns: ColumnType<SoftwareResponse>[] = useMemo(
    (): ColumnType<SoftwareResponse>[] => [
      {
        dataIndex: "software",
        key: "software",
        title: "software",
        sorter: false,

        render: (cell: string, row: SoftwareResponse): string => (row.software ? row.software : "-")
      },
      {
        dataIndex: "deviceVersion",
        key: "deviceVersion",
        title: "deviceVersion",
        sorter: false,
        render: (cell: string, row: SoftwareResponse): string => (row.deviceVersion ? row.deviceVersion : "-")
      },
      {
        dataIndex: "instalationDate",
        key: "instalationDate",
        title: "instalationDate",
        sorter: false,
        render: (cell: string, row: SoftwareResponse): string => (row.installedOn ? dateFormatted(DateHelper.convertTimestampToBrowserCulture(row.installedOn, userTimezone)) : "-")
      },
      {
        dataIndex: "currentVersion",
        key: "currentVersion",
        sorter: false,
        title: "currentVersion",
        render: (cell: string, row: SoftwareResponse): string => (row.currentVersion ? row.currentVersion : "-")
      }
    ],
    [dateFormatted, userTimezone]
  );

  const skeletonColumns: ColumnType<SoftwareResponse>[] = [
    {
      dataIndex: "software",
      key: "software",
      title: "software",
      sorter: false
    },
    {
      dataIndex: "deviceVersion",
      key: "deviceVersion",
      title: "deviceVersion",
      sorter: false
    },
    {
      dataIndex: "installedOn",
      key: "installedOn",
      title: "installedOn",
      sorter: false
    },
    {
      dataIndex: "currentVersion",
      key: "currentVersion",
      sorter: false,
      title: "currentVersion"
    }
  ];

  const handleLoadData = useCallback(async (): Promise<void> => {
    if (deviceName) {
      const response = await context.getSoftwares(deviceName);
      if (response) {
        setSoftwareData(response);
        setSoftwareDataPaginated(response.slice(0, paginationSize));
      }
    }
  }, [context, deviceName, paginationSize]);

  const handleChangePage = useCallback(
    async (page: number): Promise<void> => {
      setSoftwareDataPaginated(softwareData.slice((page - 1) * paginationSize, page * paginationSize));
      setCurrentPage(page);
    },
    [paginationSize, softwareData]
  );

  const handlePaginationSizeChange = useCallback(
    async (size: number): Promise<void> => {
      setPaginationSize(size);
      setSoftwareDataPaginated(softwareData.slice(0, size));
      await AuthService.setUserPreferences({ PREFERED_PAGE_SIZE: size });
    },
    [setPaginationSize, softwareData]
  );

  return {
    columns,
    softwareData,
    softwareDataPaginated,
    currentPage,
    handleChangePage,
    handleLoadData,
    skeletonColumns,
    handlePaginationSizeChange,
    paginationSize
  };
};
