import React, { useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";

import { useAsyncCall } from "libraries/shared/src/lib/hooks/use-async-call-shared";
import { DeviceDetailCard } from "src/app/monitoring/components/dhi/tabs/details/device-detail-card";
import { SIMSCards } from "src/app/monitoring/components/dhi/tabs/details/sims-cards";
import { DHIContext } from "src/app/monitoring/context/dhi-context";
import { IDeviceMetrics, ISIMDetail, IInterfaceMetrics } from "src/app/monitoring/models/devices/dhi/dhi";
import { DeviceDetail } from "src/app/monitoring/models/devices/devices";
import { DeviceMetrics } from "src/app/monitoring/components/dhi/tabs/details/device-metrics";
import "../../../components/dhi/tabs/details/device-health-information.scss";
import { InterfaceMetricsTable } from "src/app/monitoring/components/dhi/tabs/details/interface-metrics-table";
import { DeviceLocationMap } from "src/app/monitoring/components/dhi/tabs/details/device-location";
import { AddressCard } from "src/app/monitoring/components/dhi/tabs/details/address-card";
import { CellularSignal } from "src/app/monitoring/models/devices/cellular/cellularSignal";
import { store } from "src/app/monitoring/store/store";
import { store as storeMobx } from "src/app/portal-app/store/StoreMobx";
import { Tabs } from "../dhi";
import Loader from "src/layouts/loader/Loader";
import { DeviceInfoCard } from "src/app/monitoring/components/dhi/tabs/details/DeviceInfoCard";
import { TNSCard } from "@tns/ui-components";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "src/app/monitoring/utils/const/translation";
import { PosAtmInfoCard } from "src/app/monitoring/components/dhi/tabs/details/PosAtmInfoCard";

export interface DetailsProps {
  setTabSelected: (tabs: Tabs) => void;
}

export const Details: React.FC<DetailsProps> = observer(({ setTabSelected }) => {
  const { deviceName } = useParams();
  const { detail } = storeMobx.device;
  const { getMetrics, getSIMSDetails, getDeviceDetails, getInterfaceMetrics } = useContext(DHIContext);
  const { loadMostRecentSignal } = store.device.cellularSignal;
  const [simsDetails, setSimsDetails] = useState<ISIMDetail[]>();
  const [deviceDetails, setDeviceDetails] = useState<DeviceDetail>();
  const [deviceMetrics, setDeviceMetrics] = useState<IDeviceMetrics>();
  const [interfaceMetrics, setInterfaceMetrics] = useState<IInterfaceMetrics[]>();
  const [mostRecentSignal, setMostRecentSignal] = useState<CellularSignal>();
  const [loadingSignal, setLoadingSignal] = useState<boolean>(false);
  const { t } = useTranslation();

  const loadData = useCallback(async (): Promise<void> => {
    if (deviceName) {
      const [device, sims, metrics, interfaceMetrics] = await Promise.all([
        getDeviceDetails(deviceName),
        getSIMSDetails(deviceName),
        getMetrics(deviceName),
        getInterfaceMetrics(deviceName)
      ]);
      setDeviceDetails(device);
      setSimsDetails(sims);
      setDeviceMetrics(metrics);
      setInterfaceMetrics(interfaceMetrics);
      await detail.loadData(deviceName);
    }
  }, [deviceName]);

  const handleRefreshData = useCallback(async (): Promise<void> => {
    setLoadingSignal(true);
    if (deviceName) {
      const response = await loadMostRecentSignal(deviceName);
      setMostRecentSignal(response);
    }
    setLoadingSignal(false);
  }, [deviceName]);

  const loader = useAsyncCall(loadData, [], storeMobx.shared.getTimeToAutoRefresh());

  return loader.completed ? (
    <div className="container-dhi d-flex flex-column gap-2">
      <div className="d-flex flex-column w-100 gap-2">
        <div className="d-flex w-100 gap-2">
          <div className="w-25 d-flex flex-column gap-2">{deviceDetails && <DeviceDetailCard model={deviceDetails.model} vendor={deviceDetails.manufacturer} />}</div>
          <div className="w-75 d-flex flex-column gap-2">
            {simsDetails && (
              <div onClick={(): void => setTabSelected(Tabs.Wireless)} className="cursor-pointer">
                <SIMSCards sims={simsDetails} />
              </div>
            )}
          </div>
        </div>
        <div>
          {deviceMetrics && (
            <DeviceMetrics
              deviceMetrics={deviceMetrics}
              mostRecentSignal={mostRecentSignal}
              handleRefreshData={handleRefreshData}
              deviceDetail={deviceDetails}
              loadingSignal={loadingSignal}
            />
          )}
        </div>
      </div>
      <InterfaceMetricsTable interfaceMetrics={interfaceMetrics} getInterfaceMetrics={getInterfaceMetrics} />
      <div className="d-flex w-100 gap-2 mt-4">
        <DeviceLocationMap deviceDetail={deviceDetails} />
        <TNSCard className="w-50">
          <div className="d-flex gap-2 mt-2 mb-4">
            <span>
              {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICECONFIGURATION.operationalStatus)}: {t(deviceDetails?.operationalStatus)}
            </span>
          </div>
          <DeviceInfoCard deviceDetail={deviceDetails} />
          <PosAtmInfoCard deviceDetail={deviceDetails} />
          <AddressCard configurationData={deviceDetails} />
        </TNSCard>
      </div>
    </div>
  ) : (
    <Loader />
  );
});
