import { utils } from "xlsx";
import { ExportXLSLHelper, TypeFile } from "../helpers/ExportXLSLHelper";
import { QueryParams } from "../models/queryParams";

/**
 * @description the hook to export the data table
 * @template T the type of the data
 * @template Q the type of the data to export
 * @param {number} totalRecords the total records of the table without filters
 * @param {number} pageSize the number of records per page
 * @param {function} methodToFetchData the method to fetch the data
 * @param {function} builderParams the method to build the params
 * @param {string} fileName the name of the file to export
 * @param {function} setTotalSheets the method to set the total sheets
 * @param {function} setShowModal the method to set the show modal
 * @param {function} setCurrentSheet the method to set the current sheet
 * @param {function} mapperMethod the method to map the data
 * @returns {void}
 */

type FetchDataFunction<T, R> = ((params?: T) => Promise<R | undefined>) | ((specialParams: string, params?: T) => Promise<R | undefined>);

export function useExportDataTable<Q extends object, R extends object, T extends QueryParams>(
  totalRecords: number,
  pageSize: number,
  fetchData: FetchDataFunction<T, R>,
  params: T,
  fileName = "export",
  mapperMethod?: (data?: R) => Q[],
  isSpecialParams?: boolean,
  specialParams?: string
): () => Promise<void> {
  const handleExport = async (): Promise<void> => {
    const totalRequest = Math.ceil(totalRecords / pageSize);
    const totalPages = Array.from(Array(totalRequest).keys());
    const wb = utils.book_new();
    let dataToExport: Q[] = [];
    for (const [index] of totalPages.entries()) {
      let data: R | undefined;
      if (isSpecialParams && specialParams?.length) {
        data = await (fetchData as (specialParams: string, params: T) => Promise<R | undefined>)(specialParams, {
          ...params,
          startAtRecord: index * pageSize,
          recordsPerPage: params.recordsPerPage ?? pageSize
        });
      } else {
        data = await (fetchData as (params: T) => Promise<R | undefined>)({
          ...params,
          startAtRecord: index * pageSize,
          recordsPerPage: params.recordsPerPage ?? pageSize
        });
      }
      if (data) {
        dataToExport = mapperMethod ? [...dataToExport, ...mapperMethod(data)] : [...dataToExport, ...(data as Q[])];
      }
      if (index === totalPages.length - 1) {
        ExportXLSLHelper.addSheetToBook<Q>(wb, dataToExport, 1);
        ExportXLSLHelper.exportToXLSL(wb, fileName, TypeFile.CSV);
        return;
      }
    }
  };
  return handleExport;
}
