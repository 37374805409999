import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GapSizes, TNSContainerFluid, TNSHeader } from "@tns/ui-components";
import { useNavigate } from "react-router-dom";

export interface HeaderContainerProps {
  title: string;
  isOnGoBack?: boolean;
  children: React.ReactNode;
}

export const HeaderViewContainer: React.FC<HeaderContainerProps> = ({ title, children, isOnGoBack }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onGoBack = useCallback((): void => {
    navigate(-1);
  }, [navigate]);

  return (
    <TNSContainerFluid gap={GapSizes.med} className="containerColums">
      <div className="w-100">
        <TNSHeader title={t(title)} line breadcumb={false} onGoBack={isOnGoBack ? onGoBack : undefined} />
      </div>
      <div className="w-100">{children}</div>
    </TNSContainerFluid>
  );
};
