import React, { useMemo } from "react";
import { TNSCard } from "@tns/ui-components";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "src/app/monitoring/utils/const/translation";
import { DateHelper } from "@tnso/shared";
import { UserInfo } from "src/shared/models/auth/login/login";

export interface ConnectedLastUpdatedProps {
  lastUpdatedAt?: string;
}

export const ConnectedLastUpdated: React.FC<ConnectedLastUpdatedProps> = ({ lastUpdatedAt }) => {
  const { t } = useTranslation();
  const userInfo: UserInfo = useMemo(() => JSON.parse(localStorage.getItem("user") ?? "{}"), []);

  return lastUpdatedAt ? (
    <TNSCard className="w-100 d-flex justify-content-center align-items-center">
      <span>
        {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.lastUpdatedOn)}{" "}
        {DateHelper.convertTimestampToBrowserCulture(Number(lastUpdatedAt), userInfo?.timeZone, {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hourCycle: "h23"
        })}
      </span>
    </TNSCard>
  ) : null;
};
