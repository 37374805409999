import React, { createContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StatusCode } from "@tnso/api-core";

import { MessageHelper } from "src/app/portal-app/helpers/shared/MessageHelper";
import { TRANSLATION } from "../utils/const/translation";
import { IDeviceMetrics, ISIMDetail, IInterfaceMetrics } from "../models/devices/dhi/dhi";
import { DeviceDetailService } from "../service/device/dhi/device-detail-service";
import { DeviceDetail } from "../models/devices/devices";
import { AxiosError } from "axios";

export interface DHIContextValue {
  getDeviceDetails: (tnsDeviceName: string) => Promise<DeviceDetail | undefined>;
  getSIMSDetails: (tnsDeviceName: string) => Promise<ISIMDetail[] | undefined>;
  getMetrics: (tnsDeviceName: string) => Promise<IDeviceMetrics | undefined>;
  getInterfaceMetrics: (tnsDeviceName: string) => Promise<IInterfaceMetrics[] | undefined>;
}

export interface DHIContextProviderProps extends React.PropsWithChildren {
  someValue?: boolean;
}

const defaultValues: DHIContextValue = {
  getDeviceDetails: async () => undefined,
  getSIMSDetails: async () => undefined,
  getMetrics: async () => undefined,
  getInterfaceMetrics: async () => undefined
};

export const DHIContextProvider = ({ children }: DHIContextProviderProps): JSX.Element => {
  const { t } = useTranslation();

  const contextValues: DHIContextValue = useMemo(() => {
    return {
      getDeviceDetails: async (tnsDeviceName: string): Promise<DeviceDetail | undefined> => {
        try {
          const response = await DeviceDetailService.getDeviceDetails(tnsDeviceName);
          if (response?.data && response?.status === StatusCode.OK) {
            return response.data;
          }
        } catch (error) {
          const errorParsed = error as AxiosError;
          if (errorParsed.status !== StatusCode.NOT_FOUND) {
            MessageHelper.errorMessage(t(TRANSLATION.ERROR.upsSomethingWentWrong));
          }
        }
      },

      getSIMSDetails: async (tnsDeviceName: string): Promise<ISIMDetail[] | undefined> => {
        try {
          const response = await DeviceDetailService.getSIMSDetails(tnsDeviceName);
          if (response?.data && response?.status === StatusCode.OK) {
            return response.data;
          }
        } catch (error) {
          const errorParsed = error as AxiosError;
          if (errorParsed.status !== StatusCode.NOT_FOUND) {
            MessageHelper.errorMessage(t(TRANSLATION.ERROR.upsSomethingWentWrong));
          }
        }
      },

      getMetrics: async (tnsDeviceName: string): Promise<IDeviceMetrics | undefined> => {
        try {
          const response = await DeviceDetailService.getMetrics(tnsDeviceName);
          if (response?.data && response?.status === StatusCode.OK) {
            const updatedData = { ...response.data, cpuUsage: response.data?.cpuUsage?.map((cpu, index) => ({ ...cpu, key: index + 1 })) };
            return updatedData;
          }
        } catch (error) {
          const errorParsed = error as AxiosError;
          if (errorParsed.status !== StatusCode.NOT_FOUND) {
            MessageHelper.errorMessage(t(TRANSLATION.ERROR.upsSomethingWentWrong));
          }
        }
      },

      getInterfaceMetrics: async (tnsDeviceName: string): Promise<IInterfaceMetrics[] | undefined> => {
        try {
          const response = await DeviceDetailService.getInterfaceMetrics(tnsDeviceName);
          if (response?.data && response?.status === StatusCode.OK) {
            return response.data;
          }
        } catch (error) {
          const errorParsed = error as AxiosError;
          if (errorParsed.status !== StatusCode.NOT_FOUND) {
            MessageHelper.errorMessage(t(TRANSLATION.ERROR.upsSomethingWentWrong));
          }
        }
      }
    };
  }, []);

  return <DHIContext.Provider value={contextValues}>{children}</DHIContext.Provider>;
};

export const DHIContext = createContext(defaultValues);
