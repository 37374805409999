import React, { useMemo, useCallback } from "react";
import { TNSCard, TNSDivider, TNSMenu } from "@tns/ui-components";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../utils/const/translation";
import { GroupSelection } from "../../grouping/context/DeviceGroupingContext";
import "./device-grouping.scss";

export interface AvailableGroupsProps {
  onClick: (item: GroupSelection) => void;
}

export const AvailableGroups: React.FC<AvailableGroupsProps> = ({ onClick }) => {
  const { t } = useTranslation();

  const buildItemMenu = useMemo(() => {
    return Object.values(GroupSelection).map((key) => {
      return {
        key,
        label: <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.DETAILS[key])}</span>
      };
    });
  }, [t]);

  const handleClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event?: any & { key: GroupSelection; keyPath: string[] }) => {
      // TODO: We should export the MenuClickEventHandler type, the Menu component is mentioning that the onClick should handle this interface but it's not exported.
      if (event) {
        onClick(event.key);
      }
    },
    [onClick]
  );

  return (
    <div className="available-card-container">
      <TNSCard className="pt-3">
        <div className="d-flex justify-content-center">
          <h6>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.DETAILS.availableGroups)}</h6>
        </div>
        <TNSDivider type="horizontal" />
        <TNSMenu mode="vertical" items={buildItemMenu} onClick={handleClick} defaultSelectedKeys={[GroupSelection.MyGroups]} />
      </TNSCard>
    </div>
  );
};
