import { DateHelper } from "@tnso/shared";
import { CPUUsageMonthly, CoreColors } from "../../models/devices/hardware/hardware";
import { TRANSLATION } from "../../utils/const/translation";
import { store } from "../../../portal-app/store/StoreMobx";
import i18n from "../../../../i18n";

export class HardwareHelper {
  static coresColors = Object.values(CoreColors);

  public static formatXAxisLabel(value: string, data: CPUUsageMonthly[], isMonthly?: boolean, isDaily?: boolean): string {
    const dataItem = data && data[Number(value) - 1];
    const userTimezone = store.auth.userInfo?.timeZone;

    const month = dataItem?.month
      ? i18n.t(
          TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.UPTIME.MONTHSLEYEND[
            dataItem.month.toLowerCase() as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.UPTIME.MONTHSLEYEND
          ]
        )
      : "";

    let date = `${month} ${dataItem?.year?.toString().slice(2)}`;
    if (dataItem?.timestamp) {
      date = DateHelper.convertTimestampToBrowserCulture(
        Number(dataItem.timestamp),
        userTimezone,
        isDaily ? { hour: "numeric", minute: "numeric", hourCycle: "h23" } : { day: "2-digit", month: "2-digit", year: "numeric" }
      );
    }

    return date;
  }

  public static storageFormatYAxisLabel(value: number): string {
    return `${value} ${i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.kb)}`;
  }

  public static cpuFormatYAxisLabel(value: number): string {
    return `${value}%`;
  }
}
