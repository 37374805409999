import React, { useState } from "react";
import { TRANSLATION } from "../../utils/const/translation";
import { useTranslation } from "react-i18next";
import { useAsyncCall } from "../../../../hooks/useAsyncCallShared";
import { HeaderDrawerDhi } from "./header-drawer-dhi";
import { HeaderDrawerGrouping } from "./header-drawer-grouping";
import { DeviceGroups } from "../../models/devices/devices";
import { IDetail } from "../../store/device/detail/detail";
import { IDevice } from "../../store/device/device";
import { GroupsInformationMenu } from "./groups-information-menu";
import { TitleWithTextComponent } from "./title-with-text-component";
import { observer } from "mobx-react";
import { store } from "src/app/portal-app/store/StoreMobx";

export interface HeaderDetailDhiProps {
  // data?: DeviceDetail;
  detail: IDetail;
  deviceName?: string;
  devices: IDevice;
  color: string;
  tnsDeviceName?: string;
}

export const HeaderDetailDhi: React.FC<HeaderDetailDhiProps> = observer(({ detail, deviceName, devices, color, tnsDeviceName }) => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const { data } = store.device.detail;
  const [drawerDeviceGroupsOpen, setDrawerDeviceGroupsOpen] = useState<boolean>(false);
  const [paginatedGroups, setPaginatedGroups] = useState<DeviceGroups[] | undefined>([]);
  const maxIndexGroups = 3;

  const loaderGroups = useAsyncCall(async () => {
    if (!drawerDeviceGroupsOpen) {
      const response = await detail.loadGroups(deviceName);
      if (response) {
        setPaginatedGroups(response.slice(0, 10));
      }
    }
  }, [drawerDeviceGroupsOpen]);

  const openDrawer = (): void => {
    setDrawerOpen(true);
  };

  const closeDrawer = (): void => {
    setDrawerOpen(false);
  };

  const closeDrawerDeviceGroups = (): void => {
    setDrawerDeviceGroupsOpen(false);
  };

  return data || detail?.data ? (
    <div className="d-flex justify-content-center mt-1 header-container w-100">
      <div className="d-flex justify-content-between align-items-center gap-2 w-100">
        <div className="w-100 d-flex justify-content-start pe-5 align-items-center gap-5">
          <TitleWithTextComponent title={t(TRANSLATION.SIDEBAR.SUPPORT.CHANGEMANAGMENT.companyName)} text={data?.knownAs ?? detail.data?.knownAs} titleColor="#2677EB" />
          <TitleWithTextComponent
            title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.DETAIL.HEADER.customerDeviceName)}
            text={data?.customerDeviceName ?? detail.data?.customerDeviceName}
            titleColor="#2677EB"
            editable
            onEdit={openDrawer}
          />
          <TitleWithTextComponent
            title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.DETAIL.HEADER.tnsServiceStatus)}
            text={t(
              TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICESTATUS[
                (data?.connectivityStatus ?? detail.data?.connectivityStatus) as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICESTATUS
              ]
            )}
            titleColor="#2677EB"
            textColor={color}
          />
          <TitleWithTextComponent title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICECONFIGURATION.acna)} text={data?.acna ?? detail.data?.acna} titleColor="#2677EB" />
          <TitleWithTextComponent
            title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.DETAIL.HEADER.tnsDeviceName)}
            text={data?.tnsDeviceName ?? detail.data?.tnsDeviceName}
            titleColor="#2677EB"
          />
        </div>
        <div className="me-2">
          <GroupsInformationMenu detail={detail} paginatedGroups={paginatedGroups} setDrawerDeviceGroupsOpen={setDrawerDeviceGroupsOpen} maxIndexGroups={maxIndexGroups} />
        </div>
      </div>
      <HeaderDrawerDhi detail={detail} openDrawer={openDrawer} data={data} drawerOpen={drawerOpen} closeDrawer={closeDrawer} tnsDeviceName={tnsDeviceName} devices={devices} />
      <HeaderDrawerGrouping
        loader={loaderGroups}
        paginatedGroups={paginatedGroups}
        setPaginatedGroups={setPaginatedGroups}
        closeDrawerDeviceGroups={closeDrawerDeviceGroups}
        drawerDeviceGroupsOpen={drawerDeviceGroupsOpen}
        detail={detail}
        tnsDeviceName={tnsDeviceName}
      />
    </div>
  ) : null;
});
