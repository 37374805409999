import React, { useState } from "react";
import { CreateGroup } from "../../../components/device/device-grouping/create-device-group";
import { DeviceGroupTransferList } from "src/app/portal-app/components/device/device-grouping/device-group-transfer-list";
import { TNSDivider } from "@tns/ui-components";

export function CreateDeviceGrouping(): JSX.Element {
  const [secondStep, setSecondStep] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>("");
  const [groupColor, setGroupColor] = useState<string>("#ffffff");

  return (
    <div>
      <CreateGroup secondStep={secondStep} setSecondStep={setSecondStep} setGroupName={setGroupName} setGroupColor={setGroupColor} />
      <div className="d-flex flex-column gap-3">
        <TNSDivider type="horizontal" />
        <DeviceGroupTransferList groupName={groupName} groupColor={groupColor} transferListEnable={secondStep} />
      </div>
    </div>
  );
}
