import React, { createContext, useMemo } from "react";
import { AssociatedDevicesInGroupResponse } from "../../../interfaces/devices/group/available-groups";
import { GroupService } from "../../../service/device/GroupService";
import { MessageHelper } from "src/app/portal-app/helpers/shared/MessageHelper";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../utils/const/translation";
import { QueryParams } from "src/app/administration/models/query-params.model";

export interface DeviceGroupingContextValue {
  /**
   * Get list of associated devices by group
   * @param groupName required - The name of the group to get associated devices.
   * @param tnsDeviceName optional - The name of the TNS device to get associated devices.
   * @param recordsPerPage optional - The number of records to return per page. Default is 100.
   * @param startAt optional - The record number to start at. Default is 0.
   * @returns list of associated devices by group
   */
  getAssociatedDevices: (groupName: string, params?: QueryParams) => Promise<AssociatedDevicesInGroupResponse | undefined>;
}

export interface DeviceGroupingContextProviderProps extends React.PropsWithChildren {
  someValue?: boolean;
}

const defaultValues: DeviceGroupingContextValue = {
  getAssociatedDevices: async () => undefined
};

export enum GroupSelection {
  All = "allGroups",
  MyGroups = "myGroups"
}

export const DeviceGroupingContextProvider = ({ children }: DeviceGroupingContextProviderProps): JSX.Element => {
  const { t } = useTranslation();

  const contextValues: DeviceGroupingContextValue = useMemo(() => {
    return {
      getAssociatedDevices: async (groupName: string, params?: QueryParams): Promise<AssociatedDevicesInGroupResponse | undefined> => {
        try {
          const response = await GroupService.getAssociatedDevicesByGroup(groupName, params);
          if (response?.data) {
            return response.data;
          }
        } catch (error) {
          console.error(error);
          MessageHelper.errorMessage(t(TRANSLATION.ERROR.upsSomethingWentWrong));
        }
      }
    };
  }, []);

  return <DeviceGroupingContext.Provider value={contextValues}>{children}</DeviceGroupingContext.Provider>;
};

export const DeviceGroupingContext = createContext(defaultValues);
