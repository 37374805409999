import React, { useCallback, useMemo, useState } from "react";
import { ConnectedDevice, ConnectedDevicesParams, ConnectedDevicesResponse, ConnectedDevicesToExport } from "../../../../models/connected-devices/connected-devices";
import { useTableConnectedDevicesConfig } from "../../../../store/device/connected-devices/use-table-config";
import { AsyncApiCallStatus } from "src/shared/hooks/useAsyncCallShared";
import { ColumnsType } from "antd/lib/table";
import { TranslationHelper } from "src/shared/helpers/TranslationHelper";
import { ApiCore } from "@tnso/api-core";
import { store } from "src/app/monitoring/store/store";
import { store as sidebar } from "../../../../../portal-app/store/StoreMobx";
import { useNavigate, useParams } from "react-router-dom";
import { ScaleSize } from "@tnso/ui-components";
import { TRANSLATION } from "src/app/monitoring/utils/const/translation";
import { DrawerContent } from "./drawer-content";
import { useTranslation } from "react-i18next";
import { SorterResult } from "antd/lib/table/interface";
import { useExportDataTable } from "../../../../../../shared/hooks/use-export-data-table";
import { MapperHelper } from "../../../../../../shared/helpers/MapperHelper";
import { MenuKeys } from "../../../../../../layouts/sidebars/vertical/Sidebar";
import { observer } from "mobx-react";
import { TNSDrawer, TNSTable } from "@tns/ui-components";
import { AuthService } from "../../../../../../app/portal-app/service/auth/AuthService";
import { paginationSizeChangerContext } from "../../../../../../contexts/paginationSizeChanger/paginationSizeChangerContext";

export interface IConnectedDevicesTable {
  data?: ConnectedDevicesResponse;
  setData: (data: ConnectedDevicesResponse | undefined) => void;
  loader: AsyncApiCallStatus<void>;
  lanPorts?: string[];
  handleSearch: (filter: string, keyFilter: string) => void;
  prevParams: ConnectedDevicesParams;
  handleSort: (sorter: SorterResult<ConnectedDevice>) => Promise<void>;
  isTableToMenu?: boolean;
  setPrevParams: (params: ConnectedDevicesParams) => void;
  singleDevice?: boolean;
}

export const ConnectedDevicesTable: React.FC<IConnectedDevicesTable> = observer(
  ({ setPrevParams, data, setData, loader, lanPorts, handleSearch, prevParams, handleSort, isTableToMenu, singleDevice }) => {
    const { t } = useTranslation();
    const { deviceName } = useParams();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [isLoadingExport, setIsLoadingExport] = useState(false);
    const [macAddress, setMacAddress] = useState<string>("");
    const [connectedDeviceName, setConnectedDeviceName] = useState<string>("");
    const { paginationSize, paginationPageSizeOptions, setPaginationSize } = paginationSizeChangerContext();

    const handleChangePage = useCallback(
      async (page: number): Promise<void> => {
        const params = ApiCore.builderQueryParams<ConnectedDevicesParams>(
          {
            currentPage: page - 1,
            recordsPerPage: paginationSize
          },
          prevParams
        );
        if (deviceName || isTableToMenu) {
          setPrevParams(params);
          setCurrentPage(page);
        }
      },
      [store.device.connectedDevices, deviceName, prevParams, paginationSize]
    );

    const handleSelectRow = useCallback(
      async (row: ConnectedDevice): Promise<void> => {
        if (isTableToMenu) {
          sidebar.shared.setMenuItemSelected(MenuKeys.Devices);
          navigate(`/monitoring/devices/device-detail/${row.tnsDeviceName}`);
        }
      },
      [navigate, isTableToMenu]
    );

    const editConnectedDevices = useCallback(
      (connectedDeviceName: string, macAddress: string) => {
        if (connectedDeviceName && macAddress) {
          setConnectedDeviceName(connectedDeviceName);
          setMacAddress(macAddress);
          setOpen(true);
        }
      },
      [deviceName]
    );

    const sorting = useCallback(
      async (
        //  eslint-disable-next-line
        pagination: any,
        //  eslint-disable-next-line
        _: Record<string, string[] | null>,
        //  eslint-disable-next-line
        sorter: SorterResult<ConnectedDevice>
      ) => {
        await handleSort(sorter);
        setCurrentPage(1);
      },
      [handleSort, loader.loading, setCurrentPage]
    );

    const { columns, skeletonColumns, columnsMenu, skeletonColumnsMenu } = useTableConnectedDevicesConfig({ handleSearch, editConnectedDevices, lanPorts });
    const handleExportData = useExportDataTable<ConnectedDevicesToExport, ConnectedDevicesResponse, ConnectedDevicesParams>(
      data?.totalRecords ?? 0,
      paginationSize,
      singleDevice ? store.device.connectedDevices.loadDataByTnsDeviceName : store.device.connectedDevices.loadData,
      prevParams,
      deviceName
        ? `${deviceName}_${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.connectedDevices)}`
        : t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.connectedDevices),
      isTableToMenu ? MapperHelper.mapConnectedDevicesByMenuToExport : MapperHelper.mapConnectedDevicesToExport,
      true,
      singleDevice ? deviceName : connectedDeviceName
    );

    const handlePaginationSizeChange = useCallback(
      async (size: number) => {
        if (size !== paginationSize) {
          setCurrentPage(1);
          setPaginationSize(size);
          const params = ApiCore.builderQueryParams({ currentPage: 0, recordsPerPage: size }, prevParams);
          if (deviceName || isTableToMenu) {
            setPrevParams(params);
          }
          await AuthService.setUserPreferences({ PREFERED_PAGE_SIZE: size });
        }
      },
      [paginationSize, setPaginationSize, prevParams, deviceName, isTableToMenu, setPrevParams]
    );

    const handleExport = useCallback(async (): Promise<void> => {
      setIsLoadingExport(true);
      await handleExportData();
      setIsLoadingExport(false);
    }, [handleExportData, prevParams, connectedDeviceName]);

    const grid = useMemo(
      () => (
        <TNSTable
          columns={
            loader?.loading
              ? TranslationHelper.columnsTranslation((isTableToMenu ? skeletonColumnsMenu : skeletonColumns) as ColumnsType[])
              : TranslationHelper.columnsTranslation((isTableToMenu ? columnsMenu : columns) as unknown as ColumnsType[])
          }
          currentPage={currentPage}
          handleSelectRow={handleSelectRow}
          dataSource={data?.connectedDevices}
          totalRecords={data?.totalRecords}
          isLoading={loader?.loading}
          handleGoToPage={handleChangePage}
          recordsTranslated={[t("records"), t("of")]}
          onChange={sorting}
          showPagination={data?.connectedDevices?.length && data.connectedDevices.length > 0 ? true : false}
          onExport={handleExport}
          exportLabel={t("export")}
          emptyMessage={t(TRANSLATION.SHARED.DATATABLE.noDataAvailable)}
          isLoadingExport={isLoadingExport}
          showPaginationSizeChanger
          paginationPageSizeTextTranslated={t("resultsPerPage")}
          pageSize={paginationSize}
          paginationPageSizeOptions={paginationPageSizeOptions}
          onShowPaginationSizeChange={handlePaginationSizeChange}
          scroll={{ x: "max-content" }}
        />
      ),
      [
        data?.connectedDevices,
        data?.totalRecords,
        data?.emptyMessage,
        loader?.loading,
        loader,
        currentPage,
        handleChangePage,
        t,
        isLoadingExport,
        handleExport,
        sorting,
        isTableToMenu,
        handleSelectRow,
        handleSort
      ]
    );

    return (
      <>
        {grid}
        <TNSDrawer
          open={open}
          title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.editConnectedDeviceName)}
          sizeType={ScaleSize.md}
          onClose={(): void => setOpen(false)}>
          <DrawerContent connectedDeviceName={connectedDeviceName} macAddress={macAddress} setOpen={setOpen} setData={setData} params={prevParams} />
        </TNSDrawer>
      </>
    );
  }
);
