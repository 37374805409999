import { makeAutoObservable } from "mobx";
import { User as UserModel, UserResponse } from "../../models/user.model";
import { UserService } from "../../services/user/user-service";
import { TRANSLATION } from "../../utils/translation-keys";
import i18n from "../../../../translations/i18n";
import { MessageHelper } from "../../../portal-app/helpers/shared/MessageHelper";

export interface IUser {
  data?: UserResponse;
  userSelected?: UserModel;
  userEntityProfile?: string;

  loadUserByUsername: (username: string) => Promise<void>;
}

// eslint-disable-next-line
// @ts-ignore
class User implements IUser {
  data?: UserResponse = undefined;
  userSelected?: UserModel = undefined;
  userEntityProfile?: string = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  loadUserByUsername = async (username: string): Promise<void> => {
    try {
      const response = await UserService.getByUsername(username);
      if (response?.data) {
        this.userSelected = response.data;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.ERROR.errorLoadingUsers));
    }
  };
}

const user = new User();

export default user;
