export const TRANSLATION = {
  CONTACT: {
    emptyContactGrid: "emptyContactGrid",
    Maintenance: "Maintenance",
    Incident: "Incident",
    Escalation: "Escalation",
    Billing: "Billing",
    Business: "Business",
    Facility: "Facility",
    Other: "Other",
    action: "action",
    type: "type",
    firstName: "firstName",
    lastName: "lastName",
    email: "email",
    phone: "phone"
  },
  TABLE: {
    emptyTable: "emptyTable"
  },
  PROFILE: {
    profile: "profile",
    profileCompany: "profileCompany",
    profileEntity: "profileEntity"
  },
  DIRECTORY: {
    directory: "directory"
  },
  BUTTONS: {
    exportButton: "exportButton"
  },
  MODAL: {
    errorLoadingCompanyProfiles: "errorLoadingCompanyProfiles",
    loginSuccess: "loginSuccess",
    errorLoadingContacts: "errorLoadingContacts"
  },
  ERROR: {
    errorLoadingUsers: "errorLoadingUsers"
  }
};
