import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../utils/const/translation";
import { NotificationContext } from "src/contexts/notifications/notifications-context";
import { TNSDivider } from "@tns/ui-components";

export interface NotificationsContentProps {
  title?: string;
  message?: string;
}

export const NotificationContent: React.FC<NotificationsContentProps> = ({ title, message }) => {
  const { t } = useTranslation();
  const context = useContext(NotificationContext);

  return (
    <div className="notification-content-container d-flex flex-column w-100">
      {title && message ? (
        <>
          <div className="notification-header">
            <TNSDivider orientation="left" type="horizontal" plain>
              <span>{title}</span>
            </TNSDivider>
          </div>
          <div className="notification-body text-start">
            <span>{message}</span>
          </div>
        </>
      ) : (
        <div className="notification-body d-flex align-items-center justify-content-center">
          <span>{context.notifications?.notifications?.length ? t(TRANSLATION.NOTIFICATIONS.pleaseSelectAmItemToReadIt) : ""}</span>
        </div>
      )}
    </div>
  );
};
