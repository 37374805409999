import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/loader/Loadable";
import { DeviceDetail } from "../app/portal-app/views/menu/monitoring/devices/device-detail/device-detail";
import UserAdministrarion from "../app/portal-app/views/menu/administration/userAdministration/UserAdministration";
import { ViewDeviceGrouping } from "src/app/portal-app/containers/devices/device-grouping/view-device-grouping";
import { CreateDeviceGrouping } from "src/app/portal-app/containers/devices/device-grouping/create-device-grouping";
import { EditDeviceGrouping } from "src/app/portal-app/containers/devices/device-grouping/edit-device-grouping";
import SwapDeviceContext from "src/app/self-managed/context/swap-devices-context";
import { HeaderViewContainer } from "src/shared/components/header-view-container";
import ExternalApiContext from "../app/administration/context/tnsonline-api/external-api-context";
import DestinationListsContextProvider from "src/app/content-filtering/context/destination-lists/destination-lists-context";
import { Roles } from "@tnso/roles-and-permissions";
import { AuthHelper } from "src/app/portal-app/helpers/auth/AuthHelper";
import IncidentManagement from "src/app/administration/containers/incident-management/view-incident-management/incident-management";
import IncidentManagementContextProvider from "src/app/administration/context/incident-management/incident-management-context";
import { IncidentManagementEdit } from "src/app/administration/containers/incident-management/view-incident-management/incident-management-edit-view";

const FullLayout = Loadable(lazy(() => import("../layouts/FullLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")));
const Profile = Loadable(lazy(() => import("../layouts/header/user/profile/Profile")));
const DeviceGroupingFiltersContext = Loadable(lazy(() => import("../app/portal-app/components/filters/context/DeviceGroupingFiltersContext")));
const CustomerAccountManagerContextProvider = Loadable(lazy(() => import("../app/administration/context/cam/customer-account-manager-context")));
const UserMFAStatusContext = Loadable(lazy(() => import("../app/portal-app/context/user-mfa-context")));
// Devices
const DevicesSection = Loadable(lazy(() => import("../app/portal-app/views/menu/monitoring/devices/device-section")));
const MembersSection = Loadable(lazy(() => import("../app/portal-app/views/menu/monitoring/members/MembersConnectivity")));
const ConnectedDevices = Loadable(lazy(() => import("../app/monitoring/containers/connected-devices/connected-devices-menu")));
// Administration
const CompanyAdministration = Loadable(lazy(() => import("../app/portal-app/views/menu/administration/companyAdministration/CompanyProfile")));
const UserProfile = Loadable(lazy(() => import("../app/portal-app/views/menu/administration/userAdministration/UserProfile")));
const Administration = Loadable(lazy(() => import("../app/portal-app/views/menu/administration/companyAdministration/Administration")));
const ViewCamProfile = Loadable(lazy(() => import("../app/administration/containers/cam-profiles/view-cam-profile/view-cam-profile")));
const ViewCreateEditCamProfile = Loadable(lazy(() => import("../app/administration/containers/cam-profiles/view-create-edit-cam-profile/view-create-edit-cam-profile")));

const ViewNotificationsCenter = Loadable(lazy(() => import("../app/portal-app/containers/notification&alerts/notification-center")));

// Availability Reports
const AvailabilityReports = Loadable(lazy(() => import("../app/portal-app/views/menu/coinReports/availabilityReports/AvailabilityReports")));
// App Administration
const ViewUserRoles = Loadable(lazy(() => import("../app/administration/containers/user-roles/view-user-roles/view-user-role")));
const CreateUserRoles = Loadable(lazy(() => import("../app/administration/containers/user-roles/create-user-roles/create-user-roles")));
const EditUserRoles = Loadable(lazy(() => import("../app/administration/containers/user-roles/edit-user-roles/edit-user-roles")));
const ExternalApi = Loadable(lazy(() => import("../app/administration/containers/tnsonline-api/view-tnsonline-api/external-api")));
// Self - Service Tools
const TNSLinkProvisioning = Loadable(lazy(() => import("../app/self-managed/containers/tns-link-provisioning/tns-link-provisioning")));
const SwapDevices = Loadable(lazy(() => import("../app/self-managed/containers/swap-devices/swap-devices")));
// Support
const ChangeSection = Loadable(lazy(() => import("../app/portal-app/views/menu/support/changeManagement/ChangeSection")));
const ChangeDetails = Loadable(lazy(() => import("../app/portal-app/views/menu/support/changeManagement/changeDetail/ChangeDetail")));
// Help
const ContactUs = Loadable(lazy(() => import("../app/portal-app/views/menu/help/ContactUs")));
const UserGuide = Loadable(lazy(() => import("../app/help/containers/user-guide")));
/** *** Auth Pages ****/
const Login = Loadable(lazy(() => import("../app/portal-app/views/auth/Login")));

const ForgotPassword = Loadable(lazy(() => import("../app/portal-app/views/auth/ForgotPassword")));
const ResetPassword = Loadable(lazy(() => import("../app/portal-app/views/auth/ResetPassword")));
const Forbidden = Loadable(lazy(() => import("../app/portal-app/views/auth/error/Forbidden")));
const NotFound = Loadable(lazy(() => import("../app/portal-app/views/auth/error/NotFound")));
const UserDisabled = Loadable(lazy(() => import("../app/portal-app/views/auth/userLocked/userDisabled")));
const UserLocked = Loadable(lazy(() => import("../app/portal-app/views/auth/userLocked/userLocked")));
const DeviceAddressChange = Loadable(lazy(() => import("../app/portal-app/components/device/details/DeviceAddressChange")));

const DeviceProfiles = Loadable(lazy(() => import("../app/administration/containers/entity-profiles/device-profile/device-profile")));

const EditDeviceProfile = Loadable(lazy(() => import("../app/administration/containers/entity-profiles/edit-profile/edit-profile")));

const CreateDeviceProfile = Loadable(lazy(() => import("../app/administration/containers/entity-profiles/create-profile/create-profile")));

const ContactForm = Loadable(lazy(() => import("../app/administration/components/contact/contact-form")));

const ContactDirectory = Loadable(lazy(() => import("../app/contact-app/containers/contact-directory-container")));
// DestinationLists
const DestinationListContainer = Loadable(lazy(() => import("../app/content-filtering/containers/destination-lists/destination-lists-container")));
const ViewDestinationListContainer = Loadable(lazy(() => import("../app/content-filtering/containers/destination-lists/view-destination-list-container")));
// Core-Reports
const CoreReportsContainer = Loadable(lazy(() => import("../app/content-filtering/containers/core-reports/core-reports-container")));
// TODO: We should validate that it isn't an invalid/expired token.

export interface RoutePath {
  path: string;
  name?: string;
  element: React.ReactNode;
  children?: RoutePath[];
  exact?: boolean;
}

export const redirectByRole = (role: string, featureList: string[]): string => {
  console.info("Validating role: ", role);
  switch (role) {
    case Roles.SuperUser:
    case Roles.CAM:
      console.info("Redirecting to company profile");
      return "/administration/company";
    case Roles.Admin:
    case Roles.Basic:
      console.info("Redirecting to devices");
      return "/monitoring/devices";
    default:
      console.info("Redirecting to 403 - feature list", featureList.length);
      return featureList.length > 0 ? AuthHelper.getRouteByRedirect(featureList) : "/auth/403";
  }
};

/** ***Routes******/
export const ThemeRoutes = (token: string): RoutePath[] => {
  const { role, featureList } = AuthHelper.getAuthByToken(token);
  console.info("Role: ", role, "Feature list: ", featureList);

  return [
    {
      path: "/",
      element: <FullLayout />,
      children: [
        { path: "/", name: "Home", element: <Navigate to={redirectByRole(role, featureList)} /> },
        {
          path: "/monitoring/devices",
          name: "devices",
          exact: true,
          element: (
            <DeviceGroupingFiltersContext>
              <HeaderViewContainer title="devices">
                <DevicesSection />
              </HeaderViewContainer>
            </DeviceGroupingFiltersContext>
          )
        },
        {
          path: "/monitoring/members",
          name: "members",
          exact: true,
          element: (
            <HeaderViewContainer title="members">
              <MembersSection />
            </HeaderViewContainer>
          )
        },
        {
          path: "/monitoring/connected-devices",
          name: "connectedDevices",
          exact: true,
          element: (
            <HeaderViewContainer title="connectedDevices">
              <ConnectedDevices />
            </HeaderViewContainer>
          )
        },
        {
          path: "/monitoring/devices/device-detail/:deviceName",
          name: "deviceDetail",
          exact: true,
          element: (
            <HeaderViewContainer title="devices" isOnGoBack={true}>
              <DeviceDetail />
            </HeaderViewContainer>
          )
        },
        {
          path: "/monitoring/devices/device-grouping/details/:groupsType",
          name: "deviceDetail",
          exact: true,
          element: (
            <HeaderViewContainer title="groups">
              <ViewDeviceGrouping />
            </HeaderViewContainer>
          )
        },
        {
          path: "/monitoring/devices/device-grouping/create",
          name: "deviceDetail",
          exact: true,
          element: (
            <HeaderViewContainer title="createGroup">
              <CreateDeviceGrouping />
            </HeaderViewContainer>
          )
        },
        {
          path: "/monitoring/devices/device-grouping/edit/:groupName/:groupColor",
          name: "deviceDetail",
          exact: true,
          element: (
            <HeaderViewContainer title="editGroup">
              <EditDeviceGrouping />
            </HeaderViewContainer>
          )
        },
        {
          path: "/monitoring/devices/device-detail/:deviceName/device-address-change/edit",
          name: "deviceAddressChange",
          exact: true,
          element: (
            <HeaderViewContainer title="deviceAddress">
              <DeviceAddressChange />
            </HeaderViewContainer>
          )
        },
        {
          path: "/administration/company",
          name: "companyProfiles",
          exact: true,
          element: (
            <HeaderViewContainer title="companyProfileAdministration">
              <CompanyAdministration />
            </HeaderViewContainer>
          )
        },
        {
          path: "/administration/:companyProfile",
          name: "companyAdministration",
          exact: true,
          element: (
            <HeaderViewContainer title="companyProfileAdministration" isOnGoBack={true}>
              <Administration />
            </HeaderViewContainer>
          )
        },
        {
          path: "/support/incidentManagement",
          name: "incidentManagement",
          exact: true,
          element: (
            <IncidentManagementContextProvider>
              <HeaderViewContainer title="incidentManagement">
                <IncidentManagement />
              </HeaderViewContainer>
            </IncidentManagementContextProvider>
          )
        },
        {
          path: "/support/incidentManagement/:ticketNumber",
          name: "incidentManagement",
          exact: true,
          element: (
            <IncidentManagementContextProvider>
              <HeaderViewContainer title="incidentManagement" isOnGoBack={true}>
                <IncidentManagementEdit />
              </HeaderViewContainer>
            </IncidentManagementContextProvider>
          )
        },
        {
          path: "/administration/user",
          name: "userAdministration",
          exact: true,
          element: (
            <UserMFAStatusContext>
              <HeaderViewContainer title="userProfileAdministration">
                <UserProfile />
              </HeaderViewContainer>
            </UserMFAStatusContext>
          )
        },
        {
          path: "/administration/user-roles",
          name: "userRoles",
          exact: true,
          element: (
            <HeaderViewContainer title="userRoles">
              <ViewUserRoles />
            </HeaderViewContainer>
          )
        },
        {
          path: "/administration/user-roles/create",
          name: "userRoles",
          exact: true,
          element: (
            <HeaderViewContainer title="userRoles" isOnGoBack={true}>
              <CreateUserRoles />
            </HeaderViewContainer>
          )
        },
        {
          path: "/administration/user-roles/edit/:roleName",
          name: "userRoles",
          exact: true,
          element: (
            <HeaderViewContainer title="userRoles" isOnGoBack>
              <EditUserRoles />
            </HeaderViewContainer>
          )
        },
        {
          path: "/administration/tnsonline-api",
          name: "tnsOnlineApi",
          exact: true,
          element: (
            <ExternalApiContext>
              <HeaderViewContainer title="TNSOnline API">
                <ExternalApi />
              </HeaderViewContainer>
            </ExternalApiContext>
          )
        },
        {
          path: "/self-service-tools/tns-link-provisioning",
          name: "tnsLinkProvisioning",
          exact: true,
          element: (
            <HeaderViewContainer title="tnsLinkProvisioning">
              <TNSLinkProvisioning />
            </HeaderViewContainer>
          )
        },
        {
          path: "/self-service-tools/swap-devices",
          name: "swapDevices",
          exact: true,
          element: (
            <SwapDeviceContext>
              <HeaderViewContainer title="swapDevices">
                <SwapDevices />
              </HeaderViewContainer>
            </SwapDeviceContext>
          )
        },
        {
          path: "/coinReports/availabilityReports",
          name: "coinReports",
          exact: true,
          element: (
            <HeaderViewContainer title="coinReports">
              <AvailabilityReports />
            </HeaderViewContainer>
          )
        },
        {
          path: "/contact/contact-information",
          name: "directory",
          exact: true,
          element: (
            <HeaderViewContainer title="directory">
              <ContactDirectory />
            </HeaderViewContainer>
          )
        },
        {
          path: "/support/change-management",
          name: "changeManagement",
          exact: true,
          element: <ChangeSection />
        },
        {
          path: "/support/change-management/change-detail/:changeTicketId/devices",
          name: "changeManagementDetail",
          exact: true,
          element: (
            <HeaderViewContainer title="changeDetails" isOnGoBack={true}>
              <ChangeDetails />
            </HeaderViewContainer>
          )
        },
        {
          path: "/help/contactUS",
          name: "contactUs",
          exact: true,
          element: (
            <HeaderViewContainer title="contactUs">
              <ContactUs />
            </HeaderViewContainer>
          )
        },
        {
          path: "/help/userGuide",
          name: "UserAccount",
          exact: true,
          element: (
            <HeaderViewContainer title="userGuide">
              <UserGuide />
            </HeaderViewContainer>
          )
        },
        {
          path: "/help/userGuide/:id",
          name: "UserAccount",
          exact: true,
          element: (
            <HeaderViewContainer title="userGuide">
              <UserGuide />
            </HeaderViewContainer>
          )
        },
        {
          path: "/administration/deviceProfile",
          name: "deviceProfile",
          exact: true,
          element: (
            <HeaderViewContainer title="entityProfiles">
              <DeviceProfiles />
            </HeaderViewContainer>
          )
        },
        {
          path: "/administration/deviceProfile/:profileName/edit",
          name: "editDeviceProfile",
          exact: true,
          element: (
            <HeaderViewContainer title="entityProfiles" isOnGoBack={true}>
              <EditDeviceProfile />
            </HeaderViewContainer>
          )
        },
        {
          path: "/administration/deviceProfile/new_profile",
          name: "editDeviceProfile",
          exact: true,
          element: (
            <HeaderViewContainer title="entityProfiles" isOnGoBack={true}>
              <CreateDeviceProfile />
            </HeaderViewContainer>
          )
        },
        {
          path: "/administration/deviceProfile/:entityProfile/contact/edit/:contactId",
          name: "contactEdit",
          exact: true,
          element: (
            <HeaderViewContainer title="profiles">
              <ContactForm />
            </HeaderViewContainer>
          )
        },
        {
          path: "/administration/deviceProfile/:entityProfile/contact/create",
          name: "contactCreate",
          exact: true,
          element: (
            <HeaderViewContainer title="profiles">
              <ContactForm />
            </HeaderViewContainer>
          )
        },
        {
          path: "/administration/camProfile",
          name: "CamProfiles",
          exact: true,
          element: (
            <CustomerAccountManagerContextProvider>
              <HeaderViewContainer title="camProfile">
                <ViewCamProfile />
              </HeaderViewContainer>
            </CustomerAccountManagerContextProvider>
          )
        },
        {
          path: "/administration/camProfile/create",
          name: "CreateCamProfiles",
          exact: true,
          element: (
            <CustomerAccountManagerContextProvider>
              <HeaderViewContainer title="camProfile" isOnGoBack>
                <ViewCreateEditCamProfile />
              </HeaderViewContainer>
            </CustomerAccountManagerContextProvider>
          )
        },
        {
          path: "/administration/camProfile/:camProfileName/edit",
          name: "EditCamProfiles",
          exact: true,
          element: (
            <CustomerAccountManagerContextProvider>
              <HeaderViewContainer title="camProfile" isOnGoBack>
                <ViewCreateEditCamProfile isCreateView={false} />
              </HeaderViewContainer>
            </CustomerAccountManagerContextProvider>
          )
        },
        {
          path: "/notifications",
          name: "notifications",
          exact: true,
          element: (
            <HeaderViewContainer title="notificationsCenter">
              <ViewNotificationsCenter />
            </HeaderViewContainer>
          )
        },
        {
          path: "/notifications/:id",
          name: "notifications",
          exact: true,
          element: (
            <HeaderViewContainer title="notificationsCenter">
              <ViewNotificationsCenter />
            </HeaderViewContainer>
          )
        },
        {
          path: "/content-filtering/destination-lists",
          name: "destination-lists",
          exact: true,
          element: (
            <HeaderViewContainer title="destinationLists">
              <DestinationListContainer />
            </HeaderViewContainer>
          )
        },
        {
          path: "/content-filtering/destination-lists/:destinationListId/edit",
          name: "edit-destination-list",
          exact: true,
          element: (
            <HeaderViewContainer title="destinationLists" isOnGoBack>
              <DestinationListsContextProvider>
                <ViewDestinationListContainer />
              </DestinationListsContextProvider>
            </HeaderViewContainer>
          )
        },
        {
          path: "/content-filtering/core-reports",
          name: "core-reports",
          exact: true,
          element: (
            <HeaderViewContainer title="core-reports">
              <CoreReportsContainer />
            </HeaderViewContainer>
          )
        }
      ]
    },
    {
      path: "/auth",
      element: <BlankLayout />,
      children: [
        { path: "password/forgot", name: "forgotPassword", element: <ForgotPassword /> },
        { path: "login", name: "login", element: !token ? <Login /> : <Navigate to="/monitoring/devices" /> },
        {
          path: "403",
          name: "forbidden",
          element: <Forbidden />
        },
        {
          path: "404",
          name: "notFound",
          element: <NotFound />
        },
        {
          path: "UserDisabled",
          name: "UserDisabled",
          exact: true,
          element: <UserDisabled />
        },
        {
          path: "UserLocked",
          name: "UserLocked",
          exact: true,
          element: <UserLocked />
        }
      ]
    },
    { path: "password/reset/:guid", name: "resetPassword", element: <ResetPassword /> },
    {
      path: "/user",
      element: <FullLayout />,
      children: [
        {
          path: "profile",
          name: "profile",
          element: (
            <HeaderViewContainer title="myAccount">
              <Profile />{" "}
            </HeaderViewContainer>
          )
        },
        {
          path: "profile/user-administration/:username",
          name: "profileAdministration",
          element: (
            <HeaderViewContainer title="userProfileAdministration" isOnGoBack>
              <UserAdministrarion />
            </HeaderViewContainer>
          )
        }
      ]
    },
    { path: "*", element: <BlankLayout />, children: [{ path: "*", name: "notFound", element: <Navigate to="/auth/404" /> }] }
  ];
};

export default ThemeRoutes;
