import React, { useState, useEffect, useRef, useCallback } from "react";
import { Card } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { TNSOTooltip } from "@tnso/ui-components";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../utils/const/translation";
import "./terminal.scss";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface TerminalProps {
  message: string;
  onClear: () => void;
}

export const Terminal: React.FC<TerminalProps> = ({ message, onClear }) => {
  const { t } = useTranslation();
  const [printedLines, setPrintedLines] = useState<string[]>([]);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (message === "") {
      setPrintedLines([]);
    } else {
      const trimmedMessage = message.replace(/\n+$/, "");
      const lines = trimmedMessage.split("\n");
      setPrintedLines(() => lines);
    }
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  }, [message]);

  const renderLine = useCallback((index: number, line: string) => {
    return (
      <div key={index} className="mb-2 d-flex" style={{ whiteSpace: "pre" }}>
        <div>{(index + 1).toString()}</div>
        <span className="text-secondary" />
        <div>{line}</div>
      </div>
    );
  }, []);

  return (
    <div className="position-relative">
      <Card className="bg-dark terminal-card" styles={{ body: { padding: "1rem", backgroundColor: "#0A0A0E" } }}>
        <div className="font-monospace terminal-card-inner">
          {printedLines.map((line, index) => renderLine(index, line))}
          <div ref={messagesEndRef} />
        </div>
      </Card>
      <TNSOTooltip title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.clean)} trigger="click" placement="topRight">
        <button
          className={`button trash ${message === "" ? "disabled-trash" : "enabled-trash"}`}
          title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.clean)}
          disabled={message === ""}
          onClick={onClear}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </TNSOTooltip>
      <CopyToClipboard text={message}>
        <TNSOTooltip title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.copiedToClipboard)} trigger="click" placement="topRight">
          <button
            className={`button copy ${message === "" ? "disabled-copy" : "enabled-copy"}`}
            title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.copyToClipboard)}
            disabled={message === ""}>
            <FontAwesomeIcon icon={faCopy} />
          </button>
        </TNSOTooltip>
      </CopyToClipboard>
    </div>
  );
};
