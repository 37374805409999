import { QueryParams } from "../../models/query-params.model";
import { User } from "../../models/user.model";
import { ApiCore, ApiService, IResponse } from "@tnso/api-core";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class UserService extends ApiService {
  static async getByUsername(username: string): Promise<IResponse<User> | undefined> {
    const urlSearchParams = ApiCore.encodeParams<QueryParams>({ username });
    return this.get<User>(`${getApiBaseUrl()}/users`, urlSearchParams);
  }
}
