import React from "react";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../utils/const/translation";

export const SignalQuality: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex signals-container justify-content-center align-items-center rounded py-4 gap-3" data-testid="signal-quality-component">
      <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.qualitySignalMetric)}: </span>
      <div className="d-flex justify-content-center align-items-center gap-2">
        <div className="excellent-box" />
        <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.excellent)}</span>
      </div>
      <div className="d-flex justify-content-center align-items-center gap-2">
        <div className="good-box" />
        <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.good)}</span>
      </div>
      <div className="d-flex justify-content-center align-items-center gap-2">
        <div className="fair-box" />
        <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.fair)}</span>
      </div>
      <div className="d-flex justify-content-center align-items-center gap-2">
        <div className="poor-box" />
        <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.poor)}</span>
      </div>
      <div className="d-flex justify-content-center align-items-center gap-2">
        <div className="no-signal-box" />
        <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.noSignal)}</span>
      </div>
    </div>
  );
};
