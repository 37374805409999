import { DailyUsageStatus } from "../../interfaces/devices/cellular/cellularUsage";

export interface ICellularUsage {
  cumulativeUsage: number[];
  predictiveUsage: number[];
}

export class CellularUsageHelper {
  /**
   *
   * @param dailyUsage a number array with the daily cellular usage data
   * @param statusData a string array with the status of the daily usage data
   * @returns two arrays, the first one with the cumulative usage and the second one with the predictive usage
   */
  static getCumulativeAndPredictiveData = (dailyUsage: number[], statusData: string[]): ICellularUsage => {
    const cumulativeUsage: number[] = [];
    let currentTotal = 0;
    const predictiveUsage = [...dailyUsage];
    for (let i = 0; i < dailyUsage.length; i++) {
      currentTotal += dailyUsage[i];
      cumulativeUsage.push(currentTotal);
      if (statusData[i] === DailyUsageStatus.PREDICTED_NO_OVERAGE || statusData[i] === DailyUsageStatus.PREDICTED_OVERAGE) {
        predictiveUsage.push(currentTotal);
      }
    }
    return { cumulativeUsage, predictiveUsage };
  };
}
