export const TRANSLATION = {
  SHARED: {
    TABLE: {
      of: "of",
      records: "records",
      search: "search",
      thereAreNoItemsToDisplayInThisView: "thereAreNoItemsToDisplayInThisView"
    },
    create: "create",
    next: "next",
    save: "save",
    cancel: "cancel"
  },
  ERROR: {
    upsSomethingWentWrong: "upsSomethingWentWrong"
  },
  MODAL: {
    ALERT: {
      successClientSecret: "successClientSecret",
      errorClientSecret: "errorClientSecret"
    }
  },
  USER_ROLES: {
    VIEW_ROLE: {
      noItemToDisplay: "thereAreNoItemsToDisplayInThisView",
      title: "userRoles",
      selectARole: "selectARole",
      usersAssociated: "usersAssociated",
      pleaseSelectARoleToViewDetails: "pleaseSelectARoleToViewDetails"
    },
    CREATE_ROLE: {
      userRoles: "userRoles",
      roleName: "roleName",
      createRole: "createRole",
      functionalitiesEnabled: "functionalitiesEnabled",
      roleCreatedSuccessfully: "roleCreatedSuccessfully",
      roleNameIsRequired: "roleNameIsRequired",
      thisRoleNameIsAlreadyTakenPleaseChooseAnotherName: "thisRoleNameIsAlreadyTakenPleaseChooseAnotherName",
      userRoleNameMustBetween1and100Characters: "userRoleNameMustBetween1and100Characters",
      pleaseCompleteName: "pleaseCompleteName"
    },
    EDIT_ROLE: {
      functionalitiesEnabled: "functionalitiesEnabled",
      roleEditedSuccessfully: "roleEditedSuccessfully"
    },
    DELETE_ROLE: {
      roleDeletedSuccessfully: "roleDeletedSuccessfully",
      areYouSureYouWantToDelete: "areYouSureYouWantToDelete",
      thisRoleCannotBeDeleted: "thisRoleCannotBeDeleted",
      itHasAssociatedUsers: "itHasAssociatedUsers"
    },
    BUTTON: {
      addRole: "addRole",
      editRole: "editRole",
      deleteRole: "deleteRole",
      next: "next",
      cancel: "cancel",
      save: "save",
      no: "no",
      yes: "yes"
    },
    PROFILE: {
      entityProfile: "entityProfile",
      profileSelectPlaceholder: "profileSelectPlaceholder",
      username: "username",
      email: "email"
    },
    DATA_TABLE: {
      monitoring: "monitoring",
      contacts: "contacts",
      selfHelpTools: "selfHelpTools",
      support: "support",
      help: "help"
    },
    HEADER_TITLE: {
      features: "features"
    }
  },
  PROFILES: {
    VIEW_PROFILE: {
      noItemToDisplay: "thereAreNoItemsToDisplayInThisView",
      title: "entityProfiles",
      selectAProfile: "selectAProfile",
      profileAssociated: "profileAssociated",
      profileSelectPlaceholder: "profileSelectPlaceholder",
      pleaseSelectAProfileToViewDetails: "pleaseSelectAProfileToViewDetails",
      profileDevicesEmptyMessage: "profileDevicesEmptyMessage"
    },
    CREATE_PROFILE: {
      profileName: "profileName",
      createProfile: "createProfile",
      profileCreatedSuccessfully: "profileCreatedSuccessfully",
      profileIsRequired: "profileIsRequired",
      thisProfileIsAlreadyTaken: "thisProfileIsAlreadyTaken",
      pleaseCompleteAProfileName: "pleaseCompleteAProfileName",
      devices: "devices",
      addAll: "addAll",
      removeAll: "removeAll",
      availableDevices: "availableDevices",
      selectedDevices: "selectedDevices",
      associateDevices: "associateDevices",
      areYouSureYouWantToMoveAll: "areYouSureYouWantToMoveAll",
      toTheSelectedDevicesList: "toTheSelectedDevicesList",
      areYouSureYouWantToRemoveAll: "areYouSureYouWantToRemoveAll",
      fromTheSelectedDevicesList: "fromTheSelectedDevicesList",
      yes: "yes",
      no: "no"
    },
    EDIT_PROFILE: {
      profileEditedSuccessfully: "profileEditedSuccessfully",
      selectedDevices: "selectedDevices",
      allDevices: "allDevices",
      acna: "acna",
      state: "state",
      city: "city",
      country: "country",
      profileNameAlreadyExists: "profileNameAlreadyExists",
      profileNameEmpty: "profileNameEmpty",
      availableDevices: "availableDevices",
      devices: "devices",
      associateDevices: "associateDevices",
      onBoardedDevices: "onBoardedDevices",
      nonBoardedDevices: "nonBoardedDevices"
    },
    DELETE_PROFILE: {
      profileDeleteConfirmation: "profileDeleteConfirmation",
      thisProfileCannotBeDeletedItHasToHave0AssociatedUsersToBeDeleted: "thisProfileCannotBeDeletedItHasToHave0AssociatedUsersToBeDeleted"
    },
    MODAL: {
      removeAllModal: "removeAllModal"
    },
    BUTTON: {
      addProfile: "addProfile",
      editProfile: "editProfile",
      deleteProfile: "deleteProfile",
      next: "next",
      cancel: "cancel",
      save: "save",
      contactModalCancel: "contactModalCancel",
      contactModalAccept: "contactModalAccept",
      removeAll: "removeAll",
      deselectAllButton: "deselectAllButton"
    },
    TABS: {
      contact: "contact",
      devices: "devices"
    }
  },
  CAM: {
    camNameAlreadyExists: "camNameAlreadyExists",
    camMaxMinLength: "camMaxMinLength",
    completeName: "pleaseCompleteName",
    createCam: "createCam",
    editCam: "editCam",
    camProfile: "camProfile",
    camProfileNameTitle: "camProfileNameTitle",
    associatedCompanyProfiles: "associatedCompanyProfiles",
    availableCompanyProfiles: "availableCompanyProfiles",
    camNameSpecialCharacter: "camNameSpecialCharacter",
    associateCompanyProfiles: "associateCompanyProfiles"
  },
  EXTERNALAPI: {
    info: "externalApiInfo",
    clientId: "clientId",
    clientSecret: "clientSecret",
    accessToTNS: "accessToTNS",
    titlePauseAccessToTNSAPI: "titlePauseAccessToTNSAPI",
    titleUnpauseAccessToTNSAPI: "titleUnpauseAccessToTNSAPI",
    date: "date",
    username: "username",
    event: "event",
    externalApiStatus: "externalApiStatus",
    enabled: "enabled",
    disabled: "disabled",
    enableApiAccess: "enableApiAccess",
    externalApi: "externalApi",
    externalApiInformation: "externalApiInformation",
    regenerate: "regenerate",
    confirmRegenerate: "confirmRegenerate",
    confirmRegenerateInfo: "confirmRegenerateInfo",
    apiEnabled: "apiEnabled",
    apiDisabled: "apiDisabled",
    secretRegenerated: "secretRegenerated",
    tnsOnlineApiStatus: "tnsOnlineApiStatus",
    email: "emailUser",
    BUTTON: {
      addRole: "addRole",
      editRole: "editRole",
      deleteRole: "deleteRole",
      next: "next",
      cancel: "cancel",
      save: "save",
      no: "no",
      yes: "yes"
    }
  },
  INCIDENTMANAGEMENT: {
    incidentManagement: "incidentManagement",
    ticketNumber: "ticketNumber",
    incidentStartTime: "incidentStartTime",
    description: "description",
    tickets: "tickets",
    customerName: "customerName",
    device: "device",
    status: "status",
    Active: "active",
    ticketNumberExact: "ticketNumberExact",
    selectStatus: "selectStatus",
    lastStatusUpdatedOn: "lastStatusUpdatedOn",
    deleteSuccess: "deleteSuccess",
    createIncidentTicket: "createIncidentTicket",
    startDate: "startDate",
    service: "service",
    incidentUrgency: "incidentUrgency",
    incidentImpactRisk: "incidentImpactRisk",
    assignedTo: "assignedTo",
    lastStatusDate: "lastStatusDate",
    customerLog: "customerLog",
    update: "update",
    new: "New",
    pendingTns: "pendingTns",
    inprogress: "inprogress",
    pendingCustomer: "pendingCustomer",
    monitoring: "monitoring",
    resolved: "resolved",
    closed: "closed",
    cancelled: "cancelled",
    active: "active",
    New: "New",
    log: "log",
    ticketUpdatedSuccessfully: "ticketUpdatedSuccessfully",
    ticketCreatedSuccessfully: "ticketCreatedSuccessfully",
    "Pending TNS": "Pending TNS",
    "In Progress": "In Progress",
    "Pending Customer": "Pending Customer",
    Monitoring: "Monitoring",
    Resolved: "Resolved",
    Closed: "Closed",
    Cancelled: "Cancelled",
    youCanOnlyPickOneDeviceAndItMustBeOnStatusOperational: "youCanOnlyPickOneDeviceAndItMustBeOnStatusOperational",
    completeWithAcna: "completeWithAcna",
    selectDeviceName: "selectDeviceName",
    pleaseSelectDate: "pleaseSelectDate",
    searchDevice: "searchDevice",
    searchDeviceExact: "searchDeviceExact",
    pleaseDescribeTheProblem: "pleaseDescribeTheProblem",
    BUTTON: {
      cancel: "cancel",
      accept: "accept",
      create: "create",
      update: "update",
      createTicket: "createTicket",
    },
    ERROR: {
      min30Length: "min30Length",
      max160Length: "max160Length",
      max2048Length: "max2048Length",
      required: "required"
    }
  }
};
