import { Variants, TNSButton, TNSDivider, TNSDrawer, TNSPagination } from "@tns/ui-components";
import { Row, Spin } from "antd";
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { TRANSLATION } from "../../utils/const/translation";
import { useTranslation } from "react-i18next";
import { MenuItem } from "./menu-dhi-items";
import { AsyncApiCallStatus } from "../../../../hooks/useAsyncCallShared";
import { DeviceGroups } from "../../models/devices/devices";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IDetail } from "../../store/device/detail/detail";

export interface HeaderDrawerDhiProps {
  loader: AsyncApiCallStatus<void>;
  setPaginatedGroups: Dispatch<SetStateAction<DeviceGroups[] | undefined>>;
  closeDrawerDeviceGroups: () => void;
  drawerDeviceGroupsOpen: boolean;
  detail: IDetail;
  paginatedGroups?: DeviceGroups[];
  tnsDeviceName?: string;
}

export const HeaderDrawerGrouping: React.FC<HeaderDrawerDhiProps> = ({
  loader,
  paginatedGroups,
  setPaginatedGroups,
  closeDrawerDeviceGroups,
  drawerDeviceGroupsOpen,
  detail,
  tnsDeviceName
}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [groups, setGroups] = useState<DeviceGroups[] | undefined>(detail.groups);
  const pageSize = 10;

  useMemo(() => {
    setGroups(detail.groups);
  }, [detail?.groups]);

  const handleGoToPage = useCallback(
    async (page: number): Promise<void> => {
      setCurrentPage(page);
      setPaginatedGroups(detail?.groups?.slice((page - 1) * pageSize, page * pageSize));
    },
    [detail?.groups]
  );

  const handleCheckedGroup = (e: CheckboxChangeEvent, item: DeviceGroups): void => {
    setPaginatedGroups(paginatedGroups?.map((group) => (group.name === item.name ? { ...group, associated: e.target.checked } : group)));
    setGroups(groups?.map((group) => (group.name === item.name ? { ...group, associated: e.target.checked } : group)));
  };

  const onClose = (): void => {
    closeDrawerDeviceGroups();
    setCurrentPage(1);
  };

  const onSave = async (): Promise<void> => {
    const associatedGroups = groups?.filter((group) => group.associated === true).map((group) => group.name) ?? [];
    await detail.editGroups(associatedGroups, tnsDeviceName);
    onClose();
  };

  return (
    <TNSDrawer open={drawerDeviceGroupsOpen} onClose={onClose} title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TAGS.deviceTags)}>
      {loader.completed ? (
        <>
          <label htmlFor="customerDeviceName" className="mb-3">
            <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TAGS.deviceTagsAvailables)}</span>
          </label>
          <div className="ms-2">
            <div className="d-flex flex-column align-items-start">
              {groups?.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((group) => (
                <MenuItem item={group} handleChecked={handleCheckedGroup} checkbox key={group.name} />
              ))}
            </div>
          </div>
          <TNSDivider type="horizontal" />
          <div className="d-flex justify-content-end align-items-center mt-1">
            <TNSPagination
              currentPage={currentPage}
              pageSize={pageSize}
              totalItems={detail?.groups?.length ?? 0}
              size="small"
              handleGoToPage={handleGoToPage}
              recordsTranslated={[t(TRANSLATION.SHARED.TABLE.records), t(TRANSLATION.SHARED.TABLE.of)]}
            />
          </div>
          <Row className="justify-content-end mt-4">
            <div className="m-2 mt-0">
              <TNSButton buttonVariant={Variants.Secondary} onClick={onClose}>
                {t(TRANSLATION.SHARED.cancel)}
              </TNSButton>
            </div>
            <TNSButton buttonVariant={Variants.Primary} onClick={onSave}>
              {t(TRANSLATION.SHARED.BUTTON.save)}
            </TNSButton>
          </Row>
        </>
      ) : (
        <Spin />
      )}
    </TNSDrawer>
  );
};
