export interface StorageUsageMonthly {
  value: number;
  month?: string;
  year?: string;
  timestamp?: string;
}

export interface HardwareExport {
  [key: string]: string | undefined;
}

export interface MemoryUsageMonthly {
  value: number;
  month?: string;
  year?: string;
  timestamp?: string;
}

export interface CPUUsageMonthly {
  value: number;
  month?: string;
  year?: string;
  timestamp?: string;
}

export interface CPUCoresData {
  [coreName: string]: CPUUsageMonthly[];
}

export interface TemperatureByUnit {
  value: number;
  month?: string;
  year?: string;
  timestamp?: string;
}

export interface TemperatureData {
  temperatureInCelsius: TemperatureByUnit[];
  temperatureInFahrenheit: TemperatureByUnit[];
}

export interface QueryParamsGraphs {
  day: number;
}

export enum Measurement {
  temperatureInFahrenheit = "temperatureInFahrenheit",
  temperatureInCelsius = "temperatureInCelsius",
  fahrenheit = "Fahrenheit",
  celsius = "Celsius",
  F = "F",
  measurementF = "°F",
  measurementC = "°C",
  C = "C"
}

export enum BadgeColors {
  normal = "#42C53F",
  warning = "#EB9646",
  critical = "#FF2D2E"
}

export enum CoreColors {
  Red = "#FF5733",
  Purple = "#6C3483",
  Green = "#27AE60",
  Yellow = "#F1C40F",
  Blue = "#3498DB",
  LightRed = "#E74C3C",
  Turquoise = "#1ABC9C",
  Violet = "#9B59B6",
  LightOrange = "#FFA07A",
  Aqua = "#00CED1",
  Pink = "#FF1493",
  Gold = "#FFD700",
  Teal = "#008080",
  Maroon = "#800000",
  BlueViolet = "#8A2BE2",
  SeaGreen = "#3CB371"
}

