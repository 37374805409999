import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import moment from "moment";
import { store } from "src/app/monitoring/store/store";
import { store as sharedStore } from "src/app/portal-app/store/StoreMobx";
import { CellularSignal, Period } from "src/app/monitoring/models/devices/cellular/cellularSignal";
import { TRANSLATION } from "src/app/monitoring/utils/const/translation";
import { useAsyncCall } from "../../../../../../libraries/shared/src/lib/hooks/use-async-call-shared";
import { DefaultView } from "src/app/monitoring/components/dhi/tabs/cellular-signal/default-view";
import Loader from "src/layouts/loader/Loader";
import { Language } from "src/app/portal-app/store/shared/Shared";
import { SIMSCards } from "src/app/monitoring/components/dhi/tabs/details/sims-cards";
import { DHIContext } from "src/app/monitoring/context/dhi-context";
import { ISIMDetail } from "src/app/monitoring/models/devices/dhi/dhi";
import "../../../components/dhi/tabs/details/device-health-information.scss";
import { SignalStrength } from "src/app/monitoring/components/dhi/tabs/details/metrics/signal-strength";
import { ConnectivityStatus } from "src/app/monitoring/models/devices/devices";
import { TNSSelect } from "@tns/ui-components";

export const enum Views {
  DefaultView = "default",
  LegacyView = "legacy"
}

export const Wireless: React.FC = observer(() => {
  const { t } = useTranslation();
  const { loadData, loadMostRecentSignal } = store.device.cellularSignal;
  const { device } = sharedStore;
  const context = useContext(DHIContext);
  const { deviceName } = useParams();
  const [interval, setInterval] = useState<Period>(Period.Daily);
  const [simsInformation, setSimsInformation] = useState<ISIMDetail[]>();
  const [mostRecentSignal, setMostRecentSignal] = useState<CellularSignal>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSignal, setLoadingSignal] = useState<boolean>(false);

  const intervalItems = useMemo(() => {
    return [
      {
        label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.lastdaily),
        value: Period.Daily
      },
      {
        label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.lastweekly),
        value: Period.Weekly
      },
      {
        label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.lastmonthly),
        value: Period.Monthly
      }
    ];
  }, [t]);

  useEffect(() => {
    if (localStorage.getItem("language") === Language.ph) {
      moment.defineLocale("tl-ph", {
        monthsShort: ["Ene", "Peb", "Mar", "Abr", "May", "Hun", "Hul", "Ago", "Set", "Okt", "Nob", "Dis"],
        weekdaysShort: ["Lun", "Mar", "Miy", "Huw", "Biy", "Sab", "Lin"]
      });
    }
  }, [t]);

  const handleRefreshData = useCallback(async (): Promise<void> => {
    setLoadingSignal(true);
    if (deviceName) {
      const response = await loadMostRecentSignal(deviceName);
      setMostRecentSignal(response);
    }
    setLoadingSignal(false);
  }, [deviceName]);

  const switchInterval = useCallback(async (value: string | null): Promise<void> => {
    setLoading(true);
    if (value && deviceName) {
      setInterval(value as Period);
      await loadData(deviceName, value as Period);
    }
    setLoading(false);
  }, []);

  const loadMethod = useCallback(async () => {
    if (deviceName) {
      const sims = await context.getSIMSDetails(deviceName);
      await loadData(deviceName, interval);
      await sharedStore.device.detail.loadData(deviceName);
      setSimsInformation(sims);
    }
  }, [deviceName]);

  const loader = useAsyncCall(loadMethod, [loadMethod], sharedStore.shared.getTimeToAutoRefresh());

  const showSignalStrength = useMemo(() => {
    return device.detail.data?.connectivityStatus === ConnectivityStatus.onPrimary || device.detail.data?.connectivityStatus === ConnectivityStatus.onBackup;
  }, [device.detail.data?.connectivityStatus]);

  return loader.loading || loading ? (
    <Loader />
  ) : (
    <div className="w-100">
      <div className="d-flex w-100 gap-2 h-100">
        {simsInformation && (
          <div className={showSignalStrength ? "w-75" : "w-100"}>
            <SIMSCards sims={simsInformation} />
          </div>
        )}
        {showSignalStrength && <SignalStrength handleRefreshData={handleRefreshData} mostRecentSignal={mostRecentSignal} loading={loadingSignal} width="w-25" />}
      </div>
      <div className="my-2 d-flex flex-column align-items-start justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <h6 className="m-0 p-0">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.signalInterval)} </h6>
          <TNSSelect onChange={switchInterval} options={intervalItems} value={interval} />
        </div>
      </div>
      <DefaultView interval={interval ?? Period.Daily} />
    </div>
  );
});
