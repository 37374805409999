import React, { useCallback } from "react";
import { Variants, TNSButton } from "@tns/ui-components";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../utils/const/translation";
import { DetailsDeviceGrouping } from "./details-device-grouping";
import { DeviceGroupingContextProvider } from "../../../components/grouping/context/DeviceGroupingContext";
import { useNavigate } from "react-router-dom";

export function ViewDeviceGrouping(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate("/monitoring/devices/device-grouping/create");
  }, [navigate]);

  return (
    <DeviceGroupingContextProvider>
      <div className="d-flex justify-content-end mb-2">
        <TNSButton buttonVariant={Variants.Primary} onClick={handleClick}>
          {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.GROUPING.createGroup)}
        </TNSButton>
      </div>
      <DetailsDeviceGrouping />
    </DeviceGroupingContextProvider>
  );
}
