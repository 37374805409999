import React, { ReactNode } from "react";

import { store } from "../../app/portal-app/store/StoreMobx";
import { observer } from "mobx-react";
import "../../app/portal-app/assets/scss/style.scss";

// import DarkTheme from "./DarkTheme";
// import LightTheme from "./LightTheme";

interface Props {
  children?: ReactNode;
}
const ThemeSelector: React.FC<Props> = ({ children }) => {
  const isDarkMode = store.customizer.isDark;

  return (
    <div className={isDarkMode ? "dark-theme" : "light-theme"}>
      {/* {isDarkMode ? <DarkTheme key="dark" /> : <LightTheme key="light" />} */}
      {children}
    </div>
  );
};
export default observer(ThemeSelector);
