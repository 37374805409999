import React, { ReactNode } from "react";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import type { TableProps } from "antd";
import { ConnectedDevice } from "../../../models/connected-devices/connected-devices";
import { TNSOSearchGrid } from "src/app/portal-app/components/shared/table/SearchFilter";
import { TNSOSelectFilter } from "src/app/portal-app/components/shared/table/SelectFilter";
import { DateHelper } from "@tnso/shared";
import { UserInfo } from "src/shared/models/auth/login/login";
import { ConnectedActionButton } from "./connected-action-button";

export interface TableConfigProps {
  handleSearch: (filter: string, keyFilter: string) => void;
  editConnectedDevices: (connectedDeviceName: string, macAddress: string) => void;
  lanPorts?: string[];
}

export interface TableConfigResponse {
  columns: TableProps<ConnectedDevice>[];
  skeletonColumns: unknown[];
  skeletonColumnsMenu: unknown[];
  columnsMenu: TableProps<ConnectedDevice>[];
}

export const useTableConnectedDevicesConfig = ({ handleSearch, editConnectedDevices, lanPorts }: TableConfigProps): TableConfigResponse => {
  // eslint-disable-next-line
  const getColumnSearchProps = (dataIndex: string, filterType?: "search" | "select", icon?: ReactNode, options?: any[]): any => ({
    // eslint-disable-next-line
    filterDropdown: (props: any) =>
      filterType === "search" ? (
        // eslint-disable-next-line
        <TNSOSearchGrid themeSelected="dark" {...props} onSearch={handleSearch} keyFilter={dataIndex} onReset={() => handleSearch("", dataIndex)} close={props.close} />
      ) : (
        <TNSOSelectFilter options={options ?? []} onSearch={handleSearch} keyFilter={dataIndex} defaultValue={[]} displayValues={true} close={props.close} />
      ),

    filterIcon: () => icon ?? <SearchOutlined />
  });
  const userInfo: UserInfo = JSON.parse(localStorage.getItem("user") ?? "{}");

  const columnsAction: TableProps<ConnectedDevice>[] = [
    {
      dataIndex: "action",
      title: "action",
      key: "action",
      width: 150,
      fixed: "right",
      render: (_: string, row: ConnectedDevice): JSX.Element => (
        <ConnectedActionButton editConnectedDevices={(): void => editConnectedDevices(row.connectedDeviceName, row.macAddress)} />
      )
    }
  ];

  const columns: TableProps<ConnectedDevice>[] = [
    {
      dataIndex: "connectedDeviceName",
      title: "connectedDeviceName",
      key: "connectedDeviceName",
      fixed: "left",
      sorter: true,
      ...getColumnSearchProps("connectedDeviceName", "search")
    },
    {
      dataIndex: "ipAddress",
      title: "ipAddress",
      key: "ipAddress",
      sorter: true,
      ...getColumnSearchProps("ipAddress", "search")
    },
    {
      dataIndex: "macAddress",
      title: "macAddress",
      key: "macAddress",
      sorter: true,
      ...getColumnSearchProps("macAddress", "search")
    },
    {
      dataIndex: "routerPortName",
      title: "lanPort",
      key: "lanPort",
      ...getColumnSearchProps(
        "routerPortName",
        "select",
        <FilterFilled />,
        lanPorts?.map((port) => ({ value: port, label: port }))
      )
    }
  ];

  const columnsMenu: TableProps<ConnectedDevice>[] = [
    {
      dataIndex: "acna",
      title: "acna",
      key: "acna",
      sorter: true,
      ...getColumnSearchProps("acna", "search")
    },
    {
      dataIndex: "customerDeviceName",
      title: "customerDeviceName",
      key: "customerDeviceName",
      sorter: true,
      ...getColumnSearchProps("customerDeviceName", "search")
    },
    {
      dataIndex: "tnsDeviceName",
      title: "tnsDeviceName",
      key: "tnsDeviceName",
      sorter: true,
      ...getColumnSearchProps("tnsDeviceName", "search")
    },
    {
      dataIndex: "lastUpdatedAt",
      title: "lastUpdatedAt",
      key: "lastUpdatedAt",
      sorter: true,
      render: (value: string) =>
        DateHelper.convertTimestampToBrowserCulture(Number(value), userInfo?.timeZone, {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hourCycle: "h23"
        })
    }
  ];

  const skeletonColumns: unknown[] = [
    {
      dataIndex: "connectedDeviceName",
      title: "connectedDeviceName",
      fixed: "left",
      key: "connectedDeviceName",
      ...getColumnSearchProps("connectedDeviceName", "search")
    },
    {
      dataIndex: "ipAddress",
      title: "ipAddress",
      key: "ipAddress",
      ...getColumnSearchProps("ipAddress", "search")
    },
    {
      dataIndex: "macAddress",
      title: "macAddress",
      key: "macAddress",
      ...getColumnSearchProps("macAddress", "search")
    },
    {
      dataIndex: "lanPort",
      title: "lanPort",
      key: "lanPort",
      ...getColumnSearchProps(
        "lanPort",
        "select",
        <FilterFilled />,
        lanPorts?.map((port) => ({ value: port, label: port }))
      )
    },
    {
      dataIndex: "action",
      title: "action",
      key: "action",
      fixed: "right"
    }
  ];

  const skeletonColumnsMenu: unknown[] = [
    {
      dataIndex: "connectedDeviceName",
      title: "connectedDeviceName",
      fixed: "left",
      key: "connectedDeviceName",
      ...getColumnSearchProps("connectedDeviceName", "search")
    },
    {
      dataIndex: "ipAddress",
      title: "ipAddress",
      key: "ipAddress",
      ...getColumnSearchProps("ipAddress", "search")
    },
    {
      dataIndex: "macAddress",
      title: "macAddress",
      key: "macAddress",
      ...getColumnSearchProps("macAddress", "search")
    },
    {
      dataIndex: "lanPort",
      title: "lanPort",
      key: "lanPort",
      ...getColumnSearchProps(
        "lanPort",
        "select",
        <FilterFilled />,
        lanPorts?.map((port) => ({ value: port, label: port }))
      )
    },
    {
      dataIndex: "acna",
      title: "acna",
      key: "acna",
      ...getColumnSearchProps("acna", "search")
    },
    {
      dataIndex: "customerDeviceName",
      title: "customerDeviceName",
      key: "customerDeviceName",
      ...getColumnSearchProps("customerDeviceName", "search")
    },
    {
      dataIndex: "tnsDeviceName",
      title: "tnsDeviceName",
      key: "tnsDeviceName",
      ...getColumnSearchProps("tnsDeviceName", "search")
    },
    {
      dataIndex: "lastUpdatedAt",
      title: "lastUpdatedAt",
      key: "lastUpdatedAt"
    },
    {
      dataIndex: "action",
      title: "action",
      key: "action",
      fixed: "right"
    }
  ];

  return {
    columns: [...columns, ...columnsAction],
    skeletonColumns,
    skeletonColumnsMenu,
    columnsMenu: [...columns, ...columnsMenu, ...columnsAction]
  };
};
