import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { TRANSLATION } from "../../../translations/translation";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { IncidentManagementContext } from "src/app/administration/context/incident-management/incident-management-context";
import { GapSizes, TNSButton, TNSContainer, TNSInput, TNSTextArea, Variants } from "@tns/ui-components";
import { Incidents } from "@tnso/api-sdk";
import { useNavigate, useParams } from "react-router-dom";
import { DateHelper } from "@tnso/shared";
import { StatusCode } from "@tnso/api-core";
import { TitleWithTextComponent } from "src/app/monitoring/components/dhi/title-with-text-component";
import { store } from "src/app/portal-app/store/StoreMobx";
import "./incident-management.scss";

interface Incident extends Incidents {
  update: string;
}

export const IncidentManagementEdit: React.FC = () => {
  const { ticketNumber } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const context = useContext(IncidentManagementContext);
  const [ticket, setTicket] = useState<Incidents>();
  const [canBeEdited, setCanBeEdited] = useState(false);
  const [logValue, setLogValue] = useState("");

  const {
    control,
    reset,
    formState: { errors, isValid },
    getValues
  } = useForm<Incident>({
    mode: "all"
  });

  const handleLoadTicket = useCallback(async (): Promise<void> => {
    if (ticketNumber) {
      const response = await context.getTicketByNumber(ticketNumber);
      if (response) {
        setTicket(response);
        setLogValue(response.customerLog ? response.customerLog : "");
        reset({
          ticketNumber: response.ticketNumber,
          startDate: response.startDate ? response.startDate : undefined,
          lastStatusDate: response.lastStatusDate ? response.lastStatusDate : undefined,
          status: response.status,
          description: response.description,
          knownAs: response.knownAs,
          tnsDeviceName: response.tnsDeviceName,
          customerLog: response.customerLog,
          update: ""
        });
        if (response.canBeEdited) {
          setCanBeEdited(response.canBeEdited);
        }
      }
    }
  }, [context, ticketNumber, reset]);

  const loadData = useCallback(async (): Promise<void> => {
    try {
      await Promise.all([handleLoadTicket()]);
    } catch (error) {
      console.error("Error loading data:", error);
    }
  }, [handleLoadTicket]);

  useEffect(() => {
    void loadData();
  }, [handleLoadTicket, loadData]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const submit = useCallback(
    async (incident: Incident): Promise<void> => {
      if (incident) {
        const response = await context.editTicket({ ticketNumber: incident.ticketNumber, customerLog: incident.update });
        if (response === StatusCode.NO_CONTENT) {
          goBack();
        }
      }
    },
    [context, goBack]
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLogValue(e.target.value);
  }, []);

  const status = useMemo(() => {
    return t(TRANSLATION.INCIDENTMANAGEMENT[ticket?.status as keyof typeof TRANSLATION.INCIDENTMANAGEMENT] as keyof typeof TRANSLATION.INCIDENTMANAGEMENT);
  }, [ticket?.status, t]);

  return (
    <TNSContainer gap={GapSizes.large} className="containerColums">
      <TNSContainer gap={GapSizes.sm} className="containerColums">
        <TNSContainer className="incident-header-container">
          <TitleWithTextComponent title={t(TRANSLATION.INCIDENTMANAGEMENT.ticketNumber)} text={ticket?.ticketNumber} titleColor="#2677EB" />
          <TitleWithTextComponent
            title={t(TRANSLATION.INCIDENTMANAGEMENT.incidentStartTime)}
            text={
              ticket?.startDate
                ? DateHelper.convertTimestampToBrowserCulture(ticket.startDate, store.auth.userInfo?.timeZone, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit"
                  })
                : "N/A"
            }
            titleColor="#2677EB"
          />
          <TitleWithTextComponent
            title={t(TRANSLATION.INCIDENTMANAGEMENT.lastStatusUpdatedOn)}
            text={
              ticket?.lastStatusDate
                ? DateHelper.convertTimestampToBrowserCulture(ticket.lastStatusDate, store.auth.userInfo?.timeZone, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit"
                  })
                : "N/A"
            }
            titleColor="#2677EB"
          />
          <TitleWithTextComponent title={t(TRANSLATION.INCIDENTMANAGEMENT.device)} text={ticket?.tnsDeviceName} titleColor="#2677EB" />
          <TitleWithTextComponent title={t(TRANSLATION.INCIDENTMANAGEMENT.status)} text={status} titleColor="#2677EB" />
        </TNSContainer>
        <div>
          <h5 className="incident-management-field-name">{t(TRANSLATION.INCIDENTMANAGEMENT.description)}</h5>
          <Controller name="description" control={control} rules={{ required: false }} render={({ field }): JSX.Element => <TNSInput className="w-100" {...field} disabled />} />
        </div>
        <div>
          <h5 className="incident-management-field-name">{t(TRANSLATION.INCIDENTMANAGEMENT.log)}</h5>
          <Controller
            name="customerLog"
            control={control}
            rules={{ required: false }}
            render={({ field }): JSX.Element => <TNSTextArea {...field} rows={7} disabled onChange={handleChange} value={logValue} autoSize={{ minRows: 2, maxRows: 12 }} />}
          />
        </div>
        <div>{errors.customerLog?.type === "required" && <label className="incident-management-error-message">{t(TRANSLATION.INCIDENTMANAGEMENT.ERROR.required)}</label>}</div>
      </TNSContainer>
      <TNSContainer gap={GapSizes.med} className="containerColums">
        <div>
          <h5 className="incident-management-field-name">{t(TRANSLATION.INCIDENTMANAGEMENT.update)}</h5>
          <Controller
            name="update"
            control={control}
            rules={{ required: true, minLength: 30, maxLength: 2048 }}
            render={({ field }): JSX.Element => (
              <TNSTextArea {...field} rows={7} disabled={!canBeEdited} autoSize={{ minRows: 2, maxRows: 12 }} status={errors.update ? "error" : undefined} />
            )}
          />
          <div>
            {errors.update?.type === "minLength" && <label className="incident-management-error-message">{t(TRANSLATION.INCIDENTMANAGEMENT.ERROR.min30Length)}</label>}
            {errors.update?.type === "maxLength" && <label className="incident-management-error-message">{t(TRANSLATION.INCIDENTMANAGEMENT.ERROR.max2048Length)}</label>}
            {errors.update?.type === "required" && <label className="incident-management-error-message">{t(TRANSLATION.INCIDENTMANAGEMENT.ERROR.required)}</label>}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-2 gap-2">
          <TNSButton buttonVariant={Variants.Primary} onClick={(): Promise<void> => submit(getValues())} disabled={!isValid || !canBeEdited}>
            {t(TRANSLATION.INCIDENTMANAGEMENT.BUTTON.update)}
          </TNSButton>
          <TNSButton buttonVariant={Variants.Secondary} onClick={goBack}>
            {t(TRANSLATION.INCIDENTMANAGEMENT.BUTTON.cancel)}
          </TNSButton>
        </div>
      </TNSContainer>
    </TNSContainer>
  );
};
