import { ApiService, IResponse } from "@tnso/api-core";
import { Day, Month } from "../../../models/devices/chart/chart";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class UptimeService extends ApiService {
  static async getMonthly(deviceName: string): Promise<IResponse<Month[]> | undefined> {
    return this.get<Month[]>(`${getApiBaseUrl()}/devices/${deviceName}/uptime/monthly`);
  }

  static async getDaily(deviceName: string, month?: string): Promise<IResponse<Day[]> | undefined> {
    return this.get<Day[]>(`${getApiBaseUrl()}/devices/${deviceName}/uptime/daily/${month}`);
  }
}
