import React from "react";
import { TRANSLATION } from "../../../../utils/const/translation";
import { useTranslation } from "react-i18next";

interface Props {
  acna: string;
  knownAs: string;
}
export const ComponentKnownAs: React.FC<Props> = ({ acna , knownAs }) => {
  const { t } = useTranslation();

  return (
    <div data-testid="member-known-as">
      <div className="d-flex align-items-start">
        <p className="m-2">{t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.partner)}: <strong className="status-text">{acna + " - " + knownAs}</strong></p>
      </div>
    </div>
  );
};
