import React, { ReactElement, useContext, useMemo } from "react";
import { TNSSelect } from "@tns/ui-components";
import { Controller, useFormContext } from "react-hook-form";
import { ConfigContext } from "../../../../../../../../../contexts/configuration/ConfigurationContext";
import { TroubleshootingContext, TroubleshootingFormValues } from "../../../../../../../components/device/troubleshooting/context/troubleshooting-context";
import { isEmpty } from "lodash";
import { TRANSLATION } from "../../../../../../../utils/const/translation";
import { useTranslation } from "react-i18next";

export const AttemptsFormSelect: React.FC = () => {
  const { t } = useTranslation();
  const context = useContext(ConfigContext);
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const name: keyof TroubleshootingFormValues = "attempts";
  const { filtersEnabled } = useContext(TroubleshootingContext);

  const options = useMemo(() => {
    const options = context.configuration?.environmentValues?.TROUBLESHOOTING_ATTEMPS?.split(",") ?? [];
    return options.map((attemp: string) => {
      return { label: attemp, value: attemp };
    });
  }, [context.configuration?.environmentValues?.TROUBLESHOOTING_ATTEMPS]);

  return (
    <div className="d-flex flex-column">
      <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.attempts)}</span>
      <div>
        <Controller
          name={name}
          control={control}
          rules={{
            required: true,
            validate: (value): boolean => {
              return isEmpty(value);
            }
          }}
          render={({ field }): ReactElement => (
            <TNSSelect
              className="w-100"
              placeholder={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.select)}
              {...(errors[name] ? { status: "error" } : {})}
              onSelect={(e): void => {
                field.onChange(Number(e));
              }}
              options={options}
              value={field.value}
              disabled={!filtersEnabled}
            />
          )}
        />
        {errors[name] && <small className="text-danger position-absolute">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.requiredField)}</small>}
      </div>
    </div>
  );
};
