import React, { useCallback, useContext, useState } from "react";
import { Flex } from "antd";
import { Variants, TNSButton, TNSInput } from "@tns/ui-components";
import { CustomizerContext } from "../../../../../contexts/customizer/CustomizerContext";

export interface TNSOSearchProps {
  themeSelected: "dark" | "light";
  keyFilter: string;
  defaultValue?: string;
  onReset: () => void;
  onSearch: (value: string | number | boolean, keyFilter: string) => void;
  close: () => void;
}

export function TNSOSearchGrid(props: TNSOSearchProps): JSX.Element {
  const customizer = useContext(CustomizerContext);
  const [searchValue, setSearchValue] = useState(props.defaultValue ?? "");
  const { onReset, onSearch } = props;

  const resetSearch = useCallback(() => {
    setSearchValue("");
    onReset();
    props.close();
  }, [onReset]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === "Enter") {
        onSearch(searchValue, props.keyFilter);
        props.close();
      }
    },
    [onSearch, searchValue, props.keyFilter]
  );

  const handleClick = useCallback(
    (searchValue: string, keyFilter: string): void => {
      onSearch(searchValue, keyFilter);
      props.close();
    },
    [onSearch, searchValue, props.keyFilter]
  );

  return (
    <Flex gap="middle" vertical className={`search-filter-grid-${customizer.selectedTheme}`} onKeyDown={(e): void => e.stopPropagation()} data-testid="search-filter">
      <TNSInput placeholder="Search" className="search-box" onChange={(e): void => setSearchValue(e.target.value)} value={searchValue} onKeyPress={handleKeyPress} />
      <Flex justify="space-between">
        <TNSButton buttonVariant={Variants.Link} onClick={resetSearch}>
          Reset
        </TNSButton>
        <TNSButton buttonVariant={Variants.Primary} onClick={(): void => handleClick(searchValue, props.keyFilter)}>
          Ok
        </TNSButton>
      </Flex>
    </Flex>
  );
}
