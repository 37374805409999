import jwtDecode from "jwt-decode";
import { Roles } from "../../interfaces/auth/roleAndPermission/role";
import { TokenPayload } from "../../interfaces/auth/login/token";
import { RoutesByRoles } from "../../../../config/authorization/routesByRoles";
import { MenuKeys } from "../../../../layouts/sidebars/vertical/Sidebar";
import { store } from "../../store/StoreMobx";

export interface AuthInfo {
  username: string | null;
  role: Roles;
  isBasic: boolean;
  isAdmin: boolean;
  isSuperUser: boolean;
  featureList: string[];
}

export class AuthHelper {
  static username: string | null = null;
  static role: Roles = Roles.Basic;
  static isBasic = true;
  static isAdmin = false;
  static isSuperUser = false;
  static featureList: string[] = [];

  public static getAuthByToken(token: string | null): AuthInfo {
    if (token) {
      const decode: TokenPayload = jwtDecode(token);
      const { Basic, Admin, SuperUser } = Roles;

      this.username = decode.username;
      this.featureList = decode.featureList ?? [];
      this.role = decode.role;
      this.isBasic = this.role === Basic;
      this.isAdmin = this.role === Admin;
      this.isSuperUser = this.role === SuperUser;
    }

    return {
      username: this.username,
      role: this.role,
      featureList: this.featureList,
      isBasic: this.isBasic,
      isAdmin: this.isAdmin,
      isSuperUser: this.isSuperUser
    };
  }

  public static getAllowedRolesForRoute(routeName: string): Roles[] {
    return RoutesByRoles[routeName as keyof typeof RoutesByRoles] ?? [];
  }

  public static redirectTo = (features: string[]): void => {
    if (features.includes("monitoring_devices")) {
      store.shared.setOpenMenuKeys([MenuKeys.Monitoring]);
      store.shared.setMenuItemSelected(MenuKeys.Devices);
      window.location.pathname = "/monitoring/devices";
    } else if (features.includes("member_connectivity")) {
      store.shared.setOpenMenuKeys([MenuKeys.Monitoring]);
      store.shared.setMenuItemSelected(MenuKeys.Members);
      window.location.pathname = "/monitoring/members";
    } else if (features.includes("contact_information")) {
      store.shared.setOpenMenuKeys([MenuKeys.Contact]);
      store.shared.setMenuItemSelected(MenuKeys.Directory);
      window.location.pathname = "/contact/contact-information";
    } else if (features.includes("change_management")) {
      store.shared.setOpenMenuKeys([MenuKeys.Support]);
      store.shared.setMenuItemSelected(MenuKeys.ChangeManagement);
      window.location.pathname = "/support/change-management";
    } else if (features.includes("contact_us")) {
      store.shared.setOpenMenuKeys([MenuKeys.Help]);
      store.shared.setMenuItemSelected(MenuKeys.ContactUs);
      window.location.pathname = "/help/contactUs";
    } else if (features.includes("user_guide")) {
      store.shared.setOpenMenuKeys([MenuKeys.Help]);
      store.shared.setMenuItemSelected(MenuKeys.UserGuide);
      window.location.pathname = "/help/userGuide";
    }
  };

  public static getRouteByRedirect = (features: string[]): string => {
    if (features.includes("monitoring_devices")) {
      return "/monitoring/devices";
    } else if (features.includes("member_connectivity")) {
      return "/monitoring/members";
    } else if (features.includes("contact_information")) {
      return "/contact/contact-information";
    } else if (features.includes("change_management")) {
      return "/support/change-management";
    } else if (features.includes("contact_us")) {
      return "/help/contactUs";
    } else if (features.includes("user_guide")) {
      return "/help/userGuide";
    }
    return "/auth/403";
  };
}
