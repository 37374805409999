import React, { useContext } from "react";
import * as darkTokens from "@tns/ui-style-dictionary/build/ts/dark-tokens";
import * as lightTokens from "@tns/ui-style-dictionary/build/ts/light-tokens";
import { Table } from "react-bootstrap";
import { DeviceMember, MembersTableTitle, membersTableTitlesTranslation } from "../../../../interfaces/memberConnectivity/memberConnectivity";
import { ItemsTunnelStatus } from "./ItemsTunnelStatus";
import { ItemsInstance } from "./ItemsInstance";
import { ItemsSrcAndDest } from "./ItemsSrcAndDest";
import { CustomizerContext } from "../../../../../../contexts/customizer/CustomizerContext";
import { Themes } from "@tns/ui-components";

interface Props {
  devices?: DeviceMember[];
}

export const MembersTable: React.FC<Props> = ({ devices }) => {
  const customizer = useContext(CustomizerContext);
  const enumValues = Object.values(MembersTableTitle);

  return (
    <Table size="sm" responsive className="members-table">
      <thead>
        <tr>
          {enumValues.map((item, index) => (
            <th
              key={index}
              className="text-center py-2"
              style={{
                backgroundColor: customizer.selectedTheme === Themes.DARK ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault
              }}>
              {membersTableTitlesTranslation(item)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {devices?.map((item, index) => (
          <tr key={index}>
            <ItemsSrcAndDest item={item} />
            <ItemsInstance interfaceDetails={item.interfaceDetails} />
            <ItemsTunnelStatus interfaceDetails={item.interfaceDetails} />
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
