import filter, { IFilter } from "./deviceProfile/Filter";
import deviceProfile, { IDeviceProfile } from "./deviceProfile/device-profile";
import contact, { IContact } from "./contact/Contact";
import userRoles, { IUserRolesStore } from "./user-roles/user-roles";
import user, { IUser } from "./user/user";

interface Store {
  deviceProfile: IDeviceProfile;
  filter: IFilter;
  contact: IContact;
  userRoles: IUserRolesStore;
  user: IUser;
}

export const store: Store = {
  deviceProfile: deviceProfile,
  filter: filter,
  contact: contact,
  userRoles: userRoles,
  user: user
};
