import { EnvironmentVariables } from "../../contexts/configuration/EnvironmentVariables";

/**
 * This class is used to create logs in console and more outputs
 */
export class LogHelper {
  /**
   * This variable is used to store the feature flags
   */
  private static environmentVariables?: EnvironmentVariables & NodeJS.ProcessEnv;

  /**
   * This function is used to initialize the feature flags
   */
  static initialize(config?: EnvironmentVariables & NodeJS.ProcessEnv): void {
    this.environmentVariables = config;
  }

  /**
   * This functions is used to create logs in console and more outputs
   * @param methodName Method name
   * @param message Message to log
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static writeLog(methodName: string, message: string, ...params: any[]): void {
    LogHelper.log(console.log, `[${methodName}]: ${message}`, ...params);
  }

  /**
   * This functions is used to create logs in console and more outputs
   * @param message Message to log
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static error(message: string, ...params: any[]): void {
    LogHelper.log(console.error, `[ERROR]: ${message}`, ...params);
  }

  /**
   * This functions is used to create logs in console and more outputs
   * @param message Message to log
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static debug(message: string, ...params: any[]): void {
    LogHelper.log(console.debug, `[DEBUG]: ${message}`, ...params);
  }

  /**
   * This functions is used to create logs in console and more outputs
   * @param message Message to log
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static warn(message: string, ...params: any[]): void {
    LogHelper.log(console.warn, `[WARN]: ${message}`, ...params);
  }

  /**
   * This functions is used to create logs in console and more outputs
   * @param message Message to log
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static info(message: string, ...params: any[]): void {
    LogHelper.log(console.info, `[INFO]: ${message}`, ...params);
  }

  /**
   * This functions is used to create logs in console and more outputs
   * @param methodName Method name
   * @param message Message to log
   * @param params Params to log
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
  private static log(method: Function, message: string, ...params: any[]): void {
    if (!this.environmentVariables) {
      console.warn("Environment variables not initialized");
      return;
    }

    if (this.environmentVariables?.Log === true || this.environmentVariables?.Log === "true") {
      method(message, ...params);
    }

    if (this.environmentVariables?.SplunkEnabled === true || this.environmentVariables?.SplunkEnabled === "true") {
      // Send data to splunk
    }
  }
}
