import { makeAutoObservable } from "mobx";
import { StatusCode } from "../../../helpers/api/RequestHelper";
import { MessageHelper } from "../../../helpers/shared/MessageHelper";
import { LVCData } from "../../../interfaces/devices/lvc/lvc";
import { LVCResponse } from "../../../interfaces/devices/response/response";
import { QueryParams } from "../../../interfaces/shared/queryParams";
import { LVCService } from "../../../service/device/deviceDetail/LVCService";

export interface ILVC {
  data?: LVCResponse;
  lvcs?: LVCData[];
  currentPage: number;
  columnsActive?: string[];
  activeFilters: Record<string, string>;

  loadData: (tnsDeviceName?: string, queryParams?: QueryParams) => Promise<LVCResponse | undefined>;
  loadDataToExport: (tnsDeviceName?: string, queryParams?: QueryParams) => Promise<LVCResponse | undefined>;
  setCurrentPage: (page: number) => void;
  setColumnsActive: (columns: string[]) => void;
  setActiveFiltersData: (keyFilter: string, valueFilter: string) => void;
  clearData: () => void;
}

class LVC implements ILVC {
  data?: LVCResponse = undefined;
  columnsActive?: string[] = [];
  lvcs?: LVCData[] = undefined;
  currentPage = 1;
  activeFilters: Record<string, string> = {};

  constructor() {
    makeAutoObservable(this);
  }

  loadData = async (tnsDeviceName?: string, queryParams?: QueryParams): Promise<LVCResponse | undefined> => {
    try {
      if (tnsDeviceName) {
        const response = await LVCService.getAll(tnsDeviceName, queryParams);
        if (response?.status === StatusCode.OK && response?.data) {
          const lvcs = response.data.lvcs?.map((lvcItem) => ({ ...lvcItem, devices: `${lvcItem.ep1DeviceName} - ${lvcItem.ep2DeviceName}` }));
          this.data = { ...response.data, lvcs };
          this.lvcs = lvcs;
          return this.data;
        }
      }
    } catch (error) {
      MessageHelper.errorMessage("Error loading lvc");
    }
  };

  loadDataToExport = async (tnsDeviceName?: string, queryParams?: QueryParams): Promise<LVCResponse | undefined> => {
    try {
      if (tnsDeviceName) {
        const response = await LVCService.getAll(tnsDeviceName, queryParams);
        if (response?.status === StatusCode.OK && response?.data) {
          const lvcs = response.data.lvcs?.map((lvcItem) => ({ ...lvcItem, devices: `${lvcItem.ep1DeviceName} - ${lvcItem.ep2DeviceName}` }));
          return { ...response.data, lvcs };
        }
      }
    } catch (error) {
      MessageHelper.errorMessage("Error loading lvc");
    }
  };

  setColumnsActive = (columns: string[]): void => {
    this.columnsActive = columns;
  };

  setCurrentPage = (page: number): void => {
    this.currentPage = page;
  };

  setActiveFiltersData = (keyFilter: string, valueFilter: string): void => {
    this.activeFilters[keyFilter] = valueFilter;
  };

  clearData = (): void => {
    this.data = undefined;
    this.lvcs = undefined;
    this.currentPage = 1;
    this.activeFilters = {};
  };
}

const lvc = new LVC();

export default lvc;
