import React, { ReactElement, useContext } from "react";
import { TNSInput } from "@tns/ui-components";
import { Controller, useFormContext } from "react-hook-form";
import { TRANSLATION } from "../../../../../../../utils/const/translation";
import { useTranslation } from "react-i18next";
import { TroubleshootingContext, TroubleshootingFormValues } from "../../../../../../../components/device/troubleshooting/context/troubleshooting-context";
import { TroubleshootingHelper } from "../../../../../../../helpers/device/TroubleshootingHelper";

export const HostNameFormInput: React.FC = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const { isFQDN } = TroubleshootingHelper();
  const name: keyof TroubleshootingFormValues = "hostname";
  const { filtersEnabled } = useContext(TroubleshootingContext);

  return (
    <div className="d-flex flex-column w-25">
      <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.hostname)}</span>
      <div>
        <Controller
          name={name}
          control={control}
          rules={{
            required: true,
            validate: (value) => isFQDN(value)
          }}
          render={({ field }): ReactElement => (
            <TNSInput
              className="w-100"
              placeholder={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.pleaseEnterHostName)}
              {...(errors[name] ? { status: "error" } : {})}
              onChange={(e): void => {
                field.onChange(e);
              }}
              value={field.value}
              disabled={!filtersEnabled}
            />
          )}
        />
        {errors[name] && <small className="text-danger position-absolute">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.inputValidHostname)}</small>}
      </div>
    </div>
  );
};
