import { makeAutoObservable } from "mobx";
import { Roles } from "../../interfaces/auth/roleAndPermission/role";
import { MessageHelper } from "../../helpers/shared/MessageHelper";
import { TokenHelper } from "../../helpers/token/TokenHelper";
import { User, UserInfo } from "../../interfaces/auth/login/login";
import { SignInResponse } from "../../interfaces/auth/response/response";
import { AuthService } from "../../service/auth/AuthService";
import { UserService } from "../../service/user/UserService";
import { TRANSLATION } from "../../utils/const/translation";
import { i18nInstance as i18n } from "../../../../i18n";
import { StorageHelper } from "../../helpers/shared/StorageHelper";
import { store } from "../StoreMobx";
import { MenuKeys } from "../../../../layouts/sidebars/vertical/Sidebar";
import { IFeatureGroupsByUserRole } from "../../../administration/models/user-role.model";
import { AuthHelper } from "../../helpers/auth/AuthHelper";
import { Authorization } from "@tnso/roles-and-permissions";

export interface IAuth {
  data?: SignInResponse;
  user?: User;
  userInfo: UserInfo | null;
  isLogged: boolean;
  signInResponse?: SignInResponse;
  userDecoded?: UserInfo;
  customRoleFeatures: IFeatureGroupsByUserRole[];
  sessionId?: string;
  isActAs: boolean;
  login: (user: User) => Promise<SignInResponse | undefined>;
  logout: () => void;
  loadCustomRoleFeature: (roleCustomFeatureGroupList: string[]) => IFeatureGroupsByUserRole[];
  actAs: (username: string) => Promise<void>;
  stopActAs: (baseUrl?: string) => void;
  resetCutomRoleFeatures: () => void;
  processLogin: (data?: SignInResponse | User, accessToken?: string, refreshToken?: string) => Promise<SignInResponse | undefined>;
}
class Auth implements IAuth {
  data?: SignInResponse = undefined;
  user?: User = undefined;
  userInfo: UserInfo | null = null;
  isLogged = false;
  signInResponse?: SignInResponse = undefined;
  userDecoded?: UserInfo = undefined;
  customRoleFeatures: IFeatureGroupsByUserRole[] = [];
  sessionId?: string = undefined;
  isActAs = false;
  constructor() {
    makeAutoObservable(this);
    this.userInfo = StorageHelper.gteItemParsed<UserInfo>("user");
    this.isActAs = StorageHelper.getItem("actAs") === "" ? false : true;
  }

  processLogin = async (data?: SignInResponse | User, accessToken?: string, refreshToken?: string): Promise<SignInResponse | undefined> => {
    try {
      if (data) {
        this.data = data as SignInResponse;
      }

      const token = (accessToken || this.data?.accessToken) ?? "";
      const refresh = (refreshToken || this.data?.refreshToken) ?? "";

      this.isLogged = true;
      this.userDecoded = await TokenHelper.setUserInfoByToken(token);
      this.loadCustomRoleFeature(this.userDecoded?.customRoleFeatureGroupList ?? this.userDecoded?.featureList ?? []);
      this.sessionId = new Date().getTime().toString();
      localStorage.setItem("sessionId", this.sessionId);
      localStorage.setItem("accessToken", token);
      localStorage.setItem("refreshToken", refresh);
      Authorization.setToken(token);
      if (this.userDecoded) {
        StorageHelper.setItem(
          "user",
          JSON.stringify({
            username: this.userDecoded.username,
            firstName: this.userDecoded.firstName,
            lastName: this.userDecoded.lastName,
            email: this.userDecoded.email,
            timeZone: this.userDecoded.timeZone,
            role: this.userDecoded.role,
            companyProfile: this.userDecoded.companyProfile,
            entityProfile: this.userDecoded.entityProfile,
            companyProfilesList: this.userDecoded.companyProfilesList,
            featureList: this.userDecoded.featureList,
            customRoleFeatureGroupList: this.userDecoded.customRoleFeatureGroupList
          })
        );
        StorageHelper.setItem("roleCustomFeatureGroupList", JSON.stringify(this.customRoleFeatures));
        StorageHelper.setItem("acnasList", JSON.stringify(this.userDecoded.acnasList));
      }

      this.userInfo = {
        username: this.userDecoded?.username || "",
        firstName: this.userDecoded?.firstName || "",
        lastName: this.userDecoded?.lastName || "",
        email: this.userDecoded?.email || "",
        timeZone: this.userDecoded?.timeZone || "",
        role: this.userDecoded?.role || Roles.Basic,
        companyProfile: this.userDecoded?.companyProfile || "",
        entityProfile: this.userDecoded?.entityProfile || "",
        companyProfilesList: this.userDecoded?.companyProfilesList || [],
        customRoleFeatureGroupList: this.userDecoded?.customRoleFeatureGroupList || [],
        featureList: this.userDecoded?.featureList || [],
        acnasList: this.userDecoded?.acnasList || []
      };

      if (localStorage.getItem("isLogged") !== "true") {
        if (this.userDecoded?.role === Roles.SuperUser || this.userDecoded?.role === Roles.CAM) {
          store.shared.setOpenMenuKeys([MenuKeys.Administration]);
          store.shared.setMenuItemSelected(MenuKeys.CompanyAdministration);
        } else if (store.shared.menuItemSelected !== MenuKeys.UserAdministration) {
          store.shared.setOpenMenuKeys([MenuKeys.Monitoring]);
          store.shared.setMenuItemSelected(MenuKeys.Devices);
        }
      }

      localStorage.setItem("isLogged", "true");
      await TokenHelper.validateExpiredCognitoToken();
      return this.data;
    } catch (error) {
      console.warn(`${i18n.t(TRANSLATION.ERROR.errorSigningIn)} ${error}`);
      throw error;
    }
  };

  login = async (user: User): Promise<SignInResponse | undefined> => {
    try {
      const data = await AuthService.signIn(user);
      if (data) {
        await this.processLogin(data);
      }

      return this.data;
    } catch (error) {
      console.warn(`${i18n.t(TRANSLATION.ERROR.errorSigningIn)} ${error}`);
    }
  };
  logout = (): void => {
    this.data = undefined;
    this.isLogged = false;
    this.userDecoded = undefined;
    this.sessionId = undefined;
    Authorization.setToken("");
    localStorage.clear();
    window.location.reload();
  };

  actAs = async (username: string): Promise<void> => {
    try {
      const autoRefresh = localStorage.getItem("autoRefresh");
      const response = await UserService.actAs({ username });
      if (response?.data) {
        this.data = response.data as SignInResponse;
        this.userDecoded = await TokenHelper.setUserInfoByToken(this.data.accessToken);
        this.loadCustomRoleFeature(this.userDecoded?.customRoleFeatureGroupList ?? []);
        this.isActAs = true;
        StorageHelper.clear();
        if (this.userDecoded) {
          localStorage.setItem(
            "user",
            JSON.stringify({
              username: this.userDecoded.username,
              firstName: this.userDecoded.firstName,
              lastName: this.userDecoded.lastName,
              email: this.userDecoded.email,
              timeZone: this.userDecoded.timeZone,
              role: this.userDecoded.role,
              companyProfile: this.userDecoded.companyProfile,
              entityProfile: this.userDecoded.entityProfile,
              companyProfilesList: this.userDecoded.companyProfilesList,
              customRoleFeatureGroupList: this.userDecoded.customRoleFeatureGroupList,
              featureList: this.userDecoded.featureList
            })
          );
          StorageHelper.setItem("roleCustomFeatureGroupList", JSON.stringify(this.customRoleFeatures));
          StorageHelper.setItem("acnasList", JSON.stringify(this.userDecoded.acnasList));
        }
        localStorage.setItem("actAs", "true");
        localStorage.setItem("autoRefresh", autoRefresh ?? "false");
        localStorage.setItem("accessToken", this.data.accessToken);
        localStorage.setItem("refreshToken", this.data.refreshToken);
        Authorization.setToken(this.data.accessToken);
        if (this.userDecoded?.role === Roles.SuperUser || this.userDecoded?.role === Roles.Admin) {
          store.shared.setOpenMenuKeys([MenuKeys.Administration]);
          store.shared.setMenuItemSelected(MenuKeys.UserAdministration);
          window.location.replace("/administration/user");
        } else if (this.userDecoded?.role === Roles.Basic) {
          store.shared.setOpenMenuKeys([MenuKeys.Monitoring]);
          store.shared.setMenuItemSelected(MenuKeys.Devices);
          window.location.replace("/");
        } else if (this.userDecoded?.role === Roles.CAM) {
          store.shared.setOpenMenuKeys([MenuKeys.Administration]);
          store.shared.setMenuItemSelected(MenuKeys.CompanyAdministration);
          window.location.replace("/administration/company");
        } else {
          AuthHelper.redirectTo(this.userDecoded?.featureList || []);
        }
      }
    } catch (error) {
      MessageHelper.errorMessage(`${i18n.t(TRANSLATION.MODAL.ALERT.errorActingAsUser)}, ${error}`);
    }
  };

  stopActAs = async (baseUrl?: string): Promise<void> => {
    try {
      if (baseUrl) {
        const response = await AuthService.validateToken(baseUrl);
        if (response?.accessToken && response?.refreshToken) {
          this.userDecoded = await TokenHelper.setUserInfoByToken(response.accessToken);
          this.loadCustomRoleFeature(this.userDecoded?.customRoleFeatureGroupList ?? []);
          if (this.userDecoded) {
            StorageHelper.setItem(
              "user",
              JSON.stringify({
                username: this.userDecoded.username,
                firstName: this.userDecoded.firstName,
                lastName: this.userDecoded.lastName,
                email: this.userDecoded.email,
                timeZone: this.userDecoded.timeZone,
                role: this.userDecoded.role,
                companyProfile: this.userDecoded.companyProfile,
                entityProfile: this.userDecoded.entityProfile,
                companyProfilesList: this.userDecoded.companyProfilesList,
                customRoleFeatureGroupList: this.userDecoded.customRoleFeatureGroupList,
                featureList: this.userDecoded.featureList
              })
            );
            StorageHelper.setItem("roleCustomFeatureGroupList", JSON.stringify(this.customRoleFeatures));
            StorageHelper.setItem("acnasList", JSON.stringify(this.userDecoded.acnasList));
          }
          StorageHelper.setItem("accessToken", response.accessToken);
          StorageHelper.setItem("refreshToken", response.refreshToken);
          StorageHelper.removeItem("actAs");
          StorageHelper.removeItem("autoRefreshToActAs");
          Authorization.setToken(response.accessToken);
          this.isActAs = false;
          if (this.userDecoded?.role === Roles.SuperUser || this.userDecoded?.role === Roles.Admin) {
            store.shared.setOpenMenuKeys([MenuKeys.Administration]);
            store.shared.setMenuItemSelected(MenuKeys.UserAdministration);
            window.location.replace("/administration/user");
          } else if (this.userDecoded?.role === Roles.CAM) {
            store.shared.setOpenMenuKeys([MenuKeys.Administration]);
            store.shared.setMenuItemSelected(MenuKeys.CompanyAdministration);
            window.location.replace("/administration/company");
          } else {
            store.shared.setOpenMenuKeys([MenuKeys.Monitoring]);
            store.shared.setMenuItemSelected(MenuKeys.Devices);
            window.location.replace("/monitoring/devices");
          }
        }
      }
    } catch (error) {
      MessageHelper.errorMessage(`${i18n.t(TRANSLATION.ERROR.errorStoppingActAsUser)}, ${error}`);
    }
  };

  loadCustomRoleFeature = (roleCustomFeatureGroupList: string[]): IFeatureGroupsByUserRole[] => {
    this.customRoleFeatures = roleCustomFeatureGroupList.map((feature) => ({
      name: feature,
      associated: false
    }));
    localStorage.setItem("roleCustomFeatureGroupList", JSON.stringify(this.customRoleFeatures));
    return this.customRoleFeatures;
  };

  resetCutomRoleFeatures = (): void => {
    const customRole = JSON.parse(localStorage.getItem("roleCustomFeatureGroupList") as string) as IFeatureGroupsByUserRole[];
    this.customRoleFeatures = customRole;
  };
}
const auth = new Auth();
export default auth;
