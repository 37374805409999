import { ApiService, IResponse } from "@tnso/api-core";
import { StorageUsageMonthly, CPUCoresData, TemperatureData, MemoryUsageMonthly, QueryParamsGraphs } from "../../../models/devices/hardware/hardware";
import { encodeParams } from "../../../../portal-app/helpers/api/RequestHelper";
import { IDeviceMetrics } from "../../../models/devices/dhi/dhi";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class HardwareService extends ApiService {
  static async getAll(tnsDeviceName: string): Promise<IResponse<IDeviceMetrics> | undefined> {
    return this.get<IDeviceMetrics>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/metrics`);
  }

  static async getStorageUsageMonthly(tnsDeviceName: string): Promise<IResponse<StorageUsageMonthly[]> | undefined> {
    return this.get<StorageUsageMonthly[]>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/metrics/storage/monthly`);
  }

  static async getStorageUsageMonthlyWithMonth(tnsDeviceName: string, month: string, day: number | null): Promise<IResponse<StorageUsageMonthly[]> | undefined> {
    const urlSearchParams = encodeParams<QueryParamsGraphs>((day ? { day } : null) as QueryParamsGraphs);
    return this.get<StorageUsageMonthly[]>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/metrics/storage/${month}`, urlSearchParams);
  }

  static async getCPUUsageMonthly(tnsDeviceName: string): Promise<IResponse<CPUCoresData> | undefined> {
    return this.get<CPUCoresData>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/metrics/cpu/monthly`);
  }

  static async getCPUUsageMonthlyWithMonth(tnsDeviceName: string, month: string, day: number | null): Promise<IResponse<CPUCoresData> | undefined> {
    const urlSearchParams = encodeParams<QueryParamsGraphs>((day ? { day } : null) as QueryParamsGraphs);
    return this.get<CPUCoresData>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/metrics/cpu/${month}`, urlSearchParams);
  }

  static async getMemoryUsageMonthly(tnsDeviceName: string): Promise<IResponse<MemoryUsageMonthly[]> | undefined> {
    return this.get<MemoryUsageMonthly[]>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/metrics/memory/monthly`);
  }

  static async getMemoryUsageMonthlyWithMonth(tnsDeviceName: string, month: string, day: number | null): Promise<IResponse<MemoryUsageMonthly[]> | undefined> {
    const urlSearchParams = encodeParams<QueryParamsGraphs>((day ? { day } : null) as QueryParamsGraphs);
    return this.get<MemoryUsageMonthly[]>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/metrics/memory/${month}`, urlSearchParams);
  }

  static async getTemperatureMonthly(tnsDeviceName: string): Promise<IResponse<TemperatureData> | undefined> {
    return this.get<TemperatureData>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/metrics/temperature/monthly`);
  }

  static async getTemperatureMonthlyWithMonth(tnsDeviceName: string, month: string, day: number | null): Promise<IResponse<TemperatureData> | undefined> {
    const urlSearchParams = encodeParams<QueryParamsGraphs>((day ? { day } : null) as QueryParamsGraphs);
    return this.get<TemperatureData>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/metrics/temperature/${month}`, urlSearchParams);
  }
}
