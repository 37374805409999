import { ApiCore } from "@tnso/api-core";
import { Notifications } from "@tnso/api-sdk";
import { ColumnsType } from "antd/es/table";
import { TNSTable } from "@tns/ui-components";
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { NotificationContext, NotificationsResponse } from "src/contexts/notifications/notifications-context";
import { useNotificationTableConfig } from "src/contexts/notifications/use-notification-table";
import { useAsyncCall } from "../../../../../libraries/shared/src/lib/hooks/use-async-call-shared";
import { NotificationHelper } from "../../helpers/notifications/notification-helper";
import { NotificationQueryParams, NotificationToExport } from "src/shared/models/notification.model";
import { SorterResult } from "antd/es/table/interface";
import { useTranslation } from "react-i18next";
import { useExportDataTable } from "@tnso/shared";
import { TRANSLATION } from "../../utils/const/translation";
import { MapperHelper } from "src/shared/helpers/MapperHelper";
import _ from "lodash";
import { store } from "../../store/StoreMobx";

export interface NotificationsTrayProps {
  setNotificationsSelected: (notifications?: Notifications) => Promise<void>;
  notificationSelected?: Notifications;
  setSelectedRows: Dispatch<SetStateAction<number[]>>;
  selectedRows: number[];
  isAllSelected: boolean;
  setIsAllSelected: Dispatch<SetStateAction<boolean>>;
  notifications?: NotificationsResponse;
  referenceId?: string;
  setParams: (newParams: NotificationQueryParams) => void;
}

export const NotificationTray: React.FC<NotificationsTrayProps> = ({
  setNotificationsSelected,
  notificationSelected,
  setSelectedRows,
  selectedRows,
  isAllSelected,
  setIsAllSelected,
  notifications,
  referenceId,
  setParams
}) => {
  const notificationsContext = useContext(NotificationContext);
  const { t } = useTranslation();
  const [notificationIds, setNotificationIds] = useState<number[]>([]);
  const [prevParams, setPrevParams] = useState<NotificationQueryParams>({ startAtRecord: 0, recordsPerPage: 10, sortBy: "createdAt", orderBy: "DESC", referenceId });
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadNotifications = useCallback(
    async (params?: NotificationQueryParams): Promise<void> => {
      await store.device.detail.loadData(referenceId);
      const queryParams = { ...prevParams, ...params };
      setParams(queryParams);
      const response = await notificationsContext.setNotificationsData(queryParams);
      if (response?.notifications && response.notifications.length > 0) {
        setNotificationIds(NotificationHelper.getIdsByNotifications(response.notifications));
        setIsLoading(false);
      } else {
        await setNotificationsSelected();
      }
      delete queryParams.referenceId;
      delete queryParams.startAtRecord;
      delete queryParams.recordsPerPage;
      delete queryParams.sortBy;
      delete queryParams.orderBy;
      const isEmpty = _.isEmpty(queryParams);
      setHasNotifications(!isEmpty);
    },
    [prevParams]
  );

  const handleChangePage = useCallback(
    async (page: number): Promise<void> => {
      setIsLoading(true);
      const params = ApiCore.builderQueryParams(
        {
          currentPage: page - 1
        },
        prevParams
      );
      setPrevParams({ ...notificationsContext.params, ...params });
      setParams({ ...notificationsContext.params, ...params });
      setCurrentPage(page);
      await setNotificationsSelected();
      await loadNotifications({ ...notificationsContext.params, ...params });
    },
    [prevParams, notificationsContext.params]
  );

  const handleSort = useCallback(
    async (_: number, __: Record<string, string[] | null>, sorter: SorterResult<Notifications>): Promise<void> => {
      if (notificationsContext.notifications?.notifications && notificationsContext.notifications?.notifications.length > 0) {
        setIsLoading(true);
        const params = { ...prevParams, sortBy: "createdAt", orderBy: sorter.order === "ascend" ? "ASC" : "DESC" };
        setPrevParams({ ...notificationsContext.params, ...params });
        setParams({ ...notificationsContext.params, ...params });
        setCurrentPage(1);
        await loadNotifications();
      }
    },
    [prevParams, notificationsContext.params, loadNotifications, notificationsContext.notifications?.notifications]
  );

  useEffect(() => {
    setIsAllSelected(NotificationHelper.getIsAllChecked(notificationIds, selectedRows));
  }, [notificationIds, selectedRows]);

  const loader = useAsyncCall(loadNotifications, [loadNotifications], referenceId ? store.shared.getTimeToAutoRefresh() : undefined);

  const tableConfig = useNotificationTableConfig(
    notificationIds,
    setSelectedRows,
    selectedRows,
    setIsAllSelected,
    isAllSelected,
    loadNotifications,
    setCurrentPage,
    setIsLoading,
    notificationSelected
  );
  const handleExportData = useExportDataTable<NotificationToExport, NotificationsResponse, NotificationQueryParams>(
    notificationsContext.notifications?.totalRecords ?? 0,
    10,
    notificationsContext.getNotifications,
    prevParams,
    referenceId ? `${t(TRANSLATION.NOTIFICATIONS.notifications)}_${referenceId}` : t(TRANSLATION.NOTIFICATIONS.notifications),
    MapperHelper.mapNotifications,
    true
  );

  const handleExport = useCallback(async (): Promise<void> => {
    setIsLoadingExport(true);
    await handleExportData();
    setIsLoadingExport(false);
  }, [handleExportData, prevParams, referenceId, notificationsContext]);

  return (
    <TNSTable
      dataSource={notifications?.notifications}
      columns={loader.loading || isLoading ? (tableConfig.skeletonColumns as ColumnsType[]) : (tableConfig.notificationsCloumns as ColumnsType[])}
      totalRecords={notifications?.totalRecords}
      currentPage={currentPage}
      showPagination
      emptyMessage={!hasNotifications ? t(TRANSLATION.NOTIFICATIONS.youHaveNoNotifications) : t(TRANSLATION.SHARED.DATATABLE.noDataAvailable)}
      onChange={handleSort}
      handleSelectRow={setNotificationsSelected}
      exportLabel={t("export")}
      recordsTranslated={[t("records"), t("of")]}
      isLoading={loader.loading || isLoading}
      handleGoToPage={handleChangePage}
      onExport={handleExport}
      isLoadingExport={isLoadingExport}
      whitoutCard
    />
  );
};
