import React, { useCallback, useState } from "react";
import { SelectWidth, TNSSelect } from "@tns/ui-components";
import { Select } from "antd";

export interface Option {
  value: string;
  label: string;
}

interface SelectFilterProps {
  key: string;
  options?: Option[];
  onSearch: (value: string, key: string) => Promise<void>;
  defaultValue?: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
}

const SelectFilter: React.FC<SelectFilterProps> = ({ value, options, onSearch, key, defaultValue, placeholder }) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value ?? defaultValue);

  const handleChange = useCallback(
    async (value: string | undefined): Promise<void> => {
      if (value === "") {
        setSelectedValue(undefined);
        await onSearch("", key);
      } else {
        setSelectedValue(value);
        if (value !== "") {
          await onSearch(value as string, key);
        }
      }
    },
    [selectedValue, key, onSearch]
  );

  return (
    <TNSSelect
      onChange={handleChange}
      value={value ?? selectedValue}
      placeholder={placeholder}
      width={SelectWidth.md}
      allowClear={true}
      onClear={async (): Promise<void> => await handleChange("")}>
      {options?.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          <span>{option.label}</span>
        </Select.Option>
      ))}
    </TNSSelect>
  );
};

export default SelectFilter;
