import { DevicesResponse, EditGroupsResponse } from "../../models/devices/response/response";
import { Device, DeviceAddress, DeviceCustomerName, DeviceDetail, DeviceGroups, DeviceParams, ServiceStatus } from "../../models/devices/devices";
import { ApiService, ApiCore, IResponse } from "@tnso/api-core";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class DeviceService extends ApiService {
  static async getAll(params?: DeviceParams): Promise<IResponse<DevicesResponse> | undefined> {
    const urlSearchParams = ApiCore.encodeParams<DeviceParams>(params ?? {});
    return this.get<DevicesResponse>(`${getApiBaseUrl()}/devices`, urlSearchParams);
  }

  static async getDetail(tnsDeviceName: string): Promise<IResponse<DeviceDetail> | undefined> {
    return this.get<DeviceDetail>(`${getApiBaseUrl()}/devices/${tnsDeviceName}`);
  }

  static async getGroups(tnsDeviceName: string): Promise<IResponse<DeviceGroups[]> | undefined> {
    return this.get<DeviceGroups[]>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/groups`);
  }

  static async getModels(featureName: string): Promise<IResponse<string[]> | undefined> {
    return this.get<string[]>(`${getApiBaseUrl()}/devices/models/features/${encodeURIComponent(featureName)}`);
  }

  static async create(device: Device): Promise<IResponse<Device> | undefined> {
    return this.post<Device>(`${getApiBaseUrl()}/devices`, device);
  }

  static async delete(deviceName: string): Promise<IResponse<Device> | undefined> {
    return this.remove<Device>(`${getApiBaseUrl()}/devices/${deviceName}`);
  }

  static async getStatus(params?: DeviceParams): Promise<IResponse<ServiceStatus> | undefined> {
    const urlSearchParams = ApiCore.encodeParams<DeviceParams>(params as DeviceParams);
    return this.get<ServiceStatus>(`${getApiBaseUrl()}/devices/count/connectivitystatus`, urlSearchParams);
  }

  static async getCarriers(): Promise<IResponse<string[]> | undefined> {
    return this.get<string[]>(`${getApiBaseUrl()}/devices/carriers`);
  }

  static async updateAddress(data: DeviceAddress, tnsDeviceName: string): Promise<IResponse<DeviceAddress> | undefined> {
    return this.patch<DeviceAddress>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/address`, data);
  }

  static async updateCustomerDeviceName(customerDeviceName: DeviceCustomerName, tnsDeviceName: string): Promise<IResponse<DeviceCustomerName> | undefined> {
    return this.patch<DeviceCustomerName>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/customerdevicename`, customerDeviceName);
  }

  static async updateGroups(groups: string[], tnsDeviceName: string): Promise<IResponse<EditGroupsResponse> | undefined> {
    return this.patch<EditGroupsResponse>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/groups`, { groups });
  }
}
