import React, { useCallback, useContext, useMemo } from "react";
import { LegendOptions } from "./LegendOptions";
import i18n from "../../../../../i18n";
import { TRANSLATION } from "../../../utils/const/translation";
import { COLORS } from "../../../utils/const/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { TNSTooltip, Themes } from "@tns/ui-components";
import { useTranslation } from "react-i18next";
import { CustomizerContext } from "../../../../../contexts/customizer/CustomizerContext";
import { SIZE } from "../../../utils/const/sizesDefinition";
import { store } from "../../../store/StoreMobx";
import { MenuKeys } from "../../../../../layouts/sidebars/vertical/Sidebar";

interface Props {
  isClosedPeriod?: boolean;
}
export const LegendDaily: React.FC<Props> = ({ isClosedPeriod }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const customizer = useContext(CustomizerContext);
  const iconColor = useMemo(() => (customizer.selectedTheme === Themes.DARK ? COLORS.CHARTS.ICON_DARK : COLORS.CHARTS.ICON_LIGHT), [customizer.selectedTheme]);

  const handleRedirect = useCallback(() => {
    navigate("/help/userGuide/41");
    store.shared.setOpenMenuKeys([MenuKeys.Help]);
    store.shared.setMenuItemSelected(MenuKeys.UserGuide);
  }, [navigate]);
  return (
    <div data-testid="legend-daily-component">
      <TNSTooltip
        title={
          <>
            <LegendOptions
              color={COLORS.BARS.ACTUAL_NO_OVERAGE}
              borderColor={COLORS.BARS.TRANSPARENT}
              label={i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARUSAGECHART.dailyDataUsage)}
              width={SIZE.CELLULAR_USAGE_CHART.LEGENDS_WIDTH}
            />
            <LegendOptions
              color={COLORS.CHARTS.AREA.NO_OVERAGE_BACKGROUND}
              borderColor={COLORS.BARS.GREEN}
              label={i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARUSAGECHART.cumulativeDataUsage)}
              width={SIZE.CELLULAR_USAGE_CHART.LEGENDS_WIDTH}
            />
            <LegendOptions
              color={COLORS.CHARTS.AREA.OVERAGE_BACKGROUND}
              borderColor={COLORS.BARS.RED}
              label={i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARUSAGECHART.cumulativeDataUsageOverage)}
              width={SIZE.CELLULAR_USAGE_CHART.LEGENDS_WIDTH}
            />
            {!isClosedPeriod && (
              <LegendOptions
                color={COLORS.BARS.PREDICTED_BACKGROUND}
                borderColor={COLORS.BARS.PREDICTED_BORDER}
                label={i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARUSAGECHART.predictedDailyUsage)}
                width={SIZE.CELLULAR_USAGE_CHART.LEGENDS_WIDTH}
              />
            )}
            <LegendOptions
              color={COLORS.BARS.TRANSPARENT}
              dashed
              label={i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARUSAGECHART.cumulativeDataUsagePredicted)}
              borderColor={COLORS.BARS.GREEN}
              width={SIZE.CELLULAR_USAGE_CHART.LEGENDS_WIDTH}
              height={SIZE.CELLULAR_USAGE_CHART.LEGENDS_HEIGHT_LINES}
            />
            <LegendOptions
              color={COLORS.BARS.TRANSPARENT}
              dashed
              label={i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARUSAGECHART.cumulativeDataUsagePredictedOverage)}
              borderColor={COLORS.BARS.RED}
              width={SIZE.CELLULAR_USAGE_CHART.LEGENDS_WIDTH}
              height={SIZE.CELLULAR_USAGE_CHART.LEGENDS_HEIGHT_LINES}
            />
            <LegendOptions
              color={COLORS.BARS.ORANGE}
              label={i18n.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARUSAGECHART.celularUsageDataPlan)}
              borderColor={COLORS.BARS.ORANGE}
              width={SIZE.CELLULAR_USAGE_CHART.LEGENDS_WIDTH}
              height={SIZE.CELLULAR_USAGE_CHART.LEGENDS_HEIGHT_LINES}
            />
          </>
        }
        placement="topLeft">
        <FontAwesomeIcon color={iconColor} icon={faCircleInfo} className="cursor-pointer mx-1" onClick={handleRedirect} />
        {t("legends")}
      </TNSTooltip>
    </div>
  );
};
