import React, { ReactNode, useMemo } from "react";
import { MetricCard } from "./metric-card";
import { DeviceMetricsHelper } from "../../../../../helpers/dhi/device-metrics-helper";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../../utils/const/translation";
import { IDeviceTemperatureUnit } from "../../../../../models/devices/dhi/dhi";
import "../device-health-information.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { TNSOBadge, TNSOTooltip } from "@tnso/ui-components";

export interface TemperatureCardProps {
  temperatureInCelsius?: number;
  temperatureInFahrenheit?: number;
}

export const TemperatureCard: React.FC<TemperatureCardProps> = ({ temperatureInCelsius, temperatureInFahrenheit }) => {
  const { t } = useTranslation();
  const colorTemperature = DeviceMetricsHelper.getColorTemperature(temperatureInCelsius, IDeviceTemperatureUnit.C);
  const temperature = (
    <h6 {...(colorTemperature ? { style: { color: colorTemperature } } : {})}>
      {temperatureInCelsius || temperatureInCelsius === 0 ? <span>{temperatureInCelsius}°C</span> : "N/A"} /{" "}
      {temperatureInFahrenheit || temperatureInFahrenheit === 0 ? <span>{temperatureInFahrenheit}°F</span> : "N/A"}
    </h6>
  );

  const tooltipContent = useMemo((): ReactNode => {
    return (
      <div className="d-flex flex-column">
        <small className="d-flex gap-2">
          <TNSOBadge color="#42C53F" /> {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.normal)}:{" <65°C / <149°F"}
        </small>
        <small className="d-flex gap-2">
          <TNSOBadge color="#EB9646" /> {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.warning)}:{" >65°C / >149°F - <75°C / <167°F"}
        </small>
        <small className="d-flex gap-2">
          <TNSOBadge color="#FF2D2E" /> {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.critical)}:{" >=75°C / >=167°F"}
        </small>
      </div>
    );
  }, [t]);

  return (
    <MetricCard
      header={
        <span>
          {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.temperature)}{" "}
          <TNSOTooltip title={tooltipContent}>
            <FontAwesomeIcon icon={faCircleInfo} />
          </TNSOTooltip>
        </span>
      }
      data={temperature}
    />
  );
};
