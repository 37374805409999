import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TNSOTooltip } from "@tnso/ui-components";
import { TRANSLATION } from "../../../../portal-app/utils/const/translation";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LVCTableTitleProps {
  title: string;
  tooltipText?: string;
}

export const LVCTableTitle: React.FC<LVCTableTitleProps> = ({ title, tooltipText }) => {
  const { t } = useTranslation();

  const columnTitle = useMemo(() => {
    return t(TRANSLATION.SHARED.TABLE[title as keyof typeof TRANSLATION.SHARED.TABLE]);
  }, [title, t]);

  const columnTooltip = useMemo(() => {
    return t(TRANSLATION.TOOLTIP.LVC[tooltipText as keyof typeof TRANSLATION.TOOLTIP.LVC]);
  }, [tooltipText, t]);

  return (
    <div className="d-flex align-items-center justify-content-between gap-2">
      <span title={columnTitle}>{columnTitle}</span>
      {tooltipText && (
        <TNSOTooltip title={columnTooltip}>
          <FontAwesomeIcon icon={faCircleInfo} />
        </TNSOTooltip>
      )}
    </div>
  );
};
