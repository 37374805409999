import { TNSTable } from "@tns/ui-components";
import { ExportXLSLHelper, TypeFile, useAsyncCall } from "@tnso/shared";
import { ColumnsType } from "antd/es/table";
import _ from "lodash";
import { observer } from "mobx-react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useTableConfig } from "src/app/portal-app/components/device/softwareTab/useTableConfig";
import { MapperHelper } from "src/app/portal-app/helpers/shared/MapperHelper";
import { store } from "src/app/portal-app/store/StoreMobx";
import { TRANSLATION } from "src/app/portal-app/utils/const/translation";
import { paginationSizeChangerContext } from "src/contexts/paginationSizeChanger/paginationSizeChangerContext";
import { utils } from "xlsx";
import { TranslationHelper } from "../../../../../../../../shared/helpers/TranslationHelper";
import "./Software.scss";

export const SoftwareTab: React.FC = observer(() => {
  const { t } = useTranslation();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const { columns, softwareDataPaginated, currentPage, handleChangePage, handlePaginationSizeChange, skeletonColumns, handleLoadData, paginationSize, softwareData } =
    useTableConfig();
  const { paginationPageSizeOptions } = paginationSizeChangerContext();
  const { deviceName } = useParams();

  const loadData = useCallback(async (): Promise<void> => {
    await handleLoadData();
  }, [handleLoadData]);

  const loader = useAsyncCall(loadData, [loadData], store.shared.getTimeToAutoRefresh());

  const handleExportData = useCallback(async () => {
    if (deviceName) {
      setIsLoadingExport(true);
      if (softwareData && !_.isEmpty(softwareData)) {
        const wb = utils.book_new();
        ExportXLSLHelper.addSheetToBook(wb, MapperHelper.mapToExportSoftwares(softwareData), `${deviceName}_Software`);
        ExportXLSLHelper.exportToXLSL(wb, `${deviceName}_Software`, TypeFile.CSV);
        setIsLoadingExport(false);
      }
    }
  }, [softwareDataPaginated, deviceName, setIsLoadingExport]);

  const grid = useMemo(() => {
    return (
      <TNSTable
        currentPage={currentPage}
        dataSource={softwareDataPaginated}
        columns={
          loader.loading
            ? TranslationHelper.columnsTranslation(skeletonColumns as unknown as ColumnsType[])
            : TranslationHelper.columnsTranslation(columns as unknown as ColumnsType[])
        }
        isLoading={loader.loading}
        totalRecords={softwareData.length}
        showPagination
        handleGoToPage={handleChangePage}
        pageSize={paginationSize}
        paginationPageSizeOptions={paginationPageSizeOptions}
        recordsTranslated={[t(TRANSLATION.SHARED.TABLE.records), t(TRANSLATION.SHARED.TABLE.of)]}
        onShowPaginationSizeChange={handlePaginationSizeChange}
        showPaginationSizeChanger
        paginationPageSizeTextTranslated={t("resultsPerPage")}
        isLoadingExport={isLoadingExport}
        onExport={handleExportData}
        exportLabel={t("export")}
        emptyMessage={t(TRANSLATION.SHARED.TABLE.noDataToDisplay)}
        scroll={{ x: "max-content" }}
      />
    );
  }, [
    currentPage,
    softwareDataPaginated,
    loader.loading,
    skeletonColumns,
    columns,
    softwareData.length,
    handleChangePage,
    paginationSize,
    paginationPageSizeOptions,
    t,
    handlePaginationSizeChange,
    isLoadingExport,
    handleExportData
  ]);
  return (
    <div>
      <div className="d-flex mb-3 mt-3">
        <span className="software-title">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.SOFTWARE.softwareVersions)}</span>
      </div>
      {grid}
    </div>
  );
});
