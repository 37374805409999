import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { store } from "../../../../store/StoreMobx";
import { store as storeByRoles } from "../../../../../administration/store/store";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import { User, UserStatusForm } from "../../../../interfaces/users/user";
import { DateHelper } from "../../../../helpers/shared/DateHelper";
import { QueryRoles, Roles, RolesByUserList } from "../../../../interfaces/auth/roleAndPermission/role";
import { TRANSLATION } from "../../../../utils/const/translation";
import { Controller, Form, useForm } from "react-hook-form";
import { TNSCard, Variants, TNSButton, TNSSelect, TNSInput, TNSModal, TNSSteps } from "@tns/ui-components";
import { ValidatorHelper } from "../../../../helpers/shared/ValidatorHelper";
import { MapperHelper } from "../../../../helpers/shared/MapperHelper";
import { useAsyncCall } from "../../../../../../hooks/useAsyncCallShared";
import { RoleHelper } from "../../../../helpers/role/RoleHelper";
import "./UserAdministration.scss";
import { Checkbox } from "antd";
import withAuthorization from "../../../../../../HOC/withAuthorization";
import { Features } from "../../../../../../layouts/sidebars/sidebardata/SidebarData";
import _ from "lodash";

const UserAdministrarionInternal: React.FC = (): JSX.Element | null => {
  const { user, companyProfile } = store;
  const { userRoles, deviceProfile } = storeByRoles;
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("user") ?? "{}");
  const [textSteps, setTextSteps] = useState<string>(t(TRANSLATION.SIDEBAR.ADMINISTRATION.next));
  const [currentSteps, setCurrentSteps] = useState<number>(1);
  const [currentFormSteps, setCurrentFormSteps] = useState<number>(1);
  const [roleModal, setRoleModal] = useState<boolean>(false);
  const [roleSelected, setRoleSelected] = useState<string>();
  const [camProfiles, setCamProfiles] = useState<string[]>([]);
  const [roleType, setRoletype] = useState<string>("superuser");
  const [selectedStatus, setSelectedStatus] = useState<UserStatusForm>(user.userSelected?.enabled ? UserStatusForm.Enabled : UserStatusForm.Disabled);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const role = MapperHelper.mapRolestoRolesByUserList(store.auth.userInfo?.role as Roles);

  const username = useMemo(() => {
    return decodeURIComponent(params.username ?? "");
  }, [params]);

  const handleItemSelect = useCallback(
    async (item: string) => {
      if (selectedStatus !== item || !item) {
        setSelectedStatus(item as UserStatusForm);
      }
    },
    [selectedStatus]
  );

  const allowedRoles = useMemo(() => {
    const userRole = userInfo.role ? userInfo.role[0].toUpperCase() + userInfo.role.slice(1).toLowerCase() : "";
    const roles = RoleHelper.getAllowedRoles(userRole);
    return userRole !== "Superuser" ? userRoles.roleNames : roles;
  }, [userRoles.roleNames]);

  const translatedRole = (role: string | undefined): RolesByUserList | undefined => {
    switch (role) {
      case t(TRANSLATION.SHARED.DATATABLE.SuperUser):
        return RolesByUserList.SuperUser;
      case t(TRANSLATION.SHARED.DATATABLE.CustomerAccountManager):
        return RolesByUserList.CAM;
      case t(TRANSLATION.SHARED.DATATABLE.Basic):
        return RolesByUserList.Basic;
      case t(TRANSLATION.SHARED.DATATABLE.Admin):
      case "":
      case null:
        return RolesByUserList.Admin;
      default:
        return role as RolesByUserList;
    }
  };

  const initialValues: User = useMemo(() => {
    return {
      email: username !== "new-user" ? user.userSelected?.email ?? "" : "",
      firstName: username !== "new-user" ? user.userSelected?.firstName ?? "" : "",
      lastName: username !== "new-user" ? user.userSelected?.lastName ?? "" : "",
      role: username !== "new-user" ? translatedRole(user.userSelected?.role) || RolesByUserList.Admin : RolesByUserList.Admin,
      timeZone: username !== "new-user" ? user.userSelected?.timeZone ?? DateHelper.getTimeZonesUTC()[0] : DateHelper.getTimeZonesUTC()[0],
      enabled: username !== "new-user" ? user.userSelected?.enabled : false,
      companyProfile: username !== "new-user" ? user.userSelected?.companyProfile : "",
      entityProfile: username !== "new-user" ? user.userSelected?.entityProfile : "",
      camProfile: username !== "new-user" ? user.userSelected?.camProfile : "",
      credentialsExpired: username !== "new-user" ? user.userSelected?.credentialsExpired : false
    };
  }, [username]);

  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid }
  } = useForm<User>({
    defaultValues: initialValues,
    mode: "all"
  });

  const userToOnBoard = useMemo(() => {
    return user.userToOnBoard;
  }, [user]);

  const handleRoleModal = useCallback(
    (event: string) => {
      const roleSelected = event;
      if (roleSelected === RolesByUserList.SuperUser && username !== "new-user") {
        setRoleModal(true);
      } else {
        setValue("role", roleSelected);
        setRoleSelected(roleSelected);
      }
    },
    [setRoleModal, setValue, username]
  );

  const handleRoleSteps = useCallback(() => {
    setValue("role", RolesByUserList.SuperUser);
    setRoleSelected(RolesByUserList.SuperUser);
    setRoleModal(false);
  }, [setRoleModal, setValue, setRoleSelected]);

  const handleRollbackRole = useCallback(() => {
    setRoleModal(false);
  }, [setRoleModal]);

  const goBack = useCallback(() => {
    user.setUserToOnBoard(false);
    localStorage.removeItem("initialFormValues");
    navigate(-1);
  }, [navigate]);

  const handleSteps = useCallback(async () => {
    setIsLoading(true);
    if (currentSteps === 1) {
      setCurrentSteps(currentSteps + 1);
      if (username && username === "new-user") {
        user.userSelected = getValues();
        if (user.userToOnBoard && user.userSelected.role && user.userSelected.email) {
          const data = getValues("role") === RolesByUserList.Admin && userInfo.role === Roles.Admin ? { ...getValues(), companyProfile: userInfo.companyProfile } : getValues();
          if (getValues("role") === RolesByUserList.Admin) {
            delete data.camProfile;
          }
          await user.onBoarded(user.userSelected.role, user.userSelected.email, data);
          navigate("/administration/user");
          return;
        }
        if (user.userSelected.role && !userToOnBoard) {
          if (user.userSelected.role === RolesByUserList.Admin) {
            delete user.userSelected.entityProfile;
            delete user.userSelected.camProfile;
            if (userInfo.role === Roles.Admin) {
              user.userSelected.companyProfile = userInfo.companyProfile;
            }
          } else {
            delete user.userSelected.companyProfile;
          }
          await user.createByRole(user.userSelected.role, user.userSelected);
        }
      } else if (username && username !== "new-user") {
        const userForm = getValues();
        if (user.userToOnBoard && userForm.role) {
          const data = getValues("role") === RolesByUserList.Admin && userInfo.role === Roles.Admin ? { ...getValues(), companyProfile: userInfo.companyProfile } : getValues();
          await user.onBoarded(userForm.role, username, data);
          navigate("/administration/user");
          return;
        }
        if (userForm.email) {
          await handleSubmit(userForm);
        }
      }
      setIsLoading(false);
      navigate("/administration/user");
    }
  }, [currentSteps, navigate, username, user]);

  const handleSubmit = useCallback(
    async (data: User) => {
      if (!user.userToOnBoard) {
        setIsLoading(true);
        if (data.role === QueryRoles.SuperUser) {
          await user.createByRole(RolesByUserList.SuperUser, data);
        } else if (data.role === QueryRoles.Admin) {
          await user.createByRole(RolesByUserList.Admin, data);
        } else if (data.role && username) {
          await user.updateByRole(data.role, data, username);
        }
        setIsLoading(false);
      } else {
        await handleSteps();
      }
      navigate("/administration/user");
    },
    [user, username]
  );

  useEffect(() => {
    if (username !== "new-user" || (userInfo.role === RolesByUserList.Admin && getValues("role") === RolesByUserList.Admin)) {
      setTextSteps(t(TRANSLATION.SIDEBAR.ADMINISTRATION.COMPANY.save));
    } else {
      setTextSteps(t(TRANSLATION.SHARED.BUTTON.next));
    }
  }, [username, user, t, TRANSLATION, getValues, userInfo]);

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  useAsyncCall(async () => {
    if (userInfo.role === Roles.SuperUser || user.userSelected?.role === RolesByUserList.SuperUser) {
      await companyProfile.getCompanyNames();
      const camProfiles = await user.getAllCamUsersName();
      setCamProfiles(camProfiles);
    } else {
      await userRoles.getRoleNames();
    }
  }, [userRoles]);

  const status = useMemo(() => {
    return Object.values(UserStatusForm).map((status: UserStatusForm) => {
      return {
        value: status,
        label: t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER[status as unknown as keyof typeof TRANSLATION.SIDEBAR.ADMINISTRATION.USER])
      };
    });
  }, []);

  const formRender = useMemo(() => {
    return (
      <Form control={control}>
        <Row>
          <Col md={1} className="d-flex justify-content-end pl-1">
            <label htmlFor="email">*{t(TRANSLATION.SHARED.emailUser)}:</label>
          </Col>
          <Col md={5} className="align-items-start p-0">
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                minLength: 5,
                maxLength: 100,
                pattern: /^[A-Z0-9._%+-]+@\w+\.[A-Z]{2,}(?:\.[A-Z]{2,})?$/i,
                validate: async (value) => value && (await ValidatorHelper.validateEmailToOnBoard(value, user.userSelected?.email ?? ""))
              }}
              render={({ field }): JSX.Element => <TNSInput {...field} disabled={!_.isEqual(username, "new-user")} readOnly={currentFormSteps !== 1} />}
            />
          </Col>
        </Row>
        <Row className="form-row">
          <Col>
            {errors.email?.type === "required" && <small className="text-danger">{t(TRANSLATION.LOGIN.emailRequired)}</small>}
            {errors.email?.type === "minLength" && <small className="text-danger">{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.emailMinAdministration)}</small>}
            {errors.email?.type === "maxLength" && <small className="text-danger">{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.emailMaxAdministration)}</small>}
            {errors.email?.type === "pattern" && <small className="text-danger">{t(TRANSLATION.LOGIN.userEmailMustBeValid)}</small>}
            {errors.email?.type === "validate" && <small className="text-danger">{t(TRANSLATION.SIDEBAR.ADMINISTRATION.COMPANY.emailAlreadyInUse)}</small>}
            {userToOnBoard ? <small className="text-danger">{t(TRANSLATION.SIDEBAR.ADMINISTRATION.COMPANY.usernameAlreadyIsUseButNotOnboarded)}</small> : null}
          </Col>
        </Row>
        {!userToOnBoard && (
          <>
            <Row className="align-items-start mt-3">
              <Col md={1} className="d-flex justify-content-end ml-1">
                <label htmlFor="firstName">*{t(TRANSLATION.LOGIN.firstName)}:</label>
              </Col>
              <Col md={5} className="align-items-start p-0 ">
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: userToOnBoard ? false : true, maxLength: 60, minLength: 2 }}
                  render={({ field }): JSX.Element => <TNSInput {...field} readOnly={currentFormSteps !== 1} />}
                />
              </Col>
              <Row className="form-row">
                <Col>
                  {errors.firstName?.type === "required" && <small className="text-danger">{t(TRANSLATION.LOGIN.firstNameRequired)}</small>}
                  {errors.firstName?.type === "minLength" && <small className="text-danger">{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.firstNameMinAdministration)}</small>}
                  {errors.firstName?.type === "maxLength" && <small className="text-danger">{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.firstNameMaxAdministration)}</small>}
                </Col>
              </Row>
            </Row>
            <Row className="align-items-start mt-3">
              <Col md={1} className=" d-flex justify-content-end pl-1">
                <label htmlFor="lastName">*{t(TRANSLATION.LOGIN.lastName)}:</label>
              </Col>
              <Col md={5} className="align-items-start p-0">
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: userToOnBoard ? false : true, maxLength: 60, minLength: 2 }}
                  render={({ field }): JSX.Element => <TNSInput {...field} readOnly={currentFormSteps !== 1} />}
                />
              </Col>
              <Row className="form-row">
                <Col>
                  {errors.lastName?.type === "required" && <small className="text-danger">{t(TRANSLATION.LOGIN.lastNameRequired)}</small>}
                  {errors.lastName?.type === "minLength" && <small className="text-danger">{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.lastNameMinAdministration)}</small>}
                  {errors.lastName?.type === "maxLength" && <small className="text-danger">{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.lastNameMaxAdministration)}</small>}
                </Col>
              </Row>
            </Row>
            <Row className="align-items-start mt-3">
              <Col md={1} className=" d-flex justify-content-end pl-1">
                <label htmlFor="timeZone">*{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.timeZone)}:</label>
              </Col>
              <Col md={5} className="align-items-start p-0">
                <Controller
                  name="timeZone"
                  control={control}
                  render={({ field }): JSX.Element => (
                    <TNSSelect
                      {...field}
                      options={DateHelper.getTimeZonesUTC().map((timeZone) => ({ label: timeZone, value: timeZone }))}
                      className="w-100"
                      disabled={currentFormSteps !== 1}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="align-items-start mt-3">
              <Col md={1} className="d-flex justify-content-end pl-1">
                <label htmlFor="enabled">*{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.Enabled)}:</label>
              </Col>
              <Col md={5} className="align-items-start p-0">
                <Controller
                  name="enabled"
                  control={control}
                  render={({ field }): JSX.Element => (
                    <TNSSelect {...field} options={status} value={selectedStatus} onSelect={handleItemSelect} className="w-100" disabled={currentFormSteps !== 1} />
                  )}
                />
              </Col>
            </Row>
            {user.userSelected?.userAccountStatus !== "Expired" && username !== "new-user" && !user.userSelected?.credentialsExpired && (
              <Row className="align-items-end">
                <Col md={4} className="mb-3 d-flex">
                  <Controller
                    name="credentialsExpired"
                    control={control}
                    // eslint-disable-next-line
                    render={({ field }) => <Checkbox {...field} checked={field.value} style={{ marginLeft: "0.5rem", marginRight: "0.5rem", marginBottom: "0.5rem" }} />}
                  />
                  <label htmlFor="credentialsExpired">{t(TRANSLATION.SHARED.USERPROFILE.userMustChangePassword)}</label>
                </Col>
              </Row>
            )}
          </>
        )}
      </Form>
    );
  }, [
    allowedRoles,
    control,
    errors.email?.type,
    errors.firstName?.type,
    errors.lastName?.type,
    errors.role?.type,
    errors.username?.type,
    getValues,
    handleRoleModal,
    isValid,
    roleSelected,
    t,
    TRANSLATION,
    user.userSelected?.email,
    user.userSelected?.role,
    selectedStatus,
    userToOnBoard
  ]);

  const formRoleRender = useMemo(() => {
    return (
      <Form control={control}>
        <Row className="align-items-center">
          <Col md={1} className=" d-flex justify-content-end pl-1">
            <label htmlFor="role">*{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.roles)}:</label>
          </Col>
          <Col md={4} className="align-items-start p-0">
            <Controller
              name="role"
              control={control}
              rules={{ required: true }}
              render={({ field }): JSX.Element => (
                <TNSSelect
                  {...field}
                  onChange={(event): void => handleRoleModal(event)}
                  options={allowedRoles?.map((role) => ({ label: t(role), value: role }))}
                  className="w-100"
                  disabled={currentFormSteps !== 1}
                />
              )}
            />
          </Col>
          <Row className="form-row">
            <Col>{errors.role && <small className="text-danger">{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.roleRequired)}</small>}</Col>
          </Row>
        </Row>
      </Form>
    );
  }, [allowedRoles, control, errors.role, handleRoleModal, t, TRANSLATION]);

  const formCompanyRender = useMemo(() => {
    return (
      <Form control={control}>
        <Row className="align-items-center">
          <Col md={1} className=" d-flex justify-content-end pl-2">
            <label htmlFor="companyProfiles">*{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.companyProfileAdministration)}:</label>
          </Col>
          <Col md={4} className="align-items-start p-0">
            <Controller
              name="companyProfile"
              control={control}
              disabled={currentFormSteps < 2 && username === "new-user"}
              rules={{ required: true }}
              render={({ field }): JSX.Element => (
                <TNSSelect {...field} options={companyProfile.companyProfileNames?.map((names: string) => ({ label: names, value: names }))} className="w-100" />
              )}
            />
          </Col>
          <Row className="form-row">
            <Col>{errors.role && <small className="text-danger">{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.roleRequired)}</small>}</Col>
          </Row>
        </Row>
      </Form>
    );
  }, [allowedRoles, control, errors.role, handleRoleModal, t, TRANSLATION, currentFormSteps, setCurrentFormSteps, companyProfile, companyProfile.companyProfileNames]);

  const formEntityRender = useMemo(() => {
    return (
      <Form control={control}>
        <Row className="align-items-center ">
          <Col md={2} className=" d-flex justify-content-end pl-2">
            <label htmlFor="entityProfiles">*{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.entityProfiles)}:</label>
          </Col>
          <Col md={5} className="align-items-start p-0">
            <Controller
              name="entityProfile"
              control={control}
              disabled={currentFormSteps < 2 && username === "new-user"}
              rules={{ required: true }}
              render={({ field }): JSX.Element => (
                <TNSSelect {...field} options={deviceProfile.entityProfileNames?.map((name: string) => ({ label: name, value: name }))} className="w-100" />
              )}
            />
          </Col>
          <Row className="form-row">
            <Col>{errors.role && <small className="text-danger">{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.roleRequired)}</small>}</Col>
          </Row>
        </Row>
      </Form>
    );
  }, [allowedRoles, control, errors.role, handleRoleModal, t, TRANSLATION, currentFormSteps, setCurrentFormSteps]);

  const formCAMRender = useMemo(() => {
    return (
      <Form control={control}>
        <Row className="align-items-center ">
          <Col md={2} className=" d-flex justify-content-end pl-2">
            <label htmlFor="CAM">*{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.selectCAM)}:</label>
          </Col>
          <Col md={5} className="align-items-start p-0">
            <Controller
              name="camProfile"
              control={control}
              disabled={currentFormSteps < 2 && username === "new-user"}
              rules={{ required: true }}
              render={({ field }): JSX.Element => (
                <TNSSelect
                  {...field}
                  options={camProfiles.map((name: string) => ({ label: name, value: name }))}
                  className="w-100"
                  emptyMessage={t(TRANSLATION.SIDEBAR.ADMINISTRATION.CAM.thereAreNoCamProfilesCreatedYet)}
                />
              )}
            />
          </Col>
          <Row className="form-row">
            <Col>{errors.role && <small className="text-danger">{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.roleRequired)}</small>}</Col>
          </Row>
        </Row>
      </Form>
    );
  }, [allowedRoles, control, errors.role, handleRoleModal, t, TRANSLATION, currentFormSteps, setCurrentFormSteps, camProfiles]);

  const renderForm = useMemo(
    () => (
      <div style={{ width: "75%", marginLeft: "3rem", marginBottom: "3rem" }}>
        {getValues("role") === RolesByUserList.Admin && userInfo.role === Roles.SuperUser ? (
          <TNSCard>{formCompanyRender}</TNSCard>
        ) : getValues("role") === RolesByUserList.CAM && userInfo.role === Roles.SuperUser ? (
          <TNSCard>{formCAMRender}</TNSCard>
        ) : (
          getValues("role") !== RolesByUserList.Admin && userInfo.role === Roles.Admin && <TNSCard>{formEntityRender}</TNSCard>
        )}
      </div>
    ),
    [formCompanyRender, formEntityRender, roleType, userInfo, getValues, formCAMRender]
  );

  const handleButton = async (): Promise<void> => {
    const dataToSendCreate = MapperHelper.mapUserByRole(getValues());
    if (getValues("role") === RolesByUserList.SuperUser) {
      const newValues = { ...dataToSendCreate.user, role: dataToSendCreate.role };
      await handleSubmit(newValues);
    } else if (getValues("role") === RolesByUserList.Admin && userInfo.role === Roles.SuperUser) {
      await companyProfile.getCompanyNames();
    } else if (getValues("role") === RolesByUserList.CAM && userInfo.role === Roles.SuperUser) {
      const cams = await user.getAllCamUsersName();
      setCamProfiles(cams);
    } else if (getValues("role") === RolesByUserList.Admin && userInfo.role === Roles.Admin) {
      const newValues = { ...dataToSendCreate.user, role: dataToSendCreate.role, companyProfile: userInfo.companyProfile };
      await handleSubmit(newValues);
    } else {
      await deviceProfile.getEntityNames();
    }
    setCurrentFormSteps(2);
    setRoletype(dataToSendCreate.role);
  };

  const titleModal = useMemo(() => {
    return (
      <>
        <div className="d-flex gap-2">
          <p className="text-center">{t(TRANSLATION.MODAL.ALERT.switchingUserRole)}</p>
        </div>
        <div>{t(TRANSLATION.MODAL.ALERT.switching)}</div>
      </>
    );
  }, []);

  return (
    <>
      <Row className="mb-4">
        {(username !== "new-user" ||
          (currentFormSteps === 2 && getValues("role") !== RolesByUserList.SuperUser) ||
          (currentFormSteps === 2 && getValues("role") === RolesByUserList.SuperUser && userInfo.role === Roles.SuperUser)) && (
          <Col className="d-flex justify-content-end gap-3">
            <TNSButton buttonVariant={Variants.Secondary} onClick={goBack}>
              {t(TRANSLATION.SHARED.BUTTON.cancel)}
            </TNSButton>
            <TNSButton buttonVariant={Variants.Primary} onClick={handleSteps} loading={isLoading} disabled={!isValid || role === RolesByUserList.CAM}>
              {t(TRANSLATION.SHARED.BUTTON.save)}
            </TNSButton>
          </Col>
        )}
      </Row>
      {username === "new-user" ? (
        <TNSCard>
          <div style={{ marginBottom: "10rem" }}>
            <TNSSteps
              direction="vertical"
              current={currentFormSteps}
              progressDot
              items={[
                {
                  title: <span>{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.createUserProfile)}</span>,
                  description: (
                    <div style={{ width: "75%", margin: "auto" }}>
                      <TNSCard>{formRender}</TNSCard>
                    </div>
                  )
                },
                {
                  title: <span>{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.roles)}</span>,
                  description: (
                    <>
                      <div style={{ width: "75%", margin: "auto" }}>
                        <TNSCard>{formRoleRender}</TNSCard>
                      </div>
                      <div className="d-flex justify-content-end" style={{ width: "95%" }}>
                        <TNSButton buttonVariant={Variants.Primary} onClick={handleButton} disabled={!isValid || currentFormSteps !== 1}>
                          {textSteps}
                        </TNSButton>
                      </div>
                    </>
                  )
                }
              ]}
            />
            {currentFormSteps === 2 && getValues("role") !== Roles.SuperUser && (
              <TNSSteps
                direction="vertical"
                current={currentFormSteps}
                progressDot
                items={[
                  {
                    title:
                      getValues("role") === RolesByUserList.Admin && userInfo.role !== Roles.Admin ? (
                        <span>{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.associateCompany)}</span>
                      ) : getValues("role") === RolesByUserList.Basic ? (
                        <span>{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.entityProfiles)}</span>
                      ) : getValues("role") === RolesByUserList.CAM ? (
                        <span>{t(TRANSLATION.SIDEBAR.ADMINISTRATION.USER.customerAccountManagerCAM)}</span>
                      ) : null,
                    disabled: currentFormSteps < 2,
                    description:
                      getValues("role") === RolesByUserList.Admin && userInfo.role !== Roles.Admin ? (
                        <div style={{ width: "75%", margin: "auto" }}>
                          <TNSCard>{formCompanyRender}</TNSCard>
                        </div>
                      ) : getValues("role") === RolesByUserList.CAM && userInfo.role === Roles.SuperUser ? (
                        <div>
                          <TNSCard>{formCAMRender}</TNSCard>
                        </div>
                      ) : (
                        <div style={{ width: "75%", margin: "auto" }}>
                          {getValues("role") !== RolesByUserList.Admin && userInfo.role === Roles.Admin && <TNSCard>{formEntityRender}</TNSCard>}
                        </div>
                      )
                  }
                ]}
              />
            )}
          </div>
        </TNSCard>
      ) : (
        <TNSCard>
          <div style={{ marginBottom: "20rem" }}>
            <div style={{ width: "75%", marginLeft: "3rem", marginBottom: "3rem" }}>
              <TNSCard>{formRender}</TNSCard>
            </div>
            <div style={{ width: "75%", marginLeft: "3rem", marginBottom: "3rem" }}>
              <TNSCard>{formRoleRender}</TNSCard>
            </div>
            {renderForm}
          </div>
        </TNSCard>
      )}
      <TNSModal
        closable={false}
        open={roleModal}
        handleCancel={handleRollbackRole}
        title={titleModal}
        textCancelButton={t(TRANSLATION.SHARED.BUTTON.cancel)}
        textOkButton={t(TRANSLATION.SHARED.BUTTON.accept)}
        handleAccept={handleRoleSteps}
        buttonsPosition="center"
      />
    </>
  );
};

// to get allowed roles for routes, use the route name from Router.tsx
const UserAdministrarion = withAuthorization(observer(UserAdministrarionInternal), Features.UserAdministration);

export default UserAdministrarion;
