import React, { useMemo } from "react";
import { store } from "../../../../store/StoreMobx";
import { observer } from "mobx-react";
import { useAsyncCall } from "../../../../../../hooks/useAsyncCall";
import { useTranslation } from "react-i18next";
import { TNSSelect } from "@tns/ui-components";

export const MemberSelected: React.FC<{ selectedItem: string; onSelect: (item: string) => void }> = observer(({ onSelect }) => {
  const { acna } = store;
  const userInfo = JSON.parse(localStorage.getItem("user") ?? "{}");
  const { t } = useTranslation();

  if (userInfo.role === "SUPERUSER") {
    useAsyncCall(acna.loadData, []);
  }

  const handleItemSelect = (item: string): void => {
    onSelect(item);
  };

  const renderItems = useMemo(() => {
    if (store.member.hasMemberConnectivity?.connectedAcnas) {
      return store.member.hasMemberConnectivity?.connectedAcnas.map((member) => ({
        label: `${member.acna} - ${member.knownAs}`,
        value: member.acna
      }));
    }
  }, []);

  return (
    <div style={{ width: "900" }}>
      <TNSSelect options={renderItems} placeholder={`${t("select")} ${t("a")} ${t("member")}`} testId="member-selected-dropdown" onSelect={handleItemSelect} className="w-100" />
    </div>
  );
});
