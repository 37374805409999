import React, { ReactNode } from "react";
import { TNSCard, TNSDivider } from "@tns/ui-components";

export interface MetricCardProps {
  header: ReactNode;
  data: ReactNode;
  width?: string;
}
export const MetricCard: React.FC<MetricCardProps> = ({ header, data, width }) => {
  return (
    <TNSCard className={`d-flex flex-column ${width ?? "w-100"}`} style={{ height: "215px" }}>
      <div className="d-flex flex-column">
        <span className="d-flex align-items-center justify-content-start">{header}</span>
        <TNSDivider type="horizontal" />
      </div>
      <div className="w-100 d-flex align-items-center justify-content-center text-center h-100">{data}</div>
    </TNSCard>
  );
};
