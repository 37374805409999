import { makeAutoObservable } from "mobx";
import {
  AvailableDevicesResponseByFilter,
  DeviceProfileInterface,
  DeviceProfileParams,
  FilterType,
  ProfileName,
  ProfileNameAvailable,
  DeviceProfileParamsToUpdate
} from "../../models/device-profile.model";
import { MessageHelper } from "../../helpers/shared/meesage-helper";
import { DeviceProfileService } from "../../services/device-profile-service";
import { TRANSLATION } from "../../utils/translation-keys";
import { StatusCode } from "../../helpers/api/RequestHelper";
import i18n from "../../../../i18n";

export interface IDeviceProfile {
  data: DeviceProfileInterface[];
  profileNameAvailable: boolean;
  entityProfileNames: string[];
  deviceProfile?: DeviceProfileInterface;
  availableDevices?: AvailableDevicesResponseByFilter;
  associatedDevices?: AvailableDevicesResponseByFilter;
  nonBoardedDevices?: AvailableDevicesResponseByFilter;
  activeFilters?: DeviceProfileParams;
  currentPage: number;

  createProfile: (profileName: ProfileName) => Promise<void>;
  loadData: () => Promise<void>;
  getEntityNames: () => Promise<string[] | undefined>;
  loadDataByProfileName: (profileName: string, params?: DeviceProfileParams) => Promise<void>;
  checkAvailableName: (profileName: string) => Promise<ProfileNameAvailable | undefined>;
  loadAvailableDevices: (profileName: string, filter: FilterType, params?: DeviceProfileParams) => Promise<void>;
  updateDeviceProfile: (oldName: string, profile: ProfileName, onboarded?: boolean, devices?: DeviceProfileParamsToUpdate) => Promise<void>;
  setCurrentPage: (page: number) => void;
  setDevices: (devices: string[]) => void;
  deleteProfile: (profileName: string) => Promise<void>;
  cleanStoreData: () => void;
}

export class DeviceProfile implements IDeviceProfile {
  data: DeviceProfileInterface[] = [];
  entityProfileNames: string[] = [];
  currentPage = 1;
  profileNameAvailable = true;
  deviceProfile?: DeviceProfileInterface = undefined;
  availableDevices?: AvailableDevicesResponseByFilter = undefined;
  associatedDevices?: AvailableDevicesResponseByFilter = undefined;
  nonBoardedDevices?: AvailableDevicesResponseByFilter = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setDevices = (devices: string[]): void => {
    if (this.associatedDevices) {
      this.associatedDevices.devices = devices;
    }
  };

  setCurrentPage = (page: number): void => {
    this.currentPage = page;
  };

  loadData = async (): Promise<void> => {
    try {
      const response = await DeviceProfileService.getAll();
      if (response?.data) {
        this.data = response.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  loadDataByProfileName = async (profileName: string, params?: DeviceProfileParams): Promise<void> => {
    try {
      if (profileName !== "") {
        this.deviceProfile = this.data.find((profile) => profile.name === profileName);
        const response = await DeviceProfileService.getDevicesByProfileName(profileName, params);
        if (response?.data) {
          if (params?.onboarded === false) {
            this.nonBoardedDevices = response.data;
          } else {
            this.associatedDevices = response.data;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  checkAvailableName = async (profileName: string): Promise<ProfileNameAvailable | undefined> => {
    try {
      if (profileName !== "") {
        const response = await DeviceProfileService.isAvailable(profileName);
        if (response?.data) {
          this.profileNameAvailable = response.data.available ? true : false;
          return response.data;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  getEntityNames = async (): Promise<string[] | undefined> => {
    try {
      const response = await DeviceProfileService.getEntityNames();
      if (response?.data) {
        this.entityProfileNames = response.data;
        return response.data;
      }
    } catch (e) {
      MessageHelper.errorMessage(i18n.t("coouldNotConnect"));
    }
  };

  loadAvailableDevices = async (profileName: string, filter: FilterType, params?: DeviceProfileParams): Promise<void> => {
    try {
      if (profileName !== "") {
        if (filter === FilterType.AllDevices) {
          const response = await DeviceProfileService.getAvailableDevices(profileName, params);
          if (response?.data) {
            this.availableDevices = response?.data;
          }
        } else {
          const response = await DeviceProfileService.getAvailableDevicesByFilter(profileName, filter, params);
          if (response?.data) {
            this.availableDevices = response.data;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  updateDeviceProfile = async (oldName: string, profile: ProfileName, onboarded?: boolean, devices?: DeviceProfileParamsToUpdate): Promise<void> => {
    try {
      if (devices) {
        await DeviceProfileService.editDeviceProfile(oldName, profile, devices);
        if (onboarded === false && this.nonBoardedDevices) {
          this.nonBoardedDevices.devices = [];
        } else if (onboarded && this.associatedDevices) {
          this.associatedDevices.devices = [];
        }
      } else {
        await DeviceProfileService.editDeviceProfile(oldName, profile);
      }
      MessageHelper.successMessage(i18n.t(TRANSLATION.SUCCESS.profileUpdateSuccess));
    } catch (e) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.ERROR.profileUpdateError));
    }
  };

  createProfile = async (profileName: ProfileName): Promise<void> => {
    try {
      const res = await DeviceProfileService.createProfile(profileName);
      if (res?.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18n.t(TRANSLATION.SUCCESS.profileCreateSuccess));
        if (profileName.name !== "") {
          await this.loadAvailableDevices(profileName.name, FilterType.AllDevices, { recordsPerPage: 10 });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  deleteProfile = async (profileName: string): Promise<void> => {
    try {
      const data = await DeviceProfileService.deleteProfile(profileName);
      if (data && data.status === 204) {
        this.data = data.data;
        MessageHelper.successMessage(i18n.t(TRANSLATION.SUCCESS.profileDeleteSuccess));
      }
    } catch (e) {
      console.log(e);
    }
  };

  cleanStoreData = (): void => {
    this.data = [];
    this.deviceProfile = undefined;
    this.availableDevices = undefined;
    this.associatedDevices = undefined;
    this.currentPage = 1;
  };
}

const deviceProfile = new DeviceProfile();

export default deviceProfile;
