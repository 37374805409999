import { makeAutoObservable } from "mobx";
import { Note as NoteModel } from "../../../models/devices/note/note";
import { NoteService } from "../../../service/device/dhi/note-service";
import { TRANSLATION } from "../../../utils/const/translation";
import { i18nInstance as i18n } from "../../../../../i18n";
import { ContactInterface } from "../../../models/devices/note/contact";
import { ContactInfoService } from "../../../service/device/dhi/contact-info-service";
import { StatusCode } from "@tnso/api-core";
import { MessageHelper } from "../../../../../shared/helpers/MessageHelper";

export interface INote {
  data?: NoteModel;
  deviceName?: string;
  dataContact?: ContactInterface;
  tnsDeviceName?: string;
  isContactData?: boolean;

  loadData: (tnsDeviceName?: string) => Promise<void>;
  update: (note: NoteModel, tnsDeviceName?: string) => Promise<void>;
  loadDataContact: (tnsDeviceName?: string) => Promise<void>;
  updateContact: (contact: ContactInterface, tnsDeviceName?: string) => Promise<void>;
  setIsContactData: (isContactData: boolean) => void;
  clearContactData: () => void;
}

class Note implements INote {
  data?: NoteModel = undefined;
  deviceName?: string | undefined;
  dataContact?: ContactInterface;
  isContactData = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadData = async (tnsDeviceName?: string): Promise<void> => {
    try {
      if (tnsDeviceName && tnsDeviceName !== this.deviceName) {
        this.deviceName = tnsDeviceName;
        const response = await NoteService.getAll(tnsDeviceName);
        if (response?.status === StatusCode.OK && response?.data) {
          this.data = response.data.note === null ? { note: "" } : response.data;
        }
      }
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.ALERT.errorLoading));
    }
  };

  update = async (note: NoteModel, tnsDeviceName?: string): Promise<void> => {
    try {
      if (tnsDeviceName) {
        const response = await NoteService.update(note, tnsDeviceName);
        if (response?.status === StatusCode.NO_CONTENT) {
          MessageHelper.successMessage(i18n.t(TRANSLATION.MODAL.ALERT.successUpdate));
          const response = await NoteService.getAll(tnsDeviceName);
          if (response?.status === StatusCode.OK && response?.data) {
            this.data = response.data;
            this.deviceName = tnsDeviceName;
          }
        }
      }
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.ALERT.errorCreating));
    }
  };

  loadDataContact = async (tnsDeviceName?: string): Promise<void> => {
    try {
      if (tnsDeviceName) {
        const response = await ContactInfoService.getAll(tnsDeviceName);
        if (response?.status === StatusCode.OK && response?.data) {
          this.dataContact = response.data;
        }
      }
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.ALERT.errorLoading));
    }
  };

  updateContact = async (contact: ContactInterface, tnsDeviceName?: string): Promise<void> => {
    try {
      if (tnsDeviceName) {
        const response = await ContactInfoService.update(contact, tnsDeviceName);
        if (response?.status === StatusCode.NO_CONTENT) {
          MessageHelper.successMessage(i18n.t(TRANSLATION.MODAL.ALERT.contactUpdatedSuccess));
          await this.loadDataContact(tnsDeviceName);
        }
      }
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.ALERT.errorCreating));
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setIsContactData = (isContactData: boolean) => {
    this.isContactData = isContactData;
  };

  clearContactData = (): void => {
    this.dataContact = undefined;
    this.isContactData = false;
  };
}

const note = new Note();

export default note;
