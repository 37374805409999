import { makeAutoObservable } from "mobx";
import { IUserAssociateByRoleResponse, IUserRole, IUserRoleAvailableResponse, IUserRoleParams, IUserRoleUpdate } from "../../models/user-role.model";
import { UserRoleService } from "../../services/user-role-service";
import { TRANSLATION } from "../../translations/translation";
import { StatusCode } from "../../helpers/api/RequestHelper";
import { MessageHelper } from "../../helpers/shared/meesage-helper";
import { i18nInstance } from "../../../../i18n";
import { ErrorHelper } from "../../helpers/shared/error-helper";
import { QueryParams } from "../../models/query-params.model";

export interface IUserRolesStore {
  roles: IUserRole[];
  roleNames?: string[];
  newRoleName?: string;
  usersAssociateByRole: IUserAssociateByRoleResponse;
  userAssociatedFilters?: IUserRoleParams;

  setRoleName(roleName: string): void;
  getRoles(queryParams?: QueryParams): Promise<void>;
  getRoleNames(): Promise<void>;
  getUserAssociateByRole(roleName: string, queryParams?: QueryParams): Promise<void>;
  getIsAvailableRole(roleName: string, oldRoleName?: string): Promise<IUserRoleAvailableResponse | undefined>;
  createRole(userRole: IUserRole): Promise<StatusCode | undefined>;
  deleteRole(roleName: string): Promise<void>;
  editeRole(userRole: IUserRoleUpdate, roleName?: string): Promise<void>;
  setUserAssociatedFilters(filters: IUserRoleParams): void;
}

class UserRoles implements IUserRolesStore {
  roles: IUserRole[] = [];
  newRoleName?: string;
  usersAssociateByRole: IUserAssociateByRoleResponse = {
    totalRecords: 0,
    returenedRecords: 0,
    users: []
  };
  userAssociatedFilters?: IUserRoleParams;
  roleNames?: string[];

  constructor() {
    makeAutoObservable(this);
  }

  setRoleName = (roleName: string): void => {
    this.newRoleName = roleName;
  };

  getRoles = async (queryParams?: QueryParams): Promise<void> => {
    try {
      const response = await UserRoleService.getRoles(queryParams);
      if (response?.data && response.status === StatusCode.OK) {
        this.roles = response.data;
      }
    } catch (error) {
      console.warn(error);
    }
  };

  getRoleNames = async (): Promise<void> => {
    try {
      const response = await UserRoleService.getRoleNames();
      if (response?.data && response.status === StatusCode.OK) {
        this.roleNames = response.data;
      }
    } catch (error) {
      console.warn(error);
    }
  };

  getUserAssociateByRole = async (roleName: string, queryParams?: QueryParams): Promise<void> => {
    try {
      const response = await UserRoleService.getUserAssociateByRole(roleName, queryParams);
      if (response?.data && response.status === StatusCode.OK) {
        this.usersAssociateByRole = response.data;
      }
    } catch (error) {
      console.warn(error);
    }
  };

  getIsAvailableRole = async (roleName: string, oldRoleName?: string): Promise<IUserRoleAvailableResponse | undefined> => {
    try {
      if (oldRoleName && roleName === oldRoleName) {
        return { available: true };
      } else {
        const response = await UserRoleService.getIsAvailableRole(roleName);
        return response?.data;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18nInstance.t(ErrorHelper.errorHTTP.badRequest));
    }
  };

  async createRole(userRole: IUserRole): Promise<StatusCode | undefined> {
    try {
      const response = await UserRoleService.createRole(userRole);
      if (response?.status === StatusCode.OK || response?.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18nInstance.t(TRANSLATION.USER_ROLES.CREATE_ROLE.roleCreatedSuccessfully));
        return StatusCode.OK;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18nInstance.t(ErrorHelper.errorHTTP.badRequest));
    }
  }

  async deleteRole(roleName: string): Promise<void> {
    try {
      const response = await UserRoleService.deleteRole(roleName);
      if (response?.status === StatusCode.OK || response?.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18nInstance.t(TRANSLATION.USER_ROLES.DELETE_ROLE.roleDeletedSuccessfully));
      }
    } catch (error) {
      MessageHelper.errorMessage(i18nInstance.t(ErrorHelper.errorHTTP.badRequest));
    }
  }

  async editeRole(userRole: IUserRoleUpdate, roleName?: string): Promise<void> {
    try {
      if (roleName) {
        const response = await UserRoleService.editRole(userRole, roleName);
        if (response?.status === StatusCode.OK || response?.status === StatusCode.NO_CONTENT) {
          MessageHelper.successMessage(i18nInstance.t(TRANSLATION.USER_ROLES.EDIT_ROLE.roleEditedSuccessfully));
        }
      }
    } catch (error) {
      MessageHelper.errorMessage(i18nInstance.t(ErrorHelper.errorHTTP.badRequest));
    }
  }

  setUserAssociatedFilters = (filters: IUserRoleParams): void => {
    this.userAssociatedFilters = filters;
  };
}

const userRoles = new UserRoles();

export default userRoles;
