import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faEllipsisVertical, faTag } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "antd";
import { IDetail } from "../../store/device/detail/detail";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../utils/const/translation";
import { MenuProps } from "antd/lib";
import { MenuItem } from "./menu-dhi-items";
import { DeviceGroups } from "../../models/devices/devices";
import { TNSDropdown } from "@tns/ui-components";

export interface GroupsInformationMenuProp {
  detail: IDetail;
  paginatedGroups: DeviceGroups[] | undefined;
  setDrawerDeviceGroupsOpen: (open: boolean) => void;
  maxIndexGroups: number;
}

export const GroupsInformationMenu: React.FC<GroupsInformationMenuProp> = ({ detail, paginatedGroups, setDrawerDeviceGroupsOpen, maxIndexGroups }) => {
  const { t } = useTranslation();

  const assignedGroups = useMemo(() => {
    return detail.groups?.filter((group) => group.associated);
  }, [detail.groups]);

  const groupsDropdown: MenuProps["items"] = useMemo(
    () =>
      assignedGroups?.slice(maxIndexGroups).map((item) => ({
        key: item.name,
        label: <MenuItem item={item} tooltipOnHover />
      })),
    [paginatedGroups]
  );

  const items: MenuProps["items"] = useMemo(
    () => [
      ...(JSON.parse(localStorage.getItem("user") ?? "{}").role === "ADMIN"
        ? [
            {
              key: "deviceGroups",
              label: <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.TREEMENU.deviceGroups)}</span>,
              onClick: (): void => {
                setDrawerDeviceGroupsOpen(true);
              }
            }
          ]
        : []),
      {
        key: "myGroups",
        label: <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.TREEMENU.myGroups)}</span>,
        onClick: (): void => {
          window.location.replace("/monitoring/devices/device-grouping/details/myGroups");
        }
      }
    ],
    [t]
  );

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex gap-2 align-items-center me-3">
        {assignedGroups?.map(
          (group, index) =>
            index < maxIndexGroups && (
              <Tooltip placement="topLeft" color="#4B5E7A" title={group.name} key={index}>
                <FontAwesomeIcon icon={faTag} size="xl" color={group?.color} />
              </Tooltip>
            )
        )}
        {assignedGroups && assignedGroups?.length > maxIndexGroups && (
          <>
            <span className="text-nowrap me-2" style={{ fontSize: "12px" }}>
              + {assignedGroups?.length - maxIndexGroups} {t(TRANSLATION.SHARED.DATATABLE.more)}
            </span>
            <TNSDropdown
              trigger={["click"]}
              menu={{ items: groupsDropdown }}
              placement="bottomRight"
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              dropdownRender={(menu) => (
                <div className="dropdown-content" style={{ maxHeight: "400px", overflowY: "scroll" }}>
                  {menu}
                </div>
              )}>
              <FontAwesomeIcon icon={faCaretDown} size="lg" className="cursor-pointer" />
            </TNSDropdown>
          </>
        )}
      </div>
      <TNSDropdown trigger={["click"]} menu={{ items }} placement="bottomRight">
        <span>
          <FontAwesomeIcon icon={faEllipsisVertical} size="xl" className="cursor-pointer" />
        </span>
      </TNSDropdown>
    </div>
  );
};
