import React, { useContext } from "react";
import { ToolsFormSelect } from "./filters/ToolsFormSelect";
import { TroubleshootingContext } from "../../../../../../components/device/troubleshooting/context/troubleshooting-context";
import { TroubleshootingFiltersButtons } from "./TroubleshootingFiltersButtons";
import { IPAddressFormInput } from "./filters/IPAddressFormInput";
import { NetworkFormSelect } from "./filters/NetworkFormSelect";
import { HostNameFormInput } from "./filters/HostNameFormInput";
import { TimeFormSelect } from "./filters/TimeFormSelect";
import { AttemptsFormSelect } from "./filters/AttemptsFormSelect";
import { TNSOAlert } from "@tnso/ui-components";
import { TRANSLATION } from "../../../../../../utils/const/translation";
import { useTranslation } from "react-i18next";
import { TroubleshootingTools } from "./enums/TroubleshootingTools";
import { GapSizes, TNSContainer } from "@tns/ui-components";

export const TroubleshootingFilters: React.FC = () => {
  const { t } = useTranslation();
  const { selectedTool, deviceCompatible, visibleFilters } = useContext(TroubleshootingContext);

  return (
    <div>
      {!deviceCompatible && (
        <TNSOAlert
          className="mb-3"
          type="info"
          message={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.enableFunctionalityInfo)}
          showIcon
          style={{ marginTop: "4px" }}
        />
      )}
      {deviceCompatible && selectedTool === TroubleshootingTools.TCPCaptures && (
        <TNSOAlert
          className="mb-3"
          type="info"
          message={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.tcpNotStored)}
          showIcon
          style={{ marginTop: "4px" }}
        />
      )}
      <TNSContainer gap={GapSizes.xs} className="align-items-end">
        <ToolsFormSelect />
        {visibleFilters.network && <NetworkFormSelect />}
        {visibleFilters.attempts && <AttemptsFormSelect />}
        {visibleFilters.time && <TimeFormSelect />}
        {visibleFilters.hostName && <HostNameFormInput />}
        {visibleFilters.ipAddress && <IPAddressFormInput />}
        {selectedTool && <TroubleshootingFiltersButtons />}
      </TNSContainer>
    </div>
  );
};
