import { DeviceDetail } from "../../../portal-app/interfaces/devices/devices";
import { Response } from "../../../portal-app/interfaces/api/api";
import { SwapParams } from "../../models/swap/swap.model";
import { ApiService, ApiCore, IResponse } from "@tnso/api-core";
import { SwapDeviceHistory, SwapStatusByTimestamp } from "../../models/swap-history";
import { QueryParams } from "../../../../app/portal-app/interfaces/shared/queryParams";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class SwapDevicesService extends ApiService {
  static async getAll(params?: QueryParams): Promise<IResponse<SwapDeviceHistory> | undefined> {
    const urlSearchParams = ApiCore.encodeParams<QueryParams>(params ?? {});
    return this.get<SwapDeviceHistory>(`${getApiBaseUrl()}/devices/swap/history`, urlSearchParams);
  }

  static async getDevicesNames(): Promise<Response<string[]> | undefined> {
    return this.get<string[]>(`${getApiBaseUrl()}/devices/names`);
  }

  static async getDetail(tnsDeviceName: string): Promise<Response<DeviceDetail> | undefined> {
    return this.get<DeviceDetail>(`${getApiBaseUrl()}/devices/${tnsDeviceName}`);
  }

  static async getDevicesToSwap(tnsDeviceName?: string): Promise<Response<string[]> | undefined> {
    return this.get<string[]>(`${getApiBaseUrl()}/devices/names/${tnsDeviceName}`);
  }

  static async getDeviceToSwap(tnsDeviceName?: string): Promise<Response<string[]> | undefined> {
    return this.get<string[]>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/swap`);
  }

  static async sendSwap(tnsDeviceName?: string, swapDeviceName?: SwapParams): Promise<Response<SwapParams> | undefined> {
    return this.post<SwapParams>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/swap`, swapDeviceName);
  }
  static async getStatusByTimestamp(tnsDeviceName: string, swapTimestamp: string): Promise<IResponse<SwapStatusByTimestamp> | undefined> {
    return this.get<SwapStatusByTimestamp>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/swap/status/timestamp/${swapTimestamp}`);
  }
}
