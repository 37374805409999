import { CognitoTokenUserData } from "src/app/portal-app/service/auth/AuthService";

export type EnvironmentVariablesFull = EnvironmentVariables & CognitoTokenUserData & NodeJS.ProcessEnv;

export enum FeatureFlags {
  ForceLocalVariables = "FORCE_LOCAL_VARIABLES",
  Monitoring = "MENU_MONITORING_ENABLED",
  MonitoringDevices = "MENU_MONITORING_DEVICES_ENABLED",
  Administration = "MENU_ADMINISTRATION_ENABLED",
  AdministrationCompany = "MENU_ADMINISTRATION_COMPANY_ENABLED",
  AdministrationUser = "MENU_ADMINISTRATION_USER_ENABLED",
  FiltersInMenuTree = "FILTERS_IN_MENU_TREE",
  UptimeEnabled = "UPTIME_ENABLED",
  DetailsEnabled = "DETAILS_ENABLED",
  MembersEnabled = "MEMBERS_ENABLED",
  LvcEnabled = "LVC_ENABLED",
  NotesEnabled = "NOTES_ENABLED",
  EventsEnabled = "EVENTS_ENABLED",
  SoftwareEnabled = "SOFTWARE_ENABLED",
  CellularUsageEnabled = "CELLULAR_USAGE_ENABLED",
  CellularSignalEnabled = "CELLULAR_SIGNAL_ENABLED",
  WirelessEnabled = "WIRELESS_ENABLED",
  HardwareEnabled = "HARDWARE_ENABLED",
  ConnectedDevicesEnabled = "CONNECTED_DEVICES_ENABLED",
  TroubleshootingEnabled = "TROUBLESHOOTING_ENABLED",
  TroubleshootingStopButtonVisible = "FEATURE_FLAG_TROUBLESHOOTING_STOP_BUTTON_VISIBLE",
  FirewallRulesEnabled = "FIREWALL_RULES_ENABLED",
  SwitchChangeThemeEnabled = "SWITCH_CHANGE_THEME_ENABLED",
  LanComponentActivated = "LAN_COMPONENT_ACTIVATED",
  MfaLandingPageEnabled = "MFA_LANDING_PAGE_ENABLED",
  AppDiscoveryViewEnabled = "APP_DISCOVERY_VIEW_ENABLED",
  TNSOUrl = "VITE_TNSO_SSO_URL",
  MfaEnabled = "VITE_MFA_ENABLED",
  IsApiMockingEnabled = "IS_API_MOCKING_ENABLED",
  SplunkEnabled = "SpunkEnabled",
  LogEnabled = "Log",
  SoftwareTabEnabled = "SOFTWARE_TAB_ENABLED",
}
export interface EnvironmentVariables {
  FORCE_LOCAL_VARIABLES?: boolean;
  GOOGLE_API_KEY?: string;
  STRAPI_TOKEN?: string;
  API_SOCKET_URL?: string;
  JWT_SECRET?: string;
  API_URL_STRAPI?: string;
  API_URL_BASE?: string;
  UPTIME_ENABLED?: string;
  DETAILS_ENABLED?: string;
  MEMBERS_ENABLED?: string;
  LVC_ENABLED?: string;
  NOTES_ENABLED?: string;
  EVENTS_ENABLED?: string;
  SOFTWARE_ENABLED?: string;
  CELLULAR_USAGE_ENABLED?: string;
  CELLULAR_SIGNAL_ENABLED?: string;
  WIRELESS_ENABLED?: string;
  HARDWARE_ENABLED?: string;
  CONNECTED_DEVICES_ENABLED?: string;
  TROUBLESHOOTING_ENABLED?: string;
  FIREWALL_RULES_ENABLED?: string;
  TROUBLESHOOTING_PING_TIME?: string;
  TROUBLESHOOTING_TCP_TIME?: string;
  TROUBLESHOOTING_ATTEMPS?: string;
  TROUBLESHOOTING_ATTEMPS_DEFAULT?: string;
  TROUBLESHOOTING_TIME_PING?: string;
  TROUBLESHOOTING_TIME_PING_DEFAULT?: string;
  TROUBLESHOOTING_TIME_TCPPACKETCAPTURE?: string;
  TROUBLESHOOTING_TIME_TCPPACKETCAPTURE_DEFAULT?: string;
  TROUBLESHOOTING_COMMAND_EXTRA_WAITING_TIME_SECONDS?: string;
  DIFFERENCE_TIME_TO_REFRESH_TOKEN?: string;
  DIFFERENCE_TIME_TO_REFRESH_COGNITO_TOKEN?: string;
  INITIAL_MAP_ZOOM?: string;
  SWITCH_CHANGE_THEME_ENABLED?: string;
  LAN_COMPONENT_ACTIVATED?: string;
  MENU_MONITORING_ENABLED?: string;
  MENU_MONITORING_DEVICES_ENABLED?: string;
  MENU_ADMINISTRATION_ENABLED?: string;
  MENU_ADMINISTRATION_COMPANY_ENABLED?: string;
  MENU_ADMINISTRATION_USER_ENABLED?: string;
  FILTERS_IN_MENU_TREE?: string;
  NUMBER_RELEASE?: string;
  SMS_COUNTRIES_ALLOWED?: string;
  IS_API_MOCKING_ENABLED?: string;
  CLIENT_IDS?: string;
  AWS_USER_POOLS_WEB_CLIENT_ID?: string;
  AWS_COGNITO_IDENTITY_POOL_ID?: string;
  AWS_COGNITO_REGION?: string;
  AWS_PROJECT_REGION?: string;
  AWS_USER_POOLS_ID?: string;
  CLOUDFRONT_DISTRIBUTION_DOMAIN_NAME?: string;
  CLOUDFRONT_DISTRIBUTION_ID?: string;
  S3_BUCKET_NAME?: string;
  AWS_COGNITO_USERNAME_ATTRIBUTES?: string;
  AWS_COGNITO_SOCIAL_PROVIDERS?: string;
  AWS_COGNITO_SIGNUP_ATTRIBUTES?: string;
  AWS_COGNITO_MFA_CONFIGURATION?: string;
  AWS_COGNITO_MFA_TYPES?: string;
  AWS_COGNITO_PASSWORDPOLICYMINLENGTH?: string;
  AWS_COGNITO_PASSWORDPOLICYCHARACTERS?: string;
  AWS_COGNITO_VERIFICATION_MECHANISMS?: string;
  TNSO_SSO_URL?: string;
  MFA_LANDING_PAGE_ENABLED?: string;
  APP_DISCOVERY_VIEW_ENABLED?: string;
  VITE_MFA_ENABLED?: string;
  STRAPI_RELEASE_CONTENT_IDS?: string;
  VITE_LAMBDA_PARAMS_URL?: string;
  Log?: string | boolean;
  SplunkEnabled?: string | boolean;
  SOFTWARE_TAB_ENABLED?: string;
  PAGINATION_SIZE_OPTIONS?: string;
  DEFAULT_PAGE_SIZE?: string;
}

export const environmentVariables: EnvironmentVariables = {
  FORCE_LOCAL_VARIABLES: false,
  GOOGLE_API_KEY: undefined,
  STRAPI_TOKEN: undefined,
  API_SOCKET_URL: undefined,
  JWT_SECRET: undefined,
  API_URL_STRAPI: undefined,
  API_URL_BASE: undefined,
  UPTIME_ENABLED: undefined,
  DETAILS_ENABLED: undefined,
  MEMBERS_ENABLED: undefined,
  LVC_ENABLED: undefined,
  NOTES_ENABLED: undefined,
  EVENTS_ENABLED: undefined,
  SOFTWARE_ENABLED: undefined,
  CELLULAR_USAGE_ENABLED: undefined,
  CELLULAR_SIGNAL_ENABLED: undefined,
  WIRELESS_ENABLED: undefined,
  HARDWARE_ENABLED: undefined,
  CONNECTED_DEVICES_ENABLED: undefined,
  TROUBLESHOOTING_ENABLED: undefined,
  FIREWALL_RULES_ENABLED: undefined,
  TROUBLESHOOTING_PING_TIME: undefined,
  TROUBLESHOOTING_TCP_TIME: undefined,
  TROUBLESHOOTING_ATTEMPS: undefined,
  TROUBLESHOOTING_TIME_PING: undefined,
  DIFFERENCE_TIME_TO_REFRESH_TOKEN: undefined,
  DIFFERENCE_TIME_TO_REFRESH_COGNITO_TOKEN: undefined,
  TROUBLESHOOTING_TIME_TCPPACKETCAPTURE: undefined,
  INITIAL_MAP_ZOOM: undefined,
  SWITCH_CHANGE_THEME_ENABLED: undefined,
  LAN_COMPONENT_ACTIVATED: undefined,
  MENU_MONITORING_ENABLED: undefined,
  MENU_MONITORING_DEVICES_ENABLED: undefined,
  MENU_ADMINISTRATION_ENABLED: undefined,
  MENU_ADMINISTRATION_COMPANY_ENABLED: undefined,
  MENU_ADMINISTRATION_USER_ENABLED: undefined,
  FILTERS_IN_MENU_TREE: undefined,
  NUMBER_RELEASE: undefined,
  SMS_COUNTRIES_ALLOWED: undefined,
  IS_API_MOCKING_ENABLED: undefined,
  CLIENT_IDS: undefined,
  AWS_USER_POOLS_WEB_CLIENT_ID: undefined,
  AWS_COGNITO_IDENTITY_POOL_ID: undefined,
  AWS_COGNITO_REGION: undefined,
  AWS_PROJECT_REGION: undefined,
  AWS_USER_POOLS_ID: undefined,
  CLOUDFRONT_DISTRIBUTION_DOMAIN_NAME: undefined,
  CLOUDFRONT_DISTRIBUTION_ID: undefined,
  S3_BUCKET_NAME: undefined,
  AWS_COGNITO_USERNAME_ATTRIBUTES: undefined,
  AWS_COGNITO_SOCIAL_PROVIDERS: undefined,
  AWS_COGNITO_SIGNUP_ATTRIBUTES: undefined,
  AWS_COGNITO_MFA_CONFIGURATION: undefined,
  AWS_COGNITO_MFA_TYPES: undefined,
  AWS_COGNITO_PASSWORDPOLICYMINLENGTH: undefined,
  AWS_COGNITO_PASSWORDPOLICYCHARACTERS: undefined,
  AWS_COGNITO_VERIFICATION_MECHANISMS: undefined,
  TNSO_SSO_URL: undefined,
  MFA_LANDING_PAGE_ENABLED: undefined,
  APP_DISCOVERY_VIEW_ENABLED: undefined,
  VITE_MFA_ENABLED: undefined,
  VITE_LAMBDA_PARAMS_URL: undefined,
  STRAPI_RELEASE_CONTENT_IDS: undefined,
  Log: undefined,
  SplunkEnabled: undefined,
  SOFTWARE_TAB_ENABLED: undefined,
  PAGINATION_SIZE_OPTIONS: "10,25,50,100",
  DEFAULT_PAGE_SIZE: "25"
};

export interface ConfigurationParams {
  action: string;
  path: string;
  applicationId: string;
  environmentId: string;
  configurationProfileId: string;
}
