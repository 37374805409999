import React, { useCallback, useMemo, useState } from "react";
import { FilterType } from "../../../interfaces/devices/group/group";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../administration/translations/translation";
import { RadioChangeEvent } from "antd";
import { groupsColumns } from "./groups-columns";
import "./device-grouping.scss";
import { useAsyncCall } from "src/hooks/useAsyncCallShared";
import { store } from "../../../../../app/portal-app/store/StoreMobx";
import { AssociatedDevicesInGroupResponse } from "../../../interfaces/devices/group/available-groups";
import { DeviceGroupItemList } from "./device-group-item-list";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { TNSModal, TNSRadioButton, TNSTransferList } from "@tns/ui-components";

export interface DeviceGroupTransferListProps {
  groupName: string;
  groupColor: string;
  transferListEnable?: boolean;
}
export function DeviceGroupTransferList({ groupName, groupColor, transferListEnable }: DeviceGroupTransferListProps): JSX.Element {
  const { t } = useTranslation();
  const [showModalAddAll, setShowModalAddAll] = useState(false);
  const [showModalRemoveAll, setShowModalRemoveAll] = useState(false);
  const [availableDevices, setAvailableDevices] = useState<AssociatedDevicesInGroupResponse>();
  const [associatedDevices, setAssociatedDevices] = useState<AssociatedDevicesInGroupResponse>();
  const { radioButtonOptions } = groupsColumns();
  const [selectedFilter, setSelectedFilter] = useState<FilterType>(FilterType.AllDevices);
  const [checkedDeviceAvailable, setCheckedDeviceAvailable] = useState<string[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] }>([]);
  const [deviceCheckedAssociate, setDeviceCheckedAssociate] = useState<string[]>([]);
  const [availableCurrentPage, setAvailableCurrentPage] = useState(1);
  const [associatedCurrentPage, setAssociatedCurrentPage] = useState(1);
  const [availableSearchValue, setAvailableSearchValue] = useState("");
  const [associatedSearchValue, setAssociatedSearchValue] = useState("");
  const showAddAll = useMemo(() => {
    return (
      (availableDevices?.devices && availableDevices.devices.length > 0) ||
      (availableDevices?.acnas && availableDevices.acnas.length > 0) ||
      (availableDevices?.states && availableDevices.states.length > 0) ||
      (availableDevices?.cities && availableDevices.cities.length > 0) ||
      (availableDevices?.countries && availableDevices.countries.length > 0)
    );
  }, [availableDevices]);

  const loadDevicesData = useCallback(async () => {
    const devicesResponse = await store.group.loadAvailableAllDevices(groupName, selectedFilter, { startAtRecord: 0, recordsPerPage: 10 });
    const associatedDevicesRersponse = await store.group.loadAssocaitedDevices(groupName, { startAtRecord: 0, recordsPerPage: 10 });
    setAvailableDevices(devicesResponse);
    setAssociatedDevices(associatedDevicesRersponse);
  }, [groupName, selectedFilter]);

  useAsyncCall(async () => {
    if (groupName) {
      await loadDevicesData();
    }
  }, [groupName, selectedFilter]);

  const handleAvailable = useCallback(async () => {
    if (groupName) {
      await store.group.editDevicesByGroupName(groupName, { removeTnsDeviceNameList: deviceCheckedAssociate as string[] });
      setDeviceCheckedAssociate([]);
      await loadDevicesData();
      setAssociatedCurrentPage(1);
      setAssociatedSearchValue("");
    }
  }, [groupName, deviceCheckedAssociate]);

  const handleAssociate = useCallback(async () => {
    if (groupName) {
      if (!_.isEmpty(checkedDeviceAvailable) || !_.isEmpty(checkedKeys)) {
        const deviceUpdate = selectedFilter === FilterType.AllDevices ? checkedDeviceAvailable : checkedKeys;
        if (associatedDevices?.devices) {
          const deviceList = [...associatedDevices.devices, ...(deviceUpdate as string[])];
          await store.group.editDevicesByGroupName(groupName, { addTnsDeviceNameList: deviceList });
          setCheckedKeys([]);
          setCheckedDeviceAvailable([]);
          await loadDevicesData();
          setAvailableCurrentPage(1);
          setAvailableSearchValue("");
        }
      }
    }
  }, [groupName, checkedDeviceAvailable, checkedKeys, groupColor, selectedFilter, associatedDevices?.devices]);

  const handleChangeLeftPage = useCallback(
    async (page: number) => {
      const devicesResponse = await store.group.loadAvailableAllDevices(groupName, selectedFilter, {
        startAtRecord: (page - 1) * 10,
        recordsPerPage: 10,
        tnsDeviceName: availableSearchValue
      });
      setAvailableCurrentPage(page);
      setAvailableDevices(devicesResponse);
    },
    [groupName, selectedFilter, availableSearchValue]
  );

  const handleChangeRightPage = useCallback(
    async (page: number) => {
      const associatedDevicesRersponse = await store.group.loadAssocaitedDevices(groupName, {
        startAtRecord: (page - 1) * 10,
        recordsPerPage: 10,
        tnsDeviceName: associatedSearchValue
      });
      setAssociatedCurrentPage(page);
      setAssociatedDevices(associatedDevicesRersponse);
    },
    [groupName, associatedSearchValue]
  );

  const handleAvailableSearch = useCallback(
    async (event: React.ChangeEvent<Element>) => {
      let key: string;
      switch (selectedFilter) {
        case FilterType.AllDevices:
          key = "tnsDeviceName";
          break;
        case FilterType.Acna:
          key = "acna";
          break;
        case FilterType.Country:
          key = "country";
          break;
        case FilterType.City:
          key = "city";
          break;
        case FilterType.State:
          key = "state";
          break;
      }

      const htmlEvent = event as React.ChangeEvent<HTMLInputElement>;
      if (groupName) {
        setAvailableSearchValue(htmlEvent.target.value);
        const avialableDevicesResponse = await store.group.loadAvailableAllDevices(groupName, selectedFilter, {
          startAtRecord: 0,
          recordsPerPage: 10,
          [key]: htmlEvent.target.value
        });
        setAvailableDevices(avialableDevicesResponse);
        setAvailableCurrentPage(1);
      }
    },
    [groupName, selectedFilter]
  );

  const handleAssociateSearch = useCallback(
    async (event: React.ChangeEvent<Element>) => {
      const htmlEvent = event as React.ChangeEvent<HTMLInputElement>;
      if (groupName) {
        setAssociatedSearchValue(htmlEvent.target.value);
        const associatedDevicesResponse = await store.group.loadAssocaitedDevices(groupName, {
          startAtRecord: 0,
          recordsPerPage: 10,
          tnsDeviceName: htmlEvent.target.value
        });
        setAssociatedDevices(associatedDevicesResponse);
        setAssociatedCurrentPage(1);
      }
    },
    [groupName]
  );

  const handleChangeRadioButton = useCallback(
    async (e: RadioChangeEvent) => {
      setAvailableSearchValue("");
      const filter = e.target.value;
      setSelectedFilter(filter);
      const devicesResponse = await store.group.loadAvailableAllDevices(groupName, filter, { startAtRecord: 0, recordsPerPage: 10, tnsDeviceName: "" });
      setAvailableDevices(devicesResponse);
      setAvailableCurrentPage(1);
    },
    [setSelectedFilter, groupName]
  );

  const handleAssociateAll = useCallback(async () => {
    await store.group.editDevicesByGroupName(groupName, { addAllDevices: true });
    handleFilterTohandleAll();
    await loadDevicesData();
    setShowModalAddAll(false);
  }, [groupName, selectedFilter]);

  const handleRemoveAll = useCallback(async () => {
    await store.group.editDevicesByGroupName(groupName, { removeAllDevices: true });
    handleFilterTohandleAll();
    await loadDevicesData();
    setShowModalRemoveAll(false);
  }, [groupName, selectedFilter]);

  const handleFilterTohandleAll = (): void => {
    setAvailableCurrentPage(1);
    setAssociatedCurrentPage(1);
    setAvailableSearchValue("");
    setAssociatedSearchValue("");
  };

  const showRemoveAll = associatedDevices?.devices && associatedDevices?.devices.length > 0;
  return (
    <>
      <div className="profile-tab-container mb-4">
        <TNSRadioButton defaultValue={selectedFilter} value={selectedFilter} onChange={handleChangeRadioButton} options={radioButtonOptions} disabled={!transferListEnable} />
      </div>
      <div className="wrapper">
        <TNSTransferList
          showSearch
          textbuttonAll={t(TRANSLATION.PROFILES.CREATE_PROFILE.addAll)}
          textButtonRemoveAll={t(TRANSLATION.PROFILES.CREATE_PROFILE.removeAll)}
          addAll={(): void => setShowModalAddAll(true)}
          removeAll={(): void => setShowModalRemoveAll(true)}
          availableCurrentPage={availableCurrentPage}
          associatedCurrentPage={associatedCurrentPage}
          showPagination
          availableSearchValue={availableSearchValue}
          associatedSearchValue={associatedSearchValue}
          headerAvailableComponent={t(TRANSLATION.PROFILES.CREATE_PROFILE.availableDevices)}
          headerAssociatedComponent={t(TRANSLATION.PROFILES.CREATE_PROFILE.selectedDevices)}
          recordsTranslated={[t(TRANSLATION.SHARED.TABLE.records), t(TRANSLATION.SHARED.TABLE.of)]}
          transferToAvailableButtonActive={!transferListEnable || _.isEmpty(deviceCheckedAssociate)}
          transferToAssociatedButtonActive={!transferListEnable || (_.isEmpty(checkedDeviceAvailable) && _.isEmpty(checkedKeys))}
          availableData={
            [
              <DeviceGroupItemList
                deviceChecked={checkedDeviceAvailable}
                setCheckedDevices={setCheckedDeviceAvailable}
                selectedFilter={selectedFilter}
                devices={availableDevices?.devices}
                checkedKeys={checkedKeys}
                devicesResponse={availableDevices}
                setCheckedKeys={setCheckedKeys}
                key={availableDevices?.devices?.length}
              />
            ] ?? []
          }
          associatedData={
            [
              <DeviceGroupItemList
                deviceChecked={deviceCheckedAssociate}
                setCheckedDevices={setDeviceCheckedAssociate}
                selectedFilter={FilterType.AllDevices}
                devices={associatedDevices?.devices}
                checkedKeys={checkedKeys}
                devicesResponse={associatedDevices}
                setCheckedKeys={setCheckedKeys}
                key={associatedDevices?.devices?.length}
              />
            ] ?? []
          }
          transferToAvailable={handleAvailable}
          transferToAssociated={handleAssociate}
          totalRecords={availableDevices?.totalRecords && availableDevices.totalRecords > 0 ? availableDevices.totalRecords : undefined}
          totalAssociatedRecords={associatedDevices?.totalRecords && associatedDevices.totalRecords > 0 ? associatedDevices.totalRecords : undefined}
          handleAvailableGoToPage={handleChangeLeftPage}
          handleAssociatedGoToPage={handleChangeRightPage}
          availableOnChange={handleAvailableSearch}
          associatedOnChange={handleAssociateSearch}
          disabledButtonRemoveAll={!showRemoveAll}
          disabledButtonAddAll={!showAddAll}
        />
        <TNSModal
          closable={false}
          open={showModalAddAll}
          title={
            <>
              <FontAwesomeIcon icon={faCircleExclamation} color="#F8E00D" />
              {` ${t(TRANSLATION.PROFILES.CREATE_PROFILE.areYouSureYouWantToMoveAll)} ${availableDevices?.totalRecords}
              ${t(TRANSLATION.PROFILES.CREATE_PROFILE.toTheSelectedDevicesList)}?`}
            </>
          }
          textCancelButton={t(TRANSLATION.PROFILES.CREATE_PROFILE.no)}
          textOkButton={t(TRANSLATION.PROFILES.CREATE_PROFILE.yes)}
          handleAccept={handleAssociateAll}
          handleCancel={(): void => setShowModalAddAll(false)}
        />
        <TNSModal
          closable={false}
          open={showModalRemoveAll}
          title={
            <>
              <FontAwesomeIcon icon={faCircleExclamation} color="#F8E00D" />
              {` ${t(TRANSLATION.PROFILES.CREATE_PROFILE.areYouSureYouWantToRemoveAll)} ${associatedDevices?.totalRecords}
              ${t(TRANSLATION.PROFILES.CREATE_PROFILE.fromTheSelectedDevicesList)}?`}
            </>
          }
          textCancelButton={t(TRANSLATION.PROFILES.CREATE_PROFILE.no)}
          textOkButton={t(TRANSLATION.PROFILES.CREATE_PROFILE.yes)}
          handleAccept={handleRemoveAll}
          handleCancel={(): void => setShowModalRemoveAll(false)}
        />
      </div>
    </>
  );
}
