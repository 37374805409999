import { ApiCore, ApiService, IResponse } from "@tnso/api-core";
import { LVCResponse } from "../../../models/devices/response/response";
import { QueryParams } from "src/shared/models/queryParams";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class LVCService extends ApiService {
  static async getAll(deviceName: string, params?: QueryParams): Promise<IResponse<LVCResponse> | undefined> {
    const urlSearchParams = ApiCore.encodeParams<QueryParams>(params as QueryParams);
    return this.get<LVCResponse>(`${getApiBaseUrl()}/devices/${deviceName}/lvc`, urlSearchParams);
  }
}
