import React, { useContext } from "react";
import * as darkTokens from "@tns/ui-style-dictionary/build/ts/dark-tokens";
import * as lightTokens from "@tns/ui-style-dictionary/build/ts/light-tokens";
import { Col } from "react-bootstrap";
import { InterfaceDetails } from "../../../../interfaces/memberConnectivity/memberConnectivity";
import { interfaceType } from "../../../../interfaces/memberConnectivity/memberConnectivity";
import { CustomizerContext } from "../../../../../../contexts/customizer/CustomizerContext";
import { Themes } from "@tns/ui-components";

interface Props {
  interfaceDetails?: InterfaceDetails[];
}

export const ItemsInstance: React.FC<Props> = ({ interfaceDetails }) => {
  const customizer = useContext(CustomizerContext);

  return (
    <td className="col-2 border-1 p-0" data-testid="item-instance">
      {interfaceDetails?.map((item, index) => (
        <Col
          key={index}
          className="text-center border-1 py-2"
          style={{
            borderBottom: `${
              interfaceDetails.length > 1 && index < interfaceDetails.length - 1
                ? `1px solid ${customizer.selectedTheme === Themes.DARK ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}`
                : "0px"
            }`,
            ...(interfaceDetails.length <= 1 || index === interfaceDetails.length - 1 ? { marginTop: "0.3rem" } : {})
          }}>
          {interfaceType(item.interfaceType)}
        </Col>
      ))}
    </td>
  );
};
