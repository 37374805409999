import { SortOrder } from "antd/es/table/interface";
import { Key } from "react";

export enum FilterType {
  Country = "countries",
  City = "cities",
  State = "states",
  Acna = "acnas",
  AllDevices = "devices"
}

export enum OnboardedType {
  OnBoardedDevices = "onBoardedDevices",
  NonBoardedDevices = "nonBoardedDevices"
}
export enum Tabs {
  Devices = "devices",
  Contact = "contact"
}

export interface SelectOptions {
  value: string;
  label: string;
  canBeDeleted: boolean;
  devices: string[];
  userCount: number;
}
export interface DeviceProfileDevices {
  tnsDeviceName: string;
}
export interface DeviceProfileInterface {
  name: string;
  userCount: number;
  canBeDeleted: boolean;
  devices: string[];
}

export interface DeviceProfileResponse {
  profiles: DeviceProfileInterface[];
}

export interface ProfileNameAvailable {
  available: boolean;
}

export interface DevicesByFilter {
  name: string;
  devices: string[];
}

export interface AvailableDevicesResponseByFilter {
  totalRecords: number;
  returnedRecords: number;
  acnas?: DevicesByFilter[];
  states?: DevicesByFilter[];
  cities?: DevicesByFilter[];
  countries?: DevicesByFilter[];
  devices?: string[];
}

export interface ProfilesToEdit {
  name: string;
  devices?: string;
}

export interface ProfileName {
  name: string;
}

export interface BuilderParams {
  currentPage?: number;
  profileName?: string;
  deviceName?: string;
  recordsPerPage?: number;
  tableFilters?: Record<string, string>;
  orderBy?: string | SortOrder;
  sortBy?: string | Key;
  onboarded?: boolean;
}

export interface DeviceProfileParams {
  startAtRecord?: number;
  tnsDeviceName?: string;
  recordsPerPage?: number;
  acna?: string;
  state?: string;
  citie?: string;
  countrie?: string;
  entityProfileName?: string;
  currentPage?: number;
  profileName?: string;
  tableFilters?: Record<string, string>;
  orderBy?: string | SortOrder;
  sortBy?: string | Key;
  onboarded?: boolean;
}

export interface DeviceProfileParamsToUpdate {
  addTnsDeviceNameList?: string[];
  removeTnsDeviceNameList?: string[];
  removeAllBoardedDevices?: boolean;
  removeAllNonboardedDevices?: boolean;
  addAllBoardedDevices?: boolean;
  addAllNonboardedDevices?: boolean;
}
