import { StatusCode } from "@tnso/api-core";
import { makeAutoObservable } from "mobx";
import { ConnectedDevicesParams, ConnectedDevicesResponse } from "../../../models/connected-devices/connected-devices";
import { ConnectedDevicesService } from "../../../service/device/dhi/connected-devices-service";
import { TRANSLATION } from "../../../utils/const/translation";
import { MessageHelper } from "../../../../../shared/helpers/MessageHelper";
import { i18nInstance } from "../../../../../i18n";
import { DeviceService } from "../../../../../app/monitoring/service/device/device-service";
import { Features } from "../../../../../layouts/sidebars/sidebardata/SidebarData";
import { AxiosResponse } from "axios";
import _ from "lodash";

export interface IConnectedDevices {
  isAvailableTab: boolean;
  loadData(params?: ConnectedDevicesParams): Promise<ConnectedDevicesResponse | undefined>;
  loadDataByTnsDeviceName(tnsDeviceName: string, params?: ConnectedDevicesParams): Promise<ConnectedDevicesResponse | undefined>;
  getAvailableLanPorts(tnsDeviceName?: string): Promise<string[] | undefined>;
  updateConnectedDeviceName(
    connectedDeviceName: string,
    macAddress: string,
    params?: ConnectedDevicesParams,
    tnsDeviceName?: string
  ): Promise<ConnectedDevicesResponse | undefined>;
  isConnectedDeviceAvailable(tnsDeviceName: string): Promise<void>;
  getModelsByConnectedDevices(): Promise<string[] | undefined>;
}

class ConnectedDevices implements IConnectedDevices {
  isAvailableTab = true;

  constructor() {
    makeAutoObservable(this);
  }

  async loadData(params?: ConnectedDevicesParams): Promise<ConnectedDevicesResponse | undefined> {
    try {
      const response = await ConnectedDevicesService.getAll({ deviceParams: params });
      delete params?.startAtRecord;
      delete params?.recordsPerPage;
      if (response?.data) {
        if (response.data.connectedDevices.length === 0) {
          if (!_.isEmpty(params)) {
            response.data.emptyMessage = TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.thereAreNoConnectedDevicesToDisplay;
          } else {
            response.data.emptyMessage = TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.thereAreNoConnectedDevicesToDisplay;
          }
        }
        return response.data;
      }
    } catch (error) {
      const errorParsed = error as AxiosResponse;
      const connectedDeviceResponse: ConnectedDevicesResponse = {
        connectedDevices: [],
        emptyMessage: TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.theDeviceIsEitherNotCompatibleWithThisFunctionalityOrItIsNotOnline,
        totalRecords: 0,
        returnedRecords: 0
      };
      if (errorParsed.status === StatusCode.CONFLICT) {
        connectedDeviceResponse.emptyMessage = TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.theDeviceIsEitherNotCompatibleWithThisFunctionalityOrItIsNotOnline;
        return connectedDeviceResponse;
      }
      return connectedDeviceResponse;
    }
  }

  async loadDataByTnsDeviceName(tnsDeviceName: string, params?: ConnectedDevicesParams): Promise<ConnectedDevicesResponse | undefined> {
    try {
      const response = await ConnectedDevicesService.getAll({ tnsDeviceName, deviceParams: params });
      delete params?.startAtRecord;
      delete params?.recordsPerPage;
      if (response?.data) {
        if (response.data.connectedDevices.length === 0) {
          if (!_.isEmpty(params)) {
            response.data.emptyMessage = TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.thereAreNoConnectedDevicesToDisplay;
          } else {
            response.data.emptyMessage = TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.thereAreNoConnectedDevicesToDisplay;
          }
        }
        return response.data;
      }
    } catch (error) {
      const errorParsed = error as AxiosResponse;
      const connectedDeviceResponse: ConnectedDevicesResponse = {
        connectedDevices: [],
        emptyMessage: TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.theDeviceIsEitherNotCompatibleWithThisFunctionalityOrItIsNotOnline,
        totalRecords: 0,
        returnedRecords: 0
      };
      if (errorParsed.status === StatusCode.CONFLICT) {
        connectedDeviceResponse.emptyMessage = TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.theDeviceIsEitherNotCompatibleWithThisFunctionalityOrItIsNotOnline;
        return connectedDeviceResponse;
      }
      return connectedDeviceResponse;
    }
  }

  async getAvailableLanPorts(tnsDeviceName: string): Promise<string[] | undefined> {
    try {
      const response = await ConnectedDevicesService.getAvailableLanPorts(tnsDeviceName);
      if (response?.data) {
        return response.data;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
    }
  }

  async updateConnectedDeviceName(
    connectedDeviceName: string,
    macAddress: string,
    params?: ConnectedDevicesParams,
    tnsDeviceName?: string
  ): Promise<ConnectedDevicesResponse | undefined> {
    try {
      const response = await ConnectedDevicesService.updateConnectedDeviceName(connectedDeviceName, macAddress);
      if (response?.status === StatusCode.OK || response?.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18nInstance.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.CONNECTEDDEVICES.changesSuccessfullySaved));
        if (tnsDeviceName) {
          return this.loadDataByTnsDeviceName(tnsDeviceName, params);
        } else {
          return this.loadData(params);
        }
      }
    } catch (error) {
      MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
    }
  }

  async isConnectedDeviceAvailable(tnsDeviceName: string): Promise<void> {
    try {
      const response = await ConnectedDevicesService.getAll({ tnsDeviceName });
      if (response?.status === StatusCode.OK) {
        this.isAvailableTab = true;
      } else if (response?.status === StatusCode.CONFLICT) {
        this.isAvailableTab = false;
      }
    } catch (error) {
      this.isAvailableTab = false;
      MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
    }
  }

  async getModelsByConnectedDevices(): Promise<string[] | undefined> {
    try {
      const response = await DeviceService.getModels(Features.ConnectedDevices);
      if (response?.data) {
        return response.data;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
    }
  }
}

const connectedDevices = new ConnectedDevices();

export default connectedDevices;
