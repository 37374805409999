import React, { ReactNode, useMemo } from "react";
import { observer } from "mobx-react";
import { IDeviceMetrics } from "../../../models/devices/dhi/dhi";
import { HardwareDetails } from "./hardware-details";
import { CPUUsageCard } from "../tabs/details/metrics/cpu-usage-card";
import { TemperatureCard } from "../tabs/details/metrics/temperature-card";
import "../tabs/details/device-health-information.scss";
import { DeviceMetricsHelper } from "../../../helpers/dhi/device-metrics-helper";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../monitoring/utils/const/translation";
import { TNSOBadge, TNSOTooltip } from "@tnso/ui-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { BadgeColors } from "../../../models/devices/hardware/hardware";

interface HardwareAllDetailsProps {
  metrics?: IDeviceMetrics;
}

export const HardwareAllDetails: React.FC<HardwareAllDetailsProps> = observer(({ metrics }) => {
  const { t } = useTranslation();

  const memoryDetail = useMemo(() => {
    const colorMemoryUsage = metrics?.memoryUsage ? DeviceMetricsHelper.getColorMemory(metrics?.memoryUsage) : undefined;
    return metrics?.memoryUsage && metrics?.memoryUsage > 0 ? (
      <div style={{ color: colorMemoryUsage }}>
        {metrics?.memoryUsage}% {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.used)}
      </div>
    ) : (
      <h6>
        <span>N/A</span>
      </h6>
    );
  }, [metrics, t]);

  const storageDetail = useMemo(() => {
    return metrics?.storageUsage && metrics?.storageUsage > 0 ? (
      <div>
        {metrics?.storageUsage} {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.kb)}
      </div>
    ) : (
      <h6>
        <span>N/A</span>
      </h6>
    );
  }, [metrics, t]);

  const tooltipContent = useMemo((): ReactNode => {
    return (
      <div className="d-flex flex-column">
        <small className="d-flex gap-2">
          <TNSOBadge color={BadgeColors.normal} /> {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.normal)}:{" <75%"}
        </small>
        <small className="d-flex gap-2">
          <TNSOBadge color={BadgeColors.warning} /> {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.warning)}:{" >75% / <90%"}
        </small>
        <small className="d-flex gap-2">
          <TNSOBadge color={BadgeColors.critical} /> {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.critical)}:{" >=90%"}
        </small>
      </div>
    );
  }, [t]);

  const memoryName = useMemo(() => {
    return (
      <span className="d-flex align-items-center gap-1">
        {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.memory)}
        <TNSOTooltip title={tooltipContent}>
          <FontAwesomeIcon icon={faCircleInfo} />
        </TNSOTooltip>
      </span>
    );
  }, [metrics, t]);

  return (
    <div className="d-flex w-100 h-100 gap-2">
      <TemperatureCard temperatureInCelsius={metrics?.temperatureInCelsius} temperatureInFahrenheit={metrics?.temperatureInFahrenheit} />
      <HardwareDetails name={memoryName}>{memoryDetail}</HardwareDetails>
      <HardwareDetails name={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.HARDWARE.storage)}>{<span>{storageDetail}</span>}</HardwareDetails>
      <CPUUsageCard cpuUsage={metrics?.cpuUsage} />
    </div>
  );
});
