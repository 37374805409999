import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../../../utils/const/translation";
import { useParams } from "react-router-dom";
import { useAsyncCall } from "../../../../../../../../../libraries/shared/src/lib/hooks/use-async-call-shared";
import { Note } from "../../../../../../interfaces/devices/note/note";
import { store } from "../../../../../../store/StoreMobx";
import { observer } from "mobx-react";
import "./Notes.scss";
import { TNSCard, Variants, TNSButton, TNSTextArea } from "@tns/ui-components";
import _ from "lodash";
import ContactCard from "../../../../../../components/device/note/ContactCard";
import { Flex } from "antd";

export const Notes: React.FC = observer(() => {
  const { t } = useTranslation();
  const { device } = store;
  const { deviceName } = useParams();
  const maxNoteLength = 500;
  const isData = useMemo(() => _.isEmpty(device.note.dataContact), [device.note.dataContact]);
  const [note, setNote] = useState<Note>({
    note: ""
  });

  const loadData = useCallback(async () => {
    await device.note.loadData(deviceName);
    if (isData) {
      device.note.setIsContactData(true);
    }
    await store.device.detail.loadData(deviceName);
  }, [deviceName]);

  useEffect(() => {
    if (device.note.data) {
      setNote({
        note: device.note.data.note
      });
    }
  }, [setNote, device.note.data]);

  const loader = useAsyncCall(loadData, [loadData], store.shared.getTimeToAutoRefresh());

  const handleOnChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => setNote({ note: event.target.value }), [setNote]);

  const onClick = useCallback(async () => {
    if (deviceName) {
      await device.note.update(note, deviceName);
    }
  }, [deviceName, note, device.note]);

  return loader.completed ? (
    <>
      <TNSCard>
        <Flex gap="middle" vertical>
          <TNSTextArea
            value={note.note}
            onChange={handleOnChange}
            maxLength={maxNoteLength}
            rows={8}
            placeholder={`${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.NOTES.enterNoteHere)} ...`}
          />
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <TNSButton buttonVariant={Variants.Primary} onClick={onClick}>
              {t(TRANSLATION.SHARED.update)}
            </TNSButton>
          </div>
        </Flex>
      </TNSCard>
      <ContactCard />
    </>
  ) : null;
});
