import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/lib/table";
import { utils } from "xlsx";
import { CellularUsage } from "../../../interfaces/devices/cellular/cellularUsage";
import { cellularUsageTranslatedData, columns } from "../../../store/device/cellularUsage/tableConfig";
import { TRANSLATION } from "../../../utils/const/translation";
import { TranslationHelper } from "../../../helpers/shared/TranslationHelper";
import { MapperHelper } from "../../../helpers/shared/MapperHelper";
import { ExportXLSLHelper, TypeFile } from "../../../helpers/shared/ExportXLSLHelper";
import { TNSDivider, TNSTable } from "@tns/ui-components";

interface Props {
  data?: CellularUsage[];
  loading: boolean;
}
export const HistoricalData: React.FC<Props> = ({ data, loading }) => {
  const { t } = useTranslation();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const dataTranslated = cellularUsageTranslatedData(t, data?.slice().reverse() ?? []);

  const exportData = useCallback(async () => {
    if (data) {
      setIsLoadingExport(true);
      setTimeout(() => {
        const dataMapped = MapperHelper.mapUsageToExport(data);
        const wb = utils.book_new();
        ExportXLSLHelper.addSheetToBook(wb, dataMapped, 1);
        ExportXLSLHelper.exportToXLSL(wb, `${data[0].name}_${t(TRANSLATION.EXPORT.cellularUsageHistoricalData)}`, TypeFile.CSV);
        setIsLoadingExport(false);
      }, 1500);
    }
  }, [data, t, isLoadingExport]);

  const dataTable = useMemo(() => data?.map((historical, index) => ({ key: historical.name + index, ...historical })) ?? [], [data]);

  const grid = useMemo(() => {
    return (
      <div className="d-flex flex-column gap-2">
        <TNSTable
          currentPage={1}
          dataSource={dataTranslated}
          columns={
            loading
              ? TranslationHelper.columnsTranslation(columns.skeletonColumns as unknown as ColumnsType[])
              : TranslationHelper.columnsTranslation(columns.historicalPeriod as unknown as ColumnsType[])
          }
          emptyMessage={t(TRANSLATION.SHARED.DATATABLE.noDataAvailable)}
          exportLabel={t("export")}
          isLoadingExport={isLoadingExport}
          onExport={exportData}
          recordsTranslated={[t(TRANSLATION.SHARED.TABLE.records), t(TRANSLATION.SHARED.TABLE.of)]}
          isLoading={loading}
          scroll={{ x: "max-content" }}
        />
      </div>
    );
  }, [data, t, exportData, isLoadingExport, dataTable, dataTranslated]);

  return (
    <div className="mt-4">
      <TNSDivider type="horizontal" orientation="left">
        <h5>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.historicalDataPeriod)}</h5>
      </TNSDivider>
      {grid}
    </div>
  );
};
