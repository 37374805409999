import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { TNSCard } from "@tns/ui-components";

import { PieChart } from "../../../components/chart/PieChart";
import { LegendUsage } from "./LegendUsage";
import { CellularUsage } from "../../../interfaces/devices/cellular/cellularUsage";
import { UnitConvertHelper } from "@tnso/shared";
import { TRANSLATION } from "../../../utils/const/translation";

interface Props {
  data?: CellularUsage;
}

export const DataConsumed: React.FC<Props> = ({ data }) => {
  const planSize = data?.planSize ? UnitConvertHelper.convertBytesToMebiBytes(data?.planSize).toFixed(2) : 0;
  const total = data?.total ? UnitConvertHelper.convertBytesToMebiBytes(data?.total).toFixed(2) : 0;
  const remaining = data?.planSize && data?.total ? UnitConvertHelper.convertBytesToMebiBytes(data?.planSize - data?.total) : 0;
  const txData = data?.txcnt ? UnitConvertHelper.convertBytesToMebiBytes(data?.txcnt).toFixed(2) : 0;
  const rxData = data?.rxcnt ? UnitConvertHelper.convertBytesToMebiBytes(data?.rxcnt).toFixed(2) : 0;
  const dataPie = [Number(txData), remaining < 0 ? 0 : Number(remaining), Number(rxData)];
  const { t } = useTranslation();

  return (
    <TNSCard className="h-100">
      {data?.planSize ? (
        <>
          <Row>
            <Col className="text-center">
              <h5>
                <b>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.percentOfDataConsumed)}</b>
              </h5>
            </Col>
          </Row>
          <Row className="mt-3">
            <Row>
              <Col md={6} className="d-flex flex-column gap-2 align-items-start">
                <span>
                  {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.deviceDataPlan)}:{" "}
                  <b>
                    {planSize} {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.MiB)}
                  </b>
                </span>
                <span>
                  {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.totalDataConsumed)}:{" "}
                  <b>
                    {total} {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.MiB)}
                  </b>
                </span>
              </Col>
              <Col md={6} className="d-flex flex-column gap-2 align-items-end">
                {data.predictedOverage && data.predictedOverage > 0 ? (
                  <span className="text-orange">
                    {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.overagePredicted)}{" "}
                    <b>
                      {UnitConvertHelper.convertBytesToMebiBytes(data.predictedOverage).toFixed(2)} {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.MiB)}
                    </b>
                  </span>
                ) : null}
                {data.overage && data.overage > 0 ? (
                  <span className="text-red">
                    {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.dataOverage)}{" "}
                    <b>
                      {UnitConvertHelper.convertBytesToMebiBytes(data.overage).toFixed(2)} {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.MiB)}
                    </b>
                  </span>
                ) : null}
              </Col>
            </Row>
            <Row className="align-content-center">
              <Col md={1} xl={2} />
              <Col md={12} className="d-flex justify-content-center data-consumed-chart">
                <PieChart series={dataPie} />
              </Col>
            </Row>
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-end">
              <LegendUsage />
            </Col>
          </Row>
        </>
      ) : (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center" data-testid="error-message-data-consumed">
          <span>{t(TRANSLATION.SHARED.TABLE.noDataFound)}</span>
        </div>
      )}
    </TNSCard>
  );
};
