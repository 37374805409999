import { useEffect, useState } from "react";

interface AsyncApiCallNotStarted {
  started: false;
  loading: false;
  failed: false;
  completed: false;
}

interface AsyncApiCallInProgress {
  started: true;
  loading: true;
  failed: false;
  completed: false;
}

interface AsyncApiCallSuccess<TRes> {
  started: true;
  loading: false;
  failed: false;
  completed: true;
  result: TRes;
}

interface AsyncApiCallError {
  started: true;
  loading: false;
  completed: false;
  failed: true;
  error: unknown;
}

export type AsyncApiCallStatus<TRes> = AsyncApiCallNotStarted | AsyncApiCallInProgress | AsyncApiCallSuccess<TRes> | AsyncApiCallError;

/**
 * A custom React hook that handles asynchronous API calls with optional auto-refresh.
 *
 * This hook manages the state of an API call, including loading, success, and error states.
 * It can also automatically refresh the API call at a specified interval.
 *
 * @param {() => Promise<TRes>} asyncMethod - A function that returns a promise for the asynchronous API call.
 * @param {any[]} dependencies - An array of dependencies that will trigger the API call when changed.
 * @param {number} [autoRefresh] - An optional interval in milliseconds for auto-refreshing the API call.
 *
 * @returns {AsyncApiCallStatus<TRes>} The current status of the API call, including loading, success, or error state.
 */
export function useAsyncCall<TRes>(asyncMethod: () => Promise<TRes>, dependencies: any[], autoRefresh?: number): AsyncApiCallStatus<TRes> {
  const [apiCallStatus, setApiCallStatus] = useState<AsyncApiCallStatus<TRes>>({
    started: false,
    loading: false,
    failed: false,
    completed: false
  });

  useEffect(() => {
    let canceled = false;

    /**
     * Executes the asynchronous API call and updates the state based on the result.
     *
     * If the call is successful, updates the state to indicate success.
     * If the call fails, updates the state to indicate failure.
     *
     * @returns {Promise<void>} A promise that resolves when the API call is complete.
     */
    const fetchData = async (): Promise<void> => {
      try {
        setApiCallStatus({ started: true, loading: true, failed: false, completed: false });
        const result = await asyncMethod();
        if (!canceled) {
          setApiCallStatus({ started: true, loading: false, failed: false, completed: true, result });
        }
      } catch (error) {
        if (!canceled) {
          setApiCallStatus({ started: true, loading: false, failed: true, completed: false, error });
        }
      }
    };

    // Initial API call
    fetchData();

    if (autoRefresh) {
      /**
       * Sets up an interval to automatically refresh the API call based on the specified interval.
       *
       * @returns {void} Clears the interval when the component is unmounted or the interval changes.
       */
      const intervalId = setInterval(fetchData, autoRefresh);

      return () => {
        clearInterval(intervalId);
        canceled = true;
      };
    } else {
      return () => {
        canceled = true;
      };
    }
  }, [asyncMethod, ...dependencies, autoRefresh]);

  return apiCallStatus;
}

/**
 * Executes an asynchronous API call and updates the provided state based on the result.
 *
 * This function is used for manual control of the API call and status updates.
 *
 * @param {() => Promise<TRes>} asyncMethod - A function that returns a promise for the asynchronous API call.
 * @param {React.Dispatch<React.SetStateAction<AsyncApiCallStatus<TRes> | undefined>>} setSubmitStatus - A state setter function to update the status of the API call.
 *
 * @returns {Promise<void>} A promise that resolves when the API call is complete.
 */
export async function runAsyncCall<TRes>(
  asyncMethod: () => Promise<TRes>,
  setSubmitStatus: React.Dispatch<React.SetStateAction<AsyncApiCallStatus<TRes> | undefined>>
): Promise<void> {
  try {
    setSubmitStatus({ started: true, loading: true, failed: false, completed: false });
    const result = await asyncMethod();
    setSubmitStatus({ started: true, loading: false, failed: false, completed: true, result });
  } catch (error) {
    setSubmitStatus({ started: true, loading: false, failed: true, completed: false, error });
  }
}
