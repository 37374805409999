import React from "react";
import { Option } from "@tnso/shared";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";
import SelectFilter from "./select-filter";
import InputFilter from "./input-filter";
import DateRangePickerFilter from "./date-range-picker-filter";
import { GapSizes, Orientation, TNSContainer } from "@tns/ui-components";
import { TicketsParams } from "../../../context/incident-management/incident-management-context";

interface FilterPanelProps {
  filters: Filters[];
}
interface Filters {
  type: "input" | "select" | "date";
  key: keyof TicketsParams;
  options?: Option[];
  onSearch: (value: string, key: string) => Promise<void>;
  defaultValue?: CheckboxOptionType[];
  placeholder?: string;
  onDatesChange?: (startDate: string, endDate: string) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
}

const FilterPanel: React.FC<FilterPanelProps> = ({ filters }) => {
  return (
    <TNSContainer gap={GapSizes.med} orientation={Orientation.row}>
      {filters?.map((filter) => {
        switch (filter.type) {
          case "select":
            return (
              <SelectFilter
                options={filter.options ?? []}
                onSearch={(value: string): Promise<void> => filter?.onSearch(value, filter?.key)}
                placeholder={filter?.placeholder}
                key={filter?.key}
                value={filter?.value}
              />
            );
          case "date":
            return filter.onDatesChange && <DateRangePickerFilter key={filter.key} value={filter.value} onDatesChange={filter?.onDatesChange} />;
          default:
            return (
              <InputFilter
                value={filter?.value}
                key={filter.key}
                placeholder={filter?.placeholder}
                onSearch={(value: string): Promise<void> => filter?.onSearch(value, filter?.key)}
              />
            );
        }
      })}
    </TNSContainer>
  );
};

export default FilterPanel;
