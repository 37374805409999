import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DeviceGroups } from "../../models/devices/devices";
import { TNSCheckbox } from "@tns/ui-components";

export interface ItemMenuProp {
  item: DeviceGroups;
  handleChecked?: (e: CheckboxChangeEvent, item: DeviceGroups) => void;
  checkbox?: boolean;
  tooltipOnHover?: boolean;
}

export const MenuItem: React.FC<ItemMenuProp> = ({ item, handleChecked, checkbox, tooltipOnHover }) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="w-100 d-flex align-items-center gap-2">
        {checkbox && handleChecked && (
          <TNSCheckbox
            checked={item.associated}
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            onChange={(e: CheckboxChangeEvent) => handleChecked(e, item)}
          />
        )}
        <FontAwesomeIcon icon={faTag} size="lg" color={item.color} />
        <span className="text-truncate" {...(tooltipOnHover ? { title: item.name } : {})}>
          {item.name}
        </span>
      </div>
    </div>
  );
};
