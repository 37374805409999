import React, { useCallback, useContext, useMemo } from "react";
import { SignalQualityType } from "../../../../../models/devices/cellular/cellularSignal";
import { COLORS } from "../../../../../utils/const/colors";
import { TNSOBadge } from "@tnso/ui-components";
import { CustomizerContext } from "../../../../../../../contexts/customizer/CustomizerContext";

import { Themes } from "@tns/ui-components";
import * as darkTokens from "@tns/ui-style-dictionary/build/ts/dark-tokens";
import * as lightTokens from "@tns/ui-style-dictionary/build/ts/light-tokens";

interface Props {
  valueSignal: string | number;
  statusSignal: SignalQualityType;
}
export const RawDataBody: React.FC<Props> = ({ valueSignal, statusSignal }) => {
  const bgColor = COLORS.BARS;
  const customizer = useContext(CustomizerContext);
  const isDark = useMemo(() => customizer.selectedTheme === Themes.DARK, [customizer.selectedTheme]);

  const assignColors = (status: SignalQualityType): Record<string, string> => {
    switch (status) {
      case SignalQualityType.Poor:
        return { background: bgColor.RED, color: "black" };
      case SignalQualityType.Fair:
        return { background: bgColor.ORANGE, color: "black" };
      case SignalQualityType.Good:
        return { background: bgColor.YELLOW, color: "black" };
      case SignalQualityType.Excellent:
        return { background: bgColor.GREEN, color: "black" };
      default:
        return { background: bgColor.GRAY, color: "white" };
    }
  };

  const handleValue = useCallback((value: string | number) => {
    return Number.isInteger(value) || typeof value === "string" ? value : Number(value).toFixed(1);
  }, []);

  return (
    <td
      // style={{ color: assignColors(statusSignal).color, backgroundColor: assignColors(statusSignal).background }}
      key={valueSignal}
      className="p-2 text-center "
      style={{ borderBottom: `1px solid  ${isDark ? darkTokens.TnsTableHeaderColumnColorDefault : lightTokens.TnsTableHeaderColumnColorDefault}` }}
      data-testid="data-row-body">
      <span className="d-flex align-items-center justify-content-center gap-2">
        <TNSOBadge color={assignColors(statusSignal).background} /> {handleValue(valueSignal)}
      </span>
    </td>
  );
};
