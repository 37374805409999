import { QueryParams } from "../shared/queryParams";

export interface User {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  enabled?: string | boolean;
  credentialsExpired?: boolean;
  accountLocked?: boolean;
  companyProfiles?: string[];
  companyProfile?: string;
  lastLogin?: string;
  timeZone?: string;
  role?: string;
  status?: UserStatus;
  actAs?: string;
  onboarded?: boolean;
  entityProfile?: string;
  camProfile?: string;
  userAccountStatus?: string;
}

export interface UserByRole {
  role: string;
  user: User;
}

export enum ButtonType {
  CREATE = "create",
  UPDATE = "update",
  NEXT = "next"
}

export enum UserStatus {
  Enabled = "enabled",
  Expired = "expired",
  Locked = "locked",
  Disabled = "disabled"
}

export enum UserStatusForm {
  Enabled = "Enabled",
  Disabled = "Disabled"
}

export interface UserTable {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  enabled?: string;
  credentialsExpired?: boolean;
  accountLocked?: boolean;
  companyProfiles?: string | string[];
  lastLogin?: string;
  timeZone?: string;
  role?: string;
  status?: UserStatus;
  onboarded?: boolean;
}

export interface UserDataExport {
  username?: string;
  firstName: string;
  lastName: string;
  email: string;
  enabled: boolean;
  credentialsExpired?: boolean;
  accountLocked?: boolean;
  role?: string;
  companyProfile?: string;
  entityProfile?: string;
}

export interface UserWithCompanyProfiles {
  username?: string;
  firstName: string;
  lastName: string;
  email: string;
  enabled: boolean;
  role?: string;
  phoneNumber?: string;
  companyProfiles?: string[];
}

export interface UserAssociated {
  username: string;
  email: string;
}

export interface UserAssociatedResponse {
  totalRecords: number;
  returnedRecords: number;
  users: UserAssociated[];
}

export interface UserOnBoarded {
  companyProfile?: string;
  entityProfile?: string;
  role?: string;
}

export interface UserAssociatedParams extends QueryParams {
  email?: string;
}

export interface UserResponse {
  totalRecords: number;
  returnedRecords: number;
  users: User[];
}

export interface AvailableByUsername {
  available: boolean;
  onboarded?: boolean;
}

export interface AvailableByEmail {
  available: boolean;
  onboarded?: boolean;
}
// This interface will be removed when the new credentialsExpired logic is done
export interface UserAlternative {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  enabled?: boolean;
  credentialsExpired?: boolean | string;
  accountLocked?: boolean;
  companyProfiles?: string[];
  companyProfile?: string;
  lastLogin?: string;
  timeZone?: string;
  role?: string;
  status?: UserStatus;
}

export interface UserMFAStatus {
  isMfaEnabled?: boolean;
  mfaOption?: string;
}
