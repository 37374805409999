import { Roles } from "../../app/portal-app/interfaces/auth/roleAndPermission/role";

export const RoutesByRoles = {
  // Routes by all roles
  login: [Roles.Basic, Roles.Admin, Roles.SuperUser],
  resetPassword: [Roles.Basic, Roles.Admin, Roles.SuperUser],
  forgotPassword: [Roles.Basic, Roles.Admin, Roles.SuperUser],
  profile: [Roles.Basic, Roles.Admin, Roles.SuperUser],
  contactUs: [Roles.Basic, Roles.Admin, Roles.SuperUser],
  userGuide: [Roles.Basic, Roles.Admin, Roles.SuperUser],
  // Routes by basic role and admin role
  changeManagement: [Roles.Basic, Roles.Admin],
  changeManagementDetail: [Roles.Basic, Roles.Admin],
  devices: [Roles.Basic, Roles.Admin],
  deviceDetail: [Roles.Basic, Roles.Admin],
  // Routes by admin and superuser roles
  administration: [Roles.Admin, Roles.SuperUser],
  companyProfiles: [Roles.Admin, Roles.SuperUser],
  userAdministration: [Roles.Admin, Roles.SuperUser],
  companyAdministration: [Roles.Admin, Roles.SuperUser],
  UserAdministrarion: [Roles.Admin, Roles.SuperUser],

  contact_information: [Roles.Admin, Roles.SuperUser],
  contact_us: [Roles.Admin, Roles.SuperUser],
  user_guide: [Roles.Admin, Roles.SuperUser],
  member_connectivity: [Roles.Admin, Roles.SuperUser],
  monitoring_devices: [Roles.Admin, Roles.SuperUser],
  change_management: [Roles.Admin, Roles.SuperUser]
};
