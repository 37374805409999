import React from "react";
import { TRANSLATION } from "../utils/translations";
import { useTranslation } from "react-i18next";
import { Variants, TNSButton } from "@tns/ui-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

interface ExportContactButtonProps {
  handleClickOnExport: () => void;
  loading: boolean;
  disabled: boolean;
}

export const ExportContactButton: React.FC<ExportContactButtonProps> = ({ handleClickOnExport, loading, disabled }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <TNSButton buttonVariant={Variants.Secondary} loading={loading} onClick={handleClickOnExport} disabled={disabled}>
      <div className="d-flex align-items-center gap-2 justify-content-center">
        <FontAwesomeIcon icon={faDownload} /> <span>{t(TRANSLATION.BUTTONS.exportButton)}</span>
      </div>
    </TNSButton>
  );
};
