import { MemberConnectivityParams, MembersExportResponse } from "../../models/connection/connection";
import { ApiCore, ApiService, IResponse } from "@tnso/api-core";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class MemberExportService extends ApiService {
  static async getAll(params?: MemberConnectivityParams): Promise<IResponse<MembersExportResponse> | undefined> {
    const urlSearchParams = ApiCore.encodeParams<MemberConnectivityParams>(params as MemberConnectivityParams);
    return this.get<MembersExportResponse>(`${getApiBaseUrl()}/members/export`, urlSearchParams);
  }
}
