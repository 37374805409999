import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { TRANSLATION } from "../../../../utils/const/translation";
import { DeviceDetail } from "../../../../models/devices/devices";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { DateHelper } from "@tnso/shared";
import { Variants, TNSButton, TNSDivider } from "@tns/ui-components";
import { InformationCardDetail } from "./InformationCardDetail";

interface AddressCardProps {
  configurationData?: DeviceDetail;
}

export const AddressCard: React.FC<AddressCardProps> = observer(({ configurationData }): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userTimezone = useMemo(() => {
    const userData = JSON.parse(localStorage.getItem("user") ?? "{}");
    return userData.timeZone;
  }, []);

  const handleOnClick = useCallback((): void => {
    navigate(`/monitoring/devices/device-detail/${configurationData?.tnsDeviceName}/device-address-change/edit`);
  }, [navigate, configurationData]);

  return (
    <>
      <div className="d-flex align-items-end justify-content-between">
        <h6>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICECONFIGURATION.deviceLocation)}</h6>
        <TNSButton buttonVariant={Variants.Link} className="float-end" onClick={handleOnClick}>
          <FontAwesomeIcon icon={faPencilAlt} />
        </TNSButton>
      </div>
      <TNSDivider type="horizontal" />
      <div className="d-flex flex-column w-100 ps-2 my-4">
        <InformationCardDetail title={`${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICECONFIGURATION.streetAddress)} 1`} data={configurationData?.streetAddress} />
        <InformationCardDetail title={`${t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICECONFIGURATION.streetAddress)} 2`} data={configurationData?.streetAddress2} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICECONFIGURATION.city)} data={configurationData?.city} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICECONFIGURATION.state)} data={configurationData?.state} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICECONFIGURATION.zipCode)} data={configurationData?.zipCode} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICECONFIGURATION.country)} data={configurationData?.country} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICECONFIGURATION.latitude)} data={configurationData?.latitude} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICECONFIGURATION.longitude)} data={configurationData?.longitude} />
        <InformationCardDetail
          title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICECONFIGURATION.statusLastChangedOn)}
          data={DateHelper.convertTimestampToBrowserCulture(configurationData?.statusLastChangedOn ?? 0, userTimezone, {
            year: "numeric",
            day: "2-digit",
            month: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h23"
          })}
        />
      </div>
    </>
  );
});
