import React, { ReactNode } from "react";
import { faBuildingUser, faCircleQuestion, faCompass, faUserGear, faScrewdriverWrench, faAddressCard, faFileInvoiceDollar, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Roles } from "../../../app/portal-app/interfaces/auth/roleAndPermission/role";

export enum Features {
  Devices = "monitoring_devices",
  Members = "member_connectivity",
  ConnectedDevices = "connected_devices",
  ContactInformation = "contact_information",
  EntityProfile = "entity_profile_administration",
  CompanyProfile = "company_profile_administration",
  UserAdministration = "user_administration",
  UserRole = "role_administration",
  CoinAvailability = "availability_reports",
  TNSlinkProvisioning = "tnslink_provisioning",
  SwapDevices = "swap_devices",
  ChangeManagement = "change_management",
  ContactUs = "contact_us",
  UserGuide = "user_guide",
  SelfServiceTools = "self_service_tools",
  UmbrellaReports = "umbrella_reports",
  UmbrellaDestinationLists = "umbrella_destination_lists_administration",
  IncidentManagement = "incident_management"
}

export interface ISidebarData {
  id: number;
  roles: string[];
  key: string;
  label: ReactNode;
  href: string;
  icon?: ReactNode;
  children?: ISidebarData[];
}

export const SidebarData: ISidebarData[] = [
  {
    id: 1,
    roles: [Roles.Basic, Roles.Admin],
    key: "monitoring",
    label: "monitoring",
    href: "/monitoring",
    icon: <FontAwesomeIcon icon={faCompass} />,
    children: [
      {
        id: 1.2,
        roles: [Roles.Basic, Roles.Admin],
        key: "monitoring_devices",
        label: "devices",
        href: "/monitoring/devices"
      },
      {
        id: 1.3,
        roles: [Roles.Basic, Roles.Admin],
        key: "member_connectivity",
        label: "members",
        href: "/monitoring/members"
      },
      {
        id: 1.4,
        roles: [Roles.Basic, Roles.Admin],
        key: "connected_devices",
        label: "connectedDevices",
        href: "/monitoring/connected-devices"
      }
    ]
  },
  {
    id: 2,
    key: "contact",
    roles: [Roles.Admin, Roles.Basic],
    label: "contact",
    href: "/contact",
    icon: <FontAwesomeIcon icon={faAddressCard} color="red" />,
    children: [
      {
        id: 2.1,
        key: "contact_information",
        roles: [Roles.Admin, Roles.SuperUser, Roles.Basic],
        label: "directory",
        href: "/contact/contact-information"
      }
    ]
  },
  {
    id: 3,
    roles: [Roles.Admin, Roles.SuperUser],
    key: "administration",
    label: "administration",
    href: "/administration",
    icon: <FontAwesomeIcon icon={faBuildingUser} />,
    children: [
      {
        id: 3.1,
        roles: [Roles.SuperUser],
        key: "company_profile_administration",
        label: "companyAdministration",
        href: "/administration/company"
      },
      {
        id: 3.2,
        roles: [Roles.Admin],
        key: "user_administration",
        label: "userProfileAdministration",
        href: "/administration/user"
      },
      {
        id: 3.3,
        roles: [Roles.Admin],
        key: "entity_profile_administration",
        label: "entityProfiles",
        href: "/administration/deviceProfile"
      },
      {
        id: 3.4,
        roles: [Roles.Admin],
        key: "role_administration",
        label: "userRoles",
        href: "/administration/user-roles"
      },
      {
        id: 3.5,
        roles: [Roles.SuperUser],
        key: "api_administration",
        label: "tnsOnlineApi",
        href: "/administration/tnsonline-api"
      },
      {
        id: 3.6,
        roles: [Roles.SuperUser],
        key: "cam_profile_administration",
        label: "camProfile",
        href: "/administration/camProfile"
      }
    ]
  },
  {
    id: 4,
    roles: [Roles.Admin, Roles.SuperUser, Roles.Basic],
    key: "self_service_tools",
    label: "selfServiceTools",
    href: "/self-service-tools",
    icon: <FontAwesomeIcon icon={faUserGear} />,
    children: [
      {
        id: 4.1,
        roles: [Roles.Basic, Roles.Admin],
        key: "tnslink_provisioning",
        label: "tnsLinkProvisioning",
        href: "/self-service-tools/tns-link-provisioning"
      },
      {
        id: 4.2,
        roles: [Roles.Basic, Roles.Admin],
        key: "swap_devices",
        label: "swapDevices",
        href: "/self-service-tools/swap-devices"
      }
    ]
  },
  {
    id: 5,
    roles: [Roles.Admin, Roles.Basic],
    key: "coin_reports",
    label: "coinReports",
    href: "/coinReports",
    icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
    children: [
      {
        id: 4.1,
        roles: [Roles.Admin, Roles.Basic],
        key: "availability_reports",
        label: "availabilityReports",
        href: "/coinReports/availabilityReports"
      }
    ]
  },
  {
    id: 6,
    roles: [Roles.Admin],
    key: "content_filtering",
    label: "contentFiltering",
    href: "/content-filtering",
    icon: <FontAwesomeIcon icon={faFilter} />,
    children: [
      {
        id: 6.1,
        roles: [Roles.Admin],
        key: "umbrella_destination_lists_administration",
        label: "destinationLists",
        href: "/content-filtering/destination-lists"
      },
      {
        id: 6.2,
        roles: [Roles.Admin],
        key: "umbrella_reports",
        label: "coreReports",
        href: "/content-filtering/core-reports"
      }
    ]
  },
  {
    id: 7,
    roles: [Roles.Admin, Roles.SuperUser, Roles.Basic],
    key: "support",
    label: "support",
    href: "/support",
    icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
    children: [
      {
        id: 7.1,
        roles: [Roles.Basic, Roles.Admin],
        key: "change_management",
        label: "changeManagement",
        href: "/support/change-management"
      },
      {
        id: 7.2,
        roles: [Roles.Admin, Roles.SuperUser],
        key: "incident_management",
        label: "incidentManagement",
        href: "/support/incidentManagement"
      }
    ]
  },
  {
    id: 8,
    roles: [Roles.Basic, Roles.Admin, Roles.SuperUser],
    key: "help",
    label: "help",
    href: "/help",
    icon: <FontAwesomeIcon icon={faCircleQuestion} />,
    children: [
      {
        id: 7.1,
        roles: [Roles.Basic, Roles.Admin, Roles.SuperUser],
        key: "contact_us",
        label: "contactUs",
        href: "/help/contactUs"
      },
      {
        id: 7.2,
        roles: [Roles.Basic, Roles.Admin, Roles.SuperUser],
        key: "user_guide",
        label: "userGuide",
        href: "/help/userGuide"
      }
    ]
  }
];
