import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { TRANSLATION } from "../../../../utils/const/translation";
import { DeviceDetail } from "../../../../models/devices/devices";
import { useTranslation } from "react-i18next";
import { TNSDivider } from "@tns/ui-components";
import { DateHelper } from "@tnso/shared";
import { InformationCardDetail } from "./InformationCardDetail";

interface DeviceInfoCardProps {
  deviceDetail?: DeviceDetail;
}
export const DeviceInfoCard: React.FC<DeviceInfoCardProps> = observer(({ deviceDetail }): JSX.Element => {
  const { t } = useTranslation();
  const userTimezone = useMemo(() => {
    const userData = JSON.parse(localStorage.getItem("user") ?? "{}");
    return userData.timeZone;
  }, []);

  return (
    <>
      <div className="d-flex align-items-end justify-content-between">
        <h6>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.deviceDetailsInfoCardTitle)}</h6>
      </div>
      <TNSDivider type="horizontal" />
      <div className="d-flex flex-column w-100 ps-2 my-4">
        <InformationCardDetail title={t(TRANSLATION.SHARED.TABLE.service)} data={deviceDetail?.service} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICECONFIGURATION.vendor)} data={deviceDetail?.manufacturer} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.deviceType)} data={deviceDetail?.type} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.serialNumber)} data={deviceDetail?.serialNumber} />
        <InformationCardDetail
          title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.deviceDetailsOperationalSince)}
          data={DateHelper.convertTimestampToBrowserCulture(deviceDetail?.operationalSince ?? 0, userTimezone, { year: "numeric", day: "numeric", month: "long" })}
        />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.deviceDetailsMonitoringSystem)} data={deviceDetail?.monitoringSystem} />
        <InformationCardDetail title={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.deviceDetailsSponsor)} data={deviceDetail?.sponsor} />
      </div>
    </>
  );
});
