import i18n from "i18next";
import translationsEn from "./translations/en/English.json";
import translationsEn_us from "./translations/en_us/English_us.json";
import translationsPt from "./translations/pt/Portuguese.json";
import translationsPt_br from "./translations/pt_br/Portuguese_br.json";
import translationsEs from "./translations/es/Spanish.json";
import translationsFr from "./translations/fr/French.json";
import translationsIt from "./translations/it/Italian.json";
import translationsDe from "./translations/de/German.json";
import translationsPh from "./translations/ph/Filipino.json";
import translationsNl from "./translations/nl/Dutch.json";
import validationsEn from "./translations/en/validations.json";
import validationsEn_us from "./translations/en_us/validations.json";
import validationsPt from "./translations/pt/validations.json";
import validationsPt_br from "./translations/pt_br/validations.json";
import validationsEs from "./translations/es/validations.json";
import validationsFr from "./translations/fr/validations.json";
import validationsIt from "./translations/it/validations.json";
import validationsDe from "./translations/de/validations.json";
import validationsPh from "./translations/ph/validations.json";
import validationsNl from "./translations/nl/validations.json";
import { DateHelper } from "@tnso/shared";
const availableLanguages = ["en", "en_us", "es", "pt", "pt_br", "fr", "it", "de", "ph", "nl"];
const instance = i18n.createInstance();
const locale = navigator.language;
DateHelper.initialize(locale);

// eslint-disable-next-line @typescript-eslint/no-floating-promises
instance
  // .use(LanguageDetector) // Detect user language
  // .use(initReactI18next) // Pass the i18n instance to react-i18next
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false
    },
    whitelist: availableLanguages,
    fallbackLng: ["en", "es", "pt"],
    ns: ["translations", "validations"],
    defaultNS: "translations",
    resources: {
      en: {
        translations: translationsEn,
        validations: validationsEn
      },
      en_us: {
        translations: translationsEn_us,
        validations: validationsEn_us
      },
      pt: {
        translations: translationsPt,
        validations: validationsPt
      },
      pt_br: {
        translations: translationsPt_br,
        validations: validationsPt_br
      },
      es: {
        translations: translationsEs,
        validations: validationsEs
      },
      fr: {
        translations: translationsFr,
        validations: validationsFr
      },
      it: {
        translations: translationsIt,
        validations: validationsIt
      },
      de: {
        translations: translationsDe,
        validations: validationsDe
      },
      ph: {
        translations: translationsPh,
        validations: validationsPh
      },
      nl: {
        translations: translationsNl,
        validations: validationsNl
      }
    }
  });

const i18nInstance = instance;
export { i18nInstance };

export default instance;
