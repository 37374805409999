import React, { useCallback } from "react";
import { IResponse, StatusCode } from "@tnso/api-core";
import { i18nInstance } from "../../../../i18n";

import { ExternalApiClientSecret, ExternalApiConfiguration, ExternalApiEnabled, ExternalApiHistory, ExternalApiHistoryParams } from "../../models/tnsonline-api/external-api";
import { ExternalApiService } from "../../services/tnsonline-api/external-api-service";
import { MessageHelper } from "../../helpers/shared/meesage-helper";
import { TRANSLATION } from "../../translations/translation";

export interface ExternalApiContextProviderProps {
  children: React.ReactNode;
}

export interface ExternalApiContextValue {
  getExternalApiConfiguration: () => Promise<ExternalApiConfiguration | undefined>;
  getExternalApiHistory: (filters?: ExternalApiHistoryParams) => Promise<ExternalApiHistory[] | undefined>;
  updateExternalApiConfiguration: (params: ExternalApiEnabled) => Promise<ExternalApiEnabled | IResponse<ExternalApiEnabled> | undefined>;
  updateExternalApiClientSecret: () => Promise<ExternalApiClientSecret | undefined>;
}

export const initialValue: ExternalApiContextValue = {
  getExternalApiConfiguration: (): Promise<ExternalApiConfiguration | undefined> => Promise.resolve(undefined),
  getExternalApiHistory: (): Promise<ExternalApiHistory[] | undefined> => Promise.resolve(undefined),
  updateExternalApiConfiguration: (): Promise<ExternalApiEnabled | IResponse<ExternalApiEnabled> | undefined> => Promise.resolve(undefined),
  updateExternalApiClientSecret: (): Promise<ExternalApiClientSecret | undefined> => Promise.resolve(undefined)
};

export const ExternalApiContext = React.createContext(initialValue);

const ExternalApiContextProvider: React.FC<ExternalApiContextProviderProps> = ({ children }) => {
  const getExternalApiConfiguration = useCallback(async (): Promise<ExternalApiConfiguration | undefined> => {
    try {
      const result = await ExternalApiService.getExternalApiConfiguration();
      return result?.data;
    } catch (error) {
      MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
      console.error(error);
      return;
    }
  }, []);

  const getExternalApiHistory = useCallback(async (filters?: ExternalApiHistoryParams): Promise<ExternalApiHistory[] | undefined> => {
    try {
      const result = await ExternalApiService.getAll(filters);
      return result?.data;
    } catch (error) {
      MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
      console.error(error);
      return;
    }
  }, []);

  const updateExternalApiConfiguration = useCallback(async (params: ExternalApiEnabled): Promise<ExternalApiEnabled | IResponse<ExternalApiEnabled> | undefined> => {
    try {
      const result = await ExternalApiService.updateExternalApiConfiguration(params);
      if (result?.status === StatusCode.NO_CONTENT) {
        return params;
      } else {
        MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
        return;
      }
    } catch (error) {
      MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.upsSomethingWentWrong));
      console.error(error);
      return;
    }
  }, []);

  const updateExternalApiClientSecret = useCallback(async (): Promise<ExternalApiClientSecret | undefined> => {
    try {
      const result = await ExternalApiService.updateExternalApiClientSecret();
      if (result?.data) {
        MessageHelper.successMessage(i18nInstance.t(TRANSLATION.MODAL.ALERT.successClientSecret));
        return result?.data;
      } else {
        MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.MODAL.ALERT.errorClientSecret));
        return;
      }
    } catch (error) {
      console.error(error);
      MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.MODAL.ALERT.errorClientSecret));
      return;
    }
  }, []);

  return (
    <ExternalApiContext.Provider
      value={{
        getExternalApiConfiguration,
        getExternalApiHistory,
        updateExternalApiConfiguration,
        updateExternalApiClientSecret
      }}>
      {children}
    </ExternalApiContext.Provider>
  );
};

export default ExternalApiContextProvider;
