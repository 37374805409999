import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import SSOContextProvider from "./contexts/auth/sso-context";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-toastify/dist/ReactToastify.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "beautiful-react-diagrams/styles.css";
import "moment/dist/locale/pt";
import "moment/dist/locale/es";
import "moment/dist/locale/fr";
import "moment/dist/locale/de";
import "moment/dist/locale/nl";
import "moment/dist/locale/it";

import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { AuthInterceptor } from "./AuthInterceptor";
import { Amplify } from "aws-amplify";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { CookieStorage } from "aws-amplify/utils";
import { ConfigContext, ConfigContextProvider } from "./contexts/configuration/ConfigurationContext";
import { CustomizerContextProvider } from "./contexts/customizer/CustomizerContext";
import { ThemeProvider, Themes } from "@tns/ui-components";
import Loader from "./layouts/loader/Loader";

import i18n from "./i18n";
import NotificationContextProvider from "./contexts/notifications/notifications-context";
import amplifyConfig from "./amplifyconfiguration";
import { LogHelper } from "./helpers/shared/LogHelper";

Amplify.configure(amplifyConfig);
cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage({
    domain: window.location.hostname === "localhost" ? window.location.hostname : ".tnsi.com"
  })
);

// eslint-disable-next-line
ReactDOM.render(
  <React.StrictMode>
    <ConfigContextProvider>
      <ThemeProvider initialTheme={Themes.DARK}>
        <CustomizerContextProvider>
          <ConfigContext.Consumer>
            {({ configuration, loading }): JSX.Element => {
              if (loading) {
                console.log("[MAIN] Loading feature flags");
              }

              if (configuration && !loading) {
                console.info("[MAIN] Feature flags properly loaded");
              }

              if (configuration?.environmentValues) {
                // Initializing logger
                LogHelper.initialize(configuration.environmentValues);
                LogHelper.info("Configuration loaded with following variables: ", configuration.environmentValues);
              }

              return loading ? (
                <Loader />
              ) : (
                <I18nextProvider i18n={i18n}>
                  <BrowserRouter>
                    <NotificationContextProvider>
                      <SSOContextProvider>
                        <AuthInterceptor />
                      </SSOContextProvider>
                    </NotificationContextProvider>
                  </BrowserRouter>
                </I18nextProvider>
              );
            }}
          </ConfigContext.Consumer>
        </CustomizerContextProvider>
      </ThemeProvider>
    </ConfigContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals().catch(console.error);
