import { ApiService, IResponse } from "@tnso/api-core";
import { CellularSignal, Period } from "../../../models/devices/cellular/cellularSignal";

const getApiBaseUrl = (): string => {
  return JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
};

export class WirelessService extends ApiService {
  static async getTnsSignal(tnsDeviceName: string, period: Period): Promise<IResponse<CellularSignal[]> | undefined> {
    return this.get<CellularSignal[]>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/tnssignal/${period}`);
  }

  static async downloadSignalReport(tnsDeviceName: string): Promise<IResponse<string> | undefined> {
    // TODO: add the posibility that models is an string
    return this.get<string>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/signalreport`);
  }

  static async mostRecentSignal(tnsDeviceName: string): Promise<IResponse<CellularSignal> | undefined> {
    return this.get<CellularSignal>(`${getApiBaseUrl()}/devices/${tnsDeviceName}/mostrecentsignal`);
  }
}
