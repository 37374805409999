import { makeAutoObservable } from "mobx";
import { MenuKeys } from "../../../../layouts/sidebars/vertical/Sidebar";
import { VITE_TIME_REFRESH_DATA_MONITORING } from "../../../../config/environments";
import { AuthService } from "../../service/auth/AuthService";

export const enum Language {
  en = "en",
  es = "es",
  pt = "pt",
  fr = "fr",
  de = "de",
  it = "it",
  nl = "nl",
  ph = "ph"
}
export interface IShared {
  menuItemSelected?: MenuKeys | string;
  openMenuKeys: MenuKeys[];
  inlineCollapsed: boolean;
  currentLanguage: string;
  isAutoRefreshActive: boolean;
  isAutoRefreshActiveToActAs: boolean;
  timeToAutoRefresh?: number;
  timeToAutoRefreshToActAs?: number;
  setMenuItemSelected: (menuItem?: MenuKeys) => void;
  setInlineCollapsed: (inlineCollapsed: boolean) => void;
  setLanguage: (language: string) => void;
  setOpenMenuKeys: (openMenuKeys: MenuKeys[]) => void;
  setIsAutoRefreshActive: (isAutoRefreshActive: boolean) => Promise<void>;
  toggleAutoRefresh: () => void;
  getTimeToAutoRefresh: () => number | undefined;
  getIsAutoRefreshActive: () => boolean;
}

class Shared implements IShared {
  menuItemSelected?: MenuKeys | string;
  openMenuKeys: MenuKeys[] = [];
  inlineCollapsed = false;
  currentLanguage = localStorage.getItem("language") ?? Language.en;
  isAutoRefreshActive = localStorage.getItem("autoRefresh") ? localStorage.getItem("autoRefresh") === "true" : false;
  isAutoRefreshActiveToActAs = localStorage.getItem("autoRefreshToActAs") ? localStorage.getItem("autoRefreshToActAs") === "true" : false;
  timeToAutoRefresh: number | undefined = undefined;
  timeToAutoRefreshToActAs: number | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
    this.menuItemSelected = localStorage.getItem("menuItemSelected") as MenuKeys;
    this.openMenuKeys = JSON.parse(localStorage.getItem("openMenuKeys") ?? "[]");
    const timeToRefresh = VITE_TIME_REFRESH_DATA_MONITORING ? Number(VITE_TIME_REFRESH_DATA_MONITORING) : 300000;
    this.timeToAutoRefresh = this.isAutoRefreshActive ? timeToRefresh : undefined;
    this.timeToAutoRefreshToActAs = this.isAutoRefreshActiveToActAs ? timeToRefresh : undefined;
  }

  setLanguage = (language: string): void => {
    localStorage.setItem("language", language);
    this.currentLanguage = language;
  };

  setMenuItemSelected = (menuItem?: MenuKeys): void => {
    if (!menuItem) {
      this.menuItemSelected = undefined;
      this.inlineCollapsed = true;
      localStorage.removeItem("menuItemSelected");
      return;
    }
    localStorage.setItem("menuItemSelected", menuItem.toString());
    this.menuItemSelected = menuItem;
  };

  setIsAutoRefreshActive = async (isAutoRefreshActive: boolean): Promise<void> => {
    const actAs = localStorage.getItem("actAs") ? Boolean(localStorage.getItem("actAs")) : false;
    if (actAs) {
      localStorage.setItem("autoRefreshToActAs", String(isAutoRefreshActive));
      this.isAutoRefreshActiveToActAs = isAutoRefreshActive;
      if (this.isAutoRefreshActiveToActAs) {
        this.timeToAutoRefreshToActAs = VITE_TIME_REFRESH_DATA_MONITORING ? Number(VITE_TIME_REFRESH_DATA_MONITORING) : 5000;
      } else {
        this.timeToAutoRefreshToActAs = undefined;
      }
    } else {
      localStorage.setItem("autoRefresh", String(isAutoRefreshActive));
      await AuthService.setUserPreferences({ AUTO_REFRESH: isAutoRefreshActive });
      this.isAutoRefreshActive = isAutoRefreshActive;
      if (this.isAutoRefreshActive) {
        this.timeToAutoRefresh = VITE_TIME_REFRESH_DATA_MONITORING ? Number(VITE_TIME_REFRESH_DATA_MONITORING) : 5000;
      } else {
        this.timeToAutoRefresh = undefined;
      }
    }
  };

  toggleAutoRefresh = async (): Promise<void> => {
    const actAs = localStorage.getItem("actAs") ? Boolean(localStorage.getItem("actAs")) : false;
    await this.setIsAutoRefreshActive(actAs ? !this.isAutoRefreshActiveToActAs : !this.isAutoRefreshActive);
  };

  getIsAutoRefreshActive = (): boolean => {
    const actAs = localStorage.getItem("actAs") ? Boolean(localStorage.getItem("actAs")) : false;
    if (actAs) {
      return this.isAutoRefreshActiveToActAs;
    } else {
      return this.isAutoRefreshActive;
    }
  };

  getTimeToAutoRefresh = (): number | undefined => {
    const actAs = localStorage.getItem("actAs") ? Boolean(localStorage.getItem("actAs")) : false;
    if (actAs) {
      return this.timeToAutoRefreshToActAs;
    } else {
      return this.timeToAutoRefresh;
    }
  };

  setInlineCollapsed = (inlineCollapsed: boolean): void => {
    this.inlineCollapsed = inlineCollapsed;
  };

  setOpenMenuKeys = (openMenuKeys: MenuKeys[]): void => {
    const lastMenuKeySelected = openMenuKeys[openMenuKeys.length - 1];
    this.openMenuKeys = lastMenuKeySelected ? [lastMenuKeySelected] : [];
    localStorage.setItem("openMenuKeys", JSON.stringify(this.openMenuKeys));
  };
}
const shared = new Shared();
export default shared;
