import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TNSCard, Variants, TNSButton, TNSDivider } from "@tns/ui-components";
import ContactForm from "./ContactForm";
import { store } from "../../../store/StoreMobx";
import { TRANSLATION } from "../../../utils/const/translation";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export enum buttons {
  ADD = "addOnSiteContactInformation",
  EDIT = "edit"
}

export const ContactCard: React.FC = observer(() => {
  const { device } = store;
  const { t } = useTranslation();
  const [opction, setOpction] = useState<buttons | null>(device.note.isContactData ? buttons.ADD : buttons.EDIT);
  const [isEdit, setIsEdit] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const isData = useMemo(() => !_.isEmpty(device.note.dataContact), [device.note.dataContact]);

  useEffect(() => {
    if (isData) {
      setOpction(buttons.EDIT);
    }
  }, [isData, isEdit]);

  const handleClick = useCallback(() => {
    switch (opction) {
      case buttons.ADD:
        setIsEdit(false);
        break;
      case buttons.EDIT:
        setIsEdit(false);
        break;
      default:
        setOpction(null);
        break;
    }
  }, [opction]);

  const contentButton = useMemo(() => {
    return showButton ? (
      <div>
        <TNSButton buttonVariant={Variants.Primary} onClick={handleClick}>
          {t(opction)}
        </TNSButton>
      </div>
    ) : null;
  }, [handleClick, opction, showButton, t]);

  const contentForm = useMemo(() => {
    return isData || !isEdit ? <ContactForm isView={setIsEdit} isEdit={isEdit} setShowButton={setShowButton} /> : null;
  }, [isData, isEdit]);

  return (
    <TNSCard className="d-flex flex-column mt-3">
      <div style={{ minHeight: "17rem" }}>
        <div className="d-md-flex justify-content-between">
          <h4 className="m-0 pt-1">{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CONTACTINFO.onSiteContact)}</h4>
        </div>
        <TNSDivider type="horizontal" />
        {contentForm}
        <div className="d-flex justify-content-end">{contentButton}</div>
      </div>
    </TNSCard>
  );
});

export default ContactCard;
