import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Authorization } from "@tnso/roles-and-permissions";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withAuthorization = (WrapedComponent: any, feature: string): any => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WithAuthorization = (props: any): JSX.Element => {
    const navigate = useNavigate();
    const isAllowed = Authorization.canPerformAction(feature);

    useEffect(() => {
      if (!isAllowed) {
        navigate("/auth/403", { replace: true });
      }
    }, [navigate]);

    return <WrapedComponent {...props} />;
  };

  return WithAuthorization;
};

export default withAuthorization;
