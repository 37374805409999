import { i18nInstance as i18n } from "../../../../i18n";
import { auth, ErrorMessage, refresh, StatusCode, CustomErrorResponse } from "../../helpers/api/RequestHelper";
import { MessageHelper } from "../../helpers/shared/MessageHelper";
import { User } from "../../interfaces/auth/login/login";
import { RefreshResponse, RefreshToken, SignInResponse, ValidateToken, ValidateTokenResponse } from "../../interfaces/auth/response/response";
import { ErrorHelper } from "../../helpers/shared/ErrorHelper";
import { TRANSLATION } from "../../utils/const/translation";
import { fetchAuthSession, fetchUserAttributes, FetchUserAttributesOutput, updateUserAttribute, UpdateUserAttributeInput } from "aws-amplify/auth";
import { Themes } from "@tns/ui-components";

export interface TNSOJwtPayload {
  aud: string;
  auth_time: number;
  "cognito:username": string;
  email: string;
  email_verified: boolean;
  event_id: string;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  jti: string;
  origin_jti: string;
  phone_number: string;
  phone_number_verified: boolean;
  sub: string;
  token_use: string;
  zoneinfo: string;
  "custom:user_data"?: string;
}

export interface CognitoTokenUserData {
  PREFERED_PAGE_SIZE?: number;
  DEFAULT_THEME?: Themes;
  AUTO_REFRESH?: boolean;
}

export class AuthService {
  private static API_URL_BASE = JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/";

  static async signIn(user: User): Promise<User | SignInResponse | undefined> {
    try {
      const response = await auth<User | SignInResponse>(`${AuthService.API_URL_BASE}/authenticate`, user);
      if (response?.status === StatusCode.OK || response?.status === StatusCode.CREATED || response?.status === StatusCode.NO_CONTENT) {
        MessageHelper.successMessage(i18n.t(TRANSLATION.MODAL.ALERT.loginSuccess));
      }
      return response?.data;
    } catch (error) {
      const parsedError = error as CustomErrorResponse;
      // error 400
      if (parsedError.response?.status === StatusCode.BAD_REQUEST) {
        MessageHelper.errorMessage(ErrorHelper.login.badRequest);
      }
      // error 403
      if (parsedError.response?.status === StatusCode.FORBIDDEN) {
        if (parsedError.response.data.error === ErrorMessage.ACCOUNT_LOCKED) {
          window.location.assign("/auth/userLocked");
        } else if (parsedError.response.data.error === ErrorMessage.ACCOUNT_DISABLED) {
          window.location.assign("/auth/userDisabled");
        } else if (parsedError.response.data.error === ErrorMessage.ACCOUNT_EXPIRED) {
          MessageHelper.errorMessage(ErrorHelper.login.accountExpired);
        } else if (parsedError.response.data.error === ErrorMessage.CREDENTIALS_EXPIRED) {
          MessageHelper.errorMessage(ErrorHelper.login.credentialsExpired);
        }
      }
      // error 404
      if (parsedError.response?.status === StatusCode.NOT_FOUND) {
        console.warn(ErrorHelper.login.userNotFound);
        MessageHelper.errorMessage(ErrorHelper.login.userNotFound);
      }
      // error 500
      if (parsedError.response?.status === StatusCode.INTERNAL_SERVER_ERROR) {
        MessageHelper.errorMessage(ErrorHelper.login.internalServerError);
      }
    }
  }

  static async refreshToken(refreshToken: RefreshToken): Promise<RefreshResponse | RefreshToken | undefined> {
    try {
      const response = await refresh<RefreshResponse | RefreshToken>("/authenticate/refreshToken", refreshToken);
      return response?.data;
    } catch (error) {
      const parsedError = error as CustomErrorResponse;
      // error 400
      if (parsedError.response?.status === StatusCode.BAD_REQUEST) {
        MessageHelper.errorMessage(ErrorHelper.login.badRequest);
      }
      // error 401
      if (parsedError.response?.status === StatusCode.UNAUTHORIZED) {
        if (parsedError.response.data.error === ErrorMessage.BAD_REFRESH_TOKEN) {
          MessageHelper.errorMessage(ErrorHelper.login.badRefreshToken);
          localStorage.clear();
          window.location.reload();
        }
      }
      // error 404
      if (parsedError.response?.status === StatusCode.NOT_FOUND) {
        MessageHelper.errorMessage(ErrorHelper.login.userNotFound);
      }
      // error 500
      if (parsedError.response?.status === StatusCode.INTERNAL_SERVER_ERROR) {
        MessageHelper.errorMessage(ErrorHelper.login.internalServerError);
      }
    }
  }

  static async validateToken(baseUrl: string): Promise<ValidateTokenResponse | ValidateToken | undefined> {
    try {
      // TODO This endpoint will be replaced when the backend is ready
      const response = await auth<ValidateToken | ValidateTokenResponse>(`${baseUrl}/authenticate/validatetoken`);
      return response?.data;
    } catch (error) {
      const parsedError = error as CustomErrorResponse;
      // error 400
      if (parsedError.response?.status === StatusCode.BAD_REQUEST) {
        MessageHelper.errorMessage(ErrorHelper.login.badRequest);
      }
      // error 401
      if (parsedError.response?.status === StatusCode.UNAUTHORIZED) {
        if (parsedError.response.data.error === ErrorMessage.BAD_REFRESH_TOKEN) {
          MessageHelper.errorMessage(ErrorHelper.login.badRefreshToken);
          localStorage.clear();
          window.location.reload();
        }
      }
      // error 404
      if (parsedError.response?.status === StatusCode.NOT_FOUND) {
        MessageHelper.errorMessage(ErrorHelper.login.userNotFound);
      }
      // error 500
      if (parsedError.response?.status === StatusCode.INTERNAL_SERVER_ERROR) {
        MessageHelper.errorMessage(ErrorHelper.login.internalServerError);
      }
    }
  }

  /**
   * Updates the user attribute
   * @param attribute attribute to update
   */
  public static async updateUserAttribute(attribute: UpdateUserAttributeInput): Promise<void> {
    try {
      await updateUserAttribute(attribute);
    } catch (error) {
      console.error("Error updating user attribute: ", error);
    }
  }

  /**
   * Fetches the user attributes
   * @returns user attributes
   */
  public static async getUserAttributes(): Promise<FetchUserAttributesOutput | undefined> {
    try {
      const attributes = await fetchUserAttributes();
      return attributes;
    } catch (error) {
      console.error("Error fetching user attributes: ", error);
    }
  }

  public static async getUserToken(): Promise<string | undefined> {
    try {
      const attributes = await fetchAuthSession();
      return attributes.tokens?.accessToken.toString();
    } catch (error) {
      console.error("Error fetching user token: ", error);
    }
  }

  /**
   * Get user information from cognito
   * @returns Cognito's user preferences
   */
  public static async getUserInformation(): Promise<TNSOJwtPayload | undefined> {
    try {
      const response = await fetchAuthSession();
      const payload = response?.tokens?.idToken?.payload ? (response.tokens.idToken.payload as unknown as TNSOJwtPayload) : undefined;

      return payload;
    } catch (error) {
      console.warn("Error fetching user information: ", error);
    }
  }

  /**
   * Set user preferences
   * @param preferences user preferences
   */
  public static async setUserPreferences(preferences: CognitoTokenUserData): Promise<void> {
    const existingPreferences = await AuthService.getUserPreferences();
    const newPreferences = { ...existingPreferences, ...preferences };
    localStorage.setItem("UserPreferences", JSON.stringify(newPreferences));
    await AuthService.updateUserAttribute({
      userAttribute: {
        attributeKey: "custom:user_data",
        value: JSON.stringify(newPreferences)
      }
    });
  }

  /**
   * Get user preferences from cognito
   * @returns user preferences
   */
  public static async getUserPreferences(): Promise<CognitoTokenUserData | undefined> {
    const data = await AuthService.getUserInformation();
    const existingPreferences = localStorage.getItem("UserPreferences");
    const preferences = existingPreferences ? JSON.parse(existingPreferences) : undefined;
    const result: CognitoTokenUserData = { ...preferences };

    if (data) {
      const parsedData = JSON.parse(data["custom:user_data"] ?? "{}");
      if (parsedData.DEFAULT_THEME && parsedData.PREFERED_PAGE_SIZE) {
        result.DEFAULT_THEME = parsedData.DEFAULT_THEME as Themes;
        result.PREFERED_PAGE_SIZE = parsedData.PREFERED_PAGE_SIZE as number;
      }
      if (parsedData.PREFERED_PAGE_SIZE) {
        result.PREFERED_PAGE_SIZE = parsedData.PREFERED_PAGE_SIZE as number;
      }
      if (parsedData.AUTO_REFRESH) {
        result.AUTO_REFRESH = parsedData.AUTO_REFRESH as boolean;
      }
    }
    return result;
  }
}
