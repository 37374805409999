import { Response } from "../../../interfaces/api/api";
import { BaseService } from "../../BaseService";
import { ContactInterface } from "../../../interfaces/devices/note/contact";

export class ContactInfoService extends BaseService {
  static async getAll(
    tnsDeviceName: string
  ): Promise<Response<ContactInterface> | undefined> {
    return this.get<ContactInterface>(`devices/${tnsDeviceName}/contact`);
  }
  static async update(
    contact: ContactInterface,
    tnsDeviceName: string
  ): Promise<Response<ContactInterface> | undefined> {
    return this.patch<ContactInterface>(
      `devices/${tnsDeviceName}/contact`,
      contact
    );
  }
}
