import React, { useMemo, useCallback } from "react";
import { observer } from "mobx-react";
import { store } from "../../../store/StoreMobx";
import { DateHelper } from "@tnso/shared";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../utils/const/translation";
import { TNSSelect } from "@tns/ui-components";

export const PeriodSelected: React.FC = observer(() => {
  const { cellularUsage } = store.device;
  const { deviceName } = useParams();
  const { t } = useTranslation();

  const handleSelected = useCallback(
    async (dateInformation: string): Promise<void> => {
      if (dateInformation !== TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.currentPeriod) {
        const [startDate, endDate, period] = dateInformation.split(",");
        cellularUsage.setIsClosedPeriod(true);
        await cellularUsage.loadBilling({ tnsDeviceName: deviceName, startDate, endDate });
        cellularUsage.current = cellularUsage.billing;
        cellularUsage.setItemSelected(period);
      } else {
        cellularUsage.setItemSelected(dateInformation);
        cellularUsage.setIsClosedPeriod(false);
        if (deviceName) {
          await cellularUsage.loadCurrent(deviceName);
        }
        cellularUsage.current = cellularUsage.currentData;
        cellularUsage.billing = cellularUsage.billingData;
      }
    },
    [t, cellularUsage, deviceName]
  );

  const dateMapper = useCallback((date: number) => {
    return DateHelper.convertTimestampToBrowserCulture(date, store.auth.userInfo?.timeZone, { month: "2-digit", day: "2-digit", year: "numeric" });
  }, []);

  const renderItems = useMemo(() => {
    // delete the first period because is equal that current period
    const data = cellularUsage.billingPeriod?.slice(1);
    const result = data?.map((period) => ({
      value: `${period.startDate},${period.endDate},${dateMapper(Number(period.startDate))} - ${dateMapper(Number(period.endDate))}`,
      label: `${dateMapper(Number(period.startDate))} - ${dateMapper(Number(period.endDate))}`
    }));
    result?.unshift({
      value: TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.currentPeriod,
      label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.currentPeriod)
    });
    return result;
  }, [cellularUsage.billingPeriod]);

  return (
    <TNSSelect
      value={cellularUsage.itemSelected}
      onSelect={(value): Promise<void> => handleSelected(value)}
      placeholder={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.currentPeriod)}
      options={renderItems}
    />
  );
});
