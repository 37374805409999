import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiCore, ErrorMessage, StatusCode } from "@tnso/api-core";
import { i18nInstance as i18n } from "../../i18n";
import { MessageHelper } from "../../app/portal-app/helpers/shared/MessageHelper";
import { TokenHelper } from "../../app/portal-app/helpers/token/TokenHelper";
import { TRANSLATION } from "../../app/portal-app/utils/const/translation";
import { CustomErrorResponse } from "../../app/portal-app/helpers/api/RequestHelper";

export class AxiosHelper {
  static instance = axios.create({
    baseURL: "",
    headers: {
      "Content-type": "application/json"
    },
    timeout: 60000
  });

  static strapiInstance = axios.create({
    baseURL: "",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  });
  /**
   *
   * Creates an instance of axios to handle authentication requests.
   * @type {import("axios").AxiosInstance}
   * @static
   * @readonly
   * @property {string} baseURL - The base URL for the authentication requests.
   * @property {Object} headers - The headers to be included in the authentication requests.
   * @property {string} headers.Content-type - The content type of the HTTP request.
   *
   */
  static instanceAuth = axios.create({
    baseURL: JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("accessToken")
    }
  });
  /**
   *
   * Indicates if the token is currently active.
   * @static
   * @type {boolean}
   *
   */
  static isActiveToken = true;
  static delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));
}
AxiosHelper.instance.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (config: AxiosRequestConfig): Promise<any> => {
    if (config.headers) {
      if (AxiosHelper.isActiveToken) {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
          return;
        }
        config.headers["Authorization"] = accessToken;
        return config;
      } else {
        setTimeout(() => {
          const accessToken = localStorage.getItem("accessToken");
          if (!accessToken) {
            MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.ALERT.sessionExpired));
            return;
          }
          if (config.headers) {
            config.headers["Authorization"] = accessToken;
            return config;
          }
        }, 200);
      }
    } else {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.ALERT.sessionExpired));
      localStorage.clear();
      window.location.reload();
    }
  },
  (error: CustomErrorResponse) => Promise.reject(error)
);

AxiosHelper.instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: CustomErrorResponse) => {
    if (error.response) {
      if (error.response.status === StatusCode.UNAUTHORIZED) {
        if (error.response.data.error === ErrorMessage.BAD_ACCESS_TOKEN) {
          const refreshToken = localStorage.getItem("refreshToken");
          if (refreshToken) {
            AxiosHelper.isActiveToken = false;
            await TokenHelper.refreshTokenValidator(error, refreshToken);
            return;
          }
        }
        if (error.response.data.error === ErrorMessage.BAD_REFRESH_TOKEN) {
          MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.ALERT.somethingWentWrong));
          localStorage.clear();
          window.location.reload();
        }
        MessageHelper.errorMessage(i18n.t(TRANSLATION.MODAL.ALERT.somethingWentWrong));
        localStorage.clear();
        window.location.reload();
      } else if (error.response.status === StatusCode.NOT_FOUND) {
        return Promise.reject(error.response);
      } else {
        if (error.response.status !== StatusCode.FORBIDDEN) {
          return Promise.reject(error.response);
        }
      }
    }
    return;
  }
);

AxiosHelper.strapiInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: CustomErrorResponse) => {
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;

      // Espera 3 segundos antes de reintentar la solicitud
      await AxiosHelper.delay(3000);
      try {
        const newResponse = await AxiosHelper.strapiInstance(originalRequest);
        return newResponse;
      } catch (newError) {
        return Promise.reject(newError);
      }
    }

    return Promise.reject(error);
  }
);

ApiCore.instance = AxiosHelper.instance;
ApiCore.authInstance = AxiosHelper.instanceAuth;
ApiCore.strapiInstance = AxiosHelper.strapiInstance;
