import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react";
import { utils } from "xlsx";
import { RawDataTable } from "./raw-data-table/raw-data-table";
import { SignalQuality } from "./signal-quality";
import { TNSCard, TNSDropdown } from "@tns/ui-components";
import { useParams } from "react-router-dom";
import { ExportContactButton } from "src/app/contact-app/components/export-contact-button";
import { Period } from "src/app/monitoring/models/devices/cellular/cellularSignal";
import { store } from "src/app/monitoring/store/store";
import { ExportXLSLHelper, TypeFile } from "src/shared/helpers/ExportXLSLHelper";
import { TRANSLATION } from "src/app/monitoring/utils/const/translation";
import { MapperHelper } from "src/shared/helpers/MapperHelper";
import { LineChart } from "./signal-line-chart";
import { MenuProps } from "antd/lib";

interface Props {
  interval: Period;
}

enum CallbacksKey {
  firstCallback = "1",
  secondCallback = "2"
}

export const DefaultView: React.FC<Props> = observer(({ interval }) => {
  const { t } = useTranslation();
  const { deviceName } = useParams();
  const { cellularSignal } = store.device;
  const [axis, setAxis] = useState(cellularSignal?.data?.map((item) => item.dateTime) ?? []);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const exportSignalData = useCallback(async () => {
    setIsLoadingExport(true);
    setIsDropdownOpen(false);
    const data = cellularSignal.data;
    if (data) {
      const wb = utils.book_new();
      const signalReport = await cellularSignal.downloadSignalReport(data[0].name);
      if (signalReport) {
        const dataParsed = ExportXLSLHelper.parseCsvString(signalReport);
        ExportXLSLHelper.addSheetToBook(wb, dataParsed, t(TRANSLATION.EXPORT.signalReport));
      }
      setIsLoadingExport(false);
      ExportXLSLHelper.exportToXLSL(wb, `${deviceName}_${t(TRANSLATION.EXPORT.cellularSignalExport)}_${t(TRANSLATION.EXPORT.signalReport)}`, TypeFile.CSV);
    }
  }, [cellularSignal.data]);

  const exportRawData = useCallback(async () => {
    setIsLoadingExport(true);
    setIsDropdownOpen(false);
    const data = cellularSignal.data;
    if (data) {
      const dataMapped = MapperHelper.mapRawDataExport(data, interval ?? Period.Daily);
      const wb = utils.book_new();
      ExportXLSLHelper.addSheetToBook(wb, dataMapped, t(TRANSLATION.EXPORT.dataReportExport));
      setIsLoadingExport(false);
      ExportXLSLHelper.exportToXLSL(wb, `${deviceName}_${t(TRANSLATION.EXPORT.cellularSignalExport)}_${t(TRANSLATION.EXPORT.rawDataReport)}`, TypeFile.CSV);
    }
  }, [cellularSignal.data]);

  useEffect(() => {
    setAxis(cellularSignal?.data?.map((item) => item.dateTime) ?? []);
  }, [cellularSignal?.data]);

  const handleMenuClick: MenuProps["onClick"] = async (e) => {
    if (e.key === CallbacksKey.firstCallback) {
      await exportSignalData();
    } else {
      await exportRawData();
    }
  };

  const menuProps: MenuProps = {
    items: [
      { label: t(TRANSLATION.EXPORT.signalReport), key: CallbacksKey.firstCallback },
      { label: t(TRANSLATION.EXPORT.dataReportExport), key: CallbacksKey.secondCallback }
    ],
    onClick: handleMenuClick
  };

  const handleClickOnExport = useCallback(async (): Promise<void> => {
    setIsDropdownOpen(!isDropdownOpen);
  }, [isDropdownOpen]);

  return cellularSignal.tnsSignal?.current && cellularSignal.rawTNSSignal?.current ? (
    <div className="d-flex flex-column gap-3">
      <TNSCard>
        <Row>
          <Col md="auto" className="align-self-center">
            <h6>
              <b>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.CELLULARSIGNAL.cellularSignalGraph)}</b>
            </h6>
          </Col>
        </Row>
        <Row>
          {cellularSignal.tnsSignal.current.length < 10 ? (
            <>
              <Col md={1} />
              <Col md={11}>{<LineChart series={cellularSignal.tnsSignal.current} categories={axis} interval={interval ?? Period.Daily} />}</Col>
            </>
          ) : (
            <>
              <span className="white-space" />
              <Col>{<LineChart series={cellularSignal.tnsSignal.current} categories={axis} interval={interval ?? Period.Daily} />}</Col>
            </>
          )}
        </Row>
      </TNSCard>
      <TNSCard>
        <Row className="my-3">
          <h6>
            <b>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.celularSignalDetails)}</b>
          </h6>
        </Row>
        <Row>
          <RawDataTable interval={interval ?? Period.Daily} />
        </Row>
        <Row>
          <Col>
            <SignalQuality />
          </Col>
        </Row>
        <div style={{ width: "max-content" }}>
          {cellularSignal.data && cellularSignal.data?.length > 0 && (
            <TNSDropdown menu={menuProps} trigger={["click"]} open={isDropdownOpen}>
              <ExportContactButton handleClickOnExport={handleClickOnExport} loading={isLoadingExport} disabled={isLoadingExport} />
            </TNSDropdown>
          )}
        </div>
      </TNSCard>
    </div>
  ) : null;
});
