import React, { useState } from "react";
import { SwapDeviceHistoryContext, SwapDeviceHistory, SwapHistoryParams, SwapStatusByTimestamp } from "../models/swap-history";
import { SwapDevicesService } from "../services/swap-devices/swap-devices-service";
import { MessageHelper } from "../../administration/helpers/shared/meesage-helper";
import { i18nInstance } from "../../../i18n";
import { TRANSLATION } from "../utils/translations";
import { DeviceService } from "../../../app/monitoring/service/device/device-service";
import { Features } from "../../../layouts/sidebars/sidebardata/SidebarData";

export interface SwapDevicesContextProviderProps {
  children: React.ReactNode;
}

export const initialValue = {
  error: false,

  loadSwapDevicesHistory: (): Promise<SwapDeviceHistory | undefined> => Promise.resolve(undefined),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateStatusByTimestamp: (tnsDeviceName: string, swapTimestamp: string): Promise<SwapStatusByTimestamp | undefined> => Promise.resolve(undefined),
  getModelsBySwapDevices: (): Promise<string[] | undefined> => Promise.resolve(undefined)
};

export const SwapDevicesContext = React.createContext<SwapDeviceHistoryContext>(initialValue);

const SwapDeviceContextProvider: React.FC<SwapDevicesContextProviderProps> = ({ children }) => {
  const [error, setError] = useState(false);

  const loadSwapDevicesHistory = async (params?: SwapHistoryParams): Promise<SwapDeviceHistory | undefined> => {
    try {
      const response = await SwapDevicesService.getAll(params);
      if (response) {
        setError(false);
        return response.data;
      }
    } catch (err) {
      console.log(err);
      setError(true);
    }
  };

  const updateStatusByTimestamp = async (tnsDeviceName: string, swapTimestamp: string): Promise<SwapStatusByTimestamp | undefined> => {
    try {
      const response = await SwapDevicesService.getStatusByTimestamp(tnsDeviceName, swapTimestamp);
      if (response) {
        setError(false);
        if (response?.data && response.data?.status === "Success") {
          MessageHelper.successMessage(i18nInstance.t(TRANSLATION.SWAP.MESSAGE.theSwapHasBeenCompletedSuccessfullyTheDeviceConnectivityStatusMayTakeSomeToUpdate));
        }
        return response.data;
      }
    } catch (err) {
      setError(true);
      console.log(err);
    }
  };

  const getModelsBySwapDevices = async (): Promise<string[] | undefined> => {
    try {
      const response = await DeviceService.getModels(Features.SwapDevices);
      if (response) {
        setError(false);
        return response.data;
      }
    } catch (err) {
      setError(true);
      console.log(err);
    }
  };

  return (
    <SwapDevicesContext.Provider
      value={{
        error,
        loadSwapDevicesHistory,
        updateStatusByTimestamp,
        getModelsBySwapDevices
      }}>
      {children}
    </SwapDevicesContext.Provider>
  );
};

export default SwapDeviceContextProvider;
