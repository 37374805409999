import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../../../utils/const/translation";
import { LVCDataTable } from "../../../../../../components/device/lvc/LVCDataTable";
import { store } from "../../../../../../store/StoreMobx";
import { observer } from "mobx-react";
import { TNSOContainer } from "@tnso/ui-components";
import { Variants, TNSButton, TNSSelect } from "@tns/ui-components";
import { DefaultOptionType } from "antd/es/select";
import { DeviceParams } from "../../../../../../interfaces/devices/devices";
import { useAsyncCall } from "../../../../../../../../../libraries/shared/src/lib/hooks/use-async-call-shared";
import { useParams } from "react-router-dom";
import { paginationSizeChangerContext } from "../../../../../../../../contexts/paginationSizeChanger/paginationSizeChangerContext";

export enum LVCViews {
  DefaultView = "default",
  LegacyView = "legacy"
}

enum ColumnsGroups {
  endPoint1 = "endPoint1",
  endPoint2 = "endPoint2",
  lvcNumberTicket = "lvcTicketNumber",
  status = "status"
}

enum LVCStatus {
  Operational = "Operational"
}

export const LogicalVirtualCircuits: React.FC = observer(() => {
  const { t } = useTranslation();
  const { deviceName } = useParams();
  const [nextViewButtonText, setNextViewButtonText] = useState(LVCViews.LegacyView);
  const { paginationSize } = paginationSizeChangerContext();
  const [params, setParams] = useState<DeviceParams>({ startAtRecord: 0, recordsPerPage: paginationSize, status: LVCStatus.Operational });
  const columnsOptions: DefaultOptionType[] = useMemo(
    () =>
      Object.values(ColumnsGroups).map((item) => ({
        label: t(item),
        value: item
      })),
    [t]
  );

  const loadData = useCallback(async (): Promise<void> => {
    await store.device.lvc.loadData(deviceName, params);
  }, [deviceName, params]);

  const loader = useAsyncCall(loadData, [], store.shared.getTimeToAutoRefresh());

  const [columnsActive, setColumnsActive] = useState<DefaultOptionType[]>([
    { label: ColumnsGroups.endPoint1, value: ColumnsGroups.endPoint1 },
    { label: ColumnsGroups.endPoint2, value: ColumnsGroups.endPoint2 }
  ]);

  useEffect(() => {
    store.device.lvc.setColumnsActive([ColumnsGroups.endPoint1, ColumnsGroups.endPoint2]);
  }, []);

  const handleTranslatedColumns = useCallback(
    (value: DefaultOptionType[]) => {
      return value.map((item) => ({
        label: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.LVCOPTIONS[item.value as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.LVCOPTIONS]),
        value: item.value
      }));
    },
    [t]
  );

  const handleChangeView = useCallback(() => {
    setNextViewButtonText(nextViewButtonText === LVCViews.LegacyView ? LVCViews.DefaultView : LVCViews.LegacyView);
  }, [nextViewButtonText]);

  const totalRecords = useMemo(() => (store.device.lvc.data && store.device.lvc.data.totalRecords ? store.device.lvc.data.totalRecords : 0), [store.device.lvc.data?.totalRecords]);

  const handleSelectColumns = useCallback(
    (value: string[]) => {
      const newColumnsActive = value.map((value) => ({
        label: value,
        value: value
      }));
      setColumnsActive(newColumnsActive);
      store.device.lvc.setColumnsActive(value);
    },
    [t]
  );

  return (
    <TNSOContainer fluid className="p-2">
      <div className="d-flex justify-content-between">
        <Card.Title className={`w-100 text-wrap main-title ${nextViewButtonText === LVCViews.LegacyView ? "" : "mb-5"}`}>
          {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.logicalVirtualConnections)}
        </Card.Title>
        <TNSButton buttonVariant={Variants.Primary} onClick={handleChangeView}>
          {t(
            TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.SWITCHBUTTONVIEW[
              nextViewButtonText as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.SIGNALCARD.SWITCHBUTTONVIEW
            ]
          )}
        </TNSButton>
      </div>
      {nextViewButtonText === LVCViews.LegacyView && (
        <div className="d-flex w-100 align-items-center gap-2 mb-3">
          <span>{t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.columnsAvailable)}: </span>
          <div style={{ minWidth: "60vh" }}>
            <TNSSelect mode="tags" options={columnsOptions} onChange={handleSelectColumns} value={handleTranslatedColumns(columnsActive)} className="w-100" showSearch={false} />
          </div>
        </div>
      )}
      <LVCDataTable
        loading={loader.loading}
        params={params}
        setParams={setParams}
        lvcs={store.device.lvc.lvcs ?? []}
        totalRecords={totalRecords}
        typeOfView={nextViewButtonText}
        options={columnsActive}
      />
    </TNSOContainer>
  );
});
