import React, { useCallback } from "react";
import { TNSCard } from "@tns/ui-components";
import { AssociatedDevicesGroup } from "src/app/portal-app/components/device/device-grouping/associated-devices-group";
import { AvailableGroups } from "src/app/portal-app/components/device/device-grouping/available-groups-component";
import { Col, Row } from "react-bootstrap";
import { GroupSelection } from "src/app/portal-app/components/grouping/context/DeviceGroupingContext";
import { useNavigate } from "react-router-dom";

export function DetailsDeviceGrouping(): JSX.Element {
  const navigate = useNavigate();

  const handleClick = useCallback((key: GroupSelection) => {
    navigate(`/monitoring/devices/device-grouping/details/${key}`);
  }, []);

  return (
    <TNSCard>
      <Row>
        <Col md={2}>
          <AvailableGroups onClick={handleClick} />
        </Col>
        <Col md={10}>
          <AssociatedDevicesGroup />
        </Col>
      </Row>
    </TNSCard>
  );
}
