import React, { useCallback, useContext, useMemo, useState } from "react";
import { Themes, TNSButton, Variants } from "@tns/ui-components";
import * as darkTokens from "@tns/ui-style-dictionary/build/ts/dark-tokens";
import * as lightTokens from "@tns/ui-style-dictionary/build/ts/light-tokens";
import { Features } from "./layouts/sidebars/sidebardata/SidebarData";
import withAuthorization from "./HOC/withAuthorization";
import { useAsyncCall } from "./hooks/useAsyncCallShared";
import { UserGuideData } from "./app/help/models/user-guide";
import { UserGuideService } from "./app/help/service/user-guide-service";
import logoDark from "./app/portal-app/assets/images/tns/tns-logo-white.png";
import logoWhite from "./app/portal-app/assets/images/tns/tns-logo.png";
import image from "./app/portal-app/assets/images/mfa/image-landing.png";
import { useNavigate } from "react-router-dom";
import Loader from "./layouts/loader/Loader";
import parse from "html-react-parser";
import { CustomizerContext } from "./contexts/customizer/CustomizerContext";

interface MFALandingProps {
  showLanding: boolean;
  setShowLanding: React.Dispatch<React.SetStateAction<boolean>>;
  onError: (error: Error) => void;
}

const MFALandingInternal: React.FC<MFALandingProps> = ({ showLanding, setShowLanding, onError }) => {
  const [currentData, setCurrentData] = useState<UserGuideData>();
  const customizer = useContext(CustomizerContext);
  const logo = useMemo(() => (customizer.selectedTheme === Themes.DARK ? logoDark : logoWhite), [customizer.selectedTheme]);
  const navigate = useNavigate();

  const userGuideContent = useMemo(() => {
    if (currentData) {
      return currentData.attributes.Content;
    }
  }, [currentData]);

  const userGuideElement = parse(userGuideContent ?? "<p></p>");

  const loader = useAsyncCall(async () => {
    try {
      const pathId = "/22";
      const response = await UserGuideService.getContent(pathId);
      if (response) {
        setCurrentData(response.data?.data);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("[MFALanding] Error getting MFA preferences activating landing as fallback: ", error);
      setShowLanding(false);
      onError(error);
    }
  }, [onError, setShowLanding]);

  const handleRedirect = useCallback((): void => {
    setShowLanding(false);
    navigate("/user/profile");
  }, [navigate, setShowLanding]);

  if (loader.loading) {
    return <Loader />;
  }

  if (loader.completed && showLanding) {
    return (
      <div
        className="mfa-landing-container"
        style={{
          backgroundColor: customizer.selectedTheme === Themes.DARK ? darkTokens.TnsBreakpointColorBackground : lightTokens.TnsBreakpointColorBackground,
          color: customizer.selectedTheme === Themes.DARK ? darkTokens.TnsTextTitleColorPrimary : lightTokens.TnsTextTitleColorPrimary
        }}>
        <div className="mfa-landing">
          <div className="d-flex">
            <div className="content ps-4 pt-4">
              <div className="d-flex flex-column gap-2 p-1">
                <img src={logo} alt="logo" className="logo" width={110} />
                <div className="d-flex">
                  <div className="content mt-4">{userGuideElement}</div>
                  <img src={image} alt="image" style={{ height: "80%" }} />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2 ps-4 mt-2">
            <TNSButton buttonVariant={Variants.Secondary} onClick={(): void => setShowLanding(false)}>
              Close
            </TNSButton>
            <TNSButton buttonVariant={Variants.Primary} onClick={(): void => handleRedirect()}>
              Go to My Account
            </TNSButton>
          </div>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

const MFALanding = withAuthorization(MFALandingInternal, Features.UserGuide);

export default MFALanding;
