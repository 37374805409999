import { TNSOAlert } from "@tnso/ui-components";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTableConfig } from "../../../../../../components/device/firewall-rules/table/use-table-config";
import { useAsyncCall } from "../../../../../../../../../libraries/shared/src/lib/hooks/use-async-call-shared";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../../../../../utils/const/translation";
import { ExportXLSLHelper, TypeFile } from "../../../../../../../../shared/helpers/ExportXLSLHelper";
import { useParams } from "react-router-dom";
import { utils } from "xlsx";
import _ from "lodash";
import { MapperHelper } from "../../../../../../helpers/shared/MapperHelper";
import { FirewallRulesContext } from "../../../../../../context/firawall-rules/firewall-rules-context";
import { TranslationHelper } from "../../../../../../../../shared/helpers/TranslationHelper";
import { store } from "src/app/portal-app/store/StoreMobx";
import { observer } from "mobx-react";
import { TNSTable } from "@tns/ui-components";

export const FirewallRules: React.FC = observer(() => {
  const { t } = useTranslation();
  const [isVirtualAccessDevice, setIsVirtualAccessDevice] = useState<boolean | undefined>(true);
  const [isDeviceUp, setIsDeviceUp] = useState<boolean | undefined>(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const { deviceName } = useParams();
  const context = useContext(FirewallRulesContext);
  const { columns, firewallRulesData, firewallRulesDataPaginated, filteredFirewallRulesData, currentPage, handleChangePage, filters, handleLoadData, skeletonColumns, handleSort } =
    useTableConfig();

  const getIsCompatible = useCallback(async (): Promise<void> => {
    if (deviceName) {
      const response = await context.getIsCompatible(deviceName);
      if (response) {
        setIsVirtualAccessDevice(response.isDeviceModelCompatible);
        setIsDeviceUp(response.isDeviceUp);
      }
    }
  }, [deviceName]);

  const handleExportData = useCallback(async () => {
    if (deviceName) {
      setIsLoadingExport(true);
      if (filteredFirewallRulesData && !_.isEmpty(filteredFirewallRulesData)) {
        const wb = utils.book_new();
        ExportXLSLHelper.addSheetToBook(wb, MapperHelper.mapToExportFirewallRules(filteredFirewallRulesData), `${deviceName}_FirewallRules`);
        ExportXLSLHelper.exportToXLSL(wb, `${deviceName}_FirewallRules`, TypeFile.CSV);
        setIsLoadingExport(false);
      }
    }
  }, [t, filteredFirewallRulesData, deviceName, setIsLoadingExport]);

  const loadData = useCallback(async (): Promise<void> => {
    await handleLoadData(filters);
    await getIsCompatible();
    await store.device.detail.loadData(deviceName);
  }, [deviceName]);

  const loader = useAsyncCall(loadData, [], store.shared.getTimeToAutoRefresh());

  const grid = useMemo(() => {
    return (
      <TNSTable
        currentPage={currentPage}
        dataSource={!isVirtualAccessDevice ? [] : firewallRulesDataPaginated}
        columns={
          loader.loading
            ? TranslationHelper.columnsTranslation(skeletonColumns as unknown as ColumnsType[])
            : TranslationHelper.columnsTranslation(columns.firewallRules as unknown as ColumnsType[])
        }
        totalRecords={filteredFirewallRulesData.length}
        onChange={handleSort}
        showPagination={firewallRulesData.length > 10}
        handleGoToPage={handleChangePage}
        isLoading={loader.loading}
        recordsTranslated={[t(TRANSLATION.SHARED.TABLE.records), t(TRANSLATION.SHARED.TABLE.of)]}
        scroll={{ x: "max-content" }}
        onExport={handleExportData}
        exportLabel={t("export")}
        isLoadingExport={isLoadingExport}
        {...(!isVirtualAccessDevice ? { emptyMessage: t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.FIREWALLRULES.deviceNotCompatibleWithThisFunctionality) } : {})}
      />
    );
  }, [t, currentPage, loader.loading, firewallRulesDataPaginated, firewallRulesData, handleChangePage, filteredFirewallRulesData, isVirtualAccessDevice, isDeviceUp]);

  return (
    <div>
      {(!isVirtualAccessDevice || (isVirtualAccessDevice && !isDeviceUp)) && (
        <TNSOAlert className="mt-4 mb-4" type="info" message={t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.FIREWALLRULES.enableFunctionalityInfo)} showIcon />
      )}
      {grid}
    </div>
  );
});
