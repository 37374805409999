import { Response } from "../../../interfaces/api/api";
import { CellularParams, CellularUsage, CellularUsageBilling, CellularUsageBillingPeriod, Wireless } from "../../../interfaces/devices/cellular/cellularUsage";
import { BaseService } from "../../BaseService";

export class CellularUsageService extends BaseService {
  static async getHistorical(tnsDeviceName: string): Promise<Response<CellularUsage[]> | undefined> {
    return this.get<CellularUsage[]>(`devices/${tnsDeviceName}/usage/historical`);
  }

  static async getBillingPeriod(tnsDeviceName: string): Promise<Response<CellularUsageBillingPeriod[]> | undefined> {
    return this.get<CellularUsageBillingPeriod[]>(`devices/${tnsDeviceName}/usage/billingperiods`);
  }

  static async getBilling(params?: CellularParams): Promise<Response<CellularUsageBilling> | undefined> {
    const tnsDeviceName = params?.tnsDeviceName;
    delete params?.tnsDeviceName;
    return this.get<CellularUsageBilling>(`devices/${tnsDeviceName}/usage/billing`, params);
  }

  static async getCurrent(tnsDeviceName: string): Promise<Response<CellularUsage> | undefined> {
    return this.get<CellularUsage>(`devices/${tnsDeviceName}/usage/current`);
  }

  static async getWireless(tnsDeviceName: string): Promise<Response<Wireless> | undefined> {
    return this.get<Wireless>(`devices/${tnsDeviceName}/wireless`);
  }
}
