import React, { ReactNode, useEffect, useMemo } from "react";
import { IDeviceCPUUsage } from "src/app/monitoring/models/devices/dhi/dhi";
import { DeviceMetricsHelper } from "../../../../../helpers/dhi/device-metrics-helper";
import { MetricCard } from "./metric-card";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "src/app/monitoring/utils/const/translation";
import { TNSOTooltip, TNSOBadge } from "@tnso/ui-components";
import { TNSProgress } from "@tns/ui-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "primereact/carousel";
import "../device-health-information.scss";

export interface CPUUsageCardProps {
  cpuUsage?: IDeviceCPUUsage[];
}
export const CPUUsageCard: React.FC<CPUUsageCardProps> = ({ cpuUsage }) => {
  const { t } = useTranslation();
  const cpuCores = DeviceMetricsHelper.groupingCores(cpuUsage);

  const cpusTemplate = (cpuUsage: IDeviceCPUUsage[]): ReactNode => {
    return (
      <div className="w-100">
        {cpuUsage?.length > 0 ? (
          cpuUsage.map((cpuUsage: IDeviceCPUUsage) => {
            return cpuUsage.usage > 0 ? (
              <div key={cpuUsage.name} className="d-flex align-items-center justify-content-between">
                <small># Core {cpuUsage.key}</small>
                <div className="progress-bar">
                  <TNSProgress key={cpuUsage.name} percent={cpuUsage.usage} strokeColor={DeviceMetricsHelper.getColorCPUUsage(cpuUsage.usage)} />
                </div>
              </div>
            ) : (
              <span>N/A</span>
            );
          })
        ) : (
          <span>N/A</span>
        )}
      </div>
    );
  };

  const tooltipContent = useMemo((): ReactNode => {
    return (
      <div className="d-flex flex-column">
        <small className="d-flex gap-2">
          <TNSOBadge color="#42C53F" /> {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.normal)}:{" <75%"}
        </small>
        <small className="d-flex gap-2">
          <TNSOBadge color="#EB9646" /> {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.warning)}:{" >75% / <90%"}
        </small>
        <small className="d-flex gap-2">
          <TNSOBadge color="#FF2D2E" /> {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.critical)}:{" >=90%"}
        </small>
      </div>
    );
  }, [t]);

  useEffect(() => {
    const carouselIndicators = document.querySelector(".p-carousel-indicators");
    if (carouselIndicators) {
      const listItems = carouselIndicators.querySelectorAll("li");
      if (listItems.length === 1) {
        carouselIndicators.classList.add("single-item");
      }
    }
  }, []);

  return (
    <MetricCard
      header={
        <span className="d-flex align-items-center gap-1">
          {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.cpuUsage)}
          <TNSOTooltip title={tooltipContent}>
            <FontAwesomeIcon icon={faCircleInfo} />
          </TNSOTooltip>
        </span>
      }
      data={
        cpuCores.length ? (
          <Carousel value={cpuCores} itemTemplate={cpusTemplate} />
        ) : (
          <h6>
            <span>N/A</span>
          </h6>
        )
      }
    />
  );
};
