import React, { useCallback, useMemo } from "react";
import { DevicesGETApi, InlineResponse20021 as SoftwareResponse } from "@tnso/api-sdk";
import { useApiController } from "../../../../shared/hooks/use-api-controller";
import { MessageHelper } from "@tnso/shared";
import { i18nInstance } from "src/i18n";
import { TRANSLATION } from "../../utils/const/translation";

export interface SoftwareTabContextValueProps {
  children: React.ReactNode;
}

export { SoftwareResponse };

export interface SoftwareTabContextValue {
  getSoftwares: (deviceName: string) => Promise<SoftwareResponse[] | undefined>;
}

export const initialValue: SoftwareTabContextValue = {
  getSoftwares: (): Promise<SoftwareResponse[] | undefined> => Promise.resolve(undefined)
};

export const SoftwareTabContext = React.createContext(initialValue);

const SoftwareTabContextProvider: React.FC<SoftwareTabContextValueProps> = ({ children }) => {
  const apiGetController = useApiController(DevicesGETApi);

  const getSoftwares = useCallback(async (deviceName: string): Promise<SoftwareResponse[] | undefined> => {
    try {
      const result = await apiGetController.devicesTnsDeviceNameSoftwaresGet(deviceName);
      return result?.data;
    } catch (error) {
      MessageHelper.errorMessage(i18nInstance.t(TRANSLATION.ERROR.somethingWentWrongTryAgainLater));
      console.error(error);
    }
  }, []);

  const value = useMemo(() => ({ getSoftwares }), [getSoftwares]);

  return <SoftwareTabContext.Provider value={value}>{children}</SoftwareTabContext.Provider>;
};

export default SoftwareTabContextProvider;
