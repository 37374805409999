import { AxiosResponse } from "axios";
import { API_URL_BASE } from "../../../../config/environments";
import { ErrorMessage, StatusCode, encodeParams, CustomErrorResponse } from "../../helpers/api/RequestHelper";
import { ActAsPayload } from "../../interfaces/auth/login/login";
import { SignInResponse } from "../../interfaces/auth/response/response";
import { RolesByUserList } from "../../interfaces/auth/roleAndPermission/role";
import { QueryParams } from "../../interfaces/shared/queryParams";
import {
  AvailableByEmail,
  AvailableByUsername,
  User,
  UserAlternative,
  UserAssociatedParams,
  UserResponse,
  UserAssociatedResponse,
  UserOnBoarded,
  UserMFAStatus
} from "../../interfaces/users/user";
import { BaseService } from "../BaseService";
import { sendPost, sendPatch } from "../../helpers/api/RequestHelper";
import { Response } from "../../interfaces/api/api";
import { ErrorHelper } from "../../helpers/shared/ErrorHelper";
import { MessageHelper } from "../../helpers/shared/MessageHelper";
import { TRANSLATION } from "../../utils/const/translation";
import { i18nInstance as i18n } from "../../../../i18n";
import { IResponse } from "@tnso/api-core";

export class UserService extends BaseService {
  static async getAll(params?: QueryParams): Promise<Response<UserResponse> | undefined> {
    const urlSearchParams = encodeParams<QueryParams>(params as QueryParams);
    return this.get<UserResponse>("users", urlSearchParams);
  }

  static async getByUsername(username: string): Promise<Response<UserResponse> | undefined> {
    const urlSearchParams = encodeParams<QueryParams>({ username });
    return this.get<UserResponse>("users", urlSearchParams);
  }

  static async getByRoleName(role: string, params?: UserAssociatedParams): Promise<Response<UserAssociatedResponse> | undefined> {
    const urlSearchParams = encodeParams<UserAssociatedParams>(params as UserAssociatedParams);
    return this.get<UserAssociatedResponse>(`users/${role}/associated`, urlSearchParams);
  }

  static async isAvailableByEmail(email: string): Promise<Response<AvailableByEmail> | undefined> {
    return this.get<AvailableByEmail>(`users/email/${encodeURIComponent(email)}/available`);
  }

  static async isAvailableByUsername(username: string): Promise<Response<AvailableByUsername> | undefined> {
    return this.get<AvailableByUsername>(`users/username/${encodeURIComponent(username)}/available`);
  }

  static async userMFAStatus(email: string): Promise<Response<UserMFAStatus> | undefined> {
    return this.get<UserMFAStatus>(`users/${encodeURIComponent(email)}/mfa/status`);
  }

  static async createByRole(role: RolesByUserList, user: User): Promise<Response | undefined> {
    return this.post<User>(`users/${role}`, user);
  }

  static async onBoardedByRole(role: string, username: string, body?: UserOnBoarded): Promise<Response | undefined> {
    return this.post<UserOnBoarded>(`users/onboard/${role}/${encodeURIComponent(username)}`, body);
  }
  // UserALternative must be removed when the new logic for credentialsExpires is done
  static async updateByRole(role: RolesByUserList, user: User | UserAlternative, username: string): Promise<Response | undefined> {
    return this.patch<User | UserAlternative>(`users/${role}/${username}`, user);
  }

  static async forceChangePassword(username: string): Promise<Response | undefined> {
    return this.patch<User | UserAlternative>(`users/${username}/forcechangepwd`);
  }

  static async updateOwnProfile(user: UserAlternative): Promise<Response | undefined> {
    return this.patch<UserAlternative>("users/profile", user);
  }

  static async disableUserMFA(email: string): Promise<Response | undefined> {
    return this.patch<string>(`users/${email}/mfa/disable`);
  }

  static async actAs(username: ActAsPayload): Promise<Response<ActAsPayload | SignInResponse> | undefined> {
    return this.post<SignInResponse | ActAsPayload>("users/actas", username);
  }

  static async forgotPassword(email: string): Promise<AxiosResponse | Record<string, string> | undefined> {
    try {
      const response = await sendPost<Record<string, string>>(`${API_URL_BASE}/users/password/forgot`, { email });
      return response;
    } catch (error) {
      MessageHelper.errorMessage(i18n.t(TRANSLATION.ERROR.upsSomethingWentWrong));
    }
  }

  static async resetPassword(password: string, guid: string): Promise<string | AxiosResponse | void> {
    try {
      const response = await sendPatch<AxiosResponse | Record<string, string>>(`${API_URL_BASE}/users/password/${guid}`, { password });
      return response;
    } catch (error) {
      const parsedError = error as CustomErrorResponse;
      if (parsedError.response?.status === StatusCode.BAD_REQUEST) {
        if (parsedError.response?.data?.error === ErrorMessage.RECENT_PASSWORDS_MATCH) {
          MessageHelper.errorMessage(ErrorHelper.password.recentPasswordMatch);
        }
        if (parsedError.response?.data?.error === ErrorMessage.PASSWORD_COMPLEXITY_DOES_NOT_MATCH) {
          MessageHelper.errorMessage(ErrorHelper.password.passwordComplexity);
        }
        if (parsedError.response?.data?.error === ErrorMessage.PASSWORD_CONTAINS_USERNAME) {
          MessageHelper.errorMessage(ErrorHelper.password.passwordContainsUsername);
        }
        if (parsedError.response?.data?.error === ErrorMessage.PASSWORD_MIN_LENGTH_DOES_NOT_MATCH) {
          MessageHelper.errorMessage(ErrorHelper.password.passwordMinLength);
        }
        if (parsedError.response?.data?.error === ErrorMessage.BAD_RESET_PASSWORD_TOKEN) {
          MessageHelper.errorMessage(ErrorHelper.password.badResetPasswordToken);
        }
      } else {
        MessageHelper.errorMessage(i18n.t(TRANSLATION.ERROR.errorResettingPassword));
      }
    }
  }

  /**
   * To create a new TNS Customer Account Manager user account
   * @param user
   * @returns
   */
  static async createCamUser(user: User): Promise<IResponse<User> | undefined> {
    return this.post<User>("users/cam", user);
  }

  /**
   * To update a TNS Customer Account Manager user account or/and to update role to Customer Account Manager
   * @param user
   * @param userName
   * @returns
   */
  static async editCamUser(user: User, userName: string): Promise<IResponse<User> | undefined> {
    return this.patch(`users/cam/${userName}`, user);
  }

  /**
   * To fetch the list of Customer Account Manager Profiles to show in the dropdown
   * @returns string[]
   */
  static async getAllCamUsersName(): Promise<IResponse<string[]> | undefined> {
    return this.get<string[]>("camprofiles/names");
  }

  /**
   * To onboard a Customer Account Manager by username
   * @param username
   * @returns
   */
  static async onBoardCam(username: string): Promise<IResponse<object> | undefined> {
    return this.post(`users/onboard/cam/${username}`);
  }
}
