import { CognitoTokenUserData } from "src/app/portal-app/service/auth/AuthService";

export class UserPrefenrencesHelper {
  /**
   * Retrieves and parses user preferences from localStorage
   * @returns Parsed CognitoTokenUserData or undefined if not found (converted to strings)
   */
  static getUserPreferences(): { [K in keyof CognitoTokenUserData]?: string } | undefined {
    const storedPreferences = localStorage.getItem("UserPreferences");

    if (!storedPreferences) {
      return undefined;
    }

    try {
      const preferences = JSON.parse(storedPreferences) as CognitoTokenUserData;
      // Convert all values to strings
      const stringPreferences: { [K in keyof CognitoTokenUserData]?: string } = {};

      Object.keys(preferences).forEach((key) => {
        const typedKey = key as keyof CognitoTokenUserData;
        if (preferences[typedKey] !== undefined) {
          stringPreferences[typedKey] = String(preferences[typedKey]);
        }
      });

      return stringPreferences;
    } catch (error) {
      console.error("Error parsing user preferences from localStorage", error);
      return undefined;
    }
  }

  /**
   * Returns specific user preferences based on the provided keys, all as strings
   * @param keys Array of keys to retrieve
   * @returns An object containing the key-value pairs of the specified keys (all values as strings)
   */
  static getParsedPreferences<K extends keyof CognitoTokenUserData>(keys: K[]): Partial<{ [K in keyof CognitoTokenUserData]: string }> {
    const preferences = this.getUserPreferences();

    if (!preferences) {
      return {};
    }

    const parsedPreferences: Partial<{ [K in keyof CognitoTokenUserData]: string }> = {};

    keys.forEach((key) => {
      if (preferences[key] !== undefined) {
        parsedPreferences[key] = preferences[key];
      }
    });

    return parsedPreferences;
  }
}
