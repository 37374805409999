import { makeAutoObservable } from "mobx";

export interface ICustomizer {
  isMiniSidebar: boolean;
  isMobileSidebar: boolean;
  isTopbarFixed: boolean;
  isRTL: boolean;
  isTreeMenuMobile: boolean;
  isBannerVisible: boolean;

  ToggleMiniSidebar: () => void;
  ToggleMobileSidebar: () => void;
}

class Customizer implements ICustomizer {
  isMiniSidebar = false;
  isMobileSidebar = false;
  isTopbarFixed = true;
  isRTL = false;
  isTreeMenuMobile = false;
  isBannerVisible = false;

  constructor() {
    makeAutoObservable(this);
  }

  ToggleMiniSidebar = (): void => {
    this.isMiniSidebar = !this.isMiniSidebar;
  };

  ToggleMobileSidebar = (): void => {
    this.isMobileSidebar = !this.isMobileSidebar;
  };

  IsBannerVisible = (newValue: boolean): void => {
    this.isBannerVisible = newValue;
  };
}

const customizer = new Customizer();

export default customizer;
