import React, { useContext } from "react";
import { TroubleshootingContext } from "../../../../../../components/device/troubleshooting/context/troubleshooting-context";
import { TRANSLATION } from "../../../../../../utils/const/translation";
import { useTranslation } from "react-i18next";
import { TNSButton, Variants } from "@tns/ui-components";

export const TroubleshootingFiltersButtons: React.FC = () => {
  const { t } = useTranslation();
  const { runEnabled, stopEnabled, runningCommand, runCommand, stopCommand, stopVisible } = useContext(TroubleshootingContext);

  return (
    <>
      <TNSButton buttonVariant={Variants.Primary} disabled={!runEnabled} onClick={runCommand} loading={runningCommand}>
        {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.run)}
      </TNSButton>
      {stopVisible && (
        <TNSButton buttonVariant={Variants.Secondary} disabled={!stopEnabled} onClick={stopCommand}>
          {t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.stop)}
        </TNSButton>
      )}
    </>
  );
};
