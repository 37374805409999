import { AWS_PARAMS_LAMBDA_URL } from "../../../../config/environments";

export class ConfigurationService {
  static async getParams(token: string): Promise<Response | undefined> {
    try {
      const URL = window.location.hostname !== "localhost" ? AWS_PARAMS_LAMBDA_URL : "/Development/param-store-function";
      const response = await fetch(URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}
