import { Response } from "../../interfaces/api/api";
import { AvailabilityReportResponse, MembersAvailabilityReportResponse } from "../../interfaces/coin/coinReports";

import { BaseService } from "../BaseService";

export class CoinReportsService extends BaseService {
  static async getAvailabilityReportData(): Promise<Response<AvailabilityReportResponse> | undefined> {
    return this.get<AvailabilityReportResponse>("coin/availability");
  }
  static async getMembersAvailabilityReportData({year, acna}: {year: number, acna: string}): Promise<Response<MembersAvailabilityReportResponse> | undefined> {
    return this.get<MembersAvailabilityReportResponse>(`coin/member/${acna}/availability/${year}`);
  }
  static async getYearReportFilter(acna: string): Promise<Response<number[]> | undefined> {
    return this.get<number[]>(`coin/member/${acna}/years`);
  }

  static async getAcnaFilter(): Promise<Response<string[]> | undefined> {
    return this.get<string[]>("coin/member/acnas");
  }
}
