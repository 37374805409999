export class DeviceImageHelper {
  static async getImage(name: string): Promise<string | undefined> {
    try {
      // eslint-disable-next-line
      const imagesMap: Record<string, () => Promise<any>> = {
        cisco_819: () => import("../../assets/images/devices/CISCO_819.png"),
        cisco_819g: () => import("../../assets/images/devices/CISCO_819G.png"),
        cisco_881: () => import("../../assets/images/devices/Cisco_881.png"),
        cisco_887va: () => import("../../assets/images/devices/Cisco_887VA.png"),
        "cisco_c819g-lte-la-k9": () => import("../../assets/images/devices/Cisco_C819G-LTE-LA-K9.png"),
        "cisco_c881-k9": () => import("../../assets/images/devices/Cisco_C881-K9.png"),
        "cisco_c1111-4plteea": () => import("../../assets/images/devices/Cisco_C1111-4PLTEEA.png"),
        "cisco_cisco887va-k9": () => import("../../assets/images/devices/Cisco_CISCO887VA-K9.png"),
        "digi_wr21-l1": () => import("../../assets/images/devices/Digi WR21-L1.png"),
        "digi_wr21-0": () => import("../../assets/images/devices/Digi_WR21-0.png"),
        "digi_wr21-l": () => import("../../assets/images/devices/Digi_WR21-L.png"),
        "digi_wr21-u": () => import("../../assets/images/devices/Digi_WR21-U.png"),
        digi_wr44: () => import("../../assets/images/devices/Digi_WR44.png"),
        digi_wr44v2: () => import("../../assets/images/devices/Digi_WR44v2.png"),
        fortinet_40f: () => import("../../assets/images/devices/Fortinet_40F.png"),
        "fortinet_fortigate-30e-3g4g-gbl": () => import("../../assets/images/devices/Fortinet_FortiGate-30E-3G4G-GBL.png"),
        virtual_virtual: () => import("../../assets/images/devices/Virtual_Virtual.png"),
        "virtualaccess_gw1042m-x-qfr": () => import("../../assets/images/devices/VirtualAccess_GW1042M-X-QFR.png"),
        "virtualaccess_gw1042m-x-rfr": () => import("../../assets/images/devices/VirtualAccess_GW1042M-X-RFR.png"),
        "virtualaccess_gw1042m-x-rfx": () => import("../../assets/images/devices/VirtualAccess_GW1042M-X-RFX.png"),
        "virtualaccess_gw1152-qfl": () => import("../../assets/images/devices/VirtualAccess_GW1152-QFL.png"),
        "virtualaccess_gw6650v-t5g-dcn0916-qfr": () => import("../../assets/images/devices/VirtualAccess_GW6650V-T5G-DCn0916-QFR.png")
      };

      // Intentar importar la imagen si está en el mapa
      if (imagesMap[name.toLowerCase()]) {
        const module = await imagesMap[name.toLowerCase()]();
        return module.default; // Obtenemos la URL de la imagen
      } else {
        return undefined;
      }
    } catch (error) {
      console.error("Error al cargar la imagen:", error);
      return undefined;
    }
  }
}
