import { useMemo, useRef } from "react";
import { AxiosHelper } from "../../config/api/AxiosInstance";

/**
 *
 * @description IMPORTANT: Do not use the return value of this hook in a useEffect dependency array, it will reload on every token refresh.
 *
 */
// eslint-disable-next-line
export interface Type<T = any> extends Function {
  // eslint-disable-next-line
  new (...args: any[]): T;
}

export enum InstanceType {
  Default = "default",
  Auth = "auth",
  Strapi = "strapi",
  DefaultWithBase = "defaultWithBase"
}

export const useApiController = <T>(Klass: Type<T>, instanceType: InstanceType = InstanceType.DefaultWithBase): T => {
  const includeBaseUrl = instanceType !== InstanceType.DefaultWithBase;
  const apiControllerClass = useRef(Klass);
  let instance = AxiosHelper.instance;
  instance.defaults.baseURL = JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
  
  if (instanceType === InstanceType.Auth) {
    instance = AxiosHelper.instanceAuth;
    instance.defaults.baseURL = JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
  }
  if (instanceType === InstanceType.DefaultWithBase) {
    instance = AxiosHelper.instance;
    instance.defaults.baseURL = JSON.parse(localStorage.getItem("ApiConfiguration") ?? "{}")?.API_URL_BASE ?? "/v1/portalapi";
  } else if (instanceType === InstanceType.Strapi) {
    instance = AxiosHelper.strapiInstance;
  }

  return useMemo(() => {
    return new apiControllerClass.current(
      {
        baseOptions: {
          headers: {
            "Content-type": "application/json"
          }
        }
      },
      includeBaseUrl ? instance.defaults.baseURL : "",
      instance
    );
  }, [includeBaseUrl, instance]);
};
