import { Response } from "../../interfaces/api/api";
import { DevicesByGroupName, GroupForm, GroupParams, GroupResponse } from "../../interfaces/devices/group/group";
import { QueryParams } from "../../interfaces/shared/queryParams";
import { BaseService } from "../BaseService";
import { StatusCode, encodeParams } from "../../helpers/api/RequestHelper";
import { AllGroupsResponse, AssociatedDevicesInGroupResponse, IsAvailableGroupResponse } from "../../interfaces/devices/group/available-groups";
import { FilterType } from "src/app/administration/models/device-profile.model";

export type GroupSearchType = "countries" | "acnas";

export class GroupService extends BaseService {
  static async getAll(params?: QueryParams): Promise<Response<GroupResponse> | undefined> {
    const urlSearchParams = encodeParams<QueryParams>(params ?? {});
    return this.get<GroupResponse>("devices/groups/associated", urlSearchParams);
  }

  /**
   * Get list of available devices by group based on search type
   * @param type required - The type of group to get available devices.
   * @param groupName required - The name of the group to get available devices.
   * @param state optional - The state of the devices to get. If not provided, all devices are returned.
   * @param recordsPerPage optional - The number of records to return per page. Default is 100.
   * @param startAtRecord optional - The record number to start at. Default is 0.
   * @returns available devices response
   */
  static async getAvailableDevicesGroup(groupName: string, params?: GroupParams): Promise<Response<AssociatedDevicesInGroupResponse> | undefined> {
    const urlSearchParams = encodeParams<QueryParams>(params ?? {});
    return this.get<AssociatedDevicesInGroupResponse>(`devices/groups/${encodeURIComponent(groupName)}/available`, urlSearchParams);
  }

  static async getAvailableDevicesGroupByFilter(groupName: string, filter: FilterType, params?: GroupParams): Promise<Response<AssociatedDevicesInGroupResponse> | undefined> {
    const urlSearchParams = encodeParams<QueryParams>(params ?? {});
    return this.get<AssociatedDevicesInGroupResponse>(`devices/${filter}/groups/${encodeURIComponent(groupName)}/available`, urlSearchParams);
  }

  static async getAssociatedDevicesGroup(groupName: string, params?: GroupParams): Promise<Response<AssociatedDevicesInGroupResponse> | undefined> {
    const urlSearchParams = encodeParams<QueryParams>(params ?? {});
    return this.get<AssociatedDevicesInGroupResponse>(`devices/groups/${encodeURIComponent(groupName)}/associated`, urlSearchParams);
  }

  /**
   * Edit a group of devices
   * @param groupName required - The name of the group to edit.
   * @param bodyRequest required - The request body to edit the group.
   * @returns operation status code
   */
  static async editGroup(groupName: string, group: DevicesByGroupName): Promise<Response<DevicesByGroupName> | undefined> {
    return this.patch<DevicesByGroupName>(`devices/groups/${encodeURIComponent(groupName)}`, group);
  }

  /**
   * Delete a group of devices
   * @param groupName required - The name of the group to delete.
   * @returns boolean - operation success or not
   */
  static async deleteGroup(groupName: string): Promise<Response | undefined> {
    return this.remove(`devices/groups/${encodeURIComponent(groupName)}`);
  }

  // TODO: Move it into request helper later
  static getResponse<T extends Response, TT>(response?: T): TT | undefined {
    return response ? ([StatusCode.OK, StatusCode.NO_CONTENT].includes(response.status ?? StatusCode.INTERNAL_SERVER_ERROR) ? response.data : undefined) : undefined;
  }

  /**
   * Associate device group
   * @param groupName required - The name of the group to associate with the device.
   * @returns boolean - True if the group was successfully associated with the device, false otherwise.
   * @throws 204 - No content
   * @throws 404 - Not found
   * @throws 500 - Internal server error
   * @throws 400 - Bad request
   * @throws 401 - Unauthorized
   * @throws 403 - Forbidden
   * @throws 405 - Method not allowed
   */
  static async associate(groupName: string): Promise<Response | undefined> {
    return this.patch(`devices/groups/associate/${encodeURIComponent(groupName)}`);
  }

  /**
   * Dissociate device group
   * @param groupName required - The name of the group to dissociate from the device.
   * @returns boolean - True if the group was successfully dissociated from the device, false otherwise.
   * @throws 204 - No content
   * @throws 404 - Not found
   * @throws 500 - Internal server error
   * @throws 400 - Bad request
   * @throws 401 - Unauthorized
   * @throws 403 - Forbidden
   * @throws 405 - Method not allowed
   */
  static async dissociate(groupName: string): Promise<Response | undefined> {
    return this.patch(`devices/groups/dissociate/${encodeURIComponent(groupName)}`);
  }

  /**
   * Get list of associated devices by group
   * @param groupName required - The name of the group to get associated devices.
   * @param tnsDeviceName optional - The name of the TNS device to get associated devices.
   * @param recordsPerPage optional - The number of records to return per page. Default is 100.
   * @param startAtRecord optional - The record number to start at. Default is 0.
   * @returns list of associated devices by group
   * @throws 204 - No content
   * @throws 404 - Not found
   * @throws 500 - Internal server error
   * @throws 400 - Bad request
   * @throws 401 - Unauthorized
   * @throws 403 - Forbidden
   * @throws 405 - Method not allowed
   */
  static async getAssociatedDevicesByGroup(groupName: string, params?: QueryParams): Promise<Response<AssociatedDevicesInGroupResponse> | undefined> {
    const urlSearchParams = encodeParams<QueryParams>(params as QueryParams);
    return this.get<AssociatedDevicesInGroupResponse>(`devices/groups/${encodeURIComponent(groupName)}/associated`, urlSearchParams);
  }

  static async getAllGroups(params?: QueryParams): Promise<Response<AllGroupsResponse> | undefined> {
    const queryParams = encodeParams<QueryParams>(params ?? {});
    return this.get<AllGroupsResponse>("devices/groups", queryParams);
  }

  static async getAllMyGroups(params?: QueryParams): Promise<Response<AllGroupsResponse> | undefined> {
    const queryParams = encodeParams<QueryParams>(params ?? {});
    return this.get<AllGroupsResponse>("devices/groups/mygroups", queryParams);
  }

  static async create(group: GroupForm): Promise<Response<GroupForm> | undefined> {
    return this.post<GroupForm>("devices/groups", group);
  }

  static async isAvailable(groupName: string): Promise<Response<IsAvailableGroupResponse> | undefined> {
    return this.get<IsAvailableGroupResponse>(`devices/groups/name/${encodeURIComponent(groupName)}/available`);
  }
}
