import React, { ReactNode } from "react";
import { store } from "../../StoreMobx";
import { DateHelper } from "../../../helpers/shared/DateHelper";
import { TNSOSearchGrid } from "../../../components/shared/table/SearchFilter";
import { TNSOSelectFilter } from "../../../components/shared/table/SelectFilter";
import { SearchOutlined } from "@ant-design/icons";
import { LVCTableTitle } from "./lvc-table-title";
import { TableProps } from "antd";
import { LVCData } from "src/app/portal-app/interfaces/devices/lvc/lvc";

export enum LVCColumnKeys {
  lvcTicketNumber = "lvcTicketNumber",
  status = "status",
  devices = "devices",
  startDate = "startDate",
  ep1Acna = "ep1Acna",
  endPoint1 = "endPoint1",
  ep2Acna = "ep2Acna",
  endPoint2 = "endPoint2"
}

export interface TableConfigProps {
  handleSearch: (filter: string, keyFilter: string) => Promise<void>;
}

export interface TableConfigResponse {
  columns: TableProps<LVCData>[];
  skeletonColumns: unknown[];
}

// eslint-disable-next-line
export const useTableLVCConfig = ({ handleSearch }: TableConfigProps): TableConfigResponse => {
  // eslint-disable-next-line
  const getColumnSearchProps = (dataIndex: string, filterType?: "search" | "select", icon?: ReactNode, options?: any[]): any => ({
    // eslint-disable-next-line
    filterDropdown: (props: any) =>
      filterType === "search" ? (
        // eslint-disable-next-line
        <TNSOSearchGrid
          themeSelected="dark"
          {...props}
          onSearch={handleSearch}
          keyFilter={dataIndex}
          onReset={(): Promise<void> => handleSearch("all", dataIndex)}
          defaultValue={store.device.lvc.activeFilters[dataIndex]}
          close={props.close}
        />
      ) : (
        <TNSOSelectFilter options={options ?? []} onSearch={handleSearch} keyFilter={dataIndex} defaultValue={[store.device.lvc.activeFilters[dataIndex]]} close={props.close} />
      ),
    filterIcon: () => icon ?? <SearchOutlined />
  });

  const columns: TableProps<LVCData>[] = [
    {
      dataIndex: "lvcTicketNumber",
      title: <LVCTableTitle title="lvcTicketNumber" tooltipText="lvcTicketNumberTooltip" />,
      key: "lvcTicketNumber",
      fixed: "left",
      collapse: "lvcTicketNumber",
      sorter: true,
      showSorterTooltip: { target: "sorter-icon" },
      ...getColumnSearchProps("lvcTicketNumber", "search")
    },
    {
      dataIndex: "status",
      title: <LVCTableTitle title="status" tooltipText="statusTooltip" />,
      key: "status",
      collapse: "status",
      showSorterTooltip: { target: "sorter-icon" }
    },
    {
      dataIndex: "devices",
      title: <LVCTableTitle title="devices" />,
      key: "devices",
      collapse: "devices",
      sorter: true,
      showSorterTooltip: { target: "sorter-icon" },
      ...getColumnSearchProps("devices", "search")
    },
    {
      dataIndex: "startDate",
      title: <LVCTableTitle title="startDate" />,
      key: "startDate",
      collapse: "startDate",
      sorter: true,
      showSorterTooltip: { target: "sorter-icon" },
      render: (cell: string | null): string => {
        const timeZone = store.auth.userInfo?.timeZone;
        return cell ? DateHelper.getDateFromTimestampWithTimeZone(Number(cell), timeZone) : "";
      }
    },
    {
      dataIndex: "ep1Acna",
      title: <LVCTableTitle title="ep1Acna" />,
      key: "ep1Acna",
      collapse: "ep1Acna",
      ellipsis: true,
    },
    {
      dataIndex: "ep1DeviceName",
      title: <LVCTableTitle title="endPoint1" tooltipText="endPoint1Tooltip" />,
      key: "ep1DeviceName",
      collapse: "endPoint1",
      sorter: true,
      showSorterTooltip: { target: "sorter-icon" },
      ...getColumnSearchProps("ep1DeviceName", "search")
    },
    {
      dataIndex: "ep1Host",
      title: <LVCTableTitle title="ep1Host" tooltipText="ep1HostTooltip" />,
      key: "ep1Host",
      collapse: "endPoint1",
      sorter: true,
      showSorterTooltip: { target: "sorter-icon" },
      ...getColumnSearchProps("ep1Host", "search")
    },
    {
      dataIndex: "ep1RealIp",
      title: <LVCTableTitle title="ep1RealIp" tooltipText="ep1RealIpTooltip" />,
      key: "ep1RealIp",
      collapse: "endPoint1",
      sorter: true,
      showSorterTooltip: { target: "sorter-icon" },
      ...getColumnSearchProps("ep1RealIp", "search")
    },
    {
      dataIndex: "knowsEp2As",
      title: <LVCTableTitle title="knowsEp2As" tooltipText="knowsEp2AsTooltip" />,
      key: "knowsEp2As",
      collapse: "endPoint1",
      sorter: true,
      showSorterTooltip: { target: "sorter-icon" },
      ...getColumnSearchProps("knowsEp2As", "search")
    },
    {
      dataIndex: "ep2Acna",
      title: <LVCTableTitle title="ep2Acna" />,
      key: "ep2Acna",
      collapse: "ep2Acna",
      ellipsis: true,
      sorter: true,
      showSorterTooltip: { target: "sorter-icon" },
      ...getColumnSearchProps("ep2Acna", "search")
    },
    {
      dataIndex: "ep2DeviceName",
      title: <LVCTableTitle title="endPoint2" tooltipText="endPoint2Tooltip" />,
      key: "ep2DeviceName",
      collapse: "endPoint2",
      sorter: true,
      showSorterTooltip: { target: "sorter-icon" },
      ...getColumnSearchProps("ep2DeviceName", "search")
    },
    {
      dataIndex: "ep2Host",
      title: <LVCTableTitle title="ep2Host" tooltipText="ep2HostTooltip" />,
      key: "ep2Host",
      collapse: "endPoint2",
      sorter: true,
      showSorterTooltip: { target: "sorter-icon" },
      ...getColumnSearchProps("ep2Host", "search")
    },
    {
      dataIndex: "ep2RealIp",
      title: <LVCTableTitle title="ep2RealIp" tooltipText="ep2RealIpTooltip" />,
      key: "ep2RealIp",
      collapse: "endPoint2",
      sorter: true,
      showSorterTooltip: { target: "sorter-icon" },
      ...getColumnSearchProps("ep2RealIp", "search")
    },
    {
      dataIndex: "knowsEp1As",
      title: <LVCTableTitle title="knowsEp1As" tooltipText="knowsEp1AsTooltip" />,
      key: "knowsEp1As",
      collapse: "endPoint2",
      sorter: true,
      showSorterTooltip: { target: "sorter-icon" },
      ...getColumnSearchProps("knowsEp1As", "search")
    }
  ];

  const skeletonColumns: unknown[] = [
    {
      dataIndex: "lvcTicketNumber",
      title: <LVCTableTitle title="lvcTicketNumber" tooltipText="lvcTicketNumberTooltip" />,
      key: "lvcTicketNumber",
      fixed: "left",
      collapse: "lvcTicketNumber",
      sorter: true,
      showSorterTooltip: { target: "sorter-icon" },
      ...getColumnSearchProps("lvcTicketNumber", "search")
    },
    {
      dataIndex: "status",
      title: "status",
      key: "status"
    },
    {
      dataIndex: "devices",
      title: "devices",
      key: "devices"
    },
    {
      dataIndex: "startDate",
      title: "startDate",
      key: "startDate"
    },
    {
      dataIndex: "ep1Acna",
      title: "ep1Acna",
      key: "ep1Acna"
    },
    {
      dataIndex: "ep1Host",
      title: "ep1Host",
      key: "ep1Host"
    },
    {
      dataIndex: "ep1RealIp",
      title: "ep1RealIp",
      key: "ep1RealIp"
    },
    {
      dataIndex: "knowsEp2As",
      title: "knowsEp2As",
      key: "knowsEp2As"
    },
    {
      dataIndex: "ep2Acna",
      title: "ep2Acna",
      key: "ep2Acna"
    },
    {
      dataIndex: "ep2Host",
      title: "ep2Host",
      key: "ep2Host"
    },
    {
      dataIndex: "ep2RealIp",
      title: "ep2RealIp",
      key: "ep2RealIp"
    },
    {
      dataIndex: "knowsEp1As",
      title: "knowsEp1As",
      key: "knowsEp1As"
    }
  ];

  return {
    columns,
    skeletonColumns
  };
};
