import { CompanyProfile, CompanyProfileWithACNAs } from "../../interfaces/companyProfiles/company";
import { DeviceAddress, DeviceTable } from "../../interfaces/devices/devices";
import { DevicesResponse, LVCResponse } from "../../interfaces/devices/response/response";
import { LVCDefaultExport, LVCTable } from "../../interfaces/devices/lvc/lvc";
import { ACNA } from "../../interfaces/companyProfiles/acna/acna";
import { ChangeTicketsExport, ChangeTicketsResponse } from "../../interfaces/changeTickets/changeTickets";
import { store } from "../../store/StoreMobx";
import { CellularSignal, CellularSignalExport, Period } from "../../interfaces/devices/cellular/cellularSignal";
import { User, UserResponse, UserStatusForm, UserTable } from "../../interfaces/users/user";
import { ExportMemberTable, MembersExportResponse } from "../../interfaces/memberConnectivity/memberConnectivity";
import { CellularUsage, CellularUsageTable } from "../../interfaces/devices/cellular/cellularUsage";
import i18n from "../../../../i18n";
import { TRANSLATION } from "../../utils/const/translation";
import { QueryRoles, Roles, RolesByUserList } from "../../interfaces/auth/roleAndPermission/role";
import { Day, Month, UptimeExport } from "../../interfaces/devices/chart/chart";
import { GetFirewallRulesResponse } from "../../context/firawall-rules/firewall-rules-context";
import { SoftwareResponse } from "../../context/softwareTab/SoftwareTabContext";
import { DateHelper, UnitConvertHelper } from "@tnso/shared";

interface AddressComponentTypes {
  street_number?: string;
  route?: string;
  neighborhood?: string;
  locality?: string;
  administrative_area_level_1?: string;
  administrative_area_level_2?: string;
  country?: string;
  postal_code?: string;
  postal_town?: string;
}

interface AddressComponentTypes {
  street_number?: string;
  route?: string;
  neighborhood?: string;
  locality?: string;
  administrative_area_level_1?: string;
  administrative_area_level_2?: string;
  country?: string;
  postal_code?: string;
  postal_town?: string;
}

enum AddressComponentEnum {
  streetNumber = "street_number",
  route = "route",
  neighborhood = "neighborhood",
  locality = "locality",
  administrative_area_level_1 = "administrative_area_level_1",
  administrative_area_level_2 = "administrative_area_level_2",
  country = "country",
  postal_code = "postal_code",
  postal_town = "postal_town"
}

interface OptionParam {
  label: string;
  value: string;
}

export class MapperHelper {
  public static mapLVC(source?: LVCResponse): LVCTable[] {
    if (source && source.lvcs) {
      const dataMapped = source.lvcs.map((item) => {
        return {
          lvcTicketNumber: item.lvcTicketNumber,
          status: item.status,
          devices: `${item.ep1DeviceName} - ${item.ep2DeviceName}`,
          startDate: item.startDate,
          ep1Acna: item.ep1Acna,
          ep1Host: item.ep1Host,
          ep1RealIp: item.ep1RealIp,
          knowsEp2As: item.knowsEp2As,
          ep2Acna: item.ep2Acna,
          ep2Host: item.ep2Host,
          ep2RealIp: item.ep2RealIp,
          knowsEp1As: item.knowsEp1As
        };
      });
      return dataMapped;
    } else {
      return [];
    }
  }

  public static mapUptime(source?: Day[] | Month[], isMonthly?: boolean): UptimeExport[] {
    if (source) {
      const dataMapped = source.map((item) => {
        return {
          [i18n.t(TRANSLATION.SHARED.TABLE.tnsDeviceName)]: item.tnsDeviceName,
          [i18n.t(TRANSLATION.SHARED.TABLE.customerDeviceName)]: `${store.device.detail.data?.customerDeviceName}`,
          [i18n.t(TRANSLATION.SHARED.TABLE.companyName)]: `${store.device.detail.data?.knownAs}`,
          [i18n.t(TRANSLATION.SHARED.TABLE.date)]: isMonthly
            ? `${i18n.t(
                TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.UPTIME.MONTHSLEYEND[
                  item.month.toLowerCase() as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.UPTIME.MONTHSLEYEND
                ]
              )} ${item.year}`
            : `${item.month}/${(item as Day).day}/${item.year}`,
          [i18n.t(TRANSLATION.SHARED.TABLE.serviceUptime)]: `${Number(item.uptime).toFixed(2)}%`
        };
      });
      return dataMapped;
    } else {
      return [];
    }
  }

  public static mapLVCExport(source?: LVCResponse): LVCTable[] {
    const timeZone = store.auth.userInfo?.timeZone;
    if (source && source.lvcs) {
      const dataMapped = source.lvcs.map((item) => {
        const startDate = item.startDate
          ? new Date(item.startDate * 1000).toLocaleString("es-ES", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
              timeZone
            })
          : "";
        const formattedDateWithoutDots = startDate.replace(/\.(\s)?/g, "");
        return {
          [i18n.t(TRANSLATION.SHARED.TABLE.lvcTicketNumber)]: item.lvcTicketNumber,
          [i18n.t(TRANSLATION.SHARED.TABLE.status)]: item.status,
          [i18n.t(TRANSLATION.SHARED.TABLE.devices)]: `${item.ep1DeviceName} - ${item.ep2DeviceName}`,
          [i18n.t(TRANSLATION.SHARED.TABLE.startDate)]: formattedDateWithoutDots,
          [i18n.t(TRANSLATION.SHARED.TABLE.ep1Acna)]: item.ep1Acna,
          [i18n.t(TRANSLATION.SHARED.TABLE.ep1Host)]: item.ep1Host,
          [i18n.t(TRANSLATION.SHARED.TABLE.ep1RealIp)]: item.ep1RealIp,
          [i18n.t(TRANSLATION.SHARED.TABLE.knowsEp2As)]: item.knowsEp2As,
          [i18n.t(TRANSLATION.SHARED.TABLE.ep2Acna)]: item.ep2Acna,
          [i18n.t(TRANSLATION.SHARED.TABLE.ep2Host)]: item.ep2Host,
          [i18n.t(TRANSLATION.SHARED.TABLE.ep2RealIp)]: item.ep2RealIp,
          [i18n.t(TRANSLATION.SHARED.TABLE.knowsEp1As)]: item.knowsEp1As
        };
      });
      return dataMapped as unknown as LVCTable[];
    } else {
      return [];
    }
  }

  public static mapLVCDefaultExport(source?: LVCResponse): LVCTable[] {
    const options = store.device.lvc.columnsActive;
    if (source && source.lvcs && options) {
      const dataMapped = source.lvcs.map((item) => {
        const showStatus = options.includes("status");
        const showEndpoint1 = options.includes("endPoint1");
        const showEndpoint2 = options.includes("endPoint2");
        const showLVCTicket = options.includes("lvcTicketNumber");
        const mappedData: Partial<LVCDefaultExport> = {};

        if (showLVCTicket) {
          mappedData[i18n.t(TRANSLATION.SHARED.TABLE.lvcTicketNumber)] = item.lvcTicketNumber;
        }

        if (showStatus) {
          mappedData[i18n.t(TRANSLATION.SHARED.TABLE.status)] = item.status;
        }

        if (showEndpoint1) {
          mappedData[i18n.t(TRANSLATION.SHARED.TABLE.endPoint1)] = item.ep1DeviceName;
          mappedData[i18n.t(TRANSLATION.SHARED.TABLE.ep1Host)] = item.ep1Host;
          mappedData[i18n.t(TRANSLATION.SHARED.TABLE.ep1RealIp)] = item.ep1RealIp;
          mappedData[i18n.t(TRANSLATION.SHARED.TABLE.knowsEp2As)] = item.knowsEp2As;
        }

        if (showEndpoint2) {
          mappedData[i18n.t(TRANSLATION.SHARED.TABLE.endPoint2)] = item.ep2DeviceName;
          mappedData[i18n.t(TRANSLATION.SHARED.TABLE.ep2Host)] = item.ep2Host;
          mappedData[i18n.t(TRANSLATION.SHARED.TABLE.ep2RealIp)] = item.ep2RealIp;
          mappedData[i18n.t(TRANSLATION.SHARED.TABLE.knowsEp1As)] = item.knowsEp1As;
        }

        return mappedData;
      });
      return dataMapped as unknown as LVCTable[];
    } else {
      return [];
    }
  }

  public static mapCompanyProfile(source: CompanyProfileWithACNAs[]): CompanyProfile[] {
    const dataMapped = source.map((item) => {
      return {
        ...item,
        acnas: item.acnas?.map((acna) => acna.name + "-" + acna.knownAs).join(",")
      };
    });
    return dataMapped;
  }

  public static mapDevices(source?: DevicesResponse): DeviceTable[] {
    if (source && source.devices) {
      const dataMapped = source.devices.map((device) => {
        const groupsNames = device.groups?.map((g) => g.name).join(", ");
        return {
          [i18n.t(TRANSLATION.SHARED.TABLE.tnsDeviceName)]: device.tnsDeviceName,
          [i18n.t(TRANSLATION.SHARED.TABLE.company)]: device.knownAs,
          [i18n.t(TRANSLATION.SHARED.TABLE.acna)]: device.acna,
          [i18n.t(TRANSLATION.SHARED.TABLE.customerDeviceName)]: device.customerDeviceName,
          [i18n.t(TRANSLATION.SHARED.TABLE.groups)]: groupsNames,
          [i18n.t(TRANSLATION.SHARED.TABLE.serviceStatus)]: i18n.t(
            TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICESTATUS[device.connectivityStatus as keyof typeof TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICESTATUS]
          ),
          [i18n.t(TRANSLATION.SHARED.TABLE.operationalStatus)]: i18n.t(TRANSLATION.SHARED.DATATABLE[device.operationalStatus as keyof typeof TRANSLATION.SHARED.DATATABLE]),
          [i18n.t(TRANSLATION.SHARED.TABLE.carrier)]: device.carrier,
          [i18n.t(TRANSLATION.SHARED.TABLE.country)]: device.country,
          [i18n.t(TRANSLATION.SHARED.TABLE.hasGeolocation)]: device.hasGeolocation ? i18n.t(TRANSLATION.SHARED.DATATABLE.yes) : i18n.t(TRANSLATION.SHARED.DATATABLE.no)
        };
      });
      return dataMapped as unknown as DeviceTable[];
    } else {
      return [];
    }
  }

  public static acnasToAcnasWithKnownAs(source: string): ACNA[] {
    const acnas = source.split(",");
    const acnasWithKnownAs = acnas.map((acna) => {
      const acnaWithKnownAs = acna.split("-");
      return {
        name: acnaWithKnownAs[0],
        knownAs: acnaWithKnownAs[1]
      };
    });
    return acnasWithKnownAs;
  }

  public static userToUserTable(source: UserResponse): UserTable[] {
    const dataMapped = source.users.map((item) => {
      return {
        [i18n.t(TRANSLATION.SHARED.TABLE.emailAddress)]: item.email,
        [i18n.t(TRANSLATION.SHARED.TABLE.firstName)]: item.firstName,
        [i18n.t(TRANSLATION.SHARED.TABLE.lastName)]: item.lastName,
        [i18n.t(TRANSLATION.SHARED.TABLE.status)]: item.enabled ? UserStatusForm.Enabled : UserStatusForm.Disabled,
        [i18n.t(TRANSLATION.SHARED.TABLE.companyProfileAdministrationTable)]: item.companyProfile,
        [i18n.t(TRANSLATION.SHARED.TABLE.entityProfile)]: item.entityProfile,
        [i18n.t(TRANSLATION.SHARED.TABLE.userRole)]: item.role
      };
    });
    return dataMapped as UserTable[];
  }

  public static mapChangeTicketsToExport(source?: ChangeTicketsResponse): ChangeTicketsExport[] {
    if (source && source.changeTickets) {
      const dataMapped = source.changeTickets.map((item) => {
        return {
          [i18n.t(TRANSLATION.SHARED.TABLE.changeStartTime)]: DateHelper.getDateFromTimestampWithTimeZone(item.changeStartTime, store.auth.userInfo?.timeZone),
          [i18n.t(TRANSLATION.SHARED.TABLE.changeTicketId)]: item.changeTicketId,
          [i18n.t(TRANSLATION.SHARED.TABLE.companyName)]: item.companyName,
          [i18n.t(TRANSLATION.SHARED.TABLE.changeDescription)]: item.changeDescription,
          [i18n.t(TRANSLATION.SHARED.TABLE.impactType)]: i18n.t(item.impactType),
          [i18n.t(TRANSLATION.SHARED.TABLE.statusOfChange)]: i18n.t(item.statusOfChange),
          [i18n.t(TRANSLATION.SHARED.TABLE.maximumDurationHrs)]: item.maximumDuration.toFixed(1)
        };
      });
      return dataMapped;
    }
    return [];
  }

  public static acnasAndKnownAs(): ACNA[] {
    const acnas = store.acna.acnas || []; // We use and empty array in case acnas is undefined

    return acnas.map((acna) => {
      return {
        name: acna.name,
        knownAs: acna.knownAs
      };
    });
  }

  public static mapMembersExport(source?: MembersExportResponse): ExportMemberTable[] {
    const dataMapped: ExportMemberTable[] = [];

    if (source && source.members) {
      source.members.forEach((item) => {
        const row: ExportMemberTable = {
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.sourceMember)]: item.acna + " - " + item.knownAs,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.acnaStatus)]: item.acnaStatus,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.destinationMember)]: item.destAcna + " - " + item.destKnownAs,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.statusOfMemberConnection)]: item.memberConnectivityStatus,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.sourceTNSDeviceName)]: item.srcTnsDeviceName,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.sourceCustomerDeviceName)]: item.srcCustomerDeviceName,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.destAcnaStatus)]: item.destAcnaStatus,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.destinationTNSDeviceName)]: item.destTnsDeviceName,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.destinationCustomerDeviceName)]: item.destCustomerDeviceName,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.wanInstance)]: item.interfaceType,
          [i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.tunnelStatus)]: item.interfaceStatus
        };
        dataMapped.push(row);
      });
    }
    dataMapped.sort((a, b) => {
      const sourceMemberComparison = a[i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.sourceMember)].localeCompare(
        b[i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.sourceMember)]
      );
      if (sourceMemberComparison !== 0) {
        return sourceMemberComparison;
      }
      return a[i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.destinationMember)].localeCompare(b[i18n.t(TRANSLATION.SIDEBAR.MONITORING.MEMBERS.MAPPER.destinationMember)]);
    });
    return dataMapped;
  }

  static mapRawDataExport = (source?: CellularSignal[], interval?: string): CellularSignalExport[] => {
    if (source) {
      return source.map((item) => {
        const dateTime =
          interval === Period.Daily
            ? DateHelper.formatTimestampInHours(item.dateTime, store.auth.userInfo?.timeZone)
            : DateHelper.formatTimestampInDays(item.dateTime, store.auth.userInfo?.timeZone);
        return {
          "TNS Device Name": item.name,
          "Date Time": dateTime,
          "Technology Type": item.technologyType,
          "Norm Low": item.normLow,
          "Norm High": item.normHigh,
          "Norm Average": item.normAverage,
          "RSSI Average": item.rssiAverage,
          "RSRP Average": item.rsrpAverage,
          "RSRQ Average": item.rsrqAverage,
          "RSCP Average": item.rscpAverage,
          "EC/IO Average": item.ecioAverage,
          "SINR Average": item.sinrAverage,
          "EC/IO Count": item.ecioCount,
          "RSCP Count": item.rscpCount,
          "RSRQ Count": item.rsrqCount,
          "RSRP Count": item.rsrpCount,
          "SINR Count": item.sinrCount,
          "EC/IO Signal Quality": item.ecioSignalQuality,
          "Norm Signal Quality": item.normSignalQuality,
          "RSSI Signal Quality": item.rssiSignalQuality,
          "RSRP Signal Quality": item.rsrpSignalQuality,
          "RSRQ Signal Quality": item.rsrqSignalQuality,
          "RSCP Signal Quality": item.rscpSignalQuality,
          "SINR Signal Quality": item.sinrSignalQuality,
          "Model Name": item.modelName,
          Service: item.service
        };
      });
    } else {
      return [];
    }
  };

  public static mapUsageToExport(source?: CellularUsage[]): CellularUsageTable[] {
    if (source) {
      const timeZone = store.auth.userInfo?.timeZone;
      const dataMapped: CellularUsageTable[] = source
        .slice()
        .reverse()
        .map((item) => {
          const billingPeriod = `${DateHelper.convertTimestampToBrowserCulture(Number(item.startDate), timeZone, {
            month: "2-digit",
            day: "2-digit",
            year: "numeric"
          })} - ${DateHelper.convertTimestampToBrowserCulture(Number(item.endDate), timeZone, { month: "2-digit", day: "2-digit", year: "numeric" })}`;
          return {
            [i18n.t(TRANSLATION.SHARED.TABLE.tnsDeviceName)]: item.name,
            [i18n.t(TRANSLATION.SHARED.TABLE.billingPeriod)]: billingPeriod,
            [i18n.t(TRANSLATION.SHARED.TABLE.planSize)]: `${UnitConvertHelper.convertBytesToMebiBytes(item.planSize).toFixed(2)} MiB`,
            [i18n.t(TRANSLATION.SHARED.TABLE.txcnt)]: `${UnitConvertHelper.convertBytesToMebiBytes(item.txcnt).toFixed(2)} MiB`,
            [i18n.t(TRANSLATION.SHARED.TABLE.rxcnt)]: `${UnitConvertHelper.convertBytesToMebiBytes(item.rxcnt).toFixed(2)} MiB`,
            [i18n.t(TRANSLATION.SHARED.TABLE.totalData)]: `${UnitConvertHelper.convertBytesToMebiBytes(item.txcnt + item.rxcnt).toFixed(2)} MiB`,
            [i18n.t(TRANSLATION.SHARED.TABLE.percent)]: `${item.percent}%`,
            [i18n.t(TRANSLATION.SHARED.TABLE.overage)]: `${UnitConvertHelper.convertBytesToMebiBytes(item.overage).toFixed(2)} MiB`
          };
        });

      return dataMapped;
    }
    return [];
  }

  public static mapSelectedNewAddress(place: google.maps.places.PlaceResult): DeviceAddress {
    const placeAux: AddressComponentTypes = {};
    const deviceAddress: DeviceAddress = {
      street1: "",
      street2: "",
      city: "",
      state: "",
      countryCode: "",
      zipCode: "",
      latitude: "",
      longitude: ""
    };
    place.address_components?.map((component) => {
      Object.assign(placeAux, {
        [component.types[0]]:
          component.types[0] === AddressComponentEnum.administrative_area_level_1 || component.types[0] === AddressComponentEnum.country
            ? component.short_name
            : component.long_name
      });
    });

    deviceAddress.street1 = `${placeAux.street_number ?? ""} ${placeAux.route ?? ""}`;
    deviceAddress.city = placeAux.locality ?? placeAux.postal_town ?? deviceAddress.city;
    deviceAddress.state = placeAux.administrative_area_level_1 ?? deviceAddress.state;
    deviceAddress.countryCode = placeAux.country ?? deviceAddress.countryCode;
    deviceAddress.zipCode = placeAux.postal_code ?? deviceAddress.zipCode;
    deviceAddress.latitude = place.geometry?.location?.lat().toString() ?? deviceAddress.latitude;
    deviceAddress.longitude = place.geometry?.location?.lng().toString() ?? deviceAddress.longitude;
    return deviceAddress;
  }

  public static mapRolestoRolesByUserList(roles?: Roles): RolesByUserList {
    switch (roles) {
      case Roles.Admin:
        return RolesByUserList.Admin;
      case Roles.SuperUser:
        return RolesByUserList.SuperUser;
      case Roles.CAM:
        return RolesByUserList.CAM;
      case Roles.Basic:
        return RolesByUserList.Basic;
      default:
        return roles as unknown as RolesByUserList;
    }
  }

  public static mapUserByRole(data: User, isOnboard?: boolean): { user: User; role: string } {
    let userMapped: User = {
      username: data.username,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      enabled: data.enabled,
      timeZone: data.timeZone
    };
    const objectMapped: { user: User; role: string } = { user: data, role: QueryRoles.Basic };

    switch (data.role) {
      case RolesByUserList.Admin:
        userMapped = {
          ...userMapped,
          companyProfile: data.companyProfile
        };
        objectMapped.role = QueryRoles.Admin;
        break;
      case RolesByUserList.Basic:
        userMapped = {
          ...userMapped,
          entityProfile: data.entityProfile ?? ""
        };
        objectMapped.role = QueryRoles.Basic;
        break;
      case RolesByUserList.SuperUser:
        objectMapped.role = QueryRoles.SuperUser;
        break;
      case RolesByUserList.CAM:
        userMapped = {
          camProfile: data.camProfile
        };
        objectMapped.role = QueryRoles.CAM;
        break;
      default:
        userMapped = {
          ...userMapped,
          entityProfile: data.entityProfile ?? "",
          role: data.role
        };
        objectMapped.role = "custom";
    }
    if (isOnboard) {
      delete userMapped.username;
      delete userMapped.firstName;
      delete userMapped.lastName;
      delete userMapped.email;
      delete userMapped.enabled;
      delete userMapped.timeZone;
    }
    objectMapped.user = userMapped;
    return objectMapped;
  }

  public static mapOptionsFormatted = (strings: string[]): OptionParam[] => {
    return strings.map((str: string) => ({
      label: i18n.t(TRANSLATION.TNSDEVICES.FILTERS[str as keyof typeof TRANSLATION.TNSDEVICES.FILTERS]) || str,
      value: str
    }));
  };

  public static mapToExportFirewallRules(filteredFirewallRulesData?: GetFirewallRulesResponse[]): GetFirewallRulesResponse[] {
    const dataMapped: GetFirewallRulesResponse[] = [];

    if (filteredFirewallRulesData) {
      filteredFirewallRulesData.forEach((item) => {
        const row: GetFirewallRulesResponse = {
          [i18n.t(TRANSLATION.SHARED.TABLE.deviceName)]: item.tnsDeviceName ? item.tnsDeviceName : "N/A",
          [i18n.t(TRANSLATION.SHARED.TABLE.lvcTicketNumber)]: item.lvcTicketNumber ? item.lvcTicketNumber : "N/A",
          [i18n.t(TRANSLATION.SHARED.TABLE.lvcId)]: item.lvcId ? item.lvcId : "N/A",
          [i18n.t(TRANSLATION.SHARED.TABLE.ep1ConnectedTo)]: item.ep1ConnectedTo ? item.ep1ConnectedTo : "N/A",
          [i18n.t(TRANSLATION.SHARED.TABLE.ep1RealIpStored)]: item.ep1RealIpStored ? item.ep1RealIpStored : "N/A",
          [i18n.t(TRANSLATION.SHARED.TABLE.ep1Knowsep2As)]: item.ep1Knowsep2As ? item.ep1Knowsep2As : "N/A",
          [i18n.t(TRANSLATION.SHARED.TABLE.ep2ConnectedTo)]: item.ep2ConnectedTo ? item.ep2ConnectedTo : "N/A",
          [i18n.t(TRANSLATION.SHARED.TABLE.ep2RealIpStored)]: item.ep2RealIpStored ? item.ep2RealIpStored : "N/A",
          [i18n.t(TRANSLATION.SHARED.TABLE.status)]: item.status ? item.status : "N/A",
          [i18n.t(TRANSLATION.SHARED.TABLE.direction)]: item.direction ? item.direction : "N/A",
          [i18n.t(TRANSLATION.SHARED.TABLE.hitCount)]: item.hitCount ? item.hitCount : "N/A",
          [i18n.t(TRANSLATION.SHARED.TABLE.customerSystemNameEp1)]: item.customerSystemNameEp1 ? item.customerSystemNameEp1 : "N/A",
          [i18n.t(TRANSLATION.SHARED.TABLE.customerSystemNameEp2)]: item.customerSystemNameEp2 ? item.customerSystemNameEp2 : "N/A"
        };
        dataMapped.push(row);
      });
    }
    return dataMapped;
  }

  public static mapToExportSoftwares(softwareData: SoftwareResponse[]): SoftwareResponse[] {
    const dataMapped: SoftwareResponse[] = [];
    if (softwareData) {
      softwareData.forEach((item) => {
        const row: SoftwareResponse = {
          [i18n.t("software")]: item.software ? item.software : "-",
          [i18n.t("deviceVersion")]: item.deviceVersion ? item.deviceVersion : "-",
          [i18n.t("instalationDate")]: item.installedOn
            ? DateHelper.convertTimestampToBrowserCulture(item.installedOn, store.auth.userInfo?.timeZone).replace(",", "").replace("/", "-").replace("/", "-")
            : "-",
          [i18n.t("currentVersion")]: item.currentVersion ? item.currentVersion : "-"
        };
        dataMapped.push(row);
      });
    }
    return dataMapped;
  }
}
