import { Notifications } from "@tnso/api-sdk";
import { NotificationsResponse, NotificationCount } from "../../contexts/notifications/notifications-context";
import { QueryParams } from "@tnso/shared";
export interface NotificationBody {
  status: string;
  updateAll?: boolean;
  updateIdList?: Array<number>;
}

export interface NotificationToExport {
  [key: string]: string | number | boolean | undefined;
}

export const enum NotificationType {
  DEVICE = "DEVICE",
  INCIDENT = "INCIDENT"
}

export const enum NotificationStatus {
  READ = "READ",
  UNREAD = "UNREAD"
}

export interface NotificationQueryParams extends QueryParams {
  notificationFromDate?: number;
  notificationToDate?: number;
  notificationType?: string;
  status?: string;
  referenceId?: string;
  id?: number;
  orderBy?: string;
  recordsPerPage?: number;
  sortBy?: string;
  startAtRecord?: number;
}

export interface NotificationContextProps {
  amountOfNotificationsUnread: number;
  notifications?: NotificationsResponse;
  params: NotificationQueryParams;
  getNotifications(notificationParams?: NotificationQueryParams): Promise<NotificationsResponse | undefined>;
  getLastNotifications: (notificationParams: NotificationQueryParams) => Promise<Notifications[] | undefined>;
  getCountNotificationsUnread: () => Promise<NotificationCount | undefined>;
  updateNotifications: (notification: NotificationBody) => Promise<NotificationsResponse | undefined>;
  setNotificationsData: (queryParams?: NotificationQueryParams) => Promise<NotificationsResponse | undefined>;
  getHasNotifications: (queryParams?: NotificationQueryParams) => Promise<boolean | undefined>;
}
