import React, { ReactNode } from "react";

export interface NotificationProps {
  type?: ReactNode;
  message?: string;
  status?: string;
}

export const NotificationType: React.FC<NotificationProps> = ({ type, status }) => {
  return <div className={`d-flex justify-content-center align-items-center notification-type notification-type-${status === "READ" ? "read" : "unread"}`}>{type}</div>;
};

export const NotificationMessage: React.FC<NotificationProps> = ({ message, status }) => {
  return <span className={`notification-message notification-message-${status === "READ" ? "read" : "unread"}`}>{message}</span>;
};
