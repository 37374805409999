import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FilterType, Group } from "../../../interfaces/devices/group/group";
import { TRANSLATION } from "../../../../administration/translations/translation";
import i18n from "../../../../../i18n";
import { AssociatedDevicesInGroupResponse } from "src/app/portal-app/interfaces/devices/group/available-groups";
import { DevicesByFilter } from "src/app/administration/models/device-profile.model";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { GroupService } from "src/app/portal-app/service/device/GroupService";
import { TNSCheckbox } from "@tns/ui-components";

export interface GroupsColumns {
  groups: Record<string, unknown>[];
  associated: Record<string, unknown>[];
  radioButtonOptions: { value: FilterType; children: string }[];
  skeletonColumnsGroups: unknown[];
  deviceByType: (data: AssociatedDevicesInGroupResponse, filter: FilterType) => DevicesByFilter[] | string[] | undefined;
}

export const groupsColumns = (): GroupsColumns => {
  const groups = [
    {
      dataIndex: "selected",
      title: "addToMap",
      key: "addToMap",
      ellipsis: true,
      width: 120,
      render: (selected: boolean, row: Group) => (
        <div className="d-flex align-items-center justify-content-center">
          {row.name === "TNS All devices" ? null : (
            <TNSCheckbox
              defaultChecked={selected}
              key={row.name}
              onChange={async (e: CheckboxChangeEvent): Promise<void> => {
                if (row.name) {
                  if (e.target.checked) {
                    await GroupService.associate(row.name);
                  } else {
                    await GroupService.dissociate(row.name);
                  }
                }
              }}
            />
          )}
        </div>
      )
    },
    {
      dataIndex: "name",
      title: "groupName",
      key: "groupName",
      render: (text: string, row: Group) => (
        <div className="d-flex align-items-center gap-3">
          <FontAwesomeIcon icon={faTag} color={row.color} size="xl" /> {text}
        </div>
      )
    }
  ];

  const associated = [
    {
      dataIndex: "deviceName",
      title: "deviceName",
      key: "deviceName"
    }
  ];

  const radioButtonOptions: { value: FilterType; children: string }[] = [
    {
      value: FilterType.AllDevices,
      children: i18n.t(TRANSLATION.PROFILES.EDIT_PROFILE.allDevices)
    },
    {
      value: FilterType.Acna,
      children: i18n.t(TRANSLATION.PROFILES.EDIT_PROFILE.acna)
    },
    {
      value: FilterType.Country,
      children: i18n.t(TRANSLATION.PROFILES.EDIT_PROFILE.country)
    },
    {
      value: FilterType.State,
      children: i18n.t(TRANSLATION.PROFILES.EDIT_PROFILE.state)
    },
    {
      value: FilterType.City,
      children: i18n.t(TRANSLATION.PROFILES.EDIT_PROFILE.city)
    }
  ];

  const skeletonColumnsGroups = groups.map(({ dataIndex, title, key }) => ({
    dataIndex,
    title,
    key
  }));

  const deviceByType = (data: AssociatedDevicesInGroupResponse, filter: FilterType): DevicesByFilter[] | string[] | undefined => {
    switch (filter) {
      case FilterType.Acna:
        return data.acnas;
      case FilterType.City:
        return data.cities;
      case FilterType.Country:
        return data.countries;
      case FilterType.State:
        return data.states;
      default:
        return data.devices;
    }
  };

  return { groups, associated, radioButtonOptions, skeletonColumnsGroups, deviceByType };
};
