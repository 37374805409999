export interface DevicesConnectionStatus {
  onPrimary: number;
  onBackup: number;
  offline: number;
  indeterminate: number;
  unknown: number;
}

export interface Group extends DevicesConnectionStatus {
  name?: string;
  totalNumberOfDevices?: number;
  color?: string;
}

export interface DevicesByGroupName {
  addTnsDeviceNameList?: string[];
  removeTnsDeviceNameList?: string[];
  removeAllDevices?: boolean;
  addAllDevices?: boolean;
  name?: string;
  color?: string;
}

export interface GroupForm {
  name: string;
  color: string;
}

export enum FilterType {
  Country = "countries",
  City = "cities",
  State = "states",
  Acna = "acnas",
  AllDevices = "devices"
}

export interface GroupResponse {
  groups: Group[];
}

export interface GroupParams {
  startAtRecord?: number;
  recordsPerPage?: number;
  tnsDeviceName?: string;
}

export interface GroupRenderProps extends Group {
  checked?: boolean;
}

export interface GroupRenderResponse {
  totalRecords: number;
  groups?: GroupRenderProps[];
}

export interface GroupParams {
  startAtRecord?: number;
  recordsPerPage?: number;
}

export enum GroupStatus {
  Pending = "Pending",
  Existing = "Existing",
  Available = "Available"
}

export enum GroupFilters {
  ACNA = "ACNA",
  Member = "member",
  Country = "country",
  City = "city",
  ZipCode = "zip Code",
  TNSServiceType = "TNS Service Type",
  BoardingStatus = "boarding Status"
}

export enum GroupFilterType {
  AllGroups = "allGroups",
  MyGroups = "myGroups"
}

export enum OptionsMenuGroups {
  CreateGroup = "createGroup",
  MyGroups = "myGroups"
}
