import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { i18nInstance } from "../../../../../../i18n";
import { TRANSLATION } from "../../../../utils/const/translation";
import { DevicesGETApi, DevicesPOSTApi } from "@tnso/api-sdk";
import { useApiController } from "../../../../../../shared/hooks/use-api-controller";
import { StatusCode } from "@tnso/api-core";
import { CustomErrorResponse } from "../../../../helpers/api/RequestHelper";
import { MessageHelper } from "./MessageHelper";
import { TroubleshootingTools } from "../../../../views/menu/monitoring/devices/device-detail/troubleshooting/enums/TroubleshootingTools";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { PingTypes } from "../../../../views/menu/monitoring/devices/device-detail/troubleshooting/enums/PingTypes";
import { store } from "../../../../store/StoreMobx";
import { DateFormat, DateHelper, useAsyncCall } from "@tnso/shared";
import { ConnectivityStatus } from "../../../../interfaces/devices/devices";
import { ConfigContext } from "../../../../../../contexts/configuration/ConfigurationContext";
import { FeatureFlags } from "../../../../../../contexts/configuration/EnvironmentVariables";

export enum CommandStatus {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  FAILED = "FAILED"
}

export enum Views {
  IPSEC = "ipsec"
}

export interface TroubleshootingFormValues {
  tool?: TroubleshootingTools;
  network?: PingTypes;
  attempts?: number;
  time?: number;
  hostname?: string;
  ipAddress?: string;
}

interface VisibleFilters {
  network: boolean;
  attempts: boolean;
  time: boolean;
  ipAddress: boolean;
  hostName: boolean;
}

interface TroubleshootingContextValue {
  selectedTool?: TroubleshootingTools;
  runningCommand: boolean;
  runEnabled: boolean;
  stopEnabled: boolean;
  terminal: string;
  deviceCompatible: boolean;
  stopVisible: boolean;
  visibleFilters: VisibleFilters;
  filtersEnabled: boolean;
  toolsFilterEnabled: boolean;
  ipAddressRangeFromTo: { from: string; to: string };
  runCommand: () => Promise<void>;
  stopCommand: () => void;
  clearTerminal: () => void;
}

const initialValue: TroubleshootingContextValue = {
  selectedTool: undefined,
  runningCommand: false,
  runEnabled: false,
  stopEnabled: false,
  terminal: "",
  deviceCompatible: false,
  stopVisible: false,
  visibleFilters: {
    network: false,
    attempts: false,
    time: false,
    ipAddress: false,
    hostName: false
  },
  filtersEnabled: true,
  toolsFilterEnabled: true,
  ipAddressRangeFromTo: {
    from: "-",
    to: "-"
  },
  runCommand: (): Promise<void> => Promise.resolve(undefined),
  stopCommand: () => undefined,
  clearTerminal: () => undefined
};

export const TroubleshootingContext = React.createContext(initialValue);

const TroubleshootingContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const apiGetController = useApiController(DevicesGETApi);
  const apiPostController = useApiController(DevicesPOSTApi);
  const context = useContext(ConfigContext);
  const { device } = store;
  const { deviceName } = useParams();
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const [ipAddressRangeFromTo, setIpAddressRangeFromTo] = useState(initialValue.ipAddressRangeFromTo);
  const [runningCommand, setRunningCommand] = useState(false);
  const [terminalMessagesBlocks, setTerminalMessagesBlocks] = useState<string[]>([]);
  const [deviceCompatible, setDeviceCompatible] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [countAttemp, setCountAttemp] = useState(0);
  const [waitingLongerTimeout, setWaitingLongerTimeout] = useState<NodeJS.Timeout>();
  const [requestId, setRequestId] = useState<string>();

  const methods = useForm<TroubleshootingFormValues>({
    defaultValues: {}
  });
  const selectedTool = methods.watch("tool");
  const network = methods.watch("network");

  const runEnabled = useMemo(() => {
    const deviceData = device.detail.data;
    const deviceOnline = deviceData?.connectivityStatus && [ConnectivityStatus.onPrimary, ConnectivityStatus.onBackup].includes(deviceData?.connectivityStatus);
    return (deviceOnline ?? false) && deviceData?.manufacturer === "VirtualAccess" && !runningCommand;
  }, [device.detail.data, runningCommand]);

  const stopEnabled = useMemo(() => runningCommand, [runningCommand]);

  const stopVisible = useMemo(() => {
    const ffStopButtonVisible = context.canPerformAction(FeatureFlags.TroubleshootingStopButtonVisible);
    return ffStopButtonVisible && (selectedTool === TroubleshootingTools.Ping || selectedTool === TroubleshootingTools.TCPCaptures);
  }, [context, selectedTool]);

  const snackBarExtraDelay = useMemo(() => {
    return Number(context.configuration?.environmentValues?.TROUBLESHOOTING_COMMAND_EXTRA_WAITING_TIME_SECONDS ?? 0);
  }, [context.configuration?.environmentValues?.TROUBLESHOOTING_COMMAND_EXTRA_WAITING_TIME_SECONDS]);

  const closeActiveToast = useCallback(() => {
    MessageHelper.dismissAll();
  }, []);

  const cancelCurrentRequest = useCallback(() => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }
  }, [abortController]);

  const stopCommand = useCallback(() => {
    clearTimeout(waitingLongerTimeout);
    closeActiveToast();
    setRunningCommand(() => false);
    setCountAttemp(() => 0);
  }, [closeActiveToast, waitingLongerTimeout]);

  const showErrorMessage = useCallback(
    (errorMessage: string) => {
      closeActiveToast();
      MessageHelper.errorMessage(errorMessage);
      console.error(errorMessage);
    },
    [closeActiveToast]
  );

  // eslint-disable-next-line
  const getErrorMessage = useCallback((error: any) => {
    let errorMessage = i18nInstance.t(TRANSLATION.ERROR.somethingWentWrongTryAgainLater);
    if (error?.status === StatusCode.CONFLICT) {
      errorMessage = error?.data.error;
    }
    return errorMessage;
  }, []);

  const showError = useCallback(
    (error: CustomErrorResponse) => {
      const errorMessage = getErrorMessage(error);
      showErrorMessage(errorMessage);
    },
    [getErrorMessage, showErrorMessage]
  );

  const getIsUmbrellaConfigured = useCallback(async (): Promise<boolean> => {
    let isCompatible = false;
    if (deviceName) {
      try {
        const result = await apiGetController.devicesTnsDeviceNameIscompatibleTroubleshootingUmbrellaGet(deviceName);
        isCompatible = result?.data?.isCompatible ?? false;
        if (!isCompatible) {
          showErrorMessage(i18nInstance.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.noInternetTunnel));
        }
      } catch (error) {
        showError(error as CustomErrorResponse);
        throw error;
      }
    }
    return isCompatible;
  }, [apiGetController, deviceName, showError, showErrorMessage]);

  const init = useCallback(async () => {
    try {
      const checkDeviceCompatible = async (): Promise<boolean> => {
        let isCompatible = false;
        if (deviceName) {
          try {
            const response = await apiGetController.devicesTnsDeviceNameIscompatibleTroubleshootingGet(deviceName);
            isCompatible = response?.data?.isCompatible ?? false;
            setDeviceCompatible(isCompatible);
          } catch (error) {
            showError(error as CustomErrorResponse);
          }
        }
        return isCompatible;
      };
      const loadDeviceIPRange = async (isCompatible: boolean): Promise<void> => {
        if (deviceName && isCompatible) {
          const result = await apiGetController.devicesTnsDeviceNameIpaddressRangeGet(deviceName);
          const range = result?.data ?? [];
          if (range.length && range.length >= 1) {
            setIpAddressRangeFromTo({ from: range[0].lo ?? "-", to: range[0].hi ?? "-" });
          }
        }
      };
      const isCompatible = await checkDeviceCompatible();
      await loadDeviceIPRange(isCompatible);
      await store.device.detail.loadData(deviceName);
    } catch (error) {
      showError(error as CustomErrorResponse);
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAsyncCall(init, [], store.shared.getTimeToAutoRefresh());

  const terminal = useMemo(() => terminalMessagesBlocks.join("\n"), [terminalMessagesBlocks]);

  const showInfoMessage = useCallback(
    (text: string, seconds?: number): void => {
      closeActiveToast();
      MessageHelper.infoMessage(text, {
        autoClose: seconds !== undefined ? seconds * 1000 : false,
        hideProgressBar: false
      });
    },
    [closeActiveToast]
  );

  const showCommandTakingLongerMessage = useCallback((): void => {
    showInfoMessage(i18nInstance.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.takingLonger));
  }, [showInfoMessage]);

  const showWaitingCommandMessage = useCallback(
    (seconds = 30): void => {
      showInfoMessage(`${i18nInstance.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.requestProcessingnWaitUpTo)} ${seconds}s`, seconds);
      setWaitingLongerTimeout(setTimeout(showCommandTakingLongerMessage, seconds * 1000));
    },
    [showCommandTakingLongerMessage, showInfoMessage]
  );

  const clearTerminal = useCallback(() => {
    setTerminalMessagesBlocks([]);
  }, []);

  useEffect((): void => {
    let attempts: number | undefined;
    let time: number | undefined;
    if (context.configuration?.environmentValues?.TROUBLESHOOTING_ATTEMPS_DEFAULT !== undefined) {
      attempts = Number(context.configuration.environmentValues.TROUBLESHOOTING_ATTEMPS_DEFAULT);
    }
    switch (selectedTool) {
      case TroubleshootingTools.Ping:
        if (context.configuration?.environmentValues?.TROUBLESHOOTING_TIME_PING_DEFAULT !== undefined) {
          time = Number(context.configuration.environmentValues.TROUBLESHOOTING_TIME_PING_DEFAULT);
        }
        break;
      case TroubleshootingTools.TCPCaptures:
        if (context.configuration?.environmentValues?.TROUBLESHOOTING_TIME_TCPPACKETCAPTURE_DEFAULT !== undefined) {
          time = Number(context.configuration.environmentValues.TROUBLESHOOTING_TIME_TCPPACKETCAPTURE_DEFAULT);
        }
        break;
    }
    methods.reset({
      tool: selectedTool,
      attempts: attempts,
      hostname: undefined,
      ipAddress: undefined,
      network: undefined,
      time
    });
    stopCommand();
    clearTerminal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTool]);

  const visibleFilters = useMemo(() => {
    const visibleFilters: VisibleFilters = {
      attempts: false,
      hostName: false,
      ipAddress: false,
      network: false,
      time: false
    };
    switch (selectedTool) {
      case TroubleshootingTools.ActiveConnections:
        break;
      case TroubleshootingTools.DHCPInformation:
        break;
      case TroubleshootingTools.DNSLookup:
        visibleFilters.hostName = true;
        break;
      case TroubleshootingTools.IPSecTunnelInformation:
        break;
      case TroubleshootingTools.Ping:
        visibleFilters.network = true;
        visibleFilters.attempts = true;
        visibleFilters.time = true;
        if (network) {
          if (network === PingTypes.LAN) {
            visibleFilters.ipAddress = true;
          } else if (network === PingTypes.INTERNET) {
            visibleFilters.hostName = true;
          }
        }
        break;
      case TroubleshootingTools.RoutingTableInformation:
        break;
      case TroubleshootingTools.TCPCaptures:
        visibleFilters.attempts = true;
        visibleFilters.time = true;
        visibleFilters.ipAddress = true;
        break;
    }
    return visibleFilters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network, selectedTool]);

  const handleStopCommand = useCallback(() => {
    cancelCurrentRequest();
    stopCommand();
  }, [cancelCurrentRequest, stopCommand]);

  useEffect(() => {
    const checkCommandResultPingTcp = async (): Promise<void> => {
      if (deviceName) {
        try {
          let commandType: "ping" | "tcpdump" | undefined;
          if (selectedTool === TroubleshootingTools.Ping) {
            commandType = "ping";
          } else if (selectedTool === TroubleshootingTools.TCPCaptures) {
            commandType = "tcpdump";
          }
          if (commandType && requestId && runningCommand) {
            const result = await apiGetController.devicesTnsDeviceNameCommandCommandTypeResultRequestIdGet(deviceName, commandType, requestId, {
              signal: abortController?.signal
            });
            const commandStatus = result?.data.commandStatus;
            if (commandStatus === CommandStatus.PENDING) {
              setTimeout(() => setRetryCount(retryCount + 1), 5000);
            } else if (commandStatus === CommandStatus.FAILED || commandStatus === CommandStatus.SUCCESS) {
              if (commandStatus === CommandStatus.FAILED) {
                showErrorMessage(i18nInstance.t(TRANSLATION.ERROR.somethingWentWrongTryAgainLater));
              }
              let commandResult = result?.data.commandResult;
              if (commandResult) {
                const last = commandResult.lastIndexOf("\n");
                const butLast = commandResult.substring(0, last).replace(/\n/g, "\n    ");
                commandResult = butLast;
                appendToTerminal(`    ${commandResult}\n`);
              }
              setCountAttemp(() => countAttemp + 1);
            }
          }
        } catch (error) {
          showError(error as CustomErrorResponse);
        }
      }
    };
    void checkCommandResultPingTcp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retryCount]);

  const appendToTerminal = useCallback((text = "No response") => {
    setTerminalMessagesBlocks((messages) => [...messages, text]);
  }, []);

  const appendAttempInfoToTerminal = useCallback(
    (attempt: number) => {
      const digitsHHMMSS = 8;
      const date = new Date();
      const dateYYYYMMDD = DateHelper.formatDate(date, DateFormat.YearMonthDay);
      const dateHHMMa = date.toTimeString().substring(0, digitsHHMMSS);
      const dateFormatted = `${dateYYYYMMDD} ${dateHHMMa}`;
      const attempText = `${i18nInstance.t(TRANSLATION.SIDEBAR.MONITORING.DEVICES.DEVICEDETAIL.TROUBLESHOOTING.attemptFirstPacket, {
        attempt: attempt.toString(),
        date: dateFormatted
      })}`;
      appendToTerminal(attempText);
    },
    [appendToTerminal]
  );

  const runCommandDnsLookup = useCallback(
    async (values: TroubleshootingFormValues) => {
      if (deviceName && values.hostname) {
        const response = await apiGetController.devicesTnsDeviceNameCommandDnslookupGet(deviceName, values.hostname);
        appendToTerminal(response.data.commandResult);
      }
    },
    [deviceName, apiGetController, appendToTerminal]
  );

  const runCommandPing = useCallback(
    async (values: TroubleshootingFormValues): Promise<void> => {
      if (deviceName && values.network) {
        const pingPollBody =
          values.network === PingTypes.LAN
            ? { ipAddress: values.ipAddress, cmdExecutionTime: values.time ?? 10 }
            : { hostname: values.hostname, cmdExecutionTime: values.time ?? 10 };
        const responseRequestId = await apiPostController.devicesTnsDeviceNameCommandPingPollPost(pingPollBody, deviceName);
        if (responseRequestId.data.requestId) {
          setRequestId(responseRequestId.data.requestId);
          setRetryCount(retryCount + 1);
        }
      }
    },
    [apiPostController, deviceName, getIsUmbrellaConfigured, retryCount]
  );

  const runCommandActiveConnections = useCallback(async () => {
    if (deviceName) {
      const response = await apiGetController.devicesTnsDeviceNameCommandTcpconnectionsGet(deviceName);
      appendToTerminal(response.data?.commandResult);
    }
  }, [deviceName, apiGetController, appendToTerminal]);

  const runCommandRoutingTableInformation = useCallback(async () => {
    if (deviceName) {
      const response = await apiGetController.devicesTnsDeviceNameCommandRoutingtableGet(deviceName);
      appendToTerminal(response.data?.commandResult);
    }
  }, [deviceName, apiGetController, appendToTerminal]);

  const runCommandDHCPInformation = useCallback(async () => {
    if (deviceName) {
      const response = await apiGetController.devicesTnsDeviceNameCommandDhcpGet(deviceName);
      appendToTerminal(response.data.commandResult);
    }
  }, [deviceName, apiGetController, appendToTerminal]);

  const runCommandIPSecTunnelInformation = useCallback(async () => {
    if (deviceName && deviceCompatible) {
      const isUmbrellaConfigured = await getIsUmbrellaConfigured();
      if (isUmbrellaConfigured) {
        const response = await apiGetController.devicesTnsDeviceNameCommandIpsecGet(deviceName);
        appendToTerminal(response.data.commandResult);
      }
    }
  }, [deviceName, deviceCompatible, getIsUmbrellaConfigured, apiGetController, appendToTerminal]);

  const runCommandTCPCaptures = useCallback(
    async (values: TroubleshootingFormValues): Promise<void> => {
      if (deviceName) {
        if (values.ipAddress) {
          const responseTcpRequest = await apiPostController.devicesTnsDeviceNameCommandTcpdumpPollPost(
            { ipAddress: values.ipAddress, cmdExecutionTime: values.time ?? 0 },
            deviceName
          );
          if (responseTcpRequest?.data.requestId) {
            setRequestId(responseTcpRequest.data.requestId);
            setRetryCount(retryCount + 1);
          }
        }
      }
    },
    [apiPostController, deviceName, retryCount]
  );

  useEffect(() => {
    const attemptChanged = async (): Promise<void> => {
      const values = methods.getValues();
      const attempts = values.attempts ?? 0;
      if (runningCommand) {
        if (countAttemp > 0 && countAttemp <= attempts) {
          await runCommandPingOrTCPCaptures(countAttemp, values);
        } else if (countAttemp > 0) {
          stopCommand();
        }
      }
    };
    void attemptChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countAttemp, runningCommand]);

  const runCommandWithNoAttempts = useCallback(
    async (values: TroubleshootingFormValues) => {
      showWaitingCommandMessage();
      try {
        switch (selectedTool) {
          case TroubleshootingTools.DNSLookup:
            await runCommandDnsLookup(values);
            break;
          case TroubleshootingTools.ActiveConnections:
            await runCommandActiveConnections();
            break;
          case TroubleshootingTools.RoutingTableInformation:
            await runCommandRoutingTableInformation();
            break;
          case TroubleshootingTools.DHCPInformation:
            await runCommandDHCPInformation();
            break;
          case TroubleshootingTools.IPSecTunnelInformation:
            await runCommandIPSecTunnelInformation();
            break;
        }
      } catch (error) {
        showError(error as CustomErrorResponse);
      } finally {
        stopCommand();
      }
    },
    [
      showWaitingCommandMessage,
      selectedTool,
      runCommandDnsLookup,
      runCommandActiveConnections,
      runCommandRoutingTableInformation,
      runCommandDHCPInformation,
      runCommandIPSecTunnelInformation,
      showError,
      stopCommand
    ]
  );

  const runCommandPingOrTCPCaptures = useCallback(
    async (attempt: number, values: TroubleshootingFormValues) => {
      appendAttempInfoToTerminal(attempt);
      try {
        if (selectedTool === TroubleshootingTools.Ping) {
          await runCommandPing(values);
        } else if (selectedTool === TroubleshootingTools.TCPCaptures) {
          await runCommandTCPCaptures(values);
        }
      } catch (error) {
        const commandResult = getErrorMessage(error as CustomErrorResponse);
        const commandStopped = abortController?.signal.aborted ?? false;
        if (commandResult && !commandStopped) {
          appendToTerminal(`    ${commandResult}\n`);
          setCountAttemp(() => attempt + 1);
        }
      }
    },
    [appendAttempInfoToTerminal, selectedTool, runCommandPing, runCommandTCPCaptures, getErrorMessage, abortController?.signal.aborted, appendToTerminal]
  );

  const runCommand = useCallback(async () => {
    const submit = methods.handleSubmit(async () => {
      // Check compatibility before running the command
      if (selectedTool === TroubleshootingTools.Ping && methods.getValues().network === PingTypes.INTERNET) {
        const isCompatible = await getIsUmbrellaConfigured();
        if (!isCompatible) {
          return; // Exit early if not compatible
        }
      }

      // Proceed with running the command
      setRunningCommand(() => true); // Lock the button
      closeActiveToast(); // Clear any active messages

      if ([TroubleshootingTools.Ping, TroubleshootingTools.TCPCaptures].includes(selectedTool)) {
        const values = methods.getValues();
        const newAbortController = new AbortController();
        setAbortController(() => newAbortController);

        const attempts = values.attempts ?? 0;
        const time = values.time ?? 0;
        const totalTimeSecondsWaitingCommandMessage = attempts * time + snackBarExtraDelay;

        showWaitingCommandMessage(totalTimeSecondsWaitingCommandMessage); // Notify user
        setCountAttemp(() => 1);
      } else {
        await runCommandWithNoAttempts(methods.getValues()); // Run other commands
      }
    });
    await submit();
  }, [methods, selectedTool, closeActiveToast, snackBarExtraDelay, showWaitingCommandMessage, runCommandWithNoAttempts]);

  const contextValue = useMemo(() => {
    const contextValue: TroubleshootingContextValue = {
      selectedTool,
      runningCommand,
      runEnabled,
      stopEnabled,
      terminal,
      deviceCompatible,
      stopVisible,
      visibleFilters,
      filtersEnabled: !runningCommand,
      toolsFilterEnabled: !runningCommand && runEnabled,
      ipAddressRangeFromTo,
      runCommand,
      stopCommand: handleStopCommand,
      clearTerminal
    };
    return contextValue;
  }, [
    selectedTool,
    runningCommand,
    runEnabled,
    stopEnabled,
    terminal,
    deviceCompatible,
    stopVisible,
    visibleFilters,
    ipAddressRangeFromTo,
    runCommand,
    handleStopCommand,
    clearTerminal
  ]);

  return (
    <TroubleshootingContext.Provider value={contextValue}>
      <FormProvider {...methods}>{children}</FormProvider>
    </TroubleshootingContext.Provider>
  );
};

export default TroubleshootingContextProvider;
