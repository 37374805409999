import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "./../../app/portal-app/utils/const/translation";
import { ConfigContext } from "../../contexts/configuration/ConfigurationContext";

interface ReleaseVersionProps {
  color?: string;
}

export const ReleaseVersion: React.FC<ReleaseVersionProps> = (): JSX.Element => {
  const context = useContext(ConfigContext);
  const { t } = useTranslation();

  return (
    <span style={{ fontSize: "14px" }}>
      {t(TRANSLATION.LOGIN.release)} {context.configuration?.environmentValues?.NUMBER_RELEASE}
    </span>
  );
};
