import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import primaryDetails from "../../assets/images/icons/marker-details-primary.png";
import offlineDetails from "../../assets/images/icons/marker-details-offline.png";
import backupDetails from "../../assets/images/icons/marker-details-backup.png";
import unknownDetails from "../../assets/images/icons/marker-details-unknown.png";
import indeterminateDetails from "../../assets/images/icons/marker-details-indeterminate.png";
import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { ConnectivityStatus, DeviceDetail } from "../../models/devices/devices";
import { GeolocationResponse } from "../../models/devices/response/response";
import { store } from "../../store/store";
import { DeviceService } from "../../service/device/device-service";
import { content } from "./info-marker";

export interface MarkerClustererProps {
  map?: google.maps.Map;
  devicesGroup?: GeolocationResponse[];
  collectElementsWithTitle: () => void;
  getDrawerData?: (device: DeviceDetail | undefined) => void;
}

export const MarkerCluster = observer(({ map, devicesGroup, collectElementsWithTitle, getDrawerData }: MarkerClustererProps): null => {
  const { geolocation } = store.device;
  const navigate = useNavigate();
  const geolocations = devicesGroup ?? geolocation.data;
  const markerClusterer = useMemo(() => new MarkerClusterer({}), []);
  const markerSpiderfier = useMemo(() => map && new OverlappingMarkerSpiderfier(map, { keepSpiderfied: true }), [map]);

  const markerClustererRef = useRef<MarkerClusterer | null>(null);
  const observerRef = useRef<MutationObserver | null>(null);
  const [isDeviceGroup] = useState<boolean>(devicesGroup ? true : false);
  const actualMarker = useRef<google.maps.InfoWindow | null>(null);

  const asignedMarkers = useCallback((): google.maps.Marker[] | undefined => {
    if (geolocations) {
      const markers: google.maps.Marker[] = [];

      geolocations?.forEach((device: GeolocationResponse): void => {
        if (device.latitude && device.longitude) {
          const marker = new google.maps.Marker({ position: { lat: Number(device.latitude), lng: Number(device.longitude) }, title: `${device.tnsDeviceName}-marker` });
          if (isDeviceGroup) {
            marker.setCursor("auto");
          }

          switch (device.connectivityStatus) {
            case ConnectivityStatus.onPrimary:
              marker.setIcon(primaryDetails);
              break;
            case ConnectivityStatus.offline:
              marker.setIcon(offlineDetails);
              break;
            case ConnectivityStatus.onBackup:
              marker.setIcon(backupDetails);
              break;
            case ConnectivityStatus.indeterminate:
              marker.setIcon(indeterminateDetails);
              break;
            default:
              marker.setIcon(unknownDetails);
          }
          marker.addListener("spider_click", async () => {
            if (!isDeviceGroup) {
              try {
                const response = await DeviceService.getDetail(device.tnsDeviceName);
                if (response) {
                  const infoContent = content(response.data, isDeviceGroup);
                  const infoMarker = new google.maps.InfoWindow({ content: infoContent });
                  actualMarker.current?.close();
                  actualMarker.current = infoMarker;
                  if (getDrawerData) {
                    getDrawerData(response.data);
                    const latLng = marker.getPosition();
                    if (latLng) {
                      map?.setCenter(latLng);
                      map?.panTo(latLng);
                      map?.setZoom(24);
                    }
                  }
                  if (!getDrawerData) {
                    infoMarker.open({
                      anchor: marker,
                      map,
                      shouldFocus: true
                    });
                  }

                  map?.addListener("zoom_changed", () => {
                    actualMarker.current?.close();
                  });

                  map?.addListener("click", () => {
                    actualMarker.current?.close();
                  });

                  google.maps.event.addListener(infoMarker, "domready", function () {
                    const element = document.getElementById("redirectToDetail");
                    if (element) {
                      element.addEventListener("click", () => navigate(`/monitoring/devices/device-detail/${device.tnsDeviceName}`));
                    }
                  });
                }
              } catch (e) {
                console.error(e);
              }
            }
          });
          if (markerSpiderfier) {
            markerSpiderfier?.addMarker(marker, () => 1);
          }
          markers.push(marker);
        }
      });
      if (!geolocations.length) {
        map?.setZoom(2);
        map?.setCenter({ lat: 0, lng: 0 });
      } else {
        const bounds = new google.maps.LatLngBounds();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        markers.forEach((marker) => bounds.extend(marker.getPosition()!));
        map?.fitBounds(bounds);
      }
      return markers;
    }
  }, [geolocations, markerSpiderfier, navigate, map, isDeviceGroup]);

  useEffect(() => {
    const markers = asignedMarkers();
    markerClusterer.addMarkers(markers ?? []);
    markerClusterer.setMap(map ?? null);
    markerClustererRef.current = markerClusterer;

    const handleMutation = (): void => {
      collectElementsWithTitle();
    };

    const observer = new MutationObserver(handleMutation);
    observerRef.current = observer;

    // Observe the target node for mutations
    const mapContainer = map?.getDiv();
    if (mapContainer) {
      observer.observe(mapContainer, { childList: true, subtree: true });
    }

    return (): void => {
      observerRef.current?.disconnect();
      markerClusterer.setMap(null);
      markerClusterer.clearMarkers();
      markerSpiderfier?.removeAllMarkers();
    };
  }, [geolocations, map, markerClusterer, asignedMarkers, markerSpiderfier, collectElementsWithTitle]);

  return null;
});
