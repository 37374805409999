import React, { useCallback, useState } from "react";
import { NotificationsGETApi, NotificationsCountGETApi, NotificationsPATCHApi, InlineResponse20079, InlineResponse20080, Notifications } from "@tnso/api-sdk";
import { useApiController } from "src/shared/hooks/use-api-controller";
import { NotificationBody, NotificationContextProps, NotificationQueryParams, NotificationStatus } from "src/shared/models/notification.model";
import { MessageHelper } from "@tnso/shared";
import { useTranslation } from "react-i18next";
import { TRANSLATION } from "../../app/portal-app/utils/const/translation";
import { InstanceType } from "../../shared/hooks/use-api-controller";

export interface NotificationContextProviderProps {
  children: React.ReactNode;
}

export type NotificationsResponse = InlineResponse20079;
export type NotificationCount = InlineResponse20080;

export const initialValue = {
  amountOfNotificationsUnread: 0,
  params: {},
  notifications: undefined,
  getNotifications: (): Promise<NotificationsResponse | undefined> => Promise.resolve(undefined),
  getLastNotifications: (): Promise<Notifications[] | undefined> => Promise.resolve(undefined),
  getCountNotificationsUnread: (): Promise<NotificationCount | undefined> => Promise.resolve(undefined),
  updateNotifications: (): Promise<NotificationsResponse | undefined> => Promise.resolve(undefined),
  setNotificationsData: (): Promise<NotificationsResponse | undefined> => Promise.resolve(undefined),
  getHasNotifications: (): Promise<boolean | undefined> => Promise.resolve(undefined)
};

export const NotificationContext = React.createContext<NotificationContextProps>(initialValue);

const NotificationContextProvider: React.FC<NotificationContextProviderProps> = ({ children }) => {
  const { t } = useTranslation();
  const [amountOfNotificationsUnread, setAmountOfNotificationsUnread] = useState(0);
  const [notifications, setNotifications] = useState<NotificationsResponse>();
  const apiGetController = useApiController(NotificationsGETApi, InstanceType.DefaultWithBase);
  const apiCountGetController = useApiController(NotificationsCountGETApi, InstanceType.DefaultWithBase);
  const apiPatchController = useApiController(NotificationsPATCHApi, InstanceType.DefaultWithBase);

  const getNotifications = useCallback(
    async (queryParams: NotificationQueryParams): Promise<NotificationsResponse | undefined> => {
      const { notificationFromDate, notificationToDate, notificationType, orderBy, referenceId, sortBy, status, startAtRecord, recordsPerPage, id } = queryParams;
      try {
        const response = await apiGetController.notificationsGet(
          notificationFromDate,
          notificationToDate,
          notificationType,
          status,
          referenceId,
          id,
          orderBy,
          recordsPerPage,
          sortBy,
          startAtRecord
        );
        if (response?.data) {
          return response.data;
        }
      } catch (error) {
        MessageHelper.errorMessage(t(TRANSLATION.MODAL.ALERT.somethingWentWrong));
        return undefined;
      }
    },
    [apiGetController, t]
  );

  const getLastNotifications = async (queryParams: NotificationQueryParams): Promise<Notifications[] | undefined> => {
    try {
      const response = await getNotifications(queryParams);
      if (response) {
        return response.notifications?.slice(0, 4);
      }
    } catch (error) {
      MessageHelper.errorMessage(t(TRANSLATION.MODAL.ALERT.somethingWentWrong));
      return undefined;
    }
  };

  const getHasNotifications = async (): Promise<boolean | undefined> => {
    try {
      const response = await apiGetController.notificationsGet();
      if (response.data && response.data.notifications) {
        return response.data?.notifications?.length > 0;
      }
    } catch (error) {
      MessageHelper.errorMessage(t(TRANSLATION.MODAL.ALERT.somethingWentWrong));
      return undefined;
    }
  };

  const setNotificationsData = useCallback(
    async (queryParams?: NotificationQueryParams) => {
      if (queryParams) {
        const response = await getNotifications(queryParams);
        setNotifications(response);
        return response;
      }
    },
    [getNotifications]
  );

  const getCountNotificationsUnread = async (): Promise<NotificationCount | undefined> => {
    const queryParams: NotificationQueryParams = { status: NotificationStatus.UNREAD };
    const { notificationFromDate, notificationToDate, notificationType, referenceId, status } = queryParams;
    try {
      const response = await apiCountGetController.notificationsCountGet(notificationFromDate, notificationToDate, notificationType, status, referenceId);
      setAmountOfNotificationsUnread(response.data.count ?? 0);
      return response.data;
    } catch (error) {
      MessageHelper.errorMessage(t(TRANSLATION.MODAL.ALERT.somethingWentWrong));
      return undefined;
    }
  };

  const updateNotifications = async (notification: NotificationBody): Promise<NotificationsResponse | undefined> => {
    try {
      await apiPatchController.notificationsPatch(notification);
      const responseUnread = await getCountNotificationsUnread();
      if (responseUnread) {
        setAmountOfNotificationsUnread(responseUnread.count ?? 0);
      }
    } catch (error) {
      setAmountOfNotificationsUnread(0);
      MessageHelper.errorMessage(t(TRANSLATION.MODAL.ALERT.somethingWentWrong));
      return undefined;
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        params: {},
        amountOfNotificationsUnread,
        notifications,
        setNotificationsData,
        getLastNotifications,
        getNotifications,
        getHasNotifications,
        getCountNotificationsUnread,
        updateNotifications
      }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
